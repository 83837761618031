import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Icon } from "antd";
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import { getUsersRoute } from "../../../../../../../../../services/navigation/users-navigation/users-navigation-routes.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";
import { getUserById, toggleEnableStatus } from "../../../../../../../../../services/users-service/users.service";
import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";

import UserInfo from "./UserInfo/UserInfo";

import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";

import BaseNavigationItem from "../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import TabsComponent from "../../../../../../../../../components/TabsComponent/TabsComponent";

import './UserDetails.scss';

class UserDetails extends Component {

  state = {
    activeTab: '1',
    data: {},
    dataLoaded: false,
    loading: false
  };

  /**
   * React lifecycle hook
   * Used to trigger data load
   */
  componentDidMount() {
    this.setLoading(true);
    getUserById(this.props.userId)
      .then(this.onGetUserSuccess)
      .catch(this.onGetUserFailure);
  }

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  getActions = () => {
    const { t } = this.props;
    return [
      <Button onClick={this.showChangePasswordModal}
        key="changePasswordBtn">
        <Icon type="form" />
        {t('UserDetails.changePassword')}
      </Button>
    ];
  };

  /**
   * Returns an array containing tabs data
   *
   * @returns {Object[]}
   */
  getTabs = () => {
    const { t } = this.props;
    return [{
      title: t('UserDetails.userInfo'),
      view: (
        <UserInfo
          data={this.state.data}
          setUserEnabled={this.setUserEnabled}
        />
      )
    }];
  };

  /**
   * User data get failure handler
   */
  onGetUserFailure = () => {
    this.setLoading(false);
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('UserDetails.dataGetFailed')
    });
  };

  /**
   * User data get success handler
   *
   * @param {Object} response
   */
  onGetUserSuccess = (response) => {
    const { data } = response.data;
    this.setDocumentTitle(data);
    this.setState({
      data: data,
      dataLoaded: true,
      loading: false
    });
  };

  /**
   * Login change success handler
   *
   * @param {String} email
   */
  onLoginChange = ({ email }) => {
    this.setState(prevState => {
      const data = cloneDeep(prevState.data);
      data.email = email;
      return {
        data: data
      };
    });
  };

  /**
   * Set user enabled failure handler
   *
   * @param {number} enabled
   */
  onSetUserEnableFailure = (enabled) => {
    this.setLoading(false);
    const { t } = this.props;
    const key = enabled === 1 ? 'enableFail' : 'disableFail';
    displayErrorNotification({
      duration: 3,
      message: t(`UserDetails.${key}`)
    });
  };

  /**
   * Set user enabled success handler
   *
   * @param {String} id
   * @param {Object} data
   */
  onSetUserEnableSuccess = (id, data) => {
    const { enable } = data;
    const newData = {
      enable: data.enable
    };

    const key = enable === 1 ? 'enableSuccess' : 'disableSuccess';
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t(`UserDetails.${key}`)
    });
    this.updateUserDetails(newData);
    this.setLoading(false);
  };

  /**
   * Returns body element for render
   *
   * @returns {JSX.Element}
   */
  renderBody = () => {
    return (
      <div className="UserDetails-body">
        <TabsComponent
          activeKey={this.state.activeTab}
          onChange={this.changeTab}
        >
          {this.getTabs()}
        </TabsComponent>
      </div>
    );
  };

  /**
   * Returns header element for render
   *
   * @returns {JSX.Element}
   */
  renderHeader = () => {
    const { name = '/' } = this.state.data;
    const { t } = this.props;
    const routes = [{
      url: getUsersRoute(),
      text: t('UserDetails.users')
    }, {
      text: name
    }];

    return (
      <BaseNavigationItem routes={routes}>
        {this.getActions()}
      </BaseNavigationItem>
    );
  };

  /**
   * Updates app document title
   *
   * @param {Object} data
   */
  setDocumentTitle = (data) => {
    const title = this.props.t('UserDetails.documentTitle', { name: data.name });
    DocumentTitleController.setDocumentTitle(title);
  };

  /**
   * Sets loading state with the passed value
   *
   * @param {boolean} loading
   */
  setLoading = (loading) => {
    this.setState({
      loading: loading
    })
  };

  /**
   * Triggers toggle enabled status request
   *
   * @param {number} enable
   * @param {String} userId
   */
  setUserEnabled = (enable, userId) => {
    this.setLoading(true);
    const data = {
      enable: enable
    };

    toggleEnableStatus(userId, enable)
      .then(this.onSetUserEnableSuccess.bind(this, userId, data))
      .catch(this.onSetUserEnableFailure.bind(this, enable));
  };

  /**
   * Shows change password modal dialog
   */
  showChangePasswordModal = () => {
    const modal = (
      <ChangePasswordModal userId={this.props.userId} />
    );
    ModalController.showModal(modal);
  };

  /**
   * Updates state with passed user data
   *
   * @param {Object} data
   */
  updateUserDetails = data => {
    this.setState(prevState => {
      const newData = cloneDeep(prevState.data);
      Object.assign(newData, data);
      this.props.updateUserData(this.props.userId, data);
      return {
        data: newData
      };
    });
  };

  render() {
    const { t } = this.props;
    const { dataLoaded, loading } = this.state;
    return (
      <LoadingWrapper className='UserDetails'
        dataLoaded={dataLoaded}
        isLoading={loading}
        loadingMsg={t('UserDetails.loadingPleaseWait')}>
        {this.renderHeader()}
        {this.renderBody()}
      </LoadingWrapper>
    );
  }
}

UserDetails.propTypes = {
  userId: PropTypes.string
};

export default withTranslation()(UserDetails);
