import axios from 'axios';

export const urls = {
  playerConfig: '/player_config',
};

/* istanbul ignore next */
export const getPropertyConfig = (propertyId) => {
  return axios.get(`${urls.playerConfig}/${propertyId}`);
};

/* istanbul ignore next */
export const savePropertyPlayerConfig = (propertyId, playerConfig) => {
  if (!playerConfig) {
    playerConfig = {};
  }
  const stringifiedConfig = encodeURIComponent(JSON.stringify(playerConfig));
  return axios.patch(`${urls.playerConfig}/${propertyId}?player_config=${stringifiedConfig}`);
};