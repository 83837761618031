import React from 'react';
import {withTranslation} from "react-i18next";
import {Button, Collapse, Icon} from "antd";
import PropTypes from 'prop-types';

import ConfigurationCollapseAbstract from "../ConfigurationCollapseAbstract";
import SelectMoviesModal from "../SelectMoviesModal/SelectMoviesModal";
import MoviePlayerConfigCollapse from '../MoviePlayerConfigCollapse/MoviePlayerConfigCollapse';

import {stopEvent} from "../../../../../../../../../../services/util-service/util.service";

import {ModalController} from "../../../../../../../../../../controllers/modal-controller/modal.controller";

import './StudioPlayerConfigCollapse.scss';

/* istanbul ignore file */
class StudioPlayerConfigCollapse extends ConfigurationCollapseAbstract {

  displaySelectMoviesModal = (studioId, event) => {
    const {propertyId} = this.props;
    const {movies} = this.props.studios[studioId];
    stopEvent(event);
    const modal = (
      <SelectMoviesModal
        selectedMovies={movies || {}}
        onSelectMovies={this.props.setSelectedMovies}
        propertyId={propertyId}
        studioId={studioId}
      />
    );
    ModalController.showModal(modal);
  };

  getConfig = ({studioId}) => {
    return this.props.studios[studioId].config;
  };

  getItemId = ({studioId}) => studioId;

  getStudioCollapseHeader = (studioId, studioName, deleteAll) => {
    const {t} = this.props;
    return (
      <div className="Header">
        <div className="Text">
          {t(`StudioPlayerConfigCollapse.title`, {name: studioName})}
        </div>
        <div className="Section2">
          {this.getConfigurePlayerBtn({studioId}, studioName)}
          <Button onClick={this.displaySelectMoviesModal.bind(this, studioId)}>
            {t(`StudioPlayerConfigCollapse.selectMovies`)}
          </Button>
          {!deleteAll ? <Icon type="close" onClick={this.removeStudio.bind(this, studioId)}/> : null}
        </div>
      </div>
    );
  };

  onPlayerConfigurationChange = (studioId, config, configValues) => {
    const {propertyId} = this.props;
    this.props.onStudioPlayerChange(propertyId, studioId, config);
  };

  removeConfiguration = (studioId) => {
    const {propertyId} = this.props;
    this.props.removeStudioConfiguration(propertyId, studioId);
  };

  removeStudio = (studioId, event) => {
    stopEvent(event);
    const {propertyId} = this.props;
    this.props.onRemoveStudio(propertyId, studioId);
  };

  studioMap = (studio) => {
    const {id: studioId, name, movies, deleteAll} = studio;
    const {onRemoveMovie, propertyId} = this.props;
    return (
      <Collapse.Panel key={studioId}
                      className="Studio"
                      header={this.getStudioCollapseHeader(studioId, name, deleteAll)}>
        {this.renderStateIndicator({studioId}, studio)}
        <div className="MovieCollapse-Container">
          <MoviePlayerConfigCollapse
            movies={movies || {}}
            studioId={studioId}
            propertyId={propertyId}
            onRemoveMovie={onRemoveMovie}
            onMoviePlayerChange={this.props.onMoviePlayerChange}
          />
        </div>
      </Collapse.Panel>
    );
  };

  render() {
    const {studios} = this.props;
    const data = Object.values(studios);
    const keys = Object.keys(studios);
    return (
      <Collapse
        className="StudioPlayerConfigCollapse"
        defaultActiveKey={keys}>
        {data.map(this.studioMap)}
      </Collapse>
    );
  }
}

StudioPlayerConfigCollapse.propTypes = {
  onMoviePlayerChange: PropTypes.func.isRequired,
  onRemoveMovie: PropTypes.func.isRequired,
  onRemoveStudio: PropTypes.func.isRequired,
  onStudioPlayerChange: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  removeStudioConfiguration: PropTypes.func.isRequired,
  setSelectedMovies: PropTypes.func.isRequired,
  studios: PropTypes.object.isRequired
};

export default withTranslation()(StudioPlayerConfigCollapse);
