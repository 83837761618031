import React, {Component} from 'react';

import BaseCheckboxList from "../../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList";

import './CatalogSelectAbstract.scss';

class CatalogSelectAbstract extends Component {
  filterDelay = 300;

  state = {
    selectedRowKeys: [],
    items: []
  };

  constructor(props) {
    super(props);
    Object.assign(this.state, {
      selectedRowKeys: props.checked || [],
      items: props.data || []
    });
  }

  componentDidMount() {
    this.setCheckedItems();
  }

  getLabel = () => {
    console.log('Abstract function definition. Please implement getLabel');
  };

  getItemsIds = ({id}) => id;

  getTitle = () => {
    console.log('Abstract function definition. Please implement getTitle');
  };

  onSelect = (selectedKeys) => {
    this.setState({
      selectedRowKeys: selectedKeys
    });
    this.props.onItemSelect(selectedKeys);
  };

  setCheckedItems = () => {
    const {checked} = this.props;
    this.setState({
      selectedRowKeys: checked || [],
    });
  };

  render() {
    const {showSearch} = this.props;
    const {items, selectedRowKeys} = this.state;
    return (
      <div className="CatalogSelectAbstract">
        <div className="Label">{this.getLabel()}</div>
        <BaseCheckboxList showSelectAll={true}
                          checked={selectedRowKeys}
                          onChange={this.onSelect}
                          items={items}
                          showSearch={showSearch}/>
      </div>
    );
  }
}

export default CatalogSelectAbstract;