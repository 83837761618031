import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Router } from "@reach/router";
import { Button, Icon } from "antd";
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import {
  editMovie, editMovie2257,
  getMovieById,
  setMovieEnabled,
  syncMovie,
  syncScene
} from "../../../../../../../../../services/movies-service/movies.service";
import {
  showMovieSceneContentInfo,
  showMovieSceneDetails,
  showMovieContentInfo
} from "../../../../../../../../../services/navigation/movies-navigation/movies-navigation.service";
import {
  getMovieDetailsRoute,
  getMoviesRoute
} from "../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";
import { getProperties } from "../../../../../../../../../services/properties-service/properties.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";
import { deleteMovieImage, uploadMovieImage } from "../../../../../../../../../services/images-service/images.service";

import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";
import { MovieDetailsCommController, MovieDetailsEventMap } from "./movie-details-comm.controller";
import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import BaseNavigationItem from '../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem';
import DeleteImagesModal from "../../../../../../../../../components/modal/DeleteImagesModal/DeleteImagesModal";
import EditMovieModal from "./EditMovieModal/EditMovieModal";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import MovieDetailsMainView from "./MovieDetailsMainView/MovieDetailsMainView";
import MovieContentInfo from "./MovieContentInfo/MovieContentInfo";
import MovieSceneContentInfo from "./MovieSceneContentInfo/MovieSceneContentInfo";
import SelectSubCategoriesModal from "./SelectSubCategoriesModal/SelectSubCategoriesModal";
import UploadMovieImagesModal from "./UploadMovieImagesModal/UploadMovieImagesModal";
import SceneDetailsView from "./SceneDetailsView/SceneDetailsView";

import './MovieDetails.scss';

/* istanbul ignore file */
class MovieDetails extends Component {

  imageDeleting = false;

  state = {
    activeTab: '1',
    data: {
      images: []
    },
    dataLoaded: false,
    loadingMsg: '',
    sceneId: 0,
    hasError: false
  };

  subscriptions = {
    movie: {},
    scene: {}
  };

  constructor(props) {
    super(props);
    Object.assign(this.subscriptions.movie, {
      deleteImages: MovieDetailsCommController.subscribeToEvent('movie', MovieDetailsEventMap.movie.deleteImagesEvent, this.deleteImages),
      edit: MovieDetailsCommController.subscribeToEvent('movie', MovieDetailsEventMap.movie.editEvent, this.editMovie),
      selectSubCategories: MovieDetailsCommController.subscribeToEvent('movie', MovieDetailsEventMap.movie.selectSubCategoriesEvent, this.showSelectSubCategoriesModal),
      sync: MovieDetailsCommController.subscribeToEvent('movie', MovieDetailsEventMap.movie.syncEvent, this.syncMovie),
      uploadImages: MovieDetailsCommController.subscribeToEvent('movie', MovieDetailsEventMap.movie.uploadImagesEvent, this.uploadImages)
    });
    Object.assign(this.subscriptions.scene, {
      details: MovieDetailsCommController.subscribeToEvent('scene', MovieDetailsEventMap.scene.detailsEvent, this.showSceneDetails),
      edit: MovieDetailsCommController.subscribeToEvent('scene', MovieDetailsEventMap.scene.editEvent, this.updateSceneData),
      sceneImageUploadFinished: MovieDetailsCommController.subscribeToEvent('scene', MovieDetailsEventMap.scene.sceneImageUploadFinishedEvent, this.fetchData),
      sceneImagesDeleteFinishedEvent: MovieDetailsCommController.subscribeToEvent('scene', MovieDetailsEventMap.scene.sceneImagesDeleteFinishedEvent, this.fetchData),
      sync: MovieDetailsCommController.subscribeToEvent('scene', MovieDetailsEventMap.scene.syncEvent, this.syncScene),
      viewContentInfo: MovieDetailsCommController.subscribeToEvent('scene', MovieDetailsEventMap.scene.viewContentInfoEvent, this.viewSceneContentInfo)
    });
    const { location, uri } = props;
    const { pathname } = location;
    const scenePath = pathname.replace(uri, '');
    if (scenePath) {
      const split = scenePath.split('/');
      this.state.sceneId = split[2] === 'details' ? split[3] : split[2];
    }
  }

  componentDidMount() {
    this.fetchData();
  };

  componentWillUnmount() {
    this.cleanupEvents('movie');
    this.cleanupEvents('scene');
  };

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  cleanupEvents = (type) => {
    for (let property in this.subscriptions[type]) {
      this.subscriptions[type][property].unsubscribe();
    }
  };

  deleteImages = () => {
    const modal = (
      <DeleteImagesModal images={this.state.data.images}
        deleteImage={this.mapDeleteImagePromise}
        onDeleteSuccess={this.onDeleteSuccess} />
    );
    ModalController.showModal(modal);
  };

  editMovie = () => {
    const modal = (
      <EditMovieModal movieId={this.props.movieId}
        movieName={this.state.data.title}
        onMovieEdit={this.fetchData} />
    );
    ModalController.showModal(modal);
  };

  fetchData = (silently, callback) => {
    if (!silently) {
      const message = this.props.t('MovieDetails.pleaseWait');
      this.setLoading(message);
    }
    const { movieId } = this.props;
    const promises = [
      getMovieById(movieId, 'DESC', 1, 0, 1, 1),
      getProperties()
    ];
    Promise.all(promises)
      .then(this.loadData.bind(this, callback))
      .catch(this.onRequestFailure);
  };

  getCustodianOfRecordsPromise = (id, custodianData) => {
    return custodianData ? editMovie2257({ id, ...custodianData }) : Promise.resolve();
  };

  getMovieEnablePromise = (id, enable) => {
    let promise;
    if (enable !== undefined) {
      promise = setMovieEnabled(id, enable);
    } else {
      promise = Promise.resolve();
    }

    return promise;
  };

  getMovieActions = () => {
    const { movieId, t, location } = this.props;
    let actions = [];
    if (location.pathname === getMovieDetailsRoute(movieId)) {
      actions = [
        <Button onClick={this.syncMovie}
          key="syncMovie">
          <Icon type="sync" />
          {t('MovieDetails.syncMovie')}
        </Button>,
        <Button onClick={this.deleteImages}
          key="deleteImages">
          <Icon type="delete" />
          {t('MovieDetails.deleteImages')}
        </Button>,
        <Button onClick={this.uploadImages}
          key="uploadLogo">
          <Icon type="picture" />
          {t('MovieDetails.uploadImages')}
        </Button>,
        <Button onClick={this.showSelectSubCategoriesModal}
          key="assignRemoveSubCategories">
          <Icon type="picture" />
          {t('MovieDetails.assignRemoveSubCategories')}
        </Button>,
        <Button onClick={this.showMovieContentInfo}
          key="movieContentInfo">
          <Icon type="book" />
          {t('MovieDetails.contentInfo')}
        </Button>
      ];
    }

    return actions;
  };

  getRoutes = () => {
    const { movieId, t, location } = this.props;
    const { data = {}, sceneId = '' } = this.state;
    const movieName = data.title || '';
    const pathname = location.pathname || '';

    const routes = [{
      url: getMoviesRoute(),
      text: t('MovieDetails.movies')
    }, {
      text: movieName
    }];

    if (pathname.includes('/scenes/details/')) {
      routes
        .splice(1, 1, {
          url: getMovieDetailsRoute(movieId),
          text: movieName
        }, {
          text: t("MovieDetails.sceneDetails")
        })
    } else if (pathname.includes('/movies/') && !pathname.includes('/scenes/') && pathname.includes('/contentInfo')) {
      routes
        .splice(1, 1, {
          url: getMovieDetailsRoute(movieId),
          text: movieName
        }, {
          text: t(`MovieDetails.movieContentInfo`, { name: movieName }),
        })
    } else if (pathname.includes('/scenes/') && pathname.includes('/contentInfo')) {
      routes
        .splice(1, 1, {
          url: getMovieDetailsRoute(movieId),
          text: movieName
        }, {
          text: t("MovieDetails.sceneContentInfo", { sceneId })
        })
    }

    return routes;
  };

  getSelectedSubCategoriesIds = () => {
    return this.state.data.subcategories.map(this.getSubCategoryId);
  };

  getSubCategoryId = (subcategory) => {
    return subcategory.id;
  };

  loadData = (callback, values) => {
    const { data } = values[0].data;
    const { properties } = values[1].data.data;
    data.allProperties = properties.sort(this.sortByName);
    let state = {};
    MovieDetailsCommController.setMovieData(data);

    this.setDocumentTitle(data);

    if (this.imageDeleting && data.images && data.images.length === 0) {
      state.activeTab = '1';
    }

    data.images.sort((a, b) => b.width - a.width);

    Object.assign(state, {
      data,
      dataLoaded: true,
      loadingMsg: ''
    });

    this.setState(state);

    this.imageDeleting = false;
    if (callback) {
      callback();
    }
  };

  mapDeleteImagePromise = (id) => {
    const { movieId } = this.props;
    return deleteMovieImage(movieId, id);
  };

  onDeleteSuccess = () => {
    this.imageDeleting = true;
    this.fetchData();
  };

  onMovieEdit = (data, custodianData, callback) => {
    const message = this.props.t('MovieDetails.editInProgress');
    this.setLoading(message);

    // Loader position on studio/movies and property/movies page, CSS failed
    const { starId, studioId, licensorId } = this.props;
    if (starId || studioId || licensorId) {
      const container = document.querySelector('.MovieDetailsMainView');
      let loader;
      const intervalID = setInterval(() => {
        loader = document.querySelector('.LoadingIndicator');
        if (loader) {
          loader.style.height = container.getBoundingClientRect().height + 'px';
          clearInterval(intervalID);
        }
      }, 500);
    }

    const { id, enable } = data;
    delete data.enable;
    let promises = [
      editMovie(id, data),
      this.getMovieEnablePromise(id, enable),
      this.getCustodianOfRecordsPromise(id, custodianData)
    ];
    Promise.all(promises)
      .then(this.onMovieEditSuccess.bind(this, callback))
      .catch(this.onRequestFailure);
  };

  onMovieEditSuccess = (callback) => {
    this.setLoading('');
    this.showNotification('editSuccess', {}, true);
    this.fetchData(false, callback);
    this.props.refreshContent();
  };

  onMovieSyncFailure = () => {
    this.showNotification('movieSyncFailure', {}, false);
    this.setLoading();
  };

  onMovieSyncSuccess = () => {
    this.showNotification('movieSyncSuccess', {}, true);
    this.setLoading();
  };

  onRequestFailure = (error = {}) => {
    displayErrorNotification({
      duration: 6,
      message: error.response?.data?.error || 'Edit failed'
    });
    this.setState({
      hasError: true,
      dataLoaded: true,
      loadingMsg: ''
    });
    LogController.logError(error);
  };

  onSetMovieEnabledFailure = (enabled) => {
    this.setLoading('');
    const { t } = this.props;
    const key = enabled === 1 ? 'enableFail' : 'disableFail';
    displayErrorNotification({
      duration: 3,
      message: t(`MovieDetails.${key}`)
    });
  };

  onSetMovieEnabledSuccess = (id, data) => {
    const { enable } = data;
    const key = enable === 1 ? 'enableSuccess' : 'disableSuccess';
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t(`MovieDetails.${key}`)
    });
    this.updateMovieDetails(id, data);
  };

  onSyncSceneFailure = () => {
    this.showNotification('sceneSyncFailure', {}, false);
    this.setLoading();
  };

  onSyncSceneSuccess = (sceneIndex) => {
    this.showNotification('sceneSyncSuccess', {
      sceneIndex: sceneIndex
    }, true);
    this.setLoading();
  };

  onUploadImages = (file, type) => {
    const { movieId } = this.props;
    return uploadMovieImage(movieId, type, file);
  };

  setDocumentTitle = (data) => {
    const title = this.props.t('MovieDetails.documentTitle', { name: data.title });
    DocumentTitleController.setDocumentTitle(title);
  };

  setLoading = (loadingMsg) => {
    this.setState({
      loadingMsg: loadingMsg
    });
  };

  setMovieEnabled = (id, enabled) => {
    const { t } = this.props;
    const message = t('MovieDetails.updatingStatus');
    this.setLoading(message);
    const data = {
      enable: enabled
    };
    setMovieEnabled(id, enabled)
      .then(this.onSetMovieEnabledSuccess.bind(this, id, data))
      .catch(this.onSetMovieEnabledFailure.bind(this, enabled));
  };

  showMovieContentInfo = () => {
    const { movieId } = this.props;
    showMovieContentInfo(movieId);
  };

  showMovieSceneContentInfo = () => {
    const { movieId } = this.props;
    const { sceneId } = this.state;
    showMovieSceneContentInfo(movieId, sceneId);
  };

  showNotification = (key, data, success) => {
    const { t } = this.props;
    const notificationData = {
      duration: 3,
      message: t(`MovieDetails.${key}`, data)
    };
    if (success) {
      displaySuccessNotification(notificationData);
    } else {
      displayErrorNotification(notificationData);
    }
  };

  showSelectSubCategoriesModal = () => {
    const { movieId } = this.props;
    const modal = (
      <SelectSubCategoriesModal movieId={movieId}
        categories={this.state.data.categories}
        subcategories={this.state.data.subcategories}
        defaultSelection={this.getSelectedSubCategoriesIds()}
        onSubCategoriesUpdated={this.fetchData} />
    );
    ModalController.showModal(modal);
  };

  showSceneDetails = ({ sceneId }) => {
    this.setState({ sceneId });
    const { movieId } = this.props;
    showMovieSceneDetails(movieId, sceneId);
  };

  sortByName = ({ name }, { name: name1 }) => {
    let order = 0;
    if (name > name1) {
      order = 1;
    } else if (name < name1) {
      order = -1;
    }

    return order;
  };

  syncMovie = () => {
    const message = this.props.t('MovieDetails.syncMovieInProgress');
    this.setLoading(message);
    const { movieId } = this.props;
    syncMovie(movieId)
      .then(this.onMovieSyncSuccess)
      .catch(this.onMovieSyncFailure);
  };

  syncScene = ({ sceneId, sceneIndex }) => {
    const message = this.props.t('MovieDetails.syncSceneInProgress');
    this.setLoading(message);
    const { movieId } = this.props;
    syncScene(movieId, sceneId)
      .then(this.onSyncSceneSuccess.bind(this, sceneIndex))
      .catch(this.onSyncSceneFailure);
  };

  uploadImages = () => {
    const modal = (
      <UploadMovieImagesModal uploadImages={this.onUploadImages}
        onUploadFinished={this.fetchData} />
    );
    ModalController.showModal(modal);
  };

  updateMovieDetails = (id, data) => {
    this.setLoading('');
    this.setState(prevState => {
      const newData = cloneDeep(prevState.data);
      Object.assign(newData, data);
      this.props.updateMovieData(id, data);
      return {
        data: newData
      };
    });
  };

  updateSceneData = () => {
    this.fetchData();
  };

  viewSceneContentInfo = ({ sceneId }) => {
    this.setState({ sceneId }, this.showMovieSceneContentInfo);
  };

  renderBreadCrumbs = () => {
    const { starId, licensorId, propertyId, studioId } = this.props;

    if (starId || licensorId || propertyId || studioId) return null;

    return (
      <BaseNavigationItem routes={this.getRoutes()}>
        {this.getMovieActions()}
      </BaseNavigationItem>
    )
  };

  render() {
    const { movieId } = this.props;
    const { activeTab, data, hasError } = this.state;
    return (
      <LoadingWrapper className='MovieDetails'
        dataLoaded={this.state.dataLoaded}
        isLoading={!!this.state.loadingMsg}
        loadingMsg={this.state.loadingMsg}>
        {this.renderBreadCrumbs()}
        <Router>
          <MovieDetailsMainView path={'/'}
            movieId={movieId}
            data={data}
            activeTab={activeTab}
            onSetMovieEnabled={this.setMovieEnabled}
            onTabChange={this.changeTab}
            setLoading={this.setLoading}
            onDeleteImage={this.onDeleteSuccess}
            onImageEdit={this.fetchData}
            onMovieEdit={this.onMovieEdit}
            scenesAreEpisodes={data.scenes_are_episodes}
            properties={data.allProperties}
            hasError={hasError}
          />
          <SceneDetailsView path={"/scenes/details/:sceneId"}
            scenes={data.scenes}
            viewingMovieId={movieId}
            scenesAreEpisodes={data.scenes_are_episodes}
            setLoading={this.setLoading}
            fetchData={this.fetchData}
            properties={data.allProperties}
          />
          <MovieSceneContentInfo path={'/scenes/:sceneId/contentInfo'} />
          <MovieContentInfo path={'/contentInfo'} />
        </Router>
      </LoadingWrapper>
    );
  }
}

MovieDetails.propTypes = {
  getMoviesRoute: PropTypes.func.isRequired,
  movieId: PropTypes.string
};

export default withTranslation()(MovieDetails);
