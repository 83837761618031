import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button} from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../ModalDialog/ModalDialog";

import './AboutModal.scss';

class AboutModal extends Component {

  modalRef = React.createRef();

  closeModal = () => {
    this.getModal().closeModal();
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <Button onClick={this.closeModal}>
        {t('AboutModal.close')}
      </Button>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('AboutModal.appInfo');
  };

  render() {
    const {appVersion, t} = this.props;
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}>
        <div className="AboutModal">
          <div className="AboutModal-description">{t('AboutModal.description')}</div>
          <div className="AboutModal-inner">
            <div>{t('AboutModal.version')}: {appVersion}</div>
            <div>{t('copyright')}</div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}

AboutModal.propTypes = {
  appVersion: PropTypes.string.isRequired
};

export default withTranslation()(AboutModal);
