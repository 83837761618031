import React, {Component} from 'react'
import Draggable from "react-draggable";

export default (WrappedComponent, handleDrag, dragElClassName) => {

  return class extends Component {
    state = {
      activeDrags: 0
    };

    onStart = () => {
      this.setState(prevState => {
        return {
          activeDrags: ++prevState.activeDrags
        };
      });
    };

    onStop = () => {
      this.setState(prevState => {
        return {
          activeDrags: --prevState.activeDrags
        };
      });
    };

    render() {
      const {forwardedRef, defaultPosition} = this.props;
      const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
      return (
        <Draggable bounds="body" {...dragHandlers} handle={handleDrag}
                   defaultPosition={defaultPosition}
                   defaultClassName={dragElClassName}>
          <div className="box">
            <WrappedComponent {...this.props} ref={forwardedRef}/>
          </div>
        </Draggable>
      );
    }
  };
};
