import React, {Component} from 'react';
import {Table} from "antd";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import {
  renderListClickableItems,
  renderListClickableItem
} from "../../../../../../../../../../../../services/util-service/util.service";
import {getLicensorDetailsRoute} from "../../../../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation-routes.service";
import {showLicensorDetails} from "../../../../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation.service"
import {getManufacturerDetailsRoute} from "../../../../../../../../../../../../services/navigation/manufacturers-navigation/manufacturers-navigation-routes.service";
import {showManufacturerDetails} from "../../../../../../../../../../../../services/navigation/manufacturers-navigation/manufacturers-navigation.service";
import {getStudioDetailsRoute} from "../../../../../../../../../../../../services/navigation/studios-navigation/studios-navigation-routes.service";
import {showStudioDetails} from "../../../../../../../../../../../../services/navigation/studios-navigation/studios-navigation.service";

import './CardSection.scss';

class CardSection extends Component {

  //Do not move this because of the columns render functions
  renderNameColumn = (item) => {
    const {t} = this.props;
    return t(`CardSection.${item}`);
  };

  renderValueColumn = (item, data) => {
    let view;
    switch (data.name) {
      case 'studios':
        view = renderListClickableItems(item, showStudioDetails, 'studios', getStudioDetailsRoute, this.mapStudio);
        break;
      case 'manufacturer':
        view = renderListClickableItem(item, showManufacturerDetails, 'manufacturer', getManufacturerDetailsRoute, '');
        break;
      case 'licensor':
        view = renderListClickableItem(item, showLicensorDetails, 'licensor', getLicensorDetailsRoute);
        break;
      default:
        view = item;
        break;
    }

    return view;
  };
  //Do not move this above because of the columns render functions

  columns = [{
    dataIndex: 'name',
    key: 'name',
    render: this.renderNameColumn,
    width: 100
  }, {
    dataIndex: 'value',
    key: 'value',
    render: this.renderValueColumn,
    width: 500
  }];

  getTableTitle = () => {
    return this.props.title;
  };

  mapStudio = ({name, status}) => {
    const {t} = this.props;
    return status ? name : `${name} (${t('CardSection.offline')})`
  };

  render() {
    return (
      <div className='CardSection'>
        <Table dataSource={this.props.data}
               columns={this.columns}
               pagination={false}
               showHeader={false}
               rowKey="name"
               bordered
               title={this.props.title && this.getTableTitle}/>
      </div>
    );
  }
}

CardSection.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default withTranslation()(CardSection);
