import axios from 'axios';

export const urls = {
  userStatus: '/users/switcher',
  users: '/users',
  usersOrientation: '/users/orientation',
  royaltiesUsers: 'royalties/users'
};

/**
 * Executes add user http request
 *
 * @param {Object} data
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const addUser = (data) => {
  return axios.post(urls.users, data);
};

export const addRoyaltiesUser = (data) => {
  return axios.post(urls.royaltiesUsers, data);
};

export const attachUserOrientation = (orientations) => {
  return axios.patch(`${urls.users}/orientation_attach`, null, {
    params: {
      orientations_id: orientations
    }
  });
};

export const cleanupUserData = () => {
  localStorage.removeItem('userData');
};

/**
 * Executes delete user http request
 *
 * @param {string} id
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const deleteUserById = (id) => {
  return axios.delete(`${urls.users}/${id}`);
};

/**
 * Executes get user by id http request
 *
 * @param {string} id
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getUserById = (id) => {
  return axios.get(`${urls.users}/${id}`);
};

export const getUserOrientations = (userId) => {
  return axios.get(`${urls.usersOrientation}/${userId}`)
};

/**
 * Returns user data from localStorage or an empty object if not user data in storage
 *
 * @returns {Object}
 */
export const getUserLocalData = () => {
  const userData = localStorage.getItem('userData');
  return userData ? JSON.parse(userData) : {};
};

/**
 * Executes get users http request
 *
 * @param {number} page
 * @param {String[]} filters
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getUsers = (page, filters, signal = null) => {
  return filters.length ? getFilteredUsers(page, filters, signal) : getUsersPage(page, signal);
};

export const getUsersCancelable = (page, filters) => {
  const controller = new AbortController();
  return {
    result: getUsers(page, filters, controller.signal),
    controller
  };
};

/**
 * Executes get filtered users http request
 *
 * @param {number} page
 * @param {String[]} filters
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getFilteredUsers = (page, filters, signal) => {
  const data = signal ? { signal } : {};
  return axios.get(`${urls.users}/filters?${filters.join('&')}&page=${page}`, data);
};

/**
 * Executes get users page http request
 *
 * @param {number} page
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getUsersPage = (page, signal) => {
  const data = signal ? { signal } : {};
  return axios.get(`${urls.users}?page=${page}`, data);
};

/**
 * Executes patch user http request
 *
 * @param {string} id
 * @param {Object} data
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const patchUser = (id, data) => {
  return axios.patch(`${urls.users}/${id}`, data);
};

/**
 * Removes user data from localStorage
 */
export const removeUserLocalData = () => {
  localStorage.removeItem('userData');
};

export const setUserDisabled = id => {
  return axios.get(
    `${urls.userStatus}/${id}/disable`
  );
};

export const setUserEnabled = id => {
  return axios.get(
    `${urls.userStatus}/${id}/enable`
  );
};

export const setRoyaltiesUserDisabled = id => {
  return axios.get(
    `${urls.royaltiesUsers}/${id}/disable`
  );
};

export const setRoyaltiesUserEnabled = id => {
  return axios.get(
    `${urls.royaltiesUsers}/${id}/enable`
  );
};



/**
 * Sets user data to localStorage
 *
 * @param {Object} data
 */
export const setUserLocalData = (data) => {
  localStorage.setItem('userData', JSON.stringify(data));
};

export const toggleEnableStatus = (id, enabled) => {
  return enabled ? setUserEnabled(id) : setUserDisabled(id);
};

export const toggleRoyaltiesUserEnabled = (id, enabled) => {
  return axios.patch(
    // `${urls.royaltiesUsers}/${id}, ${enabled}`
    `${urls.royaltiesUsers}/${id}?enable=${enabled}`
  );
};

/**
 * Executes put user data http request
 *
 * @param {string} id
 * @param {Object} data
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const updateUser = (id, data) => {
  return axios.put(`${urls.users}/${id}`, data);
};
