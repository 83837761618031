import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "antd";
import { cloneDeep } from "lodash";

import ErrorsFoundDialog from "../../ErrorsFoundDialog/ErrorsFoundDialog";
import ModalDialog from "../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import MovieSelectionModal from "../MovieSelectionModal/MovieSelectionModal";
import MoviesCollapsePanel from "./MoviesCollapsePanel/MoviesCollapsePanel";

import { ConfigureMoviesAdsController } from "./ConfigureMoviesAdsController";
import { LogController } from "../../../../../../../../../../../controllers/log-controller/log.controller";
import { ModalController } from "../../../../../../../../../../../controllers/modal-controller/modal.controller";

import { getMovieById } from "../../../../../../../../../../../services/movies-service/movies.service";
import {
	displayErrorNotification,
	displaySuccessNotification,
} from "../../../../../../../../../../../services/notification-service/notification.service";
import {
	getAdsConfig,
	removeAdsConfig,
	setAdsConfig,
} from "../../../../../../../../../../../services/ads-configuration-service/ads-configuration.service";

import "./MainDialog.scss";

/* istanbul ignore file */
class MainDialog extends Component {
	deletedScenes = {};

	modalRef = React.createRef();

	previousConfigScenes = {};

	state = {
		invalidKeys: {},
		loadingMovieAds: false,
		selectedMovies: {},
	};

	componentDidMount() {
		this.displayMovieSelectionModal();
	}

	addAdKey = (ad, index) => {
		ad.key = index;
		return ad;
	};

	areAdsInConflict = ({ start: start1 }, { start: start2 }) => {
		return start1 === start2;
	};

	clearLoading = () => {
		this.getModal().clearLoading();
	};

	clearPreviouslyDeletedScenesKeys = (adsByScenes) => {
		for (const sceneId in adsByScenes) {
			delete this.deletedScenes[sceneId];
		}
	};

	closeModal = () => {
		return this.getModal().closeModal();
	};

	collapseAll = () => {
		ConfigureMoviesAdsController.expandAll(false);
	};

	deriveMovieAdsData = (selectedMovies, previousMovies, values) => {
		const newData = {};
		const ads = values[0];
		const moviesData = values[1];
		let index;
		let previousAdsConfig;
		for (const movieId in selectedMovies) {
			index = ads.findIndex(this.matchAdsByMovieId.bind(this, +movieId));
			if (index !== -1) {
				previousAdsConfig = ads[index].data.data.ads_config;
				previousAdsConfig = {
					...previousAdsConfig,
					id: ads[index].data.data.id,
				};
				if (previousAdsConfig && previousAdsConfig.properties) {
					newData[movieId] = this.mapMovieAdsInitialDataByProperty(
						selectedMovies[movieId],
						previousAdsConfig,
						moviesData[index].data.data
					);
				} else {
					newData[movieId] = selectedMovies[movieId];
				}
			} else {
				newData[movieId] = selectedMovies[movieId];
			}
		}

		return newData;
	};

	mapMovieAdsInitialDataByScene = (scenes) => {
		const mappedData = {};
		if (scenes) {
			let item = 0;
			let itemCount = scenes.length;
			let scene;
			while (item < itemCount) {
				scene = scenes[item];
				this.previousConfigScenes[scene.sceneId] = true;
				mappedData[scene.sceneId] = scene.ads
					? scene.ads.map(this.addAdKey)
					: [];
				item++;
			}
		}

		return mappedData;
	};

	mapMovieAdsInitialDataByProperty = (
		selectionData,
		{ movieId, id: adId, properties },
		movieDetails
	) => {
		const mappedData = cloneDeep(selectionData);
		Object.assign(mappedData, {
			infos: {
				details: movieDetails,
			},
			hasPreviousConfig: true,
			movieId,
			adId,
			properties: {},
		});
		let item = 0;
		let itemCount = properties.length;
		let property;
		while (item < itemCount) {
			property = properties[item];
			mappedData.properties[property.propertyId] = {
				id: property.propertyId,
				hasPreviousConfig: true,
				name: property.name,
				scenes: this.mapInitialAds(property, movieDetails),
			};
			item++;
		}

		return mappedData;
	};

	mapInitialAds = ({ movieAds, scenes }, { movieId }) => {
		let data = {};
		if (movieAds) {
			data = this.mapMovieAdsInitialDataByScene([
				{
					ads: movieAds,
					sceneId: movieId,
				},
			]);
		}
		Object.assign(data, this.mapMovieAdsInitialDataByScene(scenes));

		return data;
	};

	displayErrorsFoundDialog = () => {
		const modal = <ErrorsFoundDialog />;
		ModalController.showModal(modal);
	};

	displayMovieSelectionModal = () => {
		const { selectedMovies } = this.state;
		const modal = (
			<MovieSelectionModal
				onSelectMovies={this.onSelectMovies}
				selectedMovies={selectedMovies}
			/>
		);
		ModalController.showModal(modal);
	};

	expandAll = () => {
		ConfigureMoviesAdsController.expandAll(true);
	};

	getActions = () => {
		const { t } = this.props;
		const { selectedMovies } = this.state;
		let addMoviesBtn = null;
		const classes = ["MainDialog-actions"];
		if (Object.keys(selectedMovies).length) {
			addMoviesBtn = this.getAddMoviesButton();
			classes.push("Spaced");
		}
		return (
			<div className={classes.join(" ")}>
				{addMoviesBtn}
				<div>
					<Button onClick={this.closeModal}>
						{t("ConfigureAdsOnMovieLevel.MainDialog.cancel")}
					</Button>
					<Button onClick={this.saveAdsConfig}>
						{t("ConfigureAdsOnMovieLevel.MainDialog.saveAdsConfig")}
					</Button>
				</div>
			</div>
		);
	};

	getAddMoviesButton = (isLink) => {
		const { t } = this.props;
		const props = {
			onClick: this.displayMovieSelectionModal,
		};
		if (isLink) {
			props.type = "link";
		}
		return (
			<Button {...props}>
				{t("ConfigureAdsOnMovieLevel.MainDialog.addMovies")}
			</Button>
		);
	};

	getInvalidKeys = (entryData, processingFunction, subProperty) => {
		const data = {};
		let invalidKeys;
		let subData;
		for (const key in entryData) {
			subData = subProperty
				? entryData[key][subProperty]
				: entryData[key];
			invalidKeys = processingFunction(subData, key);
			if (Object.keys(invalidKeys).length) {
				if (subProperty) {
					if (!data[key]) {
						data[key] = {};
					}
					data[key][subProperty] = invalidKeys;
				} else {
					data[key] = invalidKeys;
				}
			}
		}

		return data;
	};

	getMovieDataForPost = ({ infos, movieId, properties }) => {
		let data;
		const movieProperties = this.mapMoviePropertyForSave(infos, properties);
		if (movieProperties.length) {
			data = {
				movieId: movieId,
				properties: movieProperties,
			};
		}

		return data;
	};

	getMoviesDiff = (movies, previousMovies) => {
		const diff = [];
		for (const movieId in movies) {
			if (!previousMovies[movieId]) {
				diff.push(movieId);
			}
		}

		return diff;
	};

	getMoviesInvalidKeys = (selectedMovies) => {
		return this.getInvalidKeys(
			selectedMovies,
			this.getPropertiesInvalidKeys,
			"properties"
		);
	};

	getNewMoviesAds = (ids) => {
		const promises = ids.map((id) => getAdsConfig({ movieId: id }));
		return Promise.all(promises);
	};

	getNewMoviesDetails = (ids) => {
		const promises = ids.map((id) => getMovieById(id));
		return Promise.all(promises);
	};

	getPropertiesInvalidKeys = (properties) => {
		return this.getInvalidKeys(
			properties,
			this.getScenesInvalidKeys,
			"scenes"
		);
	};

	getScenesInvalidKeys = (scenes) => {
		return this.getInvalidKeys(scenes, this.getSceneInvalidKeys, "");
	};

	getSceneInvalidKeys = (sceneAds, sceneId) => {
		const data = {};
		if (!this.deletedScenes[sceneId]) {
			let item1 = 0;
			let item2 = 0;
			const adsCount = sceneAds.length;
			let ad1;
			let ad2;
			while (item1 < adsCount) {
				ad1 = sceneAds[item1];
				item2 = item1 + 1;
				while (item2 < adsCount) {
					ad2 = sceneAds[item2];
					if (this.areAdsInConflict(ad1, ad2)) {
						if (!data[item1]) {
							data[item1] = [];
						}
						data[item1].push(item2);
					}
					item2++;
				}
				item1++;
			}
		}
		return data;
	};

	getModal = () => {
		return this.modalRef.current;
	};

	getMovieById = (movies, movieId) => movies[movieId];

	getPropertyById = (movies, movieId, propertyId) => {
		const movie = this.getMovieById(movies, movieId);
		return movie.properties[propertyId];
	};

	getSceneById = (movies, movieId, propertyId, sceneId) => {
		const property = this.getPropertyById(movies, movieId, propertyId);
		return property.scenes[sceneId];
	};

	getTitle = () => {
		return (
			<div className="Title">
				Configure movies ads
				<div>
					<Button onClick={this.expandAll}>Expand All</Button>
					<Button onClick={this.collapseAll}>Collapse All</Button>
				</div>
			</div>
		);
	};

	mapMovieAdsConfigSavePromise = (movieId, data) => {
		const id = this.state.selectedMovies[movieId].adId;
		if (!data) return removeAdsConfig(id);
		return setAdsConfig({ movieId: movieId, config: data });
	};

	mapMovieAdsConfigSavePromises = () => {
		const promises = [];
		const { selectedMovies } = this.state;
		let movieData;
		for (const movieId in selectedMovies) {
			if (!selectedMovies[movieId].deleteAll) {
				movieData = this.getMovieDataForPost(selectedMovies[movieId]);
				if (movieData) {
					promises.push(
						this.mapMovieAdsConfigSavePromise(movieId, movieData)
					);
				} else if (selectedMovies[movieId].hasPreviousConfig) {
					promises.push(
						this.mapMovieAdsConfigSavePromise(movieId, undefined)
					);
				}
			} else {
				promises.push(
					this.mapMovieAdsConfigSavePromise(movieId, undefined)
				);
			}
		}

		return promises;
	};

	mapMoviePropertyForSave = (infos, properties) => {
		const data = [];
		let adsData;
		for (const propertyId in properties) {
			if (!properties[propertyId].deleteAll) {
				const { movieAds, scenesAds } = this.mapPropertyScenesForSave(
					infos.details.scenes,
					properties[propertyId].scenes
				);
				if ((scenesAds && scenesAds.length) || movieAds) {
					adsData = {
						propertyId: +propertyId,
						name: properties[propertyId].name,
					};
				}
				if (scenesAds && scenesAds.length) {
					adsData.scenes = scenesAds;
				}
				if (movieAds) {
					adsData.movieAds = movieAds;
				}
				if (adsData) {
					data.push(adsData);
				}
			}
		}
		return data;
	};

	mapPropertyScenesForSave = (movieScenes, scenes) => {
		const data = [];
		let currentAds;
		let movieAds;
		for (const sceneId in scenes) {
			currentAds = scenes[sceneId];
			if (
				!this.deletedScenes[sceneId] &&
				currentAds &&
				currentAds.length
			) {
				if (movieScenes.find(this.matchById.bind(this, +sceneId))) {
					// This is a scene ad
					data.push({
						sceneId,
						ads: scenes[sceneId].map(this.removeAdExcessData),
					});
				} else {
					movieAds = currentAds;
				}
			}
		}

		return {
			movieAds,
			scenesAds: data,
		};
	};

	matchById = (sceneId, { id }) => sceneId === id;

	onAdsConfigured = ({ ads: adsByScene, movieId, propertyId }) => {
		this.setState((prevState) => {
			const selectedMovies = cloneDeep(prevState.selectedMovies);
			const { properties } = selectedMovies[movieId];
			properties[propertyId].deleteAll = false;
			properties[propertyId].scenes = adsByScene;
			this.clearPreviouslyDeletedScenesKeys(adsByScene);
			const invalidKeys = this.getMoviesInvalidKeys(selectedMovies);
			return {
				invalidKeys,
				selectedMovies,
			};
		}, this.triggerValidation);
	};

	onMoviePropertiesSelected = (
		movieId,
		{ movieDetails, movieProperties, selectedProperties }
	) => {
		this.setState((prevState) => {
			const selectedMovies = cloneDeep(prevState.selectedMovies);
			Object.assign(selectedMovies[movieId], {
				deleteAll: false,
				infos: {
					details: movieDetails
						? movieDetails
						: selectedMovies[movieId].infos.details,
					properties: movieProperties,
				},
				properties: this.softJoinObjects(
					selectedMovies[movieId].properties,
					selectedProperties
				),
			});
			const invalidKeys = this.getMoviesInvalidKeys(selectedMovies);
			return {
				invalidKeys,
				selectedMovies,
			};
		}, this.triggerValidation);
	};

	softJoinObjects = (priorityObject, updatedObject) => {
		for (const key in priorityObject) {
			if (updatedObject[key]) {
				updatedObject[key] = priorityObject[key];
			}
		}

		return updatedObject;
	};

	onFetchMoviesAdsFailure = (error) => {
		this.clearLoading();
		this.setState({ loadingMovieAds: false });
		LogController.logError(error);
	};

	onSaveMoviesAdsConfigFailure = (error) => {
		this.clearLoading();
		LogController.logError(error);
		const { t } = this.props;
		displayErrorNotification({
			duration: 3,
			message: t("ConfigureAdsOnMovieLevel.MainDialog.configSaveFailure"),
		});
	};

	onSaveMoviesAdsConfigSuccess = () => {
		const { t } = this.props;
		displaySuccessNotification({
			duration: 3,
			message: t("ConfigureAdsOnMovieLevel.MainDialog.configSaveSuccess"),
		});
		this.closeModal();
	};

	onSceneAdsChange = (movieId, propertyId, sceneId, ads) => {
		this.setState((prevState) => {
			const selectedMovies = cloneDeep(prevState.selectedMovies);
			selectedMovies[movieId].properties[propertyId].scenes[sceneId] =
				ads;
			const invalidKeys = this.getMoviesInvalidKeys(selectedMovies);
			return {
				invalidKeys,
				selectedMovies,
			};
		}, this.triggerValidation);
	};

	onSelectMovies = (selectedMovies) => {
		const { selectedMovies: previousMovies } = this.state;
		const newMoviesIds = this.getMoviesDiff(selectedMovies, previousMovies);
		if (newMoviesIds.length) {
			const message = this.props.t(
				"ConfigureAdsOnMovieLevel.MainDialog.loadingMovieAdsMsg"
			);
			this.setLoading(message);
			this.setState({ loadingMovieAds: true });
			const promises = [
				this.getNewMoviesAds(newMoviesIds),
				this.getNewMoviesDetails(newMoviesIds),
			];
			Promise.all(promises)
				.then(this.updateMoviesState.bind(this, selectedMovies))
				.catch(this.onFetchMoviesAdsFailure);
		} else {
			this.setState({ selectedMovies });
		}
	};

	matchAdsByMovieId = (movieId, response) => {
		const { id: adsMovieId } = response?.data?.data?.movies || {};
		return movieId === adsMovieId;
	};

	removeAdExcessData = ({
		applyToLoggedUser,
		applyToNotLoggedUser,
		selectedTarget,
		duration,
		goToText,
		goToUrl,
		start,
		type,
		url,
		videoType,
	}) => {
		let data = {
			applyToLoggedUser,
			applyToNotLoggedUser,
			selectedTarget: selectedTarget || "_self",
			goToText,
			goToUrl,
			start,
			type,
			url,
		};
		if (type === "image") {
			data.duration = duration;
		} else {
			data.videoType = videoType;
		}

		return data;
	};

	removeMovie = (movieId) => {
		this.setState((prevState) => {
			const selectedMovies = cloneDeep(prevState.selectedMovies);
			if (selectedMovies[movieId].hasPreviousConfig) {
				selectedMovies[movieId].deleteAll = true;
			} else {
				delete selectedMovies[movieId];
			}
			delete selectedMovies?.[movieId]?.properties;
			const invalidKeys = this.getMoviesInvalidKeys(selectedMovies);
			return {
				invalidKeys,
				selectedMovies,
			};
		}, this.triggerValidation);
	};

	removeProperty = (movieId, propertyId) => {
		this.setState((prevState) => {
			const selectedMovies = cloneDeep(prevState.selectedMovies);
			const movie = this.getMovieById(selectedMovies, movieId);
			if (movie.properties[propertyId].hasPreviousConfig) {
				movie.properties[propertyId].deleteAll = true;
			} else {
				delete movie.properties[propertyId];
			}
			delete movie.properties[propertyId].scenes;
			const invalidKeys = this.getMoviesInvalidKeys(selectedMovies);
			return {
				invalidKeys,
				selectedMovies,
			};
		}, this.triggerValidation);
	};

	removeScene = (movieId, propertyId, sceneId) => {
		this.setState((prevState) => {
			const selectedMovies = cloneDeep(prevState.selectedMovies);
			if (this.previousConfigScenes[sceneId]) {
				this.deletedScenes[sceneId] = true;
			}
			this.getPropertyById(selectedMovies, movieId, propertyId).scenes[
				sceneId
			] = [];
			const invalidKeys = this.getMoviesInvalidKeys(selectedMovies);
			return {
				invalidKeys,
				selectedMovies,
			};
		}, this.triggerValidation);
	};

	removeSceneAd = (movieId, propertyId, sceneId, adIndex) => {
		this.setState((prevState) => {
			const selectedMovies = cloneDeep(prevState.selectedMovies);
			this.getSceneById(
				selectedMovies,
				movieId,
				propertyId,
				sceneId
			).splice(adIndex, 1);
			const invalidKeys = this.getMoviesInvalidKeys(selectedMovies);
			return {
				invalidKeys,
				selectedMovies,
			};
		}, this.triggerValidation);
	};

	renderMainView = () => {
		let view = null;
		const { selectedMovies } = this.state;
		if (Object.keys(selectedMovies).length) {
			view = this.renderSelectedMovies();
		} else {
			const isLoading = this.state.loadingMovieAds;
			if (!isLoading) {
				view = this.renderNoSelectMoviesIndicator();
			}
		}

		return view;
	};

	renderNoSelectMoviesIndicator = () => {
		return (
			<div className="NoMovieSelectedIndicator">
				<div className="NoMovieSelectedIndicator-text">
					No movie selected.
				</div>
				{this.getAddMoviesButton(true)}
			</div>
		);
	};

	renderSelectedMovies = () => {
		const { invalidKeys, selectedMovies } = this.state;
		return (
			<MoviesCollapsePanel
				movies={selectedMovies}
				deletedScenes={this.deletedScenes}
				invalidKeys={invalidKeys}
				onConfigurePropertyAds={this.onAdsConfigured}
				onPropertiesUpdated={this.onMoviePropertiesSelected}
				onUpdateSceneAdData={this.updateSceneAdData}
				onRemoveSceneAd={this.removeSceneAd}
				onRemoveProperty={this.removeProperty}
				onRemoveScene={this.removeScene}
				onRemoveMovie={this.removeMovie}
				onSceneAdsChange={this.onSceneAdsChange}
			/>
		);
	};

	saveAdsConfig = () => {
		const { invalidKeys } = this.state;
		if (Object.keys(invalidKeys).length) {
			this.displayErrorsFoundDialog();
		} else {
			const message = this.props.t(
				"ConfigureAdsOnMovieLevel.MainDialog.savingAdsMsg"
			);
			this.setLoading(message);
			const promises = this.mapMovieAdsConfigSavePromises();
			Promise.all(promises)
				.then(this.onSaveMoviesAdsConfigSuccess)
				.catch(this.onSaveMoviesAdsConfigFailure);
		}
	};

	setLoading = (message) => {
		this.getModal().setLoading(message);
	};

	triggerValidation = () => {
		ConfigureMoviesAdsController.triggerValidation();
	};

	updateMoviesState = (selectedMovies, values) => {
		this.clearLoading();
		this.setState((prevState) => {
			const { selectedMovies: previousMovies } = prevState;
			return {
				loadingMovieAds: false,
				selectedMovies: this.deriveMovieAdsData(
					selectedMovies,
					previousMovies,
					values
				),
			};
		});
	};

	updateSceneAdData = (movieId, propertyId, sceneId, index, data) => {
		this.setState((prevState) => {
			const selectedMovies = cloneDeep(prevState.selectedMovies);
			selectedMovies[movieId].properties[propertyId].scenes[sceneId][
				index
			] = data;
			const invalidKeys = this.getMoviesInvalidKeys(selectedMovies);
			return {
				invalidKeys,
				selectedMovies,
			};
		}, this.triggerValidation);
	};

	render() {
		return (
			<ModalDialog
				title={this.getTitle()}
				className="MainDialog"
				actions={this.getActions()}
				forwardedRef={this.modalRef}
			>
				<div className="MainDialog-inner">{this.renderMainView()}</div>
			</ModalDialog>
		);
	}
}

export default withTranslation()(MainDialog);
