import React from 'react';
import {withTranslation} from "react-i18next";

import {getMoviesBySexActId} from "../../../../../../../../../services/movies-service/movies.service";

import MoviesViewAbstract from "../MoviesViewAbstract/MoviesViewAbstract";

import SexActMoviesMainView from "./SexActMoviesMainView/SexActMoviesMainView";

import {getSexActMoviesRoute} from "../../../../../../../../../services/navigation/sexact-navigation/sexact-navigation-routes.service";

class SexActMovies extends MoviesViewAbstract {
  sexActId;

  constructor(props) {
    super(props);
    this.sexActId = props.sexActId;
  }

  fetchMovies = (page) => {
    this.setState({
      loading: true
    });
    const filters = this.getFilters();

    getMoviesBySexActId(this.sexActId, page, filters).then(this.loadMovies);
  };

  getMoviesRoute = () => {
    const {sexActId} = this.props;
    return getSexActMoviesRoute(sexActId);
  };

  renderMainViewRoute = () => {
    const props = this.getMainViewProps();
    props.sexActId = this.sexActId;
    return (
      <SexActMoviesMainView {...props}/>
    );
  };
}

export default withTranslation()(SexActMovies);
