import {navigate} from "@reach/router";

import {getThemesRoute, getThemeMoviesRoute} from "./categories-navigation-routes.service";

/**
 * Navigates to themes view
 *
 * @return {Promise<any>}
 */
export const showThemes = () => {
  return navigate(getThemesRoute());
};

/**
 * Navigates to theme movies view
 *
 * @return {Promise<any>}
 */
export const showThemeMovies = () => {
  return navigate(getThemeMoviesRoute());
};