import React from 'react';
import {useTranslation} from "react-i18next";

import './NotFound.scss';

const NotFound = props => {
  const {t} = useTranslation();
  return (
    <div className="NotFound">
      <div className="MainText">404</div>
      <div className="SecondaryText">
        <div>{t('NotFound.sorry')}</div>
        <p>{t('NotFound.pageNotFound')}</p>
      </div>
    </div>
  );
};

export default NotFound;
