import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import './ConfigurationWillBeRemoved.scss';

/* istanbul ignore file */
const ConfigurationWillBeRemoved = (props) => {
  const {t} = useTranslation();
  const {isFullRemoval} = props;
  const key = isFullRemoval ? 'ConfigurationWillBeRemoved.fullRemovalMsg' : 'ConfigurationWillBeRemoved.configurationRemovalMsg';
  return (
    <div className="ConfigurationWillBeRemoved">
      {t(key)}
    </div>
  );
};

ConfigurationWillBeRemoved.propTypes = {
  isFullRemoval: PropTypes.bool
};

export default ConfigurationWillBeRemoved;
