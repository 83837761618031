import {Subject} from 'rxjs';

export class MoviesViewCommCtrl {
  static instance;
  data = [];
  viewUpdated = new Subject();

  static getInstance() {
    if (MoviesViewCommCtrl.instance == null) {
      MoviesViewCommCtrl.instance = new MoviesViewCommCtrl();
    }

    return MoviesViewCommCtrl.instance;
  }

  triggerUpdate() {
    this.viewUpdated.next();
  }
}

export const MoviesViewCommController = MoviesViewCommCtrl.getInstance();
