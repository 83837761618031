import React, {Component} from 'react';
import {navigate} from "@reach/router";
import {withTranslation} from 'react-i18next';
import {Form, Input, Button, Spin} from 'antd';

import {authLogin, authMe} from '../../../services/auth-service/auth.service';
import {showMainDashboard} from "../../../services/navigation/navigation.service";
import {saveToken} from '../../../services/token-service/token.service';
import {setUserLocalData} from "../../../services/users-service/users.service";

import './SignIn.scss';

class SignInForm extends Component {

  state = {
    formData: {
      password: '',
      username: ''
    },
    errFromResponse: '',
    isValid: false,
    isSubmitting: false,
    loginFailed: false
  };

  /**
   * Clears previous route from localStorage
   */
  clearPreviousRoute = () => {
    localStorage.removeItem('previousRoute');
  };

  /**
   * Finishes login navigation
   */
  finishNavigation = () => {
    let previousRoute = localStorage.getItem('previousRoute');
    if (previousRoute) {
      if (previousRoute === '/') {
        // If previous page is properties then due to the way router is setup its route will be /
        // and forcing the router to navigate to that route again and making out the correct route for redirection
        // can sometimes fail so update previous route to the correct path
        previousRoute = '/main/properties';
      }
      navigate(previousRoute).then(this.clearPreviousRoute);
    } else {
      showMainDashboard();
    }
    this.setIsSubmitting(false);
  };

  /**
   * Returns form item error message
   *
   * @returns {null|JSX.Element}
   */
  getErrorMessage = () => {
    let error = null;
    if (this.state.loginFailed) {
      error = <div className='ErrorMessage'>{this.state.errFromResponse}</div>;
    }
    return error;
  };

  /**
   * Returns the company display text for max view
   *
   * @returns {JSX.Element}
   */
  getCompanyDescription = () => {
    const {t} = this.props;
    return (
      <div className="SignInWrapper-company">
        {t('appName')}
        <div className="SignInWrapper-company-description">
          {t('SignIn.companyDescription')}
        </div>
      </div>
    );
  };

  /**
   * Returns Button if not submitting, Spin otherwise
   *
   * @returns {JSX.Element}
   */
  getSubmitComp = () => {
    const {t} = this.props;
    let comp = (
      <Button
        type='primary'
        htmlType='submit'
        className='login-form-button'
        disabled={!this.state.isValid}>
        {t('SignIn.letsGo')}
      </Button>
    );

    if (this.state.isSubmitting) {
      comp = (
        <div className='SpinerWrapper'>
          <Spin/>
        </div>
      );
    }

    return comp;
  };

  /**
   * Triggers get logged user data request
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getUserData = () => {
    return authMe()
      .then(this.saveUserData);
  };

  /**
   * Handles login form submit
   *
   * @param {Event} e
   */
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(this.submitValidation);
  };

  /**
   * Executes user login request
   *
   * @param {String} username
   * @param {String} password
   *
   * @returns {Promise<void>}
   */
  login = async (username, password) => {
    this.setIsSubmitting(true);
    try {
      const response = await authLogin(username, password);
      this.handleLoginSuccess(response);
    } catch (e) {
      this.handleLoginFailure(e);
    }
  };

  /**
   * Handles user login request failure
   *
   * @param {Object} e
   */
  handleLoginFailure = (e) => {
    this.setIsSubmitting(false);
    this.setState({
      loginFailed: true,
      errFromResponse: e.response.data.message || this.props.t('SignIn.genericErrMsg')
    });
  };

  /**
   * Handles user login request success
   *
   * @param {Object} response
   */
  handleLoginSuccess = (response) => {
    if (response.status === 200) {
      const data = response.data.data;
      saveToken(data.jwt, data.expiresIn);
      this.getUserData()
        .then(this.finishNavigation)
        .catch(this.setIsSubmitting.bind(this, false));
    }
  };

  /**
   * Password field change handler
   *
   * @param {Event} event
   */
  passwordChangeHandler = (event) => {
    const password = event.target.value;
    this.updateStateFormData('password', password);
  };

  /**
   * User get data request success
   *
   * @param {Object} response
   */
  saveUserData = (response) => {
    setUserLocalData(response.data.data);
  };

  /**
   * Updates state to indicate submitting state
   *
   * @param {boolean} isSubmitting
   */
  setIsSubmitting = (isSubmitting) => {
    this.setState({
      isSubmitting: isSubmitting,
      loginFailed: false
    });
  };

  /**
   * If no error then executes user login, else does nothing
   *
   * @param {Object} err
   * @param {Object} values
   */
  submitValidation = (err, values) => {
    if (!err) {
      this.login(values.username, values.password);
    }
  };

  /**
   * Updates form state on data change
   *
   * @param {String} property
   * @param {String} value
   */
  updateStateFormData = (property, value) => {
    this.setState((prevState) => {
      const formData = {
        ...prevState.formData,
        [property]: value
      };
      return {
        formData: formData,
        isValid: this.validate(formData)
      };
    });
  };

  /**
   * Username change handler
   *
   * @param {Event} event
   */
  usernameChangeHandler = (event) => {
    const username = event.target.value;
    this.updateStateFormData('username', username);
  };

  // To be able to track form state without calling validateFields() on every change.
  validate = (formData) => {
    let isValid = true;
    for (let key in formData) {
      isValid = isValid && !!formData[key].length;
    }
    return isValid;
  };

  render() {
    const {t} = this.props;
    const {getFieldDecorator} = this.props.form;
    return (
      <div className='SignInWrapper'>
        <div className="SignInWrapper-inner">
          <Form onSubmit={this.handleSubmit} className='login-form '>
            <div className="SignInWrapper-company-min">
              {t('appName')}
            </div>
            <h2 className='SignInTitle'>{t('SignIn.signIn')}</h2>
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: t('SignIn.userErrMsg')
                  }
                ]
              })(
                <Input
                  className="username"
                  placeholder="USERNAME"
                  autoComplete='new-username'
                  onChange={this.usernameChangeHandler}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: t('SignIn.passErrMsg')
                  }
                ]
              })(
                <Input.Password
                  type='password'
                  placeholder="PASSWORD"
                  onChange={this.passwordChangeHandler}
                />
              )}
            </Form.Item>
            <Form.Item>
              {this.getSubmitComp()}
              {this.getErrorMessage()}
            </Form.Item>
          </Form>
          {this.getCompanyDescription()}
        </div>
      </div>
    );
  }
}

const SignIn = Form.create({name: 'normal_login'})(SignInForm);
export default withTranslation()(SignIn);
