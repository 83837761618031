import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Checkbox, List, Table } from "antd";
import PropTypes from 'prop-types';

import { getNatsUserDetailsRoute } from "../../../../../../../../../../services/navigation/users-navigation/users-navigation-routes.service";
import { getScrollHeight } from "../../../../../../../../../../services/util-service/util.service";
import { renderMomentWithHours } from "../../../../../../../../../../services/date-service/date.service";

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";

import { setNatsTestUserById, clearUserIPlist } from '../../../../../../../../../../services/nats-service/nats.service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../../services/notification-service/notification.service';
import { ModalDialog } from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';
import { ModalController } from '../../../../../../../../../../controllers/modal-controller/modal.controller';

import './NatsUsersViewBody.scss';

class NatsUsersViewBody extends Component {

  renderActions = (item, data) => {
    const { t } = this.props;
    const { id, memberid } = data || {};
    const url = memberid ? getNatsUserDetailsRoute(memberid) : '';

    const displayNoIdMsg = () => {
      if (!memberid) {
        displayErrorNotification({
          duration: 5,
          message: 'User has no memberid!'
        });
      }
    };

    const clearIpList = () => {
      if (id) {
        clearUserIPlist(id)
          .then(() => {
            displaySuccessNotification({
              duration: 3,
              message: "Clear user's IP list success!"
            });
          })
          .catch(err => {
            displayErrorNotification({
              duration: 5,
              message: t('global.somethingWentWrong')
            });
            console.log(err);
          });
      }
    };

    return (
      <>
        <LinkButton onClick={displayNoIdMsg} href={url}>
          {t('NatsUsersViewBody.viewDetails')}
        </LinkButton>
        <Button onClick={clearIpList} className='LinkButton'>
          Clear IP list
        </Button>
      </>
    );
  };

  renderDate = (date) => {
    let render = '';
    if (date) {
      render = renderMomentWithHours(date)
    }
    return render;
  };

  renderButton = (text, className) => {
    return (
      <div className="StatusItem">
        <Button className={className}>
          {text}
        </Button>
      </div>
    );
  };

  renderDownloadAllowedButton = (a, item) => {
    const { text, className } = this.getButtonsInfo(item.downloads_allowed);
    return this.renderButton(text, className);
  };

  renderStatusButton = (a, item) => {
    const { text, className } = this.getButtonsInfo(item.status, 'status');
    return this.renderButton(text, className);
  };

  getButtonsInfo = (value, key) => {
    const { t } = this.props;
    let text;
    let className;

    switch (parseInt(value)) {
      case 0:
        text = key === 'status' ? 'NatsUsersViewBody.neverJoined' : 'Not allowed';
        className = 'NeverJoined';
        break;
      case 1:
        text = key === 'status' ? 'NatsUsersViewBody.active' : 'Allowed';
        className = 'Active';
        break;
      default:
        text = 'NatsUsersViewBody.expired';
        className = 'Expired';
        break;
    }
    return { text: t(text), className };
  };

  columns = [{
    dataIndex: 'memberid',
    title: this.props.t('NatsUsersViewBody.id'),
    width: 100
  }, {
    dataIndex: 'username',
    title: this.props.t('NatsUsersViewBody.login'),
    width: 300
  }, {
    dataIndex: 'email',
    title: this.props.t('NatsUsersViewBody.email'),
    width: 300
  }, {
    align: 'center',
    dataIndex: 'status',
    title: this.props.t('NatsUsersViewBody.status'),
    render: this.renderStatusButton,
    width: 150
  }, {
    align: 'center',
    dataIndex: 'downloads_allowed',
    title: 'Download allowed',
    render: this.renderDownloadAllowedButton,
    width: 200
  }, {
    align: 'center',
    dataIndex: 'optionid',
    title: 'Option ID',
    width: 100
  }, {
    dataIndex: 'streaming_key',
    title: this.props.t('NatsUsersViewBody.streaming_key'),
    width: 450
  }, {
    align: 'center',
    dataIndex: 'is_test_user',
    title: this.props.t('NatsUsersViewBody.is_test_user'),
    render: (value, record) => {
      return <Checkbox
        checked={!!value}
        value={value}
        onChange={this.onTestUserChange.bind(this, record.id)}
      />
    },
    width: 100
  }, {
    dataIndex: 'property',
    title: this.props.t('NatsUsersViewBody.property'),
    width: 200
  }, {
    dataIndex: 'joined',
    title: this.props.t('NatsUsersViewBody.createdDate'),
    render: this.renderDate,
    width: 230
  }, {
    align: 'center',
    dataIndex: 'actions',
    title: this.props.t('NatsUsersViewBody.actions'),
    render: this.renderActions,
    width: 260
  }];

  tableRowHeight = 65;

  closeModal = () => {
    ModalController.removeModal();
  };

  getActions = (checked, userId) => {
    const { t } = this.props;
    return ([
      <Button onClick={this.closeModal} key="cancelBtn">
        {t('ChangeAltTagModal.cancel')}
      </Button>,
      <Button onClick={this.changeTestUser.bind(this, checked, userId)}
        key="saveBtn">
        {t('ChangeAltTagModal.saveChanges')}
      </Button>
    ]);
  };

  changeTestUser = (checked, userId) => {
    const { t, refresh } = this.props;
    setNatsTestUserById(userId, checked)
      .then(() => {
        this.closeModal();
        refresh();
        displaySuccessNotification({
          duration: 3,
          message: t('StreamingUsers.success')
        });
      })
      .catch(() => {
        this.closeModal();
        displayErrorNotification({
          duration: 3,
          message: t('StreamingUsers.failed')
        });
        refresh();
      });
  }

  onTestUserChange = (userId, event) => {
    const { checked } = event.target;
    const modal = (
      <ModalDialog
        title={'Change status of NATS user?'}
        actions={this.getActions(checked, userId)}
        forwardedRef={this.modalRef}
      >
        <div>Are you sure?</div>
      </ModalDialog>
    );
    ModalController.showModal(modal);
  };

  renderCheckBox = (value, item) => {
    return <Checkbox checked={!!value}
      value={value}
      onChange={this.onTestUserChange.bind(this, item.id)}
    />;
  };

  calculateTableScroll = () => {
    const { contentHeight, users } = this.props;
    const reductionHeight = 52 + 54 + 40 + 52;// Remove 52 for header and also footer height
    return getScrollHeight(contentHeight, users, this.tableRowHeight, reductionHeight);
  };

  getCardTableData = (item) => {
    const { t } = this.props;
    const { properties } = item;
    return [{
      name: t('NatsUsersViewBody.id'),
      value: item.memberid,
    }, {
      name: t('NatsUsersViewBody.login'),
      value: item.username,
    }, {
      name: t('NatsUsersViewBody.email'),
      value: item.email
    }, {
      name: t('NatsUsersViewBody.status'),
      value: this.renderStatusButton(item.status, item)
    }, {
      name: `${t('NatsUsersViewBody.streaming_key')}:`,
      value: item.streaming_key
    }, {
      name: `${t('NatsUsersViewBody.is_test_user')}:`,
      value: this.renderCheckBox(item.is_test_user, item)
    }, {
      name: t('NatsUsersViewBody.property'),
      value: properties.name
    }, {
      name: t('NatsUsersViewBody.createdDate'),
      value: this.renderDate(item.joined)
    }];
  };

  prepareUsersTableData = user => {
    const { properties } = user;
    return {
      ...user,
      property: properties.name
    };
  };

  renderListItem = (item) => {
    const { t } = this.props;
    const columns = [{
      dataIndex: 'name',
      key: 'name'
    }, {
      align: 'center',
      dataIndex: 'value',
      key: 'value'
    }];
    return (
      <BaseCard
        key={item.memberid}
        title={`${t('NatsUsersViewBody.username')}: ${item.username}`}
        footer={this.renderActions(item, item)}
      >
        <Table
          dataSource={this.getCardTableData(item)}
          rowKey={'name'}
          showHeader={false}
          pagination={false}
          columns={columns}
          bordered
        />
      </BaseCard>
    );
  };

  renderUsersList = () => {
    const { users: data } = this.props;
    return (
      <List
        className="ListView"
        dataSource={data}
        renderItem={this.renderListItem}
        split={false}
        itemLayout="vertical"
      />
    );
  };

  renderUsersTable = () => {
    const { users } = this.props;
    let data = users.map(this.prepareUsersTableData);
    return (
      <Table
        className="UsersTable"
        dataSource={data}
        rowKey="memberid"
        bordered
        columns={this.columns}
        pagination={false}
        scroll={this.calculateTableScroll()}
      />
    );
  };

  render() {
    return (
      <div className="NatsUsersViewBody">
        {this.renderUsersTable()}
        {this.renderUsersList()}
      </div>
    );
  }
}

NatsUsersViewBody.propTypes = {
  contentHeight: PropTypes.number,
  users: PropTypes.array.isRequired,
  refresh: PropTypes.func
};

export default withTranslation()(NatsUsersViewBody);
