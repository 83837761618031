import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Input, InputNumber, Table } from "antd";
import PropTypes from 'prop-types';

import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../services/notification-service/notification.service';
import { editProperty, editWhitelabelConfig } from "../../../../../../../../../../services/properties-service/properties.service";

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";

import './DetailsTab.scss';

class DetailsTab extends Component {
  editableProperties = [
    'max_just_added_page_count',
    'stars_page_enabled',
    'downloadable',
  ];

  startingData = {
    editMode: true
  };

  state = {
    editMode: false
  };

  getPropertyValue = (object, item) => {
    return Object.assign(object, { [item.name]: item.value });
  };

  constructor(props) {
    super(props);
    const {
      propertyId,
      movies,
      activeMembers,
      users,
      orders,
      dateCreated,
      max_just_added_page_count,
      stars_page_enabled,
      downloadable
    } = props.propertyData.reduce(this.getPropertyValue, {});

    const data = {
      propertyId,
      movies,
      activeMembers,
      users,
      orders,
      dateCreated,
      max_just_added_page_count: max_just_added_page_count || 10,
      stars_page_enabled,
      downloadable
    };
    Object.assign(this.state, data);
    Object.assign(this.startingData, data);
  }

  hasDataChanged = () => {
    return (this.startingData.stars_page_enabled !== this.state.stars_page_enabled) || (this.startingData.max_just_added_page_count !== this.state.max_just_added_page_count) || this.startingData.downloadable !== this.state.downloadable;
  };

  isEditable = (key) => {
    return this.editableProperties.indexOf(key) !== -1;
  };

  onChange = (key) => {
    this.setState({
      [key]: key
    });
  };

  onCheckboxChange = (key) => {
    this.setState(prevState => {
      const value = prevState[key] ? 0 : 1;
      return { [key]: value }
    });
  };

  onSaveChangesFailure = () => {
    this.props.setLoading('');
    displayErrorNotification({
      duration: 3,
      message: this.props.t('DetailsTab.propertyEditFailure')
    });
  };

  onSaveChangesSuccessfully = () => {
    const { max_just_added_page_count, stars_page_enabled, downloadable } = this.state;
    Object.assign(this.startingData, {
      max_just_added_page_count,
      stars_page_enabled,
      downloadable
    });
    this.props.setLoading('');
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('DetailsTab.propertyEditSuccessfully')
    });
    this.props.onPropertyEdit();
    this.toggleEditMode();
  };

  renderEditField = (value, key) => {
    let result;
    switch (key) {
      case 'stars_page_enabled':
        result = value;
        break;
      case 'max_just_added_page_count':
        result = this.renderMaxJustAddedPageCountField(value);
        break;
      case 'downloadable':
        result = value;
        break;
      default:
        result = (
          <Input.TextArea autosize
            autoFocus={key === 'propertyId'}
            onChange={this.onChange.bind(this, key)}
            value={this.state[key]} />
        );
        break;
    }

    return result;
  };

  renderFooter = () => {
    const { t } = this.props;
    const { editMode } = this.state;
    const key = editMode ? 'cancel' : 'edit';
    return (
      <div className="UserActions">
        {editMode ? <Button onClick={this.saveEditChanges}
          disabled={!this.hasDataChanged()}>
          {t('DetailsTab.saveChanges')}
        </Button> : null}
        <Button onClick={this.toggleEditMode.bind(this, key)}>
          {t(`DetailsTab.${key}`)}
        </Button>
      </div>
    );
  };

  renderMaxJustAddedPageCountField = () => {
    const { max_just_added_page_count } = this.state;
    return (
      <InputNumber onChange={this.setMaxJustAddedPageCount}
        value={max_just_added_page_count} />
    );
  };

  renderNameColumn = (item) => {
    return this.props.t(`DetailsTab.${item}`);
  };

  renderCheckbox = (key) => {
    const { editMode } = this.state;
    const value = this.state[key];
    return (
      <div className="Checkbox-Container">
        <Checkbox
          checked={!!value}
          disabled={!editMode}
          onChange={this.onCheckboxChange.bind(this, key)} />
      </div>
    );
  };

  renderValue = (value, key) => {
    const { editMode } = this.state;
    let result = value;

    if (editMode && this.isEditable(key)) {
      result = this.renderEditField(value, key);
    }

    return result;
  }

  renderValueColumn = (value, { name }) => {
    let result = this.renderValue(value, name);
    if (name === 'stars_page_enabled' || name === 'downloadable') {
      result = this.renderCheckbox(name);
    }
    return result;
  };

  columns = [{
    dataIndex: 'name',
    key: 'property',
    render: this.renderNameColumn
  }, {
    dataIndex: 'value',
    render: this.renderValueColumn,
    key: 'value',
    width: 300
  }];

  saveEditChanges = () => {
    const message = this.props.t('DetailsTab.savingMsg');
    this.props.setLoading(message);
    const { propertyId, max_just_added_page_count, stars_page_enabled, downloadable } = this.state;

    const whiteLabelConfigData = {
      stars_page_enabled,
      max_just_added_page_count
    };

    const promises = [
      editWhitelabelConfig(propertyId, whiteLabelConfigData),
      editProperty(propertyId, { downloadable })
    ];

    Promise.all(promises)
      .then(this.onSaveChangesSuccessfully)
      .catch(this.onSaveChangesFailure);
  };

  setMaxJustAddedPageCount = (pageCount) => {
    this.setState({
      max_just_added_page_count: pageCount
    });
  };

  toggleEditMode = (key) => {
    this.setState({
      editMode: !this.state.editMode,
      max_just_added_page_count: key === 'cancel' ? this.startingData.max_just_added_page_count : this.state.max_just_added_page_count,
      stars_page_enabled: key === 'cancel' ? this.startingData.stars_page_enabled : this.state.stars_page_enabled,
      downloadable: key === 'cancel' ? this.startingData.downloadable : this.state.downloadable
    });
  };

  render() {
    const { t } = this.props;
    let data = [...this.props.propertyData];

    return (
      <div className="DetailsTab">
        <BaseCard title={t('DetailsTab.propertyInfo')}>
          <Table
            rowKey="name"
            dataSource={data}
            pagination={false}
            showHeader={false}
            bordered
            columns={this.columns}
            footer={this.renderFooter}
          // size='middle'
          />
        </BaseCard>
      </div>
    );
  }
}

DetailsTab.propTypes = {
  name: PropTypes.string.isRequired,
  propertyData: PropTypes.array.isRequired
};

export default withTranslation()(DetailsTab);
