import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Icon} from 'antd';
import PropTypes from 'prop-types';

import StarFilters from './components/StarFilters/StarFilters';

import './StarsViewHeader.scss';

class StarsViewHeader extends Component {
  state = {
    filtersDisplayed: false
  };

  /**
   * Updates state to trigger filter re-render
   */
  displayFilters = () => {
    this.setState(prevState => {
      return {
        filtersDisplayed: !prevState.filtersDisplayed
      };
    });
  };

  /**
   * Returns filter icon type depending on state filtersDisplayed
   *
   * @returns {string}
   */
  getIconType = () => {
    return this.state.filtersDisplayed ? 'up' : 'down';
  };

  render() {
    const {defaultDisplayFilter, defaultNameFilter, generalLinks, onStatusChange, starsTotal, t} = this.props;
    const classes = this.state.filtersDisplayed ? ['StarsViewHeader', 'FilterDisplayed'] : ['StarsViewHeader'];
    return (
      <div className={classes.join(' ')}>
        <div className="StatusInnerWrapper">
          <div className='RecordCountMessage'>
            {t('StarViewHeader.recordFound', {
              recordCount: starsTotal
            })}
          </div>
          <Icon type={this.getIconType()} onClick={this.displayFilters}/>
        </div>
        <StarFilters generalLinks={generalLinks}
                     onFilterChange={onStatusChange}
                     defaultDisplayFilter={defaultDisplayFilter}
                     defaultNameFilter={defaultNameFilter}/>
      </div>
    );
  }
}

StarsViewHeader.propTypes = {
  defaultDisplayFilter: PropTypes.string.isRequired,
  defaultNameFilter: PropTypes.string,
  generalLinks: PropTypes.array.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  starsTotal: PropTypes.number.isRequired
};

export default withTranslation()(StarsViewHeader);
