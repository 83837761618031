import React, {Component} from 'react';
import {Icon} from "antd";
import PropTypes from 'prop-types';

import BaseDropdown from "../BaseDropdown/BaseDropdown";

import './BaseHeader.scss';

class BaseHeader extends Component {

  /**
   * Returns dropdown element for render
   *
   * @returns {JSX.Element|null}
   */
  getDropdown = () => {
    let view = null;
    const {items} = this.props;
    if (items) {
      view = (
        <BaseDropdown items={items}
                      menuClassName="BaseHeader-menu">
          <Icon type="more"/>
        </BaseDropdown>
      );
    }

    return view;
  };

  render() {
    const {title} = this.props;
    return (
      <div className="BaseHeader">
        <span className="BaseHeader-title">
        {title}
        </span>
        {this.getDropdown()}
      </div>
    );
  }
}

BaseHeader.propTypes = {
  items: PropTypes.array,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
};

export default BaseHeader;
