import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Icon, Input, Select} from "antd";
import PropTypes from 'prop-types';

import './StudiosViewHeader.scss';
import BaseSort from "../../../../../../../../../../components/BaseSort/BaseSort";

class StudiosViewHeader extends Component {

  filterDelay = 500;

  state = {
    filters: {
      display: '',
      search: '',
      sort: {
        ascending: false,
        name: ''
      }
    },
    nameFilter: ''
  };

  constructor(props) {
    super(props);
    const {defaultFilters} = this.props;
    if (defaultFilters) {
      this.state.nameFilter = defaultFilters.name;
    }
    Object.assign(this.state, {
      sortFilter: props.defaultSortFilter,
      filters: {
        sort: {
          ascending: false,
          name: props.defaultSortFilter,
          text: props.localizedDefaultSortFilter
        },
        display: props.defaultDisplayFilter
      }
    });
  }

  callPropsClearFilter = () => {
    this.props.onClearFilter(this.state.filters);
  };

  callPropsFilterChange = () => {
    this.props.onFilterChange(this.state.filters);
  }

  onClearFilter = () => {
    const {defaultSortFilter, defaultDisplayFilter, localizedDefaultSortFilter} = this.props;
    this.setState({
      filters: {
        display: defaultDisplayFilter,
        sort: {
          ascending: true,
          name: defaultSortFilter,
          text: localizedDefaultSortFilter
        }
      },
      nameFilter: ''
    }, this.callPropsClearFilter);
  };

  onDisplayChange = (status) => {
    this.setState(prevState => {
      return {
        filters: {
          ...prevState.filters,
          display: status
        }
      }
    }, this.callPropsFilterChange);
  };

  onNameFilterChange = (event) => {
    const name = event.target.value;
    this.setState({
      nameFilter: name
    });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.props.onNameFilterChange.bind(this, name), this.filterDelay);
  };

  onSortChange = (sortFilter, ascending, text) => {
    this.setState(prevState => {
      return {
        filters: {
          ...prevState.filters,
          sort: {
            ascending: ascending,
            name: sortFilter,
            text
          },
        }
      }
    }, this.callPropsFilterChange);
  };

  renderExportBtn = () => {
    const {t} = this.props;
    return (
      <Button className="ExportBtn"
               onClick={this.props.onStudiosExport}
               key="ExportBtn">
        <Icon type="save"/>
        {t('StudiosViewHeader.export')}
      </Button>
    );
  };

  renderDisplayFilterItem = (item) => {
    const {t} = this.props;
    return <Select.Option key={item} value={item}>
      <div className="StudioFilterDropdownItemWrapper">{t(`StudioFilters.${item}`)}</div>
    </Select.Option>
  };

  render() {
    const {t} = this.props;
    return (
      <div className="StudiosViewHeader">
        <div className="StudioFilters">
          <div className="FilterWrapper">
            <div className="label">{t('StudioFilters.searchByStudioName')}</div>
            <Input placeholder={t('StudiosViewHeader.searchByStudioName')}
                   onChange={this.onNameFilterChange}
                   value={this.state.nameFilter}/>
          </div>
          <div className="FilterWrapper">
            <div className="label">{t('StudioFilters.status')}</div>
            <Select showArrow
                    className="StudioViewHeader-select"
                    onChange={this.onDisplayChange}
                    value={this.state.filters.display}>
              {this.props.generalLinks.map(this.renderDisplayFilterItem)}
            </Select>
          </div>
          <BaseSort
            onValueChange={this.onSortChange}
            defaultSortFilter={this.props.defaultSortFilter}
            sortCriteria={this.props.sortCriteria}
            value={this.state.filters.sort}
          />
          <Button className="ClearFilter" onClick={this.onClearFilter}>
            {t('StudioFilters.clearFilter')}
          </Button>
          {this.renderExportBtn()}
        </div>
      </div>
    );
  }
}

StudiosViewHeader.propTypes = {
  defaultFilters: PropTypes.object,
  onNameFilterChange: PropTypes.func.isRequired,
  onStudiosExport: PropTypes.func.isRequired,
  localizedDefaultSortFilter: PropTypes.string
};

export default withTranslation()(StudiosViewHeader);
