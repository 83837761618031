import React, {Component} from 'react';
import {Button} from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import {BaseVideoPlayerController, FullPlayer} from "@falconstudios/ns-player";

import data from '../../../../../../../../../assets/player/data.json';

import {getMoviePlayerStills} from "../../../../../../../../../services/movies-service/movies.service";
import './VideoModal.scss';

/* istanbul ignore file */
class VideoModal extends Component {

  modalRef = React.createRef();

  ads = [];

  autoplay = false;

  videos = [];

  constructor(props) {
    super(props);
    const userSettings = BaseVideoPlayerController.getUserSettings();
    this.autoplay = !!userSettings.autoplay;
    this.setPlayerConfig();
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  getModal = () => {
    return this.modalRef.current;
  };

  onAddClick = ({ url }) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  setPlayerConfig = () => {
    const config = localStorage.getItem('playerConfig');
    let playerData;
    const { playlist } = this.props;
    if (config) {
      playerData = JSON.parse(config);
      playerData = playlist ? playerData.playlist : playerData.single;
    } else {
      playerData = playlist ? data.playlist : data.single;
    }
    this.ads = playerData.ads;
    this.videos = playerData.videos;
  };

  mapVideoThumbnails = (response) => response.data.data;

  render() {
    const data = {
      ads: this.ads,
      videos: this.videos,
      isLiked: () => true,
      isFavorite: () => true,
      fetchVideoThumbnails: getMoviePlayerStills,
      mapVideoThumbnails: this.mapVideoThumbnails,
      onAddClick: this.onAddClick,
      className: "VideoModal-player",
      autoplay: this.autoplay,
    };
    return (
      <ModalDialog forwardedRef={this.modalRef} className="VideoModal">
        <FullPlayer data={data}/>
        <Button onClick={this.closeModal}>Close</Button>
      </ModalDialog>
    );
  }
}

VideoModal.propTypes = {
  playlist: PropTypes.bool.isRequired
};

export default VideoModal;
