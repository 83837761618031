import React, {Component} from 'react';
import {Spin} from "antd";

import css from './LoadingMask.module.css';
import {LoadingController} from '../../controllers/loading-controller/loading.controller';

class LoadingMask extends Component {

  state = {
    maskVisible: false,
    text: 'text'
  };
  subscription;

  componentDidMount() {
    this.subscription = LoadingController.displayLoadingMask.subscribe(this.displayMask);
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  displayMask = (data) => {
    this.setState({
      maskVisible: data.displayMask,
      text: data.text
    });
  };

  render() {
    const classes = [css.LoadingWrapper];
    if (this.state.maskVisible) {
      classes.push(css.Visible);
    }

    return (
      <div className={classes.join(' ')}>
        <Spin size="large"/>
        {this.state.text}
      </div>
    );
  }
}

export default LoadingMask;

