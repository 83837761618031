import axios from 'axios';

export const urls = {
  host: '/host',
  editHost: '/host/edit',
  movieCatalogs: '/movie_catalogs',
  orientations: '/orientations',
  properties: '/properties',
  propertyCategories: '/properties/categories_attach',
  propertyEdit: '/properties/edit',
  propertyMovies: '/properties/movies/',
  propertyStudio: '/properties/studio',
  whitelabel: '/whitelabel_config',
  controlSwitchers: '/control_switchers',
  bannedWords: '/banned_search_terms'
};

export const addCatalogToProperty = (id, data) => {
  return axios.post(`${urls.movieCatalogs}`, {
    properties_id: id,
    ...data
  });
};

export const addHostToProperty = (id, postData) => {
  return axios.post(`${urls.host}/${id}`, postData);
};

export const attachCategoriesToProperty = (propertyId, categoryIds) => {
  const postData = {
    categories_id: categoryIds
  };
  return axios.patch(`${urls.propertyCategories}/${propertyId}`, postData);
};

export const attachStudiosToProperty = (propertyId, studioIds) => {
  const postData = {
    studios_id: studioIds
  };
  return axios.patch(`${urls.propertyStudio}/${propertyId}`, postData);
};

export const editHost = (id, postData) => {
  return axios.patch(`${urls.editHost}/${id}`, postData);
};

export const editCatalog = (catalogId, postData) => {
  return axios.patch(`${urls.movieCatalogs}/${catalogId}/edit`, postData);
};

export const editProperty = (id, data) => {
  if (data !== null && typeof data !== 'object') {
    console.error('editProperty - passed data is not an object!')
    return;
  }

  const params = {};
  Object.entries(data).forEach(([key, val]) => {
    params[key] = val;
  });

  return axios.patch(`${urls.propertyEdit}/${id}`,
    params
  );
};

export const editWhitelabelConfig = (propertyId, postData) => {
  return axios.patch(`${urls.whitelabel}/${propertyId}/append`, {
    whitelabel_config: postData,
  });
};

export const patchWhitelabelConfig = (propertyId, postData) => {
  return axios.patch(`${urls.whitelabel}/${propertyId}/append`, {
    whitelabel_config: postData
  })
}

export const getAllOrientations = () => {
  return axios.get(`${urls.orientations}`);
};

export const getProperties = (visible = 1) => {
  // by default load only visible
  if (visible === -1) {
    // load all
    visible = undefined;
  }
  return axios.get(`${urls.properties}`, {
    params: {
      visible
    }
  });
};

export const editPropertyVisibility = (propertyId, visible) => {
  return axios.patch(`${urls.propertyEdit}/${propertyId}`, {
    visible: visible ? 1 : 0
  });
};

export const getProperyAttachedCategories = (propertyId) => {
  return axios.get(`${urls.properties}/with_attach_category/${propertyId}`);
};

export const getProperyAttachedStudios = (propertyId) => {
  return axios.get(`${urls.properties}/with_attach_studio/${propertyId}`);
};

export const getPropertyById = (id) => {
  return axios.get(`${urls.properties}/${id}`);
};

export const getPropertyCatalogs = propertyId => {
  return axios.get(`${urls.properties}/${propertyId}/movie_catalogs`);
};

export const getPropertyMovies = (propertyId, page, filters) => {
  return filters.length ? getFilteredPropertyMovies(propertyId, page, filters) : getPropertyMoviesPage(propertyId, page);
};

export const getPropertyMoviesPage = (propertyId, page) => {
  return axios.get(`${urls.propertyMovies}?id=${propertyId}&page=${page}`);
};

export const getFilteredPropertyMovies = (propertyId, page, filters) => {
  return axios.get(`${urls.propertyMovies}?id=${propertyId}&${filters.join('&')}&page=${page}`);
};

export const getWhitelabelConfig = (propertyId) => {
  return axios.get(`${urls.whitelabel}/${propertyId}/get`);
};

export const setPropertyControlSwitchers = (propertyId, controlSwitchers = []) => {
  return axios.patch(`${urls.properties}/${propertyId}${urls.controlSwitchers}/attach`,
    { id: propertyId, control_switchers_id: controlSwitchers }
  );
};

export const getWhitelabelBannedWords = (propertyId) => {
  return axios.get(`${urls.bannedWords}/${propertyId}/get`);
};

export const setWhitelabelBannedWords = (propertyId, terms = []) => {
  return axios.post(`${urls.bannedWords}/${propertyId}/set`, {
    terms
  });
};