import React, { Component } from "react";
import { Redirect, Router } from "@reach/router";
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import { Button, Icon } from "antd";

import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import {
	uploadStarImage,
	deleteStarImage,
	getCoverImageUrl,
} from "../../../../../../../../../services/images-service/images.service";
import {
	getStarsRoute,
	getStarDetailsRoute,
	getStarMoviesRoute,
	getStarDetailsBaseRoute,
	getStarContentInfoRoute,
} from "../../../../../../../../../services/navigation/stars-navigation/stars-navigation-routes.service";
import {
	showStarMovies,
	showStarDetails,
	showStarContentInfo,
} from "../../../../../../../../../services/navigation/stars-navigation/stars-navigation.service";
import {
	displayErrorNotification,
	displaySuccessNotification,
} from "../../../../../../../../../services/notification-service/notification.service";
import {
	editStar,
	getStarById,
	syncStar,
} from "../../../../../../../../../services/stars-service/stars.service";
import { convertMomentToDate } from "../../../../../../../../../services/date-service/date.service";

import BaseHorizontalNavigation from "../../../../../../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";
import BaseNavigationItem from "../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import DeleteImagesModal from "../../../../../../../../../components/modal/DeleteImagesModal/DeleteImagesModal";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import StarContentInfo from "./StarContentInfo/StarContentInfo";
import StarDetailsBodyView from "./StarDetailsBodyView/StarDetailsBodyView";
import StarMovies from "../StarMovies/StarMovies";
import UploadStarImagesModal from "./UploadStarImagesModal/UploadStarImagesModal";

import "./StarDetails.scss";

class StarDetails extends Component {
	imageDeleting = false;

	starId;

	state = {
		activeTab: "1",
		data: {},
		dataLoaded: false,
		loading: false,
	};

	constructor(props) {
		super(props);
		this.starId = +props.starId;
	}

	componentDidMount() {
		this.fetchStarData();
	}

	changeTab = (activeKey) => {
		this.setState({
			activeTab: activeKey,
		});
	};

	deleteImages = () => {
		const modal = (
			<DeleteImagesModal
				images={this.filterImagesToDelete()}
				deleteImage={this.mapDeleteImagePromise}
				onDeleteSuccess={this.onDeleteSuccess}
			/>
		);
		ModalController.showModal(modal);
	};

	displayUploadPhotoModal = () => {
		const modal = (
			<UploadStarImagesModal
				uploadImages={this.uploadImages}
				onUploadFinished={this.fetchStarData}
			/>
		);
		ModalController.showModal(modal);
	};

	fetchStarData = () => {
		this.setLoading(true);
		getStarById(this.starId).then(this.loadStarData);
	};

	filterExclusiveImages = (image) => image.type === "Exclusive";

	filterHeadshotImages = (image) => image.type === "Headshot";

	filterImagesToDelete = () => {
		return this.state.data.images.filter(this.matchImagesToDelete);
	};

	getExclusiveImage = () => {
		return this.state.data.images
			? this.state.data.images.filter(this.filterExclusiveImages)
			: [];
	};

	getHeadShots = () => {
		const { images } = this.state.data;
		let headshot = null;
		if (images) {
			headshot = getCoverImageUrl(images, true);
			if (!headshot) {
				const data = images.filter(this.filterHeadshotImages);
				if (data.length) {
					headshot = data[0].url;
				}
			}
		}

		return headshot;
	};

	getDeleteExclusiveImagePromise = (exclusive) => {
		const { id: starId } = this.state.data;
		const exlusiveImage = this.getExclusiveImage();

		let promise;
		if (!exclusive && exlusiveImage.length) {
			const { id: imageId } = exlusiveImage[0];
			promise = deleteStarImage(starId, imageId);
		} else {
			promise = Promise.resolve();
		}

		return promise;
	};

	getNavigationItems = () => {
		const { data } = this.state;
		const { id, name } = data;
		const { t } = this.props;
		return [
			{
				link: getStarDetailsRoute(id),
				name: t("StarDetails.starName", { name: name || "" }),
				navigate: showStarDetails.bind(null, id),
			},
			{
				link: getStarMoviesRoute(id),
				name: t("StarDetails.movies"),
				navigate: this.navigateToStarMovies,
			},
		];
	};

	getRoutes = () => {
		const { starId, t, location = {} } = this.props;
		const { data } = this.state;
		const starName = data.name || "";
		const pathname = location.pathname || '';

		const routes = [{
			url: getStarsRoute(),
			text: t("StarDetails.stars"),
		}, {
			text: starName
		}];

		if (pathname.includes('/movies/')) {
			routes
				.splice(1, 1, {
					url: getStarDetailsBaseRoute(starId),
					text: starName
				}, {
					text: t("StarDetails.movies")
				})
		} else if (pathname.includes('/contentInfo')) {
			routes
				.splice(1, 1, {
					url: getStarDetailsBaseRoute(starId),
					text: starName
				}, {
					text: t("StarDetails.contentInfo")
				})
		}

		return routes;
	};

	getStarActions = () => {
		const { t } = this.props;
		return [
			<Button onClick={this.syncStarData} key="syncStar">
				<Icon type="sync" />
				{t("StarDetails.syncStar")}
			</Button>,
			<Button onClick={this.deleteImages} key="deleteImages">
				<Icon type="delete" />
				{t("StarDetails.deleteImages")}
			</Button>,
			<Button onClick={this.displayUploadPhotoModal} key="uploadImages">
				<Icon type="picture" />
				{t("StarDetails.uploadImages")}
			</Button>,
			<Button onClick={this.showStarContentInfo} key="contentInfo">
				<Icon type="book" />
				{t("StarDetails.contentInfo")}
			</Button>,
		];
	};

	loadStarData = (response) => {
		const data = response.data.data;
		const state = {};
		this.setDocumentTitle(data);

		if (this.imageDeleting && data.images && data.images.length === 0) {
			state.activeTab = "1";
		}

		Object.assign(state, {
			data: data,
			dataLoaded: true,
			loading: false,
		});

		this.setState(state);

		this.imageDeleting = false;
	};

	mapDeleteImagePromise = (id) => {
		const { starId } = this.props;
		return deleteStarImage(starId, id);
	};

	matchImagesToDelete = (image) => image.type !== "Exclusive";

	navigateToStarMovies = () => {
		showStarMovies(this.starId);
	};

	onDeleteSuccess = () => {
		this.imageDeleting = true;
		this.fetchStarData();
	};

	onStarEdit = (data) => {
		this.setState((prevState) => {
			const newData = cloneDeep(prevState.data);
			Object.assign(newData, data);
			return {
				data: newData,
			};
		});
	};

	onSyncStarFailure = () => {
		this.setLoading(false);
		const { t } = this.props;
		displayErrorNotification({
			duration: 3,
			message: t("StarDetails.starSyncFailureMsg"),
		});
	};

	onSyncStarSuccess = () => {
		this.setLoading(false);
		const { t } = this.props;
		displaySuccessNotification({
			duration: 3,
			message: t("StarDetails.starSyncSuccessMsg"),
		});
	};

	onStarEditFailure = () => {
		this.setLoading("");
		displayErrorNotification({
			duration: 3,
			message: this.props.t("StarDetails.editStarFailure"),
		});
	};

	onStarEditSuccess = (data, callback) => {
		displaySuccessNotification({
			duration: 3,
			message: this.props.t("StarDetails.editStarSuccess"),
		});
		this.setLoading("");
		callback();
		this.fetchStarData();
	};

	renderSubMenu = () => {
		const { location, starId } = this.props;
		const { pathname } = location;
		let view = <div />;
		if (pathname !== getStarContentInfoRoute(starId)) {
			view = (
				<BaseHorizontalNavigation items={this.getNavigationItems()}>
					{this.getStarActions()}
				</BaseHorizontalNavigation>
			);
		}

		return view;
	};

	renderTitle = () => {
		return <BaseNavigationItem routes={this.getRoutes()} />;
	};

	saveStarData = (data, callback) => {
		const message = this.props.t("StarDetails.savingMsg");
		this.setLoading(message);
		const {
			name,
			gender,
			eyeColor,
			hairColor,
			ethnicity,
			birthDate,
			heightInches,
			weightLbs,
			contractStudioId,
			biography,
			exclusive,
		} = data;
		const updateData = {
			name,
			gender,
			eyeColor,
			hairColor,
			ethnicity,
			birthDate:
				birthDate && birthDate.isValid()
					? birthDate.format("MM/DD/YYYY")
					: "null",
			heightInches,
			weightLbs,
			contractStudioId,
			biography,
			exclusive,
		};
		const postData = {
			name,
			gender,
			eyeColor,
			hairColor,
			ethnicity,
			birthDate:
				birthDate && birthDate.isValid()
					? convertMomentToDate(birthDate)
					: "null",
			heightInches,
			weightLbs,
			contractStudioId,
			biography,
			exclusive,
		};
		const { starId } = this.props;
		const promises = [
			editStar(starId, postData),
			this.getDeleteExclusiveImagePromise(exclusive),
		];
		Promise.all(promises)
			.then(this.onStarEditSuccess.bind(this, updateData, callback))
			.catch(this.onStarEditFailure);
	};

	setDocumentTitle = (data) => {
		const title = this.props.t("StarDetails.documentTitle", {
			name: data.name,
		});
		DocumentTitleController.setDocumentTitle(title);
	};

	setLoading = (loading) => {
		this.setState({
			loading: loading,
		});
	};

	showStarContentInfo = () => {
		const { starId } = this.props;
		showStarContentInfo(starId);
	};

	syncStarData = () => {
		this.setLoading(true);
		syncStar(this.props.starId)
			.then(this.onSyncStarSuccess)
			.catch(this.onSyncStarFailure);
	};

	uploadImages = (file, type) => {
		const { starId } = this.props;
		let requests = uploadStarImage(starId, type, file);
		if (type === 'Header') {
			const { header_images = [] } = this.state.data;
			if (header_images.length) {
				const imageId = header_images[0].master_image_id;
				requests = deleteStarImage(starId, imageId);
			}
		}
		return requests;
	};

	render() {
		const { data } = this.state;
		const headshot = this.getHeadShots();
		const { starId, t } = this.props;
		return (
			<LoadingWrapper
				className="StarDetails"
				dataLoaded={this.state.dataLoaded}
				isLoading={this.state.loading}
				loadingMsg={t("StarDetails.pleaseWait")}
			>
				{this.renderTitle()}
				{this.renderSubMenu()}
				<Router>
					<StarDetailsBodyView
						path={"/*"}
						{...data}
						headshot={headshot}
						activeTab={this.state.activeTab}
						changeTab={this.changeTab}
						onStarEdit={this.saveStarData}
						onStarUpdateFinished={this.fetchStarData}
						fetchStarData={this.fetchStarData}
						starId={starId}
					/>
					<StarMovies path={`/movies/*`} starName={data.name} />
					<StarContentInfo path={"/contentInfo"} />
					<Redirect
						from={"/"}
						to={getStarDetailsRoute(starId)}
						noThrow
					/>
				</Router>
			</LoadingWrapper>
		);
	}
}

StarDetails.propTypes = {
	starId: PropTypes.string,
};

export default withTranslation()(StarDetails);
