import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {Button, Collapse, Icon} from "antd";

import AdsCollapsePanel from "../AdsCollapsePanel/AdsCollapsePanel";
import CollapsiblePanel from "../../../shared/CollapsiblePanel/CollapsiblePanel";
import ConfigureAdsModal from "../AdsConfiguration/ConfigureAdsModal/ConfigureAdsModal";
import EditAdModal from "../AdsConfiguration/EditAdModal/EditAdModal";
import ConfigurationWillBeRemoved from "../../../../ConfigurationWillBeRemoved/ConfigurationWillBeRemoved";

import {ConfigureMoviesAdsController} from "../ConfigureMoviesAdsController";
import {ModalController} from "../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import {stopEvent} from "../../../../../../../../../../../../services/util-service/util.service";
import {convertRunTimeToSeconds} from "../../../../../../../../../../../../services/date-service/date.service";

import './ScenesCollapsePanel.scss';

/* istanbul ignore file */
class ScenesCollapsePanel extends Component {

  expandSubscription;

  state = {
    activeScenes: [],
    validationTriggered: false
  };

  subscriptions = {};

  constructor(props) {
    super(props);
    this.subscriptions.expandSubscription = ConfigureMoviesAdsController.expandEvent.subscribe(this.setExpanded);
    this.subscriptions.validation = ConfigureMoviesAdsController.triggerValidationEvent.subscribe(this.triggerValidation);
    this.state.activeScenes = Object.keys(props.scenesAds);
  }

  componentWillUnmount() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
  }

  configureMovieAds = (id, event) => {
    stopEvent(event);
    const {movieRunTime, onSceneAdsChange, scenesAds} = this.props;
    const modal = (
      <ConfigureAdsModal id={id}
                         endInSeconds={convertRunTimeToSeconds(movieRunTime)}
                         startInSeconds={0}
                         ads={scenesAds[id]}
                         onAdsSave={onSceneAdsChange}/>
    );
    ModalController.showModal(modal);
  };

  configureSceneAds = (sceneId, event) => {
    stopEvent(event);
    const {movieScenes, onSceneAdsChange, scenesAds} = this.props;
    const {endTimeSeconds, startTimeSeconds} = movieScenes.find(this.matchSceneById.bind(this, +sceneId));
    const modal = (
      <ConfigureAdsModal id={sceneId}
                         endInSeconds={endTimeSeconds}
                         startInSeconds={startTimeSeconds}
                         ads={scenesAds[sceneId]}
                         onAdsSave={onSceneAdsChange}/>
    );
    ModalController.showModal(modal);
  };

  displayEditAdModal = (id, index) => {
    const {movieScenes, movieRunTime, scenesAds} = this.props;
    const ads = scenesAds[id];
    const ad = ads[index];
    const isScene = !this.isMovie(id);
    let startInSeconds;
    let endInSeconds;
    if (isScene) {
      const scene = movieScenes.find(this.matchSceneById.bind(this, +id));
      startInSeconds = scene.startTimeSeconds;
      endInSeconds = scene.endTimeSeconds;
    } else {
      startInSeconds = 0;
      endInSeconds = convertRunTimeToSeconds(movieRunTime);
    }
    const props = {
      ad,
      endInSeconds,
      id: +id,
      index,
      saveChanges: this.updateSceneAdData,
      startInSeconds
    };
    const modal = (
      <EditAdModal {...props}/>
    );
    ModalController.showModal(modal);
  };

  getSceneTitle = (id) => {
    const {t} = this.props;
    const isMovie = this.isMovie(id);
    const key = isMovie ? 'movieIdTxt' : 'sceneIdTxt';
    return (
      <div className="Header">
        <div className="SceneName">
          {t(`ScenesCollapsePanel.${key}`, {id: id})}
        </div>
        <div className="Section">
          <Button
            onClick={isMovie ? this.configureMovieAds.bind(this, id) : this.configureSceneAds.bind(this, id)}>
            {t(`ScenesCollapsePanel.${isMovie ? 'configureMovieAds' : 'configureSceneAds'}`)}
          </Button>
          <Icon type="close" onClick={this.removeScene.bind(this, id)}/>
        </div>
      </div>
    );
  };

  isMovie = (id) => {
    const {movieScenes} = this.props;
    return !movieScenes.find(this.matchSceneById.bind(this, +id));
  };

  matchSceneById = (sceneId, {id}) => {
    return sceneId === id;
  };

  onActivePanelChange = (activeScenes) => {
    this.setState({activeScenes});
  };

  onRemoveSceneAd = (sceneId, index) => {
    const {onRemoveSceneAd} = this.props;
    onRemoveSceneAd(sceneId, index);
  };

  removeScene = (sceneId, event) => {
    stopEvent(event);
    const {onRemoveScene} = this.props;
    onRemoveScene(sceneId);
  };

  renderSceneAds = (id, sceneAds) => {
    const {invalidKeys} = this.props;
    const props = {
      ads: sceneAds,
      editAd: this.displayEditAdModal,
      invalidKeys: invalidKeys[id],
      removeAd: this.onRemoveSceneAd,
      id
    };
    return (
      <AdsCollapsePanel {...props}/>
    );
  };

  renderSceneContent = (id, sceneAds) => {
    let view;
    const {deletedScenes} = this.props;
    if (deletedScenes[id]) {
      view = (
        <ConfigurationWillBeRemoved isFullRemoval={true}/>
      );
    } else {
      view = this.renderSceneAds(id, sceneAds);
    }

    return view;
  };

  setExpanded = (expand) => {
    const activeScenes = expand ? Object.keys(this.props.scenesAds) : [];
    this.setState({activeScenes});
  };

  sceneMap = (sceneAds, index) => {
    const {invalidKeys, scenesAds} = this.props;
    const {validationTriggered} = this.state;
    const keys = Object.keys(scenesAds);
    const id = keys[index];
    const classes = ['Scene'];
    if (validationTriggered) {
      const additionalClass = invalidKeys[id] ? 'Invalid' : 'Valid';
      classes.push(additionalClass);
    }
    return (
      <Collapse.Panel key={id}
                      header={this.getSceneTitle(id)}
                      className={classes.join(' ')}>
        {this.renderSceneContent(id, sceneAds)}
      </Collapse.Panel>
    );
  };

  triggerValidation = () => {
    this.setState({
      validationTriggered: new Date().getTime()
    });
  };

  updateSceneAdData = (id, index, data) => {
    const {onUpdateSceneAdData} = this.props;
    onUpdateSceneAdData(id, index, data);
  };

  render() {
    const {scenesAds} = this.props;
    const data = Object.values(scenesAds);
    const initialActiveScenes = Object.keys(scenesAds);
    const {activeScenes} = this.state;
    return (
      <CollapsiblePanel className="ScenesCollapsePanel"
                        activeKeys={activeScenes}
                        defaultActiveKeys={initialActiveScenes}
                        onChange={this.onActivePanelChange}>
        {data.map(this.sceneMap)}
      </CollapsiblePanel>
    );
  }
}

ScenesCollapsePanel.propTypes = {
  deletedScenes: PropTypes.object.isRequired,
  invalidKeys: PropTypes.object,
  movieRunTime: PropTypes.string,
  movieScenes: PropTypes.array.isRequired,
  onRemoveScene: PropTypes.func.isRequired,
  onRemoveSceneAd: PropTypes.func.isRequired,
  onUpdateSceneAdData: PropTypes.func.isRequired,
  onSceneAdsChange: PropTypes.func.isRequired,
  scenesAds: PropTypes.object.isRequired
};

export default withTranslation()(ScenesCollapsePanel);
