import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Icon} from "antd";
import PropTypes from 'prop-types';

import BaseDropdown from "../../../../../../../../../../../../components/BaseDropdown/BaseDropdown";

import './MovieDetailsEnableDropdown.scss';

/* istanbul ignore file */
class MovieDetailsEnableDropdown extends Component {

  state = {
    value: 0
  };

  constructor(props) {
    super(props);
    this.state.value = props.value;
  }

  getItems = () => {
    const {t} = this.props;
    return [
      <div onClick={this.onEnableChange.bind(this, 1)} className="MovieDetailsInfosTabTable-enabled">
        {t('MovieDetailsEnableDropdown.enabled')}
      </div>,
      <div onClick={this.onEnableChange.bind(this, 0)} className="MovieDetailsInfosTabTable-disabled">
        {t('MovieDetailsEnableDropdown.disabled')}
      </div>
    ];
  };

  getStatusText = enable => {
    const {t} = this.props;
    return t(enable ? 'MovieDetailsEnableDropdown.enabled' : 'MovieDetailsEnableDropdown.disabled');
  };

  onEnableChange = (value) => {
    this.setState({value});
    this.props.onChange(value);
  };

  render() {
    const {value} = this.state;
    const {menuClassName} = this.props;
    return (
      <BaseDropdown className="MovieDetailsEnableDropdown"
                    items={this.getItems()}
                    menuClassName={menuClassName}>
        <Button className={value ? 'MovieEnabled' : 'MovieDisabled'}>
          {this.getStatusText(value)}
          <Icon type="down"/>
        </Button>
      </BaseDropdown>
    );
  }
}

MovieDetailsEnableDropdown.propTypes = {
  menuClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number
};

export default withTranslation()(MovieDetailsEnableDropdown);
