import React, { Component } from 'react';
import { Router } from '@reach/router';
import { withTranslation } from 'react-i18next';
import { Button } from "antd";

import AddScenePlaylistModal from "./ScenePlaylist/AddScenePlaylistModal/AddScenePlaylistModal";
import BaseNavigationItem from '../../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem';
import LoadingWrapper from '../../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import ScenePlaylist from './ScenePlaylist/ScenePlaylist';
import ScenePlaylistDetails from './ScenePlaylistDetails/ScenePlaylistDetails'

import { LogController } from '../../../../../../../../../../controllers/log-controller/log.controller';
import { ModalController } from "../../../../../../../../../../controllers/modal-controller/modal.controller";

import {
  getScenesPlaylist,
  deleteScenePlaylist
} from '../../../../../../../../../../services/movies-service/movies.service';
import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../services/notification-service/notification.service';
import {
  getMoviesRoute,
  getMoviesScenesPlaylistRoute
} from "../../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";

import { playlistType } from "../playlist-type";

import './ScenePlayLists.scss';

class ScenePlayLists extends Component {

  state = {
    dataLoaded: false,
    loadingMsg: '',
    playlistData: [],
    page: 0,
    pageCount: 0,
    perPage: 0,
    playlistName: '',
    total: 0
  };

  componentDidMount() {
    this.fetchScenePlaylist(1);
  };

  deletePlaylist = (data) => {
    const { t } = this.props;
    const message = t('ScenePlayLists.deletingPlaylist');
    this.setLoading(message);
    const playlistId = data.id;

    deleteScenePlaylist(playlistId)
      .then(this.onDeletePlaylistSuccess.bind(this, playlistId))
      .catch(this.onDeletePlaylistFailure)
  };

  fetchScenePlaylist = (page) => {
    const message = this.props.t('ScenePlayLists.loadingPleaseWait');
    this.setLoading(message);

    getScenesPlaylist(page)
      .then(this.loadScenePlaylist)
      .catch(this.onGetScenePlaylistFailure)
  };

  filterPlaylistsById = (id, item) => {
    return id !== item.id;
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <Button onClick={this.toggleAddPlaylist}>
        {t('ScenePlayLists.addPlaylist')}
      </Button>
    );
  };

  getRoutes = () => {
    const { t, location = {} } = this.props;
    const { playlistName } = this.state;
    const pathname = location.pathname || '';

    const routes = [{
      url: getMoviesRoute(),
      text: t('ScenePlayLists.movies')
    }, {
      text: t('ScenePlayLists.scenesPlaylists')
    }];

    if (pathname.includes('/playlists/')) {
      routes
        .splice(1, 1, {
          url: getMoviesScenesPlaylistRoute(),
          text: t('ScenePlayLists.scenesPlaylists')
        }, {
          text: playlistName
        })
    }

    return routes;
  };

  loadScenePlaylist = (response) => {
    const { scene_playlist, pagination } = response.data.data;
    const { current_page: page, last_page: pageCount, per_page: perPage, total } = pagination;
    this.setState({
      dataLoaded: true,
      loadingMsg: '',
      playlistData: scene_playlist,
      page,
      pageCount,
      perPage,
      total
    });
  };

  matchPlaylistById = (id, playlist) => {
    return playlist.id === id;
  };

  onDeletePlaylistFailure = () => {
    this.setLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('ScenePlayLists.deletePlaylistFailure')
    });
  }

  onDeletePlaylistSuccess = (playlistId) => {
    this.setLoading();

    this.playlistDeleted(playlistId);

    displaySuccessNotification({
      duration: 3,
      message: this.props.t('ScenePlayLists.deletePlaylistSuccess')
    });
  };

  onGetScenePlaylistFailure = (error) => {
    LogController.logError(error);
  };

  onPlaylistDetailsObtained = (playlistId, playlistName) => {
    this.setState({ playlistId, playlistName });
  };

  playlistChanged = (playlistId) => {
    const playlist = this.state.playlistData.find(this.matchPlaylistById.bind(this, playlistId));
    if (playlist) {
      this.fetchScenePlaylist();
    }
  };

  playlistDeleted = (playlistId) => {
    const filteredPlaylists = this.state.playlistData.filter(this.filterPlaylistsById.bind(this, playlistId));

    this.setState({
      ...this.state,
      playlistData: filteredPlaylists
    });
  };

  setLoading = message => {
    this.setState({
      loadingMsg: message
    });
  };

  toggleAddPlaylist = () => {
    const { page } = this.state;
    const modal = (
      <AddScenePlaylistModal onPlaylistAdded={this.fetchScenePlaylist.bind(this, page)} />
    );
    ModalController.showModal(modal);
  };

  render() {
    const { dataLoaded, loadingMsg, page, pageCount, perPage, playlistData, total } = this.state;
    const { contentHeight } = this.props;
    return (
      <LoadingWrapper
        className='ScenePlayListsView'
        dataLoaded={dataLoaded}
        isLoading={!!loadingMsg}
        loadingMsg={loadingMsg}>
        <BaseNavigationItem routes={this.getRoutes()}>
          {this.getActions()}
        </BaseNavigationItem>
        <Router>
          <ScenePlaylist
            path={"/"}
            contentHeight={contentHeight}
            category={playlistType.scene}
            playlistData={playlistData}
            deletePlaylist={this.deletePlaylist}
            onPageChange={this.fetchScenePlaylist}
            page={page}
            pageCount={pageCount}
            perPage={perPage}
            total={total} />
          <ScenePlaylistDetails
            path={`/:playlistId`}
            playlistDeleted={this.playlistDeleted}
            playlistChanged={this.playlistChanged}
            onPlaylistDetailsObtained={this.onPlaylistDetailsObtained}
            fetchScenePlaylist={this.fetchScenePlaylist} />
        </Router>
      </LoadingWrapper>
    );
  }
}

export default withTranslation()(ScenePlayLists);
