import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import BannerSetCard from "../../BannerSetCard/BannerSetCard";
import BannerSetPropertiesDefaultBanners from "../BannerSetPropertiesDefaultBanners/BannerSetPropertiesDefaultBanners";

import './BannerSetPropertiesMainView.scss';

/* istanbul ignore file */
class BannerSetPropertiesMainView extends Component {

  isMyBanner = (propertyId, data) => {
    let isMyBanner = true;
    const { exclusive_excluded } = data.exclusiveExcluded;
    if (exclusive_excluded.length) {
      const match = exclusive_excluded.find(this.matchByPropertyId.bind(this, propertyId));
      if (match) {
        isMyBanner = match.type === 'exclusive';
      }
    }

    return isMyBanner;
  };

  matchByPropertyId = (propertyId, { property_id }) => propertyId === property_id;

  matchSetByPropertyName = ({ properties: { name } }) => {
    return name.toLowerCase();
  };

  renderBannerSetCard = ({ banners, id, count_banners, properties, pagination }, index) => {
    const { bannerSetName } = this.props.data;
    let title;
    let isDefault = false;
    let propertyId;
    if (properties) { // This is data for property banner set
      title = `${properties.name}: ${bannerSetName}`;
      propertyId = properties.id;
    } else {
      isDefault = true;
      title = `Default: ${bannerSetName}`;
      count_banners = pagination.total;
      id = +this.props.bannerSetId;
    }
    const props = {
      bannerOrder: banners.slice(0, 6),
      bannerSetId: id,
      isDefault: isDefault,
      title: title,
      totalBannerCount: count_banners,
      key: index,
      allActionsIncluded: false,
      maxElementCount: 6,
      showSets: this.props.showSets
    };
    if (propertyId) {
      props.propertyId = propertyId
    }
    return isDefault ? (<BannerSetPropertiesDefaultBanners {...props} />) : (
      <BannerSetCard {...props} isMyBanner={this.isMyBanner.bind(this, propertyId)} />);
  };

  renderBannerSets = () => {
    const { defaultSet, propertiesSets } = this.props.data;
    let view = null;
    if (defaultSet) {
      const sets = [defaultSet].concat(propertiesSets.filter(this.matchSetByPropertyName));
      view = sets.map(this.renderBannerSetCard);
    }

    return view;
  };

  render() {
    return (
      <div className="BannerSetPropertiesMainView">
        {this.renderBannerSets()}
      </div>
    );
  }
}

BannerSetPropertiesMainView.propTypes = {
  bannerSetId: PropTypes.string,
  data: PropTypes.object,
  onUnmount: PropTypes.func,
  setLoading: PropTypes.func
};

export default withTranslation()(BannerSetPropertiesMainView);
