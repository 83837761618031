import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import {
  uploadCategoryImage
} from "../../../../../../../../../services/images-service/images.service";
import {
  getCategorySubCategoriesByName,
  deleteSubCategory
} from '../../../../../../../../../services/categories-service/subcategories-service/subcategories.service';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../../../../../../../services/notification-service/notification.service';
import { getThemesRoute } from "../../../../../../../../../services/navigation/categories-navigation/categories-navigation-routes.service";
import { getCategoryById } from "../../../../../../../../../services/categories-service/categories.service";

import AddSubCategoryModal from './AddSubCategoryModal/AddSubCategoryModal';
import BaseImagesDisplay from "../../../../../../../../../components/BaseImagesDisplay/BaseImagesDisplay";
import BaseNavigationItem from "../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import SubCategoriesView from './SubCategories/SubCategoriesView';
import TabsComponent from "../../../../../../../../../components/TabsComponent/TabsComponent";

import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";
import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import './CategoryDetails.scss';

class CategoryDetails extends Component {

  state = {
    activeTab: '1',
    banner: undefined,
    data: {},
    dataLoaded: false,
    loadingMsg: ''
  };

  componentDidMount() {
    this.fetchData();
  };

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  deleteSubCategory = (data) => {
    const { t } = this.props;
    const message = t('CategoryDetails.deletingSubCategory');
    this.setLoading(message);
    const subcategoryId = data.id;

    deleteSubCategory(subcategoryId)
      .then(this.onDeleteSubCategorySuccess(subcategoryId))
      .catch(this.onDeleteSubCategoryFailure)
  };

  fetchData = (callback) => {
    const { t } = this.props;
    const message = t('CategoryDetails.loadingMsg');
    this.setLoading(message);

    const { categoryId } = this.props;
    getCategoryById(categoryId)
      .then(this.onGetCategoryDetailsSuccess.bind(this, callback))
      .catch(this.onGetCategoryDetailsFailure);
  };

  filterByName = (name) => {
    const { t, categoryId } = this.props;
    this.setLoading(t('CategoryDetails.searching'));
    getCategorySubCategoriesByName(categoryId, name)
      .then(this.onGetFilteredSubCategoriesSuccess)
      .catch(this.onGetFilteredSubCategoriesFailure);
  };

  filterSubcategoryById = (id, item) => {
    return item.id !== id;
  };

  getActions = () => {
    const { t } = this.props;
    return [
      <Button onClick={this.showAddSubCategoryModal}
        key="addSubCategoryBtn">
        <Icon type="file-add" />
        {t('CategoryDetails.addSubCategory')}
      </Button>
    ];
  };

  getTabs = () => {
    const { t } = this.props;
    const { banner } = this.state;
    const tabs = [{
      title: t('CategoryDetails.subCategories'),
      view: (
        <SubCategoriesView
          listInfo={this.state}
          onNameFilterChange={this.filterByName}
          deleteSubCategory={this.deleteSubCategory}
          setLoading={this.setLoading}
          fetchData={this.fetchData}
        />
      )
    }];
    if (banner) {
      tabs.push({
        title: t('CategoryDetails.bannerImages'),
        view: (
          <div className="CategoryDetailsImagesTab">
            <BaseImagesDisplay images={banner.image} />
          </div>
        )
      });
    }

    return tabs;
  };

  onDeleteSubCategoryFailure = () => {
    this.setLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('CategoryDetails.deleteSubCategoryFailure')
    });
  };

  onDeleteSubCategorySuccess = (subcategoryId) => {
    this.setLoading();

    const filteredSubcategories = this.state.data.subcategory.filter(this.filterSubcategoryById.bind(this, subcategoryId));
    this.setState({
      data: {
        ...this.state.data,
        subcategory: filteredSubcategories
      }
    });

    displaySuccessNotification({
      duration: 3,
      message: this.props.t('CategoryDetails.deleteSubCategorySuccess')
    });
  };

  onGetCategoryDetailsFailure = (error) => {
    this.setLoading();
    LogController.logError(error);
  };

  onGetCategoryDetailsSuccess = (callback, response) => {
    const { data } = response.data;
    const state = {};
    this.setDocumentTitle(data);

    if (data.image && data.image.length === 0) {
      state.activeTab = '1';
    }

    Object.assign(state, {
      banner: data.banner,
      data: {
        name: data.name,
        id: data.id,
        subcategory: data.subcategory
      },
      dataLoaded: true
    });

    this.setState(state);
    this.setLoading();

    if (callback) {
      callback();
    }
  };

  onGetFilteredSubCategoriesFailure = (error) => {
    this.setLoading();
    LogController.logError(error);
  };

  onGetFilteredSubCategoriesSuccess = (response) => {
    const { data } = response.data;
    this.setState({
      data: {
        ...this.state.data,
        subcategory: data.subcategories
      },
      dataLoaded: true
    });
    this.setLoading();
  };

  setDocumentTitle = (data) => {
    const title = this.props.t('CategoryDetails.documentTitle', { name: data.name });
    DocumentTitleController.setDocumentTitle(title);
  };

  setLoading = (loadingMsg) => {
    this.setState({
      loadingMsg: loadingMsg
    });
  };

  showAddSubCategoryModal = () => {
    const modal = (
      <AddSubCategoryModal
        categoryId={this.props.categoryId}
        fetchData={this.fetchData}
      />
    );
    ModalController.showModal(modal);
  };

  renderBody = () => {
    return (
      <TabsComponent
        activeKey={this.state.activeTab}
        onChange={this.changeTab}
      >
        {this.getTabs()}
      </TabsComponent>
    );
  };

  renderHeader = () => {
    const { t } = this.props;
    const { name = '/' } = this.state.data;
    const routes = [{
      url: getThemesRoute(),
      text: t('CategoryDetails.categories')
    }, {
      text: name
    }];

    return (
      <BaseNavigationItem routes={routes}>
        {this.getActions()}
      </BaseNavigationItem>
    );
  };

  uploadImages = (file, type) => {
    const { categoryId } = this.props;
    return uploadCategoryImage(categoryId, type, file);
  };

  render() {
    const { dataLoaded } = this.state;
    return (
      <LoadingWrapper className="CategoryDetails"
        dataLoaded={dataLoaded}
        isLoading={!!this.state.loadingMsg}
        loadingMsg={this.state.loadingMsg}>
        {this.renderHeader()}
        {this.renderBody()}
      </LoadingWrapper>
    );
  }
}

CategoryDetails.propTypes = {
  categoryId: PropTypes.string
};

export default withTranslation()(CategoryDetails);
