import {withTranslation} from "react-i18next";

import UploadImages from "../../../../../../../../../../components/modal/UploadImages/UploadImages";

class UploadMovieImagesModal extends UploadImages {

  /**
   * @override
   */
  getImageCategories = () => {
    const {t} = this.props;
    return [t('UploadMovieImagesModal.tile')];
  };
}

export default withTranslation()(UploadMovieImagesModal);