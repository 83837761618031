import PropTypes from 'prop-types';

import {getPropertyMovieDetailsRoute} from "../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";
import {showPropertyMovieDetails} from "../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation.service";

import MoviesViewListAbstract from "../../../../MoviesView/MoviesViewListAbstract/MoviesViewListAbstract";

class PropertyMoviesList extends MoviesViewListAbstract {

  constructor(props) {
    super(props);
    this.state.stickyPosition = 0;
  }

  /**
   * @override
   */
  getMovieDetailsRoute = (movieId) => {
    const {propertyId} = this.props;
    return getPropertyMovieDetailsRoute(propertyId, movieId);
  };

  /**
   * @override
   */
  showMovieDetails = (movieId) => {
    const {propertyId} = this.props;
    showPropertyMovieDetails(propertyId, movieId);
  };
}

PropertyMoviesList.propTypes = {
  propertyId: PropTypes.string.isRequired
};

export default PropertyMoviesList;
