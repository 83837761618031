import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Button, Table, List} from "antd";
import PropTypes from 'prop-types';

import ConfirmationModal
  from "../../../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";
import BaseCard from '../../../../../../../../../../../../components/BaseCard/BaseCard';
import LinkButton from '../../../../../../../../../../../../components/LinkButton/LinkButton';

import {ModalController} from "../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import {renderMoment} from '../../../../../../../../../../../../services/date-service/date.service';
import {getScenePlaylistDetailsRoute} from '../../../../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service';

import './SceneMainList.scss';

class SceneMainList extends Component {

  renderActions = (item, data) => {
    const {t} = this.props;
    return (
      <div className="SceneMainListActions">
        <LinkButton href={getScenePlaylistDetailsRoute(data.id)}>
          {t('SceneMainList.viewDetails')}
        </LinkButton>
        <Button onClick={this.showDeleteConfirmationModal.bind(this, data)}>
          {t('SceneMainList.delete')}
        </Button>
      </div>
    );
  };

  columns = [{
    dataIndex: 'name',
    title: this.props.t('SceneMainList.name')
  }, {
    dataIndex: 'playlist_key',
    title: this.props.t('SceneMainList.key')
  }, {
    dataIndex: 'scene_in_playlist_count',
    title: this.props.t('SceneMainList.scenes')
  }, {
    dataIndex: 'created_at',
    render: renderMoment,
    title: this.props.t('SceneMainList.createdOn')
  }, {
    align: 'center',
    dataIndex: 'actions',
    title: this.props.t('SceneMainList.actions'),
    render: this.renderActions,
    width: 400
  }];

  renderPlaylistList = () => {
    return (
      <List
        dataSource={this.props.data}
        renderItem={this.renderPlaylistListItem}
        split={false}
        itemLayout='vertical'
      />
    );
  };

  renderPlaylistListItem = item => {
    const {id, name, scene_in_playlist_count, created_at, playlist_key} = item;
    const {t} = this.props;
    return (
      <List.Item key={id}>
        <BaseCard
          title={t('SceneMainList.title', {name})}
          footer={this.renderActions(item, item)}
        >
          <div className='ListCardWrapper'>
            <p>
              <span className='ListItemLabel'>
                {t('SceneMainList.name')}:
              </span>
              {name}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('SceneMainList.key')}:
              </span>
              {playlist_key}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('SceneMainList.scenes')}:
              </span>
              {scene_in_playlist_count}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('SceneMainList.createdOn')}:
              </span>
              {renderMoment(created_at)}
            </p>
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  renderPlaylistTable = () => {
    return (
      <Table
        dataSource={this.props.data}
        columns={this.columns}
        pagination={false}
        rowKey='id'
        bordered
      />
    );
  };

  showDeleteConfirmationModal = data => {
    const {t} = this.props;
    const modal = (
      <ConfirmationModal
        title={t('SceneMainList.confirmDelete')}
        message={t('SceneMainList.deletionQuestion')}
        yesBtnText={t('SceneMainList.deletePlaylist')}
        noBtnText={t('SceneMainList.cancel')}
        confirm={this.props.deletePlaylist.bind(this, data)}
      />
    );
    ModalController.showModal(modal);
  };

  render() {
    return (
      <div className="SceneMainList">
        {this.renderPlaylistTable()}
        {this.renderPlaylistList()}
      </div>
    );
  };
}

SceneMainList.propTypes = {
  data: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  scroll: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default withTranslation()(SceneMainList);
