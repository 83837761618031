import {withTranslation} from "react-i18next";

import {getMovies} from '../../../../services/movies-service/movies.service';

import {uploadMovieImage, assignMovieImages} from '../../../../services/images-service/images.service'

import BaseGlobalImagesAssignSelectionModal from '../../../../components/BaseGlobalImagesAssignSelectionModal/BaseGlobalImagesAssignSelectionModal';

class MovieSelectionModal extends BaseGlobalImagesAssignSelectionModal {

  assignImagesData = (data) => {
    return assignMovieImages(data);
  };

  getAssignButtonTitle = () => {
    const {t} = this.props;
    return t('MovieSelectionModal.assignImagesToMovie');
  };

  getAssignData = (id, fileId, type) => {
    return {
      movies_id: id,
      images_id: fileId,
      type: type
    };
  };

  getImageCategories = () => {
    const {t} = this.props;
    return [t('MovieSelectionModal.tile')];
  };

  getInputPlaceholder = () => {
    const {t} = this.props;
    return t('MovieSelectionModal.enterMovieId');
  };

  getSelectConfig = () => {
    return {
      getData: getMovies, 
      name: 'movies',
      id: 'movieId'
    };
  };

  getSelectInputLabel = () => {
    const {t} = this.props;
    return t('MovieSelectionModal.label');
  };

  getTitle = () => {
    const {t} = this.props;
    return t('MovieSelectionModal.title');
  };

  uploadImage = (id, type, file) => {
    return uploadMovieImage(id, type, file);
  };
}

export default withTranslation()(MovieSelectionModal);