import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Divider, Select, Spin} from "antd";
import PropTypes from 'prop-types';

import {getMovies} from "../../../../../../../../../../services/movies-service/movies.service";

import {LogController} from "../../../../../../../../../../controllers/log-controller/log.controller";

import './BannerMovieSelect.scss';

class BannerMovieSelect extends Component {

  initialLoad = false;

  searchDebounce = 300;

  state = {
    filter: '',
    loading: false,
    movies: [],
    page: 1,
    pageCount: 1,
    selectedValue: []
  };

  searchTimeoutId;

  componentDidMount() {
    this.loadMovies(true);
  }

  canLoadMore = () => {
    const {page, pageCount} = this.state;
    return page < pageCount;
  };

  getFilters = () => {
    const {filter} = this.state;
    return filter ? [`search=${filter}`] : [];
  };

  handleGetMoviesError = error => {
    LogController.logError(error);
    this.setLoading(false);
  };

  loadData = response => {
    const {movies, pagination} = response.data.data;
    this.setState(prevState => {
      const data = this.initialLoad ? movies : prevState.movies.concat(movies);
      return {
        loading: false,
        page: prevState.page + 1,
        movies: data,
        pageCount: pagination.last_page
      };
    });
  };

  loadFilteredMovies = () => {
    this.setState({
      page: 1
    });
    this.loadMovies(true);
  };

  loadMovies = (initialLoad) => {
    const {loading} = this.state;
    if (initialLoad || (!loading && this.canLoadMore())) { //Only trigger if we can load a page
      this.initialLoad = initialLoad;
      this.setLoading(true);
      const filters = this.getFilters();
      const {page} = this.state;
      getMovies(page, filters)
        .then(this.loadData)
        .catch(this.handleGetMoviesError);
    }
  };

  movieMap = (movie) => {
    return (
      <Select.Option value={movie.movieId}
                     key={movie.movieId}>
        {movie.title}
      </Select.Option>
    );
  };

  onChange = (movieId) => {
    this.setState({
      selectedValue: movieId
    });
    this.props.onMovieSelect(movieId);
  };

  onSearch = value => {
    this.setState({
      filter: value
    });
    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }
    this.searchTimeoutId = setTimeout(this.loadFilteredMovies, this.searchDebounce);
  };

  preventEventDefault = event => {
    event.preventDefault();
  };

  renderChildren = () => {
    return this.state.movies.map(this.movieMap);
  };

  renderDivider = (canLoadMore) => {
    return canLoadMore ? (
      <Divider className="BannerMovieSelect-dropdown-divider"/>
    ) : null;
  };

  renderDropdown = menu => {
    const {loading} = this.state;
    const canLoadMore = this.canLoadMore();
    return (
      <div>
        {menu}
        {this.renderDivider(canLoadMore)}
        {loading ? this.renderLoadingIndicator() : this.renderLoadMore(canLoadMore)}
      </div>
    )
  };

  renderLoadingIndicator = () => {
    return (
      <Spin size="small"
            className="BannerMovieSelect-spinner"/>
    );
  };

  renderLoadMore = (canLoadMore) => {
    const {t} = this.props;
    return canLoadMore ? (
      <div onMouseDown={this.preventEventDefault}
           onClick={this.loadMovies.bind(this, false)}
           className="BannerMovieSelect-loadMore">
        {t('BannerMovieSelect.loadMore')}
      </div>
    ) : null;
  };

  setLoading = loading => {
    this.setState({
      loading: loading
    });
  };

  render() {
    const {t} = this.props;
    const {selectedValue} = this.state;
    return (
      <div className="BannerMovieSelect">
        <div>{t('BannerMovieSelect.label')}</div>
        <Select value={selectedValue}
                showSearch
                onSearch={this.onSearch}
                filterOption={false}
                dropdownRender={this.renderDropdown}
                dropdownClassName="BannerMovieSelect-dropdown"
                onChange={this.onChange}>
          {this.renderChildren()}
        </Select>
      </div>
    );
  }
}

BannerMovieSelect.propTypes = {
  onMovieSelect: PropTypes.func.isRequired
};

export default withTranslation()(BannerMovieSelect);