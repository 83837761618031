import React, {Component} from 'react';
import {Router} from "@reach/router";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";

import {withDocumentTitleUpdate} from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import {getProperties} from "../../../../../../../../services/properties-service/properties.service";

import {LogController} from "../../../../../../../../controllers/log-controller/log.controller";

import PropertiesMainView from "./PropertiesMainView/PropertiesMainView";
import PropertiesViewDetails from "./PropertiesViewDetails/PropertiesViewDetails";

import './PropertiesView.scss';

class PropertiesView extends Component {

  state = {
    dataLoaded: false,
    loading: false,
    properties: [],
  };

  componentDidMount() {
    this.fetchProperties();
  }

  fetchProperties = (visible = 1) => {
    this.setLoading(true);
    getProperties(visible)
      .then(this.loadData)
      .catch(this.onLoadPropertiesFailure);
  };

  getDocumentTitle = () => {
    return this.props.t('PropertiesView.documentTitle');
  };

  loadData = (response) => {
    const {properties} = response.data.data;
    this.setState({
      dataLoaded: true,
      loading: false,
      properties: properties,
    });
  };

  onLoadPropertiesFailure = (error) => {
    LogController.logError(error);
  };

  setLoading = (loading) => {
    this.setState({
      loading: loading
    });
  };

  render() {
    return (
      <div className="PropertiesView">
        <Router>
          <PropertiesMainView path={"/"}
                              contentHeight={this.props.contentHeight}
                              listInfo={this.state}
                              onChange={this.fetchProperties}/>
          <PropertiesViewDetails path={`/:propertyId/*`}
                                 contentHeight={this.props.contentHeight}/>
        </Router>
      </div>
    );
  }
}

export default compose(withTranslation(), withDocumentTitleUpdate)(PropertiesView);

