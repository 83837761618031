import React from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import SexActMoviesFooter from "./SexActMoviesFooter/SexActMoviesFooter";
import SexActMoviesList from "./SexActMoviesList/SexActMoviesList";

import MoviesMainViewAbstract from "../../MoviesMainViewAbstract/MoviesMainViewAbstract";

class SexActMoviesMainView extends MoviesMainViewAbstract {

  renderFooter = () => {
    const {data, fetchMovies} = this.props;
    return (
      <SexActMoviesFooter onChange={fetchMovies}
                          currentPage={data.currentPage}
                          pageCount={data.pageCount}
                          pageSize={data.pageSize}
                          recordCount={data.recordCount}/>
    );
  };

  renderMoviesList = () => {
    const {data, setMovieEnabled, sexActId} = this.props;
    return (
      <SexActMoviesList movies={data.movies}
                        sexActId={sexActId}
                        setMovieEnabled={setMovieEnabled}/>
    )
  };
}

SexActMoviesMainView.propTypes = {
  sexActId: PropTypes.string.isRequired
};

export default withTranslation()(SexActMoviesMainView);
