import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Collapse, Icon, Table } from "antd";
import PropTypes from "prop-types";

import BaseCard from "../../../../../../../../../../../components/BaseCard/BaseCard";
import CollapsiblePanel from "../../shared/CollapsiblePanel/CollapsiblePanel";
import EditPropertyAdModal from "../adConfiguration/EditAdConfigurationModal/EditAdConfigurationModal";

import { ConfigureAdsOnPropertyLevelController } from "../ConfigureAdsOnPropertyLevelController";
import { ModalController } from "../../../../../../../../../../../controllers/modal-controller/modal.controller";

import {
	renderMoment,
	renderMomentFormattedTime,
} from "../../../../../../../../../../../services/date-service/date.service";

import { mapInvalidColors } from "../../shared/error-colors-schema";

import "./AdsCollapse.scss";

/* istanbul ignore file */
class AdsCollapse extends Component {
	adColumns = [
		{
			dataIndex: "name",
		},
		{
			dataIndex: "value",
		},
	];

	colors = {};

	state = {
		activeKeys: [],
		validationTriggered: false,
	};

	subscriptions = {};

	constructor(props) {
		super(props);
		this.state.activeKeys = Array.from(Array(props.ads.length).keys());
		this.subscriptions.validation =
			ConfigureAdsOnPropertyLevelController.validationEvent.subscribe(
				this.triggerValidation
			);
	}

	componentWillUnmount() {
		for (const key in this.subscriptions) {
			this.subscriptions[key].unsubscribe();
		}
	}

	adMap = (ad, index) => {
		const data = this.getAdTableData(ad);
		const { validationTriggered } = this.state;
		const classes = ["PropertyAdParent"];
		const style = {};
		if (validationTriggered) {
			const color = this.getPanelColor(index);
			if (color) {
				style.background = color;
			} else {
				classes.push("Valid");
			}
		}
		return (
			<Collapse.Panel
				header={this.getTitle(ad, index)}
				key={index}
				className={classes.join(" ")}
				style={style}
			>
				<BaseCard
					className="PropertyAd"
					footer={this.getAdCardFooter(index)}
				>
					<div className="PropertyAd-inner">
						<Table
							dataSource={data}
							rowKey={"id"}
							columns={this.adColumns}
							showHeader={false}
							pagination={false}
							bordered
						/>
					</div>
				</BaseCard>
			</Collapse.Panel>
		);
	};

	editPropertyAd = (index) => {
		const { property, propertyId } = this.props;
		const ad = property.ads[index];
		const modal = (
			<EditPropertyAdModal
				onPropertyAdEdit={this.onPropertyAdEdit}
				index={index}
				propertyId={propertyId}
				ad={ad}
			/>
		);
		ModalController.showModal(modal);
	};

	getAdCardFooter = (index) => {
		const { t } = this.props;
		return (
			<div className="PropertyAdCardFooter">
				<Button onClick={this.editPropertyAd.bind(this, index)}>
					{t("ConfigureAdsOnPropertyLevel.AdsCollapse.edit")}
				</Button>
			</div>
		);
	};

	getAdTableData = (ad) => {
		const { t } = this.props;
		const {
			applyToLoggedUser,
			applyToNotLoggedUser,
			appliedEndDate,
			appliedEndTime,
			appliedStartDate,
			appliedStartTime,
			selectedApplicationTime,
			selectedSection,
			selectedTarget,
			goToText,
			goToUrl,
			type,
			url,
			duration,
		} = ad;
		const appliedUsers = [];
		if (applyToLoggedUser) {
			appliedUsers.push(
				t("ConfigureAdsOnPropertyLevel.AdsCollapse.loggedUser")
			);
		}
		if (applyToNotLoggedUser) {
			appliedUsers.push(
				t("ConfigureAdsOnPropertyLevel.AdsCollapse.notLoggedUser")
			);
		}
		const data = [
			{
				name: t("ConfigureAdsOnPropertyLevel.AdsCollapse.adValidFor"),
				value: appliedUsers.join(", "),
				id: "userState",
			},
			{
				name: t(
					"ConfigureAdsOnPropertyLevel.AdsCollapse.adAppliesToAll"
				),
				value: selectedSection,
				id: "selectedSection",
			},
			{
				name: t("ConfigureAdsOnPropertyLevel.AdsCollapse.adTarget"),
				value: t(
					`ConfigureAdsOnPropertyLevel.AdsCollapse.${selectedTarget}`
				),
				id: "selectedTarget",
			},
			{
				name: t(
					"ConfigureAdsOnPropertyLevel.AdsCollapse.adWillStartAt"
				),
				value: selectedApplicationTime,
				id: "selectedApplicationTime",
			},
			{
				name: t("ConfigureAdsOnPropertyLevel.AdsCollapse.adStartDate"),
				value: this.getAdDate(appliedStartDate, appliedStartTime),
				id: "startDate",
			},
			{
				name: t("ConfigureAdsOnPropertyLevel.AdsCollapse.adEndDate"),
				value: this.getAdDate(appliedEndDate, appliedEndTime),
				id: "endDate",
			},
			{
				name: t("ConfigureAdsOnPropertyLevel.AdsCollapse.adGoToText"),
				value: goToText,
				id: "goToText",
			},
			{
				name: t("ConfigureAdsOnPropertyLevel.AdsCollapse.adGoToUrl"),
				value: goToUrl,
				id: "goToUrl",
			},
			{
				name: t("ConfigureAdsOnPropertyLevel.AdsCollapse.adType"),
				value: type,
				id: "type",
			},
			{
				name: t("ConfigureAdsOnPropertyLevel.AdsCollapse.adSourceUrl"),
				value: url,
				id: "url",
			},
		];
		if (type === "image") {
			data.push({
				name: t("ConfigureAdsOnPropertyLevel.AdsCollapse.duration"),
				value: duration,
				id: "duration",
			});
		}

		return data;
	};

	getPanelColor = (index) => {
		let color;
		const { invalidKeys } = this.props;
		if (invalidKeys) {
			color = this.getValidationColor(index);
		}

		return color;
	};

	getTitle = (ad, index) => {
		const { t } = this.props;
		return (
			<div className="Title">
				<div>
					{t("ConfigureAdsOnPropertyLevel.AdsCollapse.title", {
						index,
					})}
					{this.getAdRangeText(ad)}
				</div>
				<Icon type="close" onClick={this.removeAd.bind(this, index)} />
			</div>
		);
	};

	getValidationColor = (index) => {
		let color;
		for (const key in this.colors) {
			if (this.colors[key].values.indexOf(index) !== -1) {
				color = this.colors[key].shade;
			}
		}

		return color;
	};

	getAdDate = (date, time, returnText = false) => {
		let adDate;
		if (date && date.isValid()) {
			adDate = renderMoment(date);
			if (time) {
				adDate = `${adDate}, ${renderMomentFormattedTime(time)}`;
			}
			if (!time.isValid()) {
				adDate = '';
			}
		} else {
			const message = this.props.t("ConfigureAdsOnPropertyLevel.AdsCollapse.adTimeNotConfigured");
			adDate = !returnText ? (
				<div className="AdTimeNotConfigured">
					{message}
				</div>
			): message;
		}
		return adDate;
	};

	getAdRangeText = (ad) => {
		const {
			appliedEndDate,
			appliedEndTime,
			appliedStartDate,
			appliedStartTime,
		} = ad;
		let rangeText;
		if (appliedStartDate) {
			rangeText = ` [${this.getAdDate(appliedStartDate, appliedStartTime, true)} - ${this.getAdDate(appliedEndDate, appliedEndTime, true)}]`;
		} else {
			rangeText = ` [${this.props.t(
				"ConfigureAdsOnPropertyLevel.AdsCollapse.adTimeNotConfigured"
			)}]`;
		}
		return rangeText;
	};

	onActiveAdsChange = (activeKeys) => {
		this.setState({ activeKeys });
	};

	onPropertyAdEdit = (propertyId, index, data) => {
		const { onPropertyAdsChange } = this.props;
		onPropertyAdsChange(propertyId, index, data);
	};

	removeAd = (adIndex) => {
		this.props.onRemovePropertyAd(adIndex);
	};

	triggerValidation = () => {
		this.setState({
			validationTriggered: new Date().getTime(),
		});
	};

	render() {
		const { ads, invalidKeys } = this.props;
		const { activeKeys, validationTriggered } = this.state;
		this.colors = mapInvalidColors(invalidKeys);
		const classes = ["AdsCollapse"];
		if (validationTriggered) {
			classes.push("ValidationTriggered");
		}
		return (
			<CollapsiblePanel
				className={classes.join(" ")}
				activeKeys={activeKeys}
				onChange={this.onActiveAdsChange}
				defaultActiveKeys={activeKeys}
			>
				{ads.map(this.adMap)}
			</CollapsiblePanel>
		);
	}
}

AdsCollapse.propTypes = {
	ads: PropTypes.array,
	invalidKeys: PropTypes.object,
	onPropertyAdsChange: PropTypes.func.isRequired,
	onRemovePropertyAd: PropTypes.func.isRequired,
	property: PropTypes.object.isRequired,
	propertyId: PropTypes.string.isRequired,
};

export default withTranslation()(AdsCollapse);
