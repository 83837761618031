import { Button, Input } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../../services/notification-service/notification.service';
import { getWhitelabelConfig, patchWhitelabelConfig } from '../../../../../../../../../../services/properties-service/properties.service';

import "./EditNATSCodeModal.scss";

class EditNATSCodeModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: "",
		};
		this.modalRef = React.createRef();
	}

	componentDidMount() {
		const { t, propertyId } = this.props;
		this.getModal().setLoading(t("EditNATSCodeModal.loadingMsg"));
		getWhitelabelConfig(propertyId)
			.then(this.handleWhitelabelData)
			.catch(this.onFetchPropertyFail)
	}

	handleWhitelabelData = (response) => {
		this.getModal().clearLoading();
		const { nats_code } = response.data.data;
		this.setState({ code: nats_code });
	}

	getModal = () => {
		return this.modalRef.current;
	};

	getTitle = () => {
		const { t, propertyName } = this.props;
		return t("EditNATSCodeModal.title", { propertyName });
	};

	handleInputStateChange = (e) => {
		const stateField = e.target.getAttribute("data-name");
		let { value } = e.target;
		if (!value.length) {
			value = null;
		}
		this.setState({ [stateField]: value });
	}

	renderModalInputs = () => {
		const { code } = this.state;

		return (
			<>
				<div>
					<p>NATS Code:</p>
					<Input data-name="code" onChange={this.handleInputStateChange} value={code || ''} />
				</div>
			</>
		);
	};

	getActions = () => {
		const { t } = this.props;
		return (
			<div className="EditNATSCodeModal-actions">
				<Button onClick={this.onCancel.bind(this)} key="cancelBtn">
					{t("EditNATSCodeModal.cancel")}
				</Button>
				<Button key="saveChangesBtn" onClick={this.onSave.bind(this)}>
					{t("EditNATSCodeModal.saveChanges")}
				</Button>
			</div>
		);
	};

	onCancel = () => {
		this.getModal().closeModal();
	};

	onSave = () => {
		const { code } = this.state;
		const { propertyId } = this.props;
		patchWhitelabelConfig(propertyId, { nats_code: code ? code : null })
			.then(this.onSaveChangesSuccess)
			.catch(this.onSaveChangesFailure);
	};

	onFetchPropertyFail = () => {
		this.getModal().clearLoading();
		displayErrorNotification({
			duration: 3,
			message: this.props.t("EditBannerSetModal.onFetchPropertyFail"),
		});
		this.onCancel();
	}

	onSaveChangesFailure = () => {
		this.getModal().clearLoading();
		displayErrorNotification({
			duration: 3,
			message: this.props.t("EditBannerSetModal.saveFailureMsg"),
		});
		this.onCancel();
	};

	onSaveChangesSuccess = () => {
		this.getModal().clearLoading();
		displaySuccessNotification({
			duration: 3,
			message: this.props.t("EditBannerSetModal.saveSuccessMsg"),
		});
		this.onCancel();
	};

	render() {
		return (
			<ModalDialog
				title={this.getTitle()}
				actions={this.getActions()}
				forwardedRef={this.modalRef}
			>
				<div className="EditNATSCodeModal">
					{this.renderModalInputs()}
				</div>
			</ModalDialog>
		);
	}
}

export default withTranslation()(EditNATSCodeModal);

EditNATSCodeModal.propTypes = {
	propertyId: PropTypes.number,
	propertyName: PropTypes.string
}