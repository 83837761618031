import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Dropdown, Button, Icon, List, Menu, Table } from "antd";
import PropTypes from 'prop-types';

import {
  getStudioDetailsRoute,
  getStudioMoviesRoute
} from "../../../../../../../../../../services/navigation/studios-navigation/studios-navigation-routes.service";
import { renderMoment } from '../../../../../../../../../../services/date-service/date.service';
import { renderListClickableItem } from "../../../../../../../../../../services/util-service/util.service";

import BaseCard from '../../../../../../../../../../components/BaseCard/BaseCard';
import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";

import './StudiosViewBody.scss';

class StudiosViewBody extends Component {

  onStudioEdit = (id) => {
    const { onStudioEdit } = this.props;
    onStudioEdit(id);
  };

  renderActionItem = (item, { id }) => {
    const { t } = this.props;
    return (
      <div className="UserActions">
        <LinkButton href={getStudioDetailsRoute(item.id)}>
          {t('StudiosViewBody.viewDetails')}
        </LinkButton>
        <Button className="EditStudioBtn"
          onClick={this.onStudioEdit.bind(this, id)}>
          {t('StudiosViewBody.edit')}
        </Button>
      </div>
    );
  };

  renderEnableDisableDropdown = (status, item) => {
    const studioId = item.id;
    return (
      <div className="StatusItem">
        <Dropdown overlay={this.getEnableDisableDropdownMenu(studioId)}>
          <Button className={status ? 'StudioEnabled' : 'StudioDisabled'}>
            {this.getStatusText(status)}
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
    );
  };

  getEnableDisableDropdownMenu = studioId => {
    const { t } = this.props;
    return (
      <Menu className="StudioCard-enabledDisabled">
        <Menu.Item>
          <div
            onClick={this.setStudioEnabled.bind(this, 1, studioId)}
            className="StudioCard-enabled">
            {t('StudiosViewBody.enabled')}
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={this.setStudioEnabled.bind(this, 0, studioId)}
            className="StudioCard-disabled">
            {t('StudiosViewBody.disabled')}
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  renderDate = (date) => {
    let render = '';
    if (date) {
      const split = date.split('/');
      render = renderMoment(`${split[2]}-${split[0]}-${split[1]}`);
    }

    return render;
  };

  renderMoviesItem = (moviesCount, data) => {
    return renderListClickableItem(data, undefined, `studio-movies-${data.id}`, getStudioMoviesRoute, this.getMoviesCountText);
  };

  getMoviesCountText = ({ moviesCount }) => {
    return this.props.t('StudiosViewBody.moviesCount', { moviesCount: moviesCount });
  };

  getStatusText = status => {
    const { t } = this.props;
    return t(status ? 'StudiosViewBody.enabled' : 'StudiosViewBody.disabled');
  };

  columns = [{
    dataIndex: 'name',
    title: this.props.t('StudiosViewBody.name'),
    key: 'name',
    width: 300
  }, {
    dataIndex: 'id',
    title: this.props.t('StudiosViewBody.id'),
    key: 'id',
    width: 100
  }, {
    dataIndex: 'moviesCount',
    title: this.props.t('StudiosViewBody.movies'),
    render: this.renderMoviesItem,
    key: 'movies',
    width: 150
  }, {
    dataIndex: 'foundedDate',
    title: this.props.t('StudiosViewBody.foundedDate'),
    render: this.renderDate,
    key: 'foundedDate',
    width: 200
  }, {
    align: 'center',
    dataIndex: 'status',
    title: this.props.t('StudiosViewBody.status'),
    key: 'status',
    render: this.renderEnableDisableDropdown,
    width: 200
  }, {
    title: this.props.t('StudiosViewBody.action'),
    align: 'center',
    key: 'action',
    render: this.renderActionItem,
    width: 220
  }];

  getCardTableData = (item) => {
    const { t } = this.props;
    return [{
      name: `${t('StudiosViewBody.name')}:`,
      value: item.moviesCount,
    }, {
      name: `${t('StudiosViewBody.foundedDate')}:`,
      value: this.renderDate(item.foundedDate)
    }, {
      name: `${t('StudiosViewBody.status')}:`,
      value: this.renderEnableDisableDropdown(item.status, item)
    }, {
      name: `${t('StudiosViewBody.movies')}:`,
      value: this.renderMoviesItem(item.moviesCount, item)
    }];
  };

  renderListItem = (item) => {
    const columns = [{
      dataIndex: 'name',
      key: 'name'
    }, {
      align: 'center',
      dataIndex: 'value',
      key: 'value'
    }];
    return (
      <BaseCard
        key={item.id}
        title={`${item.name} (ID: ${item.id})`}
        footer={this.renderActionItem(item, item)}
      >
        <Table dataSource={this.getCardTableData(item)}
          rowKey={'name'}
          showHeader={false}
          pagination={false}
          columns={columns}
          bordered
        />
      </BaseCard>
    );
  };

  setStudioEnabled = (enabled, studioId) => {
    this.props.setStudioEnabled(studioId, enabled);
  };

  render() {
    const { studios } = this.props;
    return (
      <div className="StudiosViewBody">
        <List
          className="ListView"
          itemLayout="vertical"
          dataSource={studios}
          renderItem={this.renderListItem}
          split={false}
        />
        <Table
          className="StudiosTable"
          columns={this.columns}
          dataSource={studios}
          pagination={false}
          bordered
          rowKey="id"
          size='middle'
        />
      </div>
    );
  }
}

StudiosViewBody.propTypes = {
  onStudioEdit: PropTypes.func.isRequired,
  scroll: PropTypes.object.isRequired,
  setStudioEnabled: PropTypes.func.isRequired,
  studios: PropTypes.array.isRequired
};

export default withTranslation()(StudiosViewBody);

