import {Subject} from 'rxjs';

class BannerCtrl {
  static instance;
  bannerDetailsUpdated = new Subject();
  bannerSetsUpdate = new Subject();

  static getInstance() {
    if (BannerCtrl.instance == null) {
      BannerCtrl.instance = new BannerCtrl();
    }

    return BannerCtrl.instance;
  }

  notifyBannerDetailsUpdate () {
    const instance = BannerCtrl.getInstance();
    instance.bannerDetailsUpdated.next();
  }

  notifyBannerSetsUpdate () {
    const instance = BannerCtrl.getInstance();
    instance.bannerSetsUpdate.next();
  }
}

export const BannerController = BannerCtrl.getInstance();
