import React, {Component} from 'react';
import {cloneDeep} from 'lodash';
import {withTranslation} from "react-i18next";
import {Icon} from 'antd';

import {
  getMoviesPlaylistById,
  deleteMoviePlaylist
} from '../../../../../../../../../../../services/movies-service/movies.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../../services/notification-service/notification.service';
import {showMoviesPlaylist} from '../../../../../../../../../../../services/navigation/movies-navigation/movies-navigation.service';

import {ModalController} from '../../../../../../../../../../../controllers/modal-controller/modal.controller';
import {LogController} from "../../../../../../../../../../../controllers/log-controller/log.controller";

import ConfirmationModal from '../../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal';
import LoadingWrapper from "../../../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import TabsComponent from "../../../../../../../../../../../components/TabsComponent/TabsComponent";
import BaseHeader from '../../../../../../../../../../../components/BaseHeader/BaseHeader';

import MoviePlaylistDetailsTab from './components/MoviePlaylistDetailsTab/MoviePlaylistDetailsTab';
import MoviesPlaylistMoviesTab from './components/MoviesPlaylistMoviesTab/MoviesPlaylistMoviesTab';
import MoviePlaylistDetailsFooter from './components/MoviePlaylistDetailsFooter/MoviePlaylistDetailsFooter';
import EditMoviePlaylistModal from './EditMoviePlaylistModal/EditMoviePlaylistModal';
import AddMoviesModal from './AddMoviesModal/AddMoviesModal';

import './MoviePlaylistDetails.scss';


class MoviePlaylistDetails extends Component {

  movieDeleting = false;

  state = {
    activeTab: '1',
    data: {},
    dataLoaded: false,
    loadingMsg: '',
  };

  componentDidMount() {
    this.fetchPlaylistById();
  };

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  deletePlaylist = () => {
    const {id} = this.state.data;

    deleteMoviePlaylist(id)
      .then(this.onDeletePlaylistSuccess)
      .catch(this.onDeletePlaylistFailure);
  };

  editPlaylist = () => {
    const modal = (
      <EditMoviePlaylistModal
        playlistId={this.state.data.id}
        onEditPlaylist={this.onEditPlaylist}
      />
    );
    ModalController.showModal(modal);
  };

  fetchPlaylistById = () => {
    const message = this.props.t('MoviePlaylistDetails.loadingDetailsPleaseWait');
    this.setLoading(message);

    const {playlistId} = this.props;
    getMoviesPlaylistById(playlistId)
      .then(this.onGetMoviesPlaylistByIdSuccess)
      .catch(this.onGetMoviesPlaylistByIdFailure);
  };

  getMinViewDropdownItems = () => {
    const {t} = this.props;
    return [
      <div onClick={this.editPlaylist}>
        <Icon type='edit'/>
        {t('MoviePlaylistDetails.editPlaylist')}
      </div>,
      <div onClick={this.showDeletePlaylistModal}>
        <Icon type='delete'/>
        {t('MoviePlaylistDetails.deletePlaylist')}
      </div>,
      <div onClick={this.showAddMoviesModal}>
        <Icon type='file-add'/>
        {t('MoviePlaylistDetails.addMovies')}
      </div>
    ];
  };

  getTabs = () => {
    const {t} = this.props;
    const {movie_in_playlist_count} = this.state.data;
    let movieTab = {};

    if (movie_in_playlist_count && movie_in_playlist_count !== 0) {
      movieTab = {
        title: t('MoviePlaylistDetails.movies'),
        view: (
          <MoviesPlaylistMoviesTab
            detailsData={this.state.data}
            onMovieDelete={this.onMovieDelete}
          />
        )
      };
    }
    ;

    return [{
      title: t('MoviePlaylistDetails.details'),
      view: (
        <MoviePlaylistDetailsTab
          detailsData={this.state.data}
        />
      )
    }, movieTab];
  };

  onDeletePlaylistFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('MoviePlaylistDetails.deletePlaylistFailed')
    });
  };

  onDeletePlaylistSuccess = () => {
    this.props.playlistDeleted(this.state.data.id);
    showMoviesPlaylist();

    displaySuccessNotification({
      duration: 3,
      message: this.props.t('MoviePlaylistDetails.deletePlaylistSuccess')
    });
  };

  onEditPlaylist = data => {
    this.setState(prevState => {
      const newData = cloneDeep(prevState.data);
      Object.assign(newData, data);
      return {
        data: newData
      };
    });

    this.props.playlistChanged(this.state.data.id);
  };

  onGetMoviesPlaylistByIdFailure = error => {
    this.setLoading('');
    LogController.logError(error);
  };

  onGetMoviesPlaylistByIdSuccess = response => {
    const {data} = response.data;
    const state = {};

    if (this.movieDeleting && data.movies_in_playlist && data.movies_in_playlist.length === 0) {
      state.activeTab = '1';
    }

    Object.assign(state, {
      data: data,
      dataLoaded: true,
      loadingMsg: ''
    });

    this.setState(state);

    this.movieDeleting = false;
    const {playlistId} = this.props;
    this.props.onPlaylistDetailsObtained(playlistId, data.name);
  };

  onMovieDelete = () => {
    this.movieDeleting = true;
    this.fetchPlaylistById();
    this.props.playlistChanged(this.state.data.id);
  };

  onMoviesListUpdate = () => {
    this.fetchPlaylistById();
    this.props.fetchMoviePlaylist();
  };

  renderFooter = () => {
    return (
      <MoviePlaylistDetailsFooter
        deletePlaylist={this.showDeletePlaylistModal}
        editPlaylist={this.editPlaylist}
        showAddMoviesModal={this.showAddMoviesModal}
      />
    );
  };

  setLoading = (loadingMsg) => {
    this.setState({
      loadingMsg: loadingMsg
    });
  };

  showAddMoviesModal = () => {
    const modal = (
      <AddMoviesModal
        playlistId={this.state.data.id}
        onMoviesListUpdate={this.onMoviesListUpdate}
      />
    );
    ModalController.showModal(modal);
  };

  showDeletePlaylistModal = () => {
    const {t} = this.props;
    const modal = (
      <ConfirmationModal
        confirm={this.deletePlaylist}
        title={t('MoviePlaylistDetails.confirmDelete')}
        message={t('MoviePlaylistDetails.deleteQuestion')}
        yesBtnText={t('MoviePlaylistDetails.deleteBtn')}
        noBtnText={t('MoviePlaylistDetails.cancelBtn')}
      />
    );
    ModalController.showModal(modal);
  };

  render() {
    const {t} = this.props;
    const {data} = this.state;

    return (
      <LoadingWrapper
        className='MoviePlaylistDetails'
        dataLoaded={this.state.dataLoaded}
        isLoading={!!this.state.loadingMsg}
        loadingMsg={this.state.loadingMsg}
      >
        <BaseHeader
          title={t('MoviePlaylistDetails.title', {name: data.name || ''})}
          items={this.getMinViewDropdownItems()}
        />
        <div className="MoviePlaylistDetails-body">
          <TabsComponent
            activeKey={this.state.activeTab}
            onChange={this.changeTab}
          >
            {this.getTabs()}
          </TabsComponent>
        </div>
        {this.renderFooter()}
      </LoadingWrapper>
    );
  };
}

export default withTranslation()(MoviePlaylistDetails);