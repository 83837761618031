import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Radio } from 'antd';

import BaseLoadMoreSelect from '../../../../../../../../../../components/BaseLoadMoreSelect/BaseLoadMoreSelect';
import BaseCard from '../../../../../../../../../../components/BaseCard/BaseCard';
import ImageComponent from '../../../../../../../../../../components/ImageComponent/ImageComponent';
import InfoTooltip from '../../../../../../../../../../components/InfoTooltip/InfoTooltip';

import {
  getMovies,
  getMovieById
} from '../../../../../../../../../../services/movies-service/movies.service';
import {
  getWhitelabelConfig,
  editWhitelabelConfig
} from "../../../../../../../../../../services/properties-service/properties.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../services/notification-service/notification.service';

import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import './SceneSpotlightModal.scss';

class SceneSpotlightModal extends Component {
  modalRef = React.createRef();

  state = {
    activeSelection: null,
    scenes: [],
    scene_spotlight_movie_id: null,
    scene_spotlight_movie_title: null,
    whiteLabel: undefined
  }

  componentDidMount() {
    this.fetchWhitelabelConfig();
  }

  clearLoading = () => {
    this.getModal().clearLoading();
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  fetchMovieDetails = (movieId) => {
    getMovieById(movieId)
      .then(this.onGetMovieDetailsSuccess)
      .catch(this.onGetMovieDetailsFailed);
  };

  fetchWhitelabelConfig = () => {
    const message = this.props.t('SceneSpotlightModal.loadingMsg');
    this.setLoading(message);
    const { propertyId } = this.props;

    getWhitelabelConfig(propertyId)
      .then(this.getWhitelabelConfigSuccess)
      .catch(this.getWhitelabelConfigFailure);
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='SceneSpotlightModal-actions'>
        <Button onClick={this.onCancel} key='cancelBtn'>
          {t('SceneSpotlightModal.cancelBtn')}
        </Button>
        <Button onClick={this.saveChanges}
          key='saveChangesBtn'
          disabled={!this.state.activeSelection}>
          {t('SceneSpotlightModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    const { t, propertyName } = this.props;
    const name = propertyName ? ` - ${propertyName}` : '';

    return (
      <div>
        <InfoTooltip text='It should appear on the /just-added and /most-watched page in the scenes section.' />
        {t('SceneSpotlightModal.title')} {name}
      </div>
    )
  };

  getWhitelabelConfigFailure = () => {
    this.clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('SceneSpotlightModal.loadDataFailed')
    });
  };

  getWhitelabelConfigSuccess = response => {
    const currentConfig = response.data.data;
    const { scene_spotlight_id, scene_spotlight_movie_id, scene_spotlight_movie_title } = currentConfig;
    const state = {
      whiteLabel: currentConfig,
      scene_spotlight_movie_id,
      scene_spotlight_movie_title,
      activeSelection: scene_spotlight_id
    };
    this.setState(state);
    if (scene_spotlight_movie_id) {
      this.fetchMovieDetails(scene_spotlight_movie_id);
    } else {
      this.clearLoading();
    }
  };

  onActiveSelectionChange = (event) => {
    const { value: activeSelection } = event.target;
    this.setState({ activeSelection });
  };

  onCancel = () => {
    this.closeModal();
  };

  onChange = ({ movieId, title }) => {
    const message = this.props.t('SceneSpotlightModal.loadingScenes');
    this.setLoading(message);

    this.setState({
      scene_spotlight_movie_id: movieId,
      scene_spotlight_movie_title: title
    });
    this.fetchMovieDetails(movieId);
  };

  onGetMovieDetailsFailed = () => {
    this.clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('SceneSpotlightModal.loadScenesFailed')
    });
  };

  onGetMovieDetailsSuccess = response => {
    const { scenes } = response.data.data;
    this.setState({ scenes });
    this.clearLoading();
  };

  onSaveChangesFailure = () => {
    this.clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('SceneSpotlightModal.saveChangesFailure')
    });
  };

  onSaveChangesSuccess = () => {
    this.closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('SceneSpotlightModal.saveChangesSuccess')
    });
  };

  renderMovieSelect = () => {
    const { t } = this.props;
    return (
      <div className="SceneSpotlightModal-Search">
        <div className="Label">{t('SceneSpotlightModal.searchMovie')}:</div>
        {this.renderMovieSelectField()}
      </div>
    );
  };

  renderMovieSelectField = () => {
    let view = null;
    const { whiteLabel } = this.state;
    if (whiteLabel) {
      let preload = [];
      const { scene_spotlight_id, scene_spotlight_movie_id, scene_spotlight_movie_title } = whiteLabel;
      if (scene_spotlight_id) {
        preload = [{
          movieId: scene_spotlight_movie_id,
          title: scene_spotlight_movie_title
        }];
      }
      view = (
        <BaseLoadMoreSelect onSelect={this.onChange}
          getData={getMovies}
          idIdentifier="movieId"
          name="movies"
          fullValue={true}
          preload={preload}
          selectedValue={[scene_spotlight_movie_id]}
        />
      );
    }

    return view;
  };

  renderSceneCard = (scene, index) => {
    const { t } = this.props;
    const { activeSelection } = this.state;
    const { id: sceneId, images } = scene;
    return (
      <BaseCard key={`$sceneCard-${sceneId}-${index}`}>
        <div title={sceneId}>
          <ImageComponent url={images[0].url} />
          <Radio.Group value={activeSelection}
            onChange={this.onActiveSelectionChange}>
            <Radio value={sceneId}>
              {t('SceneSpotlightModal.sceneIndex', { index: index + 1 })}
            </Radio>
          </Radio.Group>
        </div>
      </BaseCard>
    );
  };

  renderScenesList = () => {
    const { scenes } = this.state;
    return (
      <div className="Scenes-Container">
        {scenes.map(this.renderSceneCard)}
      </div>
    )
  };

  saveChanges = () => {
    const message = this.props.t('SceneSpotlightModal.updatingMsg');
    this.setLoading(message);
    const { activeSelection, scene_spotlight_movie_id, scene_spotlight_movie_title } = this.state;
    const { propertyId, applyToAll } = this.props;
    const config = {
      scene_spotlight_id: activeSelection,
      scene_spotlight_movie_id,
      scene_spotlight_movie_title
    };
    const applyToPropertyId = applyToAll || propertyId;

    editWhitelabelConfig(applyToPropertyId, config)
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  setLoading = (message) => {
    this.getModal().setLoading(message);
  };

  render() {
    return (
      <ModalDialog title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className='SceneSpotlightModal'>
          {this.renderMovieSelect()}
          {this.renderScenesList()}
        </div>
      </ModalDialog>
    );
  }
}

export default withTranslation()(SceneSpotlightModal);
