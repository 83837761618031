import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from "lodash";
import { Button, List, Input } from 'antd';
import PropTypes from 'prop-types';

import ModalDialog from '../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import AddBannerUploadImagesModal from "./AddBannerUploadImagesModal/AddBannerUploadImagesModal";
import BannerMovieSelect from "./BannerMovieSelect/BannerMovieSelect";
import BannerSetSelect from "./BannerSetSelect/BannerSetSelect";
import BaseVerticalRangePicker
  from "../../../../../../../../../components/BaseVerticalRangePicker/BaseVerticalRangePicker";

import { addBanner } from '../../../../../../../../../services/banners-service/banners.service';
import { convertMomentToDate } from "../../../../../../../../../services/date-service/date.service";
import { uploadBannerImage } from "../../../../../../../../../services/images-service/images.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../services/notification-service/notification.service';
import { getProperties } from "../../../../../../../../../services/properties-service/properties.service";

import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import SingleFile from "../../../../../../../../../components/modal/UploadImages/SingleFile/SingleFile";

import './AddBannerModal.scss';
import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";
import BaseCheckboxList from "../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList";

class AddBannerModal extends Component {

  createdBannerId;

  filesForUpload = [];

  modalRef = React.createRef();

  state = {
    bannerCaption: '',
    bannerTitle: '',
    bannerUrl: '',
    bannerVideoUrl: '',
    dataLoaded: false,
    excludedProperties: [],
    exclusiveProperties: [],
    images: [],
    properties: [],
    publishRange: {
      endDate: null,
      startDate: null
    },
    selectedBannerSetsIds: [],
    selectedMovieId: null,
    starId: undefined,
    studioId: undefined,
    tagId: undefined
  };

  componentDidMount() {
    this.getProperties();
  }

  addBanner = () => {
    const message = this.props.t('AddBannerModal.addingBannerPleaseWait');
    this.getModal().setLoading(message);
    const data = this.getBannerCreationData();
    addBanner(data)
      .then(this.onAddBannerSuccess)
      .catch(this.onAddBannerFailure);
  };

  closeDialog = () => {
    this.getModal().closeModal();
  };

  displayUploadImagesModal = () => {
    const modal = (
      <AddBannerUploadImagesModal onUploadImageClick={this.updateImagesData} />
    );
    ModalController.showModal(modal);
  };

  findItemByIndex = (items, index) => {
    return items.findIndex(this.matchItemByIndex.bind(this, index));
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='AddBannerModal-actions'>
        <Button onClick={this.displayUploadImagesModal}>
          {t('AddBannerModal.uploadImages')}
        </Button>
        <div>
          <Button onClick={this.onCancel} key='cancelBtn'>
            {t('AddBannerModal.cancel')}
          </Button>
          <Button
            onClick={this.addBanner}
            key='addBannerBtn'
            type='primary'>
            {t('AddBannerModal.addBanner')}
          </Button>
        </div>
      </div>
    );
  };

  getBannerCreationData = () => {
    const data = {};
    Object.assign(data,
      this.getPropertyData('selectedMovieId', 'movies_id'),
      this.getPropertyData('bannerTitle', 'title'),
      this.getPropertyData('bannerCaption', 'caption'),
      this.getPropertyData('bannerUrl', 'url'),
      this.getPropertyData('bannerVideoUrl', 'videoClipUrl'),
      this.getPropertyData('starId', 'stars_id'),
      this.getPropertyData('studioId', 'studios_id'),
      this.getPropertyData('tagId', 'tags_id'),
      this.getBannerPublishRange(),
      this.getBannerSelectedSets(),
      this.getExclusiveExcludedData()
    );
    return data;
  };

  getBannerPublishRange = () => {
    const { endDate, startDate } = this.state.publishRange;
    const data = {};
    if (startDate) { // Add banner publish start DATE
      data.publishedDate = convertMomentToDate(startDate);

      if (endDate) { // Add banner publish end DATE
        data.publishedEnd = convertMomentToDate(endDate);
      }
    }

    return data;
  };

  getBannerSelectedSets = () => {
    const { selectedBannerSetsIds } = this.state;
    const data = {};
    if (selectedBannerSetsIds.length) { // Add banner SETS
      data.banner_set = selectedBannerSetsIds;
    }

    return data;
  };

  getExclusiveExcludedData = () => {
    return {
      exclusive_excluded: this.mapExclusiveExcludedPropertiesForPost()
    };
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getProperties = () => {
    getProperties()
      .then(this.loadPropertyData)
      .catch(this.onLoadPropertyFailure);
  };

  getPropertyData = (stateKey, postKey) => {
    const value = this.state[stateKey];
    const data = {};
    if (value) {
      data[postKey] = value;
    }

    return data;
  };

  getTitle = () => {
    return this.props.t('AddBannerModal.addBanner');
  };

  handleAddBannerSuccess = () => {
    this.closeDialog();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddBannerModal.addBannerSuccess')
    });
    this.props.onBannerAdded();
  };

  loadPropertyData = (response) => {
    const { properties } = response.data.data;
    this.setState({
      dataLoaded: true,
      properties: properties.sort(this.sortByName)
    });
  };

  mapExclusiveExcludedPropertiesForPost = () => {
    const { excludedProperties, exclusiveProperties } = this.state;
    return [
      ...this.mapPropertiesForPost(excludedProperties, 'excluded'),
      ...this.mapPropertiesForPost(exclusiveProperties, 'exclusive')
    ];
  };

  mapPropertiesForPost = (keys, type) => {
    return keys.map(this.mapPropertyForPost.bind(this, type));
  };

  mapPropertyForPost = (type, properties_id) => {
    return { type, properties_id };
  };

  matchItemByIndex = (index, item) => {
    return item.index === index;
  };

  onAddBannerFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddBannerModal.addBannerFailed')
    });
  };

  onAddBannerSuccess = (response) => {
    this.createdBannerId = response.data.data.id_banner;
    if (this.filesForUpload.length) {
      this.uploadBannerImages();
    } else {
      this.handleAddBannerSuccess();
    }
  };

  onBannerSetSelect = (bannerSetsIds) => {
    this.setState({
      selectedBannerSetsIds: bannerSetsIds
    });
  };

  onCancel = () => {
    this.closeDialog();
  };

  onInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  };

  onLoadPropertyFailure = (error) => {
    LogController.logError(error);
  };

  onMovieSelect = (movieId) => {
    this.setState({
      selectedMovieId: movieId
    });
  };

  onMultipleSelectChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  onPublishRangeChange = (publishRange) => {
    this.setState({ publishRange });
  };

  onTypeChange = (index, event) => {
    const { value } = event.target;
    this.filesForUpload[index].type = value;
    this.setState(prevState => {
      const images = cloneDeep(prevState.images);
      images[index].type = value;
      return { images };
    });
  };

  removeFile = (index) => {
    this.setState(prevState => {
      const images = cloneDeep(prevState.images);
      const imagesIndex = this.findItemByIndex(images, index);
      images.splice(imagesIndex, 1);
      this.filesForUpload.splice(imagesIndex, 1);
      return {
        images: images
      };
    });
  };

  renderBannerMovieSelect = () => {
    return (
      <BannerMovieSelect onMovieSelect={this.onMovieSelect} />
    );
  };

  renderBannerSetSelect = () => {
    return (
      <BannerSetSelect onBannerSetSelect={this.onBannerSetSelect} />
    );
  };

  renderField = (label, children) => {
    return (
      <div className="AddBannerModal-banner-input">
        <div>{label}</div>
        {children}
      </div>
    );
  };

  renderImagesPreview = () => {
    const { images } = this.state;
    return images.length ? (
      <List className="AddBannerModal-images-preview"
        dataSource={images}
        renderItem={this.renderListItem} />
    ) : null;
  };

  renderInputField = (value, label, name) => {
    const children = (
      <Input value={value}
        name={name}
        onChange={this.onInputChange} />
    );
    return this.renderField(label, children);
  };

  renderListItem = (item) => {
    const { t } = this.props;
    return (
      <SingleFile name={item.name}
        index={item.index}
        id={item.id}
        url={item.url}
        onTypeChange={this.onTypeChange}
        removeFile={this.removeFile.bind(this, item.index)}
        type={item.type}
        hideSelectionCheckbox={true}
        imageCategories={[t('AddBannerModal.banner')]} />
    );
  };

  renderMultipleSelect = (label, items, value, onChange, filterFn) => {
    const children = (
      <BaseCheckboxList items={items}
        onChange={onChange}
        showSelectAll={true} />
    );
    return this.renderField(label, children);
  };

  renderPublishRange = () => {
    const { t } = this.props;
    return (
      <BaseVerticalRangePicker endLabel={t('AddBannerModal.bannerPublishEndDate')}
        startLabel={t('AddBannerModal.bannerPublishDate')}
        onChange={this.onPublishRangeChange} />
    );
  };

  sortByName = ({ name }, { name: name1 }) => {
    let order = 0;
    if (name > name1) {
      order = 1;
    } else if (name < name1) {
      order = -1;
    }

    return order;
  };

  updateImagesData = (files, images) => {
    this.filesForUpload = files;
    this.setState(prevState => {
      const newImages = this.updateImagesUrl(files, images);
      return {
        images: prevState.images.concat(newImages)
      };
    });
  };

  updateFileUrl = (images, fileData, index) => {
    const { file, type } = fileData;
    return {
      name: file.name,
      index,
      id: index,
      url: images[index],
      type
    };
  };

  updateImagesUrl = (files, images) => {
    let data = cloneDeep(files);
    data = data.map(this.updateFileUrl.bind(this, images));

    return data;
  };

  uploadBannerImages = () => {
    const message = this.props.t('AddBannerModal.addingBannerImagesMsg');
    this.getModal().setLoading(message);
    const promises = this.filesForUpload.map(this.uploadImage);
    Promise.all(promises)
      .then(this.handleAddBannerSuccess)
      .catch(this.onAddBannerFailure);
  };

  uploadImage = ({ file, type }) => {
    return uploadBannerImage(this.createdBannerId, type, file);
  };

  render() {
    const { t } = this.props;
    const {
      bannerTitle, bannerCaption, bannerUrl, bannerVideoUrl,
      dataLoaded, exclusiveProperties, excludedProperties,
      properties, starId, studioId, tagId
    } = this.state;
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className='AddBannerModal'>
          {this.renderBannerMovieSelect()}
          {this.renderInputField(bannerTitle, t('AddBannerModal.bannerTitle'), 'bannerTitle')}
          {this.renderInputField(bannerUrl, t('AddBannerModal.bannerUrl'), 'bannerUrl')}
          {this.renderInputField(bannerCaption, t('AddBannerModal.bannerCaption'), 'bannerCaption')}
          {this.renderInputField(bannerVideoUrl, t('AddBannerModal.bannerVideoUrl'), 'bannerVideoUrl')}
          {this.renderInputField(starId, t('AddBannerModal.starId'), 'starId')}
          {this.renderInputField(tagId, t('AddBannerModal.tagId'), 'tagId')}
          {this.renderInputField(studioId, t('AddBannerModal.studioId'), 'studioId')}
          {this.renderPublishRange()}
          {this.renderBannerSetSelect()}
          {dataLoaded ? (
            <React.Fragment>
              {this.renderMultipleSelect(t('AddBannerModal.exclusiveProperties'), properties, exclusiveProperties, this.onMultipleSelectChange.bind(this, 'exclusiveProperties'))}
              {this.renderMultipleSelect(t('AddBannerModal.excludedProperties'), properties, excludedProperties, this.onMultipleSelectChange.bind(this, 'excludedProperties'))}
            </React.Fragment>
          ) : null}
          {this.renderImagesPreview()}
        </div>
      </ModalDialog>
    );
  }
}

AddBannerModal.propTypes = {
  onBannerAdded: PropTypes.func.isRequired
};

export default withTranslation()(AddBannerModal);
