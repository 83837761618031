import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Icon} from 'antd';
import PropTypes from 'prop-types';

import ModalDialog from "../ModalDialog/ModalDialog";

import "./ConfirmationModal.scss";

class ConfirmationModal extends Component {

  modalRef = React.createRef();

  confirm = () => {
    const {confirm, data} = this.props;
    if (confirm) {
      confirm(data);
    }
    this.getModal().closeModal();
  };

  decline = () => {
    const {decline} = this.props;
    if (decline) {
      decline();
    }
    this.getModal().closeModal();
  };

  getActions = () => {
    return (
      <div className="Actions">
        <Button type="default" onClick={this.decline.bind(this)}>
          {this.getNoBtnText()}
        </Button>
        <Button type="primary" onClick={this.confirm.bind(this)}>
          {this.getYesBtnText()}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getNoBtnText = () => {
    const {t, noBtnText} = this.props;
    return noBtnText || t('ConfirmationModal.noText');
  };

  getYesBtnText = () => {
    const {t, yesBtnText} = this.props;
    return yesBtnText || t('ConfirmationModal.yesText');
  };

  render() {
    const {title, message} = this.props;
    return (
      <ModalDialog title={title}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}>
        <div className="ConfirmationModal">
          <Icon type="question-circle"/>
          <div>
            {message}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

ConfirmationModal.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  confirm: PropTypes.func,
  decline: PropTypes.func,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  yesBtnText: PropTypes.string,
  noBtnText: PropTypes.string
};

export default withTranslation()(ConfirmationModal);


