import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Router} from "@reach/router";

import StudioDetailsBody from '../components/StudioDetailsBody/StudioDetailsBody';
import StudioMovies from '../../StudioMovies/StudioMovies';

import './StudioDetailsBodyView.scss';

class StudioDetailsBodyView extends Component {
  render() {
    const {name: studioName} = this.props;
    return (
      <div className='StudioDetailsBodyView'>
        <Router>
          <StudioDetailsBody
            path="/details"
            {...this.props}
            setStudioStatus={this.props.setStudioStatus}
            activeKey={this.props.activeKey}
            changeTab={this.props.changeTab}
          />
          <StudioMovies path={`/movies/*`}
                        studioName={studioName}/>
        </Router>
      </div>
    );
  }
}

export default withTranslation()(StudioDetailsBodyView);
