import React, { Suspense } from 'react';
import { Redirect, Router } from "@reach/router";

import './App.scss';
import Login from "./pages/Login/Login";
import NotFound from "./pages/NotFound/NotFound";
import MainDashboard from "./pages/MainDashboard/MainDashboard";
import { ROUTES } from "./routes";
import { MAIN_DASHBOARD_VIEW_ROUTES } from "./pages/MainDashboard/components/MainView/main-dashboard-view.routes";
import ModalContainer from "./components/ModalContainer/ModalContainer";
import LoadingMask from "./components/LoadingMask/LoadingMask";
import 'antd/dist/antd.css';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import 'videojs-playlist-ui/dist/videojs-playlist-ui.vertical.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-image-lightbox/style.css';
import {
  getBannersRoute,
  getBannersBaseRoute
} from "./services/navigation/banners-navigation/banners-navigation-routes.service";
import { getUsersRoute, getUsersBaseRoute } from "./services/navigation/users-navigation/users-navigation-routes.service";
import { MiniPlayerContainer } from "@falconstudios/ns-player";
import { cleanupToken, hasTokenExpired } from './services/token-service/token.service';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';

function App() {
  if (hasTokenExpired()) {
    cleanupToken();
  }
  return (
    <div className='App'>
      <ErrorBoundary>
        <Suspense fallback={<div className="SuspenseLoader">Loading...</div>}>
          <Router>
            <Login path={ROUTES.ROOT} />
            <MainDashboard path={`${ROUTES.MAIN_DASHBOARD}/*`} />
            <Redirect from="/main" to={`/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.PROPERTIES}`} noThrow />
            <Redirect from={getBannersBaseRoute()} to={getBannersRoute()} noThrow />
            <Redirect from={getUsersBaseRoute()} to={getUsersRoute()} noThrow />
            <NotFound default />
          </Router>
        </Suspense>
        <ModalContainer />
        <MiniPlayerContainer />
        <LoadingMask />
      </ErrorBoundary>
    </div>
  );
}

export default App;
