import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'antd';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import {
  attachStudioCatalogs,
  editStudio,
  getStudioById,
  syncStudio,
  toggleEnableStatus
} from '../../../../../../../../../services/studio-service/studio.service';
import { deleteStudioImage } from '../../../../../../../../../services/images-service/images.service';
import { uploadStudioImage } from '../../../../../../../../../services/images-service/images.service';
import {
  getStudioDetailsRoute,
  getStudioMoviesRoute,
  getStudiosRoute
} from '../../../../../../../../../services/navigation/studios-navigation/studios-navigation-routes.service';
import {
  showStudioDetails,
  showStudioMovies
} from '../../../../../../../../../services/navigation/studios-navigation/studios-navigation.service';
import { getMovieCatalogs } from "../../../../../../../../../services/movies-service/movies.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../services/notification-service/notification.service';
import { momentFormats, renderMomentFormatted } from "../../../../../../../../../services/date-service/date.service";

import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";
import { ModalController } from '../../../../../../../../../controllers/modal-controller/modal.controller';

import BaseHorizontalNavigation
  from "../../../../../../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";
import BaseNavigationItem from "../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import DeleteImagesModal from '../../../../../../../../../components/modal/DeleteImagesModal/DeleteImagesModal';
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';

import StudioDetailsBodyView from './StudioDetailsBodyView/StudioDetailsBodyView';
import UploadStudioImagesModal from "./UploadStudioImagesModal/UploadStudioImagesModal";
import { getProperties } from '../../../../../../../../../services/properties-service/properties.service';
import './StudioDetails.scss';

class StudioDetails extends Component {

  studioId;

  imageDeleting = false;

  state = {
    activeTab: '1',
    data: {
      description: '',
      id: 0,
      images: [],
      moviesCount: 0
    },
    dataLoaded: false,
    loadingMsg: '',
    properties: []
  };

  constructor(props) {
    super(props);
    this.studioId = +props.studioId;
  }

  componentDidMount() {
    this.fetchStudioData();
  }

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  deleteImage = imageId => {
    const { studioId } = this.props;
    return deleteStudioImage(studioId, imageId);
  };

  displayUploadPhotoModal = () => {
    const modal = (
      <UploadStudioImagesModal
        uploadImages={this.mapImageUpload}
        onUploadFinished={this.fetchStudioData}
      />
    );
    ModalController.showModal(modal);
  };

  fetchStudioData = (callback) => {
    const { t } = this.props;
    const message = t('StudioDetails.pleaseWait');
    this.setLoading(message);
    const promises = [
      getStudioById(this.studioId),
      getMovieCatalogs(),
      getProperties()
    ];
    Promise.all(promises)
      .then(this.loadData.bind(this, callback))
      .catch(this.onRequestFailure);
  };

  getCatalogAttachPromise = (selectedCatalogs) => {
    let promise;
    if (selectedCatalogs) {
      const { studioId } = this.props;
      promise = attachStudioCatalogs(studioId, selectedCatalogs);
    } else {
      promise = Promise.resolve();
    }
    return promise;
  };

  getDropdownItems = () => {
    const { t } = this.props;
    return ([
      <div onClick={this.fetchStudioData}>
        <Icon type='sync' />
        {t('StudioDetails.syncStudio')}
      </div>,
      <div onClick={this.onDeleteImages}>
        <Icon type='delete' />
        {t('StudioDetails.deleteImages')}
      </div>,
      <div onClick={this.displayUploadPhotoModal}>
        <Icon type='picture' />
        {t('StudioDetails.uploadLogo')}
      </div>,
      <div onClick={this.navigateToStudioMovies}>
        <Icon type='video-camera' />
        {t('StudioDetails.viewStudioMovies')}
      </div>
    ]);
  };

  getNavigationItems = () => {
    const { data } = this.state;
    const { id, name } = data;
    const { t } = this.props;
    return [{
      link: getStudioDetailsRoute(id),
      name: t('StudioDetails.studioName', { name: name || '' }),
      navigate: showStudioDetails.bind(null, id)
    }, {
      link: getStudioMoviesRoute(id),
      name: t('StudioDetails.movies'),
      navigate: this.navigateToStudioMovies
    }];
  };

  getStudioActions = () => {
    const { t } = this.props;
    return [
      <Button onClick={this.syncStudio}
        key="syncStudio">
        <Icon type="sync" />
        {t('StudioDetailsFooter.syncStudio')}
      </Button>,
      <Button onClick={this.showDeleteImagesModal}
        key="deleteImages">
        <Icon type="delete" />
        {t('StudioDetailsFooter.deleteImages')}
      </Button>,
      <Button onClick={this.displayUploadPhotoModal}
        key="uploadLogo">
        <Icon type="picture" />
        {t('StudioDetailsFooter.uploadLogo')}
      </Button>
    ];
  };

  getStudioStatusUpdatePromise = (status) => {
    let promise;
    if (status !== undefined) {
      const { studioId } = this.props;
      promise = toggleEnableStatus(studioId, status);
    } else {
      promise = Promise.resolve();
    }

    return promise;
  };

  loadData = (callback, values) => {
    const { data } = values[0].data;
    const { properties = [] } = values[2].data.data;
    const state = {};

    this.setDocumentTitle(data);

    if (this.imageDeleting && data.images && data.images.length === 0) {
      state.activeTab = '1';
    }
    data.allCatalogs = values[1].data.data;

    Object.assign(state, {
      data: data,
      dataLoaded: true,
      loadingMsg: '',
      properties
    });

    this.setState(state, callback);

    this.imageDeleting = false;
  };

  mapImageUpload = (file, type) => {
    const { studioId } = this.props;
    return uploadStudioImage(studioId, file, type);
  };

  navigateToStudioMovies = () => {
    showStudioMovies(this.studioId);
  };

  onDeleteSuccess = () => {
    this.imageDeleting = true;
    this.fetchStudioData();
  };

  onFetchDataCompleted = (callback) => {
    callback(); // Call callback function from studio details table to finish editing
    const { studioId, updateStudioData } = this.props;
    updateStudioData(studioId, cloneDeep(this.state.data)); // Call update function from the main router to update studio data
  };

  onRequestFailure = (error) => {
    this.setLoading('');
    LogController.logError(error);
  };

  onStudioEdit = ({
    name,
    status,
    foundedDate,
    descriptionNs,
    movieCatalogs,
    studiosOnPropertiesData
  }, callback) => {
    const { studioId, t } = this.props;
    const message = t('StudioDetails.updatingStudioMsg');
    this.setLoading(message);
    const postData = {
      name,
      descriptionNs,
      studiosOnPropertiesData
    };
    if (foundedDate) {
      Object.assign(postData, {
        foundedDate: renderMomentFormatted(
          foundedDate,
          momentFormats.dateYearFirst
        )
      });
    }
    const promises = [
      editStudio(studioId, postData),
      this.getStudioStatusUpdatePromise(status),
      this.getCatalogAttachPromise(movieCatalogs)
    ];

    Promise.all(promises)
      .then(this.onStudioEditSuccess.bind(this, callback))
      .catch(this.onStudioEditFailure);
  };

  onStudioEditFailure = (error) => {
    this.onRequestFailure(error);
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('StudioDetails.editFailure')
    });
  };

  onStudioEditSuccess = (callback) => {
    this.fetchStudioData(this.onFetchDataCompleted.bind(this, callback));
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t('StudioDetails.editSuccess')
    });
  };

  onStudioSyncFailure = () => {
    const { t } = this.props;
    this.setLoading('');
    displayErrorNotification({
      duration: 3,
      message: t('StudioDetails.syncStudioFailure')
    });
  };

  onStudioSyncSuccess = () => {
    const { t } = this.props;
    this.setLoading('');
    displaySuccessNotification({
      duration: 3,
      message: t('StudioDetails.syncStudioSuccess')
    });
  };

  onSetStudioStatusFailure = (enabled) => {
    this.setLoading('');
    const { t } = this.props;
    const key = enabled === 1 ? 'enableFail' : 'disableFail';
    displayErrorNotification({
      duration: 3,
      message: t(`StudioDetails.${key}`)
    });
  };

  onSetStudioStatusSuccess = (id, data) => {
    const { enable } = data;

    const newData = {
      status: data.enable
    };

    const key = enable === 1 ? 'enableSuccess' : 'disableSuccess';
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t(`StudioDetails.${key}`)
    });
    this.onStudioEdit(newData);
    this.setLoading('');
  };

  setDocumentTitle = (data) => {
    const title = this.props.t('StudioDetails.documentTitle', { name: data.name });
    DocumentTitleController.setDocumentTitle(title);
  };

  setLoading = (loadingMsg) => {
    this.setState({
      loadingMsg: loadingMsg
    });
  };

  showDeleteImagesModal = () => {
    const { images } = this.state.data;
    const modal = (
      <DeleteImagesModal
        images={images}
        deleteImage={this.deleteImage}
        onDeleteSuccess={this.onDeleteSuccess}
      />
    );
    ModalController.showModal(modal);
  };

  syncStudio = () => {
    const { t } = this.props;
    const message = t('StudioDetails.syncPleaseWait');
    this.setLoading(message);
    syncStudio(this.studioId)
      .then(this.onStudioSyncSuccess)
      .catch(this.onStudioSyncFailure);
  };

  renderTitle = () => {
    const { t } = this.props;
    const { data } = this.state;

    const routes = [{
      url: getStudiosRoute(),
      text: t('StudioDetails.studios')
    }, {
      text: data?.name
    }];

    return <BaseNavigationItem routes={routes} />;
  };

  render() {
    const { data, properties } = this.state;
    return (
      <LoadingWrapper
        className='StudioDetails'
        dataLoaded={this.state.dataLoaded}
        isLoading={!!this.state.loadingMsg}
        loadingMsg={this.state.loadingMsg}>
        {this.renderTitle()}
        <BaseHorizontalNavigation items={this.getNavigationItems()}>
          {this.getStudioActions()}
        </BaseHorizontalNavigation>
        <StudioDetailsBodyView
          {...this.props}
          {...data}
          properties={properties}
          activeKey={this.state.activeTab}
          changeTab={this.changeTab}
          onStudioEdit={this.onStudioEdit}
        />
      </LoadingWrapper>
    );
  }
}

StudioDetails.propTypes = {
  studioId: PropTypes.string,
  updateStudioData: PropTypes.func
};

export default withTranslation()(StudioDetails);
