import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {List, Table, Button, Input} from "antd";
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';

import {editHost} from '../../../../../../../../../../services/properties-service/properties.service';
import {
    displayErrorNotification,
    displaySuccessNotification
} from '../../../../../../../../../../services/notification-service/notification.service';
import {renderMomentWithHours} from "../../../../../../../../../../services/date-service/date.service";

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";

import './HostsTab.scss';

class HostsTab extends Component {

  state = {
    editMode: {}
  }

  createHrefElement = (address) => {
    return (
      <a href={address} target="_blank" rel="noopener noreferrer">{address}</a>
    );
  };

  getEditButton = (item, data) => {
    const {t} = this.props;
    const {editMode} = this.state;
    const {id} = data;
    const key = editMode[id] ? 'cancel' : 'edit';
    return (
      <div className="EditHostsButton">
        {editMode[id] ?
          (<Button disabled={this.isAddressInvalid(id) || this.checkFieldChanges(id)}
                   onClick={this.onSaveHostChanges.bind(this, id)}>
            {t('HostsTab.saveChanges')}
          </Button>) : null}
        <Button onClick={this.toggleEditMode.bind(this, data)}>
          {t(`HostsTab.${key}`)}
        </Button>
      </div>
    );
  };

  getCardFooter = (item) => {
    return (
        <div className="card-footer">
          {this.getEditButton(item, item)}
        </div>
    );
  };

  renderDate = (date) => {
    return renderMomentWithHours(date);
  };

  renderHostColumnValue = (name, value, item) => {
    const {editMode} = this.state;
    const {id} = item;
    let result = this.createHrefElement(value);
    
    if(editMode[id]) {
      result = (
        <div className="Input-Container">
          <Input.TextArea
            autosize
            autoFocus={name === 'marketing_fee'}
            onChange={this.onChange.bind(this, id, name)}
            value={editMode[id][name]}
          />
        </div>
      );
    }
 
    return result;
  };

  columns = [{
    title: this.props.t('HostsTab.baseAddress'),
    dataIndex: 'baseAddress',
    key: 'baseAddress',
    render: this.renderHostColumnValue.bind(this, 'baseAddress')
  }, {
    title: this.props.t('HostsTab.hostKey'),
    dataIndex: 'hostKey',
    key: 'hostKey',
    render: this.renderHostColumnValue.bind(this, 'hostKey')
  }, {
    title: this.props.t('HostsTab.dateCreated'),
    dataIndex: 'created_at',
    key: 'dateCreated',
    render: this.renderDate
  },
  {
    title: this.props.t('HostsTab.actions'),
    dataIndex: 'edit',
    key: 'edit',
    render: this.getEditButton,
    align: 'center',
    width: 250
  }
  ];

  buildHostsViewListItem = (item) => {
    const {t} = this.props;
    const {hostKey, baseAddress, created_at} = item;
    return (
      <List.Item key={hostKey}>
        <BaseCard
           footer={this.getCardFooter(item)}
        >
          <p>
            <span className="Label">{t('HostsTab.baseAddress')}:</span>
            <br/>{this.renderHostColumnValue('baseAddress', baseAddress, item)}
          </p>
          <p>
            <span className="Label">{t('HostsTab.hostKey')}:</span>
            <br/>{this.renderHostColumnValue('hostKey', hostKey, item)}
          </p>
          <p>
            <span className="Label">{t('HostsTab.dateCreated')}:</span>
            <br/>{this.renderDate(created_at)}
          </p>
        </BaseCard>
      </List.Item>
    );
  };

  checkFieldChanges = (hostId) => {
    const data = this.state.editMode[hostId];
    return (data.baseAddress === data.initialBaseAddress) && (data.hostKey === data.initialHostKey);
  };

  isAddressInvalid = (hostId) => {
    const {baseAddress} = this.state.editMode[hostId];
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/gi;
    const regex = new RegExp(expression);

    return !baseAddress.match(regex);
  };

  onChange = (id, name, event) => {
    let value = event.target ? event.target.value : event;

    this.setState(prevState => {
      const newState = cloneDeep(prevState);
      newState.editMode[id][name] = value;

      return newState;
    });
  };

  onEditFailure = () => {
    this.props.setLoading('');
    displayErrorNotification({
        duration: 3,
        message: this.props.t('HostsTab.editHostFailed')
    });
  };

  onEditSuccess = (data) => {
    this.props.setLoading('');
    displaySuccessNotification({
        duration: 3,
        message: this.props.t('HostsTab.editHostSuccess')
    });
    this.props.onHostListUpdate(this.toggleEditMode.bind(this, data));
  };

  onSaveHostChanges = (hostId) => {
    const message = this.props.t('HostsTab.editingHostPleaseWait');
    this.props.setLoading(message);

    const {editMode} = this.state;
    const postData = {
      base_address: editMode[hostId].baseAddress,
      host_key: editMode[hostId].hostKey,
      id: hostId
    };

    editHost(hostId, postData)
      .then(this.onEditSuccess.bind(this, postData))
      .catch(this.onEditFailure);
  };

  toggleEditMode = (data) => {
    let editMode = this.state.editMode;
    const {id, baseAddress, hostKey} = data;
 
    if(editMode[id]) {
      delete editMode[id];
    } else {
      editMode = {
        ...this.state.editMode,
        [id]: {
          baseAddress: baseAddress,
          initialBaseAddress: baseAddress,
          hostKey: hostKey,
          initialHostKey: hostKey
        }
      };
    }
 
    this.setState({
      editMode: editMode
    });
  };

  render() {
    return (
      <div className="HostsTab">
        <List className="HostsTab-List"
              renderItem={this.buildHostsViewListItem}
              dataSource={this.props.hosts}
              split={false}
              itemLayout="vertical"/>
        <Table rowKey="id"
               dataSource={this.props.hosts}
               pagination={false}
               bordered
               columns={this.columns}/>
      </div>
    );
  }
}

HostsTab.propTypes = {
  hosts: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default withTranslation()(HostsTab);
