import {ROUTES} from "../../../routes";

export const getSexActBaseRoute = (sexActId) => {
  return `/${ROUTES.MAIN_DASHBOARD}/sexAct/${sexActId}`;
};

/**
 * Returns sex act movie route
 *
 * @param {number|string} sexActId
 *
 * @returns {string}
 */
export const getSexActMoviesRoute = (sexActId) => {
  return `${getSexActBaseRoute(sexActId)}/movies`;
};

/**
 * Returns sex act movie details route
 *
 * @param {number|string} sexActId
 * @param {number|string} movieId
 *
 * @returns {string}
 */
export const getSexActMovieDetailsRoute = (sexActId, movieId) => {
  return `${getSexActMoviesRoute(sexActId)}/${movieId}`;
};