import React, { Component } from "react";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { withDocumentTitleUpdate } from "../../../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";
import PropTypes from 'prop-types';

import BannerSetCard from "../BannerSetCard/BannerSetCard";

import { DocumentTitleController } from "../../../../../../../../../../controllers/title-controller/title.controller";

import './DefaultBannerSets.scss';
import EditDefaultBannerSetModal from './EditDefaultBannerSetModal/EditDefaultBannerSetModal';
import { ModalController } from '../../../../../../../../../../controllers/modal-controller/modal.controller';

/* istanbul ignore file */
class DefaultBannerSets extends Component {
  subscription = [];
  bannerSetContainer = React.createRef();

  constructor(props) {
    super(props);

    const bannersSets = this.props.bannersSets || [];
    const getAllGroups = bannersSets.map(item => item?.group);

    this.state = {
      selectedSets: [],
      bannerSetGroups: [...new Set(getAllGroups)],
      bannersSets: bannersSets
    };
  }

  componentDidMount() {
    const { location = {}, bannersSets = [], onToggleBannerSets = () => { } } = this.props;
    const pathSegments = location.pathname?.split('/');
    const pathSetId = pathSegments[pathSegments.length - 1];
    const selectedSetGroup = bannersSets.filter(item => item.id === +pathSetId);
    const selectedSets = bannersSets.filter(item => item.group === selectedSetGroup[0]?.group);

    if (selectedSets?.length !== 0) {
      this.setState({ selectedSets }, () => onToggleBannerSets(true));
    };
    DocumentTitleController.setDocumentTitle(this.getDocumentTitle());
  }

  static getDerivedStateFromProps(props) {
    return { bannersSets: props.bannersSets }
  }

  getDocumentTitle = () => {
    return this.props.t('DefaultBannerSets.documentTitle');
  };

  showEditDefaultBannerSetsModal = (id) => {
    const { bannersSets = [] } = this.state;
    const bannerSetData = bannersSets
      .map(item => {
        if (item.id === id) {
          const {
            id,
            name,
            group,
            display_single_banner,
            banner_override_url,
            randomize_on_refresh,
            slider_delay,
            open_in_new_tab,
            nats_codes,
            parent_set,
            publish_start,
            publish_end
          } = item;
          return {
            id,
            name,
            group,
            display_single_banner,
            banner_override_url,
            randomize_on_refresh,
            slider_delay,
            open_in_new_tab,
            nats_codes,
            parent_set,
            publish_start,
            publish_end,
            bannersSets
          }
        }
        return undefined;
      })
      .filter(Boolean);

    const modal = (
      <div className="EditDefaultBannerSetModal">
        <EditDefaultBannerSetModal data={bannerSetData[0]} updateBannersData={this.props.fetchData} />
      </div>
    )
    ModalController.showModal(modal);
  }

  toggleBannerSetVisibility = (group) => {
    const { bannersSets = [] } = this.state;
    const { showSets, onToggleBannerSets = () => { } } = this.props;
    const selectedSets = bannersSets.filter(item => item.group === group);

    if (!showSets) {
      this.setState({ selectedSets }, () => onToggleBannerSets(true));
      this.bannerSetContainer.current.scrollTop = 0;
    } else {
      this.setState({ selectedSets: [] }, () => onToggleBannerSets(false));
    }
  }

  renderGroup = (name) => {
    const { showSets } = this.props;
    const icon = showSets ? 'fa-minus' : 'fa-plus';

    if (showSets) return null;

    return (
      // css for this should match css for renderTitle() from BannerSetCardAbstract
      <div key={name} className="BannerSetGroup" onClick={() => this.toggleBannerSetVisibility(name)}>
        <span>{name}</span>
        <i className={`toggleIcon fas fa-light ${icon}`} />
      </div>
    )
  }

  renderBannerSet = ({
    banners,
    count_banners,
    id,
    name,
    parent_set,
    group
  }) => {
    const { showSets, propertiesCount, fetchData } = this.props;

    if (!showSets) return null;

    return (
      <BannerSetCard
        showSets={showSets}
        onClick={() => this.toggleBannerSetVisibility(group)}
        bannerOrder={banners}
        bannerSetId={id}
        isDefault={true}
        propertyCount={propertiesCount}
        title={name}
        totalBannerCount={count_banners}
        key={id}
        allActionsIncluded={true}
        maxElementCount={6}
        parentSet={parent_set}
        updateBanners={fetchData}
        showEditDefaultBannerSetsModal={() => this.showEditDefaultBannerSetsModal(id)}
      />
    );
  };

  render = () => {
    const { selectedSets = [], bannerSetGroups = [] } = this.state;

    return (
      <div className="DefaultBannerSets">
        <div className="Inner" ref={this.bannerSetContainer}>
          {bannerSetGroups.map(this.renderGroup)}
          {selectedSets.map(this.renderBannerSet)}
        </div>
      </div>
    );
  };
}

DefaultBannerSets.propTypes = {
  fetchData: PropTypes.func.isRequired
};

export default compose(withTranslation(), withDocumentTitleUpdate)(DefaultBannerSets);
