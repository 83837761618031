import PropTypes from 'prop-types';

import MoviesViewListAbstract from '../../../MoviesView/MoviesViewListAbstract/MoviesViewListAbstract';

import {getLicensorMovieDetailsRoute} from "../../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation-routes.service";
import {showLicensorMovieDetails} from "../../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation.service";

class LicensorMoviesList extends MoviesViewListAbstract {
  constructor(props) {
    super(props);
    this.state.stickyPosition = 0;
  }

  /**
   * @override
   */
  getMovieDetailsRoute = (movieId) => {
    const {licensorId} = this.props;
    return getLicensorMovieDetailsRoute(licensorId, movieId);
  };

  /**
   * @override
   */
  showMovieDetails = (movieId) => {
    const {licensorId} = this.props;
    showLicensorMovieDetails(licensorId, movieId);
  };
}

LicensorMoviesList.propTypes = {
  licensorId: PropTypes.string
};

export default LicensorMoviesList;
