/* istanbul ignore next */
export const generateStepColor = (red, green, blue, count) => {
  const max = Math.max(red, Math.max(green, blue));

  const step = 255 / (max * 10);
  return `rgb(${red * step * count},${green * step * count},${blue * step * count})`;
};

/* istanbul ignore next */
export const mapInvalidColors = (invalidKeys) => {
  const data = {};
  let counter = 0;
  for (const key in invalidKeys) {
    counter++;
    data[key] = {
      shade: generateStepColor(255, 0, 0, counter),
      values: [+key].concat(invalidKeys[key])
    };
  }
  return data;
};