import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Router} from "@reach/router";

import LicensorInfo from '../LicensorInfo/LicensorInfo';
import LicensorMovies from '../../LicensorMovies/LicensorMovies';

import './LicensorDetailsBody.scss';
import PropTypes from "prop-types";

class LicensorDetailsBody extends Component {
  render() {
    const {name, onLicensorInfoEdit, onRoyaltyEdit, setLoading} = this.props;
    return (
      <div className='LicensorDetailsBody'>
        <Router>
          <LicensorInfo
            path={"/details/*"}
            data={this.props}
            onLicensorInfoEdit={onLicensorInfoEdit}
            onRoyaltyEdit={onRoyaltyEdit}
            setLoading={setLoading}
          />
          <LicensorMovies path={`/movies/*`} licensorName={name}/>
        </Router>
      </div>
    );
  }
}

LicensorDetailsBody.propTypes = {
  onLicensorInfoEdit: PropTypes.func.isRequired,
  onRoyaltyEdit: PropTypes.func.isRequired
};

export default withTranslation()(LicensorDetailsBody);
