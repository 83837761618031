import {withTranslation} from "react-i18next";

import UploadImages from "../../../../components/modal/UploadImages/UploadImages";

class UploadImagesModal extends UploadImages {

  /**@override
   */
  getImageCategories = () => {
    const {t} = this.props;
    return [t('UploadImagesModal.images')];
  };

  /**@override
   */
  uploadImages = () => {
    this.props.uploadImages(this.state.files);
    this.closeDialog();
  };
}

export default withTranslation()(UploadImagesModal);