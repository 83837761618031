import {withTranslation} from "react-i18next";

import {getStars} from '../../../../services/stars-service/stars.service';

import {uploadStarImage, assignStarImages} from '../../../../services/images-service/images.service'

import BaseGlobalImagesAssignSelectionModal from '../../../../components/BaseGlobalImagesAssignSelectionModal/BaseGlobalImagesAssignSelectionModal';

class StarSelectionModal extends BaseGlobalImagesAssignSelectionModal {

  assignImagesData = (data) => {
    return assignStarImages(data);
  };

  getAssignButtonTitle = () => {
    const {t} = this.props;
    return t('StarSelectionModal.assignImagesToStar');
  };

  getAssignData = (id, fileId, type) => {
    return {
      stars_id: id,
      images_id: fileId,
      type: type
    };
  };

  getImageCategories = () => {
    const {t} = this.props;
    return [t('StarSelectionModal.galleryShot'), t('StarSelectionModal.glamourShot')];
  };

  getInputPlaceholder = () => {
    const {t} = this.props;
    return t('StarSelectionModal.enterStarId');
  };

  getSelectConfig = () => {
    return {
      getData: getStars, 
      name: 'stars',
      id: 'id'
    };
  };

  getSelectInputLabel = () => {
    const {t} = this.props;
    return t('StarSelectionModal.label');
  };

  getTitle = () => {
    const {t} = this.props;
    return t('StarSelectionModal.title');
  };

  uploadImage = (id, type, file) => {
    return uploadStarImage(id, type, file);
  };
}

export default withTranslation()(StarSelectionModal);