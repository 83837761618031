import React, { Component } from 'react';
import { Router } from "@reach/router";
import { withTranslation } from "react-i18next";

import { getNatsUsers } from "../../../../../../../../../services/nats-service/nats.service";

import NatsUsersViewFooter from './NatsUsersViewFooter/NatsUsersViewFooter';
import NatsUserDetails from './NatsUserDetails/NatsUserDetails';
import NatsUsersViewHeader from './NatsUsersViewHeader/NatsUsersViewHeader';

import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";

import NatsUsersViewBody from "./NatsUsersViewBody/NatsUsersViewBody";

import './NatsUsersView.scss';

class NatsUsersView extends Component {

  filters = [];

  state = {
    dataLoaded: false,
    loading: false,
    loadingMsg: 'Loading users. Please wait',
    pagination: {
      current_page: 1,
      last_page: 0,
      per_page: 0,
      total: 0
    },
    users: []
  };

  componentDidMount() {
    this.fetchNatsUsers(1);
  }

  fetchNatsUsers = (page) => {
    this.setState({
      loading: true,
      users: []
    });
    const filters = this.getFilters();
    getNatsUsers(page, filters)
      .then(this.onGetNatsUsersSuccess)
      .catch(this.onGetNatsUsersFailure);
  };

  getFilters = () => {
    return this.filters;
  };

  refresh = () => {
    this.fetchNatsUsers(this.state.pagination.current_page || 1);
  };

  onFilterChange = (filters) => {
    this.filters = filters;
    this.fetchNatsUsers(1);
  };

  onGetNatsUsersFailure = (error) => {
    LogController.logError(error);
  };

  onGetNatsUsersSuccess = response => {
    const { users, pagination } = response.data.data;
    this.setState({
      dataLoaded: true,
      users: users,
      loading: false,
      pagination: pagination
    });
  };

  onPageChange = (page) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        current_page: page
      }
    });
    this.fetchNatsUsers(page);
  };

  isDetailsPage = () => {
    const { pathname } = this.props.location;
    return pathname.includes('/details/');
  }

  renderFooter = () => {
    const { current_page, total, per_page, last_page } = this.state.pagination;
    let footer = null;
    if (!this.isDetailsPage()) {
      footer = (
        <NatsUsersViewFooter
          currentPage={current_page}
          pageSize={per_page}
          recordCount={total}
          onChange={this.onPageChange}
          pageCount={last_page}
        />
      );
    }
    return footer;
  };

  renderBody = () => {
    const { users } = this.state;
    return (
      <Router>
        <NatsUsersViewBody
          path={'/*'}
          users={users}
          contentHeight={this.props.contentHeight}
          refresh={this.refresh}
        />
        <NatsUserDetails
          path={'/details/:userId'}
          contentHeight={this.props.contentHeight}
        />
      </Router>
    );
  };

  setLoadingMsg = (loadingMsg) => {
    this.setState({
      loadingMsg,
      loading: !!loadingMsg
    });
  };

  renderHeader = () => {
    let header = null;
    if (!this.isDetailsPage()) {
      header = <NatsUsersViewHeader setLoadingMsg={this.setLoadingMsg} onChange={this.onFilterChange} />
    }
    return header;
  };

  render() {
    const { loading, dataLoaded, loadingMsg } = this.state;
    return (
      <div className="NatsUsersView">
        {this.renderHeader()}
        <LoadingWrapper
          className='NatsUsersViewWrapper'
          dataLoaded={dataLoaded}
          isLoading={loading}
          loadingMsg={loadingMsg}
        >
          {this.renderBody()}
          {this.renderFooter()}
        </LoadingWrapper>
      </div>
    );
  }
}

export default withTranslation()(NatsUsersView);
