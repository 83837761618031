import axios from 'axios';

export const urls = {
	controlSwitchers: '/control_switchers',
	controlSwitchersAssociated: '/control_switchers/associated',
};


export const getAllControlSwitchers = () => {
	return axios.get(`${urls.controlSwitchers}`);
}

export const getAssociatedControlSwitchers = ({movies_id, studios_id, properties_id}) => {
	return axios.get(`${urls.controlSwitchersAssociated}`, {
		params: {
			movies_id: movies_id,
			studios_id: studios_id,
			properties_id: properties_id
		}
	})
}

export const editWhitelabelConfig = (propertyId, postData) => {
	return axios.post(`${urls.whitelabel}/${propertyId}/set`, {
		whitelabel_config: postData
	});
};
