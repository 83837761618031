import { ROUTES } from "../../../routes";
import { MAIN_DASHBOARD_VIEW_ROUTES } from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";


/**
 * Returns users view route
 *
 * @returns {string}
 */
export const getUsersBaseRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.USERS}`;
};

export const getUsersRoute = () => {
  return `${getUsersBaseRoute()}/list`;
};

/**
 * Returns NATS users view route
 *
 * @returns {string}
 */
export const getNatsUsersRoute = () => {
  return `${getUsersBaseRoute()}/nats`;
};

/**
 * Returns user details route
 *
 * @param {string|number} userId
 *
 * @returns {string}
 */
export const getUserDetailsRoute = userId => {
  return `${getUsersRoute()}/details/${userId}`;
};

/**
 * Returns nats user details route
 *
 * @param {string|number} userId
 *
 * @returns {string}
 */
export const getNatsUserDetailsRoute = userId => {
  return `${getNatsUsersRoute()}/details/${userId}`;
};

export const getRoyaltiesUsersRoute = () => {
  return `${getUsersBaseRoute()}/royalties`;
};

export const getRoyaltiesUserDetailsRoute = userId => {
  return `${getRoyaltiesUsersRoute()}/details/${userId}`;
};