import React from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import MoviesMainViewAbstract from "../../../MoviesView/MoviesMainViewAbstract/MoviesMainViewAbstract";

import PropertyMoviesFooter from "./PropertyMoviesFooter/PropertyMoviesFooter";
import PropertyMoviesList from "./PropertyMoviesList/PropertyMoviesList";

class PropertyMoviesMainView extends MoviesMainViewAbstract {

  renderFooter = () => {
    const { data } = this.props;
    return <PropertyMoviesFooter onChange={this.props.onChange}
      currentPage={data.currentPage}
      pageCount={data.pageCount}
      pageSize={data.pageSize}
      recordCount={data.recordCount} />;
  };

  renderMoviesList = () => {
    const {
      data,
      propertyId,
      setMovieEnabled,
      onMovieEdit,
      onMovieEdit2257,
      onSpecialCategoriesEdit } = this.props;
    return (
      <PropertyMoviesList
        movies={data.movies}
        propertyId={propertyId}
        setMovieEnabled={setMovieEnabled}
        onMovieEdit={onMovieEdit}
        onMovieEdit2257={onMovieEdit2257}
        onSpecialCategoriesEdit={onSpecialCategoriesEdit}
      />
    )
  };
}

PropertyMoviesMainView.propTypes = {
  propertyId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setMovieEnabled: PropTypes.func.isRequired
};

export default withTranslation()(PropertyMoviesMainView);
