import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Collapse, Icon } from "antd";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import AdConfigurationBaseAbstract from "../../../shared/AdConfigurationBaseAbstract";
import CollapsiblePanel from "../../../shared/CollapsiblePanel/CollapsiblePanel";
import ModalDialog from "../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import PropertyAdConfiguration from "../AdConfigPanel/AdConfigPanel";

import { stopEvent } from "../../../../../../../../../../../../services/util-service/util.service";

import "./AdsConfigurationModal.scss";

/* istanbul ignore file */
class AdsConfigurationModal extends AdConfigurationBaseAbstract {
	modalRef = React.createRef();

	state = {
		ads: [],
		activeAds: [],
		invalidAds: {},
		verificationTriggered: false,
	};

	constructor(props) {
		super(props);
		const { propertyAds } = props;
		this.state.ads =
			propertyAds && propertyAds.length
				? cloneDeep(propertyAds)
				: [this.getInitialAdConfig()];
		this.state.activeAds = [0];
	}

	adMap = (ad, index) => {
		const { propertyId } = this.props;
		const { invalidAds, verificationTriggered } = this.state;
		const classes = ["AdConfigCollapsePanel"];
		const invalidReason = invalidAds[index];
		if (verificationTriggered) {
			const validationClass = invalidReason ? "Invalid" : "Valid";
			classes.push(validationClass);
		}
		return (
			<Collapse.Panel
				key={index}
				className={classes.join(" ")}
				header={this.getAdCollapseHeader(index)}
			>
				{verificationTriggered
					? this.renderInvalidReasonExplanation(invalidReason)
					: null}
				<PropertyAdConfiguration
					ad={ad}
					key={index}
					adIndex={index}
					onAddUpdate={this.onPropertyAdUpdate}
					propertyId={propertyId}
				/>
			</Collapse.Panel>
		);
	};

	addMoreAd = () => {
		this.setState((prevState) => {
			const ads = cloneDeep(prevState.ads);
			const { activeAds } = prevState;
			ads.push(this.getInitialAdConfig());
			activeAds.push(ads.length - 1);
			return { ads, activeAds };
		});
	};

	closeModal = () => {
		this.getModal().closeModal();
	};

	getActions = () => {
		const { t } = this.props;
		return (
			<div className="Actions">
				<Button onClick={this.addMoreAd}>
					{t(
						"ConfigureAdsOnPropertyLevel.AdsConfigurationModal.moreAds"
					)}
				</Button>
				<div>
					<Button onClick={this.closeModal}>
						{t(
							"ConfigureAdsOnPropertyLevel.AdsConfigurationModal.cancel"
						)}
					</Button>
					<Button onClick={this.onSaveChangesClick}>
						{t(
							"ConfigureAdsOnPropertyLevel.AdsConfigurationModal.saveAds"
						)}
					</Button>
				</div>
			</div>
		);
	};

	getAdCollapseHeader = (index) => {
		const { t } = this.props;
		return (
			<div className="AdConfigHeader">
				{t(
					"ConfigureAdsOnPropertyLevel.AdsConfigurationModal.adCollapseHeader",
					{ index }
				)}
				<div>
					<Icon
						type="close"
						onClick={this.removeAd.bind(this, index)}
					/>
				</div>
			</div>
		);
	};

	getInitialAdConfig = () => {
		return {
			applyToLoggedUser: true,
			applyToNotLoggedUser: true,
			selectedApplicationTime: "beginning",
			selectedSection: "movie",
			selectedTarget: "_self",
		};
	};

	getModal = () => {
		return this.modalRef.current;
	};

	getTitle = () => {
		const { propertyName, t } = this.props;
		return t("ConfigureAdsOnPropertyLevel.AdsConfigurationModal.title", {
			propertyName,
		});
	};

	onPropertyAdUpdate = (propertyId, adIndex, data) => {
		this.setState((prevState) => {
			const ads = cloneDeep(prevState.ads);
			ads[adIndex] = data;
			return { ads };
		});
	};

	removeAd = (index, event) => {
		stopEvent(event);
		this.setState((prevState) => {
			const ads = cloneDeep(prevState.ads);
			ads.splice(index, 1);
			return { ads };
		});
	};

	renderAdsConfig = () => {
		const { activeAds } = this.state;
		return (
			<CollapsiblePanel
				activeKeys={activeAds}
				defaultActiveKeys={activeAds}
				onChange={this.setActiveAds}
			>
				{this.state.ads.map(this.adMap)}
			</CollapsiblePanel>
		);
	};

	onSaveAdsValidationCompleted = () => {
		const { invalidAds } = this.state;
		if (Object.keys(invalidAds).length) {
			this.displayErrorsFoundModal();
		} else {
			this.saveChanges();
		}
	};

	onSaveChangesClick = () => {
		this.validateAds(this.onSaveAdsValidationCompleted);
	};

	saveChanges = () => {
		const { propertyId, onPropertyAdsChange } = this.props;
		onPropertyAdsChange(propertyId, cloneDeep(this.state.ads));
		this.closeModal();
	};

	setActiveAds = (keys) => {
		this.setState({ activeAds: keys });
	};

	validateAds = (callback) => {
		this.setState((prevState) => {
			const { ads } = prevState;
			const invalidAds = this.getAdsInvalidKeys(ads);
			return {
				invalidAds,
				verificationTriggered: true,
			};
		}, callback);
	};

	render() {
		return (
			<ModalDialog
				title={this.getTitle()}
				actions={this.getActions()}
				forwardedRef={this.modalRef}
				className="AdsConfigurationModal"
			>
				<div className="AdsConfigurationModal-inner">
					{this.renderAdsConfig()}
				</div>
			</ModalDialog>
		);
	}
}

AdsConfigurationModal.propTypes = {
	onPropertyAdsChange: PropTypes.func.isRequired,
	propertyAds: PropTypes.array,
	propertyId: PropTypes.number.isRequired,
	propertyName: PropTypes.string.isRequired,
};

export default withTranslation()(AdsConfigurationModal);
