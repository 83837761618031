import React, {Component} from 'react';
import PropTypes from 'prop-types';

import MoviesViewFooter from "../MoviesMainView/MoviesViewFooter/MoviesViewFooter";
import MoviesViewHeader from "../MoviesMainView/MoviesViewHeader/MoviesViewHeader";

import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import MoviesViewListAbstract from "../MoviesViewListAbstract/MoviesViewListAbstract";

import jsonData from '../MoviesViewAbstract/data';

import './MoviesMainViewAbstract.scss';


class MoviesMainViewAbstract extends Component {

  defaultSortFilter = jsonData.sortCriteria[3];

  localizedDefaultSortFilter = this.props.t(`MoviesMainViewAbstract.${this.defaultSortFilter}`);

  getSortCriteria = (criteria) => {
    const {t} = this.props;
    return {
      text: t(`MoviesMainViewAbstract.${criteria}`),
      name: criteria
    }
  }

  renderFooter = () => {
    const {data, onPageChange} = this.props;
    return <MoviesViewFooter onChange={onPageChange}
                             currentPage={data.currentPage}
                             pageCount={data.pageCount}
                             pageSize={data.pageSize}
                             recordCount={data.recordCount}/>;
  };

  renderHeader = () => {
    const {t, data, filters, onClearFilter, filterByStatus, additionalActions} = this.props;
    return <MoviesViewHeader message={t('MoviesMainViewAbstract.defaultCardInfoText')}
                             detailsMessage={t('MoviesMainViewAbstract.recordsFound', {recordCount: data.recordCount})}
                             status={data.status}
                             onClearFilter={onClearFilter}
                             generalLinks={jsonData.generalLinks}
                             sortCriteria={jsonData.sortCriteria.map(this.getSortCriteria)}
                             onStatusChange={filterByStatus}
                             defaultDisplayFilter={filters.display}
                             defaultSortFilter={this.defaultSortFilter}
                             localizedDefaultSortFilter={this.localizedDefaultSortFilter}
                             defaultNameFilter={filters.search}
                             additionalActions={additionalActions}/>;
  };

  renderMoviesList = () => {
    const {data, setMovieEnabled, onMovieEdit, onSpecialCategoriesEdit, onMovieEdit2257, scrollTop} = this.props;
    return (
      <MoviesViewListAbstract movies={data.movies}
                              onMovieEdit={onMovieEdit}
                              onSpecialCategoriesEdit={onSpecialCategoriesEdit}
                              onMovieEdit2257={onMovieEdit2257}
                              setMovieEnabled={setMovieEnabled}
                              scrollTop={scrollTop}/>
    )
  };

  render() {
    const {data} = this.props;
    return (
      <LoadingWrapper className='MoviesMainViewAbstract'
                      dataLoaded={data.moviesLoaded}
                      isLoading={!!data.loadingMsg}
                      loadingMsg={data.loadingMsg}>
        {this.renderHeader()}
        {this.renderMoviesList()}
        {this.renderFooter()}
      </LoadingWrapper>
    );
  }
}

MoviesMainViewAbstract.propTypes = {
  additionalActions: PropTypes.element,
  data: PropTypes.object.isRequired,
  defaultNameFilter: PropTypes.string,
  filters: PropTypes.object.isRequired,
  fetchMovies: PropTypes.func.isRequired,
  filterByStatus: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  setMovieEnabled: PropTypes.func.isRequired,
  onMovieEdit: PropTypes.func.isRequired,
  onSpecialCategoriesEdit: PropTypes.func.isRequired,
  onMovieEdit2257: PropTypes.func.isRequired,
  scrollTop: PropTypes.number
};

export default MoviesMainViewAbstract;
