import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';

import {
  addBannerToSet,
  getBannerSets,
  removeBannerFromSet,
  getBannerById
} from '../../../../../../../../../services/banners-service/banners.service';

import BaseCheckboxList from '../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList';

import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../services/notification-service/notification.service';
import { LogController } from '../../../../../../../../../controllers/log-controller/log.controller';

import ModalDialog from '../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import './AddToSetModal.scss';

class AddToSetModal extends Component {

  addedBannerSetIds = [];

  initialSelection = [];

  isUnMounted = false;

  modalRef = React.createRef();

  removedBannerSetIds = [];

  state = {
    banners: [],
    selectedBannerSets: [],
    bannerSets: []
  };

  getItemIds = item => {
    return item.id;
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  addBannerToSet = (bannerSetId) => {
    const { bannerId } = this.props;
    let postData = [bannerId];
    return addBannerToSet(bannerSetId, postData);
  };

  addBannerToSets = () => {
    const message = this.props.t('AddToSetModal.savingMsg');
    this.getModal().setLoading(message);

    let promises = this.addedBannerSetIds.map(this.addBannerToSet);
    promises = promises.concat(this.removedBannerSetIds.map(this.removeBannerFromSet));

    Promise.all(promises)
      .then(this.onAddToSetSuccess)
      .catch(this.onAddToSetFailure);
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='AddToSetModal-actions'>
        <Button onClick={this.onCancel} key='cancelBtn'>
          {t('AddToSetModal.cancel')}
        </Button>
        <Button
          onClick={this.addBannerToSets}
          key='addToSetBtn'
          disabled={!this.addedBannerSetIds.length && !this.removedBannerSetIds.length}
          type='primary'
        >
          {t('AddToSetModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getAddedBannerSetsIds = (ids) => {
    const addedBannerSetIds = [];
    let item = ids.length;
    let currentId;
    while (item) {
      item--;
      currentId = ids[item];
      if (this.initialSelection.indexOf(currentId) === -1) {
        addedBannerSetIds.push(currentId);
      }
    }

    return addedBannerSetIds;
  };

  fetchData = () => {
    const message = this.props.t('AddToSetModal.loadingMsg');
    this.getModal().setLoading(message);

    const { bannerId } = this.props;
    const promises = [getBannerById(bannerId), getBannerSets()];

    Promise.all(promises)
      .then(this.loadBannerData)
      .catch(this.onGetBannerDataFailure);
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('AddToSetModal.addToSet');
  };

  getRemovedBannerSetIds = (ids) => {
    const removedBannerSetIds = [];
    let item = this.initialSelection.length;
    let currentId;
    while (item) {
      item--;
      currentId = this.initialSelection[item];
      if (ids.indexOf(currentId) === -1) {
        removedBannerSetIds.push(currentId);
      }
    }

    return removedBannerSetIds;
  };

  loadBannerData = (response) => {
    const { banners_sets } = response[0].data.data;
    this.initialSelection = banners_sets.map(this.getItemIds);

    this.setState({
      bannerSets: response[1].data.data.sort(this.sortByName),
      selectedBannerSets: this.initialSelection.slice()
    });

    this.getModal().clearLoading();
  };

  onAddToSetFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddToSetModal.addToSetFailed')
    });
  };

  onAddToSetSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddToSetModal.addToSetSuccess')
    });
    this.props.onBannerSetChanged();
  };

  onBannerSetChange = (selectedBannerSets) => {
    this.addedBannerSetIds = this.getAddedBannerSetsIds(selectedBannerSets);
    this.removedBannerSetIds = this.getRemovedBannerSetIds(selectedBannerSets);
    this.setState({ selectedBannerSets });
  };

  onGetBannerDataFailure = error => {
    this.getModal().clearLoading();
    LogController.logError(error);
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  removeBannerFromSet = (bannerSetId) => {
    const { bannerId } = this.props;
    const postData = [bannerId];
    return removeBannerFromSet(bannerSetId, postData);
  };

  renderBannerSetsList = () => {
    const { bannerSets, selectedBannerSets } = this.state;
    return (
      <div>
        {bannerSets.length ? (
          <BaseCheckboxList showSelectAll={true}
            onChange={this.onBannerSetChange}
            checked={selectedBannerSets}
            items={bannerSets} />
        ) : null}
      </div>
    );
  };

  sortByName = ({ name }, { name: name1 }) => {
    let order = 0;
    if (name > name1) {
      order = 1;
    } else if (name < name1) {
      order = -1;
    }

    return order;
  };

  render() {
    const { t } = this.props;
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='AddToSetModal'>
          <span className='Label'>{t('AddToSetModal.chooseSet')}:</span>
          {this.renderBannerSetsList()}
        </div>
      </ModalDialog>
    );
  }
}

export default withTranslation()(AddToSetModal);
