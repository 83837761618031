import React, { Component } from 'react';
import { Link } from "@reach/router";
import { withTranslation } from "react-i18next";
import { Button, List, Table, Icon, Menu, Dropdown } from "antd";
import PropTypes from 'prop-types';

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import BaseDropdown from "../../../../../../../../../../components/BaseDropdown/BaseDropdown";

import { renderListClickableItem } from "../../../../../../../../../../services/util-service/util.service";
import { renderMomentWithHours } from "../../../../../../../../../../services/date-service/date.service";
import {
  getPropertyDetailsRoute,
  getPropertyDetailsMoviesRoute
} from "../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";
import { showPropertyDetails } from "../../../../../../../../../../services/navigation/properties-navigation/properties-navigation.service";

import './PropertiesBody.scss';
import { editPropertyVisibility } from '../../../../../../../../../../services/properties-service/properties.service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../../services/notification-service/notification.service';
import { clearCache } from '../../../../../../../../../../services/cache-service/cache.service';
import { LoadingController } from '../../../../../../../../../../controllers/loading-controller/loading.controller';

class PropertiesBody extends Component {

  renderActionDropdown = (item, index) => {
    const { t } = this.props;
    return (
      <div className="Actions-Container">
        <BaseDropdown items={this.getDropdownItems(item, index)}>
          <Button className="Action-Button">
            {t('PropertiesBody.actions')}
            <Icon type="down" />
          </Button>
        </BaseDropdown>
        <Button
          onClick={this.onClearCache.bind(this, item)}
          className='ClearCacheBtn'
        >
          <Icon type="delete" />
          {t('PropertiesBody.clearCache')}
        </Button>
        {this.renderEnabledComboBox(item)}
      </div>
    );
  };

  onClearCache = (item) => {
    const { t } = this.props;
    LoadingController.showLoadingMask(t('PropertiesBody.clearingCache'));
    clearCache(item.id)
      .then(this.onClearCacheSuccess)
      .catch(this.onClearCacheFail);
  };

  onClearCacheFail = () => {
    LoadingController.removeLoadingMask();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('PropertiesBody.cacheClearedFailure')
    });
  };

  onClearCacheSuccess = () => {
    LoadingController.removeLoadingMask();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('PropertiesBody.cacheClearedSuccessfully')
    });
  };

  renderMoviesItem = (moviesCount, data) => {
    return renderListClickableItem(data, undefined, `property-movies-${data.id}`, getPropertyDetailsMoviesRoute, 'movie_count');
  };

  renderPropertyDetailsLink = (key, data) => <Link style={{ color: 'inherit' }} to={getPropertyDetailsRoute(data.id)} key={key}>{key}</Link>;

  toggleVisibleStatusSuccess = () => {
    displaySuccessNotification({
      duration: 3,
      message: this.props.t("PropertiesBody.editVisibilitySuccess"),
    });
    this.props.onChange();
  };

  toggleVisibleStatusFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t("PropertiesBody.editVisibilityFailure"),
    });
  };

  toggleEnabledStatus = (id, value) => {
    const visible = value === 'Visible';
    editPropertyVisibility(id, visible)
      .then(this.toggleVisibleStatusSuccess)
      .catch(this.toggleVisibleStatusFailure)
  };

  getEnableDisableDropdownMenu = whiteLabelId => {
    const { t } = this.props;
    return (
      <Menu className="ThemeCard-enabledDisabled">
        {['Visible', 'Hidden'].map(value => (
          <Menu.Item
            key={value}
          >
            <div
              onClick={(event) => this.toggleEnabledStatus(whiteLabelId, value)}
              className={`ThemeCard-${value}`}>
              {t(`PropertiesBody.${value}`)}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  renderEnabledComboBox = (config) => {
    const visible = config.visible === 1;
    const { t } = this.props;
    return (
      <div className="StatusItem">
        <Dropdown overlay={this.getEnableDisableDropdownMenu(config.id)}>
          <Button className={visible ? 'Visible' : 'Hidden'}>
            {visible ? t('PropertiesBody.Visible') : t('PropertiesBody.Hidden')}
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
    );
  };

  /**
   * Table columns config
   *
   * @type {Object[]}
   */
  columns = [{
    title: this.props.t('PropertiesBody.name'),
    dataIndex: 'name',
    render: this.renderPropertyDetailsLink,
    key: 'name',
    width: 270
  }, {
    title: this.props.t('PropertiesBody.movies'),
    dataIndex: 'movie_count',
    render: this.renderMoviesItem,
    key: 'movie_count',
    width: 130
  }, {
    title: this.props.t('PropertiesBody.users'),
    dataIndex: 'nats_users_count',
    key: 'users',
    width: 90
  }, {
    title: this.props.t('PropertiesBody.activeMembers'),
    dataIndex: 'nats_users_active_count',
    key: 'activeMembers',
    width: 140
  }, {
    title: this.props.t('PropertiesBody.dateCreated'),
    dataIndex: 'created_at',
    key: 'dateCreated',
    render: renderMomentWithHours,
    width: 260
  }, {
    title: this.props.t('PropertiesBody.action'),
    align: 'center',
    key: 'action',
    render: this.renderActionDropdown,
    width: 400
  }];

  /**
   * Navigates to property details route
   *
   * @param {Object} item
   */
  displayDetails = (item) => {
    showPropertyDetails(item.id)
  };

  getDropdownItems = (item, index) => {
    const {
      showConfigureCategoriesModal,
      showEditCatalogModal,
      showCopyCatalogModal,
      showEditPropertyModal,
      showEditSpecialCategoriesModal,
      showEditTop10Modal,
      showMovieSpotlightModal,
      showHomePageSpotlightModal,
      showSceneSpotlightModal,
      showSetBrowseConfigurationModal,
      showSetNATSCodeModal,
      showEditBanedWordsModal,
      showEditSearchOptionsModal,
      t
    } = this.props;
    return [
      <Link to={getPropertyDetailsRoute(item.id)}
        key={`viewDetails-${index}`}>
        {t('PropertiesBody.viewDetails')}
      </Link>,
      <div onClick={showEditCatalogModal.bind(this, item)} key={`edit-catalog-${index}`}>
        {t('PropertiesBody.editCatalog')}
      </div>,
      <div onClick={showCopyCatalogModal.bind(this, item)} key={`copy-catalog-${index}`}>
        {t('PropertiesBody.copyCatalog')}
      </div>,
      <div onClick={showEditTop10Modal.bind(this, item)} key={`edit-top-10-${index}`}>
        {t('PropertiesBody.editTop10')}
      </div>,
      <div onClick={showEditSpecialCategoriesModal.bind(this, item)} key={`edit-special-categories-${index}`}>
        {t('PropertiesBody.editSpecialCategories')}
      </div>,
      <div onClick={showConfigureCategoriesModal.bind(this, item)} key={`configure-category-${index}`}>
        {t('PropertiesBody.configureCategories')}
      </div>,
      <div onClick={showMovieSpotlightModal.bind(this, item)} key={`set-movie-spotlight-${index}`}>
        {t('PropertiesBody.setMovieSpotlight')}
      </div>,
      <div onClick={showHomePageSpotlightModal.bind(this, item)} key={`set-home-page-spotlight-${index}`}>
        {t('PropertiesBody.setHomePageSpotlight')}
      </div>,
      <div onClick={showSceneSpotlightModal.bind(this, item)} key={`scene-spotlight-${index}`}>
        {t('PropertiesBody.setSceneSpotlight')}
      </div>,
      <div onClick={showSetBrowseConfigurationModal.bind(this, item)} key={`browse-configuration-${index}`}>
        {t('PropertiesBody.setBrowseConfiguration')}
      </div>,
      <div onClick={showSetNATSCodeModal.bind(this, item)} key={`set-nats-code-${index}`}>
        {t('PropertiesBody.setNatsCode')}
      </div>,
      <div onClick={showEditPropertyModal.bind(this, item)} key={`edit-property-${index}`}>
        {t('PropertiesBody.edit')}
      </div>,
      <div onClick={showEditSearchOptionsModal.bind(this, item)} key={`edit-search-options-${index}`}>
        {t('PropertiesBody.setSearchOptions')}
      </div>,
      <div onClick={showEditBanedWordsModal.bind(this, item)} key={`edit-banned-words-${index}`}>
        {t('PropertiesBody.editBannedWords')}
      </div>,
    ];
  };

  /**
   * If property value in item exists then returns that value else returns an empty string
   *
   * @param {Object} item
   * @param {String} property
   *
   * @returns {string}
   */
  getItemPropertyValue = (item, property) => {
    return item[property] !== undefined ? item[property] : '';
  };

  /**
   * Returns list item card footer element for render
   *
   * @param {Object} item
   *
   * @returns {JSX.Element}
   */
  getListCardFooter = (item) => {
    const { t } = this.props;
    return (
      <div className="PropertiesBody-listCard-footer">
        <Button onClick={this.displayDetails.bind(this, item)}>{t('PropertiesBody.viewDetails')}</Button>
      </div>
    );
  };

  /**
   * Returns list item element for render
   *
   * @param {Object} item
   *
   * @returns {JSX.Element}
   */
  renderListItem = (item) => {
    const { id, movie_count, users, activeMembers, orders } = item;
    const { t } = this.props;
    return (
      <List.Item key={`property-list-${id}`}>
        <BaseCard title={item.name} footer={this.getListCardFooter(item)}>
          <div className='ListCardWrapper'>
            <p>
              <span className='ListItemLabel'>
                {t('PropertiesBody.movies')}:
              </span>
              {this.renderMoviesItem(movie_count, item)}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('PropertiesBody.users')}:
              </span>
              {users}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('PropertiesBody.activeMembers')}:
              </span>
              {activeMembers}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('PropertiesBody.orders')}:
              </span>
              {orders}
            </p>
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  render() {
    return (
      <div className="PropertiesBody">
        <List className="ListView"
          itemLayout="vertical"
          dataSource={this.props.properties}
          renderItem={this.renderListItem}
          split={false} />
        <Table columns={this.columns}
          dataSource={this.props.properties}
          bordered
          pagination={false}
          rowKey="id"
          scroll={this.props.scroll}
          size='small'
        />
      </div>
    );
  }
}

PropertiesBody.propTypes = {
  properties: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  scroll: PropTypes.object.isRequired,
  showConfigureCategoriesModal: PropTypes.func,
  showEditCatalogModal: PropTypes.func,
  showCopyCatalogModal: PropTypes.func,
  showEditPropertyModal: PropTypes.func,
  showEditSearchOptionsModal: PropTypes.func,
  showEditTop10Modal: PropTypes.func,
  showMovieSpotlightModal: PropTypes.func,
  showHomePageSpotlightModal: PropTypes.func,
  showSetBrowseConfigurationModal: PropTypes.func,
  showSceneSpotlightModal: PropTypes.func,
  showSetNATSCodeModal: PropTypes.func,
};

export default withTranslation()(PropertiesBody);
