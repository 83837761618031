import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BaseImagesDisplay from "../../../../../../../../../../components/BaseImagesDisplay/BaseImagesDisplay";

import './BannerDetailsImagesTab.scss';

class BannerDetailsImagesTab extends Component {
  render() {
    const { images, bannerId, deleteImage, onDeleteSuccess, onEditImage } = this.props;
    return (
      <div className="BannerDetailsImagesTab">
        <BaseImagesDisplay images={images}
          bannerId={bannerId}
          deleteImage={deleteImage}
          onDeleteSuccess={onDeleteSuccess}
          onEditImage={onEditImage}
        />
      </div>
    );
  }
}

BannerDetailsImagesTab.propTypes = {
  images: PropTypes.array.isRequired,
  bannerId: PropTypes.string.isRequired,
  deleteImage: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
  onEditImage: PropTypes.func.isRequired
};

export default BannerDetailsImagesTab;
