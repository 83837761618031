import React, { Component } from 'react';
import { Link } from "@reach/router";
import PropTypes from 'prop-types';

import './BaseNavigationItem.scss';

class BaseNavigationItem extends Component {
  renderComponent = () => {
    const { routes = [], children } = this.props;
    let view = null;

    if (routes.length === 0) return null;

    view = routes.map((item, index) => {
      if (item.url) {
        return (
          <Link
            to={item.url}
            key={item.text + '-' + index}
            className='Breadcrumb-link'
          >
            {item.text}
          </Link>
        )
      } else {
        return (
          <span
            key={item.text + '-' + index}
            className='Breadcrumb-text'
          >
            {item.text}
          </span>
        )
      }
    })

    return (
      <div className='BaseNavigationItem'>
        <div>
          {view}
        </div>
        <div>
          {children}
        </div>
      </div>
    )
  };

  render() {
    return this.renderComponent();
  }
}

BaseNavigationItem.propTypes = {
  routes: PropTypes.array.isRequired
};

export default BaseNavigationItem;
