import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Button, Input, Select, Icon } from "antd";

import {
  getUsersRoute,
  getNatsUsersRoute,
  getRoyaltiesUsersRoute
} from "../../../../../../../../../../services/navigation/users-navigation/users-navigation-routes.service";
import {
  showUsers,
  showNatsUsers,
  showRoyaltiesUsers
} from "../../../../../../../../../../services/navigation/users-navigation/users-navigation.service";
import {
  getProperties
} from "../../../../../../../../../../services/properties-service/properties.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../services/notification-service/notification.service';
import { exportNatsUsers } from '../../../../../../../../../../services/nats-service/nats.service';
import { CSVLink } from 'react-csv';

import BaseHorizontalNavigation
  from "../../../../../../../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";

import './NatsUsersViewHeader.scss';

export const DisplayFilters = Object.freeze({
  past7Days: 7,
  past14Days: 14,
  past30Days: 30,
  today: 1
});

export const UserTypeFilters = Object.freeze({
  all: 0,
  adminAndTest: 1,
  registred: 2
});

const filterParams = {
  property_id: 'property_id',
  display: 'display',
  user_type: 'user_type',
};

class NatsUsersViewHeader extends Component {

  displayFilters = [{
    name: this.props.t('NatsUsersViewHeader.today'),
    value: DisplayFilters.today
  }, {
    name: this.props.t('NatsUsersViewHeader.past7Days'),
    value: DisplayFilters.past7Days
  }, {
    name: this.props.t('NatsUsersViewHeader.past14Days'),
    value: DisplayFilters.past14Days
  }, {
    name: this.props.t('NatsUsersViewHeader.past30Days'),
    value: DisplayFilters.past30Days
  }];

  userTypeFilters = [{
    name: this.props.t('StreamingUsers.all'),
    value: UserTypeFilters.all
  }, {
    name: this.props.t('StreamingUsers.adminAndTest'),
    value: UserTypeFilters.adminAndTest
  }, {
    name: this.props.t('StreamingUsers.registred'),
    value: UserTypeFilters.registred
  }];

  filterDebounce = 1000;

  state = {
    streaming_key: '',
    user_type: UserTypeFilters.all,
    display: '',
    email: '',
    userName: '',
    filterToggled: false,
    property_id: '',
    allProperties: [],
    searchProperties: []
  };

  timeoutId;

  componentDidMount() {
    this.getAllProperties();
  }

  callPropsFilterChange = () => {
    const filters = this.getFilters();
    const { email, userName, display, user_type, streaming_key, property_id } = this.state;
    this.props.onChange(filters, email, userName, display, user_type, streaming_key, property_id);
  };

  clearFilter = () => {
    this.setState({
      display: '',
      email: '',
      userName: '',
      streaming_key: '',
      property_id: '',
      user_type: UserTypeFilters.all
    }, this.callPropsFilterChange);
  };

  getFilters = () => {
    const filters = [];
    const { email, userName, display, user_type, streaming_key, property_id } = this.state;
    if (email) {
      filters.push(`email=${email}`);
    }
    if (userName) {
      filters.push(`username=${userName}`);
    }
    if (display) {
      filters.push(`joined=${display}`);
    }
    if (user_type) {
      filters.push(`${filterParams.user_type}=${user_type}`);
    }
    if (streaming_key) {
      filters.push(`streaming_key=${streaming_key}`);
    }
    if (property_id) {
      filters.push(`${filterParams.property_id}=${property_id}`);
    }
    return filters;
  };

  getNavigationItems = () => {
    const { t } = this.props;
    return [{
      link: getUsersRoute(),
      name: t('UsersViewHeader.usersTab'),
      navigate: showUsers
    }, {
      link: getNatsUsersRoute(),
      name: t('UsersViewHeader.natsUsersTab'),
      navigate: showNatsUsers
    }, {
      link: getRoyaltiesUsersRoute(),
      name: t('UsersViewHeader.royalityUsersTab'),
      navigate: showRoyaltiesUsers
    }];
  };

  hasFilterChanged = () => {
    const { display, email, userName, streaming_key, property_id, user_type } = this.state;
    return !!(display || email || userName || streaming_key || property_id || user_type);
  };

  renderClearFilterBtn = () => {
    const { t } = this.props;
    return (
      <Button onClick={this.clearFilter}
        disabled={!this.hasFilterChanged()}>
        {t('NatsUsersViewHeader.clearFilter')}
      </Button>
    );
  };

  renderFilterItem = (item) => {
    const name = item?.name;
    const value = item?.value ?? item?.id;

    return (
      <Select.Option key={name} value={value}>
        <div className="NatsUsersViewHeaderFilterItemWrapper">
          {item.name}
        </div>
      </Select.Option>
    );
  };

  renderSearchToggle = () => {
    const { t } = this.props;
    return (
      <div className="NatsUsersViewHeader-inner">
        {t('NatsUsersViewHeader.filters')}:
        <Icon type={this.state.filterToggled ? 'up' : 'down'}
          onClick={this.toggleFilters} />
      </div>
    );
  };

  getAllProperties = () => {
    getProperties(-1)
      .then(data => {
        const { properties } = data?.data?.data;
        this.setState({ allProperties: properties });
      })
      .catch(err => {
        this.setState({ property_id: err?.message });
        console.log(err);
      })
  };

  onChange = (event) => {
    let name;
    let value;

    if (event?.target) {
      name = event.target.name;
      value = event.target.value;
    } else {
      name = event.name;
      value = event.value;
    }

    this.setState({ [name]: value }, () => {
      if (event?.promptly) {
        this.callPropsFilterChange();
      } else {
        if (this.timeoutId) { clearTimeout(this.timeoutId); }
        this.timeoutId = setTimeout(this.callPropsFilterChange, this.filterDebounce);
      }
    });
  };

  searchProperty = (val) => {
    const { allProperties } = this.state;
    const filtered = allProperties.filter(item => item.name.toLowerCase().includes(val.toLowerCase()));
    this.setState({ searchProperties: filtered });
  }

  renderFilters = (name, label) => {
    const { t } = this.props;
    const { user_type, display, property_id, allProperties, searchProperties } = this.state;
    const properties = searchProperties.length === 0 ? allProperties : searchProperties;

    const view = () => {
      let element = <Input value={this.state[name]} name={name} onChange={this.onChange} />;

      if (name === filterParams.user_type) {
        element = (
          <Select
            showArrow
            className="NatsUsersViewHeader-select"
            onChange={value => this.onChange({ value, name, promptly: true })}
            value={user_type}
          >
            {this.userTypeFilters.map(this.renderFilterItem)}
          </Select>
        );
      } else if (name === filterParams.display) {
        element = (
          <Select
            showArrow
            className="NatsUsersViewHeader-select"
            onChange={value => this.onChange({ value, name, promptly: true })}
            value={display}
          >
            {this.displayFilters.map(this.renderFilterItem)}
          </Select>
        );
      } else if (name === filterParams.property_id) {
        element = (
          <Select
            showArrow
            className="NatsUsersViewHeader-select property-dropdown"
            onChange={value => this.onChange({ value, name, promptly: true })}
            value={property_id}
            showSearch
            filterOption={false}
            onSearch={this.searchProperty}
            onDropdownVisibleChange={() => this.setState({ searchProperties: [] })}
          >
            {properties.map(this.renderFilterItem)}
          </Select>
        );
      }
      return element;
    }
    return (
      <div className="NatsUsersViewHeader-filterWrapper">
        <div className="NatsUsersViewHeader-filterLabel">
          {t(`NatsUsersViewHeader.${label}`)}:
        </div>
        {view()}
      </div>
    );
  }

  toggleFilters = () => {
    this.setState(prevState => {
      return {
        filterToggled: !prevState.filterToggled
      };
    });
  };

  renderExportButton = () => {
    return <Button onClick={this.onExport} disabled={!this.hasFilterChanged()}>Export</Button>;
  }

  onExportFailure = () => {
    const { setLoadingMsg } = this.props;
    setLoadingMsg('');
    displayErrorNotification({
      duration: 3,
      message: 'Users export failed!'
    });
  };

  onExportSuccess = response => {
    const { setLoadingMsg } = this.props;
    setLoadingMsg('');
    displaySuccessNotification({
      duration: 0,
      message: 'Users export success!',
      description: (
        <CSVLink
          data={response.data}
          filename={"nats-users.csv"}
        >
          Click to download CSV file
        </CSVLink>
      )
    });
  };

  onExport = () => {
    const { setLoadingMsg } = this.props;
    const filters = this.getFilters();
    setLoadingMsg('Creating export CSV. Please wait');
    exportNatsUsers(filters)
      .then(this.onExportSuccess)
      .catch(this.onExportFailure);
  };

  render() {
    const classes = ['NatsUsersViewHeader-mainWrapper'];
    if (this.state.filterToggled) {
      classes.push('FiltersToggled');
    }
    return (
      <div className="NatsUsersViewHeader_">
        <div className="NatsUsersViewHeader-inner">
          {this.renderSearchToggle()}
          <div className={classes.join(' ')}>
            {this.renderFilters('email', 'searchByUserEmail')}
            {this.renderFilters('userName', 'searchByUserName')}
            {this.renderFilters('streaming_key', 'searchStreamingKey')}
            {this.renderFilters(filterParams.property_id, 'searchPropertyID')}
            {this.renderFilters(filterParams.display, 'display')}
            {this.renderFilters(filterParams.user_type, 'searchUserType')}
            {this.renderClearFilterBtn()}
            {this.renderExportButton()}
          </div>
        </div>
        <BaseHorizontalNavigation items={this.getNavigationItems()} />
      </div>
    );
  }
}

NatsUsersViewHeader.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default withTranslation()(NatsUsersViewHeader);
