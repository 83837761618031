import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Card, Icon} from "antd";
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';

import ImageComponent from "../../ImageComponent/ImageComponent";
import ImagesLightBox from "../../ImagesLighbox/ImagesLighbox";
import ModalDialog from "../ModalDialog/ModalDialog";

import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import {ModalController} from "../../../controllers/modal-controller/modal.controller";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../services/notification-service/notification.service";

import './DeleteImagesModal.scss';

class DeleteImagesModal extends Component {

  images = [];

  imagesIds = [];

  modalRef = React.createRef();

  state = {
    fullScreen: false,
    images: [],
    photoIndex: 0
  };

  constructor(props) {
    super(props);
    this.state.images = this.props.images;
  }

  clearImageCheckedStatus = (image) => {
    const item = cloneDeep(image);
    item.checked = false;
    return item;
  };

  deleteImages = () => {
    const message = this.props.t('DeleteImagesModal.deletingImagesMsg');
    this.getModal().setLoading(message);
    const promises = this.imagesIds.map(this.props.deleteImage);
    Promise.all(promises)
      .then(this.onImageDeleteSuccess)
      .catch(this.onImageDeleteFailure)
  };

  getActions = () => {
    const {t} = this.props;
    const selectionExists = !!this.imagesIds.length;
    return (
      <div className="DeleteImagesModal-actions">
        <Button onClick={this.onClearSelection.bind(this)}
                disabled={!selectionExists}>
          {t('DeleteImagesModal.clearSelection')}
        </Button>
        <div>
          <Button onClick={this.onCancel.bind(this)}
                  className="DeleteImagesModal-cancel">
            {t('DeleteImagesModal.cancel')}
          </Button>
          <Button onClick={this.showDeleteConfirmationModal.bind(this)}
                  disabled={!selectionExists}>
            {t('DeleteImagesModal.deleteImages')}
          </Button>
        </div>
      </div>
    );
  };

  getCover = (url, index) => {
    return (
      <ImageComponent url={url}
                      onClick={this.showFullScreen.bind(this, index)}/>
    );
  };

  getFileMark = (checked, index) => {
    let mark = null;
    if (checked) {
      mark = (
        <div className="DeleteImagesModal-file-mark"
             onClick={this.showFullScreen.bind(this, index)}>
          <div className="DeleteImagesModal-file-mark-background"/>
          <Icon type="check"/>
        </div>
      )
    }
    return mark;
  };

  getImageActions = (item, index) => {
    const text = this.props.t(item.checked ? 'DeleteImagesModal.clearSelection' : 'DeleteImagesModal.markForDeletion');
    return [(
      <Button onClick={this.toggleImageCheckedStatus.bind(this, index)}>
        {text}
      </Button>
    )];
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('DeleteImagesModal.deleteImages');
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onClearSelection = () => {
    this.imagesIds = [];
    this.setState(prevState => {
      const images = prevState.images.map(this.clearImageCheckedStatus);
      return {images};
    });
  };

  onImageDeleteFailure = () => {
    this.getModal().clearLoading();
    const {t} = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('DeleteImagesModal.imageDeletionFailureMsg')
    });
  };

  onImageDeleteSuccess = () => {
    this.getModal().clearLoading();
    const {t} = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t('DeleteImagesModal.imageDeletionSuccessMsg')
    });
    this.getModal().closeModal();
    this.props.onDeleteSuccess();
  };

  renderImage = (item, index) => {
    const {url} = item;
    if (this.images.indexOf(url) === -1) {
      this.images.push(url);
    }
    return (
      <Card className="DeleteImagesModal-file"
            key={url}
            cover={this.getCover(url, index)}
            actions={this.getImageActions(item, index)}>
        {this.getFileMark(item.checked, index)}
      </Card>
    );
  };

  renderImagesLightBox = () => {
    return this.state.fullScreen ? (
      <ImagesLightBox currentIndex={this.state.photoIndex}
                      images={this.images}
                      onClose={this.toggleFullScreen}/>
    ) : null;
  };

  showDeleteConfirmationModal = () => {
    const {t} = this.props;
    const modal = (
      <ConfirmationModal title={t('DeleteImagesModal.confirmDelete')}
                         message={t('DeleteImagesModal.deletionQuestion')}
                         yesBtnText={t('DeleteImagesModal.deleteImages')}
                         noBtnText={t('DeleteImagesModal.cancel')}
                         confirm={this.deleteImages.bind(this)}/>
    );
    ModalController.showModal(modal);
  };

  showFullScreen = (index) => {
    this.setState({
      fullScreen: true,
      photoIndex: index
    });
  };

  toggleFullScreen = () => {
    this.setState(prevState => {
      return {
        fullScreen: !prevState.fullScreen
      };
    });
  };

  toggleImageCheckedStatus = (index) => {
    this.setState(prevState => {
      const images = cloneDeep(prevState.images);
      const item = images[index];
      const newChecked = !item.checked;
      this.updateSelectedIds(item.id, newChecked);
      item.checked = newChecked;
      return {images};
    });
  };

  updateSelectedIds = (id, checked) => {
    if (checked) {
      this.imagesIds.push(id);
    } else {
      const index = this.imagesIds.indexOf(id);
      this.imagesIds.splice(index, 1);
    }
  };

  render() {
    const {t} = this.props;
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}>
        <div className="DeleteImagesModal">
          <div className="DeleteImagesModal-description">{t('DeleteImagesModal.selectFilesForDeletion')}</div>
          <div className="DeleteImagesModal-images">
            {this.state.images.map(this.renderImage)}
            {this.renderImagesLightBox()}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

DeleteImagesModal.propTypes = {
  deleteImage: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired
};

export default withTranslation()(DeleteImagesModal);
