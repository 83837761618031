import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AutoComplete, Button, Checkbox, Table } from 'antd';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import BaseDelayedInput from "../../../../../../../../../../../components/BaseDelayedInput/BaseDelayedInput";
import BaseCheckboxList from "../../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList";
import MovieDetailsEnableDropdown from "./MovieDetailsEnableDropdown/MovieDetailsEnableDropdown";
import BaseDatePickerList from '../../../../../../../../../../../components/BaseDatePickerList/BaseDatePickerList';

import {
  renderListClickableItem,
  renderListClickableItems,
  renderListItems
} from '../../../../../../../../../../../services/util-service/util.service';
import { getLicensorDetailsRoute } from '../../../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation-routes.service';
import { showLicensorDetails } from '../../../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation.service';
import { getManufacturerDetailsRoute } from '../../../../../../../../../../../services/navigation/manufacturers-navigation/manufacturers-navigation-routes.service';
import { showManufacturerDetails } from '../../../../../../../../../../../services/navigation/manufacturers-navigation/manufacturers-navigation.service';
import { getStarDetailsRoute } from '../../../../../../../../../../../services/navigation/stars-navigation/stars-navigation-routes.service';
import { showStarDetails } from '../../../../../../../../../../../services/navigation/stars-navigation/stars-navigation.service';
import { getStudioDetailsRoute } from '../../../../../../../../../../../services/navigation/studios-navigation/studios-navigation-routes.service';
import { showStudioDetails } from '../../../../../../../../../../../services/navigation/studios-navigation/studios-navigation.service';
import { convertMomentToDate, momentFormats } from '../../../../../../../../../../../services/date-service/date.service';
import { showThemeMovies } from '../../../../../../../../../../../services/navigation/categories-navigation/categories-navigation.service';
import { getThemeMoviesRoute } from '../../../../../../../../../../../services/navigation/categories-navigation/categories-navigation-routes.service';
import { showPropertyDetails } from "../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation.service";
import { getPropertyDetailsRoute } from "../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";

import { searchCustomPreview } from "../../../../../../../../../../../services/video-service/video.service";

import './MovieDetailsInfosTabTable.scss';

class MovieDetailsInfosTabTable extends Component {

  editableProperties = [
    'enable', 'titleNs', 'descriptionNs', 'exclusive', 'excluded', 'movie2257s', 'customPreview',
    'publishStart', 'publishEnd', 'ns_original_spotlight', 'downloadable_on_properties'
  ];

  filterDelay = 300;

  state = {
    editMode: false,
    options: []
  };

  timeoutId;

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.editMode !== state.editMode) {
      const { data } = props;
      const obj = {};
      let item = data.length;
      const dates = ['publishDate', 'releaseDate']
      let currentItem;
      while (item) {
        item--;
        currentItem = data[item];
        if (['exclusive', 'excluded', 'downloadable_on_properties'].indexOf(currentItem.key) !== -1) {
          obj[currentItem.key] = currentItem.value?.map(({ id }) => id);
        } else {
          obj[currentItem.key] = dates.indexOf(currentItem.key) !== -1 ? convertMomentToDate(currentItem.value) : currentItem.value;
        }
      }
      newState = {
        editMode: props.editMode,
        ...obj
      };
    }

    return newState;
  }

  getId = ({ id }) => id;

  isEditable = (key) => {
    return this.editableProperties.indexOf(key) !== -1;
  };

  onCustodianInfoChange = (key, value) => {
    this.setState(prevState => {
      let { movie2257s } = prevState;
      if (!movie2257s) {
        movie2257s = {};
      }
      return {
        movie2257s: Object.assign({}, movie2257s, {
          [key]: value
        })
      };
    }, this.onDataChange);
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value
    }, this.onDataChange);
  };

  onDateTimeChange = ({ key, id, dateTime, applyToAll }) => {
    const state = this.state[key] ?? [];

    const updatedState = state?.map(item => {
      if (item.id === id) {
        item.value = dateTime;
      } else if (applyToAll) {
        item.value = dateTime;
      }

      return item;
    });

    this.setState({ [key]: updatedState }, this.onDataChange);
  };

  onCustomPreviewChange = value => {
    this.setState({
      customPreview: value
    }, this.onDataChange);
  };

  onCustomPreviewSearch = (searchText) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.searchItems.bind(this, searchText), this.filterDelay);
  };

  onCheckboxChange = (key) => {
    this.setState(prevState => {
      const value = prevState[key] ? 0 : 1;
      return { [key]: value }
    }, this.onDataChange)
  };

  renderBaseCheckboxList = ({ key }) => {
    const checked = this.state[key]?.map(item => item);
    const { properties = [] } = this.props;

    return (
      <BaseCheckboxList
        onChange={this.onChange.bind(this, key)}
        showSelectAll={true}
        checked={checked || []}
        items={properties}
      />
    );
  };

  renderCustodianOfRecordsEdit = (data) => {
    const { address, city, custodianOfRecords, name, postalCode, state } = data.value;
    const { t } = this.props;

    return (
      <div className="CustodianOfRecordsDisplay">
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.name'), this.renderInputField('name', name, false, this.onCustodianInfoChange))}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.custodianOfRecords'), this.renderInputField('custodianOfRecords', custodianOfRecords, false, this.onCustodianInfoChange))}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.address'), this.renderInputField('address', address, false, this.onCustodianInfoChange))}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.city'), this.renderInputField('city', city, false, this.onCustodianInfoChange))}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.state'), this.renderInputField('state', state, false, this.onCustodianInfoChange))}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.postalCode'), this.renderInputField('postalCode', postalCode, false, this.onCustodianInfoChange))}
      </div>
    );
  };

  renderCustomPreviewField = () => {
    const { options, customPreview } = this.state;
    return (
      <AutoComplete
        autosize
        onSearch={this.onCustomPreviewSearch}
        dataSource={options}
        onChange={this.onCustomPreviewChange}
        value={customPreview}
      />
    );
  };

  renderEditDatePicker = (key) => {
    const props = {
      data: this.state[key],
      format: momentFormats.shortMonthDate,
      onChange: ({ id, dateTime, applyToAll }) => this.onDateTimeChange({
        key,
        id,
        dateTime: convertMomentToDate(dateTime, true, false),
        applyToAll
      }),
      showTime: true
    };

    const { editMovieRecurrents, hasRecurrences } = this.props;
    if (key === 'publishStart' && editMovieRecurrents) {
      props.recurrence = editMovieRecurrents;
      props.hasRecurrences = hasRecurrences;
    }

    return <BaseDatePickerList {...props} />;
  };

  renderEditField = (data) => {
    const { key } = data;
    let view = null;

    switch (key) {
      case 'ns_original_spotlight':
        view = this.renderCheckboxEdit(data);
        break;
      case 'downloadable_on_properties':
        view = this.renderBaseCheckboxList(data);
        break;
      case 'enable':
        view = this.renderEnableDisableDropdown(data);
        break;
      case 'descriptionNs':
      case 'titleNs':
        view = this.renderInputField(key, this.state[key], false, this.onChange);
        break;
      case 'customPreview':
        view = this.renderCustomPreviewField();
        break;
      case 'exclusive':
      case 'excluded':
        view = this.renderBaseCheckboxList(data);
        break;
      case 'movie2257s':
        view = this.renderCustodianOfRecordsEdit(data);
        break;
      case 'publishStart':
      case 'publishEnd':
        view = this.renderEditDatePicker(key);
        break;
      default:
        break;
    }

    return view;
  };

  renderEnabledStatus = (value) => {
    const { t } = this.props;
    const key = value ? 'enabled' : 'disabled';
    const classes = ['EnabledDisabledTag'];
    if (value) {
      classes.push('Enabled');
    }
    return (
      <div className={classes.join(' ')}>
        {t(`MovieDetailsInfosTabTable.${key}`)}
      </div>
    );
  };

  renderInputField = (key, value, autoFocus, onChange) => {
    return (
      <BaseDelayedInput onChange={onChange.bind(this, key)}
        initialValue={value}
        autoFocus={autoFocus} />
    );
  };

  renderCheckbox = (value) => <Checkbox checked={!!value} disabled={true} />;

  renderCheckboxEdit = ({ key }) => {
    const value = this.state[key];
    return (
      <Checkbox onClick={this.onCheckboxChange.bind(this, key)} checked={value} />
    );
  };

  renderValueColumn = (item = [], data = {}) => {
    let view = null;
    const { editMode } = this.state;

    if (editMode && this.isEditable(data.key)) {
      view = this.renderEditField(data);
    } else if (item !== undefined) {
      switch (data.key) {
        case 'ns_original_spotlight':
          view = this.renderCheckbox(item);
          break;
        case 'publishStatus':
          view = this.renderOnlineOfflineStatus(item);
          break;
        case 'enable':
          view = this.renderEnabledStatus(item);
          break;
        case 'studios':
          view = renderListClickableItems(item, showStudioDetails, 'studio', getStudioDetailsRoute);
          break;
        case 'manufacturer':
          view = renderListClickableItem(item, showManufacturerDetails, 'manufacturer', getManufacturerDetailsRoute);
          break;
        case 'licensor':
          view = renderListClickableItem(item, showLicensorDetails, 'licensor', getLicensorDetailsRoute);
          break;
        case 'stars':
          view = renderListClickableItems(item, showStarDetails, 'star', getStarDetailsRoute);
          break;
        case 'orientation':
          view = renderListItems(this.mapOrientationForRender(item), 'name');
          break;
        case 'directors':
          view = renderListItems(item, 'directors');
          break;
        case 'categories':
          view = renderListClickableItems(this.mapValueForRender(item, 'categories'), showThemeMovies, 'categories', getThemeMoviesRoute);
          break;
        case 'releaseDate':
        case 'createdDate':
        case 'publishedDate':
        case 'publishStart':
        case 'publishEnd':
        case 'importedOn':
        case 'lastSyncedOn':
          if (data.key === 'publishStart' || data.key === 'publishEnd') {
            // Get min publish_start and max publish_end value
            const dates = item
              .map(data => convertMomentToDate(data.value, true, false))
              .filter(Boolean) ?? [];

            if (data.key === 'publishStart') {
              const maxStartDate = dates.length !== 1 ? dates.reduce((a, b) => a < b ? a : b, 0) : dates[0];
              item = maxStartDate;
            } else if (data.key === 'publishEnd') {
              const minEndDate = dates.length !== 1 ? dates.reduce((a, b) => a < b ? a : b, 0) : dates[0];
              item = minEndDate;
            }
          }
          view = this.renderDate(item);
          break;
        case 'movie2257s':
          view = this.renderCustodianOfRecordsDisplay(item);
          break;
        case 'excluded':
        case 'exclusive':
        case 'downloadable_on_properties':
          view = renderListClickableItems(item, showPropertyDetails, data.key, getPropertyDetailsRoute);
          break;
        default:
          view = item;
          break;
      }
    }

    return view;
  };

  columns = [
    {
      dataIndex: 'name',
      key: 'name',
      width: 300
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: this.renderValueColumn,
      width: '100%'
    }
  ];

  getOnlineStatusText = online => {
    const { t } = this.props;
    return t(online ? 'MovieDetailsInfosTabTable.online' : 'MovieDetailsInfosTabTable.offline');
  };

  getTableData = () => {
    return this.props.data.map(this.tableDataMap);
  };

  isOnlineStatus = publishStatus => {
    return publishStatus === "Online";
  };

  mapOrientationForRender = (orientations) => {
    return orientations.map(this.orientationDisplayMap);
  };

  mapValueForRender = (items, key) => {
    return items.map(this.valueRenderMap.bind(this, key));
  };

  onDataChange = () => {
    const data = cloneDeep(this.state);
    delete data.editMode;
    this.props.onDataChange(data);
  };

  onEnableChange = (value) => {
    this.setState({
      enable: value
    }, this.onDataChange);
  };

  orientationDisplayMap = (orientation) => {
    let name = orientation.name;
    const key = `MovieDetailsInfosTabTable.orientations.${name}`;
    const locale = this.props.t(key);
    if (locale !== key) {
      name = locale;
    }
    return { name };
  };

  renderDisplayField = (label, value) => {
    return (
      <div className="DisplayField">
        <div className="Label">{label}:</div>
        {value}
      </div>
    );
  };

  renderCustodianOfRecordsDisplay = ({ address, city, custodianOfRecords, name, postalCode, state }) => {
    const { t } = this.props;

    return (
      <div className="CustodianOfRecordsDisplay">
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.name'), name)}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.custodianOfRecords'), custodianOfRecords)}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.address'), address)}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.city'), city)}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.state'), state)}
        {this.renderDisplayField(t('MovieDetailsInfosTabTable.postalCode'), postalCode)}
      </div>
    );
  };

  renderDate = (date) => {
    let view = '';
    if (date) {
      const format = momentFormats.dateHours;
      view = moment(date).isValid() ? moment(date).format(format) : null;
    }
    return view;
  };

  renderEnableDisableDropdown = ({ value }) => {
    return (
      <MovieDetailsEnableDropdown menuClassName="MovieDetailsInfosTabTable-enabledDisabled"
        onChange={this.onEnableChange}
        value={value} />
    );
  };

  renderOnlineOfflineStatus = (item) => {
    const online = this.isOnlineStatus(item);
    return (
      <div className="StatusDropdownWrapper">
        <div>
          <Button className={online ? 'MovieOnline' : 'MovieOffline'}>
            {this.getOnlineStatusText(online)}
          </Button>
        </div>
      </div>
    );
  };

  searchItems = (searchText) => {
    searchCustomPreview(searchText)
      .then(this.setCustomPreviewOptions);
  };

  setCustomPreviewOptions = (response) => {
    const { data } = response.data;
    this.setState({
      options: data
    });
  };

  tableDataMap = item => {
    const { t } = this.props;
    return {
      name: t(`MovieDetailsInfosTabTable.${item.name}`),
      value: item.value,
      key: item.name,
      publishedDate: item.publishedDate
    };
  };

  valueRenderMap = (key, { tag }) => {
    const { t } = this.props;
    const localeKey = `global.${key}.${tag.name}`;
    let name = t(`global.${key}.${tag.name}`);
    if (name === localeKey) {
      name = tag.name;
    }
    return {
      id: tag.id,
      name: name
    };
  };

  componentDidUpdate(_, prevState) {
    if (this.state.editMode && this.state.editMode !== prevState.editMode) {
      this.onDataChange();
    }
  }

  render() {
    return (
      <Table
        className='MovieDetailsInfosTabTable'
        dataSource={this.getTableData()}
        columns={this.columns}
        showHeader={false}
        pagination={false}
        rowKey='key'
        bordered />
    );
  }
}

MovieDetailsInfosTabTable.propTypes = {
  data: PropTypes.array.isRequired,
  onDataChange: PropTypes.func.isRequired,
  properties: PropTypes.array,
  hasRecurrences: PropTypes.array,
  editMovieRecurrents: PropTypes.func,
};

export default withTranslation()(MovieDetailsInfosTabTable);
