import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
// import PropTypes from 'prop-types';
// import { Button, Input, Select, Icon } from "antd";

import {
  getUsersRoute,
  getNatsUsersRoute,
  getRoyaltiesUsersRoute
} from "../../../../../../../../../../services/navigation/users-navigation/users-navigation-routes.service";
import {
  showUsers,
  showNatsUsers,
  showRoyaltiesUsers
} from "../../../../../../../../../../services/navigation/users-navigation/users-navigation.service";

import BaseHorizontalNavigation
  from "../../../../../../../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";

import './RoyaltiesUsersViewHeader.scss';

class RoyaltiesUsersViewHeader extends Component {

  getNavigationItems = () => {
    const { t } = this.props;
    return [{
      link: getUsersRoute(),
      name: t('UsersViewHeader.usersTab'),
      navigate: showUsers
    }, {
      link: getNatsUsersRoute(),
      name: t('UsersViewHeader.natsUsersTab'),
      navigate: showNatsUsers
    }, {
      link: getRoyaltiesUsersRoute(),
      name: t('UsersViewHeader.royalityUsersTab'),
      navigate: showRoyaltiesUsers
    }];
  };




  onChange = (event) => {
    const email = event.target.value;
    this.setState({
      email: email
    });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(this.callPropsFilterChange, this.emailFilterDebounce);
  };

  onDisplayChange = (display) => {
    this.setState({
      display: display
    }, this.callPropsFilterChange);
  };



  // renderDisplayFilter = () => {
  //   const { t } = this.props;
  //   return (
  //     <div className="RoyaltiesUsersViewHeader-filterWrapper">
  //       <div className="RoyaltiesUsersViewHeader-filterLabel">
  //         {t('RoyaltiesUsersViewHeader.display')}:
  //       </div>
  //       <Select showArrow
  //         className="RoyaltiesUsersViewHeader-select"
  //         onChange={this.onDisplayChange}
  //         value={this.state.display}>
  //         {this.displayFilters.map(this.renderFilterItem)}
  //       </Select>
  //     </div>
  //   );
  // };

  // renderFilterItem = (item) => {
  //   return (
  //     <Select.Option key={item.name} value={item.value}>
  //       <div className="RoyaltiesUsersViewHeaderFilterItemWrapper">
  //         {item.name}
  //       </div>
  //     </Select.Option>
  //   );
  // };

  // renderSearchToggle = () => {
  //   const { t } = this.props;
  //   return (
  //     <div className="RoyaltiesUsersViewHeader-inner">
  //       {t('RoyaltiesUsersViewHeader.filters')}:
  //       <Icon type={this.state.filterToggled ? 'up' : 'down'}
  //         onClick={this.toggleFilters} />
  //     </div>
  //   );
  // };

  // renderEmailFilter = () => {
  //   const { t } = this.props;
  //   return (
  //     <div className="RoyaltiesUsersViewHeader-filterWrapper">
  //       <div className="RoyaltiesUsersViewHeader-filterLabel">
  //         {t('RoyaltiesUsersViewHeader.searchByUserEmail')}:
  //       </div>
  //       <Input value={this.state.email}
  //         onChange={this.onChange} />
  //     </div>
  //   );
  // };

  // toggleFilters = () => {
  //   this.setState(prevState => {
  //     return {
  //       filterToggled: !prevState.filterToggled
  //     };
  //   });
  // };

  render() {
    // const classes = ['RoyaltiesUsersViewHeader-mainWrapper'];

    return (
      <div className="RoyaltiesUsersViewHeader">
        <div className="RoyaltiesUsersViewHeader-inner">
          {/* {this.renderSearchToggle()} */}
          {/* <div className={classes.join(' ')}>
            {this.renderEmailFilter()}
            {this.renderDisplayFilter()}
            {this.renderClearFilterBtn()}
          </div> */}
        </div>
        <BaseHorizontalNavigation items={this.getNavigationItems()} />
      </div>
    );
  }
}

// RoyaltiesUsersViewHeader.propTypes = {
//   onChange: PropTypes.func.isRequired
// };

export default withTranslation()(RoyaltiesUsersViewHeader);
