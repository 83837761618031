import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Input } from 'antd';

import {createSubCategory} from '../../../../../../../../../../services/categories-service/subcategories-service/subcategories.service';
import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../services/notification-service/notification.service';

import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import './AddSubCategoryModal.scss';

class AddSubCategoryModal extends Component {

  modalRef = React.createRef();

  state = {
    name: ''
  };

  getActions = () => {
    const { t } = this.props;
    return [
      <Button key='cancelBtn' onClick={this.onCancel.bind(this)}>
        {t('AddSubCategoryModal.cancel')}
      </Button>,
      <Button
        key='saveChangesBtn'
        disabled={!this.state.name}
        onClick={this.onAddSubCategory.bind(this)}
      >
        {t('AddSubCategoryModal.addSubCategory')}
      </Button>
    ];
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getModalTitle = () => {
    return this.props.t('AddSubCategoryModal.addSubCategory');
  };

  onAddSubCategory = () => {
    const {name} = this.state;
    const {categoryId} = this.props;

    const message = this.props.t('AddSubCategoryModal.addingSubCategory');
    this.getModal().setLoading(message);

    const data = {
      categories_id: categoryId,
      name: name
    };
    createSubCategory(data)
      .then(this.onAddSubCategorySuccess)
      .catch(this.onAddSubCategoryFailure)
  };

  onAddSubCategoryFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddSubCategoryModal.addSubCategoryFailure')
    });
  };

  onAddSubCategorySuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddSubCategoryModal.addSubCategorySuccess')
    });
    this.props.fetchData();
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChangeName = event => {
    this.setState({
      name: event.target.value
    });
  };

  render() {
    const {t} = this.props;

    return (
      <ModalDialog
        title={this.getModalTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='AddSubCategoryModal'>
          <div className='AddSubCategoryModal-inner'>
            <span className='AddSubCategoryModal-inner-label'>
              {t('AddSubCategoryModal.name')}:
            </span>
            <Input
              onChange={this.onChangeName}
              value={this.state.name}
            />
          </div>
        </div>
      </ModalDialog>
    )
  }
};

export default withTranslation()(AddSubCategoryModal);