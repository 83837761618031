import {withTranslation} from "react-i18next";

import SpecialCategoriesSelectAbstract from '../SpecialCategoriesSelectAbstract/SpecialCategoriesSelectAbstract';

class SpecialCategoriesSelect extends SpecialCategoriesSelectAbstract {

  getLabel = () => {
    const {t} = this.props;
    return t('SpecialCategoriesSelect.label');
  };

  getTitle = () => {
    const {t} = this.props;
    return t('SpecialCategoriesSelect.title');
  };
}

export default withTranslation()(SpecialCategoriesSelect);