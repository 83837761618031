import React from 'react';
import { withTranslation } from "react-i18next";
import { List } from "antd";
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../../components/BaseFooter/BaseFooter";
import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";
import PayoutsDisplayBaseAbstract from "../../PayoutsDisplayBaseAbstract/PayotsDisplayBaseAbstract";

import { getPayoutsLicensorRoute } from "../../../../../../../../../../services/navigation/payouts-navigation/payouts-navigation-routes.service";

import './PayoutsByLicensorMainView.scss';

class PayoutsByLicensorMainView extends PayoutsDisplayBaseAbstract {

  getColumns = () => {
    const { t } = this.props;
    return [{
      dataIndex: ['licensor', 'name'],
      title: t('PayoutsByLicensorMainView.licensor')
    }, {
      dataIndex: 'gross',
      title: t('PayoutsByLicensorMainView.gross'),
      render: this.renderNumber
    }, {
      dataIndex: 'marketing_fee',
      title: t('PayoutsByLicensorMainView.marketing'),
      render: this.renderPercentage
    }, {
      dataIndex: 'processing_fee',
      title: t('PayoutsByLicensorMainView.processing'),
      render: this.renderPercentage
    }, {
      dataIndex: 'split',
      title: t('PayoutsByLicensorMainView.split'),
      render: this.renderPercentage
    }, {
      dataIndex: 'payout',
      title: t('PayoutsByLicensorMainView.payout'),
      render: this.renderNumber
    }, {
      title: t('PayoutsByLicensorMainView.action'),
      align: 'center',
      key: 'action',
      render: this.renderActionItem
    }];
  };

  getListCardFooter = (item) => {
    return (
      <div className="PayoutsByLicensor-listCard-footer">
        {this.renderActionItem(item)}
      </div>
    );
  };

  getReductionHeight = () => 55 + 42 + 54;

  getRowKey = (item) => item.licensor.id ?? '';

  renderActionItem = item => {
    const { licensor } = item;
    const { t, year, month, propertyId } = this.props;
    return (
      <LinkButton href={getPayoutsLicensorRoute(propertyId, licensor.id, year, month)}>
        {t('PayoutsByLicensorMainView.viewPayoutsByMovie')}
      </LinkButton>
    );
  };

  renderFooter = () => {
    const { data, onPageChange } = this.props;
    const { page, pageCount, perPage, recordCount } = data;
    const props = {
      className: 'PayoutPropertyDetailsFooter',
      currentPage: page,
      onChange: onPageChange,
      pageCount,
      pageSize: perPage,
      recordCount
    };
    return (
      <BaseFooter {...props} />
    );
  };

  renderPercentage = item => {
    const percentage = item * 100 + '%';
    return percentage;
  };

  renderPropertyListItem = item => {
    const { licensor, gross, marketing_fee, processing_fee, split, payout } = item;
    const { t } = this.props;
    const { id, name } = licensor;
    return (
      <List.Item key={id}>
        <BaseCard title={`${t('PayoutsByLicensorMainView.title', { name: name })}`}
          footer={this.getListCardFooter(item)}>
          <div className='ListCardWrapper'>
            {this.renderListItemField(t('PayoutsByLicensorMainView.licensor'), name)}
            {this.renderListItemField(t('PayoutsByLicensorMainView.gross'), this.renderNumber(gross))}
            {this.renderListItemField(t('PayoutsByLicensorMainView.marketing'), this.renderPercentage(marketing_fee))}
            {this.renderListItemField(t('PayoutsByLicensorMainView.processing'), this.renderPercentage(processing_fee))}
            {this.renderListItemField(t('PayoutsByLicensorMainView.split'), this.renderPercentage(split))}
            {this.renderListItemField(t('PayoutsByLicensorMainView.payout'), payout)}
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  render() {
    const { licensors } = this.props.data;
    return (
      <div className="PayoutsByLicensorMainView">
        <div className='PayoutsByLicensorBody'>
          {this.renderTable(licensors)}
          {this.renderList(licensors, this.renderPropertyListItem)}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

PayoutsByLicensorMainView.propTypes = {
  contentHeight: PropTypes.number,
  data: PropTypes.object.isRequired,
  month: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  propertyId: PropTypes.string,
  year: PropTypes.string
};

export default withTranslation()(PayoutsByLicensorMainView);
