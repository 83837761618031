import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { getRoyaltiesUserById } from "../../../../../../../../../../services/royalties-service/royalties.service";
import { displayErrorNotification, displaySuccessNotification } from "../../../../../../../../../../services/notification-service/notification.service";
import { DocumentTitleController } from "../../../../../../../../../../controllers/title-controller/title.controller";
import LoadingWrapper from "../../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import BaseNavigationItem from "../../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import {
  getRoyaltiesUsersRoute
} from "../../../../../../../../../../services/navigation/users-navigation/users-navigation-routes.service";

// import TabsComponent from "../../../../../../../../../../components/TabsComponent/TabsComponent";

import { toggleRoyaltiesUserEnabled } from "../../../../../../../../../../services/users-service/users.service";
import { getRoyaltiesUsersPage } from "../../../../../../../../../../services/royalties-service/royalties.service";

import RoyaltiesUserInfo from "./RoyaltiesUserInfo/RoyaltiesUserInfo";

import './RoyaltiesUserDetails.scss';
import { Button, Icon } from 'antd';
import { ModalController } from '../../../../../../../../../../controllers/modal-controller/modal.controller';
import ChangePasswordModal from '../../UserDetails/ChangePasswordModal/ChangePasswordModal';

class RoyaltiesUserDetails extends Component {

  state = {
    // activeTab: '1',
    data: {},
    dataLoaded: false,
    loading: false
  };

  componentDidMount() {
    this.setLoading(true);
    getRoyaltiesUserById(this.props.userId)
      .then(this.onGetUserSuccess)
      .catch(this.onGetUserFailure);
  }

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  filterSubscriptions = data => {
    const { subscriptions } = data;
    const filteredSubs = subscriptions.filter(this.getTransactions);
    return {
      ...data,
      subscriptions: filteredSubs
    };
  };

  // getTabs = () => {
  //   const { t } = this.props;
  //   let membershipInfo = {};
  //   const { data } = this.state;
  //   if (data.subscriptions && data.subscriptions.length) {
  //     membershipInfo = {
  //       title: t('RoyaltiesUserDetails.membershipInfo'),
  //       view: (
  //         <NatsMembershipInfo
  //           data={this.state.data}
  //           contentHeight={this.props.contentHeight}
  //         />
  //       )
  //     };
  //   }

  //   return [{
  //     title: t('RoyaltiesUserDetails.userInfo'),
  //     view: (
  //       <RoyaltiesUserInfo
  //         data={this.state.data}
  //       />
  //     )
  //   }, membershipInfo];
  // };

  // getTransactions = subscription => {
  //   return subscription.transactions.length;
  // };

  onGetUserFailure = () => {
    this.setLoading(false);
    displayErrorNotification({
      duration: 3,
      message: 'Failed to get users'
    });
  };

  onGetUserSuccess = (response) => {
    const { data } = response.data;
    // const filteredData = this.filterSubscriptions(data);
    // this.setDocumentTitle(data);
    this.setState({
      data: data,
      dataLoaded: true,
      loading: false
    });
  };

  setUserEnabled = (enable, userId) => {
    // this.setState({
    //   loading: true
    // });

    toggleRoyaltiesUserEnabled(userId, enable)
      .then(getRoyaltiesUsersPage(1))
      .then(this.onSetUserStatusSuccess)
      .catch(this.onSetUserStatusFailure.bind(this, enable));
  }

  onSetUserStatusSuccess = (response) => {
    const user = response.data.data;
    this.updateUserData(user.id, user);
    const msg = user.enable === 1 ? 'Enabled' : 'Disabled';

    displaySuccessNotification({
      duration: 3,
      message: msg
    });
  };

  onSetUserStatusFailure = (enabled, error) => {
    this.setState({
      loading: false
    });

    const msg = enabled === 1 ? 'Enable Failed' : 'Disable Failed';
    displayErrorNotification({
      duration: 3,
      message: msg
    });
  };

  updateUserData = (id, user) => {
    this.setState({
      loading: false
    });

    this.setState({
      data: user
    });
  };

  renderBody = () => {
    return (
      <div className="RoyaltiesUserDetails-body">
        {/* <TabsComponent
          activeKey={this.state.activeTab}
          onChange={this.changeTab}
        >
          {this.getTabs()}
        </TabsComponent> */}
        <RoyaltiesUserInfo
          data={this.state.data}
          setUserEnabled={this.setUserEnabled}
        />
      </div>
    );
  };

  /**
  * Shows change password modal dialog
  */
  showChangePasswordModal = () => {
    const modal = (
      <ChangePasswordModal userId={this.props.userId} isRoyalty={true} />
    );
    ModalController.showModal(modal);
  };

  getActions = () => {
    const { t } = this.props;
    return [
      <Button onClick={this.showChangePasswordModal}
        key="changePasswordBtn">
        <Icon type="form" />
        {t('UserDetails.changePassword')}
      </Button>
    ];
  };

  renderHeader = () => {
    const { username = '/' } = this.state.data;
    const routes = [{
      url: getRoyaltiesUsersRoute(),
      text: 'user'
    }, {
      text: username
    }];

    return (
      <BaseNavigationItem routes={routes}>
        {this.getActions()}
      </BaseNavigationItem>
    );
  };

  setDocumentTitle = (data) => {
    const title = this.props.t('RoyaltiesUserDetails.documentTitle', { name: data.username });
    DocumentTitleController.setDocumentTitle(title);
  };

  setLoading = (loading) => {
    this.setState({
      loading: loading
    })
  };

  render() {
    const { dataLoaded, loading } = this.state;
    return (
      <LoadingWrapper className='RoyaltiesUserDetails'
        dataLoaded={dataLoaded}
        isLoading={loading}
        loadingMsg='Loading Royalties Users'>
        {this.renderHeader()}
        {this.renderBody()}
      </LoadingWrapper>
    )
  }
}

export default withTranslation()(RoyaltiesUserDetails);
