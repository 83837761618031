import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Card, Checkbox, Icon, Select } from "antd";
import PropTypes from 'prop-types';

import ImageComponent from "../../../ImageComponent/ImageComponent";

import './SingleFile.scss';

class SingleFile extends Component {

  onTypeChange = (value) => {
    const { index } = this.props;
    this.props.onTypeChange(index, value);
  };

  renderImageCategoryOption = (category, index) => {
    return (
      <Select.Option value={category}
        key={category}>
        {category}
      </Select.Option>
    );
  };

  renderCategorySelect = () => {
    const { imageCategories, t, type, hideFilesDropdown } = this.props;

    if (!hideFilesDropdown) {
      return (
        <Select onChange={this.onTypeChange}
          className="SingleFile-type-select"
          defaultValue={imageCategories[0]}
          value={type}
          placeholder={t('SingleFile.enterImageType')}>
          {imageCategories.map(this.renderImageCategoryOption)}
        </Select>
      );
    }

    return null;
  };

  renderSelectionCheckbox = () => {
    const {
      id, hideSelectionCheckbox, selected, updateSelectedFiles
    } = this.props;
    return !hideSelectionCheckbox ? (
      <Checkbox onChange={updateSelectedFiles.bind(this, id)}
        defaultChecked={selected} />
    ) : null;
  };

  render() {
    const {
      id, index, name, url,
      displayFullScreen, removeFile
    } = this.props;

    return (
      <Card className="SingleFile">
        <div className="SingleFile-actions">
          {name}
          <div>
            {this.renderSelectionCheckbox()}
            <Icon type="close"
              onClick={removeFile.bind(this, id)} />
          </div>
        </div>
        <div className="SingleFile-wrapper">
          {url && (
            <ImageComponent
              url={url}
              className="UploadImages-preview"
              onClick={displayFullScreen ? displayFullScreen.bind(this, index) : null} />
          )}
        </div>
        {this.renderCategorySelect()}
      </Card>
    );
  }
}

SingleFile.propTypes = {
  displayFullScreen: PropTypes.func,
  id: PropTypes.number.isRequired,
  imageCategories: PropTypes.array.isRequired,
  hideSelectionCheckbox: PropTypes.bool,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  type: PropTypes.string,
  updateSelectedFiles: PropTypes.func,
  url: PropTypes.string,
  hideFilesDropdown: PropTypes.bool
};

export default withTranslation()(SingleFile);
