import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Layout} from "antd";

import SideMenu from "./components/SideMenu/SideMenu";
import MainContent from "./components/MainContent/MainContent";

import {MainDashboardComController} from "../../main-dashboard-comm.controller";

import './MainView.scss';

class MainView extends Component {
  state = {
    contentHeight: undefined,
    sideMenuCollapsed: false
  };

  subscription;

  constructor(props) {
    super(props);
    this.subscription = MainDashboardComController.menuToggled.subscribe(this.setSideMenuCollapsed.bind(this));
    this.state.sideMenuCollapsed = MainDashboardComController.menuCollapsed;
  }

  componentDidMount() {
    const view = document.getElementsByClassName('MainDashboardMainView')[0];
    if (view) {
      this.setState({
        contentHeight: view.clientHeight
      });
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  setSideMenuCollapsed = (collapsed) => {
    this.setState({
      sideMenuCollapsed: collapsed
    });
  };

  render() {
    return (
      <Layout className="MainDashboardMainView">
        <SideMenu collapsed={this.state.sideMenuCollapsed}
                  navigationItems={this.props.navigationItems}/>
        <MainContent contentHeight={this.state.contentHeight}/>
      </Layout>
    );
  }
}

MainView.propTypes = {
  navigationItems: PropTypes.array.isRequired
};

export default MainView;

