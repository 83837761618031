import React, { Component } from 'react';
import { Router } from "@reach/router";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import { getManufacturers } from '../../../../../../../../services/manufacturers-service/manufacturers.service';

import { LogController } from '../../../../../../../../controllers/log-controller/log.controller';
import { ManufacturerDetailsCommController, ManufacturerDetailsEventMap } from "./manufacturer-details-comm.controller";
import { ModalController } from "../../../../../../../../controllers/modal-controller/modal.controller";
// import { MainDashboardComController } from '../../../../../../../../pages/MainDashboard/main-dashboard-comm.controller';

import ManufacturesMainView from "./ManufacturesMainView/ManufacturesMainView";
import ManufacturerDetails from "./ManufacturerDetails/ManufacturerDetails";
import EditManufacturerModal from "./ManufacturerDetails/EditManufacturerModal/EditManufacturerModal";

import './MFIDsView.scss';

const selectOptions = [
  { text: "Name", name: "name" },
  { text: "ID", name: "id" },
]

const defaultFilterValues = {
  search: '',
  sort: {
    ascending: true,
    name: selectOptions[0].name,
    text: selectOptions[0].text
  }
}

class MFIDsView extends Component {

  editManufacturerSubscription;

  // subscription;

  state = {
    currentPage: 0,
    dataLoaded: false,
    loading: false,
    manufacturers: [],
    pagesTotal: 0,
    pageSize: 0,
    totalManufacturers: 0,
    filters: { ...defaultFilterValues }
  };

  searchTimeout;

  constructor(props) {
    super(props);
    this.editManufacturerSubscription = ManufacturerDetailsCommController.subscribeToEvent(
      'manufacturer',
      ManufacturerDetailsEventMap.manufacturer.editFinishedEvent,
      this.triggerUpdateIfNeeded
    );
  }

  componentDidMount() {
    // this.subscription = MainDashboardComController.mainNavigationLinkClicked.subscribe(this.fetchManufacturers.bind(this));
    this.fetchManufacturers();
  }

  componentWillUnmount() {
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
    this.editManufacturerSubscription.unsubscribe();
  }

  fetchManufacturers = (page = 1) => {
    this.setState({
      loading: true
    });

    getManufacturers(page, this.state.filters)
      .then(this.loadManufacturersData)
      .catch(this.onGetManufacturersFailure);
  };

  getDocumentTitle = () => {
    return this.props.t('MFIDsView.documentTitle');
  };

  loadManufacturersData = (response) => {
    const pagination = response.data.data.pagination;
    this.setState({
      currentPage: pagination.current_page,
      dataLoaded: true,
      loading: false,
      manufacturers: response.data.data.manufacturers,
      pagesTotal: pagination.last_page,
      pageSize: pagination.per_page,
      totalManufacturers: pagination.total
    });
  };


  matchManufacturerById = (id, manufacturer) => {
    return manufacturer.id === id;
  };

  onGetManufacturersFailure = (error) => {
    LogController.logError(error);
  };

  showEditManufacturerDialog = (id) => {
    const manufacturer = this.state.manufacturers.find(this.matchManufacturerById.bind(this, id));
    const modal = (
      <EditManufacturerModal
        onManufacturerEdit={this.triggerUpdateIfNeeded.bind(this, id)}
        manufacturerId={id}
        manufacturerName={manufacturer.name}
        licensor={manufacturer.licensor} />
    );
    ModalController.showModal(modal);
  };

  triggerUpdateIfNeeded = (id) => {
    const manufacturer = this.state.manufacturers.find(this.matchManufacturerById.bind(this, id));
    if (manufacturer) {
      this.fetchManufacturers(this.state.currentPage);
    }
  };

  // filters
  onInputSearch = (event) => {
    const search = event.target.value;

    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        search
      }
    }));

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.fetchManufacturers();
    }, 800);
  }

  onDropdownChange = (sortFilter, ascending, text) => {
    const { filters: { search } } = this.state;
    this.setState({
      filters: {
        search,
        sort: {
          ascending: ascending,
          name: sortFilter,
          text
        },
      }
    }, () => this.fetchManufacturers());
  }

  onClearFilters = () => {
    this.setState({ filters: { ...defaultFilterValues } }, () => this.fetchManufacturers());
  }

  render() {
    return (
      <div className="MFIDsView">
        <Router>
          <ManufacturesMainView
            path={"/"}
            contentHeight={this.props.contentHeight}
            data={this.state}
            onMFIDEdit={this.showEditManufacturerDialog}
            onPageChange={this.fetchManufacturers}
            onInputSearch={this.onInputSearch}
            onDropdownChange={this.onDropdownChange}
            onClearFilters={this.onClearFilters}
            dropdownSort={this.state.filters.sort}
            searchValue={this.state.filters.search}
            selectOptions={selectOptions}
          />
          <ManufacturerDetails path={`/:id`} />
        </Router>
      </div>
    )
  }
}

export default compose(withTranslation(), withDocumentTitleUpdate)(MFIDsView);
