import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Form, Input, Select } from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import { getRoles } from "../../../../../../../../../services/roles-service/roles.service";
import { addUser } from "../../../../../../../../../services/users-service/users.service";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

import './AddUserModal.scss';

class AddUserModal extends Component {

  fields = ['username', 'email', 'password', 'repeatPassword', 'primaryRole'];

  isUnMounted = false;

  modalRef = React.createRef();

  state = {
    confirmDirty: false,
    isValid: false,
    roles: []
  };

  componentDidMount() {
    getRoles()
      .then(this.loadRolesData.bind(this));
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  addUser = () => {
    const message = this.props.t('AddUserModal.addingUserPleaseWait');
    this.getModal().setLoading(message);
    const { email, password, primaryRole, username } = this.props.form.getFieldsValue();
    const data = {
      email,
      name: username,
      password,
      primary_role: primaryRole
    };
    addUser(data)
      .then(this.onAddUserSuccess)
      .catch(this.onAddUserFailure)
  };

  clearUntouchedErrors = () => {
    this.fields.forEach(this.clearUntouchedFieldErrors);
  };

  clearUntouchedFieldErrors = (name) => {
    const { form } = this.props;
    if (!form.isFieldTouched(name) && (name !== 'primaryRole' || !form.getFieldValue(name))) {
      form.setFields({
        [name]: {
          value: '',
          errors: undefined
        }
      });
    }
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="AddUserModal-actions">
        <Button onClick={this.onCancel}
          key="cancelBtn">
          {t('AddUserModal.cancel')}
        </Button>
        <Button onClick={this.addUser}
          key="addUserBtn"
          disabled={!this.state.isValid}
          type="primary">
          {t('AddUserModal.addUser')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('AddUserModal.addUser');
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState(prevState => {
      return {
        confirmDirty: prevState.confirmDirty || !!value
      };
    });
  };

  isValid = (errors) => {
    this.setState({
      isValid: errors === null
    });
    this.clearUntouchedErrors();
  };

  loadRolesData = (response) => {
    const { data = [] } = response.data;
    const removeRegularUser = data.filter(item => item.name !== 'regular');

    if (!this.isUnMounted) {
      this.setState({
        roles: removeRegularUser
      });
    }
  };

  onAddUserFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddUserModal.addUserFailed')
    });
  };

  onAddUserSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddUserModal.addUserSuccess')
    });
    this.props.onUserAdded();
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onPrimaryRoleChange = (value) => {
    const { form } = this.props;
    form.setFieldsValue({
      primaryRole: value
    });
    this.onValuesChange();
  };

  onValuesChange = () => {
    this.props.form.validateFields(this.isValid.bind(this));
  };

  renderEmail = () => {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form.Item>
        {getFieldDecorator('email', {
          rules: [{
            type: 'email',
            message: t(`AddUserModal.validation.invalidEmail`)
          }, {
            required: true,
            message: t(`AddUserModal.validation.pleaseEnterEmail`),
            whitespace: true
          }],
        })(
          <div className="AddUserModal-field-wrapper">
            <span className="Label">{t('AddUserModal.email')}:</span>
            <Input autoComplete="new-email"
              name="email" />
          </div>
        )}
      </Form.Item>
    );
  };

  renderFilterItem = (item) => {
    const { t } = this.props;
    return (
      <Select.Option key={item.id} value={item.id}>
        <div className="AddUserModalFilterItemWrapper">
          {t(`AddUserModal.${item.name}`)}
        </div>
      </Select.Option>
    );
  };

  renderPasswordField = (key, messageKey) => {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form.Item>
        {getFieldDecorator(key, {
          rules: [{
            required: true,
            message: t(`AddUserModal.validation.${messageKey}`),
            whitespace: true
          }, {
            validator: key === 'password' ? this.validatePassword : this.validateConfirmPassword
          }],
        })(
          <div className="AddUserModal-field-wrapper">
            <span className="Label">{t(`AddUserModal.${key}`)}:</span>
            <Input.Password name={key} onBlur={key === 'password' ? this.handleConfirmBlur : null} />
          </div>
        )}
      </Form.Item>
    );
  };

  renderPrimaryRole = () => {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form.Item>
        {getFieldDecorator('primaryRole', {
          rules: [{
            required: true,
            message: t('AddUserModal.validation.pleaseSelectUserPrimaryRole')
          }],
        })(
          <div className="AddUserModal-field-wrapper">
            <span className="Label">{t('AddUserModal.primaryRole')}:</span>
            <Select showArrow
              className="AddUserModal-select"
              onChange={this.onPrimaryRoleChange}>
              {this.state.roles.map(this.renderFilterItem)}
            </Select>
          </div>
        )}
      </Form.Item>
    )
  };

  renderUsername = () => {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [{
            required: true,
            message: t('AddUserModal.validation.pleaseEnterUsername'),
            whitespace: true
          }],
        })(
          <div className="AddUserModal-field-wrapper">
            <span className="Label">{t('AddUserModal.username')}:</span>
            <Input autoComplete="new-username"
              name="username" />
          </div>
        )}
      </Form.Item>
    );
  };

  validateConfirmPassword = (rule, repeatPassword, callback) => {
    const { form, t } = this.props;
    const password = form.getFieldValue('password');
    if (repeatPassword && (repeatPassword !== password)) {
      callback(t('AddUserModal.validation.passwordMismatch'));
    } else {
      callback();
    }
  };

  validatePassword = (rule, password, callback) => {
    const { form } = this.props;
    if (password && this.state.confirmDirty) {
      form.validateFields(['repeatPassword'], { force: true });
    }
    callback();
  };

  render() {
    return (
      <ModalDialog title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className="AddUserModal">
          <Form onChange={this.onValuesChange}>
            {this.renderUsername()}
            {this.renderEmail()}
            {this.renderPasswordField('password', 'pleaseEnterPassword')}
            {this.renderPasswordField('repeatPassword', 'pleaseConfirmPassword')}
            {this.renderPrimaryRole()}
          </Form>
        </div>
      </ModalDialog>
    );
  }
}

AddUserModal.propTypes = {
  onUserAdded: PropTypes.func.isRequired
};

const addUserModalForm = Form.create({ name: 'addUser' })(AddUserModal);

export default withTranslation()(addUserModalForm);
