import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Icon, List, Menu, Table } from "antd";
import PropTypes from 'prop-types';

import { getUserDetailsRoute } from "../../../../../../../../../../services/navigation/users-navigation/users-navigation-routes.service";
import { getScrollHeight } from "../../../../../../../../../../services/util-service/util.service";
import { renderMomentWithHours } from "../../../../../../../../../../services/date-service/date.service";

import UsersViewFooter from "../UsersViewFooter/UsersViewFooter";

import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";
import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";

import './UsersViewBody.scss';

class UsersViewBody extends Component {

  tableRowHeight = 65;

  getCardTableData = (item) => {
    const { t, activeUsers } = this.props;
    return [{
      name: `${t('UsersViewBody.id')}:`,
      value: activeUsers ? item.memberid : item.id,
    }, {
      name: `${t('UsersViewBody.email')}:`,
      value: item.email
    }, {
      name: `${t('UsersViewBody.status')}:`,
      value: activeUsers ? this.renderNatsStatus(item.status, item) : this.renderEnableDisableDropdown(item.status, item)
    }, {
      name: `${t('UsersViewBody.property')}:`,
      value: activeUsers ? item.properties.name : item.property
    }, {
      name: `${t('UsersViewBody.createdDate')}:`,
      value: this.renderDate(activeUsers ? item.joined : item.createdAt)
    }];
  };

  getEnableDisableDropdownMenu = userId => {

    return (
      <Menu className="UsersViewBody-enabledDisabled">
        <Menu.Item>
          <div
            onClick={this.props.setUserEnabled.bind(this, 1, userId)}
            className="UsersViewBody-enabled"
          >
            Enabled
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={this.props.setUserEnabled.bind(this, 0, userId)}
            className="UsersViewBody-disabled"
          >
            Disabled
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  getStatusText = (item) => {
    const { t } = this.props;
    return t(this.isUserEnabled(item) ? 'UsersViewBody.enabled' : 'UsersViewBody.disabled');
  };

  isUserEnabled = (item) => {
    return item.enable || item.status;
  };

  renderActions = (item, data) => {
    const { t, activeUsers } = this.props;

    return activeUsers ? null : (
      <LinkButton href={getUserDetailsRoute(data.id)}>
        {t('UsersViewBody.viewDetails')}
      </LinkButton>
    );
  };

  renderDate = (date) => {
    let render = '';
    if (date) {
      render = renderMomentWithHours(date)
    }
    return render;
  };

  renderNatsStatus = (text, item) => {
    let value = 'N/A';
    switch (text) {
      case '0':
        value = 'NonMember';
        break;
      case '1':
        value = 'Active';
        break;
      case '2':
        value = 'Ended';
        break;
      case '3':
      default:
        break;
    }
    return value;
  };

  renderEnableDisableDropdown = (text, item) => {
    const userId = this.props.activeUsers ? item.memberid : item.id;
    return (
      <div className="StatusItem">
        <Dropdown overlay={this.getEnableDisableDropdownMenu(userId)}>
          <Button className={this.isUserEnabled(item) ? 'UserEnabled' : 'UserDisabled'}>
            {this.getStatusText(item)}
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
    );
  };

  columns = [{
    dataIndex: 'id',
    title: this.props.t('UsersViewBody.id'),
    width: 300
  }, {
    dataIndex: 'name',
    title: this.props.t('UsersViewBody.loginUsername'),
    width: 280
  }, {
    dataIndex: 'email',
    title: this.props.t('UsersViewBody.email'),
    width: 300
  }, {
    align: 'center',
    dataIndex: 'status',
    title: this.props.t('UsersViewBody.status'),
    render: this.renderEnableDisableDropdown,
    width: 200
  }, {
    dataIndex: 'property',
    title: this.props.t('UsersViewBody.property'),
    width: 200
  }, {
    dataIndex: 'createdAt',
    title: this.props.t('UsersViewBody.createdDate'),
    render: this.renderDate,
    width: 300
  }, {
    align: 'center',
    dataIndex: 'actions',
    title: this.props.t('UsersViewBody.actions'),
    render: this.renderActions,
    width: 200
  }];

  natsColumns = [{
    dataIndex: 'memberid',
    title: this.props.t('UsersViewBody.id'),
    width: 100
  }, {
    dataIndex: 'username',
    title: this.props.t('UsersViewBody.loginUsername'),
    width: 340
  }, {
    dataIndex: 'email',
    title: this.props.t('UsersViewBody.email'),
    width: 300
  }, {
    align: 'center',
    dataIndex: 'status',
    title: this.props.t('UsersViewBody.status'),
    render: this.renderNatsStatus,
    width: 200
  }, {
    dataIndex: 'properties.name',
    title: this.props.t('UsersViewBody.property'),
    width: 200
  }, {
    dataIndex: 'joined',
    title: this.props.t('UsersViewBody.createdDate'),
    render: this.renderDate,
    width: 300
  }];

  calculateTableScroll = () => {
    const { contentHeight, users } = this.props;
    const reductionHeight = 52 + 54 + 52;// Remove 52 for header and also footer height
    return getScrollHeight(contentHeight, users, this.tableRowHeight, reductionHeight);
  };

  renderFooter = () => {
    const { listInfo, onPageChange, onAddUser, activeUsers, onExport } = this.props;
    const { currentPage, pageCount, pageSize, recordCount } = listInfo;
    return (
      <UsersViewFooter
        onExport={onExport}
        activeUsers={activeUsers}
        currentPage={currentPage}
        pageSize={pageSize}
        recordCount={recordCount}
        onChange={onPageChange}
        onAddUser={onAddUser}
        pageCount={pageCount}
      />
    );
  };

  renderListItem = (item) => {
    const { t, activeUsers } = this.props;
    const columns = [{
      dataIndex: 'name',
      key: 'name'
    }, {
      align: 'center',
      dataIndex: 'value',
      key: 'value'
    }];
    return (
      <BaseCard
        key={activeUsers ? item.memberid : item.id}
        title={`${t('UsersViewBody.user')}: ${activeUsers ? item.username : item.name}`}
        footer={this.renderActions(item, item)}
      >
        <Table
          dataSource={this.getCardTableData(item)}
          rowKey={activeUsers ? 'username' : 'name'}
          showHeader={false}
          pagination={false}
          columns={columns}
          bordered
        />
      </BaseCard>
    );
  };

  renderUsersList = () => {
    return (
      <List
        className="ListView"
        dataSource={this.props.users}
        renderItem={this.renderListItem}
        split={false}
        itemLayout="vertical"
      />
    );
  };

  renderUsersTable = () => {
    return (
      <Table
        className="UsersTable"
        dataSource={this.props.users}
        rowKey={this.props.activeUsers ? 'memberid' : "id"}
        bordered
        columns={this.props.activeUsers ? this.natsColumns : this.columns}
        pagination={false}
        scroll={this.calculateTableScroll()}
      />
    );
  };

  render() {
    return (
      <div>
        <div className="UsersViewBody">
          {this.renderUsersTable()}
          {this.renderUsersList()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

UsersViewBody.propTypes = {
  contentHeight: PropTypes.number,
  users: PropTypes.array.isRequired,
  onExport: PropTypes.func
};

export default withTranslation()(UsersViewBody);
