import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Input} from "antd";
import PropTypes from 'prop-types';

import './BaseImagesDisplaySearch.scss';

class BaseImagesDisplaySearch extends Component {

  changeNotifierDelay = 300;

  state = {
    value: ''
  };

  timeoutId;

  constructor(props) {
    super(props);
    this.state.value = this.props.initialValue;
  }

  notifyChange = () => {
    this.props.onChange(this.state.value);
  };

  onChange = (event) => {
    const {value} = event.target;
    this.setState({value});
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.notifyChange, this.changeNotifierDelay);
  };

  render() {
    const {t} = this.props;
    return (
      <div className="BaseImagesDisplaySearch">
        <div>{t('BaseImagesDisplaySearch.searchByType')}</div>
        <Input value={this.state.value}
               onChange={this.onChange}
               allowClear/>
      </div>
    );
  }
}

BaseImagesDisplaySearch.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default withTranslation()(BaseImagesDisplaySearch);