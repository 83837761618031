import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Input } from 'antd';

import LicensorsViewBody from './LicensorsViewBody/LicensorsViewBody';
import LicensorsViewFooter from './LicensorsViewFooter/LicensorsViewFooter';

import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import BaseSort from "../../../../../../../../../components/BaseSort/BaseSort";

import './LicensorsMainView.scss';
import PropTypes from "prop-types";

class LicensorsMainView extends Component {
  renderBody = () => {
    const { contentHeight, listInfo, onEditLicensor } = this.props;
    return (
      <LicensorsViewBody licensors={listInfo.licensors}
        contentHeight={contentHeight}
        onEditLicensor={onEditLicensor} />
    );
  };

  renderFooter = () => {
    const { listInfo, onPageChange, onAddLicensor } = this.props;
    return (
      <LicensorsViewFooter currentPage={listInfo.currentPage}
        pageSize={listInfo.pageSize}
        recordCount={listInfo.recordCount}
        onChange={onPageChange}
        onAddLicensor={onAddLicensor}
        pageCount={listInfo.pageCount} />
    );
  };

  renderFilters = () => {
    const {
      t,
      searchValue,
      onInputSearch,
      onDropdownChange,
      onClearFilters,
      dropdownSort,
      selectOptions
    } = this.props;

    return (
      <div className='FiltersHeader'>
        <label>Search by Name or ID
          <Input
            value={searchValue}
            onChange={onInputSearch}
            className="user-inputs" />
        </label>

        <label>
          <BaseSort
            onValueChange={onDropdownChange}
            defaultSortFilter={selectOptions[0].name}
            sortCriteria={selectOptions}
            value={dropdownSort}
            className="user-inputs"
          />
        </label>

        <Button className="clear-filters" onClick={onClearFilters}>
          {t('UsersViewHeader.clearFilter')}
        </Button>
      </div>
    )
  }

  render() {
    const { listInfo, t } = this.props;
    return (
      <LoadingWrapper className='LicensorsMainView'
        dataLoaded={listInfo.dataLoaded}
        isLoading={listInfo.loading}
        loadingMsg={t('LicensorsMainView.loadingPleaseWait')}
      >
        {this.renderFilters()}
        {this.renderBody()}
        {this.renderFooter()}
      </LoadingWrapper>
    );
  };
}

LicensorsMainView.propTypes = {
  listInfo: PropTypes.object,
  onAddLicensor: PropTypes.func.isRequired,
  onEditLicensor: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default withTranslation()(LicensorsMainView);
