import React, { Component } from 'react';
import ImageLoader from 'react-load-image';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Spin, Empty } from "antd";
import classnames from 'classnames';

import './ImageComponent.scss'

class ImageComponent extends Component {

  render() {
    const {
      alternateLoadingIndicator,
      onClick,
      isCover,
      origin,
      url,
      className,
      t
    } = this.props;

    const view = url ?
      <ImageLoader src={url}>
        <img onClick={onClick} alt={url} />
        <div className="ImageComponent-notFound">{t('ImageComponent.notFound')}</div>
        {alternateLoadingIndicator || <Spin size="large" />}
      </ImageLoader>
      :
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

    return (
      <div className={classnames([
        'ImageComponent',
        className,
        { "is-cover": !!isCover, "is-custom": !!origin, "is-alt": !origin }
      ])}>
        {view}
      </div>
    );
  }
}

ImageComponent.propTypes = {
  alternateLoadingIndicator: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string
};

export default withTranslation()(ImageComponent);
