import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Input, Table } from 'antd';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import BaseLoadMoreSelect from "../../../../../../../../../../components/BaseLoadMoreSelect/BaseLoadMoreSelect";

import { getLicensors } from "../../../../../../../../../../services/licensors-service/licensors.service";

import './ManufacturerDetailsTable.scss';

class ManufacturerDetailsTable extends Component {

  editableProperties = [
    'licensor', 'name'
  ];

  searchDebounce = 300;

  searchTimeoutId;

  startingData = {};

  state = {
    editMode: false,
    editedLicensor: undefined,
    licensors: [],
    name: '',
    licensor: ''
  };

  isEditable = (key) => {
    return this.editableProperties.indexOf(key) !== -1;
  };

  onMFIDNameChange = (event) => {
    this.setState({
      name: event.target.value
    });
  };

  onLicensorChange = ({ id, name }) => {
    this.setState({
      editedLicensor: {
        id, name
      },
      licensor: {
        id, name
      }
    });
  };

  renderEditField = (key) => {
    let field = null;
    switch (key) {
      case 'name':
        field = this.renderMFIDNameInput(key);
        break;
      case 'licensor':
        field = this.renderLicensorSelectField();
        break;
      default:
        break;
    }

    return field;
  };

  renderMFIDNameInput = () => {
    return (
      <Input autoFocus={true}
        onChange={this.onMFIDNameChange}
        value={this.state.name}
      />
    );
  };

  renderLicensorSelectField = () => {
    const { editedLicensor, licensor } = this.state;
    // let preload = [editedLicensor];
    let selectedValue = [editedLicensor?.id, editedLicensor?.name].filter(Boolean).join(' - ');
    if (editedLicensor.id !== licensor.id) {
      // preload = [licensor];
      selectedValue = [licensor.id];
    }
    return (
      <BaseLoadMoreSelect
        onSelect={this.onLicensorChange}
        getData={getLicensors}
        idIdentifier="id"
        name="licensors"
        searchNotAvailable={true}
        selectedValue={selectedValue}
        fullValue={true} />
    );
  };

  renderValue = (value, { key }) => {
    const { editMode } = this.state;
    let render = value;
    if (editMode && this.isEditable(key)) {
      render = this.renderEditField(key);
    }

    return render;
  };

  columns = [
    {
      dataIndex: 'name',
      key: 'name',
      width: 500
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: this.renderValue
    }
  ];

  constructor(props) {
    super(props);
    const data = {
      licensor: cloneDeep(props.licensor),
      editedLicensor: cloneDeep(props.licensor),
      name: props.name
    };
    Object.assign(this.state, data);
    Object.assign(this.startingData, data);
  }

  getTableData = () => {
    const { id, t } = this.props;
    const { licensor, name } = this.state;
    return [{
      name: t('ManufacturersViewBodyTable.name'),
      value: name,
      key: 'name'
    }, {
      name: t('ManufacturersViewBodyTable.id'),
      value: id,
      key: 'id'
    }, {
      name: t('ManufacturersViewBodyTable.licensor'),
      value: licensor.name || t('ManufacturersViewBodyTable.notExist'),
      key: 'licensor'
    }]
  }

  hasDataChanged = () => {
    const { editedLicensor, name } = this.state;
    return JSON.stringify(this.startingData) !== JSON.stringify({ licensor: editedLicensor, name });
  };

  onSaveSuccess = () => {
    const { editedLicensor, name } = this.state;
    const { id, name: licensorName } = editedLicensor;
    const licensorData = { id, name: licensorName };
    this.startingData = { licensor: licensorData, name };
    this.setState({
      licensor: licensorData
    });
    this.toggleEditMode();
  };

  renderFooter = () => {
    const { t } = this.props;
    const { editMode } = this.state;
    const key = editMode ? 'cancel' : 'edit';
    return (
      <div className="Footer">
        {editMode ? (
          <Button onClick={this.saveChanges}
            disabled={!this.hasDataChanged()}>
            {t('ManufacturerDetailsTable.saveChanges')}
          </Button>
        ) : null}
        <Button onClick={this.toggleEditMode}>
          {t(`ManufacturerDetailsTable.${key}`)}
        </Button>
      </div>
    );
  };

  saveChanges = () => {
    const { licensor, name } = this.state;
    this.props.onManufacturerEdit({ licensorId: licensor.id, name }, this.onSaveSuccess);
  };

  toggleEditMode = () => {
    this.setState(prevState => {
      return {
        editMode: !prevState.editMode
      };
    });
  };

  render() {
    return (
      <Table className='ManufacturerDetailsTable'
        dataSource={this.getTableData()}
        columns={this.columns}
        showHeader={false}
        pagination={false}
        rowKey='key'
        bordered
        footer={this.renderFooter} />
    )
  }
}

ManufacturerDetailsTable.propTypes = {
  id: PropTypes.number,
  licensor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  name: PropTypes.string,
  onManufacturerEdit: PropTypes.func.isRequired
};

export default withTranslation()(ManufacturerDetailsTable);