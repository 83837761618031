import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { Button, Icon } from 'antd';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import BaseHorizontalNavigation
  from "../../../../../../../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";
import SceneDetailsInfoTab from "./SceneDetailsInfoTab/SceneDetailsInfoTab";
import SceneDetailsImagesTab from "./SceneDetailsImagesTab/SceneDetailsImagesTab";
import TabsComponent from '../../../../../../../../../../components/TabsComponent/TabsComponent';
import EditMovieSceneModal from "../MovieDetailsScenesTab/MovieScene/EditMovieSceneModal/EditMovieSceneModal";
import UploadMovieSceneImagesModal
  from "../MovieDetailsScenesTab/MovieScene/UploadMovieSceneImagesModal/UploadMovieSceneImagesModal";
import DeleteImagesModal from "../../../../../../../../../../components/modal/DeleteImagesModal/DeleteImagesModal";

import { getMovieSceneDetailsRoute }
  from "../../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";
import { showMovieSceneDetails }
  from "../../../../../../../../../../services/navigation/movies-navigation/movies-navigation.service";
import { deleteSceneImage, uploadSceneImage } from "../../../../../../../../../../services/images-service/images.service";

import { MovieDetailsCommController, MovieDetailsEventMap } from "../movie-details-comm.controller";
import { ModalController } from "../../../../../../../../../../controllers/modal-controller/modal.controller";

import SceneDetailsSampleVideoTab from './ScenDetailsSampleVideoTab/SceneDetailsSampleVideoTab';
import { getOnPropertiesData } from '../../../../../../../../../../services/util-service/util.service';
import './SceneDetailsView.scss';

/* istanbul ignore file */
class SceneDetailsView extends Component {

  state = {
    activeTab: '1',
    selectedScene: [],
    filteredScenes: []
  };

  constructor(props) {
    super(props);
    const scene = props.scenes.find(scene => scene.id?.toString() === props.sceneId);
    this.state.filteredScenes = this.filterScenes();
    this.state.selectedScene = scene;
  }

  isItemSelected = (pathname, link) => {
    return pathname.startsWith(link);
  };

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  onNavigationItemClick = (link, index) => {
    const { scenes } = this.props;
    const selected = scenes.find(scene => scene.index === (index + 1));
    this.setState({ selectedScene: selected });
  };

  convertDurationToTime = (duration) => {
    const { hours, minutes, seconds } = this.getTime(duration);
    let render = this.formatTimeValue(seconds);
    render = `${this.formatTimeValue(minutes)}:${render}`;
    render = `${this.formatTimeValue(hours)}:${render}`;

    return render;
  };

  deleteImage = imageId => {
    const { sceneId } = this.props;
    return deleteSceneImage(sceneId, imageId);
  };

  deleteSceneImages = () => {
    const { selectedScene: { images } = [] } = this.state;

    const modal = (
      <DeleteImagesModal
        images={images}
        deleteImage={this.deleteImage}
        onDeleteSuccess={this.onDeleteFinished}
      />
    );
    ModalController.showModal(modal);
  };

  filterImagesById = (id, item) => {
    return id !== item.id;
  };

  filterScenes = () => {
    const { scenes } = this.props;
    let filteredScenes = [];
    if (scenes) {
      filteredScenes = scenes.filter(this.matchScene);
    }
    return cloneDeep(filteredScenes);
  };

  formatTimeValue = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  getNavigationItems = () => {
    const { scenes } = this.props;
    return scenes.reduce(this.reduceScenes, []);
  };

  getSceneActions = () => {
    const { t, scenesAreEpisodes } = this.props;
    const key = scenesAreEpisodes ? 'Episode' : 'Scene';
    return [
      <Button onClick={this.onSceneSync.bind(this)} key="sceneSyncBtn">
        <Icon type="sync" />
        {t(`SceneDetailsView.sync${key}`)}
      </Button>,
      <Button onClick={this.uploadImages.bind(this)} key="sceneUploadImagesBtn">
        <Icon type="camera" />
        {t(`SceneDetailsView.upload${key}Image`)}
      </Button>,
      <Button onClick={this.onViewContentInfo.bind(this)} key="sceneContentInfoBtn">
        <Icon type="book" />
        {t(`SceneDetailsView.view${key}ContentInfo`)}
      </Button>,
      <Button onClick={this.deleteSceneImages.bind(this)} key="sceneDeleteImageBtn">
        <Icon type="delete" />
        {t(`SceneDetailsView.delete${key}Images`)}
      </Button>,
      <Button onClick={this.showEditSceneModal}
        key="editSceneBtn">
        <Icon type="edit" />
        {t(`SceneDetailsView.edit${key}`)}
      </Button>
    ];
  };

  getTabs = () => {
    const data = this.filterScenes();
    const { images, scenes_on_properties_data } = data[0];
    const { t, scenesAreEpisodes, scenes = [], sceneId, fetchData, setLoading, properties } = this.props;
    const activeScene = scenes.find(scene => scene.id === Number(sceneId));
    let imageTab = {};
    const { downloadableOnProperties } = getOnPropertiesData(scenes_on_properties_data, properties);

    if (images.length !== 0) {
      imageTab = {
        title: t('SceneDetailsView.sceneImages'),
        view: (
          <div className="ImagesTab">
            <SceneDetailsImagesTab
              images={images}
              sceneId={this.props.sceneId}
              onEditCoverImage={this.onEditCoverImage}
              onDeleteImage={this.onDeleteImage}
            />
          </div>
        )
      };
    }

    return (
      [
        {
          title: t('SceneDetailsView.sceneInfo'),
          view: (
            <div className='SceneDetailsView-inner'>
              <SceneDetailsInfoTab
                data={data}
                scenesAreEpisodes={scenesAreEpisodes}
                downloadableOnProperties={downloadableOnProperties}
              />
            </div>
          )
        },
        imageTab,
        {
          title: t('SceneDetailsView.sceneSampleVideo'),
          view: (
            <div className='SceneDetailsView-inner'>
              <SceneDetailsSampleVideoTab
                setLoading={setLoading}
                fetchData={fetchData}
                activeScene={activeScene}
              />
            </div>
          )
        }
      ]
    );
  };

  getTime = (duration) => {
    const hours = Math.floor(duration / (60 * 60));
    const minutes = Math.floor((duration - (hours * 60 * 60)) / 60);
    const seconds = duration - (hours * 60 * 60) - minutes * 60;
    return {
      hours,
      minutes,
      seconds
    };
  };

  matchImageById = (id, image) => {
    let type = 'Promo Shots';
    if (image.id === id) {
      type = 'Cover';
    }

    image.type = type;

    return image;
  };

  matchScene = item => {
    return item.id === +this.props.sceneId;
  };

  matchSceneById = (id, scene) => {
    return scene.id === id;
  };

  onDeleteFinished = () => {
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.sceneImagesDeleteFinishedEvent);
  };

  onDeleteImage = imageId => {
    this.props.fetchData(true);
  };

  onEditCoverImage = imageId => {
    const { setLoading, fetchData } = this.props;
    setLoading('Loading...');
    fetchData(true);
  };

  onSceneEditSuccess = () => {
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.editEvent);
  };

  onSceneSync = () => {
    const { sceneId } = this.props;
    const { activeTab } = this.state;
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.syncEvent, {
      sceneId,
      sceneIndex: +activeTab
    });
  };

  onUploadFinished = () => {
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.sceneImageUploadFinishedEvent);
  };

  onUploadImages = (file, type) => {
    const { sceneId } = this.props;
    return uploadSceneImage(sceneId, type, file);
  };

  onViewContentInfo = () => {
    const { sceneId } = this.props;
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.viewContentInfoEvent, { sceneId });
  };

  reduceScenes = (result, item, index) => {
    const { t, movieId, scenesAreEpisodes } = this.props;
    const { id: sceneId } = item;
    const name = scenesAreEpisodes ? 'episode' : 'scene'

    const navigationItem = {
      link: getMovieSceneDetailsRoute(movieId, sceneId),
      name: t(`SceneDetailsView.${name}`, { index: index + 1 }),
      navigate: showMovieSceneDetails.bind(null, movieId, sceneId)
    };

    result.push(navigationItem);

    return result;
  };

  showEditSceneModal = () => {
    const { scenesAreEpisodes, viewingMovieId, properties } = this.props;
    const filteredScene = this.filterScenes()[0];
    const {
      enabled,
      scenes_on_properties_data,
      endTimeSeconds,
      startTimeSeconds,
      id: sceneId,
      index,
      movie,
      set_as_episode_of_movies,
      videos
    } = filteredScene;

    const {
      publishStart,
      publishEnd,
      downloadableOnProperties
    } = getOnPropertiesData(scenes_on_properties_data, properties);

    const modal = (
      <EditMovieSceneModal onSceneEditSuccess={this.onSceneEditSuccess}
        startTime={this.convertDurationToTime(startTimeSeconds)}
        enabled={enabled}
        downloadableOnProperties={downloadableOnProperties}
        endTime={this.convertDurationToTime(endTimeSeconds)}
        fullMovieData={set_as_episode_of_movies}
        movieId={movie.id}
        publishStart={publishStart}
        publishEnd={publishEnd}
        sceneId={sceneId}
        sceneIndex={index}
        videos={videos}
        scenesAreEpisodes={scenesAreEpisodes}
        viewingMovieId={viewingMovieId}
        properties={properties}
      />
    );
    ModalController.showModal(modal);
  };

  uploadImages = () => {
    const modal = (
      <UploadMovieSceneImagesModal uploadImages={this.onUploadImages}
        onUploadFinished={this.onUploadFinished} />
    );
    ModalController.showModal(modal);
  };

  render() {
    return (
      <div className="SceneDetailsView">
        <BaseHorizontalNavigation
          items={this.getNavigationItems()}
          onNavigationItemClick
          onNavigationLinkClick={this.onNavigationItemClick}
        >
          {this.getSceneActions()}
        </BaseHorizontalNavigation>
        <TabsComponent
          activeKey={this.state.activeTab}
          onChange={this.changeTab}
        >
          {this.getTabs()}
        </TabsComponent>
      </div>
    )
  }
}

SceneDetailsView.propTypes = {
  fetchData: PropTypes.func,
  sceneId: PropTypes.string,
  scenes: PropTypes.array,
  scenesAreEpisodes: PropTypes.number,
  viewingMovieId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default withTranslation()(SceneDetailsView);
