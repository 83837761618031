import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import { ModalController } from "../../../../../../../../../../../controllers/modal-controller/modal.controller";

import BaseImagesDisplay from "../../../../../../../../../../../components/BaseImagesDisplay/BaseImagesDisplay";
import ConfirmationModal from "../../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";

import { setStarHeroImage } from "../../../../../../../../../../../services/stars-service/stars.service";
import {
  deleteStarImage
} from "../../../../../../../../../../../services/images-service/images.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../../../services/notification-service/notification.service";

import './StarDetailsBodyImages.scss';
import { isFunction } from 'lodash';

class StarDetailsBodyImages extends Component {

  imagesDisplayRef;

  closeModal = () => {
    ModalController.removeModal();
  };

  deleteImage = image => {
    const { starId } = this.props;
    deleteStarImage(starId, image.id)
      .then(this.deleteImageSuccess.bind(this, image.id))
      .catch(this.deleteImageFailure);
  };

  deleteImageFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('StarDetailsBodyImages.deleteImageFailure')
    });
  };

  deleteImageSuccess = () => {
    this.props.onStarUpdateFinished();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('StarDetailsBodyImages.deleteImageSuccess')
    });
  };

  displayStarHeroStillUpdateInProgress = () => {
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('StarDetailsBodyImages.starHeroStillUpdateInProgress')
    });
  };

  onDeleteImage = image => {
    const { t } = this.props;
    const modal = (
      <ConfirmationModal
        title={t('StarDetailsBodyImages.confirmDelete')}
        message={t('StarDetailsBodyImages.deleteQuestion')}
        yesBtnText={t('StarDetailsBodyImages.deleteImage')}
        noBtnText={t('StarDetailsBodyImages.cancel')}
        decline={this.closeModal}
        confirm={this.deleteImage.bind(this, image)}
      />
    );
    ModalController.showModal(modal);
  };

  onSetHeroImageFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('StarDetailsBodyImages.setHeroFailedMsg')
    });
  };

  onSetHeroImageSuccess = () => {
    this.props.onStarUpdateFinished();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('StarDetailsBodyImages.starHeroStillChangeSuccess')
    });
  };

  setFullScreen = (index) => {
    this.imagesDisplayRef.toggleFullScreen(index);
  };

  setImagesDisplayRef = (imagesDisplay) => {
    this.imagesDisplayRef = imagesDisplay;
  };

  setHeroImage = image => {
    this.displayStarHeroStillUpdateInProgress();
    const { id: imageId } = image;
    const { starId } = this.props;
    setStarHeroImage(starId, imageId)
      .then(this.onSetHeroImageSuccess.bind(this, imageId))
      .catch(this.onSetHeroImageFailure);
  };

  onEditImage = () => {
    const { onStarUpdateFinished } = this.props;
    if (isFunction(onStarUpdateFinished)) {
      onStarUpdateFinished();
    }
  };

  render() {
    let view = null;
    if (this.props.images.length) {
      view = (
        <div className="StarDetailsBodyImages">
          <BaseImagesDisplay images={this.props.images}
            onInitialize={this.setImagesDisplayRef}
            onFullScreen={this.setFullScreen}
            onSetHeroStill={this.setHeroImage}
            onDeleteImage={this.onDeleteImage}
            onEditImage={this.onEditImage}
          />
        </div>
      );
    }
    return view;
  }
}

StarDetailsBodyImages.propTypes = {
  images: PropTypes.array.isRequired,
  onStarUpdateFinished: PropTypes.func,
  starId: PropTypes.string
};

export default withTranslation()(StarDetailsBodyImages);
