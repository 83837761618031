import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { withTranslation } from "react-i18next";
import { Button } from "antd";

import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";
import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import AddMoviesTop10Modal from "./AddMoviesTop10Modal/AddMoviesTop10Modal";
import BaseDragDropList from '../../../../../../../../../components/BaseDragDropList/BaseDragDropList';
import ImageComponent from "../../../../../../../../../components/ImageComponent/ImageComponent";
import LoadingIndicator from "../../../../../../../../../components/LoadingIndicator/LoadingIndicator";
import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import NumberOneImageSelectModal from "./NumberOneImageSelectModal/NumberOneImageSelectModal";

import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../services/notification-service/notification.service';
import {
  getMovieById,
  getTop10Movies,
  setTopTenPropertyMovies
} from "../../../../../../../../../services/movies-service/movies.service";
import { deleteMovieImage, uploadMovieImage } from "../../../../../../../../../services/images-service/images.service";

import './EditTop10Modal.scss';

/* istanbul ignore file */
class EditTop10Modal extends Component {

  modalRef = React.createRef();

  numberOneImageType;

  startingOrder;

  state = {
    movies: [],
    moviesImages: {},
    movieImagesLoading: false,
    numberOneImage: undefined,
    orderToSend: [],
    top10Images: []
  }

  componentDidMount() {
    this.numberOneImageType = `NumberOne`;
    this.fetchData();
  }

  addElements = () => {
    const { orderToSend } = this.state;
    const modal = (
      <AddMoviesTop10Modal
        propertyId={this.props.propertyId}
        updateTopTenMoviesList={this.updateTopTenMoviesList}
        currentOrder={orderToSend}
      />
    );
    ModalController.showModal(modal);
  };

  deleteMovieImage = (movieId, { id: imageId }) => deleteMovieImage(movieId, imageId);

  getNumberOneImages = (images = []) => images.filter(this.matchImageByType.bind(this, this.numberOneImageType));

  deletePreviousNumberOneImageIfSet = (response) => {
    const { images, movieId } = response.data.data;
    const numberOneImages = this.getNumberOneImages(images);
    let promise;
    if (numberOneImages.length) {
      const promises = numberOneImages.map(this.deleteMovieImage.bind(this, movieId));
      promise = Promise.all(promises);
    } else {
      promise = Promise.resolve();
    }

    return promise;
  };

  fetchData = () => {
    const { propertyId, t } = this.props;
    const message = t('EditTop10Modal.loadingMsg');
    this.getModal().setLoading(message);

    getTop10Movies(propertyId)
      .then(this.parseTop10Data)
      .then(this.loadData)
      .catch(this.onLoadDataFailure);
  };

  fetchNumberOneMovieImagesIfNeeded = () => {
    const { movieImagesLoading, orderToSend } = this.state;
    if (movieImagesLoading) {
      getMovieById(orderToSend[0])
        .then(this.loadNumberOneMovieImages)
        .catch(this.onRequestFailure);
    }
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="EditTop10Modal-actions">
        <Button onClick={this.addElements.bind(this)}
          key="addStudiosBtn">
          {t('EditTop10Modal.addMovies')}
        </Button>
        <div>
          <Button onClick={this.onCancel.bind(this)}
            key="cancelBtn">
            {t('EditTop10Modal.cancel')}
          </Button>
          <Button
            key="saveChangesBtn"
            onClick={this.saveChanges.bind(this)}>
            {t('EditTop10Modal.saveChanges')}
          </Button>
        </div>
      </div>
    );
  };

  getImageId = ({ id }) => {
    return { id };
  };

  getItemId = (item) => {
    return item.movieId;
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getNumberOneMovieDetails = (movieId) => {
    return getMovieById(movieId);
  };

  getTitle = () => {
    const { t, propertyName } = this.props;
    const name = propertyName ? ` - ${propertyName}` : '';
    return `${t('EditTop10Modal.title')}${name}`;
  };

  hasOrderChanged = () => {
    const { orderToSend } = this.state;
    return this.startingOrder.join(',') !== orderToSend.join(',');
  };

  loadData = (values) => {
    const { movies } = values[0].data.data;
    const moviesImages = {};
    if (values[1]) {
      moviesImages[movies[0].movieId] = values[1].data.data.images;
    }
    const top10Images = movies[0]?.top_10_images;
    const orderToSend = movies.map(this.getItemId);
    this.startingOrder = [...orderToSend];
    this.setState({
      movies,
      moviesImages,
      movieImagesLoading: false,
      numberOneImage: undefined,
      orderToSend,
      top10Images
    });
    this.getModal().clearLoading();
  };

  loadNumberOneMovieImages = (response) => {
    this.setState(prevState => {
      const { moviesImages, orderToSend } = prevState;
      moviesImages[orderToSend[0]] = response.data.data.images;
      const top10 = response?.data?.data?.top_10_images ?? [];

      return {
        moviesImages,
        movieImagesLoading: false,
        top10Images: top10
      };
    });
  };

  matchImageById = (imageId, { id }) => imageId === id;

  matchImageByType = (type, image) => image.type === type;

  onCancel = () => {
    this.getModal().closeModal();
  };

  onLoadDataFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditTop10Modal.loadDataFailed')
    });
  };

  onNumberOneImageSave = (files, images) => {
    const url = images[0];
    this.setState({
      numberOneImage: {
        file: files[0],
        images: [{ url }]
      }
    });
  };

  onOrderChange = (items) => {
    const orderToSend = items.map(this.getItemId);
    this.setState(prevState => {
      const { moviesImages, orderToSend: oldOrder, movies } = prevState;
      const hasNumberOneChanged = oldOrder[0] !== orderToSend[0];
      const firstMovie = movies.find(item => item?.id === orderToSend[0]);

      const state = {
        movies: items,
        orderToSend: orderToSend,
        top10Images: firstMovie?.top_10_images ?? []
      };

      if (hasNumberOneChanged) {
        state.numberOneImage = undefined;
        if (!moviesImages[orderToSend[0]]) {
          state.movieImagesLoading = true;
        }
      }

      return state;
    }, this.fetchNumberOneMovieImagesIfNeeded);
  };

  onRequestFailure = (error) => {
    LogController.logError(error);
    this.setState({
      movieImagesLoading: false
    });
  };

  onSetDataFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditTop10Modal.setDataFailed')
    });
  };

  onSetDataSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditTop10Modal.setDataSuccess')
    });
  };

  parseMovieDetailsData = (values, response) => {
    values.push(response);
    return values;
  };

  parseTop10Data = (response) => {
    const { movies } = response.data.data;
    let promise;
    if (movies.length) {
      promise = this.getNumberOneMovieDetails(movies[0].movieId)
        .then(this.parseMovieDetailsData.bind(this, [response]));
    } else {
      promise = Promise.resolve([response]);
    }

    return promise;
  };

  removeItem = (movieId, index) => {
    this.setState(prevState => {
      const { movies } = prevState;
      const firstMovie = index === 0 ? movies[index + 1]?.top_10_images : movies[0]?.top_10_images;

      const state = {
        movies: movies.filter(item => item.movieId !== movieId)
      };

      if (firstMovie) {
        state.top10Images = firstMovie;
      }

      return state;
    });
  };

  renderBaseDragDropList = () => {
    const { movies } = this.state;
    let view = null;
    if (movies.length) {
      view = (
        <BaseDragDropList
          onOrderChange={this.onOrderChange}
          renderItem={this.renderItem}
          renderAdditional={this.renderNumberOneImagePreview}
          onItemRemove={this.removeItem}
          items={movies}
          getItemId={this.getItemId} />
      );
    }

    return view;
  };

  renderChangeImage = () => {
    const { t } = this.props;
    return (
      <div onClick={this.showUploadImageDialog} className="ChangeImage">
        {t('EditTop10Modal.clickToChangeImage')}
      </div>
    );
  };

  renderNumberOneImagePreview = (item, index) => {
    const { moviesImages, movieImagesLoading, numberOneImage, orderToSend, top10Images } = this.state;
    let view = null;
    if (index === 0) {
      if (numberOneImage) {
        const { images } = numberOneImage;
        const url = images ? images[0].url : undefined;
        view = url ? this.renderNumberOnePreviewWrapper(url) : this.renderSelectImageButton();
      } else {
        if (movieImagesLoading) {
          view = this.renderNumberOneImageLoading();
        } else {
          const numberOneMovieId = orderToSend[0];
          const numberOneMovieImages = moviesImages[numberOneMovieId];
          if (numberOneMovieImages) {
            // const numberOneImages = numberOneMovieImages.filter(this.matchImageByType.bind(this, this.numberOneImageType));
            // const url = numberOneImages.length ? numberOneImages[0].url : undefined; 
            const url = top10Images[0]?.url;
            view = url ? this.renderNumberOnePreviewWrapper(url) : this.renderSelectImageButton();
          }
        }
      }
    }

    return view;
  };

  renderNumberOneImageLoading = () => {
    const { t } = this.props;
    return (
      <LoadingIndicator message={t('EditTop10Modal.fetchingMovieNumberOneImage')}
        className="NumberOneImageLoadingIndicator" />
    );
  };

  renderNumberOnePreviewWrapper = (url) => {
    return (
      <div className="NumberOnePreviewWrapper">
        <ImageComponent url={url} />
        {this.renderChangeImage()}
      </div>
    );
  };

  renderSelectImageButton = () => {
    const { t } = this.props;
    return (
      <div className="ImageSelectionWrapper">
        <div className="NoImageText">
          {t('EditTop10Modal.noImageSet')}
        </div>
        <Button onClick={this.showUploadImageDialog}>
          {t('EditTop10Modal.selectImage')}
        </Button>
      </div>
    );
  };

  renderItem = (item, index) => {
    const { cover_image, image } = item;
    const classes = ['ItemContainer'];
    if (index === 0) {
      classes.push('NumberOne');
    }
    return (
      <div className={classes.join(' ')}>
        <div className="ItemContainer-first-row">
          <div className="IndexContainer">
            <h2>{index + 1}</h2>
          </div>
          <div className="ItemDetails">
            <ImageComponent
              className="ItemImage"
              url={cover_image?.url || image}
            />
            <div className="Item-Title">{item.title}</div>
          </div>
        </div>
      </div>
    );
  };

  saveChanges = () => {
    const { t } = this.props;
    const message = t('EditTop10Modal.updatingMsg');
    this.getModal().setLoading(message);
    this.saveRemainingChanges();
  };

  saveRemainingChanges = () => {
    const { propertyId, applyToAll } = this.props;
    const { movies, numberOneImage = {} } = this.state;
    const whiteLabelPropertyId = applyToAll ? applyToAll : propertyId;
    const file = numberOneImage?.file?.file;

    const movieOrderIds = movies.map(item => item.movieId);
    setTopTenPropertyMovies(whiteLabelPropertyId, movieOrderIds, file)
      .then(this.onSetDataSuccess)
      .catch(this.onSetDataFailure);
  };

  showUploadImageDialog = () => {
    const modal = (
      <NumberOneImageSelectModal onUploadFinished={this.onUploadFinished}
        onUploadImageClick={this.onNumberOneImageSave}
        imageType={this.numberOneImageType} />
    );
    ModalController.showModal(modal);
  };

  uploadNumberOneImage = () => {
    const { movies } = this.state;
    const { movieId } = movies[0];
    return this.getNumberOneMovieDetails(movieId)
      .then(this.deletePreviousNumberOneImageIfSet)
      .then(this.uploadNumberOneMovieImage);
  };

  uploadNumberOneMovieImage = () => {
    const { movies, numberOneImage } = this.state;
    const { file } = numberOneImage.file;
    const { movieId } = movies[0];
    return uploadMovieImage(movieId, this.numberOneImageType, file);
  };

  updateTopTenMoviesList = (data) => {
    this.setState(prevState => {
      const movies = cloneDeep(prevState.movies);
      const state = {};
      if (!movies.length) {
        state.movieImagesLoading = true;
      }
      const newMovies = movies.concat(data);
      const orderToSend = newMovies.map(this.getItemId);
      Object.assign(state, {
        movies: newMovies,
        orderToSend: orderToSend
      });

      return state;
    }, this.fetchNumberOneMovieImagesIfNeeded);
  };

  render() {
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className="EditTop10Modal">
          {this.renderBaseDragDropList()}
        </div>
      </ModalDialog>
    );
  }
}

export default withTranslation()(EditTop10Modal);
