import React from 'react';
import {withTranslation} from 'react-i18next';
import {CSVLink} from "react-csv";
import {Button} from 'antd';
import PropTypes from 'prop-types';

import MoviesViewAbstract from '../../MoviesView/MoviesViewAbstract/MoviesViewAbstract';
import StarMoviesMainView from "./StarMoviesMainView/StarMoviesMainView";

import {
  getMoviesByStarId,
  getStarMoviesExport
} from '../../../../../../../../../services/movies-service/movies.service';

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";
import {getStarMoviesRoute} from "../../../../../../../../../services/navigation/stars-navigation/stars-navigation-routes.service";

import {DocumentTitleController} from "../../../../../../../../../controllers/title-controller/title.controller";

class StarMovies extends MoviesViewAbstract {

  starId;

  constructor(props) {
    super(props);
    this.starId = props.starId;
  }

  componentDidMount() {
    super.componentDidMount();
    this.setDocumentTitle();
  }

  fetchMovies = page => {
    const message = this.props.t('StarMovies.loadingMsg');
    this.setLoading(message);
    const filters = this.getFilters();

    getMoviesByStarId(this.starId, page, filters)
      .then(this.loadMovies)
      .catch(this.onFetchMoviesFailure);
  };

  getAdditionalActions = () => {
    const {t} = this.props;
    return (
      <Button onClick={this.onExportMovies}>
        {t('StarMovies.export')}
      </Button>
    );
  };

  getMoviesRoute = () => {
    const {starId} = this.props;
    return getStarMoviesRoute(starId);
  };

  onExportMovies = () => {
    const {starId, t} = this.props;
    const message = t('StarMovies.exportingMsg');
    this.setLoading(message);
    getStarMoviesExport(starId)
      .then(this.onExportMoviesSuccess)
      .catch(this.onExportMoviesFailure);
  };

  onExportMoviesFailure = () => {
    this.setLoading('');
    const {t} = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('StarMovies.moviesExportFailed')
    });
  };

  onExportMoviesSuccess = response => {
    this.setLoading('');
    const {t} = this.props;
    displaySuccessNotification({
      duration: 0,
      message: t('StarMovies.moviesExportSuccess'),
      description: (
        <CSVLink data={response.data} filename={"exported-movies.csv"}>{t('StarMovies.clickToDownloadCSV')}</CSVLink>
      )
    });
  };

  onFetchMoviesFailure = () => {
    this.setState({
      loading: true
    });

    const {t} = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('StarMovies.fetchMoviesFailure')
    });
  };

  renderMainViewRoute = () => {
    const props = this.getMainViewProps();
    props.starId = this.starId;
    return (
      <StarMoviesMainView
        {...props}
        additionalActions={this.getAdditionalActions()}
      />
    );
  };

  setDocumentTitle = () => {
    const {starName, t} = this.props;
    const title = t('StarMovies.documentTitle', {name: starName});
    DocumentTitleController.setDocumentTitle(title);
  };
}

StarMovies.propTypes = {
  starName: PropTypes.string
};

export default withTranslation()(StarMovies);
