import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Icon } from "antd";
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../../components/BaseFooter/BaseFooter";

import './UsersViewFooter.scss';

class UsersViewFooter extends Component {

  getBaseFooterProps = () => {
    const { currentPage, onChange, pageCount, pageSize, recordCount } = this.props;
    return {
      currentPage,
      onChange,
      pageCount,
      pageSize,
      recordCount
    };
  };

  renderAddUserBtn = () => {
    const { t, activeUsers } = this.props;
    return activeUsers ? (
      <Button onClick={this.props.onExport}>
        <Icon type='export'/>
        Export
      </Button>
    ) : (
      <Button onClick={this.props.onAddUser}>
        <Icon type="user-add" />
        {t('UsersViewFooter.addUser')}
      </Button>
    );
  };

  render() {
    const baseFooterProps = this.getBaseFooterProps();
    return (
      <BaseFooter className="UsersViewFooter"
        {...baseFooterProps}>
        {this.renderAddUserBtn()}
      </BaseFooter>
    );
  }
}

UsersViewFooter.propTypes = {
  activeUsers: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  recordCount: PropTypes.number.isRequired
};

export default withTranslation()(UsersViewFooter);

