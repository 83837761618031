import React, {Component} from 'react';

import BaseCheckboxList from "../../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList";


class SpecialCategoriesSelectAbstract extends Component {
  filterDelay = 300;

  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      items: []
    };
  }

  componentDidMount() {
    this.setCheckedItems();
    this.setState({
      selectedRowKeys: this.props.checked,
      items: this.props.data
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.selectedRowKeys !== this.props.selectedRowKeys) {
      this.setState({selectedRowKeys: this.props.checked});
    }

    if(prevState.items !== this.props.data) {
      this.setState({items: this.props.data});
    }

    if(prevProps.checked !== this.props.checked) {
      this.setCheckedItems();
    }
  }

  getLabel = () => {
    console.log('Abstract function definition. Please implement getLabel');
  };

  getItemsIds = ({id}) => id;

  getTitle = () => {
    console.log('Abstract function definition. Please implement getTitle');
  };

  onSelect = (selectedKeys) => {
    this.setState({
      selectedRowKeys: selectedKeys
    });
    this.props.onItemSelect(selectedKeys);
  };

  setCheckedItems = () => {
    const {checked} = this.props;
    this.setState({
      selectedRowKeys: checked || [],
    });
  };

  render() {
    const {showSearch} = this.props;
    const {items, selectedRowKeys} = this.state;
    return (
      <div className="SpecialCategoriesSelectAbstract">
        <h4 className="Label">{this.getLabel()}</h4>
        <BaseCheckboxList key={items.length}
                          showSelectAll={true}
                          checked={selectedRowKeys}
                          onChange={this.onSelect}
                          items={items}
                          showSearch={showSearch}/>
      </div>
    );
  }
}

export default SpecialCategoriesSelectAbstract;