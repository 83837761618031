import axios from 'axios';

export const urls = {
  categories: 'categories'
};

export const getCategoriesWithoutPagination = () => {
  return axios.get(`${urls.categories}/categorieswithoutpaginator`);
};

// TODO commented code - remove if View Details btn is not needed
export const getCategoryById = (id) => {
  return axios.get(`${urls.categories}/${id}`);
};

export const getCategoryByPropertyId = (propertyId) => {
  return axios.get(`${urls.categories}/property/${propertyId}`);
};