import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import DetailsTab from '../DetailsTab/DetailsTab';
import HostsTab from '../HostsTab/HostsTab';

import './PropertyDetails.scss';

class PropertyDetails extends Component {
  render () {
    const {t, name, hosts, propertyData, setLoading, onPropertyEdit, onHostListUpdate, whiteLabel} = this.props;
    return (
      <div className="PropertyDetails">
        <DetailsTab propertyData={propertyData}
                    setLoading={setLoading}
                    onPropertyEdit={onPropertyEdit}
                    name={name}
                    whiteLabel={whiteLabel}/>
        <h2>{t('PropertyDetails.hosts')}</h2>
        <HostsTab hosts={hosts}
                  setLoading={setLoading}
                  name={name}
                  onHostListUpdate={onHostListUpdate}
        />
      </div>
    )
  }
}

export default withTranslation()(PropertyDetails);