import axios from 'axios';

export const urls = {
  users: '/royalties/users',
  user: '/royalties/users/'
};

/**
 * Executes get nats filtered users http request
 *
 * @param {number} page
 * @param {String[]} filters
 * @returns {Promise<AxiosResponse<T>>}
 */
// export const getFilteredNatsUsers = (page, filters) => {
//   return axios.get(`${urls.users}?${filters.join('&')}&page=${page}`);
// };

/**
 * Executes get nats users http request
 *
 * @param {number} page
 * @param {String[]} filters
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
// export const getNatsUsers = (page, filters) => {
//   return filters.length ? getFilteredNatsUsers(page, filters) : getNatsUsersPage(page);
// };

export const getRoyaltiesUsers = (page, filters) => {
  return getRoyaltiesUsersPage(page);
};


/**
 * Executes get nats users page http request
 *
 * @param {number} page
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getRoyaltiesUsersPage = (page) => {
  return axios.get(`${urls.users}?page=${page}`);
};

export const getRoyaltiesUserById = (id) => {
  return axios.get(`${urls.user}${id}`);
};

export const updateRoyaltyUser = (id, data) => {
  return axios.patch(`${urls.users}/${id}`, data);
};
