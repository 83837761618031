import {withTranslation} from "react-i18next";

import CatalogSelectAbstract from '../CatalogSelectAbstract/CatalogSelectAbstract';

class CatalogOrientationSelect extends CatalogSelectAbstract {

  getLabel = () => {
    const {t} = this.props;
    return t('CatalogOrientationSelect.label');
  };

  getTitle = () => {
    const {t} = this.props;
    return t('CatalogOrientationSelect.title');
  };
}

export default withTranslation()(CatalogOrientationSelect);