import PropTypes from "prop-types";

import MoviesViewListAbstract from "../../../MoviesViewListAbstract/MoviesViewListAbstract";

import {getSexActMovieDetailsRoute} from "../../../../../../../../../../../services/navigation/sexact-navigation/sexact-navigation-routes.service"
import {showSexActMovieDetails} from "../../../../../../../../../../../services/navigation/sexact-navigation/sexact-navigation.service";

class SexActMoviesList extends MoviesViewListAbstract {

  constructor(props) {
    super(props);
    this.state.stickyPosition = 0;
  }

  /**
   * @override
   */
  getMovieDetailsRoute = (movieId) => {
    const {sexActId} = this.props;
    return getSexActMovieDetailsRoute(sexActId, movieId);
  };

  /**
   * @override
   */
  showMovieDetails = (movieId) => {
    const {sexActId} = this.props;
    showSexActMovieDetails(sexActId, movieId);
  };
}

SexActMoviesList.propTypes = {
  sexActId: PropTypes.string.isRequired
};

export default SexActMoviesList;
