import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "antd";
import PropTypes from "prop-types";

import MovieDetailsDescription from "./MovieDetailsDescription/MovieDetailsDescription";
import MovieDetailsImage from "./MovieDetailsImage/MovieDetailsImage";
import MovieDetailsInfosTabTable from "./MovieDetailsInfosTabTable/MovieDetailsInfosTabTable";

import { areArraysEqual, getOnPropertiesData } from "../../../../../../../../../../services/util-service/util.service";

import { convertMomentToDate } from "../../../../../../../../../../services/date-service/date.service";
import { getMovieLogoImage } from "../../../../../../../../../../services/images-service/images.service";
import EditMovieRecurrents from "../EditMovieRecurrents/EditMovieRecurrents";
import { ModalController } from "../../../../../../../../../../controllers/modal-controller/modal.controller";
import "./MovieDetailsInfosTab.scss";

class MovieDetailsInfosTab extends Component {
	data = {};

	matchStudioName = (item) => {
		return item.name === "NakedSword Originals";
	};

	state = {
		editMode: false,
		isNakedSwordOriginals: this.props.data.studios?.findIndex(this.matchStudioName) > -1
	};

	/**
	 * Returns an array containing basic table data
	 *
	 * @returns {Object[]}
	 */
	getBasicInfoData = () => {
		const {
			enable,
			movieId,
			runTime,
			releaseDate,
			studios,
			directors,
			stars,
			series,
			categories,
			createdDate,
			publishStatus,
			publishedDate,
			importedOn,
			orientation,
			ns_original_spotlight
		} = this.props.data;

		const seriesData = series?.length ? `${series?.name} (${series?.id})` : '';

		const data = [{
			name: "publishStatus",
			value: publishStatus,
			key: "publishStatus",
		}, {
			name: "enable",
			value: enable,
			key: "enable",
		}, {
			name: "movieId",
			value: movieId,
			key: "movieId",
		}, {
			name: "runTime",
			value: runTime,
			key: "runTime",
		}, {
			name: "releaseDate",
			value: releaseDate,
			key: "releaseDate",
		}, {
			name: "studios",
			value: studios,
			key: "studios",
		}, {
			name: "series",
			value: seriesData,
			key: "series",
		}, {
			name: "directors",
			value: directors,
			key: "directors",
		}, {
			name: "stars",
			value: stars,
			key: "stars",
		}, {
			name: "orientation",
			value: orientation,
			key: "orientation",
		}, {
			name: "categories",
			value: categories,
			key: "categories",
		}, {
			name: "createdDate",
			value: createdDate,
			key: "createdDate",
		}, {
			name: "publishedDate",
			value: publishedDate,
			key: "publishedDate",
		}, {
			name: "importedOn",
			value: importedOn,
			key: "importedOn",
		}];

		if (this.state.isNakedSwordOriginals) {
			data.push({
				name: "ns_original_spotlight",
				value: ns_original_spotlight,
				key: "ns_original_spotlight",
			});
		}

		return data;
	};

	/**
	 * Returns description text for render
	 *
	 * @returns {string}
	 */
	getDescription = () => {
		return this.props.data.descriptionNs || this.props.data.description;
	};

	getMovieInfoData = () => {
		const {
			lastSyncedOn,
			manufacturer = {},
			description,
			descriptionNs,
			title,
			titleNs,
			movie2257s = [],
			videos = [],
			movies_on_properties_data,
			allProperties
		} = this.props.data;

		const {
			publishStart,
			publishEnd,
			downloadableOnProperties,
			exclusive,
			excluded
		} = getOnPropertiesData(movies_on_properties_data, allProperties);

		const data = [{
			name: "publishStart",
			value: publishStart,
			key: "publishStart",
		}, {
			name: "publishEnd",
			value: publishEnd,
			key: "publishEnd",
		}, {
			name: "downloadable_on_properties",
			value: downloadableOnProperties,
			key: "downloadable_on_properties",
		}, {
			name: "lastSyncedOn",
			value: lastSyncedOn,
			key: "lastSyncedOn",
		}, {
			name: "manufacturer",
			value: manufacturer,
			key: "manufacturer",
		}, {
			name: "licensor",
			value: manufacturer.licensor || "",
			key: "licensor",
		}, {
			name: "description",
			value: description,
			key: "description",
		}, {
			name: "descriptionNs",
			value: descriptionNs,
			key: "descriptionNs",
		}, {
			name: "title",
			value: title,
			key: "title",
		}, {
			name: "titleNs",
			value: titleNs,
			key: "titleNs",
		}, {
			name: "customPreview",
			value: videos.length ? videos[0].partial_url : undefined,
			key: "customPreview",
		}, {
			name: "exclusive",
			value: exclusive,
			key: "exclusive",
		}, {
			name: "excluded",
			value: excluded,
			key: "excluded",
		}];

		if (movie2257s.length) {
			data.push({
				name: "movie2257s",
				value: movie2257s[0],
				key: "movie2257s",
			});
		}

		return data;
	};

	hasChanged = (newValue, key) => {
		return this.props.data[key] !== newValue;
	};

	hasArrayChanged = (newValue, key) => {
		return !areArraysEqual(newValue, this.props.data[key]);
	};

	onDataChange = (data) => {
		Object.assign(this.data, data);
	};

	renderFooter = () => {
		const { t } = this.props;
		const { editMode } = this.state;
		const key = editMode ? "cancel" : "edit";
		return (
			<div className="UserActions">
				{editMode ? (
					<Button onClick={this.saveChanges.bind(this)}>
						{t("MovieDetailsInfosTabTable.saveChanges")}
					</Button>
				) : null}
				<Button onClick={this.toggleEditMode}>
					{t(`MovieDetailsInfosTabTable.${key}`)}
				</Button>
			</div>
		);
	};

	saveChanges = (recurrenceId, recurrence, recurrenceCallback) => {
		const { movieId, allProperties = [], movies_on_properties_data = [] } = this.props.data;
		const {
			titleNs,
			descriptionNs,
			customPreview,
			enable,
			movie2257s,
			ns_original_spotlight,
			publishStart = [],
			publishEnd = [],
			excluded = [],
			exclusive = [],
			downloadable_on_properties = []
		} = this.data;

		const { publishRecurrences = [] } = getOnPropertiesData(movies_on_properties_data, allProperties);

		const moviesOnPropertiesPost = allProperties.map(({ id }) => {
			const propertyPublishStart = publishStart.find(item => item.id === id).value;
			const propertyPublishEnd = publishEnd.find(item => item.id === id).value;
			const propertyRecurrence = publishRecurrences.filter(item => item.id === id);

			const data = {
				properties_id: id,
				exclusive_excluded: null,
				publish_start: convertMomentToDate(propertyPublishStart, true, true),
				publish_end: convertMomentToDate(propertyPublishEnd, true, true),
				downloadable: 0,
				publish_recurrences: propertyRecurrence
			};

			if (downloadable_on_properties.includes(id)) {
				data.downloadable = 1;
			}

			if (exclusive.includes(id)) {
				data.exclusive_excluded = 'exclusive';
			} else if (excluded.includes(id)) {
				data.exclusive_excluded = 'excluded';
			}

			if (recurrenceId === id) {
				data.publish_recurrences = recurrence;
			}
			return data;
		});

		const postData = {
			id: movieId,
			titleNs,
			descriptionNs,
			videos: customPreview,
			ns_original_spotlight,
			enable,
			moviesOnPropertiesData: moviesOnPropertiesPost
		};

		const custodianData = this.hasArrayChanged([movie2257s], "movie2257s")
			? movie2257s
			: undefined;

		this.props.onMovieEdit(postData, custodianData, () => {
			this.toggleEditMode();

			if (recurrenceCallback) {
				recurrenceCallback();
			}
		});

		const intervalID = setInterval(() => {
			if (this.props.hasError) {
				this.toggleEditMode();
				if (recurrenceCallback) {
					recurrenceCallback();
				}
				clearInterval(intervalID);
			} else {
				clearInterval(intervalID);
			}
		}, 3000);
	};

	editMovieRecurrents = (recurrenceId) => {
		const { allProperties, movies_on_properties_data } = this.props.data;
		const { publishRecurrences = [] } = getOnPropertiesData(movies_on_properties_data, allProperties);
		const property = allProperties?.find(item => item.id === recurrenceId);
		const propertyRecurrence = publishRecurrences.filter(item => item.id === recurrenceId);

		const modal = (
			<EditMovieRecurrents
				property={property}
				onMovieEdit={(data, callback) => this.saveChanges(recurrenceId, data, callback)}
				recurrence={propertyRecurrence}
			/>
		);
		ModalController.showModal(modal);
	};

	toggleEditMode = () => {
		this.setState((prevState) => {
			return {
				editMode: !prevState.editMode,
			};
		});
	};

	render() {
		const { data, setMovieEnabled } = this.props;
		const logoUrl = getMovieLogoImage(data.images);
		const { editMode, isNakedSwordOriginals } = this.state;
		const { allProperties = [], movies_on_properties_data = [] } = this.props.data;
		const { publishRecurrences = [] } = getOnPropertiesData(movies_on_properties_data, allProperties);
		const hasRecurrences = publishRecurrences.map(item => item.id);

		return (
			<div className="MovieDetailsInfosTab">
				<div className="MovieDetailsInfosTab-primary">
					<MovieDetailsDescription
						description={this.getDescription()}
					/>
					<div className="MovieDetailsInfosTab-inner">
						<MovieDetailsImage logoUrl={logoUrl} />
						<MovieDetailsInfosTabTable
							data={this.getBasicInfoData()}
							movieId={data.movieId}
							setMovieEnabled={setMovieEnabled}
							editMode={editMode}
							onDataChange={this.onDataChange.bind(this)}
						/>
						<MovieDetailsImage logoUrl={logoUrl} />
					</div>
					<MovieDetailsInfosTabTable
						data={this.getMovieInfoData()}
						editMode={editMode}
						properties={data.allProperties}
						onDataChange={this.onDataChange.bind(this)}
						isNakedSwordOriginals={isNakedSwordOriginals}
						editMovieRecurrents={this.editMovieRecurrents}
						hasRecurrences={hasRecurrences}
					/>
				</div>
				{this.renderFooter()}
			</div>
		);
	}
}

MovieDetailsInfosTab.propTypes = {
	data: PropTypes.object.isRequired,
	onMovieEdit: PropTypes.func.isRequired,
	setMovieEnabled: PropTypes.func.isRequired,
};

export default withTranslation()(MovieDetailsInfosTab);
