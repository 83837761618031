import {withTranslation} from "react-i18next";

import UploadImages from "../../../../../../../../../components/modal/UploadImages/UploadImages";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

class ReplacePictureModal extends UploadImages {
  singleImageUpload = true;

  /**
   * @override
   */
  getImageCategories = () => {
    const {t} = this.props;
    return [t('ReplacePictureModal.banner')];
  };

  /**
   * @override
   */
  getModalTitle = () => {
    const {t} = this.props;
    return t('ReplacePictureModal.title');
  };

  onUploadError = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('ReplacePictureModal.bannerReplacePictureFailedMsg')
    });
  };

  onUploadSuccess = () => {
    this.getModal().clearLoading();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('ReplacePictureModal.bannerReplacePictureSuccessMsg')
    });
    this.closeDialog();
    this.props.onUploadFinished();
  };
}

export default withTranslation()(ReplacePictureModal);