import React, { Component } from 'react';

import BannerCard from '../../BannerCard/BannerCard'
import BannersViewFooter from "../BannersViewFooter/BannersViewFooter";

import PropTypes from 'prop-types';

import './BannersViewBody.scss';

class BannersViewBody extends Component {
  renderBannerCards = (banner, i) => {
    const { onBannerEdit, onBannerSetChanged, onDeleteBanner, setLoading, showCheckBox, bannersToDelete } = this.props;
    return (
      <BannerCard key={i}
        showCheckBox={showCheckBox}
        bannersToDelete={bannersToDelete}
        banner={banner}
        onBannerEdit={onBannerEdit}
        showEdit={true}
        onBannerSetChanged={onBannerSetChanged}
        onDeleteBanner={onDeleteBanner}
        setLoading={setLoading} />
    );
  };

  renderFooter = () => {
    const { listInfo, onPageChange } = this.props;
    const { currentPage, pagesTotal, pageSize, recordCount } = listInfo;
    return (
      <BannersViewFooter
        currentPage={currentPage}
        onChange={onPageChange}
        pageCount={pagesTotal}
        pageSize={pageSize}
        total={recordCount}
      />
    );
  };

  render() {
    return (
      <div className='BannersViewBody'>
        <div className='BannersViewBody-inner'>
          {this.props.bannersData.map(this.renderBannerCards)}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

BannersViewBody.propTypes = {
  bannersData: PropTypes.array.isRequired,
  listInfo: PropTypes.object.isRequired,
  onBannerEdit: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  showCheckBox: PropTypes.func.isRequired,
  bannersToDelete: PropTypes.arrayOf(PropTypes.number)
};

export default BannersViewBody;
