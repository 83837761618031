import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import BannerSetCardAbstract from "./BannerSetCardAbstract";

/* istanbul ignore file */
class BannerSetCard extends BannerSetCardAbstract {

  getAdditionalClasses = () => ['BannerSetCard'];
}

BannerSetCard.propTypes = {
  allActionsIncluded: PropTypes.bool,
  bannerOrder: PropTypes.array.isRequired,
  bannerSetId: PropTypes.number.isRequired,
  doNotShowStates: PropTypes.bool,
  isDefault: PropTypes.bool,
  maxElementCount: PropTypes.number,
  propertyId: PropTypes.number,
  propertyCount: PropTypes.number,
  title: PropTypes.string.isRequired,
  totalBannerCount: PropTypes.number.isRequired,
  parentSet: PropTypes.object
};

export default withTranslation()(BannerSetCard);
