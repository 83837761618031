import React from 'react';
import PropTypes from 'prop-types';

import './StarDetailsBodyDescription.scss';

const StarDetailsBodyDescription = props => {
  let view = null;
  if (props.description) {
    view = (
      <p className='StarDetailsBodyDescription'
        dangerouslySetInnerHTML={{
          __html: props.description
        }}
      />
    );
  }
  return view;
};

StarDetailsBodyDescription.propTypes = {
  description: PropTypes.string
};

export default StarDetailsBodyDescription;
