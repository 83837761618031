import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Collapse, Icon, Select } from "antd";
import PropTypes from "prop-types";

import AdsCollapse from "../AdsCollapse/AdsCollapse";
import CollapsiblePanel from "../../shared/CollapsiblePanel/CollapsiblePanel";
import PropertyAdsConfigurationModal from "../adConfiguration/AdsConfigurationModal/AdsConfigurationModal";

import { stopEvent } from "../../../../../../../../../../../services/util-service/util.service";

import { ModalController } from "../../../../../../../../../../../controllers/modal-controller/modal.controller";
import { ConfigureAdsOnPropertyLevelController } from "../ConfigureAdsOnPropertyLevelController";

import "./PropertiesCollapse.scss";
import { isEqual } from "lodash";

/* istanbul ignore file */
class PropertiesCollapse extends Component {
	state = {
		activeKeys: [],
		validationTriggered: false,
		property: [],
	};

	validationSubscription;

	constructor(props) {
		super(props);
		this.state.activeKeys = Object.keys(props.properties);
		this.validationSubscription =
			ConfigureAdsOnPropertyLevelController.validationEvent.subscribe(
				this.validateData
			);
	}

	componentWillMount() {
		const { properties } = this.props;
		if (Object.keys(properties)) {
			this.setState({ property: Object.values(properties) });
		}
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.properties, this.props.properties)) {
			this.setState({ property: Object.values(this.props.properties) });
		}
	}

	componentWillUnmount() {
		if (this.validationSubscription) {
			this.validationSubscription.unsubscribe();
		}
	}

	configurePropertyAds = (id, name, event) => {
		const { ads } = this.props.properties[id];
		stopEvent(event);
		const modal = (
			<PropertyAdsConfigurationModal
				propertyName={name}
				propertyId={id}
				onPropertyAdsChange={this.onPropertyAdsChange}
				propertyAds={ads}
			/>
		);
		ModalController.showModal(modal);
	};

	getPropertyCollapseHeader = (propertyId, propertyName) => {
		const { t } = this.props;
		return (
			<div className="Header">
				<div className="Text">
					{t("ConfigureAdsOnNatsLevel.PropertiesCollapse.natsTitle", {
						name: propertyName,
					})}
				</div>
				<div className="Section2">
					<Button
						onClick={this.configurePropertyAds.bind(
							this,
							propertyId,
							propertyName
						)}
					>
						{t(
							"ConfigureAdsOnNatsLevel.PropertiesCollapse.configureAds"
						)}
					</Button>
					<Icon
						type="close"
						onClick={this.removeProperty.bind(this, propertyId)}
					/>
				</div>
			</div>
		);
	};

	onActiveCollapseChange = (activeKeys) => {
		this.setState({ activeKeys });
	};

	onPropertyAdsChange = (propertyId, ads) => {
		this.props.onPropertyAdsChange(propertyId, ads);
	};

	onPropertyAdEdit = (propertyId, index, data) => {
		const { properties, onPropertyAdsChange } = this.props;
		const { ads } = properties[propertyId];
		ads[index] = data;
		onPropertyAdsChange(propertyId, ads);
	};

	onPropertyRelatedNATSCodesChange = (values, propertyId) => {
		this.props.onRelatedNATSCodesChange(propertyId, values);
		const { property } = this.state;
		this.setState({ property: [{ ...property[0], relatedCodes: values }] });
	};

	propertyMap = (property) => {
		const { invalidKeys } = this.props;
		const { validationTriggered } = this.state;
		const { id, name } = property;
		const { ads } = property;
		const classes = ["Property"];
		if (validationTriggered && ads) {
			classes.push(invalidKeys[id] ? "Invalid" : "Valid");
		}
		return (
			<Collapse.Panel
				key={id}
				className={classes.join(" ")}
				header={this.getPropertyCollapseHeader(id, name)}
			>
				<div>
					<Select
						mode="tags"
						style={{ width: "100%", marginBottom: "20px" }}
						placeholder="Please select"
						defaultValue={property?.relatedCodes}
						onChange={(values) =>
							this.onPropertyRelatedNATSCodesChange(values, id)
						}
					>
						{property?.relatedCodes?.map((code) => (
							<Select.Option value={code} key={code}>
								{code}
							</Select.Option>
						))}
					</Select>
					{ads
						? this.renderPropertyAds(property, id, ads)
						: this.renderNoAdsConfigured()}
				</div>
			</Collapse.Panel>
		);
	};

	removeAd = (propertyId, adIndex) => {
		this.props.onRemovePropertyAd(propertyId, adIndex);
	};

	removeProperty = (propertyId) => {
		this.props.onRemoveProperty(propertyId);
	};

	renderNoAdsConfigured = () => {
		const { t } = this.props;
		return (
			<div>
				{t(
					"ConfigureAdsOnNatsLevel.PropertiesCollapse.noAdsConfigured"
				)}
			</div>
		);
	};

	renderPropertyAds = (property, propertyId, ads) => {
		const { invalidKeys } = this.props;
		let view = null;
		if (ads) {
			view = (
				<AdsCollapse
					invalidKeys={invalidKeys[propertyId]}
					onPropertyAdsChange={this.onPropertyAdEdit}
					onRemovePropertyAd={this.removeAd.bind(this, propertyId)}
					property={property}
					propertyId={propertyId}
					ads={ads}
				/>
			);
		}

		return view;
	};

	validateData = () => {
		this.setState({
			validationTriggered: new Date().getTime(),
		});
	};

	render() {
		const { properties } = this.props;
		const { property, activeKeys } = this.state;
		const keys = Object.keys(properties);
		return (
			<CollapsiblePanel
				className="PropertiesCollapse"
				defaultActiveKeys={keys}
				activeKeys={activeKeys}
				onChange={this.onActiveCollapseChange}
			>
				{property.map(this.propertyMap)}
			</CollapsiblePanel>
		);
	}
}

PropertiesCollapse.propTypes = {
	invalidKeys: PropTypes.object.isRequired,
	onPropertyAdsChange: PropTypes.func.isRequired,
	onRemoveProperty: PropTypes.func.isRequired,
	onRemovePropertyAd: PropTypes.func.isRequired,
	properties: PropTypes.object.isRequired,
};

export default withTranslation()(PropertiesCollapse);
