import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Input} from 'antd';

import {addHostToProperty} from '../../../../../../../../../../services/properties-service/properties.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../services/notification-service/notification.service';

import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import './AddHostModal.scss';

class AddHostModal extends Component {

  state = {
    baseAddress: '',
    hostKey: ''
  };

  modalRef = React.createRef();

  addHost = () => {
    const message = this.props.t('AddHostModal.addingHostPleaseWait');
    const {propertyId} = this.props;

    this.getModal().setLoading(message);

    const postData = {
      base_address: this.state.baseAddress,
      host_key: this.state.hostKey
    };

    addHostToProperty(propertyId, postData)
      .then(this.onAddHostToPropertySuccess)
      .catch(this.onAddHostToPropertyFailure);
  };

  closeDialog = () => {
    this.getModal().closeModal();
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className='AddHostModal-actions'>
          <Button 
            onClick={this.onCancel} 
            key='cancelBtn'>
            {t('AddHostModal.cancel')}
          </Button>
          <Button
            onClick={this.addHost}
            key='addHostBtn'
            disabled={this.isAdressInvalid()}
            type='primary'>
            {t('AddHostModal.addHostBtn')}
          </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('AddHostModal.title');
  };

  onAddHostToPropertyFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddHostModal.addHostFailed')
    });
  };

  onAddHostToPropertySuccess = () => {
    this.closeDialog();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddHostModal.addHostSuccess')
    });
    this.props.onHostListUpdate();
  };

  onCancel = () => {
    this.closeDialog();
  };

  onInputChange = (event) => {
    const {value, name} = event.target;
    this.setState({
      [name]: value
    });
  };

  isAdressInvalid = () => {
    const {baseAddress} = this.state;
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/gi;
    const regex = new RegExp(expression);

    return !baseAddress.match(regex);
  };

  render() {
    const {t} = this.props;
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='AddHostModal'>
          <div className="AddHostModal-field-wrapper">
            <div className="Label">{t('AddHostModal.baseAddress')}:</div>
            <div>{t('AddHostModal.warning')}</div>
            <Input 
              value={this.state.baseAddress}
              name="baseAddress"
              onChange={this.onInputChange}
            />
          </div>
          <div className="AddHostModal-field-wrapper">
            <div className="Label">{t('AddHostModal.hostKey')}:</div>
            <Input 
              value={this.state.hostKey}
              name="hostKey"
              onChange={this.onInputChange}
            />
          </div>
        </div>
      </ModalDialog>
    );
  };
};

export default withTranslation()(AddHostModal);