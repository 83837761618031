import React from 'react';
import { withTranslation } from 'react-i18next';
import { List } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { renderMoment } from "../../../../../../../../../services/date-service/date.service";
import {
  getPayoutsRoute,
  getPayoutPropertiesPeriodRoute,
  getPayoutLicensorsPeriodRoute
} from '../../../../../../../../../services/navigation/payouts-navigation/payouts-navigation-routes.service';

import BaseCard from '../../../../../../../../../components/BaseCard/BaseCard';
import BaseFooter from "../../../../../../../../../components/BaseFooter/BaseFooter";
import LinkButton from "../../../../../../../../../components/LinkButton/LinkButton";
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import PayoutsByMonthHeader from "./PayoutsByMonthHeader/PayoutsByMonthHeader";
import PayoutsDisplayBaseAbstract from "../PayoutsDisplayBaseAbstract/PayotsDisplayBaseAbstract";

import './PayoutsByMonth.scss';

class PayoutsByMonth extends PayoutsDisplayBaseAbstract {

  compensateForValueIfMissing = (value, valueText) => {
    return value ? valueText : '';
  };

  getColumns = () => {
    const { t } = this.props;

    return [{
      dataIndex: 'start_date',
      title: t('PayoutsByMonth.startDate'),
      render: renderMoment
    }, {
      dataIndex: 'end_date',
      title: t('PayoutsByMonth.endDate'),
      render: renderMoment
    }, {
      dataIndex: 'status',
      title: 'Status',
      render: this.renderStatus
    }, {
      dataIndex: 'view_time',
      title: t('PayoutsByMonth.viewTime'),
      render: this.renderTime
    }, {
      dataIndex: 'gross',
      title: t('PayoutsByMonth.gross'),
      render: this.renderNumber
    }, {
      dataIndex: 'affiliate',
      title: t('PayoutsByMonth.affiliate'),
      render: this.renderNumber
    }, {
      dataIndex: 'payout',
      title: t('PayoutsByMonth.payout'),
      render: this.renderNumber
    }, {
      title: t('PayoutsByMonth.action'),
      align: 'center',
      key: 'action',
      render: this.renderActionItem
    }];
  };

  getListCardFooter = (item) => {
    return (
      <div className="PayoutsByMonth-listCard-footer">
        {this.renderActionItem(item)}
      </div>
    );
  };

  getReductionHeight = () => 42 + 54 + 54;

  getRowKey = (item) => item.start_date;

  renderActionItem = item => {
    const { t } = this.props;
    const { start_date } = item;
    const year = moment(start_date).year();
    const month = moment(start_date).month() + 1;

    return (
      <>
        <LinkButton className='ActionBtn' href={getPayoutPropertiesPeriodRoute(year, month)}>
          {t('PayoutsByMonth.ViewDetailsByProperties')}
        </LinkButton>
        <LinkButton className='ActionBtn' href={getPayoutLicensorsPeriodRoute(year, month)}>
          {t('PayoutsByMonth.ViewDetailsByLicensors')}
        </LinkButton>
      </>
    );
  };

  renderFooter = () => {
    const { data, onPageChange } = this.props;
    let view = null;
    if (data.pagination) {
      const { page, perPage, recordCount, pageCount } = data.pagination;
      const props = {
        className: 'Footer',
        currentPage: page,
        onChange: onPageChange,
        pageCount,
        pageSize: perPage,
        recordCount
      };
      view = (
        <BaseFooter {...props} />
      );
    }

    return view;
  };

  renderHeader = () => {

    let header = null;
    const { period, range, location, onDateChange, exportMontly } = this.props;
    if (range.length && location.pathname === getPayoutsRoute()) {
      header = (
        <PayoutsByMonthHeader
          period={period}
          range={range}
          onPayoutExport={exportMontly}
          onDateChange={onDateChange} />
      );
    }

    return header;
  };

  renderPayoutsListItem = item => {
    const { end_date, start_date, gross, affiliate, payout, view_time } = item;
    const { t } = this.props;
    return (
      <List.Item key={payout}>
        <BaseCard
          title={t('PayoutsByMonth.title')}
          footer={this.getListCardFooter(item)}>
          <div className='ListCardWrapper'>
            {this.renderListItemField(t('PayoutsByMonth.startDate'), renderMoment(start_date))}
            {this.renderListItemField(t('PayoutsByMonth.endDate'), renderMoment(end_date))}
            {this.renderListItemField(t('PayoutsByMonth.viewTime'), this.renderTime(view_time))}
            {this.renderListItemField(t('PayoutsByMonth.gross'), this.renderNumber(gross))}
            {this.renderListItemField(t('PayoutsByMonth.affiliate'), this.renderNumber(affiliate))}
            {this.renderListItemField(t('PayoutsByMonth.payout'), this.renderNumber(payout))}
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  renderTime = time => {
    const { t } = this.props;
    const days = Math.floor(time / (60 * 60 * 24));
    const hours = Math.floor((time / (60 * 60)) % 24);
    const minutes = Math.floor((time / 60) % 60);

    const daysText = `${days} ${t('PayoutsByMonth.days')}`;
    const hoursText = `${hours} ${t('PayoutsByMonth.hours')}`;
    const minutesText = `${minutes} ${t('PayoutsByMonth.minutes')}`;

    return `${this.compensateForValueIfMissing(days, daysText)} ${this.compensateForValueIfMissing(hours, hoursText)} ${this.compensateForValueIfMissing(minutes, minutesText)}`;
  };

  render() {
    const { data, dataLoaded, loadingMsg } = this.props;
    const { months } = data;
    return (
      <LoadingWrapper
        className='PayoutsByMonth'
        dataLoaded={dataLoaded}
        isLoading={!!loadingMsg}
        loadingMsg={loadingMsg}>
        {this.renderHeader()}
        <div className='PayoutsByMonthBody'>
          {this.renderTable(months)}
          {this.renderList(months, this.renderPayoutsListItem)}
        </div>
        {this.renderFooter()}
      </LoadingWrapper>
    );
  };
}

PayoutsByMonth.propTypes = {
  data: PropTypes.object.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
  loadingMsg: PropTypes.string,
  period: PropTypes.object,
  range: PropTypes.array
};

export default withTranslation()(PayoutsByMonth);