import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, DatePicker, Input, Select, Checkbox } from 'antd';
import { withTranslation } from 'react-i18next';
import ModalDialog from "../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import { editBannerSet } from '../../../../../../../../../../../services/banners-service/banners.service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../../../services/notification-service/notification.service';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { convertMomentToDate, momentFormats } from "../../../../../../../../../../../services/date-service/date.service";
import './EditDefaultBannerSetModal.scss';

class EditDefaultBannerSetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      group: '',
      banner_override_url: '',
      displaySingleBanner: false,
      randomOnRefresh: false,
      openInNewTab: false,
      sliderDelay: 5,
      natsCodes: [],
      parentSet: undefined,
      parentSets: [],
      loading: false,
      publishStart: null,
      publishEnd: null
    }
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    const {
      id,
      name,
      group,
      banner_override_url,
      display_single_banner: displaySingleBanner,
      randomize_on_refresh: randomOnRefresh,
      open_in_new_tab: openInNewTab,
      slider_delay: sliderDelay,
      nats_codes: natsCodes = [],
      parent_set: parentSet,
      bannersSets = [],
      publish_start: publishStart = null,
      publish_end: publishEnd = null
    } = this.props.data;

    // Exclude all child sets and exclude Playlists set (id: 60)
    const parentSets = bannersSets.filter(set => !set.parent_set && set.id !== 60);

    this.setState({
      id,
      name,
      group,
      banner_override_url,
      displaySingleBanner,
      randomOnRefresh,
      openInNewTab,
      sliderDelay,
      natsCodes,
      parentSet,
      parentSets,
      publishStart,
      publishEnd
    });
  }

  setLoading = loading => {
    this.setState({
      loading: loading
    });
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="EditDefaultBannerSetModal-actions">
        <Button
          onClick={this.onCancel.bind(this)}
          key="cancelBtn"
        >
          {t('EditBannerSetModal.cancel')}
        </Button>
        <Button
          key="saveChangesBtn"
          onClick={this.saveChanges.bind(this)}
        >
          {t('EditBannerSetModal.save')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  handleEditBannerName = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleEditNATSCodes = (value) => {
    this.setState({ natsCodes: value })
  }

  handleParentSetChange = (parentSetId) => {
    const parentSetObject = this.state.parentSets.find(item => item.id === parentSetId);
    this.setState({ parentSet: parentSetObject })
  }

  onCancel = () => {
    this.getModal().closeModal();
  };

  saveChanges = () => {
    const {
      id,
      name,
      group,
      banner_override_url,
      displaySingleBanner,
      randomOnRefresh,
      openInNewTab,
      sliderDelay,
      natsCodes,
      parentSet,
      publishStart,
      publishEnd
    } = this.state;
    const loadingMsg = this.props.t('EditBannerSetModal.savingMsg');
    this.getModal().setLoading(loadingMsg);

    const data = {
      name,
      group,
      banner_override_url: banner_override_url ? banner_override_url : 'null',
      display_single_banner: displaySingleBanner ? 1 : 0,
      randomize_on_refresh: randomOnRefresh ? 1 : 0,
      open_in_new_tab: openInNewTab ? 1 : 0,
      slider_delay: isNaN(sliderDelay) ? 5 : +sliderDelay <= 32767 ? +sliderDelay : 32767
    };

    if (parentSet) {
      data.nats_codes = natsCodes || null;
      data.parent_set_id = parentSet.id;
      data.publish_start = convertMomentToDate(publishStart, 1, 1);
      data.publish_end = convertMomentToDate(publishEnd, 1, 1);
    }

    Promise.all([editBannerSet(id, data)])
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  onSaveChangesSuccess = () => {
    const { updateBannersData } = this.props;
    this.getModal().clearLoading();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditBannerSetModal.saveSuccessMsg')
    });
    this.onCancel();
    updateBannersData();
  }

  onSaveChangesFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditBannerSetModal.saveFailureMsg')
    });
    this.onCancel();
  }

  onDateChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleStateChange = (elem = {}) => {
    const { name, value, checked, type } = elem.target || {};
    const val = type === 'checkbox' ? checked : value;

    this.setState({ [name]: val });
  };

  renderNATSOptions = () => {
    const { natsCodes, parentSet, parentSets, publishStart, publishEnd } = this.state;
    const { t } = this.props;
    // If parent set is null, we do not render the NATS options
    if (isEmpty(parentSet)) return;

    return (
      <>
        <div className="Option">
          {t("EditBannerSetModal.nats")}
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={natsCodes}
            onChange={this.handleEditNATSCodes}
          >
            {natsCodes.map(code =>
              <Select.Option value={code} key={code}>
                {code}
              </Select.Option>
            )}
          </Select>
        </div>
        <div className="Option">
          {t("EditBannerSetModal.parentSelect")}
          <Select value={parentSet.id} onChange={this.handleParentSetChange}>
            {parentSets.map(set =>
              <Select.Option value={set.id} key={set.id}>
                {set.name}
              </Select.Option>
            )}
          </Select>
        </div>
        <div className="Option">
          {t("EditBannerSetModal.publishStart")}
          <DatePicker
            showToday
            showTime
            value={moment(publishStart).isValid() ? moment(publishStart) : null}
            onChange={this.onDateChange.bind(this, 'publishStart')}
            format={momentFormats.dateHours}
          />
        </div>
        <div className="Option">
          {t("EditBannerSetModal.publishEnd")}
          <DatePicker
            showToday
            showTime
            value={moment(publishEnd).isValid() ? moment(publishEnd) : null}
            onChange={this.onDateChange.bind(this, 'publishEnd')}
            format={momentFormats.dateHours}
          />
        </div>
      </>
    )
  }

  renderInput = (label, name, value, propType) => {
    return (
      <div className='Option'>
        {label}
        <Input onChange={this.handleStateChange} name={name} value={value} />
      </div>
    )
  };

  renderCheckbox = (label, name, value) => {
    return (
      <div className='Option'>
        <Checkbox
          checked={value}
          name={name}
          onChange={this.handleStateChange}>
          {label}
        </Checkbox>
      </div>
    )
  };

  render() {
    const { t } = this.props;
    const {
      name,
      group,
      banner_override_url,
      sliderDelay,
      randomOnRefresh,
      openInNewTab,
      displaySingleBanner
    } = this.state;

    return (
      <ModalDialog
        title={t("EditBannerSetModal.title")}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        {this.renderInput('Name', 'name', name)}
        {this.renderInput('Link to', 'banner_override_url', banner_override_url)}
        {this.renderInput('Group', 'group', group)}
        {this.renderNATSOptions()}
        {this.renderInput('Slider delay time', 'sliderDelay', sliderDelay)}
        {this.renderCheckbox('Random on refresh', 'randomOnRefresh', randomOnRefresh)}
        {this.renderCheckbox('Open in new tab', 'openInNewTab', openInNewTab)}
        {this.renderCheckbox('Display single banner', 'displaySingleBanner', displaySingleBanner)}
      </ModalDialog>
    );
  }
}

export default withTranslation()(EditDefaultBannerSetModal);

EditDefaultBannerSetModal.propTypes = {
  data: PropTypes.object
}