import React from 'react';
import { DatePicker, Tooltip, Icon } from 'antd';
import moment from 'moment';
import './BaseDatePickerList.scss';

const BaseDatePickerList = (props) => {
    const { format, onChange, data = [], showTime, recurrence, hasRecurrences = [] } = props;

    if (!data || data.length === 0) return null;

    const applyToAllValue = data.every(item => item.value !== null) && data[0]?.value;

    const onDateTimeChange = ({ id, dateTime, applyToAll }) => {
        // publishing movies for future dates is that they go live at Midnight 00:01.
        const isFutureDate = moment(dateTime).isAfter(moment());
        const midnight = moment(dateTime).set({ 'hour': 0o0, 'minute': 0o1, 'second': 0o0 });
        const alteredDateTime = isFutureDate ? midnight : dateTime;

        if (onChange) {
            onChange({ id, dateTime: alteredDateTime, applyToAll });
        }
    };

    const renderPicker = ({ value, onChange, ...props }) => {
        return (
            <DatePicker
                showToday
                value={moment(value).isValid() ? moment(value) : null}
                format={format}
                onChange={onChange}
                className='DatePicker'
                size='small'
                showTime={showTime}
                {...props}
            />
        )
    };

    return (
        <div className='BaseDatePickerList'>
            <div className='Header'>
                <span>Apply to all Properties</span>
                {renderPicker({
                    value: applyToAllValue ?? null,
                    onChange: dateTime => onDateTimeChange({ dateTime, applyToAll: true }),
                    renderExtraFooter: () => <b>Apply to all Properties</b>
                })}
            </div>
            <div className='Inner'>
                {data.map(property => {
                    const { id, name } = property || {};
                    const value = data.find(item => item?.id === id)?.value ?? null;
                    const hasRecurrence = hasRecurrences.includes(id);

                    return (
                        <div className='ListItem' key={id}>
                            <div className='Title' title={id + ' - ' + name}>{name}</div>
                            {recurrence ? (
                                <Tooltip title='Recurrent Publishes' placement='right'>
                                    <Icon
                                        type='retweet'
                                        onClick={() => recurrence(id)}
                                        className={`RecurrenceIcon ${hasRecurrence ? 'Active' : ''}`}
                                    />
                                </Tooltip>
                            ) : null}

                            <div className='DatePickerContainer' title={id + ' - ' + name}>
                                {renderPicker({
                                    value,
                                    onChange: dateTime => onDateTimeChange({ id, dateTime }),
                                    renderExtraFooter: () => <b>{name}</b>
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BaseDatePickerList;