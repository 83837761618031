import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';

import BaseLoadMoreSelect from '../../../../../../../../../../components/BaseLoadMoreSelect/BaseLoadMoreSelect';
import InfoTooltip from '../../../../../../../../../../components/InfoTooltip/InfoTooltip';

import { getMovies } from '../../../../../../../../../../services/movies-service/movies.service';
import {
  getWhitelabelConfig,
  editWhitelabelConfig
} from "../../../../../../../../../../services/properties-service/properties.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../services/notification-service/notification.service';

import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import './MovieSpotlightModal.scss';

class MovieSpotlightModal extends Component {
  modalRef = React.createRef();

  state = {
    movieSpotlightID: null,
    movieSpotlightName: '',
    whiteLabel: undefined
  }

  componentDidMount() {
    this.fetchWhitelabelConfig();
  }

  fetchWhitelabelConfig = () => {
    const message = this.props.t('MovieSpotlightModal.loadingMsg');
    this.getModal().setLoading(message);
    const { propertyId } = this.props;

    getWhitelabelConfig(propertyId)
      .then(this.getWhitelabelConfigSuccess)
      .catch(this.getWhitelabelConfigFailure);
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='MovieSpotlightModal-actions'>
        <Button onClick={this.onCancel} key='cancelBtn'>
          {t('MovieSpotlightModal.cancel')}
        </Button>
        <Button onClick={this.saveChanges}
          key='saveChangesBtn'>
          {t('MovieSpotlightModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    const { t, propertyName } = this.props;
    const name = propertyName ? ` - ${propertyName}` : '';
    return (
      <div>
        <InfoTooltip text='It should appear on the /just-added and /most-watched page in the movies section.' />
        {t('MovieSpotlightModal.title')} {name}
      </div>
    )
  };

  getWhitelabelConfigFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('MovieSpotlightModal.loadDataFailed')
    });
  };

  getWhitelabelConfigSuccess = response => {
    const currentConfig = response.data.data;
    this.setState({
      whiteLabel: currentConfig,
      movieSpotlightID: currentConfig?.movie_spotlight_id,
      movieSpotlightName: currentConfig?.movie_spotlight_title
    });

    this.getModal().clearLoading();
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChange = ({ movieId, title }) => {
    this.setState({
      movieSpotlightID: movieId,
      movieSpotlightName: title
    });
  };

  onSaveChangesFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('MovieSpotlightModal.saveChangesFailure')
    });
  };

  onSaveChangesSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('MovieSpotlightModal.saveChangesSuccess')
    });
  };

  renderMovieSelect = () => {
    const { t } = this.props;
    return (
      <div className="MovieSpotlightModal-Inner">
        <div className="Label">{t('MovieSpotlightModal.searchMovie')}:</div>
        {this.renderMovieSelectField()}
      </div>
    );
  };

  renderMovieSelectField = () => {
    let view = null;
    const { whiteLabel } = this.state;
    if (whiteLabel) {
      const { movie_spotlight_id, movie_spotlight_title } = whiteLabel;
      let preload = [];
      if (movie_spotlight_id) {
        preload = [{
          movieId: movie_spotlight_id,
          title: movie_spotlight_title
        }];
      }
      view = (
        <BaseLoadMoreSelect onSelect={this.onChange}
          getData={getMovies}
          idIdentifier="movieId"
          name="movies"
          fullValue={true}
          preload={preload}
          selectedValue={[whiteLabel.movie_spotlight_id]} />
      );
    }

    return view;
  };

  saveChanges = () => {
    const message = this.props.t('MovieSpotlightModal.updatingMsg');
    this.getModal().setLoading(message);

    const { movieSpotlightID, movieSpotlightName } = this.state;
    const { propertyId, applyToAll } = this.props;
    const config = {
      movie_spotlight_id: movieSpotlightID,
      movie_spotlight_title: movieSpotlightName
    };
    const whiteLabelId = applyToAll || propertyId;

    editWhitelabelConfig(whiteLabelId, config)
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  render() {
    return (
      <ModalDialog title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className='MovieSpotlightModal'>
          {this.renderMovieSelect()}
        </div>
      </ModalDialog>
    );
  }
}

export default withTranslation()(MovieSpotlightModal);
