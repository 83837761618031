import {navigate} from "@reach/router";

import {
  getPropertiesRoute,
  getPropertyDetailsRoute,
  getPropertiesDetailsBannersSetsRoute,
  getPropertiesDetailsBannerSetBannersRoute,
  getPropertyDetailsUsersRoute,
  getPropertyDetailsMoviesRoute,
  getPropertyMovieDetailsRoute
} from "./properties-navigation-routes.service";

/**
 * Navigates to properties view
 *
 * @returns {Promise<any>}
 */
export const showProperties = () => {
  return navigate(getPropertiesRoute());
};

/**
 * Navigates to property details view
 *
 * @param {number|string} propertyId
 * @returns {Promise<any>}
 */
export const showPropertyDetails = propertyId => {
  return navigate(getPropertyDetailsRoute(propertyId));
};

export const showPropertyDetailsBannersSets = (propertyId) => {
  return navigate(getPropertiesDetailsBannersSetsRoute(propertyId));
};

export const showPropertyDetailsBannersSetBanners = (propertyId, bannerSetId) => {
  return navigate(getPropertiesDetailsBannerSetBannersRoute(propertyId, bannerSetId));
};
/**
 * Navigates to property movies view
 *
 * @param {number|string} propertyId
 *
 * @returns {Promise<any>}
 */
export const showPropertyDetailsMovies = propertyId => {
  return navigate(getPropertyDetailsMoviesRoute(propertyId));
};

/**
 * Navigates to property movie details view
 *
 * @param {number|string} propertyId
 * @param {number|string} movieId
 *
 * @returns {Promise<any>}
 */
export const showPropertyMovieDetails = (propertyId, movieId) => {
  return navigate(getPropertyMovieDetailsRoute(propertyId, movieId));
};

/**
 * Navigates to property users view
 *
 * @param {number|string} propertyId
 *
 * @returns {Promise<any>}
 */
export const showPropertyUsers = propertyId => {
  return navigate(getPropertyDetailsUsersRoute(propertyId));
};