import React from 'react';
import {withTranslation} from "react-i18next";
import {Collapse, Icon} from "antd";
import PropTypes from 'prop-types';

import ConfigurationCollapseAbstract from "../ConfigurationCollapseAbstract";

import {stopEvent} from "../../../../../../../../../../services/util-service/util.service";

import './MoviePlayerConfigCollapse.scss';

/* istanbul ignore file */
class MoviePlayerConfigCollapse extends ConfigurationCollapseAbstract {

  getConfig = ({movieId}) => {
    return this.props.movies[movieId].config;
  };

  getItemId = ({movieId}) => movieId;

  getMovieCollapseHeader = (movieId, movieName, deleteAll) => {
    const {t} = this.props;
    return (
      <div className="Header">
        <div className="Text">
          {t(`MoviePlayerConfigCollapse.title`, {name: movieName})}
        </div>
        <div className="Section2">
          {this.getConfigurePlayerBtn({movieId}, movieName)}
          {!deleteAll ? <Icon type="close" onClick={this.removeMovie.bind(this, movieId)}/> : null}
        </div>
      </div>
    );
  };

  movieMap = (movie) => {
    const {id: movieId, title, deleteAll} = movie;
    return (
      <Collapse.Panel key={movieId}
                      className="Movie"
                      header={this.getMovieCollapseHeader(movieId, title, deleteAll)}>
        {this.renderStateIndicator({movieId}, movie)}
      </Collapse.Panel>
    );
  };

  onPlayerConfigurationChange = (movieId, config, configValues) => {
    const {propertyId, studioId} = this.props;
    this.props.onMoviePlayerChange(propertyId, studioId, movieId, config);
  };

  removeConfiguration = movieId => {
    this.removeMovie(movieId);
  };

  removeMovie = (movieId, event) => {
    if (event) {
      stopEvent(event);
    }
    const {propertyId, studioId} = this.props;
    this.props.onRemoveMovie(propertyId, studioId, movieId);
  };

  render() {
    const {movies} = this.props;
    const data = Object.values(movies);
    const keys = Object.keys(movies);
    return (
      <Collapse
        className="MoviePlayerConfigCollapse"
        defaultActiveKey={keys}
      >
        {data.map(this.movieMap)}
      </Collapse>
    );
  }
}

MoviePlayerConfigCollapse.propTypes = {
  movies: PropTypes.object.isRequired,
  onMoviePlayerChange: PropTypes.func.isRequired,
  onRemoveMovie: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  studioId: PropTypes.number.isRequired
};

export default withTranslation()(MoviePlayerConfigCollapse);
