import axios from 'axios';

export const urls = {
  attachStudioCatalogs: '/studios/movie_catalogs/attach',
  studioEdit: '/studios/edit',
  studiosExport: '/studios/export',
  studios: '/studios',
  studioDisable: '/studios/status/disable',
  studioEnable: '/studios/status/enable',
  studioSync: '/studios/sync',
  controlSwitchers: '/control_switchers'
};

export const attachStudioCatalogs = (studioId, attachedCatalogs) => {
  let params = [];
  for (let i = 0; i < attachedCatalogs.length; i++) {
    params.push(`&movie_catalog_id[]=${attachedCatalogs[i]}`);
  }

  return axios.patch(`${urls.attachStudioCatalogs}?studios_id=${studioId}${params.join('')}`);
};

export const editStudio = (studioId, { foundedDate, descriptionNs, name, studiosOnPropertiesData }) => {
  return axios.patch(`${urls.studioEdit}/${studioId}`,
    { studios_on_properties_data: studiosOnPropertiesData },
    {
      params: {
        name,
        description_ns: descriptionNs,
        founded_date: foundedDate
      }
    }
  );
};

export const exportStudios = name => {
  const searchParams = name ? `?search=${name}` : '';
  return axios.get(`${urls.studiosExport}${searchParams}`);
};

export const getStudios = (page, filters, signal = null) => {
  return filters?.length
    ? getFilteredStudios(page, filters, signal)
    : getStudiosPage(page, signal);
};

export const getStudiosCancelable = (page, filters) => {
  const controller = new AbortController();
  return {
    result: getStudios(page, filters, controller.signal),
    controller
  };
};

export const getStudiosPage = (page, signal) => {
  const data = signal ? {
    signal
  } : {};
  return axios.get(`${urls.studios}?page=${page}`, data);
};

export const getStudiosWithoutPagination = () => {
  return axios.get(`${urls.studios}/without_pagination`);
};

export const getFilteredStudios = (page, filters, signal) => {
  const data = signal ? {
    signal
  } : {};
  return axios.get(`${urls.studios}?${filters.join('&')}&page=${page}`, data);
};

export const getStudioById = id => {
  return axios.get(`${urls.studios}/${id}`);
};

export const setStudioDisabled = id => {
  return axios.get(
    `${urls.studioDisable}/${id}`
  );
};

export const setStudioEnabled = id => {
  return axios.get(
    `${urls.studioEnable}/${id}`
  );
};

export const setStudioControlSwitchers = (studioId, controlSwitchers = []) => {
  return axios.patch(`${urls.studios}/${studioId}${urls.controlSwitchers}/attach`,
    { id: studioId, control_switchers_id: controlSwitchers }
  );
}

export const syncStudio = id => {
  return axios.get(`${urls.studioSync}/${id}`);
};

export const toggleEnableStatus = (id, enabled) => {
  return enabled ? setStudioEnabled(id) : setStudioDisabled(id);
};
