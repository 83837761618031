import React, {Component} from 'react';
import {Button, Icon} from "antd";
import PropTypes from 'prop-types';

import './MoviesViewFooter.scss';

import {
  showMoviesPlaylist,
  showScenesPlaylist
} from "../../../../../../../../../../services/navigation/movies-navigation/movies-navigation.service";

import BaseFooter from '../../../../../../../../../../components/BaseFooter/BaseFooter';
import BaseDropdown from '../../../../../../../../../../components/BaseDropdown/BaseDropdown';

class MoviesViewFooterAbstract extends Component {
  state = {
    inputInvalid: false
  };

  renderChildren = () => {
    return [
      this.renderMaxView(),
      this.renderMinView()
    ];
  };

  getMinViewDropdownItems = () => {
    const {t} = this.props;
    return [
      <div onClick={showMoviesPlaylist}>
        <Icon type="video-camera"/>
        {t('MoviesViewFooter.moviePlaylist')}
      </div>,
      <div onClick={showScenesPlaylist}>
        <Icon type="camera"/>
        {t('MoviesViewFooter.scenePlaylist')}
      </div>
    ];
  };

  renderMaxView = () => {
    const {t} = this.props;
    return (
      <div className="MoviesViewFooter-maxView" key="maxView">
        <Button onClick={showMoviesPlaylist}>
          <Icon type="video-camera"/>
          {t('MoviesViewFooter.moviePlaylist')}
        </Button>
        <Button onClick={showScenesPlaylist}>
          <Icon type="camera"/>
          {t('MoviesViewFooter.scenePlaylist')}
        </Button>
      </div>
    );
  };

  renderMinView = () => {
    const {t} = this.props;
    return (
      <div className="MoviesViewFooter-minView" key="minView">
        <BaseDropdown
          items={this.getMinViewDropdownItems()}
          menuClassName="MoviesViewFooter-dropdownMenu"
        >
          <Button>
            {t('MoviesViewFooter.viewPlaylist')}
            <Icon type="down"/>
          </Button>
        </BaseDropdown>
      </div>
    );
  };

  render() {
    return (
      <div className="MoviesViewFooter">
        <BaseFooter
          currentPage={this.props.currentPage}
          recordCount={this.props.recordCount}
          pageCount={this.props.pageCount}
          pageSize={this.props.pageSize}
          onChange={this.props.onChange}
        >
          {this.renderChildren()}
        </BaseFooter>
      </div>
    );
  }
}

MoviesViewFooterAbstract.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  recordCount: PropTypes.number.isRequired
};

export default MoviesViewFooterAbstract;
