import axios from 'axios';

export const urls = {
  users: '/nats/users',
  usersExport: '/nats/users/export',
  user: '/nats/user/',
  unassigned: '/nats/profitloss/view_time/unassigned'
};

export const exportNatsUsers = (filters = []) => {
  const filtersObject = filters.reduce((obj, f) => {
    var [key, val] = f.split('=');
    obj[key] = val;
    return obj;
  }, {});
  return axios.get(urls.usersExport, {
    params: {
      ...filtersObject
    }
  });
};

/**
 * Executes get nats filtered users http request
 *
 * @param {number} page
 * @param {String[]} filters
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getFilteredNatsUsers = (page, filters = [], signal) => {
  const filtersObject = filters.reduce((obj, f) => {
    var [key, val] = f.split('=');
    obj[key] = val;
    return obj;
  }, {});
  const data = signal ? { signal } : {};
  return axios.get(`${urls.users}`, {
    ...data,
    params: {
      page,
      ...filtersObject
    }
  });
};

/**
 * Executes get nats users http request
 *
 * @param {number} page
 * @param {String[]} filters
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getNatsUsers = (page, filters, signal = null) => {
  return filters.length ? getFilteredNatsUsers(page, filters, signal) : getNatsUsersPage(page, signal);
};

export const getNatsUsersCancelable = (page, filters) => {
  const controller = new AbortController();
  return {
    result: getNatsUsers(page, filters, controller.signal),
    controller
  };
};


/**
 * Executes get nats users page http request
 *
 * @param {number} page
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getNatsUsersPage = (page, signal) => {
  const data = signal ? { signal } : {};
  return axios.get(`${urls.users}?page=${page}`, data);
};

export const getNatsUserById = (id) => {
  return axios.get(`${urls.user}${id}/details`);
}

export const setNatsTestUserById = (id, is_test_user) => {
  return axios.patch(`${urls.user}${id}/test/set`, null, {
    params: {
      id,
      is_test_user: is_test_user ? 1 : 0
    }
  });
}

export const clearUserIPlist = (id) => axios.get(`/cache/nats_user/${id}/stream_block/clear`);

export const assignViewTimeToProperty = (view_time_id, properties_id) => {
  return axios.post(`${urls.unassigned}/${view_time_id}/assign`, {
    properties_id
  });
};

export const getUnassignedKeysCancelable = (page, {
  streaming_key,
  user_type = 0,
  start_date,
  end_date,
  view_time_min = 0
}) => {
  const controller = new AbortController();
  return {
    result: axios.get(urls.unassigned, {
      params: {
        page,
        streaming_key,
        // movies_id,
        view_time_min,
        start_date,
        end_date,
        user_type
      },
      signal: controller.signal
    }),
    controller
  };
};
