import React, { Component } from 'react';
import { Router } from "@reach/router";
import { CSVLink } from "react-csv";
import { compose } from "recompose";
import moment from 'moment';
import { withTranslation } from "react-i18next";
import { exportPayoutsMonthly } from '../../../../../../../../services/payouts-service/payouts.service';

import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import {
  getPayouts,
  getPayoutByPeriod,
  getPayoutByProperty,
  getPayoutByLicensor,
  exportPayoutsByProperty,
  exportFullPayouts,
  exportPayoutsByLicensor,
  exportPayoutsByMovie,
  editFinalizePayoutStatus
} from '../../../../../../../../services/payouts-service/payouts.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../services/notification-service/notification.service';
import {
  getPayoutPropertiesPeriodRoute, getPayoutPropertyRoute, getPayoutsLicensorRoute,
  getPayoutsRoute
} from "../../../../../../../../services/navigation/payouts-navigation/payouts-navigation-routes.service";

import { LogController } from '../../../../../../../../controllers/log-controller/log.controller';

import PayoutsByMonth from './PayoutsByMonth/PayoutsByMonth';
import PayoutsByProperty from './PayoutsByProperty/PayoutsByProperty';

import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import { ModalController } from '../../../../../../../../controllers/modal-controller/modal.controller';
import EditPayoutsPropertyModal from './PayoutsByProperty/EditPayoutsPropertyModal/EditPayoutsPropertyModal';
import LicensorTopView from './PayoutsByLicensor/LicensorTopView';
import './PayoutsView.scss';

class PayoutsView extends Component {
  activeStartDate;
  activeEndDate;
  activePropertyId;
  activeLicensorId;

  dateFormat = 'YYYY-MM-DD';

  // licensorDataLoading = false; 

  state = {
    dataLoaded: false,
    loadingMsg: '',
    period: {},
    range: [],
    activePropertyName: undefined,
    activeStartDate: undefined,
    activeEndDate: undefined,
    activeLicensorName: undefined,
    monthsData: {
      months: [],
      page: 1,
      pageCount: 1,
      perPage: 1,
      recordCount: 1
    },
    licensorsData: {
      licensors: [],
      page: 1,
      pageCount: 1,
      perPage: 1,
      recordCount: 1
    },
    properties: [],
    moviesData: {
      movies: [],
      page: 1,
      pageCount: 1,
      perPage: 1,
      recordCount: 1
    },
    calculationInProgress: false
  };

  componentDidMount() {
    const period = this.getInitialPeriod();
    this.fetchPayoutsByMonth(period, 1);

    // globalHistory.listen(({ action, location }) => {
    //   const { pathname } = location;

    //   if (pathname === getPayoutsRoute()) { 
    //       this.fetchPayoutsByMonth(period, 1); 
    //   }
    // });
  };

  clearLoading = () => {
    this.setState({
      dataLoaded: true,
      loadingMsg: ''
    });
  };

  displayFailureMessage = (message, description = '', duration = 3) => {
    displayErrorNotification({
      message,
      description,
      duration
    });
  };

  exportFullPayouts = () => {
    const { activeStartDate, activeEndDate } = this;
    return exportFullPayouts(activeStartDate, activeEndDate);
  };

  exportPayouts = (exportPromiseFn) => {
    const message = this.props.t('PayoutsView.exportingMsg');
    this.setLoading(message);
    exportPromiseFn()
      .then(this.onPayoutExportSuccess)
      .catch(this.onPayoutExportFailure);
  };

  exportPayoutsByLicensor = () => {
    const { activeStartDate, activeEndDate, activePropertyId } = this;
    return exportPayoutsByLicensor(activePropertyId, activeStartDate, activeEndDate);
  };

  exportPayoutsByMovie = () => {
    const { activeStartDate, activeEndDate, activePropertyId, activeLicensorId } = this;
    return exportPayoutsByMovie(activePropertyId, activeLicensorId, activeStartDate, activeEndDate);
  };

  exportPayoutsByProperty = () => {
    const { activeStartDate, activeEndDate } = this;
    return exportPayoutsByProperty(activeStartDate, activeEndDate);
  };

  fetchLicensorsDataIfNeeded = (propertyId) => {
    const { activePropertyId } = this;
    if (activePropertyId !== propertyId) {
      this.activePropertyId = propertyId;
      this.fetchPayoutsByLicensor(propertyId, 1);
    }
  };

  fetchMoviesDataIfNeeded = (propertyId, licensorId) => {
    const { activeLicensorId } = this;
    if (activeLicensorId !== licensorId) {
      this.activeLicensorId = licensorId;
      this.fetchPayoutsByMovie(propertyId, licensorId, 1);
    }
  };

  fetchIfCalculationInProgress = (error = {}) => {
    const status = error.response?.status;

    if (status === 503) { // Calculation in progress, might take couple of seconds/minutes
      this.setState({
        calculationInProgress: true,
        loadingMsg: this.props.t('PayoutsView.calculationInProgress')
      });
      const { activeStartDate, activeEndDate, activePropertyId, activeLicensorId } = this;
      const { monthsData = {}, properties = [], licensorsData = {}, moviesData = {} } = this.state;
      const period = this.getInitialPeriod();
      const { pathname } = this.props.location;
      const { range } = this.state;
      const startMoment = range[0];
      const split = pathname.slice(1).split('/');
      const year = split[4] || startMoment.year();
      const month = split[5] || startMoment.month() + 1;

      // /main/payouts
      if ((pathname === getPayoutsRoute())) {
        const monthsTimeout = setTimeout(() => {
          this.fetchPayoutsByMonth(period, 1);
        }, 10000);
        if (monthsData.months?.length !== 0) {
          clearTimeout(monthsTimeout);
        }
      }

      // /main/payouts/properties/period/2023/2
      if (pathname === getPayoutPropertiesPeriodRoute(year, month)) {
        const propertiesTimeout = setTimeout(() => {
          this.fetchPayoutsByProperty(activeStartDate, activeEndDate);
        }, 10000);
        if (properties.length !== 0) {
          clearTimeout(propertiesTimeout);
        }
      }

      // /main/payouts/properties/period/2023/2/property/8
      if (pathname === getPayoutPropertyRoute(activePropertyId, year, month)) {
        const licensorsTimeout = setTimeout(() => {
          this.fetchPayoutsByLicensor(activePropertyId, 1);
        }, 10000);
        if (licensorsData.licensors?.length !== 0) {
          clearTimeout(licensorsTimeout);
        }
      }

      // /main/payouts/properties/period/2023/2/property/8/licensors/93
      if (pathname === getPayoutsLicensorRoute(activePropertyId, activeLicensorId, year, month)) {
        const moviesTimeout = setTimeout(() => {
          this.fetchPayoutsByMovie(activePropertyId, activeLicensorId, 1);
        }, 10000);
        if (moviesData.movies?.length !== 0) {
          clearTimeout(moviesTimeout);
        }
      }
    } else {
      this.setState({
        dataLoaded: true,
        loadingMsg: ''
      });
      this.displayFailureMessage(error.message, error.response?.data?.error, 0);
    }
    console.error(error);
  };

  fetchPayoutsByLicensor = (propertyId, page) => {
    const { activeStartDate, activeEndDate } = this;
    // if (activeStartDate && activeEndDate && !licensorDataLoading) {
    const { t } = this.props;
    const { calculationInProgress } = this.state;
    const msg = calculationInProgress ? t('PayoutsView.calculationInProgress') : t('PayoutsView.loadingLicensorPleaseWait');

    this.setState({
      dataLoaded: false,
      loadingMsg: msg
    });

    // this.licensorDataLoading = true;
    const params = {
      startDate: activeStartDate,
      endDate: activeEndDate,
      propertyId: propertyId,
      page: page
    };

    getPayoutByProperty(params)
      .then(this.loadLicensorsData.bind(this, propertyId))
      .catch(this.fetchIfCalculationInProgress);
    // }
  };

  fetchPayoutsByProperty = (startDate, endDate) => {
    const { t } = this.props;
    const { calculationInProgress } = this.state;
    const msg = calculationInProgress ? t('PayoutsView.calculationInProgress') : t('PayoutsView.loadingPropertiesPleaseWait');

    this.setState({
      dataLoaded: false,
      loadingMsg: msg
    });

    getPayoutByPeriod(startDate, endDate)
      .then(this.loadPropertiesData.bind(this, startDate, endDate))
      .catch(this.fetchIfCalculationInProgress);
  };

  fetchPayoutsByMonth = (period, page) => {
    const { t } = this.props;
    const { start_date, end_date } = period;
    const { calculationInProgress } = this.state;
    const msg = calculationInProgress ? t('PayoutsView.calculationInProgress') : t('PayoutsView.loadingMonthsPleaseWait');

    this.setState({
      dataLoaded: false,
      loadingMsg: msg
    });

    getPayouts(start_date, end_date, page)
      .then(this.loadMonthsData)
      .catch(this.fetchIfCalculationInProgress);
  };

  fetchPayoutsByMovie = (propertyId, licensorId, page) => {
    const { activeStartDate, activeEndDate } = this;
    if (activeStartDate && activeEndDate) {
      const { t } = this.props;
      this.setState({
        dataLoaded: false,
        loadingMsg: t('PayoutsView.loadingMoviesPleaseWait')
      });
      const params = {
        properties_id: propertyId,
        licensors_id: licensorId,
        start_date: activeStartDate,
        end_date: activeEndDate,
        page: page
      };

      getPayoutByLicensor(params)
        .then(this.loadMoviesData.bind(this, licensorId))
        .catch(this.fetchIfCalculationInProgress);
    }
  };

  fetchPropertyDataIfNeeded = (startDate, endDate) => {
    const { activeStartDate } = this;
    const { properties } = this.state;
    if (properties.length === 0 || (startDate !== activeStartDate)) {
      this.activeStartDate = startDate;
      this.activeEndDate = endDate;
      this.fetchPayoutsByProperty(startDate, endDate);
    }
  };

  getDocumentTitle = () => {
    return this.props.t('PayoutsView.documentTitle');
  };

  getExportPromise = () => {
    let exportPromise = Promise.resolve();

    const { pathname } = this.props.location;
    const { activePropertyId, activeLicensorId } = this;
    const { range } = this.state;
    const startMoment = range[0];
    const split = pathname.slice(1).split('/');
    const year = split[4] || startMoment.year();
    const month = split[5] || startMoment.month() + 1;

    switch (pathname) {
      case getPayoutPropertiesPeriodRoute(year, month):
        exportPromise = this.exportPayoutsByProperty();
        break;
      case getPayoutPropertyRoute(activePropertyId, year, month):
        exportPromise = this.exportPayoutsByLicensor();
        break;
      case getPayoutsLicensorRoute(activePropertyId, activeLicensorId, year, month):
        exportPromise = this.exportPayoutsByMovie();
        break;
      default:
        break;
    }

    return exportPromise;
  };

  getInitialPeriod = () => {
    const { pathname } = this.props.location;
    let rangeStart;
    let rangeEnd;
    if (pathname === getPayoutsRoute()) {// We are on main payouts route so load current year payouts
      rangeStart = moment().startOf('year');
      rangeEnd = moment().endOf('month');
    } else {
      const split = pathname.split('/');
      const year = split[4];
      const month = split[5];
      rangeStart = moment(`${year}/${month}`, 'YYYY/M').startOf('month');
      rangeEnd = moment(`${year}/${month}`, 'YYYY/M').endOf('month');
    }
    const date = {
      start_date: rangeStart.format(this.dateFormat),
      end_date: rangeEnd.format(this.dateFormat)
    };

    this.setState({
      period: date,
      range: [rangeStart, rangeEnd]
    });

    return date;
  };

  loadPropertiesData = (startDate, endDate, response) => {
    const { by_property } = response.data.data;
    this.setState({
      activeStartDate: startDate,
      activeEndDate: endDate,
      dataLoaded: true,
      loadingMsg: '',
      properties: by_property,
      calculationInProgress: false
    });
  };

  loadLicensorsData = (propertyId, response) => {
    const { data } = response.data;
    const { by_licensor, pagination, properties } = data;
    const { current_page, last_page, per_page, total } = pagination;
    // this.licensorDataLoading = false;
    this.setState({
      activePropertyName: properties.name,
      dataLoaded: true,
      licensorsData: {
        licensors: by_licensor,
        page: current_page,
        pageCount: last_page,
        perPage: per_page,
        recordCount: total,
      },
      loadingMsg: '',
      calculationInProgress: false
    });
  };

  loadMonthsData = (response) => {
    const { monthly, pagination } = response.data.data;
    const { current_page, last_page, total, per_page } = pagination;

    this.setState({
      monthsData: {
        months: monthly,
        page: current_page,
        pageCount: last_page,
        perPage: per_page,
        recordCount: total
      },
      dataLoaded: true,
      loadingMsg: '',
      calculationInProgress: false
    });
  };

  loadMoviesData = (licensorId, response) => {
    const { by_movie, pagination, licensor } = response.data.data;
    const { current_page, last_page, per_page, total } = pagination;
    this.setState({
      activeLicensorName: licensor.name,
      moviesData: {
        movies: by_movie,
        page: current_page,
        pageCount: last_page,
        perPage: per_page,
        recordCount: total
      },
      dataLoaded: true,
      loadingMsg: '',
      calculationInProgress: false
    });
  };

  onDateChange = (startDate, endDate) => {
    const endOfMonth = endDate.endOf('month');
    const period = {
      start_date: startDate.format(this.dateFormat),
      end_date: endOfMonth.format(this.dateFormat),
    };
    const { pathname } = this.props.location;
    const split = pathname.split('/');
    const year = split[4];
    const month = split[5];
    let rangeStart;
    let rangeEnd;

    if (year && month) {
      rangeStart = moment(`${year}/${month}`, 'YYYY/M').startOf('month');
      rangeEnd = moment(`${year}/${month}`, 'YYYY/M').endOf('month');
    }

    this.setState({
      range: rangeStart ? [rangeStart, rangeEnd] : [startDate, endOfMonth],
      period: period
    }, this.fetchPayoutsByMonth.bind(this, period, 1));
  };

  onLicensorsPageChange = (page) => {
    const { activePropertyId } = this;
    this.fetchPayoutsByLicensor(activePropertyId, page);
  };

  onMoviesPageChange = (page) => {
    const { activePropertyId, activeLicensorId } = this;
    this.fetchPayoutsByMovie(activePropertyId, activeLicensorId, page);
  };

  onMonthPageChange = page => {
    const { period } = this.state;
    this.fetchPayoutsByMonth(period, page);
  };

  onPayoutExportFailure = (error) => {
    this.onRequestFailure(error);
    const message = this.props.t('PayoutsView.payoutsExportFailed');
    this.displayFailureMessage(message);
  };

  onPayoutExportSuccess = response => {
    this.setLoading('');
    const { t } = this.props;
    displaySuccessNotification({
      duration: 0,
      message: t('PayoutsView.payoutsExportSuccess'),
      description: (
        <CSVLink data={response.data} filename={"exported-payouts.csv"}>{t('PayoutsView.clickToDownloadCSV')}</CSVLink>
      )
    });
  };

  onRequestFailure = (error) => {
    // this.licensorDataLoading = false;
    this.clearLoading();
    LogController.logError(error);
  };

  exportMontly = () => {
    const message = this.props.t('PayoutsView.exportingMsg');
    this.setLoading(message);

    exportPayoutsMonthly(this.state.period)
      .then(this.onPayoutExportSuccess)
      .catch(this.onPayoutExportFailure);
  }

  onEditFinalizeStatus = (startDate, endDate, propertyId) => {
    const { calculationInProgress } = this.state;
    const msg = calculationInProgress ? this.props.t('PayoutsView.calculationInProgress') : 'Finalizing the payout status. Please wait';

    this.setState({
      dataLoaded: false,
      loadingMsg: msg
    });

    editFinalizePayoutStatus(propertyId, startDate, endDate, 2)
      .then(this.fetchPayoutsByProperty.bind(this, startDate, endDate))
      .catch(this.fetchIfCalculationInProgress);
  }

  openFinalizePayoutModal = (startDate, endDate, propertyId) => {
    const message = `Are you sure you want to finalize the payout report for ${startDate} to ${endDate}?
    This cannot be undone.
    `;

    const modal = (
      <ConfirmationModal
        title="Confirm"
        message={message}
        startDate={startDate}
        endDate={endDate}
        onPayoutEdit={this.onEditFinalizeStatus.bind(this, startDate, endDate, propertyId)}
      />
    );
    ModalController.showModal(modal);
  };

  updatePayoutData = (data) => {
    const { affiliate, gross, status, propertyId } = data;
    const { properties } = this.state;
    const index = properties.findIndex(elem => elem.properties.id === propertyId);
    const updatedProperties = [...properties];
    updatedProperties[index].affiliate = affiliate;
    updatedProperties[index].gross = gross;
    updatedProperties[index].status = status;
    this.setState({ properties: updatedProperties });
  };

  onEditPayout = (startDate, endDate, propertyId) => {
    const { properties } = this.state;
    const propertyData = properties.find(elem => elem.properties.id === propertyId);

    const modal = (
      <EditPayoutsPropertyModal
        propertyData={propertyData}
        startDate={startDate}
        endDate={endDate}
        updatePayoutData={this.updatePayoutData}
      // onPayoutPropertyEdit={this.fetchPayoutsByMonth .bind(this, startDate, endDate)} 
      />
    );
    ModalController.showModal(modal);
  };

  renderPayoutsByMonth = () => {
    const { contentHeight } = this.props;
    const { dataLoaded, loadingMsg, monthsData, period, range } = this.state;
    return (
      <PayoutsByMonth path={"/"}
        onDateChange={this.onDateChange}
        contentHeight={contentHeight}
        onPageChange={this.onMonthPageChange}
        data={monthsData}
        period={period}
        range={range}
        dataLoaded={dataLoaded}
        loadingMsg={loadingMsg}
        exportMontly={this.exportMontly}
      />
    );
  };

  renderPayoutsByProperty = () => {
    const { contentHeight } = this.props;
    const { activePropertyId, activeLicensorId } = this;
    const { activePropertyName, activeLicensorName, dataLoaded, loadingMsg } = this.state;
    return (
      <PayoutsByProperty path={`/properties/period/:year/:month/*`}
        activePropertyId={activePropertyId}
        activeLicensorId={activeLicensorId}
        activePropertyName={activePropertyName}
        activeLicensorName={activeLicensorName}
        contentHeight={contentHeight}
        data={this.state}
        onExportPayouts={this.exportPayouts.bind(this, this.getExportPromise)}
        onFullExport={this.exportPayouts.bind(this, this.exportFullPayouts)}
        onPayoutsByMoviesInitialize={this.fetchMoviesDataIfNeeded}
        onPayoutsByPropertyInitialize={this.fetchPropertyDataIfNeeded}
        onPayoutsByLicensorInitialize={this.fetchLicensorsDataIfNeeded}
        onLicensorsPageChange={this.onLicensorsPageChange}
        onMoviesPageChange={this.onMoviesPageChange}
        dataLoaded={dataLoaded}
        loadingMsg={loadingMsg}
        openFinalizePayoutModal={this.openFinalizePayoutModal}
        onEditPayout={this.onEditPayout}
      />
    );
  };

  renderPayoutsByLicensorTop = () => <LicensorTopView path={'/*'} />;

  setLoading = message => {
    this.setState({
      loadingMsg: message
    });
  };

  render() {
    return (
      <div className="PayoutsView">
        <Router>
          {this.renderPayoutsByMonth()}
          {this.renderPayoutsByProperty()}
          {this.renderPayoutsByLicensorTop()}
        </Router>
      </div>
    );
  }
}

export default compose(withTranslation(), withDocumentTitleUpdate)(PayoutsView);
