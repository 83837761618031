import React from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {CSVLink} from "react-csv";
import {Button} from 'antd';

import {
  getMoviesByStudioId,
  getMoviesExport
} from "../../../../../../../../../services/movies-service/movies.service";
import {getStudioMoviesRoute} from "../../../../../../../../../services/navigation/studios-navigation/studios-navigation-routes.service";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

import {DocumentTitleController} from "../../../../../../../../../controllers/title-controller/title.controller";

import MoviesViewAbstract from "../../MoviesView/MoviesViewAbstract/MoviesViewAbstract";
import StudioMoviesMainView from "./StudioMoviesMainView/StudioMoviesMainView";

class StudioMovies extends MoviesViewAbstract {

  componentDidMount() {
    super.componentDidMount();
    this.setDocumentTitle();
  }

  fetchMovies = (page) => {
    const message = this.props.t('StudioMovies.loadingPleaseWait');
    this.setLoading(message);
    const filters = this.getFilters();
    getMoviesByStudioId(this.props.studioId, page, filters).then(this.loadMovies);
  };

  getAdditionalActions = () => {
    const {t} = this.props;
    return (
      <Button onClick={this.onExportMovies}>
        {t('StudioMovies.export')}
      </Button>
    );
  };

  getMoviesRoute = () => {
    const {studioId} = this.props;
    return getStudioMoviesRoute(studioId);
  };

  onExportMovies = () => {
    const {studioName, t} = this.props;
    const message = t('StudioMovies.exportingMsg');
    this.setLoading(message);
    const searchParams = [`search=${studioName}`];
    getMoviesExport(searchParams)
      .then(this.onExportMoviesSuccess)
      .catch(this.onExportMoviesFailure);
  };

  onExportMoviesFailure = () => {
    this.setLoading('');
    const {t} = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('StudioMovies.moviesExportFailed')
    });
  };

  onExportMoviesSuccess = response => {
    this.setLoading('');
    const {t} = this.props;
    displaySuccessNotification({
      duration: 0,
      message: t('StudioMovies.moviesExportSuccess'),
      description: (
        <CSVLink data={response.data} filename={"exported-movies.csv"}>{t('StudioMovies.clickToDownloadCSV')}</CSVLink>
      )
    });
  };

  renderMainViewRoute = () => {
    const props = this.getMainViewProps();
    props.onChange = this.fetchMovies;
    return (
      <StudioMoviesMainView
        {...props}
        additionalActions={this.getAdditionalActions()}
      />
    );
  };

  setDocumentTitle = () => {
    const {studioName, t} = this.props;
    const title = t('StudioMovies.documentTitle', {name: studioName});
    DocumentTitleController.setDocumentTitle(title);
  };
}

StudioMovies.propTypes = {
  studioId: PropTypes.string,
  studioName: PropTypes.string
};

export default withTranslation()(StudioMovies);
