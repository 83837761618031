import React from 'react';
import { Icon, Tooltip } from 'antd';

import './InfoTooltip.scss';

const InfoTooltip = (props) => {
    const { text = '' } = props;

    if (!text) return null;

    return (
        <Tooltip title={text} className='InfoTooltip'>
            <Icon type='info-circle' />
        </Tooltip>
    )
};

InfoTooltip.displayName = 'InfoTooltip';

export default InfoTooltip;