import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {DatePicker, TimePicker} from "antd";
import {cloneDeep} from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';

import {momentFormats} from "../../../../../../../../../../../../../services/date-service/date.service";
import {range} from "../../../../../../../../../../../../../services/util-service/util.service";

import './AdTime.scss';

/* istanbul ignore file */
class AdTime extends Component {

  state = {
    ad: {}
  };

  timeFormat = "HH:mm";

  constructor(props) {
    super(props);
    const {appliedEndDate, appliedEndTime, appliedStartDate, appliedStartTime} = props.ad;
    Object.assign(this.state.ad, {appliedEndDate, appliedEndTime, appliedStartDate, appliedStartTime});
  }

  isDisabledEndDate = (current) => {
    let isDisabled = false;
    if (current) {
      const {appliedStartDate} = this.state.ad;
      isDisabled = current < moment().startOf('day');
      if (appliedStartDate) {
        isDisabled = isDisabled || current < appliedStartDate.startOf('day');
      }
    }

    return isDisabled;
  };

  isDisabledStartDate = (current) => {
    let isDisabled = false;
    if (current) {
      const {appliedEndDate} = this.state.ad;
      isDisabled = current < moment().startOf('day');
      if (appliedEndDate) {
        isDisabled = isDisabled || current > appliedEndDate.endOf('day');
      }
    }

    return isDisabled;
  };

  isSameDay = (start, end) => {
    const startYear = start.year();
    const startMonth = start.month();
    const startDay = start.date();
    const endYear = end.year();
    const endMonth = end.month();
    const endDay = end.date();
    return (startYear === endYear) && (startMonth === endMonth) && (startDay === endDay);
  };

  isToday = (date) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();
    const year = date.year();
    const month = date.month();
    const day = date.date();
    return (currentYear === year) && (currentMonth === month) && (currentDay === day);
  };

  getDisabledEndHours = () => {
    let disabledHours = [];
    const {appliedEndDate, appliedStartDate, appliedStartTime} = this.state.ad;
    if (this.isToday(appliedEndDate)) {
      disabledHours = this.getTodayDisabledHours();
    }
    if (appliedStartDate && this.isSameDay(appliedStartDate, appliedEndDate) && appliedStartTime) {
      let hours = appliedStartTime.hours();
      const minute = appliedStartTime.minute();
      if (minute === 59) {
        hours++;
      }
      disabledHours = this.getDisabledRange(24, hours);
    }

    return disabledHours;
  };

  getDisabledEndMinutes = () => {
    let disabledMinutes = [];
    const {appliedEndDate, appliedEndTime, appliedStartDate, appliedStartTime} = this.state.ad;
    if (this.isToday(appliedEndDate) && appliedEndTime && (appliedEndTime.hour() === new Date().getHours())) {
      disabledMinutes = this.getTodayDisabledMinutes();
    }
    if (appliedStartDate && this.isSameDay(appliedStartDate, appliedEndDate) && appliedStartTime && appliedEndTime) {
      const startHour = appliedStartTime.hour();
      const endHour = appliedEndTime.hour();
      if (startHour === endHour) {
        const startMinute = appliedStartTime.minute() + 1;
        disabledMinutes = this.getDisabledRange(59, startMinute);
      }
    }

    return disabledMinutes;
  };

  getDisabledRange = (totalCount, subtraction, fromBehind) => {
    let disabledRange;
    if (fromBehind) {
      disabledRange = range(0, totalCount).splice(subtraction, totalCount - subtraction);
    } else {
      disabledRange = range(0, totalCount).splice(0, subtraction);
    }
    return disabledRange;
  };

  getDisabledStartHours = () => {
    let disabledHours = [];
    const {appliedEndDate, appliedEndTime, appliedStartDate} = this.state.ad;
    if (appliedEndDate && this.isSameDay(appliedStartDate, appliedEndDate) && appliedEndTime) {
      let hours = appliedEndTime.hours() + 1;
      const minute = appliedEndTime.minute();
      if (minute === 0) {
        hours--;
      }
      disabledHours = this.getDisabledRange(24, hours, true);
    }
    if (this.isToday(appliedStartDate)) {
      disabledHours = this.getTodayDisabledHours().concat(disabledHours);
    }

    return disabledHours;
  };

  getDisabledStartMinutes = () => {
    let disabledMinutes = [];
    const {appliedEndDate, appliedEndTime, appliedStartDate, appliedStartTime} = this.state.ad;
    if (appliedEndDate && this.isSameDay(appliedStartDate, appliedEndDate) && appliedStartTime && appliedEndTime) {
      const startHour = appliedStartTime.hour();
      const endHour = appliedEndTime.hour();
      if (startHour === endHour) {
        const endMinute = appliedEndTime.minute();
        disabledMinutes = this.getDisabledRange(59, endMinute, true);
      }
    }
    if (this.isToday(appliedStartDate) && appliedStartTime && (appliedStartTime.hour() === new Date().getHours())) {
      disabledMinutes = this.getTodayDisabledMinutes().concat(disabledMinutes);
    }

    return disabledMinutes;
  };

  getTodayDisabledHours = () => {
    const currentHour = new Date().getHours();
    return this.getDisabledRange(24, currentHour);
  };

  getTodayDisabledMinutes = () => {
    const currentMinute = new Date().getMinutes() + 1;
    return this.getDisabledRange(59, currentMinute);
  };

  onAdDateChange = (property, date) => {
    this.setState(prevState => {
      const ad = cloneDeep(prevState.ad);
      ad[property] = date;
      return {ad};
    }, this.onAddUpdate);
  };

  onAdTimeChange = (property, time) => {
    this.setState(prevState => {
      const ad = cloneDeep(prevState.ad);
      ad[property] = time;
      return {ad};
    }, this.onAddUpdate);
  };

  onAddUpdate = () => {
    const {ad} = this.state;
    this.props.onAddTimeUpdate(ad);
  };

  renderAdStartDate = () => {
    const {t} = this.props;
    const {appliedStartDate, appliedStartTime} = this.state.ad;
    return (
      <div className="Interval">
        <div className="Text">
          {t('ConfigureAdsOnPropertyLevel.AdTime.selectAdStartTime')}
        </div>
        <div>
          <DatePicker onChange={this.onAdDateChange.bind(this, 'appliedStartDate')}
                      format={momentFormats.date}
                      disabledDate={this.isDisabledStartDate}
                      value={appliedStartDate}/>
          <TimePicker onChange={this.onAdTimeChange.bind(this, 'appliedStartTime')}
                      format={this.timeFormat}
                      disabledHours={this.getDisabledStartHours}
                      disabledMinutes={this.getDisabledStartMinutes}
                      disabled={!appliedStartDate}
                      value={appliedStartTime}/>
        </div>
      </div>
    );
  };

  renderAdEndDate = () => {
    const {t} = this.props;
    const {appliedEndDate, appliedEndTime} = this.state.ad;
    const additionalDateData = appliedEndDate && appliedEndDate.isValid() ? {
      value: appliedEndDate
    }: {};
    const additionalTimeData = appliedEndTime && appliedEndTime.isValid() ? {
      value: appliedEndTime
    }: {};
    return (
      <div className="Interval">
        <div className="Text">
          {t('ConfigureAdsOnPropertyLevel.AdTime.selectAdEndTime')}
        </div>
        <div>
          <DatePicker onChange={this.onAdDateChange.bind(this, 'appliedEndDate')}
                      {...additionalDateData}
                      format={momentFormats.date}
                      disabledDate={this.isDisabledEndDate}
          />
          <TimePicker onChange={this.onAdTimeChange.bind(this, 'appliedEndTime')}
                      {...additionalTimeData}
                      format={this.timeFormat}
                      disabledHours={this.getDisabledEndHours}
                      disabledMinutes={this.getDisabledEndMinutes}
                      disabled={!appliedEndDate}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="AdTime">
        {this.renderAdStartDate()}
        {this.renderAdEndDate()}
      </div>
    );
  }
}

AdTime.propTypes = {
  ad: PropTypes.object.isRequired,
  onAddTimeUpdate: PropTypes.func.isRequired
};

export default withTranslation()(AdTime);
