import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { getNatsUserById } from "../../../../../../../../../../services/nats-service/nats.service";
import { displayErrorNotification } from "../../../../../../../../../../services/notification-service/notification.service";
import { DocumentTitleController } from "../../../../../../../../../../controllers/title-controller/title.controller";
import LoadingWrapper from "../../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import BaseNavigationItem from "../../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import {
  getNatsUsersRoute
} from "../../../../../../../../../../services/navigation/users-navigation/users-navigation-routes.service";

import TabsComponent from "../../../../../../../../../../components/TabsComponent/TabsComponent";

import NatsUserInfo from "./NatsUserInfo/NatsUserInfo";
import NatsMembershipInfo from "./NatsUserMembershipInfo/NatsMembershipInfo";

import './NatsUserDetails.scss';

class NatsUserDetails extends Component {

  state = {
    activeTab: '1',
    data: {},
    dataLoaded: false,
    loading: false
  };

  componentDidMount() {
    this.setLoading(true);
    getNatsUserById(this.props.userId)
      .then(this.onGetUserSuccess)
      .catch(this.onGetUserFailure);
  }

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  filterSubscriptions = data => {
    const { subscriptions } = data;
    const filteredSubs = subscriptions.filter(this.getTransactions);
    return {
      ...data,
      subscriptions: filteredSubs
    };
  };

  getTabs = () => {
    const { t } = this.props;
    let membershipInfo = {};
    const { data } = this.state;
    if (data.subscriptions && data.subscriptions.length) {
      membershipInfo = {
        title: t('NatsUserDetails.membershipInfo'),
        view: (
          <NatsMembershipInfo
            data={this.state.data}
            contentHeight={this.props.contentHeight}
          />
        )
      };
    }

    return [{
      title: t('NatsUserDetails.userInfo'),
      view: (
        <NatsUserInfo
          data={this.state.data}
        />
      )
    }, membershipInfo];
  };

  getTransactions = subscription => {
    return subscription.transactions.length;
  };

  onGetUserFailure = () => {
    this.setLoading(false);
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('NatsUserDetails.dataGetFailed')
    });
  };

  onGetUserSuccess = (response) => {
    const { data } = response.data;
    const filteredData = this.filterSubscriptions(data);
    this.setDocumentTitle(data);
    this.setState({
      data: filteredData,
      dataLoaded: true,
      loading: false
    });
  };

  renderBody = () => {
    return (
      <div className="NatsUserDetails-body">
        <TabsComponent
          activeKey={this.state.activeTab}
          onChange={this.changeTab}
        >
          {this.getTabs()}
        </TabsComponent>
      </div>
    );
  };

  renderHeader = () => {
    const { t } = this.props;
    const { username = '/' } = this.state.data;
    const routes = [{
      url: getNatsUsersRoute(),
      text: t('NatsUserDetails.users')
    }, {
      text: username
    }];

    return <BaseNavigationItem routes={routes} />;
  };

  setDocumentTitle = (data) => {
    const title = this.props.t('NatsUserDetails.documentTitle', { name: data.username });
    DocumentTitleController.setDocumentTitle(title);
  };

  setLoading = (loading) => {
    this.setState({
      loading: loading
    })
  };

  render() {
    const { t } = this.props;
    const { dataLoaded, loading } = this.state;
    return (
      <LoadingWrapper className='NatsUserDetails'
        dataLoaded={dataLoaded}
        isLoading={loading}
        loadingMsg={t('NatsUserDetails.loadingPleaseWait')}>
        {this.renderHeader()}
        {this.renderBody()}
      </LoadingWrapper>
    )
  }
}

export default withTranslation()(NatsUserDetails);
