import {withTranslation} from "react-i18next";

import {getStudios} from '../../../../services/studio-service/studio.service';

import {uploadStudioImage, assignStudioImages} from '../../../../services/images-service/images.service'

import BaseGlobalImagesAssignSelectionModal from '../../../../components/BaseGlobalImagesAssignSelectionModal/BaseGlobalImagesAssignSelectionModal';

class StudioSelectionModal extends BaseGlobalImagesAssignSelectionModal {

  assignImagesData = (data) => {
    return assignStudioImages(data);
  };

  getAssignButtonTitle = () => {
    const {t} = this.props;
    return t('StudioSelectionModal.assignImagesToStudio');
  };

  getAssignData = (id, fileId, type) => {
    return {
      studios_id: id,
      images_id: fileId,
      type: type
    };
  };

  getImageCategories = () => {
    const {t} = this.props;
    return [t('StudioSelectionModal.logo')];
  };

  getInputPlaceholder = () => {
    const {t} = this.props;
    return t('StudioSelectionModal.enterStudioId');
  };

  getSelectConfig = () => {
    return {
      getData: getStudios, 
      name: 'studio',
      id: 'id'
    };
  };

  getSelectInputLabel = () => {
    const {t} = this.props;
    return t('StudioSelectionModal.label');
  };

  getTitle = () => {
    const {t} = this.props;
    return t('StudioSelectionModal.title');
  };

  uploadImage = (id, type, file) => {
    return uploadStudioImage(id, type, file);
  };
}

export default withTranslation()(StudioSelectionModal);