import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Button } from "antd";
import { isFunction } from "lodash";

import './ImageActions.scss';
import { ModalController } from '../../controllers/modal-controller/modal.controller';
import ChangeAltTagModal from './ChangeAltTagModal/ChangeAltTagModal';
import { displayErrorNotification, displaySuccessNotification } from '../../services/notification-service/notification.service';
import { setImageAltTag } from '../../services/movies-service/movies.service';
import UploadBannerImagesModal from '../../pages/MainDashboard/components/MainView/components/MainContent/components/BannersView/BannerDetails/UploadBannerImagesModal/UploadBannerImagesModal';
import { replaceBannerImage, replaceMovieImage } from '../../services/images-service/images.service';

class ImageActions extends Component {

  onSetAltTagRequest = (image, newAltTag, closeModalCallback) => {
    const { id: imageId } = image;
    setImageAltTag(imageId, newAltTag)
      .then(this.onSetAltTagSuccess.bind(this, imageId, closeModalCallback))
      .catch(this.onSetAltTagFailure);
  };

  onSetAltTagSuccess = (imageId, closeModalCallback) => {
    this.props.onEditImage(imageId);
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('ImageActions.setAltTagSuccessMsg')
    });
    closeModalCallback();
  };

  onSetAltTagFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('ImageActions.setAltTagFailedMsg')
    });
  };

  onDeleteImage = () => {
    const { data, onDeleteImage } = this.props;
    onDeleteImage(data);
  };

  onFullScreen = () => {
    const { index, onFullScreen } = this.props;
    onFullScreen(index);
  };

  onSetHeroStill = () => {
    const { data, onSetHeroStill } = this.props;
    onSetHeroStill(data);
  };

  onSetAltTag = () => {
    const { data } = this.props;
    const modal = (
      <ChangeAltTagModal
        data={data}
        saveChanges={this.onSetAltTagRequest}
      />
    );
    ModalController.showModal(modal);
  };

  replaceImage = (files, closeModalCallback) => {
    const { bannerId, movieId, data } = this.props;
    let promise;

    if (movieId) {
      promise = replaceMovieImage(movieId, data.id, files);
    } else if (bannerId) {
      promise = replaceBannerImage(bannerId, data.id, files);
    }

    promise
      .then(() => {
        this.props.onEditImage(data.id);
        displaySuccessNotification({
          duration: 3,
          message: 'Replace Image Successful!'
        });
      })
      .catch((err) => {
        displayErrorNotification({
          duration: 8,
          message: err?.message + '!',
          description: err?.response?.data?.error
        });
        console.error(err);
      })
      .finally(() => {
        closeModalCallback();
      });
  }

  onReplaceImage = () => {
    const { t, movieId } = this.props;

    const modal = (
      <UploadBannerImagesModal
        replaceImage={this.replaceImage}
        title={t('ImageActions.replaceImage')}
        hideFilesDropdown={!!movieId}
      />
    );
    ModalController.showModal(modal);
  };

  showDeviceName = () => {
    const { movieId, data: { width, type } } = this.props;
    const devices = { desktop: 'Desktop', tablet: 'Tablet', mobile: 'Mobile' };
    let view;

    if (width >= 1024) {
      view = devices.desktop;
    } else if (width <= 1024 && width >= 480) {
      view = devices.tablet;
    } else if (width <= 480) {
      if (movieId) {
        if (width >= 380) {
          view = devices.desktop;
        } else if (width < 380 && width > 160) {
          view = devices.tablet;
        } else if (width <= 160) {
          view = devices.mobile;
        }
        if (type.toLowerCase().includes('back')) {
          view += ', Back';
        }
      } else {
        view = devices.mobile;
      }
    }

    return view;
  }

  render() {
    const { children, t, onSetHeroStill, bannerId, movieId, data: { width, height } } = this.props;
    const isSetHeroStillVisible = isFunction(onSetHeroStill);
    const device = this.showDeviceName();
    const showReplaceImageElements = bannerId || movieId;

    return (
      <div className="ImageActions">
        {showReplaceImageElements && <span className="ImageDimension">{width}x{height} {device}</span>}
        {children}
        <div className="Actions">
          <Button onClick={this.onFullScreen}>{t('ImageActions.fullScreen')}</Button>
          {isSetHeroStillVisible ? <Button onClick={this.onSetHeroStill}>{t('ImageActions.setHeroStill')}</Button> : null}
          {showReplaceImageElements && <Button onClick={this.onReplaceImage}>{t('ImageActions.replaceImage')}</Button>}
          <Button onClick={this.onSetAltTag}>{t('ImageActions.setAltTag')}</Button>
          <Button onClick={this.onDeleteImage}>{t('ImageActions.deleteImage')}</Button>
        </div>
      </div>
    );
  }
}

ImageActions.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  onFullScreen: PropTypes.func,
  onSetHeroStill: PropTypes.func,
  onEditImage: PropTypes.func,
  onDeleteImage: PropTypes.func,
  bannerId: PropTypes.string,
  movieId: PropTypes.string
};

export default withTranslation()(ImageActions);
