import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import PropertyBannerSetBannersAbstract from "./PropertyBannerSetBannersAbstract";

import { getBannerSetPropertySaveKey } from "../../../../../../../../../../services/banners-service/banners.service";

/* istanbul ignore file */
class PropertyBannerSetBanners extends PropertyBannerSetBannersAbstract {

  constructor(props) {
    super(props);
    this.dialogName = 'PropertyBannerSetBanners';
  }

  componentWillUnmount() {
    this.props.updateState('', '');
  }

  getAdditionalStatePropertiesFromValue = (values) => {
    const { name } = values[4].data.data;
    const { propertyId, updateState } = this.props;
    updateState(propertyId, name);
    return {
      propertyName: name
    };
  };

  getRoutes = () => [];

  getPushAllConfirmationModalMessage = () => {
    const { t } = this.props;
    const { propertyName, name: bannerSetName } = this.state;
    return t(`${this.dialogName}.pushAllConfirmMsg`, { bannerSetName, propertyName });
  };

  getSaveKey = () => {
    const { bannerSetId, propertyId } = this.props;
    return getBannerSetPropertySaveKey(bannerSetId, propertyId);
  };

  onReorderChange = () => {
    const { bannerSetId, onReorderChange, propertyId } = this.props;
    onReorderChange(bannerSetId, propertyId);
  };
}

PropertyBannerSetBanners.propTypes = {
  bannerSetId: PropTypes.string,
  propertyId: PropTypes.string,
  updateState: PropTypes.func
};

export default withTranslation()(PropertyBannerSetBanners);
