import axios from 'axios';

export const urls = {
  bannerImageUpload: '/banners/images',
  categoryImageAssign: 'categories/assign',
  categoryImageUpload: '/images/category',
  deleteUnassignedImages: '/images/unassigned/delete',
  movieImageUpload: 'images/movie',
  sceneImageUpload: '/images/scene',
  movieImageAssign: 'movies/assign/',
  starImageAssign: 'stars/assign/',
  bannerImageAssign: 'banners/assign/',
  starImageUpload: '/images/star',
  studioImageAssign: 'studios/assign/',
  studioImageUpload: '/images/studio',
  unassigned: '/images/unassigned',
  unassignedImages: '/images/unassigned/upload'
};

export const deleteImage = (url, id, imageId) => {
  return axios.delete(`${url}/${id}/${imageId}`, {
    params: {
      id,
      image_id: imageId
    }
  });
};

export const assignBannerImages = (data) => {
  return axios.post(urls.bannerImageAssign, data);
};

export const assignCategoryImages = (data) => {
  return axios.post(urls.categoryImageAssign, data);
};

export const assignMovieImages = (data) => {
  return axios.post(urls.movieImageAssign, data);
};

export const assignStarImages = (data) => {
  return axios.post(urls.starImageAssign, data);
};

export const assignStudioImages = (data) => {
  return axios.post(urls.studioImageAssign, data);
};

export const deleteBannerImage = (id, imageId) => {
  return deleteImage(urls.bannerImageUpload, id, imageId);
};

export const deleteCategoryImage = (id, imageId) => {
  return deleteImage(urls.categoryImageUpload, id, imageId);
};

export const deleteMovieImage = (id, imageId) => {
  return deleteImage(urls.movieImageUpload, id, imageId);
};

export const deleteSceneImage = (id, imageId) => {
  return deleteImage(urls.sceneImageUpload, id, imageId);
};

export const deleteStarImage = (id, imageId) => {
  return deleteImage(urls.starImageUpload, id, imageId);
};

export const deleteStudioImage = (id, imageId) => {
  return deleteImage(urls.studioImageUpload, id, imageId);
};

export const deleteUnassignedImages = (imageId) => {
  return axios.delete(`${urls.deleteUnassignedImages}?ids[]=${imageId}`);
};

export const getCoverImageUrl = (images, returnIfUndefined) => {
  let url;
  if (images && images.length) {
    let coverImage = images.find(matchCoverImage);
    if (!coverImage) {
      if (!returnIfUndefined) {
        coverImage = images[0];
        url = coverImage.url;
      }
    } else {
      url = coverImage.url;
    }
  }

  return url;
};

export const getUnassignedImages = () => {
  return axios.get(urls.unassigned);
};

export const matchCoverImage = ({ is_cover }) => !!is_cover;

export const postUnassignedImages = (images) => {
  let formData = new FormData();
  for (let i = 0; i < images.length; i++) {
    formData.append(`images[${i}]`, images[i].file);
  }
  return axios.post(`${urls.unassignedImages}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const uploadImage = (url, id, type, file) => {
  let formData = new FormData();
  formData.append("image", file);
  return axios.post(`${url}/${id}/${type}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const replaceBannerImage = (banner_id, image_id, file) => {
  let formData = new FormData();
  formData.append("image", file);

  return axios.post(`/banners/${banner_id}/images/single/${image_id}/replace`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const findLargesBannerImage = (images) => {
  let img;

  if (images && images.length !== 0) {
    const maxWidth = Math.max(...images.map(img => img.width));
    img = images.find(img => img.width === maxWidth);

    if (!img) {
      img = images.find(img => img?.url);
    }
  }

  return img?.url;
}

export const replaceMovieImage = (movie_id, image_id, file) => {
  let formData = new FormData();
  formData.append("image", file);

  return axios.post(`/images/movie/${movie_id}/single/${image_id}/replace`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getMovieLogoImage = (images) => {
  let poster;

  if (images && images.length !== 0) {
    images.forEach(img => {
      const type = img.type.toLowerCase();

      if (type.includes('front') && img.width >= 300) {
        poster = img.url;
      }
    });

    if (!poster) {
      poster = images.find(item => item.url);
    }
  }

  return poster;
};

export const uploadBannerImage = (id, type, file) => {
  return uploadImage(urls.bannerImageUpload, id, type, file);
};

export const uploadCategoryImage = (id, type, file) => {
  return uploadImage(urls.categoryImageUpload, id, type, file);
};

export const uploadMovieImage = (id, type, file) => {
  return uploadImage(urls.movieImageUpload, id, type, file);
};

export const uploadSceneImage = (id, type, file) => {
  return uploadImage(urls.sceneImageUpload, id, type, file);
};

export const uploadStarImage = (id, type, file) => {
  return uploadImage(urls.starImageUpload, id, type, file);
};

export const uploadStudioImage = (id, file, type) => {
  return uploadImage(urls.studioImageUpload, id, type, file);
};
