import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Collapse, Icon} from "antd";
import PropTypes from 'prop-types';

import PropertiesCollapsePanel from "../PropertiesCollapsePanel/PropertiesCollapsePanel";
import PropertiesSelectionModal from "../../PropertiesSelectionModal/PropertiesSelectionModal";

import {stopEvent} from "../../../../../../../../../../../../services/util-service/util.service";

import {ConfigureMoviesAdsController} from "../ConfigureMoviesAdsController";
import {ModalController} from "../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import CollapsiblePanel from "../../../shared/CollapsiblePanel/CollapsiblePanel";

import './MoviesCollapsePanel.scss';
import ConfigurationWillBeRemoved from "../../../../ConfigurationWillBeRemoved/ConfigurationWillBeRemoved";

/* istanbul ignore file */
class MoviesCollapsePanel extends Component {

  expandSubscription;

  state = {
    activeMovies: [],
    validationTriggered: false
  };

  subscriptions = {};

  constructor(props) {
    super(props);
    this.subscriptions.expandSubscription = ConfigureMoviesAdsController.expandEvent.subscribe(this.setExpanded);
    this.subscriptions.validation = ConfigureMoviesAdsController.triggerValidationEvent.subscribe(this.triggerValidation);
    this.state.activeMovies = Object.keys(props.movies);
  }

  componentWillUnmount() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
  }

  displayPropertySelectionModal = (movie) => {
    const {infos, movieId, title, properties} = movie;
    const modal = (
      <PropertiesSelectionModal movieTitle={title}
                                movieId={movieId}
                                onPropertiesSelected={this.onPropertiesUpdated.bind(this, movieId)}
                                selectedProperties={properties}
                                movieInfos={infos}/>
    );
    ModalController.showModal(modal);
  };

  hasMovieAds = ({properties}) => {
    let hasAds = false;
    if (properties && Object.keys(properties).length) {
      let scenes;
      let ads;
      for (const propertyId in properties) {
        scenes = properties[propertyId].scenes;
        if (scenes && Object.keys(scenes).length) {
          for (const sceneId in scenes) {
            ads = scenes[sceneId];
            hasAds = !!ads;
            if (hasAds) {
              break;
            }
          }
        }
        if (hasAds) {
          break;
        }
      }
    }

    return hasAds;
  };

  movieMap = (movie) => {
    const {validationTriggered} = this.state;
    const {invalidKeys} = this.props;
    const {movieId} = movie;
    const classes = ['Movie'];
    if (validationTriggered) {
      const movieHasAdsConfigured = this.hasMovieAds(movie);
      if (movieHasAdsConfigured) {
        if (invalidKeys[movieId]) {
          classes.push('Invalid');
        } else {
          classes.push('Valid');
        }
      }
    }
    return (
      <Collapse.Panel key={movieId}
                      header={this.renderMoviePanelHeader(movie)}
                      className={classes.join(' ')}>
        {this.renderMovieProperties(movie)}
      </Collapse.Panel>
    );
  };

  onMovieRemove = (movieId, event) => {
    stopEvent(event);
    const {onRemoveMovie} = this.props;
    onRemoveMovie(movieId);
  };

  onPropertiesUpdated = (movieId, data) => {
    const {onPropertiesUpdated} = this.props;
    onPropertiesUpdated(movieId, data);
  };

  onRemoveProperty = (movieId, propertyId) => {
    const {onRemoveProperty} = this.props;
    onRemoveProperty(movieId, propertyId);
  };

  onRemoveScene = (movieId, propertyId, sceneId) => {
    const {onRemoveScene} = this.props;
    onRemoveScene(movieId, propertyId, sceneId);
  };

  onRemoveSceneAd = (movieId, propertyId, sceneId, index) => {
    const {onRemoveSceneAd} = this.props;
    onRemoveSceneAd(movieId, propertyId, sceneId, index);
  };

  renderMoviePanelHeader = (movie) => {
    const {t} = this.props;
    const {movieId, title} = movie;
    return (
      <div className="Header">
        <div className="MovieName">
          {t('ConfigureAdsOnMovieLevel.MoviesCollapsePanel.movieTitleTxt', {title})}
        </div>
        <div className="Section2">
          <Button onClick={this.selectProperties.bind(this, movie)}>
            {t('ConfigureAdsOnMovieLevel.MoviesCollapsePanel.selectProperties')}
          </Button>
          <Icon type="close" onClick={this.onMovieRemove.bind(this, movieId)}/>
        </div>
      </div>
    );
  };

  renderMovieProperties = (movie) => {
    const {deletedScenes, onConfigurePropertyAds, invalidKeys} = this.props;
    const {deleteAll, movieId, properties} = movie;
    let view;
    if (deleteAll) {
      view = (
        <ConfigurationWillBeRemoved isFullRemoval={true}/>
      );
    } else {
      view = (
        <PropertiesCollapsePanel movie={movie}
                                 deletedScenes={deletedScenes}
                                 invalidKeys={invalidKeys[movieId] ? invalidKeys[movieId].properties : {}}
                                 properties={properties ? properties : {}}
                                 onConfigurePropertyAds={onConfigurePropertyAds}
                                 onRemoveProperty={this.onRemoveProperty.bind(this, movieId)}
                                 onRemoveScene={this.onRemoveScene.bind(this, movieId)}
                                 onRemoveSceneAd={this.onRemoveSceneAd.bind(this, movieId)}
                                 onUpdateSceneAdData={this.onUpdateSceneAdData.bind(this, movieId)}
                                 onSceneAdsChange={this.onSceneAdsChange.bind(this, movieId)}/>
      );
    }
    return view;
  };

  selectProperties = (movie, event) => {
    stopEvent(event);
    this.displayPropertySelectionModal(movie);
  };

  setExpanded = (expand) => {
    const activeMovies = expand ? Object.keys(this.props.movies) : [];
    this.setState({
      activeMovies
    });
  };

  onActivePanelChange = (activeMovies) => {
    this.setState({activeMovies});
  };

  onSceneAdsChange = (movieId, propertyId, sceneId, ads) => {
    const {onSceneAdsChange} = this.props;
    onSceneAdsChange(movieId, propertyId, sceneId, ads);
  };

  onUpdateSceneAdData = (movieId, propertyId, sceneId, index, data) => {
    const {onUpdateSceneAdData} = this.props;
    onUpdateSceneAdData(movieId, propertyId, sceneId, index, data);
  };

  triggerValidation = () => {
    this.setState({
      validationTriggered: new Date().getTime()
    });
  };

  render() {
    const {movies} = this.props;
    const data = Object.values(movies);
    const initialKeys = Object.keys(movies);
    const {activeMovies} = this.state;
    return (
      <CollapsiblePanel defaultActiveKeys={initialKeys}
                        activeKeys={activeMovies}
                        onChange={this.onActivePanelChange}
                        className="MoviesCollapsePanel">
        {data.map(this.movieMap)}
      </CollapsiblePanel>
    );
  }
}

MoviesCollapsePanel.propTypes = {
  deletedScenes: PropTypes.object.isRequired,
  invalidKeys: PropTypes.object.isRequired,
  movies: PropTypes.object.isRequired,
  onConfigurePropertyAds: PropTypes.func.isRequired,
  onPropertiesUpdated: PropTypes.func.isRequired,
  onRemoveMovie: PropTypes.func.isRequired,
  onRemoveProperty: PropTypes.func.isRequired,
  onRemoveSceneAd: PropTypes.func.isRequired,
  onRemoveScene: PropTypes.func.isRequired,
  onSceneAdsChange: PropTypes.func.isRequired,
  onUpdateSceneAdData: PropTypes.func.isRequired
};

export default withTranslation()(MoviesCollapsePanel);
