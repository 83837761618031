import React, {Component} from 'react';
import {cloneDeep} from 'lodash';
import {withTranslation} from "react-i18next";
import {Icon} from 'antd';

import {
  getScenePlaylistById,
  deleteScenePlaylist
} from '../../../../../../../../../../../services/movies-service/movies.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../../services/notification-service/notification.service';
import {showScenesPlaylist} from '../../../../../../../../../../../services/navigation/movies-navigation/movies-navigation.service';

import {ModalController} from '../../../../../../../../../../../controllers/modal-controller/modal.controller';
import {LogController} from "../../../../../../../../../../../controllers/log-controller/log.controller";

import ConfirmationModal from '../../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal';
import LoadingWrapper from "../../../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import TabsComponent from "../../../../../../../../../../../components/TabsComponent/TabsComponent";
import BaseHeader from '../../../../../../../../../../../components/BaseHeader/BaseHeader';

import ScenePlaylistDetailsTab from './components/ScenePlaylistDetailsTab/ScenePlaylistDetailsTab';
import ScenesPlaylistScenesTab from './components/ScenesPlaylistScenesTab/ScenesPlaylistScenesTab';
import ScenePlaylistDetailsFooter from './components/ScenePlaylistDetailsFooter/ScenePlaylistDetailsFooter';
import EditScenePlaylistModal from './EditScenePlaylistModal/EditScenePlaylistModal';

import './ScenePlaylistDetails.scss';
import AddScenesModal from './AddScenesModal/AddScenesModal';

class ScenePlaylistDetails extends Component {

  sceneDeleting = false;

  state = {
    activeTab: '1',
    data: {},
    dataLoaded: false,
    loadingMsg: '',
  };

  componentDidMount() {
    this.fetchPlaylistById();
  };

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  deletePlaylist = () => {
    const {id} = this.state.data;

    deleteScenePlaylist(id)
      .then(this.onDeletePlaylistSuccess)
      .catch(this.onDeletePlaylistFailure);
  };

  editPlaylist = () => {
    const modal = (
      <EditScenePlaylistModal
        playlistId={this.state.data.id}
        onEditPlaylist={this.onEditPlaylist}
      />
    );
    ModalController.showModal(modal);
  };

  fetchPlaylistById = () => {
    const message = this.props.t('ScenePlaylistDetails.loadingDetailsPleaseWait');
    this.setLoading(message);

    const {playlistId} = this.props;
    getScenePlaylistById(playlistId)
      .then(this.onGetScenePlaylistByIdSuccess)
      .catch(this.onGetScenePlaylistByIdFailure);
  };

  getMinViewDropdownItems = () => {
    const {t} = this.props;
    return [
      <div onClick={this.editPlaylist}>
        <Icon type='edit'/>
        {t('ScenePlaylistDetails.editPlaylist')}
      </div>,
      <div onClick={this.showDeletePlaylistModal}>
        <Icon type='delete'/>
        {t('ScenePlaylistDetails.deletePlaylist')}
      </div>
    ];
  };

  getTabs = () => {
    const {t} = this.props;
    const {scenes_in_playlist} = this.state.data;
    let sceneTab = {};

    if (scenes_in_playlist && scenes_in_playlist.length !== 0) {
      sceneTab = {
        title: t('ScenePlaylistDetails.scenes'),
        view: (
          <ScenesPlaylistScenesTab
            detailsData={this.state.data}
            onSceneDelete={this.onSceneDelete}
          />
        )
      };
    }

    return [{
      title: t('ScenePlaylistDetails.details'),
      view: (
        <ScenePlaylistDetailsTab
          detailsData={this.state.data}
        />
      )
    }, sceneTab];
  };

  onDeletePlaylistFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('ScenePlaylistDetails.deletePlaylistFailed')
    });
  };

  onDeletePlaylistSuccess = () => {
    this.props.playlistDeleted(this.state.data.id);
    showScenesPlaylist();

    displaySuccessNotification({
      duration: 3,
      message: this.props.t('ScenePlaylistDetails.deletePlaylistSuccess')
    });
  };

  onEditPlaylist = data => {
    this.setState(prevState => {
      const newData = cloneDeep(prevState.data);
      Object.assign(newData, data);
      return {
        data: newData
      };
    });

    this.props.playlistChanged(this.state.data.id);
  };

  onGetScenePlaylistByIdFailure = error => {
    this.setLoading('');
    LogController.logError(error);
  };

  onGetScenePlaylistByIdSuccess = response => {
    const {data} = response.data;
    const state = {};

    if (this.sceneDeleting && data.scenes_in_playlist && data.scenes_in_playlist.length === 0) {
      state.activeTab = '1';
    }

    Object.assign(state, {
      data: data,
      dataLoaded: true,
      loadingMsg: ''
    });

    this.setState(state);

    this.sceneDeleting = false;
    const {playlistId} = this.props;
    this.props.onPlaylistDetailsObtained(playlistId, data.name);
  };

  onSceneDelete = () => {
    this.sceneDeleting = true;
    this.fetchPlaylistById();
    this.props.playlistChanged(this.state.data.id);
  };

  onScenesListUpdate = () => {
    this.fetchPlaylistById();
    this.props.fetchScenePlaylist();
  };

  renderFooter = () => {
    return (
      <ScenePlaylistDetailsFooter
        deletePlaylist={this.showDeletePlaylistModal}
        editPlaylist={this.editPlaylist}
        showAddScenesModal={this.showAddScenesModal}
      />
    );
  };

  showAddScenesModal = () => {
    const modal = (
      <AddScenesModal
        playlistId={this.state.data.id}
        onScenesListUpdate={this.onScenesListUpdate}
      />
    );
    ModalController.showModal(modal);
  };

  setLoading = (loadingMsg) => {
    this.setState({
      loadingMsg: loadingMsg
    });
  };

  showDeletePlaylistModal = () => {
    const {t} = this.props;
    const modal = (
      <ConfirmationModal
        confirm={this.deletePlaylist}
        title={t('ScenePlaylistDetails.confirmDelete')}
        message={t('ScenePlaylistDetails.deleteQuestion')}
        yesBtnText={t('ScenePlaylistDetails.deleteBtn')}
        noBtnText={t('ScenePlaylistDetails.cancelBtn')}
      />
    );
    ModalController.showModal(modal);
  };

  render() {
    const {t} = this.props;
    const {data} = this.state;

    return (
      <LoadingWrapper
        className='ScenePlaylistDetails'
        dataLoaded={this.state.dataLoaded}
        isLoading={!!this.state.loadingMsg}
        loadingMsg={this.state.loadingMsg}
      >
        <BaseHeader
          title={t('ScenePlaylistDetails.title', {name: data.name || ''})}
          items={this.getMinViewDropdownItems()}
        />
        <div className="ScenePlaylistDetails-body">
          <TabsComponent
            activeKey={this.state.activeTab}
            onChange={this.changeTab}
          >
            {this.getTabs()}
          </TabsComponent>
        </div>
        {this.renderFooter()}
      </LoadingWrapper>
    );
  };
};

export default withTranslation()(ScenePlaylistDetails);