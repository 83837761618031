import {ROUTES} from "../../../routes";
import {MAIN_DASHBOARD_VIEW_ROUTES} from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";

/**
 * Returns themes view route
 *
 * @returns {string}
 */
export const getThemesRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.THEMES}`;
};

/**
 * Returns theme details route
 *
 * @param {string|number} themeId
 *
 * @returns {string}
 */
export const getThemeDetailsRoute = themeId => {
  return `${getThemesRoute()}/${themeId}`;
};

/**
 * Returns theme movies route
 *
 * @param {string|number} themeId
 *
 * @returns {string}
 */
export const getThemeMoviesRoute = themeId => {
  return `${getThemesRoute()}/${themeId}/movies`;
};