import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Input, Select } from 'antd';
import PropTypes from 'prop-types';

import { editManufacturer } from '../../../../../../../../../../services/manufacturers-service/manufacturers.service';
import { getLicensors } from "../../../../../../../../../../services/licensors-service/licensors.service";
import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../services/notification-service/notification.service';

import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import './EditManufacturerModal.scss';
import BaseLoadMoreSelect from '../../../../../../../../../../components/BaseLoadMoreSelect/BaseLoadMoreSelect';

class EditManufacturerModal extends Component {

  isUnMounted = false;

  modalRef = React.createRef();

  state = {
    licensor: this.props.licensor || '',
    licensors: [],
    name: this.props.manufacturerName
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  getActions = () => {
    const { t } = this.props;
    return [
      <Button key='cancelBtn' onClick={this.onCancel.bind(this)}>
        {t('EditManufacturerModal.cancel')}
      </Button>,
      <Button
        key='saveChangesBtn'
        disabled={!this.hasDataChanged()}
        onClick={this.onSaveChanges.bind(this)}
      >
        {t('EditManufacturerModal.saveChanges')}
      </Button>
    ];
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getModalTitle = () => {
    return this.props.t('EditManufacturerModal.editManufacturer', {
      manufacturerName: this.props.manufacturerName
    });
  };

  hasDataChanged = () => {
    return this.state.name !== this.props.manufacturerName || JSON.stringify(this.state.licensor) !== JSON.stringify(this.props.licensor);
  };

  loadLicensors = (response) => {
    if (!this.isUnMounted) {
      this.setState({
        licensors: response.data.data.licensors
      });
    }
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChangeName = event => {
    this.setState({
      name: event.target.value
    });
  };

  onLicensorChange = (item) => {
    this.setState({
      licensor: {
        id: item.id,
        name: item.name
      }
    });
  };

  onManufacturerEditFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditManufacturerModal.editManufacturerFailure')
    });
  }

  onManufacturerEditSuccess = data => {
    this.getModal().closeModal();

    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditManufacturerModal.editManufacturerSuccess')
    });

    this.props.onManufacturerEdit(data);
  };

  onSaveChanges = () => {
    const message = this.props.t('EditManufacturerModal.savingMsg');
    this.getModal().setLoading(message);

    const { name, licensor } = this.state;
    const { manufacturerId } = this.props;
    const updateData = { name, licensor: licensor };
    const postData = { name, licensors_id: licensor.id };

    editManufacturer(manufacturerId, postData)
      .then(this.onManufacturerEditSuccess.bind(this, updateData))
      .catch(this.onManufacturerEditFailure);
  };

  renderLicensorsOptions = (item) => {
    return (
      <Select.Option
        key={`licensor-${item.id}`}
        value={item.name}
        onClick={this.onLicensorChange.bind(this, item)}
      >
        <div className="LicensorDropdownItemWrapper">
          <span className="LicensorDropdownItemText">
            {item.name}
          </span>
        </div>
      </Select.Option>
    )
  };

  render() {
    const { t } = this.props;
    const { name = '', id = '' } = this.state.licensor;
    return (
      <ModalDialog
        title={this.getModalTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='EditManufacturerModal'>
          <div className='EditManufacturerModal-inner'>
            <span className='EditManufacturerModal-inner-label'>
              {t('EditManufacturerModal.name')}:
            </span>
            <Input
              onChange={this.onChangeName}
              value={this.state.name}
            />
          </div>
          <div className='EditManufacturerModal-inner'>
            <span className='EditManufacturerModal-inner-label'>
              {t('EditManufacturerModal.licensor')}:
            </span>
            <BaseLoadMoreSelect
              onSelect={this.onLicensorChange.bind(this)}
              getData={getLicensors}
              idIdentifier="id"
              name="licensors"
              searchNotAvailable={true}
              selectedValue={[`${id} ${name ? `- ${name}` : ''}`]}
              fullValue={true} />
          </div>
        </div>
      </ModalDialog>
    )
  }
}

EditManufacturerModal.propTypes = {
  licensor: PropTypes.object,
  manufacturerId: PropTypes.number.isRequired,
  manufacturerName: PropTypes.string,
  onManufacturerEdit: PropTypes.func.isRequired
};

export default withTranslation()(EditManufacturerModal);