import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Checkbox, Input} from "antd";
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {getMoviesByStudioId} from "../../../../../../../../../../services/movies-service/movies.service";

import {LogController} from "../../../../../../../../../../controllers/log-controller/log.controller";

import './SelectMoviesModal.scss';

/* istanbul ignore file */
class SelectMoviesModal extends Component {

  filterDebounceInterval = 300;

  filterTimeoutId;

  modalRef = React.createRef();

  state = {
    movieName: '',
    movies: [],
    page: 1,
    pagesCount: 1,
    selectedMovies: {}
  };

  constructor(props) {
    super(props);
    this.state.selectedMovies = cloneDeep(props.selectedMovies);
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  filterMovies = () => {
    this.filterMoviesByName();
  };

  filterMoviesByName = () => {
    const {studioId} = this.props;
    const {movieName, page} = this.state;
    const filters = [`search=${movieName}`];
    getMoviesByStudioId(studioId, page, filters)
      .then(this.onFilterMoviesByNameSuccess)
      .catch(this.onRequestError);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.closeModal}>
          {t(`SelectMoviesModal.cancel`)}
        </Button>
        <Button onClick={this.onSelectMovies}>
          {t('SelectMoviesModal.selectMovies')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('SelectMoviesModal.title');
  };

  loadMoreMovies = () => {
    this.setState(prevState => {
      const {page} = prevState;
      return {
        page: page + 1
      };
    }, this.filterMoviesByName);
  };

  movieMap = ({title, movieId}) => {
    const checked = !!this.state.selectedMovies[movieId];
    const classes = ['MovieItem'];
    if (checked) {
      classes.push('Checked');
    }
    return (
      <div key={movieId}
           className={classes.join(' ')}
           onClick={this.setMovieSelected.bind(this, movieId, title)}>
        <div className="MovieTitle">
          {title}
        </div>
        <Checkbox checked={checked}/>
      </div>
    );
  };

  onFilterMoviesByNameSuccess = (response) => {
    const {movies, pagination} = response.data.data;
    const {current_page, last_page: pagesCount} = pagination;
    this.setState(prevState => {
      let moviesData = current_page > 1 ? prevState.movies : [];
      moviesData = moviesData.concat(movies);
      return {
        movies: moviesData,
        page: current_page,
        pagesCount
      };
    });
  };

  onMovieNameChange = (event) => {
    const {value} = event.target;
    this.setState({
      movieName: value,
      page: 1
    });
    this.setFilterDelay();
  };

  onRequestError = (error) => {
    LogController.logError(error);
  };

  onSelectMovies = () => {
    const {propertyId, studioId} = this.props;
    this.props.onSelectMovies(propertyId, studioId, cloneDeep(this.state.selectedMovies));
    this.getModal().closeModal();
  };

  renderMovies = () => {
    return this.state.movies.map(this.movieMap);
  };

  renderMoviesLoadMore = () => {
    let view = null;
    const {t} = this.props;
    const {page, pagesCount} = this.state;
    if (page !== pagesCount) {
      view = (
        <Button onClick={this.loadMoreMovies}>
          {t('SelectMoviesModal.loadMoreMovies')}
        </Button>
      );
    }

    return view;
  };

  setFilterDelay = () => {
    if (this.filterTimeoutId) {
      clearTimeout(this.filterTimeoutId);
    }
    this.filterTimeoutId = setTimeout(this.filterMovies, this.filterDebounceInterval);
  };

  setMovieSelected = (movieId, title) => {
    this.setState(prevState => {
      const selectedMovies = Object.assign({}, prevState.selectedMovies);
      const checked = !selectedMovies[movieId];
      if (checked) {
        selectedMovies[movieId] = {id: movieId, title};
      } else {
        delete selectedMovies[movieId];
      }
      return {
        selectedMovies
      };
    });
  };

  render() {
    const {t} = this.props;
    const {movieName} = this.state;
    return (
      <ModalDialog actions={this.getActions()}
                   title={this.getTitle()}
                   className="SelectMoviesModal"
                   forwardedRef={this.modalRef}>
        <div className="SelectMoviesModal-inner">
          <div className="FilterWrapper">
            <div className="Label">{t('SelectMoviesModal.search')}:</div>
            <Input value={movieName}
                   autoFocus
                   onChange={this.onMovieNameChange}/>
          </div>
          <div className="MoviesList">
            {this.renderMovies()}
          </div>
          {this.renderMoviesLoadMore()}
        </div>
      </ModalDialog>
    );
  }
}

SelectMoviesModal.propTypes = {
  onSelectMovies: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  selectedMovies: PropTypes.object.isRequired,
  studioId: PropTypes.number.isRequired
};

export default withTranslation()(SelectMoviesModal);
