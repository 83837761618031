import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'antd';

import './ScenePlaylistDetailsFooter.scss';

class ScenePlaylistDetailsFooter extends Component {
  render() {
    const {t} = this.props;
    return (
      <div className='ScenePlaylistDetailsFooter'>
        <div className='ScenePlaylistDetailsFooter-inner'>
          <Button onClick={this.props.deletePlaylist}>
            <Icon type='delete' />
            {t('ScenePlaylistDetailsFooter.deletePlaylist')}
          </Button>
          <Button onClick={this.props.editPlaylist}>
            <Icon type='edit' />
            {t('ScenePlaylistDetailsFooter.editPlaylist')}
          </Button>
          <Button onClick={this.props.showAddScenesModal}>
            <Icon type='file-add' />
            {t('ScenePlaylistDetailsFooter.addScenes')}
          </Button>
        </div>
      </div>
    );
  };
}

export default withTranslation()(ScenePlaylistDetailsFooter);