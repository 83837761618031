import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import SceneMainList from './SceneMainList/SceneMainList';

import './ScenePlaylist.scss';
import PlaylistFooter from '../../PlaylistFooter/PlaylistFooter';

class ScenePlaylist extends Component {

  calculateTableScroll = () => {
    const {contentHeight} = this.props;

    // Remove header height, footer height
    return contentHeight - 52 - 54;
  };

  render() {
    const {onPageChange, page, pageCount, perPage, total} = this.props;
    return (
      <div className="ScenePlaylist">
        <SceneMainList
          data={this.props.playlistData}
          scroll={this.calculateTableScroll()}
          deletePlaylist={this.props.deletePlaylist}
          onPageChange={onPageChange}
          page={page}
          pageCount={pageCount}
          perPage={perPage}
          total={total}/>
          <PlaylistFooter 
            currentPage={page}
            pageCount={pageCount}
            pageSize={perPage}
            recordCount={total}
            onChange={onPageChange}/>
      </div>
    );
  }
}

ScenePlaylist.propTypes = {
  category: PropTypes.string.isRequired,
  contentHeight: PropTypes.number,
  playlistData: PropTypes.array.isRequired,
  deletePlaylist: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default withTranslation()(ScenePlaylist);
