import React, { Component } from 'react';
import { Location, Link } from "@reach/router";
import { Layout, Menu, Icon } from 'antd';
import PropTypes from 'prop-types';

import { MainDashboardComController } from "../../../../main-dashboard-comm.controller";

import './SideMenu.scss';

class SideMenu extends Component {

  state = {
    breakpoint: false,
    route: ''
  };

  getSelectedIndex = (pathname) => {
    return this.props.navigationItems.findIndex(this.matchByLinkPath.bind(null, pathname));
  };

  getSiderAdditionalProps = () => {
    return {
      collapsed: this.props.collapsed,
      collapsible: true,
      collapsedWidth: 0
    };
  };

  matchByLinkPath = (pathName, item) => {
    return pathName.indexOf(item.link) !== -1;
  };

  onItemClick = (data) => {
    this.onItemSelected(data);
    MainDashboardComController.toggleSideMenu();
  };

  onItemSelected = (data) => {
    this.props.navigationItems[data.key].navigate();
  };

  renderSideMenu = ({ location }) => {
    const selectedId = this.getSelectedIndex(location.pathname);
    const additionalProps = this.getSiderAdditionalProps();
    return (
      <Layout.Sider className='MainDashboardSideMenu' {...additionalProps}>
        <Menu
          mode='inline'
          selectedKeys={[`${selectedId}`]}
          onSelect={this.onItemSelected.bind(this)}
          onClick={this.onItemClick.bind(this)}
        >
          {this.props.navigationItems.map((item, index) => {
            return (
              <Menu.Item key={index} link={item.link}>
                <Link to={item.link}>
                  <Icon type={item.icon} theme={item.icon !== 'key' ? 'filled' : null} />
                  {item.name}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Layout.Sider>
    );
  };

  setBreakpoint = (breakpoint) => {
    this.setState({
      breakpoint: breakpoint
    });
  };

  render() {
    return (
      <Location>
        {this.renderSideMenu}
      </Location>
    );
  }
}

SideMenu.propTypes = {
  navigationItems: PropTypes.array.isRequired
};

export default SideMenu;
