import {navigate} from "@reach/router";

import {
  getLicensorsRoute,
  getLicensorDetailsRoute,
  getLicensorMoviesRoute,
  getLicensorMovieDetailsRoute
} from "./licensors-navigation-routes.service";


/**
 * Navigates to licensors view
 *
 * @returns {Promise<any>}
 */
export const showLicensors = () => {
  return navigate(getLicensorsRoute());
};

/**
 * Navigates to licensor details view
 *
 * @param {number|string} licensorId
 *
 * @returns {Promise<any>}
 */
export const showLicensorDetails = licensorId => {
  return navigate(getLicensorDetailsRoute(licensorId));
};

/**
 * Navigates to licensors movies view
 *
 * @param {number|string} licensorId
 *
 * @returns {Promise<any>}
 */
export const showLicensorMovies = licensorId => {
  return navigate(getLicensorMoviesRoute(licensorId));
};

/**
 * Navigates to licensor movie details view
 *
 * @param {number|string} licensorId
 * @param {number|string} movieId
 *
 * @returns {Promise<any>}
 */
export const showLicensorMovieDetails = (licensorId, movieId) => {
  return navigate(getLicensorMovieDetailsRoute(licensorId, movieId));
};