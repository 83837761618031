import React, { Component } from 'react';
import { List } from "antd";
import PropTypes from 'prop-types';

import MovieScene from "./MovieScene/MovieScene";

import './MovieDetailsScenesTab.scss'

/* istanbul ignore file */
class MovieDetailsScenesTab extends Component {

  /**
   * Returns MovieScene element for render
   *
   * @param {Object} scene
   * @param {number} index
   *
   * @returns {JSX.Element}
   */
  renderScene = (scene, index) => {
    const { scenes, scenesAreEpisodes, viewingMovieId, properties } = this.props;
    return (
      <MovieScene key={`scene-${scene.id}`}
        data={scene}
        index={index + 1}
        sceneCount={scenes.length}
        scenesAreEpisodes={scenesAreEpisodes}
        viewingMovieId={viewingMovieId}
        properties={properties}
      />
    );
  };

  render() {
    const { scenes } = this.props;
    let view = null;
    if (scenes) {
      view = (
        <List dataSource={scenes} renderItem={this.renderScene} />
      );
    }
    return (
      <div className="MovieDetailsScenesTab">
        {view}
      </div>
    );
  }
}

MovieDetailsScenesTab.propTypes = {
  scenes: PropTypes.array,
  viewingMovieId: PropTypes.string,
  properties: PropTypes.array
};

export default MovieDetailsScenesTab;
