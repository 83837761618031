
import { Button, Icon, Input } from 'antd';
import { isFunction } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ModalDialog } from '../../../../../../../../../../../components/modal/ModalDialog/ModalDialog';
import { recreateVideoSample } from '../../../../../../../../../../../services/movies-service/movies.service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../../../services/notification-service/notification.service';
import './SampleVideoConfiguration.scss';

const DEFAULT_DURATION = 6;

class SampleVideoConfiguration extends Component {

    state = {
        samples: [{
            duration: DEFAULT_DURATION,
            percentage_start: 20
        }, {
            duration: DEFAULT_DURATION,
            percentage_start: 50
        }, {
            duration: DEFAULT_DURATION,
            percentage_start: 80
        }]
    };

    modalRef = React.createRef();

    getModal = () => {
        return this.modalRef.current;
    };

    closeModal = () => {
        this.getModal().closeModal()
    };

    recreateVideoSampleSuccess = (response) => {
        const { fetchData } = this.props;
        displaySuccessNotification({
            duration: 3,
            message: this.props.t("SampleVideoConfiguration.success"),
        });
        if (isFunction(fetchData)) {
            fetchData(false);
        }
    };

    recreateVideoSampleError = (err) => {
        displayErrorNotification({
            duration: 3,
            message: this.props.t("SampleVideoConfiguration.fail"),
        });
    };

    save = () => {
        const { samples } = this.state;
        const { sceneId, setLoading } = this.props;
        const message = this.props.t('SampleVideoConfiguration.loadingPleaseWait');
        this.closeModal();
        if (isFunction(setLoading)) {
            setLoading(message);
        }
        recreateVideoSample(sceneId, samples)
            .then(this.recreateVideoSampleSuccess)
            .catch(this.recreateVideoSampleError);
    };

    getActions = () => {
        const { t } = this.props;
        return (
            <div className='SampleVideoConfiguration-actions'>
                <Button
                    onClick={this.addSample}
                >
                    {t('SampleVideoConfiguration.add')}
                </Button>
                <div className='Section'>
                    <Button
                        onClick={() => this.closeModal()}
                    >
                        {t('SampleVideoConfiguration.cancel')}
                    </Button>
                    <Button
                        type='primary'
                        onClick={() => this.save()}
                    >
                        {t('SampleVideoConfiguration.save')}
                    </Button>
                </div>
            </div>
        );
    };

    onInputChange = (event) => {
        const { samples } = this.state;
        const { value, name } = event.target;
        const [property, index] = name.split('-');
        samples[Number(index)][property] = Number(value);
        this.setState({
            samples: [...samples]
        });
    };

    renderConfig = (sample, index) => {
        return (
            <div className='Sample' key={index}>
                <div className={'Index'}>{index + 1}.</div>
                <div className='Percentage'>
                    <label>Start from Percentage:</label>
                    <Input value={sample.percentage_start}
                        step={1}
                        min={0}
                        max={100}
                        type={'number'}
                        name={`percentage_start-${index}`}
                        onChange={this.onInputChange}
                    />
                </div>
                <div className='Duration'>
                    <label>Duration (seconds):</label>
                    <Input value={sample.duration}
                        step={1}
                        min={1}
                        max={10}
                        type={'number'}
                        name={`duration-${index}`}
                        onChange={this.onInputChange}
                    />
                </div>
                <div className={'RemoveBtn'}>
                    <Icon type="close" onClick={() => this.remove(index)} />
                </div>
            </div>
        );
    };

    remove = (index) => {
        let { samples } = this.state;
        samples.splice(index, 1);
        this.setState({
            samples: [...samples]
        });
    };

    addSample = () => {
        const { samples } = this.state;
        this.setState({
            samples: [...samples, {
                duration: DEFAULT_DURATION,
                percentage_start: 0
            }]
        });
    };

    render() {
        const { title } = this.props;
        const { samples } = this.state;
        return (
            <ModalDialog
                title={title}
                actions={this.getActions()}
                ref={this.modalRef}
                forwardedRef={this.modalRef}
            >
                <div className='SampleVideoConfiguration'>
                    {samples.map(this.renderConfig)}
                </div>
            </ModalDialog>
        );
    }
}
export default withTranslation()(SampleVideoConfiguration);