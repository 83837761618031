import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Input } from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import './BrowseConfigurationAddNewItemModal.scss';

/* istanbul ignore file */
class BrowseConfigurationAddNewItemModal extends Component {

  displayTextRef = React.createRef();

  modalRef = React.createRef();

  state = {
    link: '',
    openInNewWindow: false,
    text: '',
    loggedIn: true,
    loggedOut: true
  };

  constructor(props) {
    super(props);
    const { link, openInNewWindow, text, loggedIn, loggedOut } = props;
    Object.assign(this.state, {
      link,
      openInNewWindow,
      text,
      loggedIn: loggedIn === undefined ? true : loggedIn,
      loggedOut: loggedOut === undefined ? true : loggedOut
    });
  }

  componentDidMount() {
    this.focusDisplayText();
  }

  addItem = () => {
    const { id, onItemAdd } = this.props;
    const { link, openInNewWindow, text, loggedIn, loggedOut } = this.state;
    onItemAdd({ link, openInNewWindow, text, loggedIn, loggedOut }, id);
    this.closeModal();
  };

  addNewItemIfDataValid = (event) => {
    if (event.key === 'Enter' && this.isDataValid()) {
      this.addItem();
    }
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  focusDisplayText = () => {
    this.displayTextRef.current.focus();
  };

  getActions = () => {
    const { id, t } = this.props;
    const key = id ? 'saveItem' : 'addItem';
    return [
      <Button onClick={this.closeModal}
        key="cancelBtn">
        {t('BrowseConfigurationAddNewItemModal.cancel')}
      </Button>,
      <Button onClick={this.addItem}
        disabled={!this.isDataValid()}
        key="addItemBtn">
        {t(`BrowseConfigurationAddNewItemModal.${key}`)}
      </Button>
    ];
  };

  getModal = () => this.modalRef.current;

  getTitle = () => {
    const { id } = this.props;
    const key = id ? 'editItemTitle' : 'addItemTitle';
    return this.props.t(`BrowseConfigurationAddNewItemModal.${key}`);
  }

  isDataValid = () => {
    const { link, text } = this.state;
    return link && text;
  };

  onChange = (key, event) => {
    const { value } = event.target;
    this.setState({
      [key]: value
    });
  };

  onCheckboxChange = (key, event) => {
    const { checked } = event.target;
    this.setState({
      [key]: checked
    });
  };

  renderInputField = (label, key, ref) => {
    const value = this.state[key];
    return (
      <div className="InputField">
        <div className="Label">{label}:</div>
        <Input ref={ref}
          onChange={this.onChange.bind(this, key)}
          value={value}
          onKeyUp={this.addNewItemIfDataValid} />
      </div>
    );
  };

  render() {
    const { openInNewWindow, loggedIn, loggedOut } = this.state;
    const { t } = this.props;
    return (
      <ModalDialog className="BrowseConfigurationAddNewItemModal"
        forwardedRef={this.modalRef}
        actions={this.getActions()}
        title={this.getTitle()}>
        <div className="BrowseConfigurationAddNewItemModal-inner">
          {this.renderInputField(t('BrowseConfigurationAddNewItemModal.text'), 'text', this.displayTextRef)}
          {this.renderInputField(t('BrowseConfigurationAddNewItemModal.link'), 'link')}
          <Checkbox checked={openInNewWindow}
            onChange={this.onCheckboxChange.bind(this, 'openInNewWindow')}
          >
            {t('BrowseConfigurationAddNewItemModal.openInNewWindow')}
          </Checkbox>
          <Checkbox checked={loggedIn}
            onChange={this.onCheckboxChange.bind(this, 'loggedIn')}
          >
            {t('BrowseConfigurationAddNewItemModal.loggedIn')}
          </Checkbox>
          <Checkbox checked={loggedOut}
            onChange={this.onCheckboxChange.bind(this, 'loggedOut')}
          >
            {t('BrowseConfigurationAddNewItemModal.loggedOut')}
          </Checkbox>
        </div>
      </ModalDialog>
    );
  }
}

BrowseConfigurationAddNewItemModal.propTypes = {
  id: PropTypes.number,
  link: PropTypes.string,
  onItemAdd: PropTypes.func,
  loggedIn: PropTypes.bool,
  loggedOut: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
  text: PropTypes.string
};

export default withTranslation()(BrowseConfigurationAddNewItemModal);
