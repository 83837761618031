import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button } from "antd";
import { cloneDeep, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import SingleCustodianOfRecords from "./SingleCustodianOfRecords/SingleCustodianOfRecords";
import TabsComponent from "../../../../../../../../../../components/TabsComponent/TabsComponent";

import { editMovie2257, getMovieById } from "../../../../../../../../../../services/movies-service/movies.service";
import { displaySuccessNotification } from "../../../../../../../../../../services/notification-service/notification.service";

import { LogController } from "../../../../../../../../../../controllers/log-controller/log.controller";

import './EditCustodianOfRecordsModal.scss';

class EditCustodianOfRecordsModal extends Component {

  modalRef = React.createRef();

  state = {
    activeCustodianView: '1',
    data: []
  };

  componentDidMount() {
    this.fetchMovieDetails();
  }

  clearLoading = () => {
    const modal = this.getModal();
    if (modal) {
      modal.clearLoading();
    }
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  fetchMovieDetails = () => {
    const { movieId, t } = this.props;
    const message = t('EditCustodianOfRecordsModal.loadingDataMsg');
    this.setLoading(message);
    getMovieById(movieId)
      .then(this.loadData)
      .catch(this.onRequestFailure);
  };

  getActions = () => {
    const { t } = this.props;
    return [
      <Button key="cancelBtn"
        onClick={this.closeModal}>
        {t('EditCustodianOfRecordsModal.cancel')}
      </Button>,
      <Button key="saveChangesBtn"
        onClick={this.saveChanges}>
        {t('EditCustodianOfRecordsModal.saveChanges')}
      </Button>
    ];
  };

  getChangedCustodians = () => {
    const { custodiansOfRecords: initialCustodians } = this.startingData;
    const { data: custodiansOfRecords } = this.state;
    const changedCustodians = [];
    let item = initialCustodians?.length;
    let custodian1;
    let custodian2;
    while (item) {
      item--;
      custodian1 = initialCustodians[item];
      custodian2 = custodiansOfRecords[item];
      if (!this.isEqual(custodian1, custodian2)) {
        changedCustodians.push(custodian2);
      }
    }

    return changedCustodians;
  };

  getCustodiansUpdate = () => {
    const promises = [];
    const changedCustodians = this.getChangedCustodians();
    if (changedCustodians.length) {
      promises.push(...changedCustodians.map(this.mapCustodianDataForPost));
    }

    return promises;
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('EditCustodianOfRecordsModal.title');
  };

  isEqual = (obj1, obj2) => {
    return isEqual(obj1, obj2);
  };

  loadData = (response) => {
    let { movie2257s } = response.data.data;
    if (!movie2257s.length) {
      movie2257s = [{}];
    }
    this.clearLoading();
    this.startingData = cloneDeep(movie2257s);
    this.setState({
      data: movie2257s
    });
  };

  mapCustodianDataForPost = (data) => {
    return editMovie2257(data);
  };

  onActiveCustodianViewChange = (activeCustodianView) => {
    this.setState({ activeCustodianView });
  };

  onDataChange = (index, data) => {
    this.setState(prevState => {
      const finalData = cloneDeep(prevState.data);
      finalData[index] = data;
      return { data: finalData };
    })
  };

  onRequestFailure = (error) => {
    this.clearLoading();
    LogController.logError(error);
  };

  onSaveChangesSuccess = () => {
    this.clearLoading();
    const { onSaveChanges, t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t('EditCustodianOfRecordsModal.saveSuccess')
    });
    if (onSaveChanges) {
      onSaveChanges();
    }
    this.closeModal();
  };

  renderCustodianTab = (data, index) => {
    return {
      title: data.name,
      view: (
        <SingleCustodianOfRecords data={data}
          index={index}
          onDataChange={this.onDataChange} />
      )
    }
  };

  saveChanges = () => {
    const promises = this.getCustodiansUpdate();
    if (promises.length) {
      this.setLoading();
      Promise.all(promises)
        .then(this.onSaveChangesSuccess)
        .catch(this.onRequestFailure);
    } else {
      this.closeModal();
    }
  };

  setLoading = (message) => {
    const modal = this.getModal();
    if (modal) {
      modal.setLoading(message);
    }
  };

  render() {
    const { activeCustodianView, data } = this.state;
    return (
      <ModalDialog className="EditCustodianOfRecordsModal"
        title={this.getTitle()}
        forwardedRef={this.modalRef}
        actions={this.getActions()}>
        <div className="EditCustodianOfRecordsModal-inner">
          <TabsComponent activeKey={activeCustodianView}
            onChange={this.onActiveCustodianViewChange}>
            {data.map(this.renderCustodianTab)}
          </TabsComponent>
        </div>
      </ModalDialog>
    );
  }
}

EditCustodianOfRecordsModal.propTypes = {
  movieId: PropTypes.number.isRequired,
  movieName: PropTypes.string.isRequired,
  onSaveChanges: PropTypes.func
};

export default withTranslation()(EditCustodianOfRecordsModal);
