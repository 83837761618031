import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Divider, Select, Spin } from "antd";
import PropTypes from 'prop-types';

import { getMovies } from "../../../../../../../../../../../../../../services/movies-service/movies.service";

import { LogController } from "../../../../../../../../../../../../../../controllers/log-controller/log.controller";

import './PlaylistMovieSelect.scss';

class PlaylistMovieSelect extends Component {

  initialLoad = false;

  searchDebounce = 300;

  state = {
    filter: '',
    loading: false,
    movies: [],
    page: 1,
    pageCount: 1,
    selectedValues: []
  };

  searchTimeoutId;

  componentDidMount() {
    this.loadMovies(true);
  }

  canLoadMore = () => {
    const { page, pageCount } = this.state;
    return page < pageCount;
  };

  // getFilters = () => {
  //   const {filter} = this.state;
  //   return filter ? [`search=${filter}`, 'elastic_score=desc'] : [];
  // };

  handleGetMoviesError = error => {
    LogController.logError(error);
    this.setLoading(false);
  };

  loadData = response => {
    const { movies, pagination } = response.data.data;
    this.setState(prevState => {
      const data = this.initialLoad ? movies : prevState.movies.concat(movies);
      return {
        loading: false,
        page: prevState.page + 1,
        movies: data,
        pageCount: pagination.last_page
      };
    });
  };

  loadFilteredMovies = () => {
    this.setState({
      page: 1
    });
    this.loadMovies(true);
  };

  loadMovies = (initialLoad) => {
    const { loading, filter, page } = this.state;
    if (initialLoad || (!loading && this.canLoadMore())) { //Only trigger if we can load a page
      this.initialLoad = initialLoad;
      this.setLoading(true);
      // const filters = this.getFilters();
      const filters = {
        search: filter
      }
      getMovies(page, filters)
        .then(this.loadData)
        .catch(this.handleGetMoviesError);
    }
  };

  movieMap = (movie) => {
    return (
      <Select.Option value={movie.movieId}
        key={movie.movieId}>
        {movie.title}
      </Select.Option>
    );
  };

  onChange = (selectedValues) => {
    this.setState({
      selectedValues: selectedValues
    });
    this.props.onMoviesSelect(selectedValues);
  };

  onSearch = value => {
    this.setState({
      filter: value
    });
    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }
    this.searchTimeoutId = setTimeout(this.loadFilteredMovies, this.searchDebounce);
  };

  preventEventDefault = event => {
    event.preventDefault();
  };

  renderChildren = () => {
    return this.state.movies.map(this.movieMap);
  };

  renderDivider = (canLoadMore) => {
    return canLoadMore ? (
      <Divider className="PlaylistMovieSelect-dropdown-divider" />
    ) : null;
  };

  renderDropdown = menu => {
    const { loading } = this.state;
    const canLoadMore = this.canLoadMore();
    return (
      <div>
        {menu}
        {this.renderDivider(canLoadMore)}
        {loading ? this.renderLoadingIndicator() : this.renderLoadMore(canLoadMore)}
      </div>
    )
  };

  renderLoadingIndicator = () => {
    return (
      <Spin size="small"
        className="PlaylistMovieSelect-spinner" />
    );
  };

  renderLoadMore = (canLoadMore) => {
    const { t } = this.props;
    return canLoadMore ? (
      <div onMouseDown={this.preventEventDefault}
        onClick={this.loadMovies.bind(this, false)}
        className="PlaylistMovieSelect-loadMore">
        {t('PlaylistMovieSelect.loadMore')}
      </div>
    ) : null;
  };

  setLoading = loading => {
    this.setState({
      loading: loading
    });
  };

  render() {
    const { t } = this.props;
    const { selectedValues } = this.state;

    return (
      <div className="PlaylistMovieSelect">
        <div>{t('PlaylistMovieSelect.label')}</div>
        <Select value={selectedValues}
          showSearch
          onSearch={this.onSearch}
          filterOption={false}
          mode='multiple'
          dropdownRender={this.renderDropdown}
          dropdownClassName="PlaylistMovieSelect-dropdown"
          onChange={this.onChange}
        >
          {this.renderChildren()}
        </Select>
      </div>
    );
  }
}

PlaylistMovieSelect.propTypes = {
  onMoviesSelect: PropTypes.func.isRequired
};

export default withTranslation()(PlaylistMovieSelect);