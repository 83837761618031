import React, {Component} from 'react';
import {Tabs} from "antd";
import PropTypes from 'prop-types';

import './TabsComponent.scss';

class TabsComponent extends Component {

  /**
   * Returns tab render JSX element
   *
   * @param {Object} item
   * @param {index} index
   *
   * @returns {JSX.Element}
   */
  renderTab = (item, index) => {
    const title = item ? item.title : '';
    const view = item ? item.view : null;
    return (
      <Tabs.TabPane key={index + 1} tab={title}>
        {view}
      </Tabs.TabPane>
    );
  };

  /**
   * Returns an array of mapped tabs
   *
   * @returns {JSX.Element[]}
   */
  renderTabs = () => {
    return this.props.children.map(this.renderTab);
  };

  render() {
    return (
      <Tabs activeKey={this.props.activeKey}
            onChange={this.props.onChange}
            className="TabsComponent">
        {this.renderTabs()}
      </Tabs>
    );
  }
}

TabsComponent.propTypes = {
  activeKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TabsComponent;
