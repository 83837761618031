import axios from 'axios';

export const urls = {
  clearCache: '/cache/clear'
};

export const clearCache = (propertyId) => {
  return axios.get(`${urls.clearCache}`, {
    params: {
      properties_id: propertyId
    }
  });
};