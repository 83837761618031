import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Router } from "@reach/router";
import { cloneDeep } from "lodash";
import PropTypes from 'prop-types';

import BannerSetBanners from "../BannerSetBanners/BannerSetBanners";
import BannerSetPropertiesMainView from "./BannerSetPropertiesMainView/BannerSetPropertiesMainView";
// import BaseDelayedInput from "../../../../../../../../../../components/BaseDelayedInput/BaseDelayedInput";
import BaseNavigationItem from '../../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem';
import PropertyBannerSetBanners from "../PropertyBannerSetBanners/PropertyBannerSetBanners";

import {
  getBannerSetPropertiesRoute,
  getBannerSetsRoute
} from "../../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";

import {
  getBannerSetById,
} from "../../../../../../../../../../services/banners-service/banners.service";

import { DocumentTitleController } from "../../../../../../../../../../controllers/title-controller/title.controller";
import { LogController } from "../../../../../../../../../../controllers/log-controller/log.controller";

import './BannerSetProperties.scss';

class BannerSetProperties extends Component {

  state = {
    bannerSetName: '',
    defaultSet: undefined,
    propertiesSets: [],
    loading: true
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  fetchData = (refresh) => {
    const { bannerSetId, fetchData } = this.props;
    fetchData(bannerSetId, refresh)
      .then(this.loadData);
  };

  getPropertyIdFromLocation = () => {
    const { location } = this.props;
    const pathName = location.pathname;
    const hastProperties = pathName.includes('/properties/');
    let propertyIdValue;
    let propertyId = '';

    if (hastProperties) {
      propertyIdValue = pathName.split('/');
      propertyId = propertyIdValue[propertyIdValue.length - 2];
    }
    return propertyId;
  };

  getRoutes = () => {
    const { bannerSetId, t } = this.props;
    const { bannerSetName, propertiesSets } = this.state;

    const propertyId = this.getPropertyIdFromLocation();
    const property = propertiesSets.find(({ properties }) => (properties.id).toString() === propertyId);
    const propertyName = property?.properties?.name || '';

    const routes = [{
      url: getBannerSetsRoute(),
      text: t('BannerSetProperties.defaultBannerSets')
    }, {
      url: propertyId ? getBannerSetPropertiesRoute(bannerSetId) : '',
      text: `${bannerSetName} properties`
    }];

    if (propertyId) {
      routes.push({ text: `${propertyName}${bannerSetName} banners` })
    }

    return routes;
  };

  loadData = ({ bannerSetName, defaultSet, propertiesSets, ...rest }) => {
    this.setDocumentTitle(bannerSetName);
    this.setState({
      bannerSetName,
      defaultSet,
      propertiesSets: propertiesSets.sort(this.sortByPropertyName),
      loading: false
    });
  };

  matchPropertyById = (propertyId, { properties: { id } }) => {
    return propertyId === id;
  };

  // onPropertyNameChange = (propertyName) => {
  //   this.setState({ propertyName });
  // };

  onRequestFailure = (error) => {
    this.clearLoading();
    LogController.logError(error);
  };

  // renderSearch = () => {
  //   const { propertyName } = this.state;
  //   return (
  //     <BaseDelayedInput value={propertyName}
  //       onChange={this.onPropertyNameChange}
  //       initialValue={propertyName}
  //       placeholder={"Search by property name"}
  //       allowClear={true} />
  //   );
  // };

  setDocumentTitle = (name) => {
    DocumentTitleController.setDocumentTitle(this.props.t('BannerSetProperties.documentTitle', { name }));
  };

  sortByPropertyName = ({ properties: { name } }, { properties: { name: name1 } }) => {
    let order = 0;
    if (name > name1) {
      order = 1;
    } else if (name < name1) {
      order = -1;
    }

    return order;
  };

  updateAllData = () => {
    this.fetchData(true);
    const { bannerSetId, onDefaultReorderChange } = this.props;
    onDefaultReorderChange(bannerSetId);
  };

  updateProperty = (propertyId, response) => {
    this.setState(prevState => {
      const propertiesSets = cloneDeep(prevState.propertiesSets);
      const index = propertiesSets.findIndex(this.matchPropertyById.bind(this, +propertyId));
      propertiesSets[index].banners = response.data.data.banners;
      return { propertiesSets };
    });
  };

  updatePropertyBanners = (bannerSetId, propertyId) => {
    getBannerSetById(bannerSetId, 0, 'index', 'asc', undefined, propertyId)
      .then(this.updateProperty.bind(this, propertyId))
      .catch(this.onRequestFailure);
  };

  updateState = (propertyId, propertyName) => {
    this.setState({
      propertyName,
      propertyId
    });
    if (!propertyName) {
      this.setDocumentTitle(this.state.bannerSetName);
    }
  };

  render() {
    const { bannerSetId, onUnmount, showSets } = this.props;

    return (
      <div className="BannerSetProperties">
        {!this.state.loading && (
          <BaseNavigationItem routes={this.getRoutes()}>
            {/* {this.renderSearch()} */}
          </BaseNavigationItem>
        )}
        <Router>
          <BannerSetPropertiesMainView path={'/'}
            bannerSetId={bannerSetId}
            onUnmount={onUnmount}
            data={this.state}
            showSets={showSets}
          />
          <PropertyBannerSetBanners
            path={'/:propertyId/banners'}
            updateState={this.updateState}
            onReorderChange={this.updatePropertyBanners}
          />
          <BannerSetBanners path={'/default/banners'}
            onReorderChange={this.updateAllData}
            showBreadcrumbs={this.getPropertyIdFromLocation()} />
        </Router>
      </div>
    );
  }
}

BannerSetProperties.propTypes = {
  bannerSetId: PropTypes.string,
  data: PropTypes.object,
  fetchData: PropTypes.func.isRequired,
  onDataLoad: PropTypes.func,
  onDefaultReorderChange: PropTypes.func.isRequired,
  onUnmount: PropTypes.func
};

export default withTranslation()(BannerSetProperties);
