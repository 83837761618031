import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Checkbox, Input} from "antd";
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {getProperties} from "../../../../../../../../../../services/properties-service/properties.service";
import {LogController} from "../../../../../../../../../../controllers/log-controller/log.controller";

import './SelectPropertiesModal.scss';

/* istanbul ignore file */
class SelectPropertiesModal extends Component {

  filterDebounceTime = 300;

  filterTimeoutId;

  modalRef = React.createRef();

  state = {
    allProperties: [],
    propertyName: '',
    properties: [],
    selectedProperties: {}
  };

  componentDidMount() {
    this.filterProperties();
  }

  constructor(props) {
    super(props);
    this.state.selectedProperties = cloneDeep(props.properties);
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  filterData = () => {
    const {allProperties} = this.state;
    const properties = allProperties.filter(this.matchPropertyByName);
    this.setState({properties});
  };

  filterProperties = () => {
    this.getProperties()
      .then(this.filterData);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.closeModal}>
          {t(`SelectPropertiesModal.cancel`)}
        </Button>
        <Button onClick={this.saveChanges}>
          {t(`SelectPropertiesModal.saveProperties`)}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getProperties = () => {
    return new Promise(this.getPropertiesExecutor);
  };

  getPropertiesExecutor = (resolve, reject) => {
    const {allProperties} = this.state;
    if (allProperties.length) {
      resolve(allProperties);
    } else {
      getProperties()
        .then(this.onGetPropertiesSuccess.bind(this, resolve))
        .catch(this.onGetPropertiesFailure.bind(this, reject));
    }
  };

  getTitle = () => {
    const {t} = this.props;
    return t(`SelectPropertiesModal.selectProperties`);
  };

  matchPropertyByName = ({name}) => {
    const {propertyName} = this.state;
    if (propertyName === '') {
      return true; // initialy show all data
    }
    return name.toLowerCase().indexOf(propertyName.toLowerCase()) !== -1;
  };

  onGetPropertiesFailure = (reject, error) => {
    LogController.logError(error);
    reject();
  };

  onGetPropertiesSuccess = (resolve, response) => {
    const {properties: allProperties} = response.data.data;
    this.setState({allProperties}, resolve);
  };

  onPropertyNameFilterChange = (event) => {
    const {value: propertyName} = event.target;
    this.setState({propertyName});
    if (this.filterTimeoutId) {
      clearTimeout(this.filterTimeoutId);
    }
    this.filterTimeoutId = setTimeout(this.filterProperties, this.filterDebounceTime);
  };

  propertyMap = ({id, name}) => {
    const {selectedProperties} = this.state;
    const checked = !!selectedProperties[id];
    const classes = ['Property'];
    if (checked) {
      classes.push('Checked');
    }
    return (
      <div className={classes.join(' ')}
           key={id}
           onClick={this.setPropertyChecked.bind(this, id, name)}>
        <div className="Name">
          {name}
        </div>
        <Checkbox checked={checked}/>
      </div>
    );
  };

  renderFilteredProperties = () => {
    const {properties} = this.state;
    return properties.map(this.propertyMap);
  };

  saveChanges = () => {
    const {selectedProperties} = this.state;
    const {properties} = this.props;
    const data = {};
    let newSelected = {};
    for (let propertyId in selectedProperties) {
      if (properties[propertyId]) {
        data[propertyId] = properties[propertyId];
      } else {
        data[propertyId] = selectedProperties[propertyId];
        newSelected[propertyId] = data[propertyId];
      }
    }
    this.props.onPropertiesSelected(data, newSelected);
    this.closeModal();
  };

  setPropertyChecked = (id, name) => {
    this.setState(prevState => {
      const selectedProperties = cloneDeep(prevState.selectedProperties);
      const checked = !selectedProperties[id];
      if (checked) {
        selectedProperties[id] = {
          id, name
        };
      } else {
        delete selectedProperties[id];
      }
      return {selectedProperties};
    });
  };

  render() {
    const {t} = this.props;
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}
                   className="SelectPropertiesModal">
        <div className="SelectPropertiesModal-inner">
          <div className="NameFilter">
            <div className="Label">
              {t(`SelectPropertiesModal.search`)}:
            </div>
            <Input value={this.state.propertyName}
                   onChange={this.onPropertyNameFilterChange}
                   autoFocus/>
          </div>
          <div className="PropertiesList">
            {this.renderFilteredProperties()}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

SelectPropertiesModal.propTypes = {
  onPropertiesSelected: PropTypes.func.isRequired,
  properties: PropTypes.object.isRequired
};

export default withTranslation()(SelectPropertiesModal);
