import React from 'react';
import PropTypes from 'prop-types';
import {Spin} from "antd";

import './LoadingIndicator.scss';

const LoadingIndicator = props => {
  return (
    <div className={`LoadingIndicator ${props.className}`}>
      <Spin size="large"/>
      <div
        className="LoadingText">{props.message}<span>.</span><span>.</span><span>.</span>
      </div>
    </div>
  );
};

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default LoadingIndicator;
