import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Radio} from "antd";

import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import ConfigureAdsOnMovieLevelDialog from "./ConfigureAdsOnMovieLevel/MainDialog/MainDialog";
import ConfigureAdsOnPropertyLevelDialog from "./ConfigureAdsOnPropertyLevel/MainDialog";
import ConfigureAdsOnNatsLevelDialog from "./ConfigureAdsOnNatsLevel/MainDialog";

import {ModalController} from "../../../../../../../../../controllers/modal-controller/modal.controller";

import './ConfigureAppAds.scss';

/* istanbul ignore file */
class ConfigureAppAds extends Component {

  state = {
    activeSelection: 1
  };

  modalRef = React.createRef();

  closeModal = () => {
    this.getModal().closeModal();
  };

  displayAdsConfigDialog = () => {
    const { activeSelection } = this.state;
    const adsConfigDialogs = {
      1: this.displayPropertyAdsConfigDialog.bind(this),
      2: this.displayMoviesAdsConfigDialog.bind(this),
      3: this.displayNatsAdsConfigDialog.bind(this)
    }
    adsConfigDialogs[activeSelection]();
  };

  displayMoviesAdsConfigDialog = () => {
    this.closeModal();
    const modal = (
      <ConfigureAdsOnMovieLevelDialog/>
    );
    ModalController.showModal(modal);
  };

  displayPropertyAdsConfigDialog = () => {
    this.closeModal();
    const modal = (
      <ConfigureAdsOnPropertyLevelDialog/>
    );
    ModalController.showModal(modal);
  };

  displayNatsAdsConfigDialog = () => {
    this.closeModal();
    const modal = (
      <ConfigureAdsOnNatsLevelDialog/>
    );
    ModalController.showModal(modal);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.closeModal}>
          {t('ConfigureAppAds.cancel')}
        </Button>
        <Button onClick={this.displayAdsConfigDialog}>
          {t('ConfigureAppAds.continue')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('ConfigureAppAds.title');
  };

  onActiveSelectionChange = (event) => {
    const {value: activeSelection} = event.target;
    this.setState({activeSelection});
  };

  render() {
    const {t} = this.props;
    const {activeSelection} = this.state;
    return (
      <ModalDialog forwardedRef={this.modalRef}
                   title={this.getTitle()}
                   actions={this.getActions()}
                   className="ConfigureAppAds">
        <div className="ConfigureAppAds-inner">
          <div className="Description" dangerouslySetInnerHTML={{__html: t('ConfigureAppAds.description')}}/>
          <Radio.Group value={activeSelection}
                       onChange={this.onActiveSelectionChange}>
            <Radio value={1}>
              {t('ConfigureAppAds.propertyConfiguration')}
            </Radio>
            <Radio value={2}>
              {t('ConfigureAppAds.moviesConfiguration')}
            </Radio>
            <Radio value={3}>
              {t('ConfigureAppAds.natsConfiguration')}
            </Radio>
          </Radio.Group>
        </div>
      </ModalDialog>
    );
  }
}

export default withTranslation()(ConfigureAppAds);
