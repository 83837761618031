import React, { Component } from 'react';
import { compose } from "recompose";
import { cloneDeep } from 'lodash';
import { withTranslation } from "react-i18next";
import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import { getUsersCancelable, toggleEnableStatus } from "../../../../../../../../services/users-service/users.service";

import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../services/notification-service/notification.service';

import { LogController } from "../../../../../../../../controllers/log-controller/log.controller";
import { ModalController } from "../../../../../../../../controllers/modal-controller/modal.controller";

import AddUserModal from "./AddUserModal/AddUserModal";
import UsersMainSection from './UsersMainSection/UsersMainSection';

import './UsersView.scss';
import { exportNatsUsers, getNatsUsersCancelable } from '../../../../../../../../services/nats-service/nats.service';
import { CSVLink } from 'react-csv';

class UsersView extends Component {

  defaultFilters = {};
  filters = [];

  state = {
    currentPage: 1,
    dataLoaded: false,
    loading: false,
    recordCount: 0,
    pageCount: 0,
    pageSize: 0,
    users: []
  };

  componentDidMount() {
    this.fetchUsers(1);
  }

  onExport = () => {
    exportNatsUsers(this.filters)
      .then(this.onExportSuccess)
      .catch(this.onExportFailure);
  };

  onExportFailure = (error) => {
    this.onRequestFailure(error);
    const message = this.props.t('UsersView.exportFailed');
    displayErrorNotification({
      duration: 3,
      message
    });
  };

  onExportSuccess = response => {
    const { t } = this.props;
    displaySuccessNotification({
      duration: 0,
      message: t('UsersView.exportSuccess'),
      description: (
        <CSVLink data={response.data} filename={"nats-users.csv"}>{t('UsersView.clickToDownloadCSV')}</CSVLink>
      )
    });
  };

  fetchUsers = (page) => {
    this.setState({
      loading: true
    });
    const filters = this.getFilters();
    if (this.props.propertyId && this.props.activeUsers) {
      filters.push('property_id=' + this.props.propertyId);
    }
    const getUsersFn = this.props.activeUsers ? getNatsUsersCancelable : getUsersCancelable;
    const { result, controller } = getUsersFn(page, filters);
    if (this.lastRequestController) {
      this.lastRequestController.abort();
    }
    this.lastRequestController = controller;
    result.then(this.loadData)
      .catch(this.onGetUsersFailure);
  };

  getDocumentTitle = () => {
    return this.props.t('UsersView.documentTitle');
  };

  getFilters = () => {
    return this.filters;
  };

  loadData = (response) => {
    let info = Array.isArray(response.data.data) ? this.parseUnFilteredData(response.data) : this.parseFilteredData(response.data.data);
    this.loadUsersData(info);
  };

  loadUsersData = ({ users, pagination }) => {
    this.setState({
      currentPage: pagination.currentPage,
      dataLoaded: true,
      loading: false,
      pageCount: pagination.totalPages,
      pageSize: pagination.perPage,
      recordCount: pagination.total,
      users: users
    });
  };

  matchUserById = (id, user) => {
    return user.id === id;
  };

  onAddUser = () => {
    const modal = (
      <AddUserModal onUserAdded={this.refreshContent} />
    );
    ModalController.showModal(modal);
  };

  onFilterChange = (filters, email, display) => {
    this.defaultFilters = { display: display, email: email };
    this.filters = filters;
    this.fetchUsers(1);
  };

  onGetUsersFailure = (error) => {
    LogController.logError(error);
  };

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    });
    this.fetchUsers(page);
  };

  onSetUserStatusFailure = (enabled, error) => {
    this.setState({
      loading: false
    });
    const { t } = this.props;
    const key = enabled === 1 ? 'enableFail' : 'disableFail';
    displayErrorNotification({
      duration: 3,
      message: t(`UsersView.${key}`)
    });
  };

  onSetUserStatusSuccess = (id, data) => {
    const { enable } = data;

    const newData = {
      enable: data.enable
    };

    const key = enable === 1 ? 'enableSuccess' : 'disableSuccess';
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t(`UsersView.${key}`)
    });
    this.updateUserData(id, newData);
  };

  parseFilteredData = ({ users, pagination }) => {
    return {
      users: users,
      pagination: {
        currentPage: pagination.current_page,
        perPage: pagination.per_page,
        totalPages: pagination.to,
        total: pagination.total
      }
    };
  };

  parseUnFilteredData = ({ data, meta }) => {
    return {
      users: data,
      pagination: meta.pagination
    };
  };

  refreshContent = () => {
    const { currentPage } = this.state;
    this.fetchUsers(currentPage);
  };

  setUserEnabled = (enable, userId) => {
    this.setState({
      loading: true
    });
    const data = {
      enable: enable
    };

    toggleEnableStatus(userId, enable)
      .then(this.onSetUserStatusSuccess.bind(this, userId, data))
      .catch(this.onSetUserStatusFailure.bind(this, enable));
  }

  updateUserData = (id, data) => {
    this.setState({
      loading: false
    });
    const userIndex = this.state.users.findIndex(this.matchUserById.bind(this, id));
    this.setState(prevState => {
      const users = cloneDeep(prevState.users);
      Object.assign(users[userIndex], data);
      return {
        users: users
      };
    });
  };

  render() {
    return (
      <div className='UsersView'>
        <UsersMainSection
          activeUsers={this.props.activeUsers}
          onExport={this.onExport}
          listInfo={this.state}
          onFilterChange={this.onFilterChange}
          contentHeight={this.props.contentHeight}
          onPageChange={this.onPageChange}
          onAddUser={this.onAddUser}
          setUserEnabled={this.setUserEnabled}
          defaultFilters={this.defaultFilters}
          updateUserData={this.updateUserData}
        />
      </div>
    );
  }
}

export default compose(withTranslation(), withDocumentTitleUpdate)(UsersView);
