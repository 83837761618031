import React, { Component } from "react";
import { Router } from "@reach/router";
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import BannerSetProperties from "./BannerSetProperties/BannerSetProperties";
import BannerSetBanners from "./BannerSetBanners/BannerSetBanners";
import DefaultBannerSets from "./DefaultBannerSets/DefaultBannerSets";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";

import {
  getBannerSetById,
  getBannerSetProperties,
  getBannerSets
} from "../../../../../../../../../services/banners-service/banners.service";
import { getProperties } from "../../../../../../../../../services/properties-service/properties.service";

import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";

import './BannerSets.scss';
import {
  getBannerSetPropertiesRoute
} from "../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";

/* istanbul ignore file */
class BannerSets extends Component {

  bannerSetProperties;

  defaultBannerSets;

  state = {
    dataLoaded: false,
    loadingMsg: '',
    banners_sets: [],
    propertiesCount: 0
  };

  componentDidMount() {
    this.fetchDefaultSets();
  }

  clearData = (key) => {
    this[key] = undefined;
  };

  clearLoading = () => {
    this.setState({ loadingMsg: '' });
  };

  fetchBannerSetProperties = (bannerSetId, refresh) => {
    let promise;
    if (!refresh && this.bannerSetProperties) {
      promise = Promise.resolve(cloneDeep(this.bannerSetProperties));
    } else {
      this.bannerSetId = bannerSetId;
      if (this.isPropertiesPath()) {
        const message = this.props.t('BannerSets.loadingBannerSetPropertiesMsg');
        this.setLoading(message);
      }
      const promises = [
        getBannerSetById(bannerSetId, 0, 'index', 'asc'),
        getBannerSets(0),
        getBannerSetProperties(bannerSetId, undefined, 'index', 'asc', 6)
      ];
      promise = Promise.all(promises)
        .then(this.loadPropertiesData)
        .catch(this.onRequestFailure);
    }

    return promise;
  };

  fetchDefaultSets = () => {
    let promise;
    const message = this.props.t('BannerSets.loadingMsg');
    this.setLoading(message);

    // if (this.isDefaultBannerSetsPath()) {
    //   const message = this.props.t('BannerSets.loadingMsg');
    //   this.setLoading(message);
    // }
    const promises = [
      getBannerSets(12, 'index', 'asc', 0),
      getProperties()
    ];
    promise = Promise.all(promises)
      .then(this.loadData)
      .catch(this.onRequestFailure);

    return promise;
  };

  getBannerDefaultSetOrder = () => {
    getBannerSets(6, 'index', 'asc', 0)
      .then(this.setDefaultBannerSets)
      .catch(this.onRequestFailure);
  };

  getDefaultBannerSetOrder = () => {
    getBannerSetById(this.bannerSetId, 1, 'index', 'asc', 6)
      .then(this.setDefaultBannerSetOrder)
      .catch(this.onRequestFailure);
  };

  // isDefaultBannerSetsPath = () => {
  //   const path = getBannerSetsRoute();
  //   const { pathname } = window.location;
  //   return pathname === path;
  // };

  isPropertiesPath = () => {
    const path = getBannerSetPropertiesRoute(this.bannerSetId);
    const { pathname } = window.location;
    return pathname === path;
  };

  loadData = (values) => {
    const sets = values[0].data?.data || [];
    const { properties = [] } = values[1].data?.data || {};

    this.defaultBannerSets = {
      propertiesCount: properties.length,
      sets
    };

    this.setState({
      dataLoaded: true,
      loadingMsg: '',
      banners_sets: sets,
      propertiesCount: properties.length
    });
    return cloneDeep(this.defaultBannerSets);
  };

  loadPropertiesData = (values) => {
    const { data: defaultSet } = values[0].data;
    const { data: sets } = values[1].data;
    const { name } = sets.find(this.matchBannerSetById);
    const { data: propertiesSets } = values[2].data;
    this.bannerSetProperties = {
      bannerSetName: name,
      defaultSet,
      propertiesSets
    };
    this.clearLoading();
    return cloneDeep(this.bannerSetProperties);
  };

  matchBannerSetById = ({ id }) => {
    return +this.bannerSetId === id;
  };

  onRequestFailure = (error) => {
    this.clearLoading();
    LogController.logError(error);
  };

  saveData = (key, data) => {
    this[key] = data;
  };

  setDefaultBannerSets = (response) => {
    this.defaultBannerSets.sets = response.data.data;
  };

  setDefaultBannerSetOrder = (response) => {
    const { sets } = this.defaultBannerSets;
    const index = sets.findIndex(this.matchBannerSetById);
    sets[index].banners = response.data.data.banners;
    this.defaultBannerSets.sets = sets;
  };

  setLoading = (loadingMsg) => {
    this.setState({ loadingMsg });
  };

  render = () => {
    const { dataLoaded, loadingMsg, banners_sets = [], propertiesCount } = this.state;
    return (
      <LoadingWrapper className="BannerSets"
        dataLoaded={dataLoaded}
        isLoading={!!loadingMsg}
        loadingMsg={loadingMsg}>
        <Router>
          <DefaultBannerSets
            showSets={this.props.showSets}
            onToggleBannerSets={this.props.onToggleBannerSets}
            fetchData={this.fetchDefaultSets}
            bannersSets={banners_sets}
            propertiesCount={propertiesCount}
            path={'/*'}
          />
          <BannerSetBanners
            fetchData={this.fetchDefaultSets}
            onReorderChange={this.getBannerDefaultSetOrder}
            path={'/:bannerSetId/banners'}
          />
          <BannerSetProperties
            showSets={true}
            path={'/:bannerSetId/properties/*'}
            fetchData={this.fetchBannerSetProperties}
            onDefaultReorderChange={this.getDefaultBannerSetOrder}
            onUnmount={this.clearData.bind(this, 'bannerSetProperties')} />
        </Router>
      </LoadingWrapper>
    );
  };
}

export default withTranslation()(BannerSets);
