import React from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { List, Button } from "antd";
import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import { renderMoment } from "../../../../../../../../../../services/date-service/date.service";

import { getPayoutPropertyRoute } from "../../../../../../../../../../services/navigation/payouts-navigation/payouts-navigation-routes.service";

import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";
import PayoutsDisplayBaseAbstract from "../../PayoutsDisplayBaseAbstract/PayotsDisplayBaseAbstract";

import './PayoutsByPropertyMainView.scss';

class PayoutsByPropertyMainView extends PayoutsDisplayBaseAbstract {

  compensateForValueIfMissing = (value, valueText) => {
    return value ? valueText : '';
  };

  getColumns = () => {
    const { t } = this.props;
    return [{
      dataIndex: ['properties', 'name'],
      title: t('PayoutsByPropertyMainView.property')
    }, {
      dataIndex: 'status',
      title: 'Status',
      render: this.renderStatus
    }, {
      dataIndex: 'view_time',
      title: t('PayoutsByPropertyMainView.viewTime'),
      render: this.renderTime
    }, {
      dataIndex: 'gross',
      title: t('PayoutsByPropertyMainView.gross'),
      render: this.renderNumber
    }, {
      dataIndex: 'affiliate',
      title: t('PayoutsByPropertyMainView.affiliate'),
      render: this.renderNumber
    }, {
      dataIndex: 'payout',
      title: t('PayoutsByPropertyMainView.payout'),
      render: this.renderNumber
    }, {
      dataIndex: 'updated_at',
      title: t('PayoutsByPropertyMainView.updated'),
      render: renderMoment
    }, {
      title: t('PayoutsByPropertyMainView.action'),
      align: 'center',
      key: 'action',
      render: this.renderActionItem
    }];
  };

  getListCardFooter = (item) => {
    return (
      <div className="PayoutsByProperty-listCard-footer">
        {this.renderActionItem(item)}
      </div>
    );
  };

  getReductionHeight = () => 55 + 42;

  getRowKey = item => item.properties.id;

  onEditPayoutStatus = (propertyId) => {
    const { onEditPayout, activeStartDate, activeEndDate } = this.props;
    onEditPayout(activeStartDate, activeEndDate, propertyId);
  };

  openFinalizePayoutModal = (propertyId) => {
    const { openFinalizePayoutModal, activeStartDate, activeEndDate } = this.props;
    openFinalizePayoutModal(activeStartDate, activeEndDate, propertyId);
  };

  renderActionItem = item => {
    const { properties, status } = item;
    const { t, year, month, } = this.props;
    let actionButtons = null;

    if (status !== 2) {
      actionButtons = (
        <>
          <Button
            className="ActionBtn"
            onClick={this.onEditPayoutStatus.bind(this, properties.id)}
            key="edit">
            {t('PayoutsByProperty.edit')}
          </Button>
          <Button
            className="ActionBtn"
            onClick={this.openFinalizePayoutModal.bind(this, properties.id)}
            key="finalize"
          >
            Finalize
          </Button>
        </>
      );
    }

    return (
      <div className="ActionContainer">
        <LinkButton href={getPayoutPropertyRoute(properties.id, year, month)}>
          {t('PayoutsByPropertyMainView.viewPayoutsByLicensor')}
        </LinkButton>
        {actionButtons}
      </div>
    );
  };

  renderPeriodsListItem = item => {
    const { properties, gross, affiliate, payout, updated, view_time } = item;
    const { t } = this.props;
    const { id, name } = properties;
    return (
      <List.Item key={id}>
        <BaseCard
          title={`${t('PayoutsByPropertyMainView.title', { name: name })}`}
          footer={this.getListCardFooter(item)}>
          <div className='ListCardWrapper'>
            {this.renderListItemField(t('PayoutsByPropertyMainView.property'), name)}
            {this.renderListItemField(t('PayoutsByPropertyMainView.viewTime'), this.renderTime(view_time))}
            {this.renderListItemField(t('PayoutsByPropertyMainView.gross'), this.renderNumber(gross))}
            {this.renderListItemField(t('PayoutsByPropertyMainView.affiliate'), this.renderNumber(affiliate))}
            {this.renderListItemField(t('PayoutsByPropertyMainView.payout'), this.renderNumber(payout))}
            {this.renderListItemField(t('PayoutsByPropertyMainView.updated'), renderMoment(updated))}
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  renderTime = time => {
    const { t } = this.props;
    const days = Math.floor(time / (60 * 60 * 24));
    const hours = Math.floor((time / (60 * 60)) % 24);
    const minutes = Math.floor((time / 60) % 60);

    const daysText = `${days} ${t('PayoutsByMonth.days')}`;
    const hoursText = `${hours} ${t('PayoutsByMonth.hours')}`;
    const minutesText = `${minutes} ${t('PayoutsByMonth.minutes')}`;

    return `${this.compensateForValueIfMissing(days, daysText)} ${this.compensateForValueIfMissing(hours, hoursText)} ${this.compensateForValueIfMissing(minutes, minutesText)}`;
  };

  render() {
    const { properties } = this.props;
    return (
      <div className="PayoutsByPropertyMainView">
        <div className='PayoutsByPropertyMainViewBody'>
          {this.renderTable(properties)}
          {this.renderList(properties, this.renderPeriodsListItem)}
        </div>
      </div>
    );
  }
}

PayoutsByPropertyMainView.propTypes = {
  contentHeight: PropTypes.number,
  properties: PropTypes.array.isRequired,
  activeStartDate: PropTypes.string,
  activeEndDate: PropTypes.string,
  openFinalizePayoutModal: PropTypes.func.isRequired,
  onEditPayout: PropTypes.func.isRequired
};

export default withTranslation()(PayoutsByPropertyMainView);
