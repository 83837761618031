import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import StarsViewHeader from './StarsViewHeader/StarsViewHeader';
import StarsViewList from './StarsViewList/StarsViewList';

import BaseFooter from "../../../../../../../../../components/BaseFooter/BaseFooter";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";

import './StarsMainView.scss';

class StarsMainView extends Component {

  renderFooter = () => {
    const {listInfo, onPageChange} = this.props;
    const {currentPage, pageSize, pagesTotal, recordCount} = listInfo;
    return (
      <BaseFooter
        currentPage={currentPage}
        onChange={onPageChange}
        pageSize={pageSize}
        pageCount={pagesTotal}
        recordCount={recordCount}
      />
    );
  };

  renderHeader = () => {
    const {defaultDisplayFilter, defaultNameFilter, jsonData, filterStars, listInfo} = this.props;
    return (
      <StarsViewHeader
        defaultDisplayFilter={defaultDisplayFilter}
        defaultNameFilter={defaultNameFilter}
        generalLinks={jsonData.generalLinks}
        onStatusChange={filterStars}
        starsTotal={listInfo.recordCount}
      />
    );
  };

  renderTable = () => {
    const {contentHeight, listInfo, onStarEdit, filterBasedOnCriteria} = this.props;
    return (
      <StarsViewList dataSource={listInfo.starsData}
                     filterBasedOnCriteria={filterBasedOnCriteria}
                     contentHeight={contentHeight}
                     pagesTotal={listInfo.pagesTotal}
                     onStarEdit={onStarEdit}/>
    );
  };

  render() {
    const {listInfo, t} = this.props;
    return (
      <LoadingWrapper className='StarsMainView'
                      dataLoaded={listInfo.starsLoaded}
                      isLoading={listInfo.loading}
                      loadingMsg={t('StarsView.loadingMsg')}>
        {this.renderHeader()}
        {this.renderTable()}
        {this.renderFooter()}
      </LoadingWrapper>
    );
  }
}

StarsMainView.propTypes = {
  contentHeight: PropTypes.number,
  listInfo: PropTypes.object,
  onStarEdit: PropTypes.func.isRequired,
  filterBasedOnCriteria: PropTypes.func.isRequired
};

export default withTranslation()(StarsMainView);
