import React, { Component } from 'react';
import { Router } from "@reach/router";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import CategoryDetails from "./CategoryDetails/CategoryDetails";
import CategoriesMainView from "./CategoriesMainView/CategoriesMainView";
import CategoryMovies from './CategoryMovies/CategoryMovies';

import { MainDashboardComController } from '../../../../../../../../pages/MainDashboard/main-dashboard-comm.controller';

import './CategoriesView.scss';
import { getTagsCancelable } from '../../../../../../../../services/tag-service/tag-service';
import { isNumber } from 'lodash';

class CategoriesView extends Component {
  subscription;

  state = {
    currentPage: 0,
    data: [],
    dataLoaded: false,
    loading: false,
    pageCount: 0,
    perPage: 0,
    total: 0
  };

  componentDidMount() {
    this.subscription = MainDashboardComController.mainNavigationLinkClicked.subscribe(this.fetchData.bind(this, 1));
    this.fetchData(1);
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  fetchData = (page, filters = []) => {
    this.setLoading(true);
    const { result, controller } = getTagsCancelable(page, filters);
    if (this.lastRequestController) {
      this.lastRequestController.abort();
    }
    this.lastRequestController = controller;
    result.then(this.onGetCategoriesSuccess)
      .catch(this.onGetCategoriesFailure);
  };

  getDocumentTitle = () => {
    return this.props.t('CategoriesView.documentTitle');
  };

  onGetCategoriesFailure = (error) => {
    this.setLoading(false);
  };

  onGetCategoriesSuccess = (response) => {
    const { tags, pagination } = response.data.data;
    this.setState({
      currentPage: pagination.current_page,
      data: tags,
      dataLoaded: true,
      loading: false,
      pageCount: pagination.last_page,
      perPage: pagination.per_page,
      total: pagination.total
    });
  };

  onPageChange = (page, filters) => {
    if (isNumber(filters)) { // when pagination is clicked, than second parameter is number which is uneccesary
      filters = [];
    }
    this.fetchData(page, filters);
  };

  setLoading = (loading) => {
    this.setState({
      loading: loading
    });
  };

  filterBasedOnCriteria = (filters) => {
    this.fetchData(1, filters);
  };

  render() {
    return (
      <div className="CategoriesView">
        <Router>
          <CategoriesMainView path={"/"}
            setLoading={this.setLoading}
            contentHeight={this.props.contentHeight}
            listInfo={this.state}
            filterBasedOnCriteria={this.filterBasedOnCriteria}
            onPageChange={this.onPageChange} />
          <CategoryDetails path={`/:categoryId`} />
          <CategoryMovies path={`/:categoryId/movies`} />
        </Router>
      </div>
    );
  }
}

export default compose(withTranslation(), withDocumentTitleUpdate)(CategoriesView);

