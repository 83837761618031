import React, { Component } from 'react';
import { Link } from "@reach/router";
import { Button, Table, InputNumber } from "antd";
import { Subject } from "rxjs";
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import BaseDelayedInput from "../../../../../../../../../../components/BaseDelayedInput/BaseDelayedInput";
import BaseNavigationItem from '../../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem';
import BaseVirtualList from "../../../../../../../../../../components/BaseVirtualList/BaseVirtualList";
import ConfirmationModal from "../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";
import ImageComponent from "../../../../../../../../../../components/ImageComponent/ImageComponent";
import LoadingWrapper from "../../../../../../../../../../components/LoadingWrapper/LoadingWrapper";

import {
  editBannerExclusiveExcluded,
  editBannerSet, getBannerSetBannersList,
  getBannerSets, removeBannerFromSet, setBannerSetBannersOrder
} from "../../../../../../../../../../services/banners-service/banners.service";
import {
  displayErrorNotification,
  displaySuccessNotification,
  displayInfoNotification
} from "../../../../../../../../../../services/notification-service/notification.service";
import { showBannerDetails } from "../../../../../../../../../../services/navigation/banners-navigation/banners-navigation.service";
import { getBannerDetailsRoute } from "../../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";

import { LogController } from "../../../../../../../../../../controllers/log-controller/log.controller";
import { ModalController } from "../../../../../../../../../../controllers/modal-controller/modal.controller";

import './BannerSetBannersAbstract.scss';
// import { checkVisible } from '../../../../../../../../../../services/util-service/util.service';

/* istanbul ignore file */
class BannerSetBannersAbstract extends Component {

  dialogName = 'BannerSetBannersAbstract';

  allBannersRef = React.createRef();

  rowKey = 1;

  editableFields = [
    'name'
  ];

  initialLoad = true;

  isUnmounted = false;

  onDataReload = new Subject();

  protoFields = [
    'name', 'bannerSetId'
  ];

  renderValueColumn = (value, data) => {
    let render;
    if (this.protoFields.indexOf(data.key) === -1) {
      render = this.renderNonProtoField(value, data);
    } else {
      render = this.renderProtoField(data);
    }
    return render;
  };

  columns = [{
    dataIndex: 'name',
    width: 500
  }, {
    dataIndex: 'value',
    render: this.renderValueColumn
  }];

  newName;

  removedBanners = [];

  state = {
    banners: [],
    bannerSets: [],
    dataLoaded: false,
    editMode: false,
    loadingMsg: '',
    properties: [],
    tempChangesDetected: false,
    totalBanners: 0,
    itemsPerRow: 5,
    name: ''
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const calculatedItemsPerRow = Math.floor(this.allBannersRef?.current?.offsetWidth / 260);
    if (calculatedItemsPerRow &&
      (prevState.itemsPerRow !== this.state.itemsPerRow || this.state.itemsPerRow !== calculatedItemsPerRow)
    ) {
      this.setState({ itemsPerRow: calculatedItemsPerRow })
    }

    // If there is a blank (hidden) banner visible it means that the row is not
    // fully filled, therefore no sidebar, therefore no way to load new banners on scroll
    // if(checkVisible(document.querySelector(".Banner.Hidden"))) {
    //   this.fetchItems();
    // }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  clearLoading = () => {
    this.setState({ loadingMsg: '' });
  };

  displayPushAllConfirmMessage = () => {
    const { t } = this.props;
    const modal = (
      <ConfirmationModal title={t(`${this.dialogName}.pushAllConfirmTitle`)}
        message={this.getPushAllConfirmationModalMessage()}
        confirm={this.pushAllOrderChanges} />
    );
    ModalController.showModal(modal);
  };

  fetchData = (callback) => {
    const message = this.props.t('BannerSetBannersAbstract.loadingDataMsg')
    this.setLoading(message);
    Promise.all(this.getDataPromises())
      .then(this.loadData.bind(this, callback))
      .catch(this.onRequestFailure);
  };

  getAdditionalDataPromises = () => [];

  getDataPromises = () => {
    return [
      this.getBannerSetBannersFullList(),
      getBannerSets(0),
      ...this.getAdditionalDataPromises()
    ];
  };

  getPushAllConfirmationModalMessage = () => {
    const { t } = this.props;
    return t(`${this.dialogName}.pushAllConfirmMsg`);
  };

  getStateFromValues = (values) => {
    const { data: banners } = values[0].data;
    const { data: bannerSets } = values[1].data || [];
    const { data: propertiesData } = values[2].data;
    const { name } = bannerSets.find(this.matchBannerSetByIndex);
    const properties = propertiesData.hasOwnProperty('properties') ? propertiesData.properties : propertiesData;

    return Object.assign({
      banners: this.rearrangeBannersIfNeeded(banners),
      bannerSets,
      dataLoaded: true,
      loadingMsg: '',
      name: name,
      newName: name,
      properties: properties,
      totalBanners: banners.length
    });
  };

  getAdditionalTableData = () => [];

  getBannerSetBannersFullList = () => {
    const { bannerSetId, propertyId } = this.props;
    return getBannerSetBannersList(bannerSetId, 'index', 'asc', propertyId);
  };

  getRoutes = () => {
    console.log('Implement getRoutes');
  };

  getSaveKey = () => '';

  parseTableSetName = name => name;

  getTableData = () => {
    const { bannerSetId, t, propertyId } = this.props;
    const { name } = this.state;
    const bannerSetName = propertyId ? name : this.parseTableSetName(name);

    const data = [{
      name: t('BannerSetBannersAbstract.bannerSetId'),
      value: bannerSetId,
      key: 'bannerSetId'
    }, {
      name: t('BannerSetBannersAbstract.name'),
      value: bannerSetName,
      key: 'name'
    }];
    return data.concat(this.getAdditionalTableData());
  };

  hasDataChanged = () => {
    const { name, newName } = this.state;
    return name !== newName;
  };

  isEditable = (key) => {
    return this.editableFields.indexOf(key) !== -1;
  };

  loadData = (callback, values) => {
    this.clearLoading();
    if (!this.isUnmounted) {
      this.setState(this.getStateFromValues(values), this.triggerVirtualListReload);
    }
    if (callback) {
      callback();
    }
  };

  mapRemovePromise = () => {
    const { bannerSetId } = this.props;
    const removedBanners = this.storedBannerOrder.removedBanners;
    return removeBannerFromSet(bannerSetId, removedBanners);
  };

  matchBannerSetByIndex = ({ id }) => {
    const { bannerSetId } = this.props;
    return id === +bannerSetId;
  };

  moveItemRight = (index, direction) => {
    this.setState(prevState => {
      const banners = cloneDeep(prevState.banners);
      const nextIndex = index + 1;
      let currentItem = banners[index];
      let nextItem = banners[nextIndex];

      if (!nextItem) return;

      banners.splice(index, 1, nextItem);
      banners.splice(nextIndex, 1, currentItem);
      return { banners };
    }, () => {
      const { banners } = this.state;
      let banner_id;
      let put_before;
      const lastItem = this.storedBannerOrder.order[this.storedBannerOrder.order.length - 1];

      if (direction === 'left') {
        banner_id = banners[index].id;
        put_before = banners[index + 1].id;

        if (lastItem?.banners_id === banner_id) {
          lastItem.put_before_banners_id = put_before;
        } else {
          this.updateSteps(banner_id, put_before);
        }
      }

      if (direction === 'right') {
        banner_id = banners[index + 1]?.id;

        if (!banner_id) return;

        put_before = banners[index + 2]?.id ?? null;

        if (lastItem?.banners_id === banner_id) {
          lastItem.put_before_banners_id = put_before;
        } else {
          this.updateSteps(banner_id, put_before);
        }
      }
    });
  };

  onEditSuccess = () => {
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t('BannerSetBannersAbstract.editBannerSuccess')
    });
    this.fetchData(this.toggleEditMode);
  };

  onItemPositionChange = (prevIndex, event) => {
    const { target } = event;
    let newIndex = event.target.value;
    const { banners } = this.state;
    if (newIndex < 1 || newIndex > banners.length) {
      displayErrorNotification({
        duration: 3,
        message: this.props.t('BannerSetBannersAbstract.bannerIndexRange', { totalBannersCount: banners.length })
      });
      target.blur();
    } else {
      --newIndex; // Entered values go from 1 to total banners count so deduct 1 to get array insertion index
      this.setState(prevState => {
        const banners = cloneDeep(prevState.banners);
        const item = banners.splice(prevIndex, 1)[0];
        if (banners.length >= newIndex) {
          banners.splice(newIndex, 0, item);
        }
        return { banners };
      }, () => {
        const { banners } = this.state;
        const banner_id = banners[newIndex].id;
        const put_before = banners[newIndex + 1]?.id ?? null;
        const lastItem = this.storedBannerOrder.order[this.storedBannerOrder.order.length - 1];

        if (lastItem?.banners_id === banner_id) {
          lastItem.put_before_banners_id = put_before;
        } else {
          this.updateSteps(banner_id, put_before);
        }

        target.blur();
      });
    }
  };

  onNameChange = (name) => {
    this.setState({
      newName: name
    });
  };

  onPositionInputFocus = (event) => {
    event.target.select();
  };

  onPublishSuccess = () => {
    const { fetchData } = this.props;

    this.setState({ tempChangesDetected: false });
    this.clearLoading();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('BannerSetBannersAbstract.publishSuccess')
    });
    this.storedBannerOrder.order = [];
    this.storedBannerOrder.removedBanners = [];
    this.fetchData(this.onReorderChange);
    const key = this.getSaveKey();
    localStorage.removeItem(key);

    if (fetchData) {
      fetchData();
    }
  };

  onReorderChange = () => {
    const { bannerSetId, onReorderChange } = this.props;
    onReorderChange(bannerSetId);
  };

  onRequestFailure = (error) => {
    this.clearLoading();
    LogController.logError(error);
  };

  pushAllOrderChanges = () => {
    const message = this.props.t('BannerSetBannersAbstract.publishInProcess');
    this.setLoading(message);

    this.setBannerSetBannersOrder()
      .then(this.pushRemoveBanners)
      .then(this.onPublishSuccess)
      .catch(this.onRequestFailure);
  };

  pushRemoveBanners = () => {
    let promise = Promise.resolve();
    const { propertyId } = this.props;
    const removedBanners = this.storedBannerOrder.removedBanners;

    if (removedBanners.length !== 0) {
      if (propertyId) {
        const { bannerSets = [] } = this.state;
        const bannerSetIds = [];

        bannerSets.forEach((item = {}) => {
          const { id, banners = [] } = item;

          banners.forEach(banner => {
            if (removedBanners.includes(banner.id)) {
              bannerSetIds.push(id);
            }
          })
        });

        const data = {
          bannerSetIds,
          excluded: [{
            properties_id: propertyId,
            exclusive_excluded: 'excluded',
          }]
        };

        promise = Promise.all(removedBanners.map(bannerId => editBannerExclusiveExcluded(bannerId, data)));
      } else {
        promise = this.mapRemovePromise();
      }
    }
    return promise;
  };

  rearrangeBannersIfNeeded = (banners) => {
    // reorder banners by previous stored order 
    const tempData = this.storeBannersToStorage(this.getSaveKey()).get();
    if (tempData) {
      const { order, removedBanners } = tempData;
      this.storedBannerOrder.removedBanners = removedBanners;
      this.storedBannerOrder.order = order;

      let index;
      let putBeforeIndex;
      let a1, a2, a3, a4;

      for (let i = 0; i < order.length; i++) {
        index = banners.findIndex(item => item.id === order[i].banners_id);

        if (order[i].put_before_banners_id == null) {
          putBeforeIndex = banners.length;
        } else {
          putBeforeIndex = banners.findIndex(item => item.id === order[i]?.put_before_banners_id);
        }

        if (putBeforeIndex > index) {
          a1 = banners.slice(0, index);
          a2 = banners.slice(index + 1, putBeforeIndex);
          a3 = [banners[index]];
          a4 = banners.slice(putBeforeIndex);
        } else {
          a1 = banners.slice(0, putBeforeIndex);
          a2 = [banners[index]];
          a3 = banners.slice(putBeforeIndex, index);
          a4 = banners.slice(index + 1);
        }
        banners = a1.concat(a2, a3, a4);
      }

      // remove banners
      banners = banners.filter(ban => ![...removedBanners].includes(ban.id));

      this.setState({
        tempChangesDetected: true
      });
    }

    return banners;
  };

  removeBanner = (bannerId) => {
    this.storedBannerOrder.removedBanners.push(bannerId);
    this.setState(prevState => {
      const banners = cloneDeep(prevState.banners);
      const index = banners.findIndex(item => item.id === bannerId);
      banners.splice(index, 1);
      return { banners };
    });
  };

  storeBannersToStorage = (key, value) => {
    return {
      get: () => {
        let data = localStorage.getItem(key);
        if (data) { data = JSON.parse(data); }
        return data;
      },
      set: () => {
        localStorage.setItem(key, JSON.stringify(value));
      },
      clear: () => {
        localStorage.removeItem(key);
      }
    }
  }

  renderAllBanners = () => {
    const { banners, totalBanners } = this.state;
    const { t } = this.props;
    return (
      <div className="AllBanners" ref={this.allBannersRef}>
        <div className="Header">
          <span>
            {t('BannerSetBannersAbstract.bannersCount', { count: totalBanners })}
          </span>
          {this.renderTempChangesDetected()}
        </div>
        <div className="AllBanners-inner">
          <BaseVirtualList
            items={banners}
            onComponentReload={this.onDataReload}
            segmentLength={this.state.itemsPerRow}
            renderItem={this.renderBannersRow} />
        </div>
        <div className="Footer">
          <Button className="SaveAll"
            onClick={() => this.preventSaveIfNoChanges(this.saveAllChanges)}>
            {t(`${this.dialogName}.saveAll`)}
          </Button>
          <Button className="PushAll"
            onClick={() => this.preventSaveIfNoChanges(this.displayPushAllConfirmMessage)}>
            {t(`${this.dialogName}.pushAll`)}
          </Button>
        </div>
      </div>
    );
  };

  renderBannersRow = (banners, index) => {
    return (
      <div className="BannersRow"
        key={`banners-row-${index}`}>
        {banners.map(this.renderSingleBanner)}
      </div>
    );
  };

  renderDataTable = () => {
    return (
      <Table columns={this.columns}
        dataSource={this.getTableData()}
        pagination={false}
        showHeader={false}
        bordered
        footer={this.renderDataTableFooter} />
    );
  };

  renderDataTableFooter = () => {
    const { editMode } = this.state;
    const key = editMode ? 'cancel' : 'edit';
    const { t } = this.props;
    return (
      <div className="UserActions">
        {editMode ? (
          <Button onClick={this.saveEditChanges}
            disabled={!this.hasDataChanged()}>
            {t('BannerSetBannersAbstract.saveChanges')}
          </Button>
        ) : null}
        <Button onClick={this.toggleEditMode}>
          {t(`BannerSetBannersAbstract.${key}`)}
        </Button>
      </div>
    );
  };

  renderEditField = (key, value) => {
    let render = null;
    if (key === 'name') {
      const { name } = this.state;
      render = <BaseDelayedInput onChange={this.onNameChange} initialValue={name} />;
    }

    return render;
  };

  renderMoveLeftIndicator = (index) => {
    return index !== undefined && index ? (
      <i className="fas fa-angle-double-left"
        onClick={this.moveItemRight.bind(this, index - 1, 'left')} />
    ) : null;
  };

  renderMoveRightIndicator = (index) => {
    const { totalBanners } = this.state;
    return index !== undefined && index !== totalBanners ? (
      <i className="fas fa-angle-double-right"
        onClick={this.moveItemRight.bind(this, index, 'right')} />
    ) : null;
  };

  renderNonProtoField = (value, data) => value;

  renderPositionSelect = (index) => {
    const { totalBanners } = this.state;
    return (
      <InputNumber value={index + 1}
        max={totalBanners.length}
        min={1}
        onFocus={this.onPositionInputFocus}
        onPressEnter={this.onItemPositionChange.bind(this, index)} />
    );
  };

  renderProtoField = ({ key, value }) => {
    let render = value;
    const { editMode } = this.state;
    if (editMode && this.isEditable(key)) {
      render = this.renderEditField(key, value);
    }

    return render;
  };

  renderRemoveIndicator = (id) => {
    return (
      <i className="fas fa-times"
        onClick={this.removeBanner.bind(this, id)} />
    );
  };

  renderImage = (url, id) => {
    return (
      <Link to={getBannerDetailsRoute(id)}>
        <ImageComponent url={url}
          alternateLoadingIndicator={this.renderImageLoadingSkeleton()} />
      </Link>
    );
  };

  renderImageLoadingSkeleton = () => {
    return (
      <div className="ImageLoadingSkeleton" />
    );
  };

  renderNavigation = () => <BaseNavigationItem routes={this.getRoutes()} />;

  renderNoImage = () => {
    const { t } = this.props;
    return (
      <div className="NoImage">
        {t('BannerSetBannersAbstract.noImage')}
      </div>
    );
  };

  renderSingleBanner = ({ id, image, index }) => {
    let children = null;
    if (id) {
      const url = image?.url ?? undefined;
      children = (
        <React.Fragment>
          <div className="Row AlignCenter JustifySpaceBetween">
            <div className="Row AlignCenter">
              {this.renderMoveLeftIndicator(index)}
              {this.renderPositionSelect(index)}
              {this.renderMoveRightIndicator(index)}
            </div>
            {this.renderRemoveIndicator(id)}
          </div>
          {url ? this.renderImage(url, id) : this.renderNoImage()}
        </React.Fragment>
      );
    }
    if (index === undefined) {
      index = `${++this.rowKey}-row`;
    }
    const classes = ['Banner'];
    if (!children) {
      classes.push('Hidden');
    }
    return (
      <div className={classes.join(' ')}
        key={`banner-${index}`}>
        {children}
      </div>
    );
  };

  renderTempChangesDetected = () => {
    const { tempChangesDetected } = this.state;
    let view = null;
    if (tempChangesDetected) {
      const { t } = this.props;
      view = (
        <span className="TempChangesMsg">{t('BannerSetBannersAbstract.tempChangesMsg')}</span>
      );
    }

    return view;
  };

  storedBannerOrder = {
    order: [],
    removedBanners: []
  }

  updateSteps = (banId, put_before = null) => {
    this.storedBannerOrder.order.push({
      banners_sets_id: this.props.bannerSetId,
      banners_id: banId,
      put_before_banners_id: put_before
    })
  }

  preventSaveIfNoChanges = (callback) => {
    if (this.storedBannerOrder.order.length === 0 && this.storedBannerOrder.removedBanners.length === 0) {
      displayInfoNotification({
        duration: 3,
        message: 'No changes detected!'
      });
    } else {
      callback();
    }
  };

  saveAllChanges = () => {
    this.storeBannersToStorage(this.getSaveKey(), { ...this.storedBannerOrder }).set();
    this.setState({
      tempChangesDetected: true
    });
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('BannerSetBannersAbstract.dataSaved')
    });
  };

  saveEditChanges = () => {
    const message = this.props.t('BannerSetBannersAbstract.editInProgress');
    this.setLoading(message);
    const { newName } = this.state;
    const { bannerSetId } = this.props;
    editBannerSet(bannerSetId, { name: newName })
      .then(this.onEditSuccess)
      .catch(this.onRequestFailure);
  };

  setBannerSetBannersOrder = () => {
    const { bannerSetId, propertyId } = this.props;
    const order = this.storedBannerOrder.order;

    const params = { reorder: [] };
    order.forEach(item => {
      params.reorder.push(
        {
          banners_sets_id: bannerSetId,
          banners_id: item.banners_id,
          put_before_banners_id: item.put_before_banners_id
        },
      )
    });

    if (propertyId) {
      params.properties_id = propertyId;
    }

    return params.reorder.length !== 0 ? setBannerSetBannersOrder(params) : Promise.resolve();
  };

  setLoading = (loadingMsg) => {
    this.setState({ loadingMsg });
  };

  showBannerDetails = (bannerId) => {
    showBannerDetails(bannerId);
  };

  toggleEditMode = () => {
    this.setState(prevState => {
      return {
        editMode: !prevState.editMode
      };
    });
  };

  triggerVirtualListReload = () => {
    this.onDataReload.next();
  };

  render() {
    const { dataLoaded, loadingMsg } = this.state;
    return (
      <LoadingWrapper className="BannerSetBannersAbstract"
        dataLoaded={dataLoaded}
        isLoading={!!loadingMsg}
        loadingMsg={loadingMsg}>
        {this.renderNavigation()}
        <div className="BannerSetBannersAbstract-Inner">
          {this.renderDataTable()}
          {this.renderAllBanners()}
        </div>
      </LoadingWrapper>
    );
  }
}

BannerSetBannersAbstract.propTypes = {
  bannerSetId: PropTypes.string,
  onReorderChange: PropTypes.func.isRequired,
  t: PropTypes.func
};

export default BannerSetBannersAbstract;
