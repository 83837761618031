import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Collapse, Icon, Radio } from "antd";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import BaseCard from "../../../../../../../../../../../../components/BaseCard/BaseCard";

import AdConfigurationPanel from "../../../shared/AdConfigurationPanel/AdConfigurationPanel";
import TestAdConfigModal from "../../../TestAdConfigModal/TestAdConfigModal";
import PropertyAdConfigurationTime from "./AdTime/AdTime";

import { stopEvent } from "../../../../../../../../../../../../services/util-service/util.service";

import { ModalController } from "../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import "./AdConfigPanel.scss";

/* istanbul ignore file */
class AdConfigPanel extends Component {
	state = {
		ad: {},
		isAdConfigValid: false,
	};

	constructor(props) {
		super(props);
		this.state.ad = this.props.ad;
	}

	componentDidMount() {
		this.validateAdConfig();
	}

	getAdConfigurationCollapseHeader = () => {
		const { t } = this.props;
		const { isAdConfigValid } = this.state;
		return (
			<div className="AddConfigurationHeader">
				<div className="Text">
					{t(
						"ConfigureAdsOnPropertyLevel.AdConfigPanel.adConfiguration"
					)}
				</div>
				<Button onClick={this.testAdConfig} disabled={!isAdConfigValid}>
					{t(
						"ConfigureAdsOnPropertyLevel.AdConfigPanel.testConfiguration"
					)}
				</Button>
			</div>
		);
	};

	getAdTitle = () => {
		const { adIndex: index, onPropertyAdRemove, t } = this.props;
		return (
			<div className="AdCardTitle">
				<div className="Text">
					{t("ConfigureAdsOnPropertyLevel.AdConfigPanel.title", {
						index,
					})}
				</div>
				{onPropertyAdRemove ? (
					<Icon type="close" onClick={this.removeAd} />
				) : null}
			</div>
		);
	};

	onAddConfigChange = (index, data) => {
		this.setState((prevState) => {
			const ad = cloneDeep(prevState.ad);
			Object.assign(ad, data);
			return { ad };
		}, this.onAddUpdate);
	};

	onAdApplicationTimeChange = (event) => {
		this.setState((prevState) => {
			const ad = cloneDeep(prevState.ad);
			ad.selectedApplicationTime = event.target.value;
			return { ad };
		}, this.onAddUpdate);
	};

	onAdTimeUpdate = (data) => {
		this.setState((prevState) => {
			const ad = cloneDeep(prevState.ad);
			Object.assign(ad, data);
			return { ad };
		}, this.onAddUpdate);
	};

	onAddUpdate = () => {
		const { adIndex, onAddUpdate, propertyId } = this.props;
		const { ad } = this.state;
		onAddUpdate(propertyId, adIndex, ad);
		this.validateAdConfig();
	};

	onMovieSceneTypeChange = (event) => {
		this.setState((prevState) => {
			const ad = cloneDeep(prevState.ad);
			ad.selectedSection = event.target.value;
			return { ad };
		}, this.onAddUpdate);
	};

	onAdTargetChange = (event) => {
		this.setState((prevState) => {
			const ad = cloneDeep(prevState.ad);
			ad.selectedTarget = event.target.value;
			return { ad };
		}, this.onAddUpdate);
	};

	removeAd = () => {
		const { adIndex, onPropertyAdRemove, propertyId } = this.props;
		onPropertyAdRemove(propertyId, adIndex);
	};

	renderAdConfigurationPanel = () => {
		const { ad } = this.state;
		const { adIndex: index } = this.props;
		return (
			<Collapse defaultActiveKey={1} className="AdConfiguration">
				<Collapse.Panel
					key={1}
					header={this.getAdConfigurationCollapseHeader()}
				>
					<AdConfigurationPanel
						onAddConfigChange={this.onAddConfigChange}
						index={index}
						ad={ad}
						startInSeconds={0}
						endInSeconds={0}
					/>
				</Collapse.Panel>
			</Collapse>
		);
	};

	renderAdContent = () => {
		return (
			<div className="AdConfigPanel-inner">
				<div>
					{this.renderUserStateConfig()}
					{this.renderSectionSelectionConfig()}
					{this.renderTargetSelectionConfig()}
					{this.renderAdPeriodConfig()}
					{this.renderAdSelectedApplicationTimeConfig()}
					{this.renderAdConfigurationPanel()}
				</div>
			</div>
		);
	};

	renderAdPeriodConfig = () => {
		const { ad } = this.state;
		return (
			<PropertyAdConfigurationTime
				ad={ad}
				onAddTimeUpdate={this.onAdTimeUpdate}
			/>
		);
	};

	renderSectionSelectionConfig = () => {
		const { t } = this.props;
		const { selectedSection } = this.state.ad;
		return (
			<div className="AdSelectedSectionConfig">
				<Radio.Group
					onChange={this.onMovieSceneTypeChange}
					value={selectedSection}
				>
					<div className="SectionOptions">
						<div className="Text">
							{t(
								"ConfigureAdsOnPropertyLevel.AdConfigPanel.applyToAllMoviesInProperty"
							)}
						</div>
						<Radio value="movie"></Radio>
					</div>
					<div className="SectionOptions">
						<div className="Text">
							{t(
								"ConfigureAdsOnPropertyLevel.AdConfigPanel.applyToAllMoviesScenesInProperty"
							)}
						</div>
						<Radio value="scene"></Radio>
					</div>
				</Radio.Group>
			</div>
		);
	};

	renderTargetSelectionConfig = () => {
		const { selectedTarget } = this.state.ad;
		return (
			<div className="AdTargetSelection">
				<Radio.Group
					onChange={this.onAdTargetChange}
					value={selectedTarget}
				>
					<div className="SectionOptions">
						<div className="Text">Open in new tab</div>
						<Radio value="_blank"></Radio>
					</div>
					<div className="SectionOptions">
						<div className="Text">Open in same tab</div>
						<Radio value="_self"></Radio>
					</div>
				</Radio.Group>
			</div>
		);
	};

	renderAdSelectedApplicationTimeConfig = () => {
		const { t } = this.props;
		const { selectedApplicationTime, selectedSection } = this.state.ad;
		return (
			<div className="AdSelectedApplicationTimeConfig">
				<Radio.Group
					onChange={this.onAdApplicationTimeChange}
					value={selectedApplicationTime}
				>
					<div className="SectionOptions">
						<div className="Text">
							{t(
								"ConfigureAdsOnPropertyLevel.AdConfigPanel.applyToBeginning",
								{ name: selectedSection }
							)}
						</div>
						<Radio value="beginning"></Radio>
					</div>
					<div className="SectionOptions">
						<div className="Text">
							{t(
								"ConfigureAdsOnPropertyLevel.AdConfigPanel.applyToHalf",
								{ name: selectedSection }
							)}
						</div>
						<Radio value="half"></Radio>
					</div>
					<div className="SectionOptions">
						<div className="Text">
							{t(
								"ConfigureAdsOnPropertyLevel.AdConfigPanel.applyToEnd",
								{ name: selectedSection }
							)}
						</div>
						<Radio value="end"></Radio>
					</div>
				</Radio.Group>
			</div>
		);
	};

	renderUserStateConfig = () => {
		const { t } = this.props;
		const { applyToLoggedUser, applyToNotLoggedUser } = this.state.ad;
		return (
			<div className="AdUserStateConfig">
				<div className="UserStateManagement">
					<div className="Text">
						{t(
							"ConfigureAdsOnPropertyLevel.AdConfigPanel.applyToLoggedUser"
						)}
					</div>
					<Checkbox
						checked={applyToLoggedUser}
						onChange={this.setAdApplyToLoggedUsers}
					/>
				</div>
				<div className="UserStateManagement">
					<div className="Text">
						{t(
							"ConfigureAdsOnPropertyLevel.AdConfigPanel.applyToNotLoggedUser"
						)}
					</div>
					<Checkbox
						checked={applyToNotLoggedUser}
						onChange={this.setAdApplyToNotLoggedUsers}
					/>
				</div>
			</div>
		);
	};

	setAdApplyToLoggedUsers = (event) => {
		this.setState((prevState) => {
			const ad = cloneDeep(prevState.ad);
			ad.applyToLoggedUser = event.target.checked;
			return { ad };
		}, this.onAddUpdate);
	};

	setAdApplyToNotLoggedUsers = (event) => {
		this.setState((prevState) => {
			const ad = cloneDeep(prevState.ad);
			ad.applyToNotLoggedUser = event.target.checked;
			return { ad };
		}, this.onAddUpdate);
	};

	testAdConfig = (event) => {
		stopEvent(event);
		const { ad } = this.state;
		const modal = <TestAdConfigModal ad={ad} />;
		ModalController.showModal(modal);
	};

	validateAdConfig = () => {
		const { goToText, goToUrl, type, url } = this.state.ad;
		const isAdConfigValid = !!(goToText && goToUrl && type && url);
		this.setState({ isAdConfigValid });
	};

	render() {
		const { adIndex: index } = this.props;
		return (
			<BaseCard
				title={this.getAdTitle()}
				className="AdConfigPanel"
				key={index}
			>
				{this.renderAdContent()}
			</BaseCard>
		);
	}
}

AdConfigPanel.propTypes = {
	ad: PropTypes.object.isRequired,
	adIndex: PropTypes.number.isRequired,
	onAddUpdate: PropTypes.func.isRequired,
	onPropertyAdRemove: PropTypes.func,
	propertyId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
};

export default withTranslation()(AdConfigPanel);
