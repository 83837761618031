import { withTranslation } from 'react-i18next';

import MoviesViewFooterAbstract from "../../../MoviesView/MoviesMainView/MoviesViewFooter/MoviesViewFooterAbstract";

import './LicensorMoviesFooter.scss';

class LicensorMoviesFooter extends MoviesViewFooterAbstract {
  renderChildren = () => null;
}

export default withTranslation()(LicensorMoviesFooter);
