import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Checkbox, Input} from "antd";
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';

import LoadingIndicator from "../../../../../../../../../../../components/LoadingIndicator/LoadingIndicator";
import ModalDialog from "../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {getProperties} from "../../../../../../../../../../../services/properties-service/properties.service";
import {LogController} from "../../../../../../../../../../../controllers/log-controller/log.controller";

import './SelectPropertiesModal.scss';

/* istanbul ignore file */
class SelectPropertiesModal extends Component {

  modalRef = React.createRef();

  state = {
    allProperties: [],
    loading: false,
    propertyName: '',
    properties: [],
    selectedProperties: {}
  };

  constructor(props) {
    super(props);
    this.state.selectedProperties = cloneDeep(props.properties);
  }

  componentDidMount () {
    this.filterProperties();
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  filterData = () => {
    const {allProperties} = this.state;
    const properties = allProperties.filter(this.matchPropertyByName);
    this.setState({properties, loading: false});
  };

  filterProperties = () => {
    const {allProperties} = this.state;
    if (!allProperties.length) {
      this.setLoading(true);
    }
    this.getProperties()
      .then(this.filterData);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.closeModal}>
          {t('ConfigureAdsOnPropertyLevel.SelectPropertiesModal.cancel')}
        </Button>
        <Button onClick={this.saveChanges}>
          {t('ConfigureAdsOnPropertyLevel.SelectPropertiesModal.saveProperties')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getProperties = () => {
    return new Promise(this.getPropertiesExecutor);
  };

  getPropertiesExecutor = (resolve, reject) => {
    const {allProperties} = this.state;
    if (allProperties.length) {
      resolve(allProperties);
    } else {
      getProperties()
        .then(this.onGetPropertiesSuccess.bind(this, resolve))
        .catch(this.onGetPropertiesFailure.bind(this, reject));
    }
  };

  getTitle = () => {
    return this.props.t('ConfigureAdsOnPropertyLevel.SelectPropertiesModal.title');
  };

  matchPropertyByName = ({name}) => {
    const {propertyName} = this.state;
    return name.toLowerCase().indexOf(propertyName.toLowerCase()) !== -1;
  };

  onGetPropertiesFailure = (reject, error) => {
    LogController.logError(error);
    reject();
  };

  onGetPropertiesSuccess = (resolve, response) => {
    const {properties: allProperties} = response.data.data;
    this.setState({allProperties}, resolve);
  };

  onPropertyNameFilterChange = (event) => {
    const {value: propertyName} = event.target;
    this.setState({propertyName});
  };

  propertyMap = ({id, name}) => {
    const {selectedProperties} = this.state;
    const checked = !!selectedProperties[id];
    const classes = ['Property'];
    if (checked) {
      classes.push('Checked');
    }
    return (
      <div className={classes.join(' ')}
           key={id}
           onClick={this.setPropertyChecked.bind(this, id, name)}>
        <div className="Name">
          {name}
        </div>
        <Checkbox checked={checked}/>
      </div>
    );
  };

  renderFilteredProperties = () => {
    const {properties} = this.state;
    return properties.map(this.propertyMap);
  };

  renderLoadingIndicator = () => {
    let view = null;
    const {loading} = this.state;
    if (loading) {
      const {t} = this.props;
      view = (
        <LoadingIndicator message={t('SelectPropertiesModal.loadingPropertiesMsg')}/>
      );
    }

    return view;
  };

  renderNameFilter = () => {
    const {t} = this.props;
    return (
      <div className="NameFilter">
        <div className="Label">
          {t('ConfigureAdsOnPropertyLevel.SelectPropertiesModal.searchByPropertyName')}
        </div>
        <Input.Search value={this.state.propertyName}
                      onChange={this.onPropertyNameFilterChange}
                      onSearch={this.filterProperties}
                      autoFocus
                      enterButton/>
      </div>
    );
  };

  saveChanges = () => {
    const {selectedProperties} = this.state;
    const {properties} = this.props;
    const data = {};
    for (let propertyId in selectedProperties) {
      if (properties[propertyId]) {
        data[propertyId] = properties[propertyId];
      } else {
        data[propertyId] = selectedProperties[propertyId];
      }
    }
    this.props.onPropertiesSelected(data);
    this.closeModal();
  };

  setLoading = (loading) => {
    this.setState({loading});
  };

  setPropertyChecked = (id, name) => {
    this.setState(prevState => {
      const selectedProperties = cloneDeep(prevState.selectedProperties);
      const checked = !selectedProperties[id];
      if (checked) {
        selectedProperties[id] = {
          id, name
        };
      } else {
        delete selectedProperties[id];
      }
      return {selectedProperties};
    });
  };

  render() {
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}
                   className="SelectPropertiesModal">
        <div className="SelectPropertiesModal-inner">
          {this.renderNameFilter()}
          <div className="PropertiesList">
            {this.renderLoadingIndicator()}
            {this.renderFilteredProperties()}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

SelectPropertiesModal.propTypes = {
  properties: PropTypes.object.isRequired,
  onPropertiesSelected: PropTypes.func.isRequired
};

export default withTranslation()(SelectPropertiesModal);
