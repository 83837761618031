import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Table, Input, DatePicker, InputNumber, Checkbox } from 'antd';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import { ModalController } from "../../../../../../../../../../../controllers/modal-controller/modal.controller";

import ImageComponent from "../../../../../../../../../../../components/ImageComponent/ImageComponent";

import { convertToMoment, renderMoment } from "../../../../../../../../../../../services/date-service/date.service";
import {
  uploadStarImage,
  deleteStarImage
} from "../../../../../../../../../../../services/images-service/images.service";

import UploadExclusiveImage from "./UploadExclucisveImage/UploadExclusiveImage";

import './StarDetailsBodyTable.scss';

class StarDetailsBodyTable extends Component {

  datePickerFormat = 'MMMM Do YYYY';

  editableProperties = [
    'name', 'gender', 'eyeColor', 'hairColor', 'ethnicity', 'birthDate', 'heightInches', 'weightLbs', 'contractStudioId', 'biography'
  ];

  exclusiveImageUploaded = () => {
    return this.state.exclusive ? this.getExclusiveImage().length : true;
  };

  isEditable = (key) => {
    return this.editableProperties.indexOf(key) !== -1;
  };

  onChange = (key, event) => {
    const value = event.target ? event.target.value || event.target.checked : event;
    this.setState({
      [key]: value
    });
  };

  renderEditField = (key) => {
    let render = null;
    switch (key) {
      case 'name':
        render = this.renderTextArea(key, true);
        break;
      case 'gender':
      case 'eyeColor':
      case 'hairColor':
      case 'ethnicity':
      case 'biography':
      case 'contractStudioId':
        render = this.renderTextArea(key, false);
        break;
      case 'birthDate':
        render = this.renderDateField(key);
        break;
      case 'heightInches':
      case 'weightLbs':
        render = this.renderNumberField(key);
        break;
      default:
        break;
    }

    return render;
  };

  renderExclusiveCheckBox = () => {
    const { t } = this.props;
    const { editMode, exclusive } = this.state;

    return (
      <div className="ExclusiveStar-Container">
        <Checkbox disabled={!editMode}
          checked={exclusive}
          onChange={this.onChange.bind(this, 'exclusive')} />
        {editMode && exclusive === true &&
          <Button onClick={this.showUploadImageModal}>
            {t('StarDetailsBodyTable.uploadExclusiveImage')}
          </Button>
        }
      </div>
    );
  };

  renderDateField = (key) => {
    return (
      <DatePicker value={this.state[key]}
        format={this.datePickerFormat}
        onChange={this.onChange.bind(this, key)} />
    );
  };

  renderNumberField = (key) => {
    return (
      <InputNumber
        onChange={this.onChange.bind(this, key)}
        value={this.state[key]} />
    );
  };

  renderTextArea = (key, autoFocus) => {
    return (
      <Input.TextArea
        autoFocus={autoFocus}
        onChange={this.onChange.bind(this, key)}
        value={this.state[key]} />
    );
  };

  renderValue = (value, { key }) => {
    const { editMode } = this.state;
    let render = value;
    if (editMode && this.isEditable(key)) {
      render = this.renderEditField(key);
    }

    return render;
  };

  columns = [
    {
      dataIndex: 'name',
      key: 'name',
      width: 300
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: this.renderValue
    }
  ];

  startingData = {
    editMode: true
  };

  state = {
    editMode: false
  };

  constructor(props) {
    super(props);
    const {
      biography,
      contractStudioId,
      name,
      gender,
      eyeColor,
      hairColor,
      ethnicity,
      birthDate,
      heightInches,
      weightLbs,
      exclusive
    } = props;
    const data = {
      biography, contractStudioId,
      name, gender,
      eyeColor, hairColor,
      ethnicity, birthDate: convertToMoment(birthDate),
      heightInches, weightLbs,
      exclusive: exclusive ? true : false
    };
    Object.assign(this.state, data);
    Object.assign(this.startingData, data);
  }

  filterExclusiveImages = image => image.type === 'Exclusive';

  getExclusiveImage = () => {
    return this.props.images ? this.props.images.filter(this.filterExclusiveImages) : [];
  };

  getTableData = () => {
    const {
      t,
      headshot,
      biography,
      contractStudioId,
      id,
      name,
      gender,
      eyeColor,
      hairColor,
      ethnicity,
      birthDate,
      heightInches,
      weightLbs
    } = this.props;
    return [{
      name: t('StarDetailsBodyTable.AEBNHeadshot'),
      value: headshot ? this.renderHeadshot(headshot) : t('StarDetailsBodyTable.none'),
      key: 'AEBNHeadshot'
    }, {
      name: t('StarDetailsBodyTable.starID'),
      value: id,
      key: 'StarID'
    }, {
      name: t('StarDetailsBodyTable.name'),
      value: name,
      key: 'name'
    }, {
      name: t('StarDetailsBodyTable.gender'),
      value: gender,
      key: 'gender'
    }, {
      name: t('StarDetailsBodyTable.eyeColor'),
      value: eyeColor,
      key: 'eyeColor'
    }, {
      name: t('StarDetailsBodyTable.hairColor'),
      value: hairColor,
      key: 'hairColor'
    }, {
      name: t('StarDetailsBodyTable.ethnicity'),
      value: ethnicity,
      key: 'ethnicity'
    }, {
      name: t('StarDetailsBodyTable.birthDate'),
      value: this.renderBirthDate(birthDate),
      key: 'birthDate'
    }, {
      name: t('StarDetailsBodyTable.heightInches'),
      value: heightInches,
      key: 'heightInches'
    }, {
      name: t('StarDetailsBodyTable.weightLbs'),
      value: weightLbs,
      key: 'weightLbs'
    }, {
      name: t('StarDetailsBodyTable.biography'),
      value: biography,
      key: 'biography'
    }, {
      name: t('StarDetailsBodyTable.contractStudioId'),
      value: contractStudioId,
      key: 'contractStudioId'
    }, {
      name: t('StarDetailsBodyTable.exclusive'),
      value: this.renderExclusiveCheckBox(),
      key: 'exclusive'
    }];
  };

  hasDataChanged = () => {
    return JSON.stringify(this.state) !== JSON.stringify(this.startingData);
  };

  onSaveChangesSuccess = () => {
    this.startingData = cloneDeep(this.state);
    this.toggleEditMode();
  };

  onUploadFinished = (currentExclusiveImage) => {
    if (currentExclusiveImage.length) {
      const { id: starId } = this.props;
      deleteStarImage(starId, currentExclusiveImage[0].id);
    }
    this.props.fetchStarData();
  };

  renderBirthDate = (birthDate) => {
    let render = '';
    if (birthDate) {
      const split = birthDate.split('/');
      render = renderMoment(`${split[2]}-${split[0]}-${split[1]}`);
    }

    return render;
  };

  renderFooter = () => {
    const { t } = this.props;
    const { editMode } = this.state;
    const key = editMode ? 'cancel' : 'editStar';
    return (
      <div className="UserActions">
        {editMode ?
          <Button onClick={this.saveEditChanges} disabled={!this.exclusiveImageUploaded() || !this.hasDataChanged()}>
            {t('StarDetailsBodyTable.saveChanges')}
          </Button> : null}
        <Button onClick={this.toggleEditMode}>
          {t(`StarDetailsBodyTable.${key}`)}
        </Button>
      </div>
    );
  };

  renderHeadshot = headshot => {
    return headshot ? (
      <div key={`headshot.filename`}>
        <ImageComponent url={headshot} />
      </div>
    ) : null;
  };

  saveEditChanges = () => {
    this.props.onStarEdit(cloneDeep(this.state), this.onSaveChangesSuccess);
  };

  showUploadImageModal = () => {
    const currentExclusiveImage = this.getExclusiveImage();
    const modal = (
      <UploadExclusiveImage uploadImages={this.uploadImages}
        onUploadFinished={this.onUploadFinished.bind(this, currentExclusiveImage)} />
    );
    ModalController.showModal(modal);
  };

  toggleEditMode = () => {
    const { exclusive } = this.startingData;
    this.setState(prevState => {
      return {
        editMode: !prevState.editMode,
        exclusive: exclusive
      };
    });
  };

  uploadImages = (file, type) => {
    const { id: starId } = this.props;
    return uploadStarImage(starId, type, file);
  };

  render() {
    return (
      <Table
        className='StarDetailsBodyTable'
        dataSource={this.getTableData()}
        columns={this.columns}
        showHeader={false}
        pagination={false}
        rowKey='key'
        bordered
        footer={this.renderFooter}
      />
    );
  }
}

StarDetailsBodyTable.propTypes = {
  headshot: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  biography: PropTypes.string,
  gender: PropTypes.string,
  eyeColor: PropTypes.string,
  hairColor: PropTypes.string,
  ethnicity: PropTypes.string,
  birthDate: PropTypes.string,
  heightInches: PropTypes.number,
  onStarEdit: PropTypes.func.isRequired
};

export default withTranslation()(StarDetailsBodyTable);
