import React, { Component } from 'react';
import { Router } from "@reach/router";
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import BaseNavigationItem from '../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem';
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import PayoutsByLicensor from "../PayoutsByLicensor/PayoutsByLicensor";
import PayoutsByPropertyMainView from "./PayoutsByPropertyMainView/PayoutsByPropertyMainView";

import { renderMoment } from "../../../../../../../../../services/date-service/date.service";
import {
  getPayoutPropertiesPeriodRoute, getPayoutPropertyRoute, getPayoutsRoute,
} from '../../../../../../../../../services/navigation/payouts-navigation/payouts-navigation-routes.service';

import './PayoutsByProperty.scss';

class PayoutsByProperty extends Component {
  dateFormat = 'YYYY-MM-DD';

  endDate;

  startDate;

  constructor(props) {
    super(props);
    this.startDate = moment(`${props.month}/${props.year}`, 'M/YYYY').startOf('month').format(this.dateFormat);
    this.endDate = moment(`${props.month}/${props.year}`, 'M/YYYY').endOf('month').format(this.dateFormat);
  };

  componentDidMount() {
    this.props.onPayoutsByPropertyInitialize(this.startDate, this.endDate);
  };

  getActions = () => {
    const { onExportPayouts, onFullExport, t } = this.props;

    return [
      <Button onClick={onExportPayouts}
        key="export">
        <Icon type="save" />
        {t('PayoutsByProperty.export')}
      </Button>,
      <Button onClick={onFullExport}
        key="fullExport">
        <Icon type="save" />
        {t('PayoutsByProperty.fullExport')}
      </Button>
    ]
  };

  getRoutes = () => {
    const { year, month, activePropertyId, activePropertyName, activeLicensorName, t, location } = this.props;
    const pathname = location.pathname || '';

    const routes = [{
      url: getPayoutsRoute(),
      text: t('PayoutsByProperty.payouts')
    }, {
      text: `${renderMoment(this.startDate)} - ${renderMoment(this.endDate)}`,
    }];

    if (pathname.includes('/property/')) {
      routes.splice(1, 1, {
        url: getPayoutPropertiesPeriodRoute(year, month),
        text: `${renderMoment(this.startDate)} - ${renderMoment(this.endDate)}`
      }, {
        text: t('PayoutsByProperty.propertyTitle', { name: activePropertyName })
      });
    }

    if (pathname.includes('/licensors/')) {
      routes.splice(2, 1, {
        url: getPayoutPropertyRoute(activePropertyId, year, month),
        text: t('PayoutsByProperty.propertyIdTitle', { propertyId: activePropertyName })
      }, {
        text: t(`PayoutsByProperty.licensorTitle`, { licensorId: activeLicensorName })
      });
    }

    return routes;
  };

  render() {
    const {
      contentHeight,
      data,
      dataLoaded,
      loadingMsg,
      onMoviesPageChange,
      onLicensorsPageChange,
      onPayoutsByLicensorInitialize,
      onPayoutsByMoviesInitialize,
      onEditPayout,
      openFinalizePayoutModal
    } = this.props;
    const {
      activeStartDate,
      activeEndDate,
      licensorsData,
      properties,
      moviesData } = data;
    return (
      <div className="PayoutsByProperty">
        <BaseNavigationItem routes={this.getRoutes()}>
          {this.getActions()}
        </BaseNavigationItem>
        <LoadingWrapper
          className='PayoutsByProperty-inner'
          dataLoaded={dataLoaded}
          isLoading={!!loadingMsg}
          loadingMsg={loadingMsg}>
          <Router>
            <PayoutsByPropertyMainView
              path={'/'}
              properties={properties}
              contentHeight={contentHeight}
              onEditPayout={onEditPayout}
              openFinalizePayoutModal={openFinalizePayoutModal}
              activeStartDate={activeStartDate}
              activeEndDate={activeEndDate}
            />
            <PayoutsByLicensor path={`/property/:propertyId/*`}
              contentHeight={contentHeight}
              data={licensorsData}
              dataLoaded={dataLoaded}
              loadingMsg={loadingMsg}
              onPageChange={onLicensorsPageChange}
              onMoviesPageChange={onMoviesPageChange}
              onPayoutsByLicensorInitialize={onPayoutsByLicensorInitialize}
              onPayoutsByMoviesInitialize={onPayoutsByMoviesInitialize}
              moviesData={moviesData} />
          </Router>
        </LoadingWrapper>
      </div>
    );
  };
}

PayoutsByProperty.propTypes = {
  activePropertyId: PropTypes.string,
  activeLicensorId: PropTypes.string,
  activePropertyName: PropTypes.string,
  activeLicensorName: PropTypes.string,
  contentHeight: PropTypes.number,
  data: PropTypes.object.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
  loadingMsg: PropTypes.string,
  onExportPayouts: PropTypes.func.isRequired,
  onFullExport: PropTypes.func.isRequired,
  onPayoutsByLicensorInitialize: PropTypes.func.isRequired,
  onPayoutsByMoviesInitialize: PropTypes.func.isRequired,
  onPayoutsByPropertyInitialize: PropTypes.func.isRequired,
  onLicensorsPageChange: PropTypes.func.isRequired,
  onMoviesPageChange: PropTypes.func.isRequired,
  openFinalizePayoutModal: PropTypes.func.isRequired,
  onEditPayout: PropTypes.func.isRequired
};

export default withTranslation()(PayoutsByProperty);