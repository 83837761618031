import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Table} from "antd";
import {renderMoment} from "../../../../../../../../../../../../../services/date-service/date.service";
import PropTypes from 'prop-types';

class MoviePlaylistDetailsTab extends Component {

  renderNameColumn = (item) => {
    return this.props.t(`MoviePlaylistDetailsTab.${item}`);
  };

  columns = [{
    dataIndex: 'name',
    key: 'details',
    render: this.renderNameColumn
  }, {
    dataIndex: 'value',
    key: 'value'
  }];

  getTableData = () => {
    const {name, movie_playlist_category_name, created_at, updated_at} = this.props.detailsData;
    return [{
      name: 'name',
      value: name
    }, {
      name: 'categoryName',
      value: movie_playlist_category_name
    }, {
      name: 'created',
      value: renderMoment(created_at)
    }, {
      name: 'updated',
      value: renderMoment(updated_at)
    }];
  };

  render() {
    return (
      <div className="DetailsTab">
        <Table
          rowKey="name"
          dataSource={this.getTableData()}
          pagination={false}
          showHeader={false}
          bordered
          columns={this.columns}
        />
      </div>
    );
  }
}

MoviePlaylistDetailsTab.propTypes = {
  detailsData: PropTypes.object.isRequired,
};

export default withTranslation()(MoviePlaylistDetailsTab);
