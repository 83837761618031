import {withTranslation} from "react-i18next";

import UploadImages from "../../../../../../../../../../components/modal/UploadImages/UploadImages";

class UploadBannerImagesModal extends UploadImages {

  /**
   * @override
   */
  getImageCategories = () => {
    const {t} = this.props;
    return [t('UploadBannerImagesModal.banner')];
  };
}

export default withTranslation()(UploadBannerImagesModal);