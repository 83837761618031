import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import MovieDetailsImagesTab from "../MovieDetailsImagesTab/MovieDetailsImagesTab";
import MovieDetailsInfosTab from "../MovieDetailsInfosTab/MovieDetailsInfosTab";
import MovieDetailsScenesTab from "../MovieDetailsScenesTab/MovieDetailsScenesTab";
import MovieDetailsPlayerTab from "../MovieDetailsPlayerTab/MovieDetailsPlayerTab";
import TabsComponent from "../../../../../../../../../../components/TabsComponent/TabsComponent";

import './MovieDetailsMainView.scss';

class MovieDetailsMainView extends Component {

  onImageEdit = (imageId) => {
    const { setLoading, onImageEdit } = this.props;
    setLoading('Loading...');
    onImageEdit(imageId);
  };

  getTabs = () => {
    const { data, t, movieId, onMovieEdit, onSetMovieEnabled, scenesAreEpisodes, onDeleteImage, properties, hasError } = this.props;
    const name = scenesAreEpisodes ? 'episodes' : 'scenes';
    let imagesTab = {};

    imagesTab = {
      title: t('MovieDetailsMainView.images'),
      view: (
        <MovieDetailsImagesTab
          images={data.images}
          movieId={movieId}
          onImageEdit={this.onImageEdit}
          onDeleteImage={onDeleteImage} />
      )
    };

    return [{
      title: t('MovieDetailsMainView.details'),
      view: (
        <MovieDetailsInfosTab
          data={data}
          setMovieEnabled={onSetMovieEnabled}
          onMovieEdit={onMovieEdit}
          hasError={hasError}
        />
      )
    }, {
      title: t(`MovieDetailsMainView.${name}`),
      view: (
        <MovieDetailsScenesTab
          scenes={data.scenes}
          scenesAreEpisodes={scenesAreEpisodes}
          viewingMovieId={movieId}
          properties={properties}
        />
      )
    }, imagesTab, {
      title: t('MovieDetailsMainView.player'),
      view: (
        <MovieDetailsPlayerTab movieId={movieId}
          data={data} />
      )
    }];
  };

  render() {
    const { activeTab, onTabChange } = this.props;
    return (
      <div className="MovieDetailsMainView">
        <div className="MovieDetails-body">
          <TabsComponent
            activeKey={activeTab}
            onChange={onTabChange}>
            {this.getTabs()}
          </TabsComponent>
        </div>
      </div>
    );
  }
}

MovieDetailsMainView.propTypes = {
  activeTab: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  movieId: PropTypes.string.isRequired,
  onMovieEdit: PropTypes.func.isRequired,
  onSetMovieEnabled: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onImageEdit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  properties: PropTypes.array,
  hasError: PropTypes.bool,
};

export default withTranslation()(MovieDetailsMainView);
