import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Table } from "antd";
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import './PlayerConfigurationModal.scss';
import { stopEvent } from "../../../../../../../../../../services/util-service/util.service";

/* istanbul ignore file */
class PlayerConfigurationModal extends Component {

  modalRef = React.createRef();

  state = {
    playerColorValue: '#9f44b4', // --vjs-theme-fantasy--primary: #9f44b4; default color for al whitelabels
    allChecked: false,
    selected: [],
    saveConfiguration: {}
  };

  renderCell = (item) => {
    if (item === 'playerColor') {
      return this.renderColorPicker(item);
    }
    return this.renderCheckbox(item);
  }

  renderCheckbox = (item) => {
    return (
      <Checkbox checked={this.getConfigStatus(item)} />
    );
  };

  onColorChange = (e) => {
    const { saveConfiguration } = this.state;
    this.setState({
      saveConfiguration: {
        ...saveConfiguration,
        playerColor: e.target.value
      },
      playerColorValue: e.target.value
    });
  };

  renderColorPicker = (item) => {
    return (
      <input type='color' onChange={this.onColorChange} value={this.state.playerColorValue} />
    )
  };

  columns = [{
    dataIndex: 'text',
    title: 'Config'
  }, {
    dataIndex: 'name',
    render: this.renderCell,
    align: 'center'
  }];

  constructor(props) {
    super(props);
    const { config, configurations, configValues } = props;
    this.state.selected = config ? config : [];
    this.state.allChecked = config && config.length === configurations.length;
    const playerColor = configValues?.playerColor || this.state.playerColorValue;

    if (Array.isArray(config)) {
      const configToSave = config.reduce((configToSave, key) => {
        configToSave[key] = true;
        return configToSave;
      }, {
        playerColor
      });
      this.state.playerColorValue = playerColor;
      this.state.saveConfiguration = {
        ...this.state.saveConfiguration,
        ...configToSave
      };
    }
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.closeModal}>
          {t('PlayerConfigurationModal.cancel')}
        </Button>
        <Button onClick={this.saveChanges}>
          {t('PlayerConfigurationModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getConfigStatus = name => {
    return this.state.selected.includes(name);
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    const { itemName, t } = this.props;
    return t(`PlayerConfigurationModal.title`, { name: itemName });
  };

  onRow = (record) => {
    return {
      onClick: this.toggleItemSelection.bind(this, record.name)
    };
  };

  renderConfigTable = () => {
    const { configurations } = this.props;
    const data = configurations.filter(config => config.name === 'playerColor'); // TODO remove when other options are supported
    return (
      <Table
        dataSource={data}
        rowKey='name'
        onRow={this.onRow}
        bordered
        columns={this.columns}
        pagination={false}
        showHeader={false}
      />
    );
  };

  renderSelectAllBtn = () => {
    const { t } = this.props;
    return (
      <div className="Header">
        <Button onClick={this.setAllSelected}
          className="SelectAllBtn">
          {this.state.allChecked ? t(`PlayerConfigurationModal.disableAll`) : t(`PlayerConfigurationModal.enableAll`)}
          <Checkbox checked={this.state.allChecked} />
        </Button>
      </div>
    )
  };

  saveChanges = () => {
    const { itemId, onPlayerChange } = this.props;
    const { selected, saveConfiguration } = this.state;
    onPlayerChange(itemId, cloneDeep(selected), cloneDeep(saveConfiguration));
    this.closeModal();
  };

  setSelectedItem = (item) => {
    let selectedKeys = this.state.selected;
    if (selectedKeys.includes(item)) {
      const index = selectedKeys.indexOf(item);
      selectedKeys.splice(index, 1);
    } else {
      selectedKeys.push(item);
    }

    const configToSave = selectedKeys.reduce((configToSave, key) => {
      configToSave[key] = true;
      return configToSave;
    }, {
      playerColor: this.state.saveConfiguration.playerColor
    });
    this.setState({
      saveConfiguration: configToSave,
      allChecked: selectedKeys.length === this.props.configurations.length,
      selected: selectedKeys
    });
  };

  setAllSelected = () => {
    this.setState(prevState => {
      let { allChecked } = prevState;
      return {
        allChecked: !allChecked,
        selected: allChecked ? [] : this.props.configurations.map(this.setFileSelected)
      };
    });
  };

  setFileSelected = (item) => {
    return item.name;
  };

  toggleItemSelection = (item, event) => {
    if (item !== 'playerColor') {
      stopEvent(event);
      this.setSelectedItem(item);
    }
  };

  render() {
    return (
      <ModalDialog title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
        className="PlayerConfigurationModal">
        <div className="PlayerConfigurationModal-inner">
          {/* {this.renderSelectAllBtn()} // TODO enable when all options are enabled */}
          {this.renderConfigTable()}
        </div>
      </ModalDialog>
    );
  }
}

PlayerConfigurationModal.propTypes = {
  config: PropTypes.array,
  configurations: PropTypes.array.isRequired,
  onPlayerChange: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  itemName: PropTypes.string.isRequired
};

export default withTranslation()(PlayerConfigurationModal);
