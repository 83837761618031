import React, { Component } from 'react';
import { Router } from '@reach/router';
import { CSVLink } from "react-csv";
import { compose } from "recompose";
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import { ModalController } from "../../../../../../../../controllers/modal-controller/modal.controller";

import {
  exportStudios,
  toggleEnableStatus,
  getStudiosCancelable
} from '../../../../../../../../services/studio-service/studio.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../services/notification-service/notification.service';

import { MainDashboardComController } from '../../../../../../../../pages/MainDashboard/main-dashboard-comm.controller';

import EditStudioModal from "./EditStudioModal/EditStudioModal";
import StudiosMainView from './StudiosMainView/StudiosMainView';
import StudioDetails from './StudioDetails/StudioDetails';

import './StudiosView.scss';
import jsonData from "./StudiosMainView/StudiosViewHeader/data";


class StudiosView extends Component {
  defaultSortFilter = jsonData.sortCriteria[0];
  defaultDisplayFilter = jsonData.generalLinks[jsonData.generalLinks.length - 1];
  filters = {
    name: '',
    sort: {
      name: jsonData.sortCriteria[0],
      ascending: false
    }
  };
  localizedDefaultSortFilter = this.props.t(`StudiosView.${this.defaultSortFilter}`);
  subscription;
  state = {
    currentPage: 0,
    pageCount: 0,
    pageSize: 0,
    recordCount: 0,
    studios: [],
    dataLoaded: false,
    loadingMsg: ''
  };

  componentDidMount() {
    this.subscription = MainDashboardComController.mainNavigationLinkClicked.subscribe(this.fetchStudios.bind(this, 1));
    this.fetchStudios(1);
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  exportStudiosData = () => {
    const message = this.props.t('StudiosView.exportingMsg');
    this.setLoading(message);
    exportStudios(this.filters.name)
      .then(this.onExportStudiosSuccess)
      .catch(this.onExportStudiosFailure);
  };

  fetchStudios = page => {
    const message = this.props.t('StudiosView.loadingPleaseWait');
    this.setLoading(message);
    const filters = this.getFilters();
    const { result, controller } = getStudiosCancelable(page, filters);
    if (this.lastRequestController) {
      this.lastRequestController.abort();
    }
    this.lastRequestController = controller;
    result.then(this.loadStudios).catch(() => { });
  };

  filterByStatus = (filters) => {
    Object.assign(this.filters, filters);
    this.fetchStudios(1);
  };

  getDocumentTitle = () => {
    return this.props.t('StudiosView.documentTitle');
  };

  getFilters = () => {
    const filters = [];
    let item;
    const excluded = ['all'];
    for (let key in this.filters) {
      item = this.filters[key];
      if (key === 'name' && item) {
        filters.push(`search=${item}`);
      } else {
        if (item) {
          if (item.name) {
            if (excluded.indexOf(item.name) === -1) {
              filters.push(this.getNamedFilters(item));
            }
          } else {
            if (excluded.indexOf(item) === -1) {
              if (item === 'enabled') {
                filters.push(`status=true`);
              } else if (item === 'disabled') {
                filters.push(`status=false`);
              } else {
                filters.push(`${item}=true`);
              }
            }
          }
        }
      }
    }

    return filters;
  };

  getNamedFilters = (item) => {
    const name = item.name;
    const ascending = item.ascending ? 'asc' : 'desc';
    return `${name}=${ascending}`;
  };

  getSortCriteria = (criteria) => {
    const { t } = this.props;
    return {
      text: t(`StudiosView.${criteria}`),
      name: criteria
    }
  }

  loadStudios = response => {
    const data = response.data.data;
    const pagination = data.pagination;
    this.setState({
      currentPage: pagination.current_page,
      loadingMsg: '',
      pageCount: pagination.last_page,
      pageSize: pagination.per_page,
      recordCount: pagination.total,
      studios: data.studio,
      dataLoaded: true
    });
  };

  matchStudioById = (id, studio) => {
    return studio.id === +id;
  };

  onClearFilter = (filters) => {
    filters.name = '';
    this.filterByStatus(filters);
  };

  onExportStudiosFailure = () => {
    this.setLoading('');
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('StudiosView.studiosExportFailed')
    });
  };

  onExportStudiosSuccess = response => {
    this.setLoading('');
    const { t } = this.props;
    displaySuccessNotification({
      duration: 0,
      message: t('StudiosView.studiosExportSuccess'),
      description: (
        <CSVLink data={response.data} filename={"exported-studios.csv"}>{t('StudiosView.clickToDownloadCSV')}</CSVLink>
      )
    });
  };

  onNameFilterChange = (page, filters) => {
    Object.assign(this.filters, filters);
    this.fetchStudios(page);
  };

  onSetStudioStatusFailure = (enabled, error) => {
    this.setLoading('');
    const { t } = this.props;
    const key = enabled === 1 ? 'enableFail' : 'disableFail';
    displayErrorNotification({
      duration: 3,
      message: t(`StudiosView.${key}`)
    });
  };

  onSetStudioStatusSuccess = (id, data) => {
    const { enable } = data;

    const newData = {
      status: data.enable
    };

    const key = enable === 1 ? 'enableSuccess' : 'disableSuccess';
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t(`StudiosView.${key}`)
    });
    this.updateStudioData(id, newData);
  };

  onPageChange = page => {
    this.fetchStudios(page);
  };

  refreshOnDataChange = () => {
    this.fetchStudios(this.state.currentPage);
  };

  setLoading = message => {
    this.setState({
      loadingMsg: message
    });
  };

  setStudioStatus = (id, enabled) => {
    const message = this.props.t('StudiosView.updatingStatus');
    this.setLoading(message);
    const data = {
      enable: enabled
    };

    toggleEnableStatus(id, enabled)
      .then(this.onSetStudioStatusSuccess.bind(this, id, data))
      .catch(this.onSetStudioStatusFailure.bind(this, enabled));
  };

  showEditStudioDialog = (id) => {
    const modal = (
      <EditStudioModal studioId={id} onStudioEdit={this.refreshOnDataChange} />
    );
    ModalController.showModal(modal);
  };

  updateStudioData = (id, data) => {
    this.setLoading('');
    const studioIndex = this.state.studios.findIndex(this.matchStudioById.bind(this, id));
    this.setState(prevState => {
      const studios = cloneDeep(prevState.studios);
      if (!studios[studioIndex]) return;
      Object.assign(studios[studioIndex], data);
      return {
        studios: studios
      };
    });
  };

  render() {
    return (
      <div className='StudiosView'>
        <Router>
          <StudiosMainView
            defaultDisplayFilter={this.defaultDisplayFilter}
            generalLinks={jsonData.generalLinks}
            onStatusChange={this.filterByStatus}
            sortCriteria={jsonData.sortCriteria.map(this.getSortCriteria)}
            onClearFilter={this.onClearFilter}
            onFilterChange={this.props.onStatusChange}
            defaultSortFilter={this.defaultSortFilter}
            localizedDefaultSortFilter={this.localizedDefaultSortFilter}
            defaultFilters={this.filters}
            path={'/'}
            contentHeight={this.props.contentHeight}
            listInfo={this.state}
            onNameFilterChange={this.onNameFilterChange}
            onPageChange={this.onPageChange}
            exportStudiosData={this.exportStudiosData}
            setStudioEnabled={this.setStudioStatus}
            onStudioEdit={this.showEditStudioDialog}
          />
          <StudioDetails
            path={`/:studioId/*`}
            updateStudioData={this.updateStudioData}
          />
        </Router>
      </div>
    );
  }
}

export default compose(withTranslation(), withDocumentTitleUpdate)(StudiosView);
