import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Table } from "antd";
import PropTypes from 'prop-types';

import ImageComponent from "../../ImageComponent/ImageComponent";

import './BaseImagesDisplayTable.scss';
import ImageActions from '../../ImageActions/ImageActions';

class BaseImagesDisplayTable extends Component {

  divRef = React.createRef();

  state = {
    scroll: 240
  };

  renderImage = (url, record, index) => {
    const { is_cover: isCover, origin } = record;
    let imageEl = (
      <ImageComponent url={url}
        isCover={isCover}
        origin={origin}
      />
    );
    const { onDeleteImage, onEditImage, onSetHeroStill, bannerId, movieId } = this.props;
    return (
      <div className="ImageColumn">
        <ImageActions data={record}
          index={index}
          onFullScreen={this.toggleFullScreen.bind(this, index)}
          onDeleteImage={onDeleteImage}
          onSetHeroStill={onSetHeroStill}
          onEditImage={onEditImage}
          key={index}
          bannerId={bannerId}
          movieId={movieId}
        >
          {imageEl}
        </ImageActions>
      </div>
    );
  };

  columns = [{
    dataIndex: 'id',
    title: this.props.t('BaseImagesDisplayTable.id'),
    width: '15%',
    align: 'center'
  }, {
    dataIndex: 'alt',
    title: this.props.t('BaseImagesDisplayTable.altTag'),
    width: '10%'
  }, {
    dataIndex: 'filename',
    title: this.props.t('BaseImagesDisplayTable.fileName'),
    width: '40%',
    textWrap: 'word-break'
  }, {
    dataIndex: 'type',
    title: this.props.t('BaseImagesDisplayTable.type'),
    width: '15%',
    align: 'center'
  }, {
    dataIndex: 'url',
    render: this.renderImage,
    title: this.props.t('BaseImagesDisplayTable.image'),
    width: '20%',
    align: 'center'
  }];

  componentDidMount() {
    const divRef = this.getDivRef();
    if (divRef && divRef.current) {
      const scroll = divRef.current.clientHeight - 73;
      this.setState({ scroll });
    }
  }

  getDivRef = () => {
    return this.divRef;
  };

  matchImageType = ({ type }) => {
    return type.toLowerCase().indexOf(this.props.searchType.toLowerCase()) !== -1;
  };

  toggleFullScreen = (index) => {
    this.props.toggleFullScreen(index);
  };

  render() {
    let { images, searchType } = this.props;
    images = searchType ? images.filter(this.matchImageType) : images;
    return (
      <div className="BaseImagesDisplayTable"
        ref={this.divRef}>
        <Table dataSource={images}
          columns={this.columns}
          pagination={false}
          bordered
          useFixedHeader={true}
          scroll={{ y: this.state.scroll }}
          rowKey="id" />
      </div>
    );
  }
}

BaseImagesDisplayTable.propTypes = {
  images: PropTypes.array.isRequired,
  searchType: PropTypes.string,
  toggleFullScreen: PropTypes.func.isRequired,
  bannerId: PropTypes.string,
  movieId: PropTypes.string,
  onDeleteImage: PropTypes.func.isRequired,
  onEditImage: PropTypes.func.isRequired,
  onSetHeroStill: PropTypes.func
};

export default withTranslation()(BaseImagesDisplayTable);