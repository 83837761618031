import axios from 'axios';

export const urls = {
  attachSubCategory: 'subcategories/movie/attach',
  categorySubCategories: '/subcategories/category',
  detachSubCategory: 'subcategories/movie/detach',
  subcategories: '/subcategories'
};

/**
 * Attached sub-category to movie
 *
 * @param {String} movieId
 * @param {String} subCategoryId
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const attachSubCategoryToMovie = (movieId, subCategoryId) => {
  return axios.patch(`${urls.attachSubCategory}/${subCategoryId}/${movieId}`);
};

/**
 * Creates sub-category
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const createSubCategory = (data) => {
  return axios.post(urls.subcategories, data);
};

/**
 * Deletes sub-category
 *
 * @param {String} id
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const deleteSubCategory = (id) => {
  return axios.delete(`${urls.subcategories}/${id}`);
};

/**
 * Detaches sub-category from movie
 *
 * @param {String} subCategoryId
 * @param {String} movieId
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const detachSubCategoryFromMovie = (subCategoryId, movieId) => {
  return axios.delete(`${urls.detachSubCategory}/${subCategoryId}/${movieId}`);
};

/**
 * Edits sub-category
 *
 * @param {String} id
 * @param {Object} data
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const editSubCategory = (id, data) => {
  return axios.patch(`${urls.subcategories}/${id}`, data);
};

/**
 * Retrieves category sub-categories from backend
 *
 * @param {String} categoryId
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getCategorySubCategories = (categoryId) => {
  return axios.get(`${urls.categorySubCategories}/${categoryId}`);
};

export const getCategorySubCategoriesByName = (categoryId, search) => {
  return axios.get(`${urls.categorySubCategories}/${categoryId}?search=${search}`);
};

/**
 * Retrieves sub-category details from backend
 *
 * @param {String} id
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getSubCategoryDetails = (id) => {
  return axios.get(`${urls.subcategories}/${id}`);
};
