import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button} from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import BaseCheckboxList from "../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList";

import {
  attachSubCategoryToMovie, detachSubCategoryFromMovie,
  getCategorySubCategories
} from "../../../../../../../../../../services/categories-service/subcategories-service/subcategories.service";

import {LogController} from "../../../../../../../../../../controllers/log-controller/log.controller";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../../services/notification-service/notification.service";

import './SelectSubCategoriesModal.scss';

class SelectSubCategoriesModal extends Component {

  addedSubCategoriesIds = [];

  initialSelection = [];

  modalRef = React.createRef();

  removedSubCategoriesIds = [];

  state = {
    categories: [],
    loadedSubcategories: true,
    selectedSubCategories: [],
    subcategories: []
  };

  getCategoryTag = item => {
    return item.tag;
  };

  constructor(props) {
    super(props);
    const {subcategories, categories} = this.props;
    this.initialSelection = subcategories.map(this.getSubCategoryId);
    Object.assign(this.state, {
      categories: categories.map(this.getCategoryTag),
      selectedSubCategories: this.initialSelection.slice(),
      subcategories: subcategories
    });
  }

  componentDidMount() {
    this.fetchSubCategories();
  }

  attachSubCategory = (subCategoryId) => {
    const {movieId} = this.props;
    return attachSubCategoryToMovie(movieId, subCategoryId);
  };

  clearLoading = () => {
    this.getModal().clearLoading();
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  detachSubCategory = (subCategoryId) => {
    const {movieId} = this.props;
    return detachSubCategoryFromMovie(subCategoryId, movieId);
  };

  fetchCategorySubCategories = (category) => {
    const {id: categoryId} = category;
    return getCategorySubCategories(categoryId);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="SelectSubCategoriesModal-actions">
        <Button onClick={this.closeModal.bind(this)}>
          {t('SelectSubCategoriesModal.cancel')}
        </Button>
        <Button type="primary"
                disabled={!this.addedSubCategoriesIds.length && !this.removedSubCategoriesIds.length}
                onClick={this.saveChanges.bind(this)}>
          {t('SelectSubCategoriesModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getAddedSubCategoriesIds = (ids) => {
    const addedSubCategoriesIds = [];
    let item = ids.length;
    let currentId;
    while (item) {
      item--;
      currentId = ids[item];
      if (this.initialSelection.indexOf(currentId) === -1) {
        addedSubCategoriesIds.push(currentId);
      }
    }

    return addedSubCategoriesIds;
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getRemovedSubCategoriesIds = (ids) => {
    const removedSubCategoriesIds = [];
    let item = this.initialSelection.length;
    let currentId;
    while (item) {
      item--;
      currentId = this.initialSelection[item];
      if (ids.indexOf(currentId) === -1) {
        removedSubCategoriesIds.push(currentId);
      }
    }

    return removedSubCategoriesIds;
  };

  getSubCategoryId = ({id}) => {
    return id;
  };

  getTitle = () => {
    return this.props.t('SelectSubCategoriesModal.title');
  };

  loadSubCategories = (response) => {
    let newSubCategories = [];

    newSubCategories = response.reduce(this.reduceSubCategories, []);

    this.setState({
      subcategories: newSubCategories.sort(this.sortByName),
      loadedSubcategories: true
    });

    this.clearLoading();
  };

  loadSubCategoriesFailed = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('SelectSubCategoriesModal.getSubCategoriesFailed')
    });
  };

  onSaveChangesFailure = (error) => {
    this.clearLoading();
    LogController.logError(error);
    displayErrorNotification({
      duration: 3,
      message: this.props.t('SelectSubCategoriesModal.saveFailed')
    });
  };

  onSaveChangesSuccess = () => {
    this.clearLoading();
    this.closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('SelectSubCategoriesModal.saveSuccessful')
    });
    this.props.onSubCategoriesUpdated();
  };

  onSubCategoryChanges = (selectedSubCategories) => {
    this.addedSubCategoriesIds = this.getAddedSubCategoriesIds(selectedSubCategories);
    this.removedSubCategoriesIds = this.getRemovedSubCategoriesIds(selectedSubCategories);
    this.setState({selectedSubCategories});
  };

  fetchSubCategories = () => {
    const message = this.props.t('SelectSubCategoriesModal.loadingSubcategories');
    this.getModal().setLoading(message);

    const {categories} = this.state;
    
    this.setState({
      loadedSubcategories: false
    });

    const promises = categories.map(this.fetchCategorySubCategories);

    Promise.all(promises)
      .then(this.loadSubCategories)
      .catch(this.loadSubCategoriesFailed);
  };

  reduceSubCategories = (result, response) => {
    const {subcategories} = response.data.data;
    result.push(...subcategories);

    return result;
  };

  renderSubCategorySelect = () => {
    const {loadedSubcategories, subcategories, selectedSubCategories} = this.state;
      return (
        <div className="SubCategoriesBox">
          {loadedSubcategories && (
            <BaseCheckboxList items={subcategories}
                              onChange={this.onSubCategoryChanges}
                              checked={selectedSubCategories}
                              showSelectAll={true}/>
          )}
        </div>
      );
  };

  saveChanges = () => {
    const message = this.props.t('SelectSubCategoriesModal.addingSubCategories');
    this.getModal().setLoading(message);
    let promises = this.addedSubCategoriesIds.map(this.attachSubCategory);
    promises = promises.concat(this.removedSubCategoriesIds.map(this.detachSubCategory));
    Promise.all(promises)
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  sortByName = ({name}, {name: name1}) => {
    let order = 0;
    if (name > name1) {
      order = 1;
    } else if (name < name1) {
      order = -1;
    }

    return order;
  };

  render() {
    const {t} = this.props;
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}>
        <div className="SelectSubCategoriesModal">
          <div className="SelectSubCategoriesModal-label">{t('SelectSubCategoriesModal.selectedSubCategories')}:</div>
          {this.renderSubCategorySelect()}
        </div>
      </ModalDialog>
    );
  }
}

SelectSubCategoriesModal.propTypes = {
  categories: PropTypes.array.isRequired,
  defaultSelection: PropTypes.array.isRequired,
  movieId: PropTypes.string.isRequired,
  onSubCategoriesUpdated: PropTypes.func.isRequired,
  subcategories: PropTypes.array.isRequired
};

export default withTranslation()(SelectSubCategoriesModal);
