/* istanbul ignore file */
export const readFileAsTextExecutor = (file, resolve) => {
  const reader = new FileReader();
  reader.onload = (event) => resolve(event.target.result);
  reader.readAsText(file);
};


export const readFileAsText = (file) => {
  return new Promise(readFileAsTextExecutor.bind(null, file));
};