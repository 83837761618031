import React from 'react';
import PropTypes from 'prop-types';

import ImageComponent from "../../../../../../../../../../../components/ImageComponent/ImageComponent";

const MovieDetailsImage = props => {
  let view = null;
  const imageUrl = props.logoUrl;
  if (imageUrl) {
    view = (
      <ImageComponent className='MovieDetailsImage'
                      url={imageUrl}/>
    );
  }
  return view;
};

MovieDetailsImage.propTypes = {
  logoUrl: PropTypes.string
};

export default MovieDetailsImage;
