import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon, Input, Select } from 'antd';
import PropTypes from 'prop-types';

import './ThemesViewHeader.scss';
import { MainDashboardComController } from '../../../../../../../../main-dashboard-comm.controller';
import { getTagsExport } from '../../../../../../../../../../services/tag-service/tag-service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../../services/notification-service/notification.service';
import { CSVLink } from 'react-csv';

class ThemesViewHeader extends Component {
    timeoutId;
    themeNameFilterDebounce = 500;
    subscription;
    state = {
        filtersDisplayed: false,
        filters: {
            search: '',
            display: 'all',
            enabled: 'all'
        }
    };

    clearFilters = () => {
        this.setState({
            filters: {
                search: '',
                display: 'all',
                enabled: 'all'
            }
        });
    };

    componentDidMount() {
        this.subscription = MainDashboardComController.mainNavigationLinkClicked.subscribe(this.clearFilters.bind(this));
    }

    displayFilters = () => {
        this.setState(prevState => {
            return {
                filtersDisplayed: !prevState.filtersDisplayed
            };
        });
    };

    getIconType = () => {
        return this.state.filtersDisplayed ? 'up' : 'down';
    };

    onThemeNameChange = (event) => {
        const search = event.target.value;
        this.setState(prevState => {
            return {
                filters: {
                    ...prevState.filters,
                    search: search
                }
            };
        });
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(
            this.searchThemes,
            this.themeNameFilterDebounce
        );
    };

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    searchThemes = () => {
        const { search, display, enabled } = this.state.filters;
        let filters = search ? [`search=${search}`] : [];
        if (display) {
            filters.push(`type=${display}`);
        }
        if (enabled === 'Enabled') {
            filters.push(`enabled=1`);
        } else if (enabled === 'Disabled') {
            filters.push(`enabled=0`);
        }
        this.props.setActiveFilters(filters);
        this.props.onPageChange(1, filters);
    };

    renderDisplayFilterItem = item => {
        const { t } = this.props;
        return (
            <Select.Option key={item} value={item}>
                <div>{t(`CategoriesMainView.${item}`)}</div>
            </Select.Option>
        );
    };

    onDisplayChange = status => {
        this.setState(prevState => {
            return {
                filters: {
                    ...prevState.filters,
                    display: status
                }
            };
        }, this.searchThemes);
    };

    onStatusChange = enabled => {
        this.setState(prevState => {
            return {
                filters: {
                    ...prevState.filters,
                    enabled
                }
            };
        }, this.searchThemes);
    };


    onExportFailure = () => {
        const { t, setLoading } = this.props;
        setLoading('');
        displayErrorNotification({
            duration: 3,
            message: t('ThemesViewHeader.exportFailed')
        });
    };

    onExportSuccess = response => {
        const { t, setLoading } = this.props;
        setLoading('');
        displaySuccessNotification({
            duration: 0,
            message: t('ThemesViewHeader.exportSuccess'),
            description: (
                <CSVLink 
                    data={response.data} 
                    filename={"exported-tags.csv"}
                    >{t('ThemesViewHeader.clickToDownloadCSV')}
                </CSVLink>
            )
        });
    };

    onExport = () => {
        const { appliedFilters, setLoading } = this.props;
        setLoading(this.props.t('ThemesViewHeader.exportingMsg'));
        getTagsExport(appliedFilters)
            .then(this.onExportSuccess)
            .catch(this.onExportFailure);
    };

    renderFilters = () => {
        const { t } = this.props;
        return (
            <div className='ThemeFiltersWrapper'>
                <div className='InputWrapper'>
                    <div>{t('ThemesViewHeader.searchByThemeName')}:</div>
                    <Input
                        value={this.state.filters.search}
                        onChange={this.onThemeNameChange}
                        allowClear
                    />
                </div>
                <div className='InputWrapper'>
                    <div>{t('CategoriesMainView.display')}:</div>
                    <Select
                        showArrow
                        onChange={this.onDisplayChange}
                        value={this.state.filters.display}
                    >
                        {['all', 'category', 'sex_act'].map(this.renderDisplayFilterItem)}
                    </Select>
                </div>
                <div className='InputWrapper'>
                    <div>{t('CategoriesMainView.displayStatus')}:</div>
                    <Select
                        showArrow
                        onChange={this.onStatusChange}
                        value={this.state.filters.enabled}
                    >
                        {['all', 'Enabled', 'Disabled'].map(this.renderDisplayFilterItem)}
                    </Select>
                </div>
                <div className='InputWrapper'>
                    <Button onClick={this.onExport}>
                        {t('ThemesViewHeader.export')}
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        const { recordCount, t } = this.props;
        const classes = this.state.filtersDisplayed ? ['ThemesViewHeader', 'FilterDisplayed'] : ['ThemesViewHeader'];
        return (
            <div className={classes.join(' ')}>
                <div className="StatusInnerWrapper">
                    <div className='RecordCountMessage'>
                        {t('ThemesViewHeader.recordFound', {
                            recordCount
                        })}
                    </div>
                    <Icon type={this.getIconType()} onClick={this.displayFilters} />
                </div>
                {this.renderFilters()}
            </div>
        );
    }
}

ThemesViewHeader.propTypes = {
    appliedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    setLoading: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    recordCount: PropTypes.number.isRequired,
    setActiveFilters: PropTypes.func.isRequired
};

export default withTranslation()(ThemesViewHeader);
