import React from 'react';
import {withTranslation} from "react-i18next";
import {cloneDeep} from 'lodash';
import {Button} from "antd";
import PropTypes from 'prop-types';

import AdConfigurationBaseAbstract, {InvalidReasons} from "../../../../shared/AdConfigurationBaseAbstract";
import ModalDialog from "../../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import TestAdConfigModal from "../../../../TestAdConfigModal/TestAdConfigModal";
import AdConfigPanel from "../AdConfigPanel/AdConfigPanel";

import {ModalController} from "../../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import './EditAdModal.scss';

/* istanbul ignore file */
class EditAdModal extends AdConfigurationBaseAbstract {

  modalRef = React.createRef();

  state = {
    invalidReason: false
  };

  constructor(props) {
    super(props);
    this.state = props.ad;
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.closeModal}>
          {t('EditAdModal.cancel')}
        </Button>
        <Button onClick={this.onSaveChangesClick}>
          {t('EditAdModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getTitle = () => {
    const {index, t} = this.props;
    return (
      <div className="Header">
        {t('EditAdModal.title', {index})}
        <Button onClick={this.testConfiguration.bind(this)}>
          Test Configuration
        </Button>
      </div>
    )
  };

  onAddConfigChange = (index, data) => {
    this.setState(data);
  };

  onDataValidated = () => {
    const {invalidReason} = this.state;
    if (!invalidReason) {
      this.saveChanges();
    } else {
      this.displayErrorsFoundModal();
    }
  };

  onSaveChangesClick = () => {
    this.validateData(this.onDataValidated);
  };

  saveChanges = () => {
    const {index: adIndex, saveChanges, id} = this.props;
    saveChanges(id, adIndex, cloneDeep(this.state));
    this.closeModal();
  };

  testConfiguration = () => {
    const modal = (
      <TestAdConfigModal ad={this.state}/>
    );
    ModalController.showModal(modal);
  };

  validateData = (callback) => {
    this.setState({
      invalidReason: !this.isAdConfigValid(this.state) ? InvalidReasons.InvalidAdConfig : null
    }, callback);
  };

  render() {
    const {invalidReason} = this.state;
    const {index, endInSeconds, startInSeconds} = this.props;
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}
                   className="EditAdModal">
        <div className="EditAdModal-inner">
          {this.renderInvalidReasonExplanation(invalidReason)}
          <AdConfigPanel ad={this.state}
                         index={index}
                         onAddConfigChange={this.onAddConfigChange}
                         endInSeconds={endInSeconds}
                         startInSeconds={startInSeconds}/>
        </div>
      </ModalDialog>
    );
  }
}

EditAdModal.propTypes = {
  ad: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  saveChanges: PropTypes.func.isRequired,
  endInSeconds: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  startInSeconds: PropTypes.number.isRequired
};

export default withTranslation()(EditAdModal);
