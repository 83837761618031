import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import StarDetailsBodyDescription from './StarDetailsBodyDescription/StarDetailsBodyDescription';
import StarDetailsBodyTable from './StarDetailsBodyTable/StarDetailsBodyTable';
import StarDetailsBodyImages from './StarDetailsBodyImages/StarDetailsBodyImages';
import TabsComponent from '../../../../../../../../../../components/TabsComponent/TabsComponent';

import './StarDetailsBody.scss';

class StarDetailsBody extends Component {

  getTabs = () => {
    const {t, biography, images, onStarUpdateFinished, starId} = this.props;
    let imageTab = {};

    if (images.length !== 0) {
      imageTab = {
        title: t('StarDetailsBody.starImages'),
        view: (
          <div className="ImagesTab">
            <StarDetailsBodyImages images={images}
                                   starId={starId}
                                   onStarUpdateFinished={onStarUpdateFinished}/>
          </div>
        )
      };
    }

    return (
      [
        {
          title: t('StarDetailsBody.starInfo'),
          view: (
            <div>
              <StarDetailsBodyDescription description={biography}/>
              <div className='StarDetailsBody-inner'>
                <StarDetailsBodyTable {...this.props} />
              </div>
            </div>
          )
        },
        imageTab
      ]
    );
  };

  render() {
    return (
      <div className='StarDetailsBody'>
        <TabsComponent
          activeKey={this.props.activeTab}
          onChange={this.props.changeTab}
        >
          {this.getTabs()}
        </TabsComponent>
      </div>
    );
  };
}

export default withTranslation()(StarDetailsBody);
