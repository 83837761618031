import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Select} from "antd";
import PropTypes from 'prop-types';

import {getBannerSets} from "../../../../../../../../../../services/banners-service/banners.service";

import {LogController} from "../../../../../../../../../../controllers/log-controller/log.controller";

import BaseCheckboxList from "../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList";

import './BannerSetSelect.scss';

class BannerSetSelect extends Component {

  state = {
    dataLoaded: false,
    sets: [],
    selectedSets: []
  };

  componentDidMount() {
    getBannerSets()
      .then(this.loadData)
      .catch(this.handleGetBannerSetsError);
  }

  handleGetBannerSetsError = error => {
    LogController.logError(error);
  };

  loadData = response => {
    const {data} = response.data;
    this.setState({
      dataLoaded: true,
      sets: data.sort(this.sortByName)
    });
  };

  onChange = (selectedSets) => {
    this.setState({
      selectedSets: selectedSets
    });
    this.props.onBannerSetSelect(selectedSets);
  };

  setMap = (set) => {
    return (
      <Select.Option value={set.id}
                     key={set.id}>
        {set.name}
      </Select.Option>
    );
  };

  sortByName = ({name}, {name: name1}) => {
    let order = 0;
    if (name > name1) {
      order = 1;
    } else if (name < name1) {
      order = -1;
    }

    return order;
  };

  render() {
    const {t} = this.props;
    const {dataLoaded, sets, selectedSets} = this.state;
    return (
      <div className="BannerSetSelect">
        <div>{t('BannerSetSelect.label')}</div>
        {dataLoaded ? <BaseCheckboxList items={sets}
                                        showSelectAll={true}
                                        onChange={this.onChange}
                                        checked={selectedSets}/> : null}
      </div>
    );
  }
}

BannerSetSelect.propTypes = {
  onBannerSetSelect: PropTypes.func.isRequired
};

export default withTranslation()(BannerSetSelect);