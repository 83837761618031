import {ROUTES} from "../../../routes";
import {MAIN_DASHBOARD_VIEW_ROUTES} from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";

/**
 * Returns banners view route
 *
 * @returns {string}
 */
export const getBannersBaseRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.BANNERS}`;
};

export const getBannersRoute = () => {
  return `${getBannersBaseRoute()}/list`;
};

/**
 * Returns banner details route
 *
 * @param {string|number} bannerId
 *
 * @returns {string}
 */
export const getBannerDetailsRoute = bannerId => {
  return `${getBannersRoute()}/details/${bannerId}`;
};

/**
 * Returns banner sets route
 *
 * @returns {string}
 */
export const getBannerSetsRoute = () => {
  return `${getBannersBaseRoute()}/sets`;
};

/**
 * Returns banner set details route
 *
 * @param {string|number} bannerSetId
 *
 * @returns {string}
 */
export const getBannerSetDetailsRoute = bannerSetId => {
  return `${getBannerSetsRoute()}/${bannerSetId}`;
};

export const getBannerSetBannersRoute = (bannerSetId) => {
  return `${getBannerSetDetailsRoute(bannerSetId)}/banners`;
};

export const getPropertyBannerSetBannersRoute = (bannerSetId, propertyId) => {
  return `${getBannerSetDetailsRoute(bannerSetId)}/properties/${propertyId}/banners`;
};

export const getBannerSetPropertiesRoute = (bannerSetId) => {
  return `${getBannerSetDetailsRoute(bannerSetId)}/properties`;
};

export const getBannerSetPropertiesDefaultBannersRoute = (bannerSetId) => {
  return `${getBannerSetPropertiesRoute(bannerSetId)}/default/banners`;
};
