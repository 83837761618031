import React from 'react';
import PropTypes from 'prop-types';

import ImageComponent from "../../../../../../../../../../../../../components/ImageComponent/ImageComponent";

import './StudioDetailsBodyLogo.scss';

const StudioDetailsBodyLogo = props => {
  let view = null;
  const imageUrl = props.url;
  if (imageUrl) {
    view = (
      <ImageComponent className='StudioDetailsBodyLogo'
                      url={imageUrl}/>
    );
  }
  return view;
};

StudioDetailsBodyLogo.propTypes = {
  url: PropTypes.string
};

export default StudioDetailsBodyLogo;
