import axios from "axios";

export const urls = {
	stars: "/stars",
	starEdit: "/stars/edit",
	starSync: "/stars/sync",
};

export const editStar = (
	id,
	{
		name,
		gender,
		eyeColor,
		hairColor,
		ethnicity,
		birthDate,
		heightInches,
		weightLbs,
		contractStudioId,
		biography,
		exclusive,
	}
) => {
	return axios.patch(`${urls.starEdit}/${id}`, null, {
		params: {
			name,
			gender,
			eye_color: eyeColor,
			hair_color: hairColor,
			ethnicity,
			birth_date: birthDate,
			height_inches: heightInches,
			weight_lbs: weightLbs,
			contract_studio_id: contractStudioId,
			biography,
			exclusive: exclusive ? 1 : 0,
		},
	});
};

export const getAllStars = () => {
	return axios.get(urls.stars);
};

export const getStars = (page, filters, signal = null) => {
	return filters?.length
		? getFilteredStars(page, filters, signal)
		: getStarsPage(page, signal);
};


export const getStarsCancelable = (page, filters) => {
	const controller = new AbortController();
	return {
		result: getStars(page, filters, controller.signal),
		controller
	};
};

export const getStarContentInfo = (startId) => {
	return axios.get(`${urls.stars}/${startId}/info/aebn`);
};

export const getFilteredStars = (page, filters = ["sort_by=elastic_score"], signal) => {
	const data = signal ? { signal } : {};
	return axios.get(`${urls.stars}?${filters.join("&")}&page=${page}`, data);
};

export const getStarsPage = (page, signal) => {
	const data = signal ? { signal } : {};
	return axios.get(`${urls.stars}?page=${page}`, data);
};

export const getStarById = (
	id,
	starImagesCustomOnly = 1,
	starImagesMasterOnly = 1
) => {
	return axios.get(`${urls.stars}/${id}`, {
		params: {
			star_images_custom_only: starImagesCustomOnly,
			star_images_master_only: starImagesMasterOnly,
			star_images_sort_order: 'DESC' // newest first
		},
	});
};

export const setStarHeroImage = (starId, imageId) => {
	return axios.patch(`/images/${imageId}/star/${starId}`);
};

export const syncStar = (starId) => {
	return axios.get(`${urls.starSync}/${starId}`);
};
