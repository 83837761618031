export class LogCtrl {
  static instance;

  static getInstance() {
    if (LogCtrl.instance == null) {
      LogCtrl.instance = new LogCtrl();
    }

    return LogCtrl.instance;
  }

  logError(data) {
    console.log(data);
    // console.log('error', data.message);
  }

  logInfo(data) {
    console.info(data.message);
  }
}

export const LogController = LogCtrl.getInstance();
