import React from "react";
import { withTranslation } from "react-i18next";
import { Button } from "antd";
import PropTypes from "prop-types";

import AdConfigurationBaseAbstract from "../../../shared/AdConfigurationBaseAbstract";
import ModalDialog from "../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import PropertyAdConfiguration from "../AdConfigPanel/AdConfigPanel";

import "./EditAdConfigurationModal.scss";

/* istanbul ignore file */
class EditAdConfigurationModal extends AdConfigurationBaseAbstract {
	modalRef = React.createRef();

	state = {
		ad: {},
	};

	constructor(props) {
		super(props);
		this.state.ad = props.ad;
	}

	closeModal = () => {
		this.getModal().closeModal();
	};

	getActions = () => {
		const { t } = this.props;
		return (
			<div className="Actions">
				<Button onClick={this.closeModal}>
					{t(
						"ConfigureAdsOnPropertyLevel.EditAdConfigurationModal.cancel"
					)}
				</Button>
				<Button onClick={this.onSaveChangesClick}>
					{t(
						"ConfigureAdsOnPropertyLevel.EditAdConfigurationModal.saveChanges"
					)}
				</Button>
			</div>
		);
	};

	getModal = () => {
		return this.modalRef.current;
	};

	getTitle = () => {
		const { index, t } = this.props;
		return (
			<div className="Title">
				{t(
					"ConfigureAdsOnPropertyLevel.EditAdConfigurationModal.title",
					{ index }
				)}
			</div>
		);
	};

	onAddUpdate = (propertyId, adIndex, ad) => {
		this.setState({ ad });
	};

	onSaveAdsValidationCompleted = () => {
		const { invalidAds } = this.state;
		if (Object.keys(invalidAds).length) {
			this.displayErrorsFoundModal();
		} else {
			this.saveChanges();
		}
	};

	onSaveChangesClick = () => {
		this.validateAds(this.onSaveAdsValidationCompleted);
	};

	renderAdConfiguration = () => {
		const { ad } = this.state;
		const { index, propertyId } = this.props;
		return (
			<PropertyAdConfiguration
				ad={ad}
				adIndex={index}
				onAddUpdate={this.onAddUpdate}
				propertyId={propertyId}
			/>
		);
	};

	saveChanges = () => {
		const { index, onPropertyAdEdit, propertyId } = this.props;
		const { ad } = this.state;
		onPropertyAdEdit(propertyId, index, ad);
		this.closeModal();
	};

	validateAds = (callback) => {
		this.setState((prevState) => {
			const { ad } = prevState;
			const invalidAds = this.getAdsInvalidKeys([ad]);
			return {
				invalidAds,
				verificationTriggered: true,
			};
		}, callback);
	};

	render() {
		const { invalidAds, verificationTriggered } = this.state;
		return (
			<ModalDialog
				className="EditAdConfigurationModal"
				title={this.getTitle()}
				actions={this.getActions()}
				forwardedRef={this.modalRef}
			>
				<div className="EditAdConfigurationModal-inner">
					{verificationTriggered
						? this.renderInvalidReasonExplanation(invalidAds[0])
						: null}
					{this.renderAdConfiguration()}
				</div>
			</ModalDialog>
		);
	}
}

EditAdConfigurationModal.propTypes = {
	ad: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	onPropertyAdEdit: PropTypes.func.isRequired,
	propertyId: PropTypes.number.isRequired,
};

export default withTranslation()(EditAdConfigurationModal);
