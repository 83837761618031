import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {cloneDeep} from 'lodash';

import ModalDialog from "../../../components/modal/ModalDialog/ModalDialog";
import {ModalController} from "../../../controllers/modal-controller/modal.controller";
import {LogController} from '../../../controllers/log-controller/log.controller';
import ConfirmationModal from "../../../components/modal/ConfirmationModal/ConfirmationModal";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../services/notification-service/notification.service";

import {
  getUnassignedImages,
  postUnassignedImages,
  deleteUnassignedImages
} from '../../../services/images-service/images.service';

import StarSelectionModal from "./StarSelectionModal/StarSelectionModal";
import MovieSelectionModal from "./MovieSelectionModal/MovieSelectionModal";
import StudioSelectionModal from "./StudioSelectionModal/StudioSelectionModal";
import BannerSelectionModal from './BannerSelectionModal/BannerSelectionModal';
import UploadImagesModal from './UploadImagesModal/UploadImagesModal';

import './UploadDroppedFiledModal.scss';
import {Button, Checkbox} from "antd";

import MarkingImage from "./MarkingImage/MarkingImage";

class UploadDroppedFiledModal extends Component {
  
  modalRef = React.createRef();

  state = {
    allChecked: false,
    files: [],
    unassignedImages: []
  };

  constructor(props) {
    super(props);
    this.state.files = props.files;
  };

  componentDidMount() {
    this.fetchUnassignedImages();
  };

  addFiles = (addedFiles) => { 
    this.setState(prevState => {
      let {files} = prevState;
      files = files.concat(addedFiles);
      return {
        files
      };
    });
  };

  areAllImagesSelected = (files) => {
    return !files.find(this.matchUncheckedFile);
  };

  clearAssignedImages = files => {
    const idsToRemove = files.map(this.getFilesId);
    const filteredFiles = this.state.files.filter(this.filterFiles.bind(this, idsToRemove));
    
    this.setState({
      files: filteredFiles
    });
  };

  closeModal = () => {
    this.props.onClose();
    ModalController.removeModal();
  };

  deleteImages = () => {
    const message = this.props.t('UploadDroppedFiledModal.deleting');
    this.getModal().setLoading(message);
    const {files} = this.state;
    const imageIds = files.map(this.matchUnassignedImageIds);

    const promises = imageIds.map(this.deleteUnassignedImage);

    Promise.all(promises)
      .then(this.onDeleteImagesSuccess)
      .catch(this.onDeleteImagesFailure);
  };

  deleteSingleImage = (id, type) => {
    const message = this.props.t('UploadDroppedFiledModal.deleting');
    this.getModal().setLoading(message);
    if(type === 'Unassigned') {
      deleteUnassignedImages(id)
      .then(this.onDeleteSingleImageSuccess.bind(this, id))
      .catch(this.onDeleteImagesFailure);
    } else {
      this.updateFilesData(id);
    }
  };

  deleteUnassignedImage = (imageId) => {
    if(imageId) {
      return deleteUnassignedImages(imageId);
    }
  };

  fetchUnassignedImages = () => {
    const message = this.props.t('UploadDroppedFiledModal.loadingMsg');
    this.getModal().setLoading(message);

    getUnassignedImages()
      .then(this.onFetchUnassignedImagesSuccess)
      .catch(this.onFetchUnassignedImagesFailure);
  };

  filterFiles = (idsToRemove, file) => {
    return !idsToRemove.includes(+file.id)
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="UploadDroppedFiledModal-Footer">
        <Button onClick={this.showUploadImagesModal} key="addMoreImages">{t('UploadDroppedFiledModal.addMoreImages')}</Button>
        <Button onClick={this.onModalClose} key="closeBtn">{t('UploadDroppedFiledModal.closeBtn')}</Button>
        <Button disabled={this.isDisabled()} onClick={this.showStarSelectionModal} key="addToStarBtn">{t('UploadDroppedFiledModal.addToStarBtn')}</Button>
        <Button disabled={this.isDisabled()} onClick={this.showMovieSelectionModal} key="addToMovieBtn">{t('UploadDroppedFiledModal.addToMovieBtn')}</Button>
        {/* <Button disabled={this.isDisabled()} onClick={this.showStudioSelectionModal} key="addToStudioBtn">{t('UploadDroppedFiledModal.addToStudioBtn')}</Button> */}
        <Button disabled={this.isDisabled()} onClick={this.showBannerSelectionModal} key="addToBannerBtn">{t('UploadDroppedFiledModal.addToBannerBtn')}</Button>
      </div>
    )
  };

  getDroppedUnassignedFiles = () => {
    return this.state.files.filter(this.isDroppedFile);
  };

  getFileName = (file, index) => {
    return (
      <MarkingImage 
        file={file} 
        index={index} 
        onCheckChange={this.onImageCheckChange} 
        key={index}
        showDeleteSingleImageModal={this.showDeleteSingleImageModal}
      />
    );
  };

  getFilesId = file => {
    return file.id;
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getSelectedFiles = () => {
    return this.state.files.filter(this.matchCheckedFiles);
  };

  isDisabled = () => {
    return !this.state.files.find(this.matchCheckedFiles);
  };

  isDroppedFile = (file) => {
    return !!file.file;
  };

  matchCheckedFiles = (file) => {
    return file.checked;
  };

  matchFileById = (id, file) => {
    return file.id === id;
  };

  matchUnassignedImageIds = (file) => {
    return file.type === 'Unassigned' ? file.id : null;
  };

  matchUncheckedFile = (file) => {
    return !file.checked;
  };

  onDeleteImagesFailure = () => {
    this.getModal().setLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('UploadDroppedFiledModal.deleteImagesFailure')
    });
  }

  onDeleteImagesSuccess = () => {
    this.getModal().setLoading();
    this.setState({
      files: []
    });
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('UploadDroppedFiledModal.onDeleteImagesSuccess')
    });
    this.props.onDeleteAllFiles();
  };

  onDeleteSingleImageSuccess = (id) => {
    this.updateFilesData(id);
  };

  onFetchUnassignedImagesFailure = error => {
    LogController.logError(error);
  };

  onFetchUnassignedImagesSuccess = res => {
    const {images} = res.data.data;

    this.setState({
      files: this.state.files.concat(images)
    });

    this.getModal().clearLoading();
  };

  onImageCheckChange = (index, checked) => {
    this.setState(prevState => {
      const files = cloneDeep(prevState.files);
      files[index].checked = checked;
      return {
        allChecked: this.areAllImagesSelected(files),
        files
      };
    });
  };

  onModalClose = () => {
    const droppedUnassignedFiles = this.getDroppedUnassignedFiles();
    if(droppedUnassignedFiles.length) {
      const {t} = this.props;
      const modal = (
        <ConfirmationModal
          title={t('UploadDroppedFiledModal.confirmSave')}
          message={t('UploadDroppedFiledModal.saveQuestion')}
          yesBtnText={t('UploadDroppedFiledModal.saveImages')}
          noBtnText={t('UploadDroppedFiledModal.discardImages')}
          decline={this.closeModal}
          confirm={this.saveUnassignedImages.bind(this, droppedUnassignedFiles)}
        />
      );
      ModalController.showModal(modal);
    }else {
      this.closeModal();
    }
  };

  onSaveUnassignedImagesFailure = () => {
    this.getModal.clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('UploadDroppedFiledModal.onSaveUnassignedImagesFailure')
    });
  };

  onSaveUnassignedImagesSuccess = () => {
    this.closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('UploadDroppedFiledModal.onSaveUnassignedImagesSuccess')
    });
  };

  saveUnassignedImages = (droppedUnassignedFiles) => {
    const message = this.props.t('UploadDroppedFiledModal.savingImages');
    this.getModal().setLoading(message);

    postUnassignedImages(droppedUnassignedFiles)
      .then(this.onSaveUnassignedImagesSuccess)
      .catch(this.onSaveUnassignedImagesFailure);
  };

  setAllSelected = () => {
    this.setState(prevState => {
      let {files, allChecked} = prevState;
      allChecked = !allChecked;
      files = files.map(this.setFileSelected.bind(this, allChecked));
      return {
        allChecked: allChecked,
        files
      };
    });
  };

  setFileSelected = (selected, file) => {
    file.checked = selected;
    return file;
  };

  showBannerSelectionModal = () => {
    const modal = (
      <BannerSelectionModal 
        files={this.getSelectedFiles()} 
        clearAssignedImages={this.clearAssignedImages}  
      />
    );
    ModalController.showModal(modal);
  };

  showDeleteAllModal = () => {
    const {t} = this.props;
    const modal = (
      <ConfirmationModal
        title={t('UploadDroppedFiledModal.confirmDelete')}
        message={t('UploadDroppedFiledModal.deleteQuestion')}
        yesBtnText={t('UploadDroppedFiledModal.deleteImages')}
        noBtnText={t('UploadDroppedFiledModal.cancel')}
        confirm={this.deleteImages}
      />
    );
    ModalController.showModal(modal);
  };

  showDeleteSingleImageModal = (id, type) => {
    const {t} = this.props;
    const modal = (
      <ConfirmationModal
        title={t('UploadDroppedFiledModal.confirmDelete')}
        message={t('UploadDroppedFiledModal.deleteQuestion')}
        yesBtnText={t('UploadDroppedFiledModal.deleteSingleImage')}
        noBtnText={t('UploadDroppedFiledModal.cancel')}
        confirm={this.deleteSingleImage.bind(this, id, type)}
      />
    );
    ModalController.showModal(modal);
  };

  showMovieSelectionModal = () => {
    const modal = (
      <MovieSelectionModal 
        files={this.getSelectedFiles()}
        clearAssignedImages={this.clearAssignedImages}    
      />
    );
    ModalController.showModal(modal);
  };

  showStarSelectionModal = () => {
    const modal = (
      <StarSelectionModal 
        files={this.getSelectedFiles()}
        clearAssignedImages={this.clearAssignedImages}  
      />
    );
    ModalController.showModal(modal);
  };

  showStudioSelectionModal = () => {
    const modal = (
      <StudioSelectionModal 
        files={this.getSelectedFiles()}
        clearAssignedImages={this.clearAssignedImages}    
      />
    );
    ModalController.showModal(modal);
  };

  showUploadImagesModal = () => {
    const modal = (
      <UploadImagesModal uploadImages={this.addFiles}/>
    );
    ModalController.showModal(modal);
  };

  updateFilesData = (id) => {
    this.setState(prevState => {
      const files = cloneDeep(prevState.files);
      const index = files.findIndex(this.matchFileById.bind(this, id));
      files.splice(index, 1);
      return {
        files: files
      };
    });
    this.props.onDeleteFile(id);
    this.getModal().setLoading();
  };

  render() {
    const {t} = this.props;
    const {files} = this.state;
    return (
      <ModalDialog 
        title="Upload Files" 
        actions={this.getActions()}
        forwardedRef={this.modalRef}
        closeModal={this.onModalClose}
      >
        <div className="UploadDroppedFiledModal">
          <div className="UploadDroppedFiledModal-actions">
            <Button onClick={this.showDeleteAllModal} disabled={files.length === 0}>
              {t('UploadDroppedFiledModal.deleteAll')}
            </Button>
            <Button onClick={this.setAllSelected}>
              {this.state.allChecked ? t('UploadDroppedFiledModal.deselectAll') : t('UploadDroppedFiledModal.selectAll')}
              <Checkbox checked={this.state.allChecked}/>
            </Button>
          </div>
          <div className="UploadDroppedFiledModal-images">
            {files.map(this.getFileName)}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

UploadDroppedFiledModal.propTypes = {
  files: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withTranslation()(UploadDroppedFiledModal);