import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button} from 'antd';

import {
  addNewPlaylistMovie,
  getMoviesPlaylistById
} from '../../../../../../../../../../../../services/movies-service/movies.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../../../services/notification-service/notification.service';

import ModalDialog from '../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import PlaylistMovieSelect from './components/PlaylistMovieSelect/PlaylistMovieSelect';

import './AddMoviesModal.scss';

class AddMoviesModal extends Component {

  state = {
    selectedMovies: []
  };

  modalRef = React.createRef();


  addMovie = (movieId) => {
    const {playlistId} = this.props;
    return addNewPlaylistMovie(playlistId, movieId);
  };

  addMoviesToPlaylist = async () => {
    const idsToSend = await this.prepareMovieIdsToSend();
    const message = this.props.t('AddMoviesModal.addingMoviesPleaseWait');
    this.getModal().setLoading(message);
    let promises = idsToSend.map(this.addMovie);
    Promise.all(promises)
      .then(this.onAddToPlaylistSuccess)
      .catch(this.onAddToPlaylistFailure);
  };

  closeDialog = () => {
    this.getModal().closeModal();
  };

  getExistingIds = () => {
    const {playlistId} = this.props;
    return getMoviesPlaylistById(playlistId);
  };

  getMovieId = (item) => {
    return item.movie_id;
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className='AddMoviesModal-actions'>
          <Button 
            onClick={this.onCancel} 
            key='cancelBtn'>
            {t('AddMoviesModal.cancel')}
          </Button>
          <Button
            onClick={this.addMoviesToPlaylist}
            key='addMoviesBtn'
            disabled={this.isDisabled()}
            type='primary'>
            {t('AddMoviesModal.addMoviesBtn')}
          </Button>
      </div>
    );
  };
  getModal = () => {
    return this.modalRef.current;
  };

  getMovieIds = (movies) => {
    return movies.map(this.getMovieId);
  };

  getTitle = () => {
    return this.props.t('AddMoviesModal.addMovies');
  };

  isDisabled = () => {
    return this.state.selectedMovies.length === 0;
  };

  matchById = (movies_in_playlist, item) => {
    return !movies_in_playlist.includes(+item);
  }

  matchExistingIds = (movies_in_playlist, idsToSend) => {
    return idsToSend.filter(this.matchById.bind(this, movies_in_playlist));
  };

  onAddToPlaylistFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddMoviesModal.addMovieFailed')
    });
  };

  onAddToPlaylistSuccess = () => {
    this.closeDialog();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddMoviesModal.addMovieSuccess')
    });
    this.props.onMoviesListUpdate();
  };

  onCancel = () => {
    this.closeDialog();
  };

  onMoviesSelect = (selectedMovies) => {
    this.setState({
      selectedMovies: selectedMovies
    });
  };

  prepareMovieIdsToSend = async () => {
    let idsToSend = this.state.selectedMovies;
    const playlistData = await this.getExistingIds();
    const {movies_in_playlist} = playlistData.data.data;

    const movieIdsArray = this.getMovieIds(movies_in_playlist);
    const filteredIdsToSend = this.matchExistingIds(movieIdsArray, idsToSend);

    return filteredIdsToSend;
  };
  
  renderPlaylistMovieSelect = () => {
    return (
      <PlaylistMovieSelect 
        onMoviesSelect={this.onMoviesSelect}
      />
    );
  };

  render() {
    const {t} = this.props;
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='AddMoviesModal'>
          <div className="AddMoviesModal-description">{t('AddMoviesModal.description')}</div>
          {this.renderPlaylistMovieSelect()}
        </div>
      </ModalDialog>
    );
  };
};

export default withTranslation()(AddMoviesModal);