import React, { Component } from 'react';
import { Router } from "@reach/router";

import UsersMainView from '../UsersMainView/UsersMainView';

import NatsUsersView from '../NatsUsersView/NatsUsersView';
import RoyaltiesUsersView from '../RoyaltiesUsersView/RoyaltiesUsersView';

import './UsersMainSection.scss';

class UsersMainSection extends Component {

  renderBody = () => {
    return (
      <Router>
        <UsersMainView
          {...this.props}
          path={'/list/*'}
          users={this.props.listInfo.users}
          onFilterChange={this.props.onFilterChange}
          onPageChange={this.props.onPageChange}
          onAddUser={this.props.onAddUser}
          defaultFilters={this.props.defaultFilters}
          contentHeight={this.props.contentHeight}
          setUserEnabled={this.props.setUserEnabled}
          updateUserData={this.props.updateUserData}
        />
        <NatsUsersView
          path={'/nats/*'}
          contentHeight={this.props.contentHeight}
        />
        <RoyaltiesUsersView
          path={'/royalties/*'}
          contentHeight={this.props.contentHeight}
        />
      </Router>
    );
  };

  renderActiveUsers = () => {
    return <UsersMainView
      {...this.props}
      activeUsers={this.props.activeUsers}
      onExport={this.props.onExport}
      users={this.props.listInfo.users}
      onFilterChange={this.props.onFilterChange}
      onPageChange={this.props.onPageChange}
      onAddUser={this.props.onAddUser}
      defaultFilters={this.props.defaultFilters}
      contentHeight={this.props.contentHeight}
      setUserEnabled={this.props.setUserEnabled}
      updateUserData={this.props.updateUserData}
    />;
  };

  render() {
    return (
      <div className='UsersMainSection'>
        {this.props.activeUsers ? this.renderActiveUsers() : this.renderBody()}
      </div>
    );
  }
}

export default UsersMainSection;
