import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import ContentInfoView from "../../../MoviesView/MovieDetails/ContentInfoView/ContentInfoView";

import {getStarContentInfo} from "../../../../../../../../../../services/stars-service/stars.service";

class StarContentInfo extends ContentInfoView {

  fetchData = () => {
    const message = this.props.t('StarContentInfo.fetchingDataMsg');
    this.setLoading(message);
    this.getStarContentInfo()
      .then(this.loadData)
      .catch(this.onRequestFailure);
  };

  getStarContentInfo = () => {
    const {starId} = this.props;
    return getStarContentInfo(starId);
  };

  loadData = (response) => {
    const {data} = response.data;
    this.setState({
      data,
      dataLoaded: true,
      loadingMsg: ''
    });
  };
}

StarContentInfo.propTypes = {
  starId: PropTypes.string
};

export default withTranslation()(StarContentInfo);
