import PropTypes from 'prop-types';

import MoviesViewFooterAbstract from "../../../../MoviesView/MoviesMainView/MoviesViewFooter/MoviesViewFooterAbstract";

import './PropertyMoviesFooter.scss';

class PropertyMoviesFooter extends MoviesViewFooterAbstract {
  renderChildren = () => null;
}

PropertyMoviesFooter.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  recordCount: PropTypes.number.isRequired
};

export default PropertyMoviesFooter;
