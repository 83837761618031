import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Icon, Input } from "antd";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import LoadingIndicator from "../../../../../../../../../../../components/LoadingIndicator/LoadingIndicator";
import ModalDialog from "../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {
	getAdsConfig,
	getExistingNatsAdsConfigs,
	removeAdsConfig,
	setAdsConfig,
} from "../../../../../../../../../../../services/ads-configuration-service/ads-configuration.service";
import { LogController } from "../../../../../../../../../../../controllers/log-controller/log.controller";

import "./SelectNatsCodesModal.scss";

/* istanbul ignore file */
class SelectNatsCodesModal extends Component {
	modalRef = React.createRef();

	state = {
		loading: false,
		allNatsCodes: [],
		natsCodeName: "",
		natsCodes: [],
		selectedNatsCodes: {},
	};

	constructor(props) {
		super(props);
		this.state.selectedNatsCodes = cloneDeep(props.properties);
	}

	componentDidMount() {
		this.filterNatsCodes();
	}

	closeModal = () => {
		this.getModal().closeModal();
	};

	filterData = () => {
		const { allNatsCodes } = this.state;
		const natsCodes = allNatsCodes.filter(this.matchPropertyByName);
		this.setState({ natsCodes, loading: false });
	};

	filterNatsCodes = () => {
		const { allNatsCodes } = this.state;
		if (!allNatsCodes.length) {
			this.setLoading(true);
		}
		this.getAllExistingNatsCodes().then(this.filterData);
	};

	getActions = () => {
		const { selectedNatsCodes } = this.state;
		const { t } = this.props;
		const multipleSelected = Object.keys(selectedNatsCodes).length > 1;

		return (
			<div className="Actions">
				<Button className="DeleteCodes" onClick={this.deleteCodes}>
					{t(
						"ConfigureAdsOnNatsLevel.SelectNatsCodesModal.deleteCodes"
					)}
				</Button>
				<Button onClick={this.closeModal}>
					{t("ConfigureAdsOnNatsLevel.SelectNatsCodesModal.cancel")}
				</Button>
				<Button onClick={this.saveChanges} disabled={multipleSelected}>
					{t(
						"ConfigureAdsOnNatsLevel.SelectNatsCodesModal.saveCodes"
					)}
				</Button>
			</div>
		);
	};

	getModal = () => {
		return this.modalRef.current;
	};

	getAllExistingNatsCodes = () => {
		return new Promise(this.getNatsExecutor);
	};

	getNatsExecutor = (resolve, reject) => {
		const { allNatsCodes } = this.state;
		if (allNatsCodes.length) {
			resolve(allNatsCodes);
		} else {
			getExistingNatsAdsConfigs()
				.then(this.onGetNatsCodesSuccess.bind(this, resolve))
				.catch(this.onGetNatsCodesFailure.bind(this, reject));
		}
	};

	getTitle = () => {
		return this.props.t(
			"ConfigureAdsOnNatsLevel.SelectNatsCodesModal.title"
		);
	};

	matchPropertyByName = (name) => {
		const { natsCodeName } = this.state;
		return name.toLowerCase().indexOf(natsCodeName.toLowerCase()) !== -1;
	};

	onGetNatsCodesFailure = (reject, error) => {
		LogController.logError(error);
		reject();
	};

	onGetNatsCodesSuccess = (resolve, response) => {
		const allNatsCodes = response.data.data;
		this.setState({ allNatsCodes }, resolve);
	};

	onNatsNameFilterChange = (event) => {
		const { value: natsCodeName } = event.target;
		this.setState({ natsCodeName });
	};

	propertyMap = (code) => {
		const { selectedNatsCodes } = this.state;
		const checked = !!selectedNatsCodes[code];
		const classes = ["Property"];
		if (checked) {
			classes.push("Checked");
		}
		return (
			<div
				className={classes.join(" ")}
				key={code}
				onClick={this.setPropertyChecked.bind(this, code, code)}
			>
				<div className="Name">{code}</div>
				<Checkbox checked={checked} />
			</div>
		);
	};

	renderFilteredProperties = () => {
		const { natsCodes } = this.state;
		return natsCodes.map(this.propertyMap);
	};

	renderLoadingIndicator = () => {
		let view = null;
		const { loading } = this.state;
		if (loading) {
			const { t } = this.props;
			view = (
				<LoadingIndicator
					message={t("SelectNatsCodesModal.loadingCodesMsg")}
				/>
			);
		}

		return view;
	};

	renderNameFilter = () => {
		const { t } = this.props;
		return (
			<div className="NameFilter">
				<div className="Label">
					{t(
						"ConfigureAdsOnNatsLevel.SelectNatsCodesModal.searchByCodeName"
					)}
				</div>
				<div className="Actions">
					<Input.Search
						value={this.state.natsCodeName}
						onChange={this.onNatsNameFilterChange}
						onSearch={this.filterNatsCodes}
						autoFocus
						enterButton
					/>
					<Button
						className="AddNewCode"
						onClick={this.addNewNatsCode}
					>
						<Icon type="plus" />
					</Button>
				</div>
			</div>
		);
	};

	addNewNatsCode = () => {
		const { allNatsCodes, natsCodes, natsCodeName } = this.state;
		const codeExists = allNatsCodes.includes(natsCodeName);
		if (!codeExists && natsCodeName.length) {
			this.setState(
				{
					allNatsCodes: [...allNatsCodes, natsCodeName],
					natsCodes: [...natsCodes, natsCodeName],
				},
				() => {
					setAdsConfig({
						natsCodes: [natsCodeName],
						config: { ads_config: [] },
					});
				}
			);
		} else {
			this.filterNatsCodes();
		}
	};

	deleteCodes = async () => {
		const { selectedNatsCodes } = this.state;
		const promises = [];

		for (let natsCode in selectedNatsCodes) {
			promises.push(getAdsConfig({ natsCode: natsCode }));
		}

		const idsToRemove = await Promise.all(promises)
			.then((response) => response.map((item) => item.data.data.id))
			.catch((err) => console.log(err));

		if (idsToRemove.length) {
			Promise.all(idsToRemove.map((id) => removeAdsConfig(id)))
        		.then(() => {
					this.setState(
						{
							allNatsCodes: [],
							natsCodeName: "",
							natsCodes: [],
							selectedNatsCodes: {},
						},
						this.filterNatsCodes
					);
				})
        		.catch((err) => console.log(err))
		}
	};

	saveChanges = () => {
		const { selectedNatsCodes } = this.state;
		const { properties } = this.props;
		const data = {};
		for (let propertyId in selectedNatsCodes) {
			if (properties[propertyId]) {
				data[propertyId] = properties[propertyId];
			} else {
				data[propertyId] = selectedNatsCodes[propertyId];
			}
		}
		this.props.onPropertiesSelected(data);
		this.closeModal();
	};

	setLoading = (loading) => {
		this.setState({ loading });
	};

	setPropertyChecked = (id, name) => {
		this.setState((prevState) => {
			const selectedNatsCodes = cloneDeep(prevState.selectedNatsCodes);
			const checked = !selectedNatsCodes[id];
			if (checked) {
				selectedNatsCodes[id] = {
					id,
					name,
				};
			} else {
				delete selectedNatsCodes[id];
			}
			return { selectedNatsCodes };
		});
	};

	render() {
		return (
			<ModalDialog
				title={this.getTitle()}
				actions={this.getActions()}
				forwardedRef={this.modalRef}
				className="SelectPropertiesModal"
			>
				<div className="SelectPropertiesModal-inner">
					{this.renderNameFilter()}
					<div className="PropertiesList">
						{this.renderLoadingIndicator()}
						{this.renderFilteredProperties()}
					</div>
				</div>
			</ModalDialog>
		);
	}
}

SelectNatsCodesModal.propTypes = {
	properties: PropTypes.object.isRequired,
	onPropertiesSelected: PropTypes.func.isRequired,
};

export default withTranslation()(SelectNatsCodesModal);
