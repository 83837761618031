import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import MainList from "./MainList/MainList";

import './Playlist.scss';

class Playlist extends Component {

  calculateTableScroll = () => {
    const {contentHeight} = this.props;
    // Remove header height, footer height
    return contentHeight - 52 - 54 - 43;
  };

  render() {
    return (
      <div className="Playlist">
        <MainList
          data={this.props.playlistData}
          scroll={this.calculateTableScroll()}
          deletePlaylist={this.props.deletePlaylist}/>
      </div>
    );
  }
}

Playlist.propTypes = {
  category: PropTypes.string.isRequired
};

export default withTranslation()(Playlist);
