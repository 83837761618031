import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon} from "antd";

import MovieFilters from './MovieFilters/MovieFilters';
import StatusInfo from '../../StatusInfo/StatusInfo';

import {MoviesViewCommController} from '../../MoviesViewCommController/movies-view-comm.controller';

import './MoviesViewHeader.scss';


class MoviesViewHeader extends Component {

  state = {
    filtersDisplayed: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    MoviesViewCommController.triggerUpdate();
  }

  displayFilters = () => {
    this.setState(prevState => {
      return {
        filtersDisplayed: !prevState.filtersDisplayed
      };
    });
  };

  getIconType = () => {
    return this.state.filtersDisplayed ? 'up' : 'down';
  };

  render() {
    const classes = this.state.filtersDisplayed ? ['MoviesViewHeader', 'FilterDisplayed'] : ['MoviesViewHeader'];
    return (
      <div className={classes.join(' ')}>
        <div className="StatusInnerWrapper">
          <StatusInfo detailsMessage={this.props.detailsMessage} message={this.props.message}
                      status={this.props.status}/>
          <Icon type={this.getIconType()} onClick={this.displayFilters}/>
        </div>
        <MovieFilters sortCriteria={this.props.sortCriteria}
                      localizedDefaultSortFilter={this.props.localizedDefaultSortFilter}
                      generalLinks={this.props.generalLinks}
                      onClearFilter={this.props.onClearFilter}
                      onFilterChange={this.props.onStatusChange}
                      defaultDisplayFilter={this.props.defaultDisplayFilter}
                      defaultSortFilter={this.props.defaultSortFilter}
                      defaultNameFilter={this.props.defaultNameFilter}
                      additionalActions={this.props.additionalActions}/>
      </div>
    );
  }
}

MoviesViewHeader.propTypes = {
  additionalActions: PropTypes.element,
  defaultDisplayFilter: PropTypes.string.isRequired,
  defaultNameFilter: PropTypes.string,
  defaultSortFilter: PropTypes.string.isRequired,
  detailsMessage: PropTypes.string,
  generalLinks: PropTypes.array.isRequired,
  message: PropTypes.string,
  onClearFilter: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  sortCriteria: PropTypes.array.isRequired,
  status: PropTypes.string,
  localizedDefaultSortFilter: PropTypes.string
};

export default MoviesViewHeader;



