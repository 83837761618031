import i18n from '../../i18n';

export class TitleCtrl {
  static instance;

  static getInstance() {
    if (TitleCtrl.instance == null) {
      TitleCtrl.instance = new TitleCtrl();
    }

    return TitleCtrl.instance;
  }

  setDocumentTitle = (title) => {
    const appName = i18n.t('appName');
    let newTitle = appName;

    if(title) {
      newTitle = `${appName} | ${title}`;
    }

    document.title = newTitle;
  };
}

export const DocumentTitleController = TitleCtrl.getInstance();
