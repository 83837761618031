import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Table } from "antd";
import PropTypes from 'prop-types';

import { renderMomentWithHours } from "../../../../../../../../../../../services/date-service/date.service";
import BaseCard from "../../../../../../../../../../../components/BaseCard/BaseCard";
import './NatsUserInfo.scss';

class NatsUserInfo extends Component {

  generalColumns = [{
    dataIndex: 'name',
    width: 200
  }, {
    dataIndex: 'value'
  }];

  propertiesColumns = [{
    dataIndex: 'id',
    title: this.props.t('NatsUserInfo.id'),
    width: 200
  }, {
    dataIndex: 'name',
    title: this.props.t('NatsUserInfo.property')
  }, {
    dataIndex: 'registeredOn',
    title: this.props.t('NatsUserInfo.registeredOn')
  }];

  getAccountInfoTableData = () => {
    const { t } = this.props;
    const {
      memberid,
      username,
      email,
      joined,
      updated_at,
      status,
      optionid,
      downloads_allowed
    } = this.props.data;
    return [{
      name: t('NatsUserInfo.userID'),
      value: memberid,
      key: memberid
    }, {
      name: t('NatsUserInfo.optionID'),
      value: optionid,
      key: t('NatsUserInfo.optionID')
    }, {
      name: t('NatsUserInfo.login'),
      value: username,
      key: t('NatsUserInfo.login')
    }, {
      name: t('NatsUserInfo.email'),
      value: email,
      key: t('NatsUserInfo.email')
    }, {
      name: t('NatsUserInfo.status'),
      value: this.renderStatusButton(status),
      key: t('NatsUserInfo.status')
    }, {
      name: t('NatsUserInfo.downloadAllowed'),
      value: this.renderDownloadAllowedButton(downloads_allowed),
      key: t('NatsUserInfo.downloadAllowed')
    }, {
      name: t('NatsUserInfo.createdDate'),
      value: renderMomentWithHours(joined),
      key: t('NatsUserInfo.createdDate')
    }, {
      name: t('NatsUserInfo.updatedDate'),
      value: renderMomentWithHours(updated_at),
      key: t('NatsUserInfo.updatedDate')
    }];
  };

  getPropertiesTableData = () => {
    const { properties, joined } = this.props.data;
    return [{
      id: properties.id,
      name: properties.name,
      registeredOn: renderMomentWithHours(joined),
      key: 1
    }];
  };

  getPropertiesMinViewTableData = () => {
    const { t } = this.props;
    const { properties, joined } = this.props.data;
    return [{
      name: t('NatsUserInfo.id'),
      value: properties.id,
      key: 1
    }, {
      name: t('NatsUserInfo.property'),
      value: properties.name,
      key: 2
    }, {
      name: t('NatsUserInfo.registeredOn'),
      value: renderMomentWithHours(joined),
      key: 3
    }];
  };

  renderAccountUserInfoTable = () => {
    const data = this.getAccountInfoTableData();
    return this.renderTable(data, this.generalColumns, false);
  };

  renderButton = (text, className) => {
    return (
      <div className="StatusItem">
        <Button className={className}>
          {text}
        </Button>
      </div>
    );
  };

  renderDownloadAllowedButton = (downloadAllowed) => {
    const { text, className } = this.getButtonsInfo(downloadAllowed);
    return this.renderButton(text, className);
  };

  renderStatusButton = (status) => {
    const { text, className } = this.getButtonsInfo(status, 'status');
    return this.renderButton(text, className);
  };

  getButtonsInfo = (value, key) => {
    const { t } = this.props;
    let text;
    let className;

    switch (parseInt(value)) {
      case 0:
        text = key === 'status' ? 'NatsUsersViewBody.neverJoined' : 'Not allowed';
        className = 'NeverJoined';
        break;
      case 1:
        text = key === 'status' ? 'NatsUsersViewBody.active' : 'Allowed';
        className = 'Active';
        break;
      default:
        text = 'NatsUsersViewBody.expired';
        className = 'Expired';
        break;
    }
    return { text: t(text), className };
  };

  renderPropertiesMaxTable = () => {
    const data = this.getPropertiesTableData();
    return this.renderTable(data, this.propertiesColumns, true);
  };

  renderPropertiesMinTable = () => {
    const data = this.getPropertiesMinViewTableData();
    return this.renderTable(data, this.generalColumns, false);
  };

  renderTable = (data, columns, showHeader) => {
    return (
      <Table dataSource={data}
        bordered
        columns={columns}
        pagination={false}
        showHeader={showHeader} />
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className="NatsUserInfo">
        <BaseCard title={t('NatsUserInfo.accountInfo')}>
          {this.renderAccountUserInfoTable()}
        </BaseCard>
        <BaseCard title={t('NatsUserInfo.propertiesInfo')}>
          {this.renderPropertiesMaxTable()}
          {this.renderPropertiesMinTable()}
        </BaseCard>
      </div>
    );
  }
}

NatsUserInfo.propTypes = {
  data: PropTypes.object.isRequired
};

export default withTranslation()(NatsUserInfo);
