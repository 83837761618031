import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {
  editManufacturer,
  getManufacturerById
} from '../../../../../../../../../services/manufacturers-service/manufacturers.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../services/notification-service/notification.service';
import { getManufacturersRoute } from '../../../../../../../../../services/navigation/manufacturers-navigation/manufacturers-navigation-routes.service';

import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import {
  ManufacturerDetailsCommController,
  ManufacturerDetailsEventMap
} from '../manufacturer-details-comm.controller';

import ManufacturerDetailsTable from './ManufacturerDetailsTable/ManufacturerDetailsTable';
import BaseNavigationItem from "../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';

import './ManufacturerDetails.scss';

class ManufacturerDetails extends Component {

  state = {
    data: {},
    dataLoaded: false,
    loading: false
  };

  componentDidMount() {
    this.getManufacturerDetails();
  };

  getDetailsBody = () => {
    const { data } = this.state;
    return (
      <div className="ManufacturerDetailsBody">
        <ManufacturerDetailsTable {...data}
          onManufacturerEdit={this.onManufacturerEdit} />
      </div>
    )
  };

  getLicensor = licensor => {
    return (
      licensor ?
        {
          id: licensor.id,
          name: licensor.name
        } : this.props.t('ManufacturerDetails.notExist')
    );
  };

  getManufacturerDetails = () => {
    this.setLoading(true);
    getManufacturerById(this.props.id)
      .then(this.onGetManufacturerSuccess)
      .catch(this.onGetManufacturerFailure);
  };

  notifyDataChange = () => {
    ManufacturerDetailsCommController.fireEvent(
      'manufacturer',
      ManufacturerDetailsEventMap.manufacturer.editFinishedEvent,
      this.state.data.id
    );
    this.getManufacturerDetails();
  };

  onGetManufacturerFailure = () => {
    this.setLoading(false);
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('ManufacturerDetails.dataGetFailed')
    });
  };

  onGetManufacturerSuccess = (response) => {
    const { t } = this.props;
    const manufacturer = response.data.data;
    this.setDocumentTitle(manufacturer);
    this.setState({
      data: {
        id: manufacturer.id,
        licensor: this.getLicensor(manufacturer.licensor),
        name: manufacturer.name || t('ManufacturerDetails.notExist')
      },
      dataLoaded: true,
      loading: false
    });
  };

  onManufacturerEdit = ({ name, licensorId }, callback) => {
    this.setLoading(true);

    const { id: manufacturerId } = this.props;
    const postData = { name, licensors_id: licensorId };

    editManufacturer(manufacturerId, postData)
      .then(this.onManufacturerEditSuccess.bind(this, callback))
      .catch(this.onManufacturerEditFailure);
  };

  onManufacturerEditFailure = (error) => {
    this.setLoading(false);
    displayErrorNotification({
      duration: 3,
      message: this.props.t('ManufacturerDetails.editManufacturerFailure')
    });
  }

  onManufacturerEditSuccess = (callback) => {
    this.setLoading(false);
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('ManufacturerDetails.editManufacturerSuccess')
    });
    callback();
    this.notifyDataChange();
  };

  renderTitle = () => {
    const { t } = this.props;
    const { data } = this.state;
    const routes = [{
      url: getManufacturersRoute(),
      text: t('ManufacturerDetails.manufacturers')
    }, {
      text: data?.name || ''
    }];

    return <BaseNavigationItem routes={routes} />;
  };

  setDocumentTitle = (data) => {
    const title = this.props.t('ManufacturerDetails.documentTitle', { name: data.name });
    DocumentTitleController.setDocumentTitle(title);
  };

  setLoading = (loading) => {
    this.setState({
      loading: loading
    });
  };

  render() {
    const { t } = this.props;

    return (
      <LoadingWrapper
        className='ManufacturerDetails'
        dataLoaded={this.state.dataLoaded}
        isLoading={this.state.loading}
        loadingMsg={t('ManufacturerDetails.loadingPleaseWait')}
      >
        {this.renderTitle()}
        {this.getDetailsBody()}
      </LoadingWrapper>
    );
  };
}

export default withTranslation()(ManufacturerDetails);
