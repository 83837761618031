import {navigate} from "@reach/router";

import {
  getManufacturersRoute,
  getManufacturerDetailsRoute
} from "./manufacturers-navigation-routes.service";

/**
 * Navigates to manufacturers view
 *
 * @returns {Promise<any>}
 */
export const showManufacturers = () => {
  return navigate(getManufacturersRoute());
};

/**
 * Navigates to manufacturer details view
 *
 * @param {number|string} manufacturerId
 *
 * @returns {Promise<any>}
 */
export const showManufacturerDetails = manufacturerId => {
  return navigate(getManufacturerDetailsRoute(manufacturerId));
};