import React from 'react';
import { TimePicker } from "antd";
import PropTypes from 'prop-types';

import './TimeSelector.scss';

const TimeSelector = props => {
  return (
    <div className="TimeSelector">
      <div>{props.label}</div>
      <TimePicker value={props.value}
        allowClear={false}
        onChange={props.onChange} />
    </div>
  );
};

TimeSelector.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
};

export default TimeSelector;
