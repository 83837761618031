import React, { Component } from 'react';
import './ErrorBoundary.scss';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { errorMessage: '', errorInfo: '' };
  }

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error.toString(), errorInfo);
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <div className='ErrorBoundary'>
          <h3>Something went wrong!</h3>
          <p>{this.state.errorMessage}</p>
          <button onClick={() => window.location.replace('/')}>Return to home page</button>
        </div>
      )
    }

    return this.props.children;
  }
};

export const ErrorMessage = () => {
  return (
    <div className='ErrorMessage'>
      <span>Oops! Something went wrong...</span>
    </div>
  )
};