import React, {Component} from 'react';

import {ModalController} from '../../controllers/modal-controller/modal.controller';
import './ModalContainer.scss';

class ModalContainer extends Component {
  state = {
    data: []
  };
  subscription;
  startingZIndex = 200;

  componentDidMount() {
    this.subscription = ModalController.modalAdded.subscribe(this.updateModals);
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateModals = (data) => {
    this.setState({
      data: data
    })
  };

  render() {
    const classes = ["ModalContainer"];
    if (this.state.data.length) {
      classes.push("Visible");
    }

    return (
      <div className={classes.join(' ')}>
        {this.state.data.map((item, index) => {
          return (
            <div key={index} style={{zIndex: this.startingZIndex + index, position: 'absolute'}}
                 className="InnerWrapper">
              <div className="Background" style={{zIndex: this.startingZIndex + index + 1}}/>
              <div style={{zIndex: this.startingZIndex + index + 2}} className="ModalWrapper">
                {item}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ModalContainer;

