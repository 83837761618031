import { ROUTES } from "../../../routes";
import { MAIN_DASHBOARD_VIEW_ROUTES } from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";

export const getPayoutsRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.PAYOUTS}`;
};

export const getPayoutPropertiesPeriodRoute = (year, month) => {
  return `${getPayoutsRoute()}/properties/period/${year}/${month}`;
};

export const getPayoutLicensorsPeriodRoute = (year, month) => {
  return `${getPayoutsRoute()}/licensors/period/${year}/${month}`;
};

export const getPayoutPropertyRoute = (propertyId, year, month) => {
  return `${getPayoutPropertiesPeriodRoute(year, month)}/property/${propertyId}`;
};

export const getPayoutsLicensorRoute = (propertyId, licensorId, year, month) => {
  return `${getPayoutPropertyRoute(propertyId, year, month)}/licensors/${licensorId}`;
};