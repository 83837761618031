import {ROUTES} from "../../../routes";
import {MAIN_DASHBOARD_VIEW_ROUTES} from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";

/**
 * Returns studios view route
 *
 * @returns {string}
 */
export const getStudiosRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.STUDIOS}`;
};

export const getStudioDetailsRootRoute = (studioId) => {
  return `${getStudiosRoute()}/${studioId}`;
};

/**
 * Returns studio details route
 *
 * @param {number|string} studioId
 *
 * @returns {string}
 */
export const getStudioDetailsRoute = (studioId) => {
  return `${getStudioDetailsRootRoute(studioId)}/details`;
};

/**
 * Returns studio movies route
 *
 * @param {string|number} studioId
 *
 * @returns {string}
 */
export const getStudioMoviesRoute = studioId => {
  return `${getStudioDetailsRootRoute(studioId)}/movies`;
};

/**
 * Returns studio movie details route
 *
 * @param {number|string} studioId
 * @param {number|string} movieId
 *
 * @returns {string}
 */
export const getStudioMovieDetailsRoute = (studioId, movieId) => {
  return `${getStudioMoviesRoute(studioId)}/${movieId}`;
};