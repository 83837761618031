import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Table, Collapse, List, Tooltip} from "antd";
import PropTypes from 'prop-types';

import {renderMoment} from "../../../../../../../../../../../services/date-service/date.service";
import {getScrollHeight} from "../../../../../../../../../../../services/util-service/util.service";

import BaseCard from '../../../../../../../../../../../components/BaseCard/BaseCard';

import './NatsMembershipInfo.scss';

class NatsMembershipInfo extends Component {

  tableRowHeight = 159;

  calculateTableScroll = transactions => {
    const {contentHeight} = this.props;
    const reductionHeight = 52 + 54 + 38 + 38 + 41 + 45 + 46 + 34;
    return getScrollHeight(contentHeight, transactions, this.tableRowHeight, reductionHeight);
  };

  renderDetailUrl = (detailURL, item) => {
    if(item) {
      const {detail} = item;
      const filteredDetail = detail.replace('RGNATIVE:', '');
      const {t} = this.props;
      return (
        <span>
          {t('NatsMembershipInfo.rocketGate')}
          <a href={detailURL} target="_blank" rel="noopener noreferrer">
            {filteredDetail}
          </a>
        </span>
      );
    }
  };

  renderTooltip = item => {
    return (
      <Tooltip placement="topLeft" title={item}>
        {item}
      </Tooltip>
    );
  };

  getActiveKeys = subscriptions => {
    //returns array of indexes for default expanded panels
    let activeArray = [];
    for (let index = 0; index < subscriptions.length; index++) {
      activeArray.push(index);
    }
    return activeArray;
  };

  columns = [
    {
      title: this.props.t('NatsMembershipInfo.date'),
      dataIndex: 'date',
      className: 'DateColumn',
      render: renderMoment,
      width: 200
    }, {
      title: this.props.t('NatsMembershipInfo.property'),
      dataIndex: ['properties', 'name'],
      width: 120
    }, {
      title: this.props.t('NatsMembershipInfo.type'),
      dataIndex: 'type',
      className: 'TypeColumn',
      render: this.renderTooltip,
      width: 400
    }, {
      title: this.props.t('NatsMembershipInfo.amount'),
      dataIndex: 'amount',
      width: 100
    }, {
      title: this.props.t('NatsMembershipInfo.currency'),
      dataIndex: 'currency',
      width: 100
    }, {
      title: this.props.t('NatsMembershipInfo.usdAmount'),
      dataIndex: 'usd_amount',
      width: 200
    }, {
      title: this.props.t('NatsMembershipInfo.transactionColumn'),
      dataIndex: 'detail_url',
      render: this.renderDetailUrl
    }
  ];

  renderCollapsePanels = (item, index) => {
    const {transactions} = item;
    const reversedTransactions = transactions.reverse();
    return (
      <Collapse.Panel 
        header={this.renderSubscriptionUrl(item)} 
        className="SubscriptionPanel"
        key={index}
      >
        {this.renderTransactiosTable(reversedTransactions)}
        {this.renderTransactiosList(reversedTransactions)}
      </Collapse.Panel>
    );
  };

  renderTransactiosTable = transactions => {
    return (
      <Table
        dataSource={transactions}
        rowKey='detail'
        bordered
        columns={this.columns}
        pagination={false}
        scroll={this.calculateTableScroll(transactions)}
      />
    );
  };

  renderTransactionsListItem = item => {
    const {date, properties, type, amount, currency, usd_amount, detail, detail_url} = item;
    const {t} = this.props;
    return (
      <List.Item key={detail}>
        <BaseCard
          title={`${t('NatsMembershipInfo.transaction', {detail})}`}
        >
          <div className='ListCardWrapper'>
            <p>
              <span className='ListItemLabel'>
                {t('NatsMembershipInfo.date')}:
              </span>
              {renderMoment(date)}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('NatsMembershipInfo.property')}:
              </span>
              {properties.name}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('NatsMembershipInfo.type')}:
              </span>
              {type}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('NatsMembershipInfo.amount')}:
              </span>
              {amount}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('NatsMembershipInfo.currency')}:
              </span>
              {currency}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('NatsMembershipInfo.usdAmount')}:
              </span>
              {usd_amount}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('NatsMembershipInfo.transactionColumn')}:
              </span>
              {this.renderDetailUrl(detail_url, item)}
            </p>
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  renderTransactiosList = transactions => {
    return (
      <List
        dataSource={transactions}
        renderItem={this.renderTransactionsListItem}
        split={false}
        itemLayout='vertical'
      />
    );
  };

  renderSubscriptionCollapse = () => {
    const {subscriptions} = this.props.data;
    return (
      <Collapse defaultActiveKey={this.getActiveKeys(subscriptions)} className="CollapseContainer">
        {subscriptions.map(this.renderCollapsePanels)}
      </Collapse>
    );
  };

  renderSubscriptionUrl = (item) => {
    const {t} = this.props;
    return (
      <div className="Subscription-text">
        <a href={item.subscription_url} target="_blank" rel="noopener noreferrer">
          <span>{t('NatsMembershipInfo.subscriptionId')}</span>
          {item.subscription_id}
        </a>
      </div>
    );
  };

  renderTable = (data, columns) => {
    return (
      <Table 
        dataSource={data}
        bordered
        pagination={false}
        columns={columns}
        showHeader={false}
        rowKey='name'
      />
    );
  };

  render() {
    return (
      <div className="NatsMembershipInfo">
        <div>
          {this.renderSubscriptionCollapse()}
        </div>
      </div>
    );
  }
}

NatsMembershipInfo.propTypes = {
  data: PropTypes.object.isRequired
};

export default withTranslation()(NatsMembershipInfo);
