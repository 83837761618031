import React, { Component } from 'react';
import { Button, Icon } from "antd";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BannerFilters from './BannerFilters/BannerFilters';

import {
  getBannersRoute,
  getBannerSetsRoute
} from "../../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";
import {
  showBanners,
  showBannerSets
} from "../../../../../../../../../../services/navigation/banners-navigation/banners-navigation.service";

import BaseHorizontalNavigation
  from "../../../../../../../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";

import './BannersViewHeader.scss';

class BannersViewHeader extends Component {

  state = {
    filterDisplayed: false
  };

  getActions = () => {
    const { t } = this.props;
    return [
      <Button onClick={this.props.showNewBannerSetModal}
        key="createNewBannerSet">
        {t('BannersViewHeader.createNewBannerSet')}
      </Button>,
      <Button onClick={this.props.publishUnpublishedBanners}
        key="publishUnpublishedBanners">
        {t('BannersViewHeader.publishUnpublishedBanners')}
      </Button>,
      <Button onClick={this.props.onBannerAdd}
        key="addBannerBtn">
        {t('BannersViewHeader.addBanner')}
      </Button>,
      <Button onClick={this.props.showUploadBannersModal}
        key="uplodatBannersBtn">
        {t('BannersViewHeader.uploadBanners')}
      </Button>,
      <Button onClick={this.props.deleteMultipleBanners}
        disabled={!this.props.bannersToDelete.length}
        key="deleteSelectedBannersBtn">
        {t('BannersViewHeader.deleteMultipleBanners')}
      </Button>
    ];
  };

  getFilterToggleIcon = () => {
    return this.state.filterDisplayed ? 'up' : 'down';
  };

  showBanSets = () => {
    this.props.onToggleBannerSets(false);
    showBannerSets();
  }

  getNavigationItems = () => {
    const { t } = this.props;
    return [{
      link: getBannersRoute(),
      name: t('BannersViewHeader.allBanners'),
      navigate: showBanners
    }, {
      link: getBannerSetsRoute(),
      name: t('BannersViewHeader.defaultBannerSets'),
      navigate: this.showBanSets
    }];
  };

  toggleFilters = () => {
    this.setState(prevState => {
      return {
        filterDisplayed: !prevState.filterDisplayed
      };
    });
  };

  render() {
    const { t } = this.props;
    const classes = this.state.filterDisplayed ? ['BannersViewHeader', 'FiltersDisplayed'] : ['BannersViewHeader'];
    return (
      <div className={classes.join(' ')}>
        <div className="BannersViewHeader-inner">
          <div className="BannersViewHeader-primary">
            <div className='RecordCountMessage'>
              {t('BannersViewHeader.recordFound', {
                recordCount: this.props.bannersTotal
              })}
            </div>
            <Icon className="BannersViewHeader-toggle"
              onClick={this.toggleFilters}
              type={this.getFilterToggleIcon()} />
          </div>
          <BannerFilters
            defaultDisplayFilter={this.props.defaultDisplayFilter}
            generalLinks={this.props.generalLinks}
            onFilterChange={this.props.filterByStatus} />
        </div>
        <BaseHorizontalNavigation items={this.getNavigationItems()}>
          {this.getActions()}
        </BaseHorizontalNavigation>
      </div>
    );
  }
}

BannersViewHeader.propTypes = {
  bannersTotal: PropTypes.number.isRequired,
  defaultDisplayFilter: PropTypes.string.isRequired,
  filterByStatus: PropTypes.func.isRequired,
  generalLinks: PropTypes.array.isRequired,
  onBannerAdd: PropTypes.func.isRequired,
  publishUnpublishedBanners: PropTypes.func.isRequired,
  showNewBannerSetModal: PropTypes.func.isRequired,
  deleteMultipleBanners: PropTypes.func.isRequired,
  bannersToDelete: PropTypes.arrayOf(PropTypes.number)
};

export default withTranslation()(BannersViewHeader);
