import axios from 'axios';

export const urls = {
  manufacturers: '/manufacturers'
};

export const getManufacturers = (page, filters) => {
  const params = {
    page
  };

  if (filters?.search) {
    params.name = filters.search;
  }

  if (filters?.sort) {
    const { name, ascending } = filters.sort;
    params.sort_by = name;
    params.sort_order = ascending ? 'asc' : 'desc';
  }

  return axios.get(`${urls.manufacturers}`, {
    params
  });
};

export const getManufacturerById = (id) => {
  return axios.get(`${urls.manufacturers}/${id}`);
};

export const editManufacturer = (id, data) => {
  return axios.patch(`${urls.manufacturers}/edit/${id}`, data);
};
