import React, {Component} from 'react';
import {cloneDeep} from 'lodash';
import {withTranslation} from "react-i18next";
import {Button} from "antd";

import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import SelectPropertiesModal from "./SelectPropertiesModal/SelectPropertiesModal";
import PropertyPlayerConfigCollapse from "./PropertyPlayerConfigCollapse/PropertyPlayerConfigCollapse";

import {
  getPropertyConfig,
  savePropertyPlayerConfig
} from "../../../../../../../../../services/player-configuration-service/player-configuration-service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

import {LogController} from "../../../../../../../../../controllers/log-controller/log.controller";
import {ModalController} from "../../../../../../../../../controllers/modal-controller/modal.controller";

import './ConfigurePlayer.scss';

/* istanbul ignore file */
class ConfigurePlayer extends Component {

  state = {
    isLoading: false,
    properties: {}
  };

  modalRef = React.createRef();

  componentDidMount() {
    this.displaySelectPropertiesDialog();
  }

  clearLoading = () => {
    this.getModal().clearLoading();
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  displaySelectPropertiesDialog = () => {
    const {properties} = this.state;
    const modal = (
      <SelectPropertiesModal
        properties={properties}
        onPropertiesSelected={this.setSelectedProperties}
      />
    );
    ModalController.showModal(modal);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.displaySelectPropertiesDialog}>
          {t('ConfigurePlayer.addProperties')}
        </Button>
        <div>
          <Button onClick={this.closeModal}>
            {t('ConfigurePlayer.cancel')}
          </Button>
          <Button onClick={this.onSaveChanges}>
            {t('ConfigurePlayer.saveChanges')}
          </Button>
        </div>
      </div>
    );
  };

  getAddPropertiesButton = (isLink) => {
    const {t} = this.props;
    const props = {
      onClick: this.displaySelectPropertiesDialog
    };
    if (isLink) {
      props.type = 'link';
    }
    return (
      <Button {...props}>
        {t(`ConfigurePlayer.addProperties`)}
      </Button>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getPropertiesConfig = (properties, newProperties) => {
    const propertyIds = Object.keys(newProperties);
    const message = this.props.t('ConfigurePlayer.loadingPleaseWait');
    this.getModal().setLoading(message);
    this.setState({isLoading: true});

    const promises = propertyIds.map(this.getPropertyConfig);
    Promise.all(promises)
      .then(this.onGetPropertiesConfigSuccess.bind(this, properties))
      .catch(this.onGetPropertiesConfigFailure);
  };

  getPropertyConfig = propertyId => {
    return getPropertyConfig(propertyId);
  };

  getTitle = () => {
    const {t} = this.props;
    return t('ConfigurePlayer.title');
  };

  mapPropertyPromisesForPost = () => {
    const promises = [];
    const {properties} = this.state;
    const propertyIds = Object.keys(properties);
    let item = propertyIds.length;
    let currentId;
    let currentProperty;
    let data;
    while (item) {
      item--;
      currentId = propertyIds[item];
      currentProperty = properties[currentId];
      const {hasPreviousConfig} = currentProperty;
      data = this.mapPropertyDataForPost(currentId);
      if (data || hasPreviousConfig) {
        promises.push(this.savePropertyPlayerConfig(currentId, data));
      }
    }

    return promises;
  };

  mapPropertyDataForPost = (propertyId) => {
    let data = '';
    const {properties} = this.state;
    const {config, configValues, studios: propertyStudios, id, name, deleteAll} = properties[propertyId];
    if (!deleteAll) {
      const studios = this.mapPropertyStudiosForPost(propertyStudios);
      if (config) {
        data = {config, id, name, configValues};
        if (Object.keys(studios).length) {
          data.studios = studios;
        }
      } else if (Object.keys(studios).length) {
        data = {id, name, studios};
      }
    }

    return data;
  };

  mapPropertyStudiosForPost = (propertyStudios) => {
    const data = {};
    if (propertyStudios) {
      let studio;
      for (const studioId in propertyStudios) {
        studio = propertyStudios[studioId];
        const {config, id, name, movies: studioMovies, deleteAll} = studio;
        if (!deleteAll) {
          const movies = this.mapStudioMoviesForPost(studioMovies);
          if (config) {
            data[studioId] = {id, name, config};
            if (Object.keys(movies).length) {
              data[studioId].movies = movies;
            }
          } else if (Object.keys(movies).length) {
            data[studioId] = {id, name, movies};
          }
        }
      }
    }

    return data;
  };

  mapStudioMoviesForPost = (movies) => {
    const data = {};
    if (movies) {
      let movie;
      for (const movieId in movies) {
        movie = movies[movieId];
        const {config, id, title, deleteAll} = movie;
        if (!deleteAll && config) {
          data[movieId] = {id, title, config};
        }
      }
    }

    return data;
  };

  markForDeletion = (data, keys) => {
    if (keys.length) {
      let key = keys.splice(0, 1)[0];
      if (data[key]) {
        for (const id in data[key]) {
          data[key][id].deleteAll = true;
          if (keys.length) {
            this.markForDeletion(data[key][id], keys);
          }
        }
      }
    }

    return data;
  };

  onGetPropertiesConfigFailure = error => {
    this.getModal().clearLoading();
    LogController.logError(error)
    this.setState({isLoading: false});
  };

  onGetPropertiesConfigSuccess = (properties, response) => {
    this.getModal().clearLoading();
    for (let index = 0; index < response.length; index++) {
      const element = response[index];
      const {data} = element.data;
      const {propertyPlayerConfig} = data;
      if (propertyPlayerConfig && Object.keys(propertyPlayerConfig).length) {
        properties[data.propertiyId] = this.setHasPreviousConfig(data.propertyPlayerConfig);
      }
    }
    this.setState({
      isLoading: false,
      properties: properties
    });
  };

  onSaveChanges = () => {
    const message = this.props.t('ConfigurePlayer.savingMsg');
    this.getModal().setLoading(message);

    const {properties} = this.state;
    const propertyIds = Object.keys(properties);
    const promises = this.mapPropertyPromisesForPost(propertyIds);
    Promise.all(promises)
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  onSaveChangesFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('ConfigurePlayer.saveFailureMsg')
    });
  };

  onSaveChangesSuccess = () => {
    this.clearLoading();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('ConfigurePlayer.saveSuccessMsg')
    });
    this.closeModal();
  };

  savePropertyPlayerConfig = (propertyId, data) => {
    return savePropertyPlayerConfig(propertyId, data);
  };

  removeMovie = (propertyId, studioId, movieId) => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      const movie = properties[propertyId].studios[studioId].movies[movieId];
      const {hasPreviousConfig} = movie;
      if (hasPreviousConfig) {
        movie.deleteAll = true;
      } else {
        delete properties[propertyId].studios[studioId].movies[movieId];
      }
      return {properties};
    });
  };

  removeProperty = (propertyId) => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      const property = properties[propertyId];
      if (property.hasPreviousConfig) {
        property.deleteAll = true;
        properties[propertyId] = this.markForDeletion(property, ['studios', 'movies']);
      } else {
        delete properties[propertyId];
      }
      return {properties};
    });
  };

  removePropertyConfiguration = propertyId => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      const property = properties[propertyId];
      if (property.hasPreviousConfig) {
        property.deleteConfig = true;
      } else {
        delete property.config;
      }
      return {properties};
    });
  };

  removeStudio = (propertyId, studioId) => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      const studio = properties[propertyId].studios[studioId];
      if (studio.hasPreviousConfig) {
        studio.deleteAll = true;
        properties[propertyId].studios[studioId] = this.markForDeletion(studio, ['movies']);
      } else {
        delete properties[propertyId].studios[studioId];
      }
      return {properties};
    });
  };

  removeStudioConfiguration = (propertyId, studioId) => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      const studio = properties[propertyId].studios[studioId];
      if (studio.hasPreviousConfig) {
        studio.deleteConfig = true;
      } else {
        delete studio.config;
      }
      return {properties};
    });
  };

  renderMainView = () => {
    const {isLoading, properties} = this.state;
    let view = null;
    const hasProperties = Object.keys(properties).length;
    if (isLoading) {
      if (hasProperties) {
        view = this.renderPropertyCollapse();
      }
    } else {
      view = hasProperties ? this.renderPropertyCollapse() : this.renderNoPropertiesSelected();
    }

    return view;
  };

  renderNoPropertiesSelected = () => {
    const {t} = this.props;
    return (
      <div className="NoProperties">
        {t(`ConfigurePlayer.noProperties`)}
        {this.getAddPropertiesButton(true)}
      </div>
    );
  };

  renderPropertyCollapse = () => {
    const {properties} = this.state;
    return (
      <PropertyPlayerConfigCollapse
        properties={properties}
        onRemoveProperty={this.removeProperty}
        onRemoveStudio={this.removeStudio}
        onRemoveMovie={this.removeMovie}
        onPropertyPlayerChange={this.savePropertyPlayer}
        onStudioPlayerChange={this.saveStudioPlayer}
        onMoviePlayerChange={this.saveMoviePlayer}
        setSelectedStudios={this.setSelectedStudios}
        setSelectedMovies={this.setSelectedMovies}
        removePropertyConfiguration={this.removePropertyConfiguration}
        removeStudioConfiguration={this.removeStudioConfiguration}
      />
    );
  };

  saveMoviePlayer = (propertyId, studioId, movieId, config) => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      properties[propertyId].studios[studioId].movies[movieId].config = config;
      return {properties};
    });
  };

  savePropertyPlayer = (propertyId, config, configValues) => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      properties[propertyId].config = config;
      properties[propertyId].configValues = configValues;
      return {properties};
    });
  };

  saveStudioPlayer = (propertyId, studioId, config) => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      properties[propertyId].studios[studioId].config = config;
      return {properties};
    });
  };

  setHasPreviousConfig = (data) => {
    data.hasPreviousConfig = true;
    const {studios} = data;
    if (studios) {
      let currentStudio;
      for (const studioId in studios) {
        currentStudio = studios[studioId];
        currentStudio.hasPreviousConfig = true;
        const {movies} = currentStudio;
        if (movies) {
          for (const movieId in movies) {
            movies[movieId].hasPreviousConfig = true;
          }
        }
      }
    }

    return data;
  };

  setSelectedMovies = (propertyId, studioId, movies) => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      properties[propertyId].studios[studioId].movies = movies;
      return {properties};
    });
  };

  setSelectedProperties = (properties, newSelected) => {
    if (Object.keys(newSelected).length) {
      this.getPropertiesConfig(properties, newSelected);
    } else {
      this.setState({properties});
    }
  };

  setSelectedStudios = (propertyId, studios) => {
    this.setState(prevState => {
      const properties = cloneDeep(prevState.properties);
      properties[propertyId].studios = studios;
      return {properties};
    });
  };

  render() {
    const mainView = this.renderMainView();
    return (
      <ModalDialog forwardedRef={this.modalRef}
                   title={this.getTitle()}
                   actions={this.getActions()}
                   className="ConfigurePlayer">
        <div className="ConfigurePlayer-inner">
          {mainView}
        </div>
      </ModalDialog>
    );
  }
}

ConfigurePlayer.propTypes = {};

export default withTranslation()(ConfigurePlayer);
