import axios from 'axios';

export const urls = {
    tagThemes: 'tags/themes',
};

export const getTags = (page, filters = [], signal = null) => {
    const hasSort = filters.some(filter => !!filter.match(/sort_by/g));
    if (!hasSort) {
        filters.push(`sort_by=elastic_score`);
    }
    const query = [`page=${page}`, ...filters];
    const data = signal ? { signal } : {};
    return axios.get(`${urls.tagThemes}?${query.join('&')}`, data);
};

export const getTagsCancelable = (page, filters = []) => {
    const controller = new AbortController();
    return {
        result: getTags(page, filters, controller.signal),
        controller
    };
};

export const setThemeCustomNameByOrientation = (tagId, orientations_id, custom_name = null) => {
    if (!custom_name) {
        custom_name = '';
    }
    return axios.post(`${urls.tagThemes}/${tagId}/custom_name`, null, {
        params: {
            orientations_id,
            custom_name
        }
    });
};

export const setThemeEnabledStatus = (tagId, enabled = false) => {
    return axios.patch(`${urls.tagThemes}/${tagId}/enable`, null, {
        params: {
            enabled: enabled ? 1 : 0
        }
    });
};

export const getTagsExport = (filters = []) => {
    return axios.get(`${urls.tagThemes}/export?${filters.join('&')}`);
};
