import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../../components/BaseFooter/BaseFooter";

import './StudiosViewFooter.scss';

class StudiosViewFooter extends Component {

  getBaseFooterProps = () => {
    const {currentPage, onChange, pageCount, pageSize, total} = this.props;
    return {
      currentPage,
      onChange,
      pageCount,
      pageSize,
      recordCount: total
    };
  };

  render() {
    const baseFooterProps = this.getBaseFooterProps();
    return (
      <BaseFooter className="StudiosViewFooter"
                  {...baseFooterProps}>
      </BaseFooter>
    );
  }
}

StudiosViewFooter.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default withTranslation()(StudiosViewFooter);
