import React, { Component } from 'react';
import { Dropdown, Menu } from "antd";
import PropTypes from 'prop-types';

class BaseDropdown extends Component {

  /**
   * Returns Dropdown menu element
   *
   * @returns {JSX.Element}
   */
  getDropdownMenu = () => {
    const { menuClassName } = this.props;
    return (
      <Menu className={menuClassName}>
        {this.renderMenuItems()}
      </Menu>
    );
  };

  /**
   * Maps items to Menu items for dropdown menu
   *
   * @param {JSX.Element} item
   * @param {number} index
   *
   * @returns {JSX.Element}
   */
  menuItemMap = (item, index) => {
    return (
      <Menu.Item key={index}>{item}</Menu.Item>
    );
  };

  /**
   * Returns an array of Menu items for dropdown menu
   *
   * @returns {JSX.Element}
   */
  renderMenuItems = () => {
    const { items } = this.props;
    return items.map(this.menuItemMap);
  };

  scrollStyles = () => {
    return {
      maxHeight: '95vh',
      overflow: 'auto'
    }
  }

  render() {
    const { className, children, addScrollBar } = this.props;
    return (
      <Dropdown className={`BaseDropdown ${className}`}
        overlay={this.getDropdownMenu()}
        overlayStyle={addScrollBar ? this.scrollStyles() : {}}>
        {children}
      </Dropdown>
    );
  }
}

BaseDropdown.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  menuClassName: PropTypes.string,
  addScrollBar: PropTypes.bool
};

export default BaseDropdown;
