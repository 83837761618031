import React from 'react';
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../../components/BaseFooter/BaseFooter";

const PlaylistFooter = props => {
  return (
    <BaseFooter className='PlaylistFooter' {...props}> </BaseFooter>
  );
};

PlaylistFooter.propTypes = {
  currentPage: PropTypes.number,
  onChange: PropTypes.func,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  recordCount: PropTypes.number
};

export default PlaylistFooter;

