import React from 'react';
import {Layout} from 'antd';
import {Router} from '@reach/router';
import {MAIN_DASHBOARD_VIEW_ROUTES} from '../../main-dashboard-view.routes';

import BannersViewSection from './components/BannersView/BannersViewSection';
import CategoriesView from './components/CategoriesView/CategoriesView';
import LicensorsView from './components/LicensorsView/LicensorsView';
import MailView from './components/MailView/MailView';
import MFIDsView from './components/MFIDsView/MFIDsView';
import MoviesView from './components/MoviesView/MoviesView';
import PayoutsView from './components/PayoutsView/PayoutsView';
import PropertiesView from './components/PropertiesView/PropertiesView';
import SexActMovies from './components/MoviesView/SexActMovies/SexActMovies';
import StarsView from './components/StarsView/StarsView';
import StudiosView from './components/StudiosView/StudiosView';
import UsersView from './components/UsersView/UsersView';
import StreamingUsers from './components/StreamingUsers/StreamingUsers';

import NotFound from '../../../../../NotFound/NotFound';

import './MainContent.scss';
import {MainDashboardComController} from '../../../../main-dashboard-comm.controller';

export const hideSideMenu = () => {
  MainDashboardComController.hideSideMenuIfVisible();
};

const MainContent = props => {
  return (
    <Layout>
      <Layout.Content className='MainContent' onClick={hideSideMenu}>
        <Router>
          <PropertiesView
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.PROPERTIES}/*`}
            contentHeight={props.contentHeight}
          />
          <StudiosView
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.STUDIOS}/*`}
            contentHeight={props.contentHeight}
          />
          <MoviesView
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.MOVIES}/*`}
            contentHeight={props.contentHeight}
          />
          <SexActMovies
            path={`sexAct/:sexActId/${MAIN_DASHBOARD_VIEW_ROUTES.MOVIES}/*`}
          />
          <StarsView
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.STARS}/*`}
            contentHeight={props.contentHeight}
          />
          <CategoriesView
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.THEMES}/*`}
            contentHeight={props.contentHeight}
          />
          <BannersViewSection
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.BANNERS}/*`}
            contentHeight={props.contentHeight}
          />
          <UsersView
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.USERS}/*`}
            contentHeight={props.contentHeight}
          />
          <LicensorsView
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.LICENSORS}/*`}
            contentHeight={props.contentHeight}
          />
          <MFIDsView path={`${MAIN_DASHBOARD_VIEW_ROUTES.MFIDS}/*`}
                     contentHeight={props.contentHeight}/>
          <PayoutsView
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.PAYOUTS}/*`}
            contentHeight={props.contentHeight}
          />
          <StreamingUsers
            path={`${MAIN_DASHBOARD_VIEW_ROUTES.UNASSIGNED_VIEW_TIME}/*`}
            contentHeight={props.contentHeight}
          />
          <MailView path={MAIN_DASHBOARD_VIEW_ROUTES.MAIL}
                    contentHeight={props.contentHeight}/>
          <NotFound default/>
        </Router>
      </Layout.Content>
    </Layout>
  );
};

export default MainContent;
