import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Icon, Table, Tag} from "antd";
import PropTypes from 'prop-types';

import {renderMomentWithHours} from "../../../../../../../../../../services/date-service/date.service";

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import BaseDropdown from "../../../../../../../../../../components/BaseDropdown/BaseDropdown";

import './UserInfo.scss';

class UserInfo extends Component {

  generalColumns = [{
    dataIndex: 'name',
    width: 150
  }, {
    dataIndex: 'value'
  }];

  propertiesColumns = [{
    dataIndex: 'id',
    title: this.props.t('UserInfo.id')
  }, {
    dataIndex: 'name',
    title: this.props.t('UserInfo.property')
  }, {
    dataIndex: 'registeredOn',
    title: this.props.t('UserInfo.registeredOn')
  }];

  /**
   * Returns account info table data
   *
   * @returns {Object[]}
   */
  getAccountInfoTableData = () => {
    const {t} = this.props;
    const {id, name, email, createdAt, updatedAt, primaryRole, enable} = this.props.data;
    return [{
      name: t('UserInfo.userID'),
      value: id,
      key: 1
    }, {
      name: t('UserInfo.login'),
      value: name,
      key: 2
    }, {
      name: t('UserInfo.email'),
      value: email,
      key: 3
    }, {
      name: t('UserInfo.userType'),
      value: primaryRole ? this.renderPrimaryRole(primaryRole.name) : '',
      key: 4
    }, {
      name: t('UserInfo.status'),
      value: this.renderEnableDisableDropdown(enable),
      key: 5
    }, {
      name: t('UserInfo.createdDate'),
      value: renderMomentWithHours(createdAt),
      key: 6
    }, {
      name: t('UserInfo.updatedDate'),
      value: renderMomentWithHours(updatedAt),
      key: 7
    }];
  };

  /**
   * Returns enable/disable dropdown items
   *
   * @returns {JSX.Element[]}
   */
  getEnableDisabledDropdownItems = () => {
    const {t, data} = this.props;
    const {id} = data;
    return [
      <div onClick={this.props.setUserEnabled.bind(this, 1, id)}
           className="UserInfo-enabled">
        {t('UserInfo.enabled')}
      </div>,
      <div onClick={this.props.setUserEnabled.bind(this, 0, id)}
           className="UserInfo-disabled">
        {t('UserInfo.disabled')}
      </div>
    ];
  };

  /**
   * Returns properties table data
   *
   * @returns {Object[]}
   */
  getPropertiesTableData = () => {
    const {id, name, registeredOn} = this.props.data;
    return [{
      id: id,
      name: name,
      registeredOn: renderMomentWithHours(registeredOn),
      key: 1
    }];
  };

  /**
   * Returns properties table data for small screen render
   *
   * @returns {Object[]}
   */
  getPropertiesMinViewTableData = () => {
    const {t} = this.props;
    const {id, name, registeredOn} = this.props.data;
    return [{
      name: t('UserInfo.id'),
      value: id,
      key: 1
    }, {
      name: t('UserInfo.property'),
      value: name,
      key: 2
    }, {
      name: t('UserInfo.registeredOn'),
      value: renderMomentWithHours(registeredOn),
      key: 3
    }];
  };

  /**
   * Returns status display text
   *
   * @param {number} status
   *
   * @returns {String}
   */
  getStatusText = status => {
    const {t} = this.props;
    return t(status ? 'UserInfo.enabled' : 'UserInfo.disabled');
  };

  /**
   * Returns account info table element for render
   *
   * @returns {JSX.Element}
   */
  renderAccountUserInfoTable = () => {
    const data = this.getAccountInfoTableData();
    return this.renderTable(data, this.generalColumns, false);
  };

  /**
   * Returns enable/disable dropdown element for render
   *
   * @param {number} enable
   *
   * @returns {JSX.Element}
   */
  renderEnableDisableDropdown = (enable) => {
    return (
      <div className="StatusItem">
        <BaseDropdown items={this.getEnableDisabledDropdownItems()}
                      menuClassName="UserInfo-enabledDisabled">
          <Button className={enable ? 'UserEnabled' : 'UserDisabled'}>
            {this.getStatusText(enable)}
            <Icon type="down"/>
          </Button>
        </BaseDropdown>
      </div>
    );
  };

  /**
   * Returns primary role tag element for render
   *
   * @param {String} primaryRole Primary role name for localization
   *
   * @returns {JSX.Element}
   */
  renderPrimaryRole = (primaryRole) => {
    const {t} = this.props;
    return (
      <Tag>{t(`UserInfo.${primaryRole}`)}</Tag>
    );
  };

  /**
   * Returns properties table element for large screen render
   *
   * @returns {JSX.Element}
   */
  renderPropertiesMaxTable = () => {
    const data = this.getPropertiesTableData();
    return this.renderTable(data, this.propertiesColumns, true);
  };

  /**
   * Returns properties table element for small screen render
   *
   * @returns {JSX.Element}
   */
  renderPropertiesMinTable = () => {
    const data = this.getPropertiesMinViewTableData();
    return this.renderTable(data, this.generalColumns, false);
  };

  /**
   * Returns table element for render
   *
   * @param {Object[]} data
   * @param {Object[]} columns
   * @param {boolean} showHeader
   *
   * @returns {JSX.Element}
   */
  renderTable = (data, columns, showHeader) => {
    return (
      <Table dataSource={data}
             bordered
             columns={columns}
             pagination={false}
             showHeader={showHeader}/>
    );
  };

  render() {
    const {t} = this.props;
    return (
      <div className="UserInfo">
        <BaseCard title={t('UserInfo.accountInfo')}>
          {this.renderAccountUserInfoTable()}
        </BaseCard>
        <BaseCard title={t('UserInfo.propertiesInfo')}>
          {this.renderPropertiesMaxTable()}
          {this.renderPropertiesMinTable()}
        </BaseCard>
      </div>
    );
  }
}

UserInfo.propTypes = {
  data: PropTypes.object.isRequired
};

export default withTranslation()(UserInfo);
