import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Collapse} from "antd";

import './CollapsiblePanel.scss';

/* istanbul ignore file */
class CollapsiblePanel extends Component {
  render() {
    const {activeKeys, children, className, defaultActiveKeys, onChange} = this.props;
    const classes = ['CollapsiblePanel'];
    if (className) {
      classes.push(className);
    }
    return (
      <Collapse className={classes.join(' ')}
                defaultActiveKey={defaultActiveKeys}
                activeKey={activeKeys}
                onChange={onChange}>
        {children}
      </Collapse>
    );
  }
}

CollapsiblePanel.propTypes = {
  activeKeys: PropTypes.array,
  className: PropTypes.string,
  defaultActiveKeys: PropTypes.array,
  onChange: PropTypes.func
};

export default CollapsiblePanel;
