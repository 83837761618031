import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "@reach/router";
import { Button, Dropdown, Menu, Icon } from "antd";
import PropTypes from "prop-types";

import BaseCard from "../../../../../../../../../../../components/BaseCard/BaseCard";
import ImageComponent from "../../../../../../../../../../../components/ImageComponent/ImageComponent";
import LinkButton from "../../../../../../../../../../../components/LinkButton/LinkButton";

import { renderMoment } from "../../../../../../../../../../../services/date-service/date.service";

import CardSection from "./CardSection/CardSection";

import "./MovieCard.scss";

class MovieCard extends Component {
	editMovie = () => {
		const { movieId, onMovieEdit, title } = this.props;
		onMovieEdit(movieId, title);
	};

	editSpecialCategories = () => {
		const { movieId, title, onSpecialCategoriesEdit } = this.props;
		onSpecialCategoriesEdit(movieId, title);
	};

	editMovie2257 = () => {
		const { movieId, onMovieEdit2257, title } = this.props;
		onMovieEdit2257(movieId, title);
	};

	getEnableDisableDropdownMenu = () => {
		const { t } = this.props;
		return (
			<Menu className="MovieCard-enabledDisabled">
				<Menu.Item>
					<div
						onClick={this.setMovieEnabled.bind(this, 1)}
						className="MovieCard-enabled"
					>
						{t("MovieCard.enabled")}
					</div>
				</Menu.Item>
				<Menu.Item>
					<div
						onClick={this.setMovieEnabled.bind(this, 0)}
						className="MovieCard-disabled"
					>
						{t("MovieCard.disabled")}
					</div>
				</Menu.Item>
			</Menu>
		);
	};

	getOnlineStatusText = (online) => {
		const { t } = this.props;
		return t(online ? "MovieCard.online" : "MovieCard.offline");
	};

	getStatusText = () => {
		const { enable, t } = this.props;
		return t(enable ? "MovieCard.enabled" : "MovieCard.disabled");
	};

	getTitle = () => {
		const { t } = this.props;
		return (
			<div className="CardHeader">
				<div className="CardHeader-title">
					<h2>{this.props.title}</h2>
					<div className="StatusDropdownWrapper">
						{this.renderOnlineOfflineStatus()}
						{this.renderEnableDisableDropdown()}
					</div>
				</div>
				<div className="UserActions">
					<LinkButton
						className="ViewMovieDetails"
						href={this.props.movieDetailsRoute}
					>
						{t("MovieCard.viewDetails")}
					</LinkButton>
					<Button onClick={this.editMovie}>
						{t("MovieCard.edit")}
					</Button>
					<Button onClick={this.editSpecialCategories}>
						{t("MovieCard.editSpecialCategories")}
					</Button>
					<Button onClick={this.editMovie2257}>
						{t("MovieCard.edit2257")}
					</Button>
				</div>
			</div>
		);
	};

	isOnlineStatus = (publishStatus) => {
		return publishStatus === "Online";
	};

	renderDate = (date, format) => {
		let render = "";
		if (date) {
			if (date.indexOf("/") !== -1) {
				const split = date.split("/");
				date = `${split[2]}-${split[0]}-${split[1]}`;
			}
			render = renderMoment(date, format);
		}

		return render;
	};

	renderEnableDisableDropdown = () => {
		const { enable } = this.props;
		return (
			<Dropdown overlay={this.getEnableDisableDropdownMenu()}>
				<Button className={enable ? "MovieEnabled" : "MovieDisabled"}>
					{this.getStatusText()}
					<Icon type="down" />
				</Button>
			</Dropdown>
		);
	};

	renderLicensorData = () => {
		const { manufacturer, importedOn } = this.props;
		const { licensor } = manufacturer;
		let view = null;
		if (licensor) {
			const licensorData = [
				{
					name: "licensor",
					value: licensor,
				},
				{
					name: "importedOn",
					value: renderMoment(new Date(importedOn)),
				},
			];
			view = <CardSection data={licensorData} />;
		}
		return view;
	};

	renderMovieData = () => {
		const { manufacturer, releaseDate, runTime, movieId, studios, series } =
			this.props;
		const licensor = manufacturer.licensor || {};
		const movieData = [
			{
				name: "movieId",
				value: movieId,
			},
			{
				name: "manufacturer",
				value: manufacturer,
			},
			{
				name: "licensor",
				value: licensor,
			},
			{
				name: "studios",
				value: studios,
			},
			{
				name: "releaseDate",
				value: this.renderDate(releaseDate),
			},
			{
				name: "runTime",
				value: runTime,
			},
		];
		if (!!series?.id) {
			movieData.push({
				name: "series",
				value: `${series?.name} (${series?.id})`,
			});
		}
		return <CardSection data={movieData} />;
	};

	renderOnlineOfflineStatus = () => {
		const { publishStatus } = this.props;

		const online = this.isOnlineStatus(publishStatus);
		return (
			<Button className={online ? "MovieOnline" : "MovieOffline"}>
				{this.getOnlineStatusText(online)}
			</Button>
		);
	};

	renderPublishData = () => {
		const { publishStart } = this.props;
		let view = null;
		if (publishStart) {
			const publishData = [
				{
					name: "publishStart",
					value: this.renderDate(publishStart, "MMMM Do YYYY HH:mm"),
				},
			];
			view = <CardSection data={publishData} />;
		}
		return view;
	};

	setMovieEnabled = (enabled) => {
		const { movieId } = this.props;
		this.props.setMovieEnabled(movieId, enabled);
	};

	render() {
		const { image, movieDetailsRoute, showDetails, t } = this.props;
		return (
			<BaseCard className="MovieCard" title={this.getTitle()}>
				<div className="CardBody">
					<Link to={movieDetailsRoute}>
						<ImageComponent className="CardImage" url={image} />
					</Link>
					<div className="CardContent">
						<div className="MovieInfoWrapper">
							{this.renderMovieData()}
							{this.renderLicensorData()}
							{this.renderPublishData()}
							<Button onClick={showDetails}>
								{t("MovieCard.viewDetails")}
							</Button>
						</div>
					</div>
				</div>
			</BaseCard>
		);
	}
}

MovieCard.propTypes = {
	enable: PropTypes.number.isRequired,
	image: PropTypes.string,
	movieDetailsRoute: PropTypes.string.isRequired,
	movieId: PropTypes.number.isRequired,
	publishStatus: PropTypes.string.isRequired,
	publishData: PropTypes.object,
	onMovieEdit: PropTypes.func.isRequired,
	onMovieEdit2257: PropTypes.func.isRequired,
	setMovieEnabled: PropTypes.func.isRequired,
	showDetails: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};

export default withTranslation()(MovieCard);
