import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { withTranslation } from "react-i18next";
import { Button } from "antd";

import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";
import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import InfoTooltip from '../../../../../../../../../components/InfoTooltip/InfoTooltip';

import {
  getWhitelabelConfig,
  editWhitelabelConfig
} from "../../../../../../../../../services/properties-service/properties.service";
import BaseDragDropList from '../../../../../../../../../components/BaseDragDropList/BaseDragDropList';

import AddCategoriesModal from "./AddCategoriesModal/AddCategoriesModal";

import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../services/notification-service/notification.service';

import './ConfigureCategoriesModal.scss';

class ConfigureCategoriesModal extends Component {

  modalRef = React.createRef();

  state = {
    categories: [],
    whiteLabel: null
  }

  componentDidMount() {
    this.fetchData();
  }

  addElements = () => {
    const { categories } = this.state;
    const modal = (
      <AddCategoriesModal
        propertyId={this.props.propertyId}
        updateCategoriesList={this.updateCategoriesList}
        currentOrder={categories.map(this.getItemId)}
      />
    );
    ModalController.showModal(modal);
  };

  fetchData = () => {
    const message = this.props.t('ConfigureCategoriesModal.loadingMsg');
    this.getModal().setLoading(message);

    const { propertyId } = this.props;

    getWhitelabelConfig(propertyId)
      .then(this.loadData)
      .catch(this.onLoadDataFailure);
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="ConfigureCategoriesModal-actions">
        <Button onClick={this.addElements.bind(this)}
          key="addCategoriesBtn">
          {t('ConfigureCategoriesModal.addCategories')}
        </Button>
        <div>
          <Button onClick={this.onCancel.bind(this)}
            key="cancelBtn">
            {t('ConfigureCategoriesModal.cancel')}
          </Button>
          <Button key="saveChangesBtn"
            onClick={this.saveChanges.bind(this)}>
            {t('ConfigureCategoriesModal.saveChanges')}
          </Button>
        </div>
      </div>
    );
  };

  getItemId = (item) => {
    return item.id;
  };

  getItems = () => {
    return this.state.categories;
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    const { t, propertyName } = this.props;
    const name = propertyName ? ` - ${propertyName}` : '';
    return (
      <div>
        <InfoTooltip text='Homepage theme links below slider, for registered users only.' />
        {t('ConfigureCategoriesModal.title')} {name}
      </div>
    )
  };

  loadData = response => {
    const currentConfig = response.data.data;
    const categories = response.data.data.categories || [];
    this.setState({
      categories: categories || [],
      whiteLabel: currentConfig
    });

    this.startingCategories = categories;
    this.getModal().clearLoading();
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onLoadDataFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('ConfigureCategoriesModal.loadDataFailed')
    });
  };

  onOrderChange = (items) => {
    this.setState({
      categories: items
    });
  };

  onSetDataFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('ConfigureCategoriesModal.setDataFailed')
    });
  };

  onSetDataSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('ConfigureCategoriesModal.setDataSuccess')
    });
  };

  removeCategory = (id, index) => {
    this.setState(prevState => {
      let categories = cloneDeep(prevState.categories);
      categories.splice(index, 1);
      return { categories };
    });
  };

  renderBaseDragDropList = () => {
    const { categories } = this.state;
    let view = null;
    if (categories.length) {
      view = (
        <BaseDragDropList onOrderChange={this.onOrderChange}
          renderItem={this.renderItem}
          onItemRemove={this.removeCategory}
          items={this.getItems() || []} />
      );
    }

    return view;
  };

  renderItem = (item, index) => {
    return (
      <div className="ItemContainer">
        <div className="IndexContainer">
          <h2>{index + 1}</h2>
        </div>
        <div className="ItemDetails">
          <div className="Item-Title">{item.name}</div>
        </div>
      </div>
    );
  };

  saveChanges = () => {
    const message = this.props.t('ConfigureCategoriesModal.updatingMsg');
    this.getModal().setLoading(message);
    const { categories } = this.state;
    const { propertyId, applyToAll } = this.props;
    const config = { categories };
    const whiteLabelId = applyToAll || propertyId;

    editWhitelabelConfig(whiteLabelId, config)
      .then(this.onSetDataSuccess)
      .catch(this.onSetDataFailure);
  };

  updateCategoriesList = (data) => {
    this.setState(prevState => {
      return {
        categories: data
      }
    });
  };

  render() {
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className="ConfigureCategoriesModal">
          {this.renderBaseDragDropList()}
        </div>
      </ModalDialog>
    );
  }
}

export default withTranslation()(ConfigureCategoriesModal);
