import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Empty } from 'antd';

import { ModalController } from "../../../../../../../../../../controllers/modal-controller/modal.controller";
import ConfirmationModal from "../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";

import { deleteMovieImage } from "../../../../../../../../../../services/images-service/images.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../../services/notification-service/notification.service";

import BaseImagesDisplay from "../../../../../../../../../../components/BaseImagesDisplay/BaseImagesDisplay";

import './MovieDetailsImagesTab.scss';

class MovieDetailsImagesTab extends Component {

  imagesDisplayRef;

  closeModal = () => {
    ModalController.removeModal();
  };

  deleteImage = image => {
    const { movieId } = this.props;
    deleteMovieImage(movieId, image.id)
      .then(this.deleteImageSuccess.bind(this, image.id))
      .catch(this.deleteImageFailure);
  };

  deleteImageFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('MovieDetailsImagesTab.deleteImageFailure')
    });
  };

  deleteImageSuccess = imageId => {
    this.props.onDeleteImage(imageId);
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('MovieDetailsImagesTab.deleteImageSuccess')
    });
  };

  onDeleteImage = image => {
    const { t } = this.props;
    const modal = (
      <ConfirmationModal
        title={t('MovieDetailsImagesTab.confirmDelete')}
        message={t('MovieDetailsImagesTab.deleteQuestion')}
        yesBtnText={t('MovieDetailsImagesTab.deleteImage')}
        noBtnText={t('MovieDetailsImagesTab.cancel')}
        decline={this.closeModal}
        confirm={this.deleteImage.bind(this, image)}
      />
    );
    ModalController.showModal(modal);
  };

  setFullScreen = index => {
    this.imagesDisplayRef.toggleFullScreen(index);
  };

  onEditImage = (imageId) => {
    this.props.onImageEdit(imageId);
  };

  setImagesDisplayRef = (imagesDisplay) => {
    this.imagesDisplayRef = imagesDisplay;
  };

  render() {
    let view = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

    const { images, movieId } = this.props;
    if (images.length) {
      view = (
        <div className="MovieDetailsImagesTab-inner">
          <BaseImagesDisplay images={images}
            onFullScreen={this.setFullScreen}
            onDeleteImage={this.onDeleteImage}
            onEditImage={this.onEditImage}
            onInitialize={this.setImagesDisplayRef}
            movieId={movieId}
          />
        </div>
      );
    }
    return (
      <div className="MovieDetailsImagesTab">
        {view}
      </div>
    );
  }
}

MovieDetailsImagesTab.propTypes = {
  images: PropTypes.array,
  onImageEdit: PropTypes.func.isRequired
};

export default withTranslation()(MovieDetailsImagesTab);
