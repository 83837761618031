import React, {Component} from 'react';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {Button, Input, List, Table} from 'antd';
import {withDocumentTitleUpdate} from "../../../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import ConfirmationModal from '../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal';
import {ModalController} from '../../../../../../../../../../controllers/modal-controller/modal.controller';
import BaseCard from '../../../../../../../../../../components/BaseCard/BaseCard';

import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../services/notification-service/notification.service';
import {editSubCategory} from '../../../../../../../../../../services/categories-service/subcategories-service/subcategories.service';

import './SubCategoriesView.scss';

class SubCategoriesView extends Component {

  filterDelay = 300;

  state = {
    editMode: {}
  };

  confirmDeleteSubCategory = (data) => {
    const {t} = this.props;
    const modal = (
      <ConfirmationModal 
        confirm={this.deleteSubCategory.bind(this, data)}
        title={t('SubCategoriesView.confirmDelete')}
        message={t('SubCategoriesView.deleteQuestion')}
        yesBtnText={t('SubCategoriesView.deleteBtn')}
        noBtnText={t('SubCategoriesView.cancel')}
      />
    );
    ModalController.showModal(modal);
  };

  deleteSubCategory = (data) => {
    this.toggleEditMode(data.id);
    this.props.deleteSubCategory(data);
  };

  hasDataChanged = (id) => {
    const {editMode} = this.state;
    return editMode[id].subcategoryName !== editMode[id].initialName;
  };

  renderActions = (item, data) => {
    const {t} = this.props;
    const {editMode} = this.state;
    const {id, name, category_id} = data;
    const key = editMode[id] ? 'cancel' : 'editSubCategory';
    return (
      <div className="SubCategoriesViewActions">
        {editMode[id] ?
          (<Button onClick={this.onSaveChanges.bind(this, id, category_id)}
                   disabled={!editMode[id].subcategoryName || !this.hasDataChanged(id)}>
            {t('SubCategoriesView.saveChanges')}
          </Button>) : null}
        <Button onClick={this.toggleEditMode.bind(this, id, name)}>
          {t(`SubCategoriesView.${key}`)}
        </Button>
        <Button onClick={this.confirmDeleteSubCategory.bind(this, data)}>
          {t('SubCategoriesView.deleteSubCategory')}
        </Button>
      </div>
    );
  };

  renderNameColumnValue = (name, {id}) => {
    const {editMode} = this.state;
    let value = name;
    
    if(editMode[id]) {
      value = (
        <Input.TextArea
          autosize
          autoFocus={true}
          onChange={this.onChange.bind(this, id)}
          value={editMode[id].subcategoryName}
        />
      );
    }

    return value;
  };

  columns = [
    {
      dataIndex: 'id',
      title: this.props.t('SubCategoriesView.id'),
      width: 100
    },
    {
      dataIndex: 'name',
      title: this.props.t('SubCategoriesView.name'),
      render: this.renderNameColumnValue
    },
    {
      align: 'center',
      dataIndex: 'actions',
      title: this.props.t('SubCategoriesView.actions'),
      render: this.renderActions,
      width: 400
    }
  ];

  onChange = (id, event) => {
    let value = event.target ? event.target.value : event;
    const {editMode} = this.state;

    this.setState({
      editMode: {
        ...editMode,
        [id]: {
          initialName: editMode[id].initialName,
          subcategoryName: value
        }
      }
    });
  };

  onEditSubCategoryFailure = () => {
    this.props.setLoading('');
    displayErrorNotification({
      duration: 3,
      message: this.props.t('SubCategoriesView.editSubCategoryFailure')
    });
  };

  onEditSubCategorySuccess = (subcategoryId) => {
    this.props.setLoading('');
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('SubCategoriesView.editSubCategorySuccess')
    });
    
    this.props.fetchData(this.toggleEditMode.bind(this, subcategoryId));
  };

  onNameFilterChange = (event) => {
    const name = event.target.value;
    this.setState({
      nameFilter: name
    });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.props.onNameFilterChange.bind(this, name), this.filterDelay);
  };

  onSaveChanges = (subcategoryId, categoryId) => {
    const {editMode} = this.state;

    const message = this.props.t('SubCategoriesView.editingSubCategory');
    this.props.setLoading(message);

    const data = {
      name: editMode[subcategoryId].subcategoryName,
      categories_id: categoryId
    };

    editSubCategory(subcategoryId, data)
      .then(this.onEditSubCategorySuccess.bind(this, subcategoryId))
      .catch(this.onEditSubCategoryFailure);
  };

  renderSubCategoriesList = () => {
    return (
      <List
        className="ListWrapper"
        dataSource={this.props.listInfo.data.subcategory}
        renderItem={this.renderSubCategoryListItem}
        split={false}
        itemLayout='vertical'
      />
    );
  };

  renderSubCategoryListItem = item => {
    const {id, name} = item;
    const {t} = this.props;
    return (
      <List.Item key={id}>
        <BaseCard 
          title={`${t('SubCategoriesView.title', {name})}`}
          footer={this.renderActions(item, item)}
        >
          <div className='ListCardWrapper'>
            <p>
              <span className='ListItemLabel'>
                {t('SubCategoriesView.id')}:
              </span>
              {id}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('SubCategoriesView.name')}:
              </span>
              {this.renderNameColumnValue(name, item)}
            </p>
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  renderSubCategoriesTable = () => {
    return (
      <Table
        className="TableWrapper"
        dataSource={this.props.listInfo.data.subcategory}
        rowKey='id'
        bordered
        columns={this.columns}
        pagination={false}
      />
    );
  };

  toggleEditMode = (id, name) => {
    let editMode = this.state.editMode;

    if(editMode[id]) {
      delete editMode[id];
    } else {
      editMode = {
        ...this.state.editMode,
        [id]: {
          initialName: name,
          subcategoryName: name,
        }
      };
    }

    this.setState({
      editMode: editMode
    });
  };

  render() {
    const {t} = this.props;
    return (
      <div className="SubCategoriesView">
        <div className="SubCategoriesViewFilterWrapper">
          <Input 
            placeholder={t('SubCategoriesView.searchBySubCategoryName')}
            onChange={this.onNameFilterChange}
            value={this.state.nameFilter}
          />
        </div>
        <div className="SubCategoriesViewBody">
          {this.renderSubCategoriesTable()}
          {this.renderSubCategoriesList()}
        </div>
      </div>
    );
  }
}

export default compose(withTranslation(), withDocumentTitleUpdate)(SubCategoriesView);
