import {Subject} from "rxjs";

/* istanbul ignore file */
export class ConfigureMoviesAdsCtrl {
  static instance;
  expandEvent = new Subject();
  triggerValidationEvent = new Subject();

  static getInstance() {
    if (ConfigureMoviesAdsCtrl.instance == null) {
      ConfigureMoviesAdsCtrl.instance = new ConfigureMoviesAdsCtrl();
    }

    return ConfigureMoviesAdsCtrl.instance;
  }

  expandAll = (expand) => {
    const instance = ConfigureMoviesAdsCtrl.getInstance();
    instance.expandEvent.next(expand);
  };

  triggerValidation = () => {
    this.triggerValidationEvent.next();
  };
}

export const ConfigureMoviesAdsController = ConfigureMoviesAdsCtrl.getInstance();
