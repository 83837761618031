import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Button, Input, Select, Icon } from "antd";

import {
  getUsersRoute,
  getNatsUsersRoute,
  getRoyaltiesUsersRoute
} from "../../../../../../../../../../services/navigation/users-navigation/users-navigation-routes.service";
import {
  showUsers,
  showNatsUsers,
  showRoyaltiesUsers
} from "../../../../../../../../../../services/navigation/users-navigation/users-navigation.service";

import BaseHorizontalNavigation
  from "../../../../../../../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";

import './UsersViewHeader.scss';

export const DisplayFilters = Object.freeze({
  admins: 1,
  past7Days: 7,
  past14Days: 14,
  past30Days: 30,
  today: 5
});

class UsersViewHeader extends Component {

  displayFilters = [{
    name: this.props.t('UsersViewHeader.today'),
    value: DisplayFilters.today
  }, {
    name: this.props.t('UsersViewHeader.past7Days'),
    value: DisplayFilters.past7Days
  }, {
    name: this.props.t('UsersViewHeader.past14Days'),
    value: DisplayFilters.past14Days
  }, {
    name: this.props.t('UsersViewHeader.past30Days'),
    value: DisplayFilters.past30Days
  }, {
    name: this.props.t('UsersViewHeader.admins'),
    value: DisplayFilters.admins
  }];

  emailFilterDebounce = 500;

  state = {
    display: this.props.defaultFilters.display,
    email: this.props.defaultFilters.email,
    filterToggled: false
  };

  timeoutId;

  callPropsFilterChange = () => {
    const filters = this.getFilters();
    this.props.onChange(filters, this.state.email, this.state.display);
  };

  clearFilter = () => {
    this.setState({
      display: '',
      email: ''
    }, this.callPropsFilterChange);
  };

  getDisplayFilter = () => {
    let displayFilter;
    const { display } = this.state;
    switch (display) {
      case DisplayFilters.past7Days:
      case DisplayFilters.past14Days:
      case DisplayFilters.past30Days:
        displayFilter = `imported_on=${display}`;
        break;
      case DisplayFilters.today:
        displayFilter = 'imported_today=true';
        break;
      case DisplayFilters.admins:
        displayFilter = 'admins=true';
        break;
      default:
        break;
    }

    return displayFilter;
  };

  getFilters = () => {
    const filters = [];
    const { email, display } = this.state;
    if (email) {
      filters.push(`search=${email}`);
    }
    if (display) {
      filters.push(this.getDisplayFilter());
    }

    return filters;
  };

  getNavigationItems = () => {
    const { t } = this.props;
    return [{
      link: getUsersRoute(),
      name: t('UsersViewHeader.usersTab'),
      navigate: showUsers
    }, {
      link: getNatsUsersRoute(),
      name: t('UsersViewHeader.natsUsersTab'),
      navigate: showNatsUsers
    }, {
      link: getRoyaltiesUsersRoute(),
      name: t('UsersViewHeader.royalityUsersTab'),
      navigate: showRoyaltiesUsers
    }];
  };

  hasFilterChanged = () => {
    const { display, email } = this.state;
    return !!(display || email);
  };

  onChange = (event) => {
    const email = event.target.value;
    this.setState({
      email: email
    });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(this.callPropsFilterChange, this.emailFilterDebounce);
  };

  onDisplayChange = (display) => {
    this.setState({
      display: display
    }, this.callPropsFilterChange);
  };

  renderClearFilterBtn = () => {
    const { t } = this.props;
    return (
      <Button onClick={this.clearFilter}
        disabled={!this.hasFilterChanged()}>
        {t('UsersViewHeader.clearFilter')}
      </Button>
    );
  };

  renderDisplayFilter = () => {
    const { t } = this.props;
    return (
      <div className="UsersViewHeader-filterWrapper">
        <div className="UsersViewHeader-filterLabel">
          {t('UsersViewHeader.display')}:
        </div>
        <Select showArrow
          className="UsersViewHeader-select"
          onChange={this.onDisplayChange}
          value={this.state.display}>
          {this.displayFilters.map(this.renderFilterItem)}
        </Select>
      </div>
    );
  };

  renderFilterItem = (item) => {
    return (
      <Select.Option key={item.name} value={item.value}>
        <div className="UsersViewHeaderFilterItemWrapper">
          {item.name}
        </div>
      </Select.Option>
    );
  };

  renderSearchToggle = () => {
    const { t } = this.props;
    return (
      <div className="UsersViewHeader-inner">
        {t('UsersViewHeader.filters')}:
        <Icon type={this.state.filterToggled ? 'up' : 'down'}
          onClick={this.toggleFilters} />
      </div>
    );
  };

  renderEmailFilter = () => {
    const { t } = this.props;
    return (
      <div className="UsersViewHeader-filterWrapper">
        <div className="UsersViewHeader-filterLabel">
          {t('UsersViewHeader.searchByUserEmail')}:
        </div>
        <Input value={this.state.email}
          onChange={this.onChange} />
      </div>
    );
  };

  toggleFilters = () => {
    this.setState(prevState => {
      return {
        filterToggled: !prevState.filterToggled
      };
    });
  };

  render() {
    const classes = ['UsersViewHeader-mainWrapper'];
    if (this.state.filterToggled) {
      classes.push('FiltersToggled');
    }
    return (
      <div>
        <div className="UsersViewHeader">
          {this.renderSearchToggle()}
          <div className={classes.join(' ')}>
            {this.renderEmailFilter()}
            {this.renderDisplayFilter()}
            {this.renderClearFilterBtn()}
          </div>
        </div>
        <BaseHorizontalNavigation items={this.getNavigationItems()} />
      </div>
    );
  }
}

UsersViewHeader.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default withTranslation()(UsersViewHeader);
