import { navigate } from "@reach/router";

import {
  getPayoutsRoute,
  getPayoutPropertiesPeriodRoute,
  getPayoutPropertyRoute
} from "./payouts-navigation-routes.service";

/**
 * Navigates to payouts view
 *
 * @returns {Promise<any>}
 */
export const showPayouts = () => {
  return navigate(getPayoutsRoute());
};

/**
 * Navigates to payouts period view
 *
 * @returns {Promise<any>}
 */
export const showPayoutsPeriod = (year, month) => {
  return navigate(getPayoutPropertiesPeriodRoute(year, month));
};

/**
 * Navigates to payouts property view
 *
 * @returns {Promise<any>}
 */
export const showPayoutsProperty = (propertyId, year, month) => {
  return navigate(getPayoutPropertyRoute(propertyId, year, month));
};