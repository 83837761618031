import React from 'react';
import {Link} from "@reach/router";
import PropTypes from 'prop-types';

import './LinkButton.scss';

const LinkButton = ({className, children, href, onClick}) => {
  const classes = className ? [className, 'LinkButton'] : ['LinkButton'];
  return (
    <Link onClick={onClick}
       className={classes.join(' ')}
       to={href}>
      {children}
    </Link>
  );
};

LinkButton.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func
};

export default LinkButton;
