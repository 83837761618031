import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Checkbox, Input} from "antd";
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {getProperyAttachedStudios} from "../../../../../../../../../../services/properties-service/properties.service";
import {LogController} from "../../../../../../../../../../controllers/log-controller/log.controller";

import './SelectStudioModal.scss';

/* istanbul ignore file */
class SelectStudioModal extends Component {

  filterDebounceTime = 300;

  filterTimeoutId;

  modalRef = React.createRef();

  state = {
    allStudios: [],
    studioName: '',
    studios: [],
    selectedStudios: {}
  };

  constructor(props) {
    super(props);
    this.state.selectedStudios = cloneDeep(props.studios);
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  filterData = () => {
    const {allStudios} = this.state;
    const studios = allStudios.filter(this.matchStudioByName);
    this.setState({studios});
  };

  filterStudios = () => {
    this.getStudios()
      .then(this.filterData);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.closeModal}>
          {t(`SelectStudioModal.cancel`)}
        </Button>
        <Button onClick={this.saveChanges}>
          {t(`SelectStudioModal.saveStudios`)}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getStudios = () => {
    return new Promise(this.getStudiosExecutor);
  };

  getStudiosExecutor = (resolve, reject) => {
    const {propertyId} = this.props;
    const {allStudios} = this.state;
    if (allStudios.length) {
      resolve(allStudios);
    } else {
      getProperyAttachedStudios(propertyId)
        .then(this.onGetStudiosSuccess.bind(this, resolve))
        .catch(this.onGetStudiosFailure.bind(this, reject));
    }
  };

  getTitle = () => {
    const {t} = this.props;
    return t(`SelectStudioModal.selectStudios`);
  };

  matchStudioByName = ({name}) => {
    const {studioName} = this.state;
    return name.toLowerCase().indexOf(studioName.toLowerCase()) !== -1;
  };

  onGetStudiosFailure = (reject, error) => {
    LogController.logError(error);
    reject();
  };

  onGetStudiosSuccess = (resolve, response) => {
    const {studios: allStudios} = response.data.data;
    this.setState({allStudios}, resolve);
  };

  onStudioNameFilterChange = (event) => {
    const {value: studioName} = event.target;
    this.setState({studioName});
    if (this.filterTimeoutId) {
      clearTimeout(this.filterTimeoutId);
    }
    this.filterTimeoutId = setTimeout(this.filterStudios, this.filterDebounceTime);
  };

  studioMap = ({id, name}) => {
    const {selectedStudios} = this.state;
    const checked = !!selectedStudios[id];
    const classes = ['Studio'];
    if (checked) {
      classes.push('Checked');
    }
    return (
      <div className={classes.join(' ')}
           key={id}
           onClick={this.setStudioChecked.bind(this, id, name)}>
        <div className="Name">
          {name}
        </div>
        <Checkbox checked={checked}/>
      </div>
    );
  };

  renderFilteredStudios = () => {
    const {studios} = this.state;
    return studios.map(this.studioMap);
  };

  saveChanges = () => {
    const {selectedStudios} = this.state;
    const {studios, propertyId} = this.props;
    const data = {};
    for (let studioId in selectedStudios) {
      if (studios[studioId]) {
        data[studioId] = studios[studioId];
      } else {
        data[studioId] = selectedStudios[studioId];
      }
    }
    this.props.onStudiosSelected(propertyId, data);
    this.closeModal();
  };

  setStudioChecked = (id, name) => {
    this.setState(prevState => {
      const selectedStudios = cloneDeep(prevState.selectedStudios);
      const checked = !selectedStudios[id];
      if (checked) {
        selectedStudios[id] = {
          id, name
        };
      } else {
        delete selectedStudios[id];
      }
      return {selectedStudios};
    });
  };

  render() {
    const {t} = this.props;
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}
                   className="SelectStudioModal">
        <div className="SelectStudioModal-inner">
          <div className="NameFilter">
            <div className="Label">
              {t(`SelectStudioModal.search`)}:
            </div>
            <Input value={this.state.studioName}
                   onChange={this.onStudioNameFilterChange}
                   autoFocus/>
          </div>
          <div className="StudiosList">
            {this.renderFilteredStudios()}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

SelectStudioModal.propTypes = {
  onStudiosSelected: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  studios: PropTypes.object.isRequired
};

export default withTranslation()(SelectStudioModal);
