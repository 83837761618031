import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Checkbox, Input} from "antd";
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {getMovieById, getMovies} from "../../../../../../../../../../../services/movies-service/movies.service";

import {LogController} from "../../../../../../../../../../../controllers/log-controller/log.controller";

import './MovieSelectionModal.scss';

/* istanbul ignore file */
class MovieSelectionModal extends Component {

  modalRef = React.createRef();

  state = {
    movieId: '',
    movieName: '',
    movies: [],
    page: 1,
    pagesCount: 1,
    selectedMovies: {}
  };

  constructor(props) {
    super(props);
    this.state.selectedMovies = cloneDeep(props.selectedMovies);
  }

  clearLoading = () => {
    this.getModal().clearLoading();
  };

  filterMovies = () => {
    const {movieName} = this.state;
    if (movieName) {
      this.filterMoviesByName();
    } else {
      this.filterMoviesById();
    }
  };

  filterMoviesById = () => {
    this.setLoading();
    const {movieId} = this.state;
    getMovieById(movieId)
      .then(this.onFilterMoviesByIdSuccess)
      .catch(this.onRequestError);
  };

  filterMoviesByName = () => {
    this.setLoading();
    const {movieName, page} = this.state;
    const filters = [`search=${movieName}`, `elastic_score=desc`];
    getMovies(page, filters)
      .then(this.onFilterMoviesByNameSuccess)
      .catch(this.onRequestError);

  };

  getActions = () => {
    const {t} = this.props;
    return (
      <Button onClick={this.onSelectMovies}>
        {t('ConfigureAdsOnMovieLevel.MovieSelectionModal.selectMovies')}
      </Button>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('ConfigureAdsOnMovieLevel.MovieSelectionModal.title');
  };

  loadMoreMovies = () => {
    this.setState(prevState => {
      const {page} = prevState;
      return {
        page: page + 1
      };
    }, this.filterMoviesByName);
  };

  movieMap = ({title, movieId}) => {
    const checked = !!this.state.selectedMovies[movieId];
    const classes = ['MovieItem'];
    if (checked) {
      classes.push('Checked');
    }
    return (
      <div key={movieId}
           className={classes.join(' ')}
           onClick={this.setMovieSelected.bind(this, movieId, title)}>
        <div className="MovieTitle">
          {title}
        </div>
        <Checkbox checked={checked}/>
      </div>
    );
  };

  onFilterMoviesByIdSuccess = (response) => {
    this.clearLoading();
    const {data} = response.data;
    this.setState({
      movies: [data]
    })
  };

  onFilterMoviesByNameSuccess = (response) => {
    this.clearLoading();
    const {movies, pagination} = response.data.data;
    const {current_page, last_page: pagesCount} = pagination;
    this.setState(prevState => {
      let moviesData = current_page > 1 ? prevState.movies : [];
      moviesData = moviesData.concat(movies);
      return {
        movies: moviesData,
        page: current_page,
        pagesCount
      };
    });
  };

  onMovieIdChange = (event) => {
    const {value} = event.target;
    this.setState({
      movieId: value,
      movieName: '',
      page: 1
    });
  };

  onMovieNameChange = (event) => {
    const {value} = event.target;
    this.setState({
      movieId: '',
      movieName: value,
      page: 1
    });
  };

  onRequestError = (error) => {
    this.clearLoading();
    LogController.logError(error);
  };

  onSelectMovies = () => {
    this.props.onSelectMovies(cloneDeep(this.state.selectedMovies));
    this.getModal().closeModal();
  };

  renderMovies = () => {
    return this.state.movies.map(this.movieMap);
  };

  renderMoviesLoadMore = () => {
    let view = null;
    const {t} = this.props;
    const {page, pagesCount} = this.state;
    if (page !== pagesCount) {
      view = (
        <Button onClick={this.loadMoreMovies}>
          {t('ConfigureAdsOnMovieLevel.MovieSelectionModal.loadMoreMovies')}
        </Button>
      );
    }

    return view;
  };

  setLoading = () => {
    const message = this.props.t('ConfigureAdsOnMovieLevel.MovieSelectionModal.loadingMoviesMsg');
    this.getModal().setLoading(message);
  };

  setMovieSelected = (movieId, title) => {
    this.setState(prevState => {
      const selectedMovies = Object.assign({}, prevState.selectedMovies);
      const checked = !selectedMovies[movieId];
      if (checked) {
        selectedMovies[movieId] = {movieId, title};
      } else {
        delete selectedMovies[movieId];
      }
      return {
        selectedMovies
      };
    });
  };

  render() {
    const {t} = this.props;
    const {movieId, movieName} = this.state;
    return (
      <ModalDialog actions={this.getActions()}
                   title={this.getTitle()}
                   className="MovieSelectionModal"
                   forwardedRef={this.modalRef}>
        <div className="MovieSelectionModal-inner">
          <div className="MovieSelectionDescription"
               dangerouslySetInnerHTML={{__html: t('ConfigureAdsOnMovieLevel.MovieSelectionModal.description')}}/>
          <div className="FilterWrapper">
            <div className="Label">{t('ConfigureAdsOnMovieLevel.MovieSelectionModal.searchByMovieName')}</div>
            <Input.Search value={movieName}
                          onSearch={this.filterMovies}
                          enterButton
                          autoFocus
                          onChange={this.onMovieNameChange}/>
          </div>
          <div className="FilterWrapper">
            <div className="Label">{t('ConfigureAdsOnMovieLevel.MovieSelectionModal.searchByMovieId')}</div>
            <Input.Search value={movieId}
                          onSearch={this.filterMovies}
                          enterButton
                          onChange={this.onMovieIdChange}/>
          </div>
          <div className="MoviesList">
            {this.renderMovies()}
          </div>
          {this.renderMoviesLoadMore()}
        </div>
      </ModalDialog>
    );
  }
}

MovieSelectionModal.propTypes = {
  onSelectMovies: PropTypes.func.isRequired,
  selectedMovies: PropTypes.object.isRequired
};

export default withTranslation()(MovieSelectionModal);
