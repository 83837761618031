import { Subject } from 'rxjs';

import { cloneDeep } from 'lodash';

export const MovieDetailsEventMap = Object.freeze({
  movie: {
    deleteImagesEvent: 'deleteImagesEvent',
    editEvent: 'editEvent',
    selectSubCategoriesEvent: 'selectSubCategoriesEvent',
    syncEvent: 'syncEvent',
    uploadImagesEvent: 'uploadImagesEvent'
  },
  scene: {
    detailsEvent: 'detailsEvent',
    editEvent: 'editEvent',
    sceneImageUploadFinishedEvent: 'sceneImageUploadFinishedEvent',
    sceneImagesDeleteFinishedEvent: 'sceneImagesDeleteFinishedEvent',
    syncEvent: 'syncEvent',
    viewContentInfoEvent: 'viewContentInfoEvent'
  }
});

class MovieDetailsComm {
  static instance;

  events = {
    movie: {
      [MovieDetailsEventMap.movie.deleteImagesEvent]: new Subject(),
      [MovieDetailsEventMap.movie.editEvent]: new Subject(),
      [MovieDetailsEventMap.movie.selectSubCategoriesEvent]: new Subject(),
      [MovieDetailsEventMap.movie.syncEvent]: new Subject(),
      [MovieDetailsEventMap.movie.uploadImagesEvent]: new Subject()
    },
    scene: {
      [MovieDetailsEventMap.scene.detailsEvent]: new Subject(),
      [MovieDetailsEventMap.scene.editEvent]: new Subject(),
      [MovieDetailsEventMap.scene.sceneImagesDeleteFinishedEvent]: new Subject(),
      [MovieDetailsEventMap.scene.sceneImageUploadFinishedEvent]: new Subject(),
      [MovieDetailsEventMap.scene.syncEvent]: new Subject(),
      [MovieDetailsEventMap.scene.viewContentInfoEvent]: new Subject()
    },
    turnOffEditImages: new Subject()
  };

  movieData;

  static getInstance() {
    if (MovieDetailsComm.instance == null) {
      MovieDetailsComm.instance = new MovieDetailsComm();
    }

    return MovieDetailsComm.instance;
  }

  fireEvent = (type, key, data) => {
    const instance = MovieDetailsComm.getInstance();
    instance.getEvent(type, key).next(data);
  };

  getEvent = (type, key) => {
    const instance = MovieDetailsComm.getInstance();
    return instance.events[type][`${key}`];
  };

  getMovieData = () => {
    const instance = MovieDetailsComm.getInstance();
    return cloneDeep(instance.movieData);
  };

  setMovieData = (data) => {
    const instance = MovieDetailsComm.getInstance();
    instance.movieData = cloneDeep(data);
  };

  subscribeToEvent = (type, key, callback) => {
    const instance = MovieDetailsComm.getInstance();
    return instance.getEvent(type, key).subscribe(callback);
  };
}

export const MovieDetailsCommController = MovieDetailsComm.getInstance();
