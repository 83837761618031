import {withTranslation} from "react-i18next";

import UploadImages from "../../../../../../../../../../components/modal/UploadImages/UploadImages";

class UploadStarImagesModal extends UploadImages {

  /**@override
   */
  getImageCategories = () => {
    const {t} = this.props;
    return [t('UploadStarImagesModal.galleryShot'), t('UploadStarImagesModal.glamourShot'), t('UploadStarImagesModal.headerShot')];
  };
}

export default withTranslation()(UploadStarImagesModal);