import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Card, List } from "antd";
import PropTypes from 'prop-types';

import ImageComponent from "../../ImageComponent/ImageComponent";

import './BaseImagesDisplayList.scss';
import ImageActions from '../../ImageActions/ImageActions';

class BaseImagesDisplayList extends Component {

  findImageIndexByUrl = (url) => {
    return this.props.images.findIndex(this.matchImageUrl.bind(this, url));
  };

  matchImageType = ({ type }) => {
    return type.toLowerCase().indexOf(this.props.searchType.toLowerCase()) !== -1;
  };

  matchImageUrl = (url1, { url }) => {
    return url1 === url;
  };

  renderListCardCover = (data) => {
    const { url, is_cover: isCover, origin } = data;
    const index = this.findImageIndexByUrl(url);
    let imageEl = (
      <ImageComponent
        isCover={isCover}
        origin={origin}
        url={url}
      />
    );
    const { onDeleteImage, onEditImage, onSetHeroStill, bannerId, movieId } = this.props;
    return (
      <ImageActions
        data={data}
        index={index}
        onFullScreen={this.toggleFullScreen.bind(this, index)}
        onDeleteImage={onDeleteImage}
        onEditImage={onEditImage}
        onSetHeroStill={onSetHeroStill}
        key={index}
        bannerId={bannerId}
        movieId={movieId}
      >
        {imageEl}
      </ImageActions>
    );
  };

  renderListCard = (image) => {
    const { filename, type } = image;
    return (
      <Card cover={this.renderListCardCover(image)}>
        <Card.Meta title={filename} description={type} />
      </Card>
    );
  };

  toggleFullScreen = (index) => {
    this.props.toggleFullScreen(index);
  };

  render() {
    let { images, searchType } = this.props;
    images = searchType ? images.filter(this.matchImageType) : images;
    return (
      <div className="BaseImagesDisplayList">
        <List dataSource={images}
          renderItem={this.renderListCard} />
      </div>
    );
  }
}

BaseImagesDisplayList.propTypes = {
  images: PropTypes.array.isRequired,
  searchType: PropTypes.string,
  toggleFullScreen: PropTypes.func,
  bannerId: PropTypes.string,
  movieId: PropTypes.string,
  onDeleteImage: PropTypes.func.isRequired,
  onEditImage: PropTypes.func.isRequired,
  onSetHeroStill: PropTypes.func
};

export default withTranslation()(BaseImagesDisplayList);