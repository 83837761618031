import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Icon, Layout } from 'antd';

import { LogController } from '../../controllers/log-controller/log.controller';
import { ModalController } from '../../controllers/modal-controller/modal.controller'
import { LoadingController } from '../../controllers/loading-controller/loading.controller';

import ConfirmationModal from "../../components/modal/ConfirmationModal/ConfirmationModal";
import MainDashboardHeader from './components/MainDashboardHeader/MainDashboardHeader';
import MainView from './components/MainView/MainView';

import { MenuItems } from './MenuItemsEnum';

import { authLogout } from '../../services/auth-service/auth.service';
import { cleanupToken, getUserToken } from '../../services/token-service/token.service';
import { showNavigationRoot } from "../../services/navigation/navigation.service";
import { getUsersBaseRoute } from "../../services/navigation/users-navigation/users-navigation-routes.service";
import { showUsers } from "../../services/navigation/users-navigation/users-navigation.service"
import { getStudiosRoute } from "../../services/navigation/studios-navigation/studios-navigation-routes.service";
import { showStudios } from "../../services/navigation/studios-navigation/studios-navigation.service";
import { getStarsRoute } from "../../services/navigation/stars-navigation/stars-navigation-routes.service";
import { showStars } from "../../services/navigation/stars-navigation/stars-navigation.service";
import { getPropertiesRoute } from "../../services/navigation/properties-navigation/properties-navigation-routes.service";
import { showProperties } from "../../services/navigation/properties-navigation/properties-navigation.service";
import { getBannersBaseRoute } from "../../services/navigation/banners-navigation/banners-navigation-routes.service";
import { showBanners } from "../../services/navigation/banners-navigation/banners-navigation.service";
import { getThemesRoute } from "../../services/navigation/categories-navigation/categories-navigation-routes.service";
import { showThemes } from "../../services/navigation/categories-navigation/categories-navigation.service";
import { getLicensorsRoute } from "../../services/navigation/licensors-navigation/licensors-navigation-routes.service";
import { showLicensors } from "../../services/navigation/licensors-navigation/licensors-navigation.service";
// import { getMailsRoute } from "../../services/navigation/mails-navigation/mails-navigation-routes.service";
// import { showMail } from "../../services/navigation/mails-navigation/mails-navigation.service"
import { getManufacturersRoute } from "../../services/navigation/manufacturers-navigation/manufacturers-navigation-routes.service";
import { showManufacturers } from "../../services/navigation/manufacturers-navigation/manufacturers-navigation.service";
import { getMoviesRoute } from "../../services/navigation/movies-navigation/movies-navigation-routes.service";
import { showMovies } from "../../services/navigation/movies-navigation/movies-navigation.service";
import { getPayoutsRoute } from "../../services/navigation/payouts-navigation/payouts-navigation-routes.service";
import { showPayouts } from "../../services/navigation/payouts-navigation/payouts-navigation.service";

import { removeUserLocalData } from "../../services/users-service/users.service";

import AboutModal from "../../components/modal/AboutModal/AboutModal";
import UserSettingsModal from "../../components/modal/UserSettingsModal/UserSettingsModal";

import { cloneDeep } from "lodash";
import UploadDroppedFiledModal from "./UploadDroppedFiledModal/UploadDroppedFiledModal";

import { stopEvent } from "../../services/util-service/util.service";

import ConfigureAppAds
  from "./components/MainView/components/MainContent/components/MoviesView/ConfigureAppAds/ConfigureAppAds";
import ConfigurePlayer
  from "./components/MainView/components/MainContent/components/MoviesView/ConfigurePlayer/ConfigurePlayer";
import { env } from '../../env';
import './MainDashboard.scss';
import { getUnassignedViewTime, showUnassignedViewTime } from '../../services/navigation/view-time-unassigned/view-time-unassigned-navigation';

class MainDashboard extends Component {

  dropRef = React.createRef();

  fileCounter = 0;

  navigationItems = [
    {
      name: this.props.t('MainDashboard.properties'),
      navigate: showProperties,
      link: getPropertiesRoute(),
      icon: 'database'
    },
    {
      name: this.props.t('MainDashboard.studios'),
      navigate: showStudios,
      link: getStudiosRoute(),
      icon: 'video-camera'
    },
    {
      name: this.props.t('MainDashboard.movies'),
      navigate: showMovies,
      link: getMoviesRoute(),
      icon: 'camera'
    },
    {
      name: this.props.t('MainDashboard.stars'),
      navigate: showStars,
      link: getStarsRoute(),
      icon: 'star'
    },
    {
      name: this.props.t('MainDashboard.categories'),
      navigate: showThemes,
      link: getThemesRoute(),
      icon: 'tags'
    },
    {
      name: this.props.t('MainDashboard.banners'),
      navigate: showBanners,
      link: getBannersBaseRoute(),
      icon: 'flag'
    },
    {
      name: this.props.t('MainDashboard.users'),
      navigate: showUsers,
      link: getUsersBaseRoute(),
      icon: 'skin'
    },
    {
      name: this.props.t('MainDashboard.licensors'),
      navigate: showLicensors,
      link: getLicensorsRoute(),
      icon: 'reconciliation'
    },
    {
      name: this.props.t('MainDashboard.mfids'),
      navigate: showManufacturers,
      link: getManufacturersRoute(),
      icon: 'appstore'
    },
    {
      name: this.props.t('MainDashboard.payouts'),
      navigate: showPayouts,
      link: getPayoutsRoute(),
      icon: 'pay-circle'
    },
    // {
    //   name: this.props.t('MainDashboard.mail'),
    //   navigate: showMail,
    //   link: getMailsRoute(),
    //   icon: 'mail'
    // },
    {
      name: this.props.t('MainDashboard.unassigned_view_time'),
      navigate: showUnassignedViewTime,
      link: getUnassignedViewTime(),
      icon: 'key'
    }
  ];

  state = {
    dragging: false,
    files: []
  };

  images = [];

  /**
   * React component lifecycle hook
   * Used to check for user token and navigate to correct view based on token existence
   */
  componentDidMount() {
    this.setDragListeners();
    if (!getUserToken()) {
      this.navigateToRoot();
    } else {
      ModalController.setupKeysListener();
    }
  }

  /**
   * React component lifecycle hook
   * Cleans up keyboard events listeners
   */
  componentWillUnmount() {
    this.clearDragListeners();
    ModalController.cleanupKeysListener();
  }

  allFilesRead = () => {
    return !this.state.files.find(this.matchFileWithoutUrl);
  };

  /**
   * Cleans up any user data on logout
   *
   * @returns {Promise<void>}
   */
  cleanup = async () => {
    cleanupToken();
    removeUserLocalData();
    await this.navigateToRoot();
    LoadingController.removeLoadingMask();
  };

  clearDragListeners = () => {
    let div = this.dropRef.current;
    if (div) {
      div.removeEventListener('dragenter', this.handleDragIn);
      div.removeEventListener('dragleave', this.handleDragOut);
      div.removeEventListener('dragover', this.handleDrag);
      div.removeEventListener('drop', this.handleDrop);
    }
  };

  /**
   * Displays logout confirm modal
   */
  confirmLogout = () => {
    const { t } = this.props;
    const modal = (
      <ConfirmationModal confirm={this.logoutUser} title={t('MainDashboard.confirmLogout')}
        message={t('MainDashboard.confirmLogoutMessage')} />
    );
    ModalController.showModal(modal);
  };

  displayAdsConfigDialog = () => {
    const modal = (
      <ConfigureAppAds />
    );
    ModalController.showModal(modal);
  };

  displayConfigPlayerDialog = () => {
    const modal = (
      <ConfigurePlayer />
    );
    ModalController.showModal(modal);
  };

  displayUploadFilesModal = () => {
    if (this.allFilesRead()) {
      const modal = (
        <UploadDroppedFiledModal
          files={this.state.files}
          onClose={this.onUploadDropFilesModalClose}
          onDeleteFile={this.onDeleteFile}
          onDeleteAllFiles={this.onDeleteAllFiles}
        />
      );
      ModalController.showModal(modal);
    }
  };

  fileAdded = (fileName) => {
    return !!this.state.files.find(this.matchFileByName.bind(this, fileName));
  };

  /**
   * Handles header avatar dropdown list click
   *
   * @param {Event} event
   */
  handleClick = (event) => {
    const id = event.target.id;
    if (id) {
      this.handleRouteSwitch(id);
    }
  };

  handleDrag = (e) => {
    stopEvent(e);
  };

  handleDragIn = (e) => {
    stopEvent(e);
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setDragging(true);
    }
  };

  handleDragOut = (e) => {
    stopEvent(e);
    this.dragCounter--;
    if (this.dragCounter <= 0) {
      this.setDragging(false);
    }
  };

  handleDrop = (e) => {
    stopEvent(e);
    this.dragCounter = 0;
    this.processSelectedFiles(e.dataTransfer.files);
    e.dataTransfer.clearData();
    this.setDragging(false);
  };

  /**
   * Handles logout error
   *
   * @param {Object} e
   */
  handleLogoutError = (e) => {
    const error = e.response.data || e;
    LogController.logError(error);
    LoadingController.removeLoadingMask();
  };

  /**
   * Handles logout success and cleans up user data
   *
   * @param {Object} response
   */
  handleLogoutSuccess = (response) => {
    if (response.status === 204) {
      this.cleanup();
    }
  };

  /**
   * Executes action depending on the passed id
   *
   * @param {string} id
   */
  handleRouteSwitch = (id) => {
    switch (id) {
      case MenuItems.ABOUT:
        this.showAboutModal();
        break;
      case MenuItems.SETTINGS:
        this.showSettingsModal();
        break;
      case MenuItems.LOGOUT:
        this.confirmLogout();
        break;
      default:
        break;
    }
  };

  /**
   * Triggers user logout request
   *
   * @returns {Promise<void>}
   */
  logoutUser = async () => {
    const { t } = this.props;
    LoadingController.showLoadingMask(t('MainDashboard.loggingOut'));
    try {
      const response = await authLogout();
      this.handleLogoutSuccess(response);
    } catch (e) {
      this.handleLogoutError(e);
    }
  };

  matchFileById = (id, file) => {
    return file.id === id;
  };

  matchFileByName = (name, file) => {
    return file.name === name;
  };

  matchFileWithoutUrl = (file) => {
    return !file.url;
  };

  /**
   * Navigates to root route
   *
   * @returns {Promise<void>}
   */
  async navigateToRoot() {
    await showNavigationRoot();
  }

  onDeleteAllFiles = () => {
    this.setState({
      files: []
    });
  };

  onDeleteFile = (id) => {
    this.setState(prevState => {
      const files = cloneDeep(prevState.files);
      const index = files.findIndex(this.matchFileById.bind(this, id));
      files.splice(index, 1);
      return {
        files: files
      };
    });
  };

  onFileRead = (id, e) => {
    const files = cloneDeep(this.state.files);
    const index = files.findIndex(this.matchFileById.bind(this, id));
    const url = e.target.result;
    files[index].url = url;
    this.images[index] = url;
    this.setState({
      files: files
    }, this.displayUploadFilesModal);
  };

  /**
   * Show properties route on logo click
   */
  onLogoClick = () => {
    showProperties();
  };

  onUploadDropFilesModalClose = () => {
    this.setState({
      files: []
    });
    this.images = [];
  };

  processSelectedFiles = (files) => {
    let fileList = cloneDeep(this.state.files);
    for (let i = 0; i < files.length; i++) {
      if (files[i].name && !this.fileAdded(files[i].name)) {
        this.fileCounter++;
        fileList.push({
          name: files[i].name,
          id: this.fileCounter,
          selected: true,
          file: files[i]
        });
        this.images.push('');
        this.readURL(files[i], this.fileCounter);
      }
    }
    this.setState({ files: fileList });
  };

  readURL(file, id) {
    const reader = new FileReader();
    reader.onload = this.onFileRead.bind(this, id);

    reader.readAsDataURL(file);
  }

  renderDropZone = () => {
    const { t } = this.props;
    const { dragging } = this.state;
    return dragging ? (
      <div className="DropZone">
        <div className="DropZone-inner">
          <Icon type="cloud-upload" />
          <div>{t('MainDashboard.dragDropMsg')}</div>
        </div>
      </div>
    ) : null;
  };

  setDragging = (dragging) => {
    this.setState({ dragging });
  };

  setDragListeners = () => {
    this.dragCounter = 0;
    let div = this.dropRef.current;
    if (div) {
      div.addEventListener('dragenter', this.handleDragIn);
      div.addEventListener('dragleave', this.handleDragOut);
      div.addEventListener('dragover', this.handleDrag);
      div.addEventListener('drop', this.handleDrop);
    }
  };

  /**
   * Shows about modal dialog
   */
  showAboutModal = () => {
    const { REACT_APP_VERSION } = env;
    const modal = (
      <AboutModal appVersion={REACT_APP_VERSION} />
    );
    ModalController.showModal(modal);
  };

  showSettingsModal = () => {
    const modal = (
      <UserSettingsModal />
    );
    ModalController.showModal(modal);
  };

  render() {
    return (
      <Layout className="MainDashboard">
        <div ref={this.dropRef}
          className="MainDashboard-inner">
          <MainDashboardHeader onClick={this.handleClick}
            onLogoClick={this.onLogoClick}
            navigationItems={this.navigationItems}
            displayUploadFilesModal={this.displayUploadFilesModal}
            displayAdsConfigDialog={this.displayAdsConfigDialog}
            displayConfigPlayerDialog={this.displayConfigPlayerDialog} />
          <MainView navigationItems={this.navigationItems} />
          {this.renderDropZone()}
        </div>
      </Layout>
    );
  }
}

export default withTranslation()(MainDashboard);
