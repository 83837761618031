import React, {Component} from 'react';
import moment from 'moment';
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';

import {BaseVideoPlayerController, FullPlayer} from "@falconstudios/ns-player";

import {LogController} from "../../../../../../../../../../controllers/log-controller/log.controller";
import {MovieDetailsCommController} from "../movie-details-comm.controller";

import {getAebnMovieStream} from "../../../../../../../../../../services/streaming-service/streaming.service";
import {getMoviePlayerStills} from "../../../../../../../../../../services/movies-service/movies.service";
import {getPropertyConfig} from "../../../../../../../../../../services/player-configuration-service/player-configuration-service";

import './MovieDetailsPlayerTab.scss';

/* istanbul ignore file */
class MovieDetailsPlayerTab extends Component {

  autoplay = false;

  state = {
    ads: [],
    playerConfiguration: {},
    videos: []
  };

  videoFormatUpdateSubscription;

  constructor(props) {
    super(props);
    const userSettings = BaseVideoPlayerController.getUserSettings();
    this.autoplay = !!userSettings.autoplay;
  }

  componentDidMount() {
    this.updatePlayerData();
  }

  componentWillUnmount() {
    if (this.videoFormatUpdateSubscription) {
      this.videoFormatUpdateSubscription.unsubscribe();
    }
  }

  loadMovieData = (values) => {
    const {data: url} = values[0].data;
    const {images, movieId, title, runTime} = MovieDetailsCommController.getMovieData();
    const videos = [{
      id: movieId,
      sources: [
        {
          src: url,
          type: BaseVideoPlayerController.getUserSettingsVideoFormat()
        }
      ],
      duration: moment.duration(runTime).asSeconds(),
      thumbnail: [
        {
          src: images[0].url
        }
      ],
      poster: images[0].url,
      name: title
    }];
    const {propertyPlayerConfig} = values[1].data.data;
    const state = {
      ads: [],
      images,
      playerConfiguration: this.getValidPlayerConfig(propertyPlayerConfig),
      videos
    };
    this.setState(cloneDeep(state));
    return state;
  };

  getValidPlayerConfig = (propertyPlayerConfig) => {
    const {movieId, data} = this.props;
    const {studioId} = data.studios[0].id;
    let playerConfig = {};
    if (propertyPlayerConfig) {
      const configuration = this.mapPropertyPlayerConfigObject(propertyPlayerConfig);
      playerConfig = configuration.config;
      if (configuration.studios) {
        const studioConfig = configuration.studios[studioId];
        if (studioConfig) {
          playerConfig = studioConfig.config;
          if (studioConfig.movies) {
            const movieConfig = studioConfig.movies[movieId];
            if (movieConfig) {
              playerConfig = movieConfig.config;
            }
          }
        }
      }
    }

    return playerConfig;
  };

  mapConfigObject = (config) => {
    const data = {};
    let item = config.length;
    let currentKey;
    while (item) {
      item--;
      currentKey = config[item];
      data[currentKey] = true;
    }

    return data;
  };

  mapObject = (data, callbackFn) => {
    const mappedData = {};
    let currentItem;
    for (const key in data) {
      currentItem = data[key];
      mappedData[key] = callbackFn(key, currentItem);
    }

    return mappedData;
  };

  mapPropertyPlayerConfigObject = ({config, studios}) => {
    const data = {
      config: this.mapConfigObject(config)
    };
    if (studios) {
      data.studios = this.mapObject(studios, this.studioMapFn)
    }

    return data;
  };

  mapVideoThumbnails = (response) => {
    return response.data.data;
  };

  movieMapFn = (movieId, {config}) => {
    return {
      config: this.mapConfigObject(config)
    };
  };

  onAddClick = ({url}) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  onGetAebnMovieStreamFailure = (error) => {
    LogController.logError(error);
  };

  studioMapFn = (studioId, {config, movies}) => {
    const data = {
      config: this.mapConfigObject(config)
    };
    if (movies) {
      data.movies = this.mapObject(movies, this.movieMapFn)
    }

    return data;
  };

  updatePlayerData = () => {
    const {movieId} = this.props;
    const format = BaseVideoPlayerController.getUserStreamVideoFormat();
    const maxBitrate = BaseVideoPlayerController.getMaxBitrate();
    const promises = [
      getAebnMovieStream({format, maxBitrate, movieId}),
      getPropertyConfig(1)
    ];
    return Promise.all(promises)
      .then(this.loadMovieData)
      .catch(this.onGetAebnMovieStreamFailure);
  };

  render() {
    const {ads, videos, playerConfiguration} = this.state;
    const data = {
      ads: ads,
      autoplay: this.autoplay,
      className: "Player",
      fetchVideoThumbnails: getMoviePlayerStills,
      mapVideoThumbnails: this.mapVideoThumbnails,
      onAddClick: this.onAddClick,
      playerConfiguration: playerConfiguration,
      videos: videos
    };
    return videos.length ? (
      <div className="MovieDetailsPlayerTab">
        <FullPlayer data={data}
                    onVideoFormatChange={this.updatePlayerData}/>
      </div>
    ) : null;
  }
}

MovieDetailsPlayerTab.propTypes = {
  data: PropTypes.object.isRequired,
  movieId: PropTypes.string.isRequired
};

export default MovieDetailsPlayerTab;
