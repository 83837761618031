import { withTranslation } from 'react-i18next';

import MoviesViewFooterAbstract from "../../../MoviesView/MoviesMainView/MoviesViewFooter/MoviesViewFooterAbstract";

import './StarMoviesFooter.scss';

class StarMoviesFooter extends MoviesViewFooterAbstract {
  renderChildren = () => null;
}

export default withTranslation()(StarMoviesFooter);
