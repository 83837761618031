import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Table} from "antd";

import {getAllOrientations} from "../../../services/properties-service/properties.service";
import {
  getUserOrientations,
  attachUserOrientation
} from "../../../services/users-service/users.service";

import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../services/notification-service/notification.service';

import ModalDialog from "../ModalDialog/ModalDialog";

import './UserSettingsModal.scss';

class UserSettingsModal extends Component {

  modalRef = React.createRef();

  userOrientations = [];

  state = {
    orientations: [],
    selectAll: false,
    selectedRowKeys: [],
  }

  columns = [
    {
      title: this.props.t('UserSettingsModal.name'),
      dataIndex: 'name'
    }
  ];

  componentDidMount() {
    this.fetchData();
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  fetchData = () => {
    const message = this.props.t('UserSettingsModal.loadingPleaseWait');
    this.getModal().setLoading(message);

    const promises = [this.fetchOrientations(), this.fetchUserData()];
    Promise.all(promises)
      .then(this.onGetDataSuccess)
      .catch(this.onGetDataFailure);
  };

  fetchOrientations = () => {
    return getAllOrientations();
  };

  fetchUserData = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    return getUserOrientations(userData.id);
  };

  getActions = () => {
    const {t} = this.props;
    return ([
      <Button onClick={this.closeModal} key="cancelBtn">
        {t('UserSettingsModal.cancel')}
      </Button>,
      <Button onClick={this.saveChanges} 
              key="saveBtn"
              disabled={!this.hasDataChanged()}>
        {t('UserSettingsModal.saveChanges')}
      </Button>
    ]);
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getOrientationId = item => {
    return item.id;
  };

  getTitle = () => {
    return this.props.t('UserSettingsModal.title');
  };

  hasDataChanged = () => {
    return this.userOrientations.toString() !== this.state.selectedRowKeys.toString();
  };

  onAttachOrientationsFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('UserSettingsModal.attachOrientationsFailure')
    });
  };

  onAttachOrientationsSuccess = () => {
    this.getModal().clearLoading();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('UserSettingsModal.attachOrientationsSuccess')
    });
    this.closeModal();
  };

  onGetDataFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('UserSettingsModal.getDataFailed')
    });
  };

  onGetDataSuccess = response => {
    const orientationsResponse = response[0];
    const userResponse = response[1];
    const {orientation} = userResponse.data.data;

    const userOrientationIds = orientation.map(this.getOrientationId);
    const orientationsData = orientationsResponse.data.data;

    this.userOrientations = userOrientationIds.toString();

    this.setState({
      orientations: orientationsData,
      selectedRowKeys: userOrientationIds
    });

    this.getModal().clearLoading();
  };

  onSelect = item => {
    let selectedKeys = this.state.selectedRowKeys;
    const {id} = item;
    if(selectedKeys.includes(id)) {
      const index = selectedKeys.indexOf(id);
      selectedKeys.splice(index, 1);
    } else {
      selectedKeys.push(id);
    }

    this.setState({
      selectedRowKeys: selectedKeys
    });
  };

  onSelectAll = () => {
    this.setState(prevState => {
      let selectedRowKeys= [];
      if (!prevState.selectAll) {
        selectedRowKeys = this.state.orientations.map(this.getOrientationId);
      }
      return {
        selectAll: !prevState.selectAll,
        selectedRowKeys
      };
    });
  };

  renderOrientationsTable = () => {
    const {orientations, selectedRowKeys} = this.state;
    let table = null;

    const rowSelection = {
      selectedRowKeys,
      onSelect: this.onSelect,
      onSelectAll: this.onSelectAll
    };
  
    if(orientations.length) {
      table = (
        <Table
          rowSelection={rowSelection}
          rowKey="id"
          columns={this.columns}
          dataSource={this.state.orientations}
          bordered
          showHeader={false}
          pagination={false}
        />
      );
    }

    return table;
  };

  saveChanges = () => {
    const {selectedRowKeys} = this.state;

    const message = this.props.t('UserSettingsModal.savingMsg');
    this.getModal().setLoading(message);

    attachUserOrientation(selectedRowKeys)
      .then(this.onAttachOrientationsSuccess)
      .catch(this.onAttachOrientationsFailure);
  };

  render() {
    const {t} = this.props;
    
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}>
        <div className="UserSettingsModal">
          <div className="UserSettingsModal-label">{t('UserSettingsModal.displayOnly')}:</div>
          <div className="UserSettingsModal-inner">
            {this.renderOrientationsTable()}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

export default withTranslation()(UserSettingsModal);
