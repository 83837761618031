import {navigate} from "@reach/router";

import {
  getMoviesRoute,
  getMovieDetailsRoute,
  getMoviesPlaylistsRoute,
  getMoviesScenesPlaylistRoute,
  getMovieSceneDetailsRoute,
  getMovieSceneContentInfoRoute,
  getMovieContentInfoRoute
} from "./movies-navigation-routes.service";

/**
 * Navigates to movies view
 *
 * @returns {Promise<any>}
 */
export const showMovies = () => {
  return navigate(getMoviesRoute());
};

/**
 * Navigates to movie details view
 *
 * @param {number|string} movieId
 *
 * @returns {Promise<any>}
 */
export const showMovieDetails = movieId => {
  return navigate(getMovieDetailsRoute(movieId));
};

export const showMovieContentInfo = (movieId) => {
  return navigate(getMovieContentInfoRoute(movieId));
};

export const showMovieSceneContentInfo = (movieId, sceneId) => {
  return navigate(getMovieSceneContentInfoRoute(movieId, sceneId));
};

/**
 * Navigates to movie playlist view
 *
 * @returns {Promise<any>}
 */
export const showMoviesPlaylist = () => {
  return navigate(getMoviesPlaylistsRoute());
};

/**
 * Navigates to movie scene details view
 *
 * @param {number|string} movieId
 * @param {number|string} sceneId
 *
 * @returns {Promise<any>}
 */
export const showMovieSceneDetails = (movieId, sceneId) => {
  return navigate(getMovieSceneDetailsRoute(movieId, sceneId));
};

/**
 * Navigates to scene playlist view
 *
 * @returns {Promise<any>}
 */
export const showScenesPlaylist = () => {
  return navigate(getMoviesScenesPlaylistRoute());
};