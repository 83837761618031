import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Select } from 'antd';

import './BannerFilters.scss';

class BannerFilters extends Component {
  state = {
    filters: {
      display: ''
    }
  };

  constructor(props) {
    super(props);

    Object.assign(this.state, {
      filters: {
        display: props.defaultDisplayFilter
      }
    });
  }

  callPropsFilterChange = () => {
    this.props.onFilterChange(this.state.filters);
  };

  onDisplayChange = status => {
    this.setState(prevState => {
      return {
        filters: {
          ...prevState.filters,
          display: status
        }
      };
    }, this.callPropsFilterChange);
  };

  renderDisplayFilterItem = item => {
    const { t } = this.props;
    return (
      <Select.Option key={item} value={item}>
        <div>{t(`BannerFilters.${item}`)}</div>
      </Select.Option>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className='BannerFilters'>
        <div className='InputWrapper'>
          <div>{t('BannerFilters.display')}:</div>
          <Select
            showArrow
            onChange={this.onDisplayChange}
            value={this.state.filters.display}
          >
            {this.props.generalLinks.map(this.renderDisplayFilterItem)}
          </Select>
        </div>
      </div>
    );
  }
}

BannerFilters.propTypes = {
  defaultDisplayFilter: PropTypes.string.isRequired,
  generalLinks: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired
};

export default withTranslation()(BannerFilters);
