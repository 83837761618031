import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import BannerSetCardAbstract from "../../BannerSetCard/BannerSetCardAbstract";

import {
  getBannerSetPropertiesDefaultBannersRoute,
} from "../../../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";
import {getDefaultBannerSetSaveKey} from "../../../../../../../../../../../services/banners-service/banners.service";

/* istanbul ignore file */
class BannerSetPropertiesDefaultBanners extends BannerSetCardAbstract {

  getAdditionalClasses = () => ['BannerSetPropertiesDefaultBanners'];

  getBannersRoute = () => {
    const {bannerSetId} = this.props;
    return getBannerSetPropertiesDefaultBannersRoute(bannerSetId);
  };

  getSavedData = () => {
    const {bannerSetId} = this.props;
    let data = {};
    const key = getDefaultBannerSetSaveKey(bannerSetId);
    let savedData = localStorage.getItem(key);
    if (savedData) {
      data = JSON.parse(savedData);
    }

    return data;
  };
}

BannerSetPropertiesDefaultBanners.propTypes = {
  allActionsIncluded: PropTypes.bool,
  bannerOrder: PropTypes.array.isRequired,
  bannerSetId: PropTypes.number.isRequired,
  doNotShowStates: PropTypes.bool,
  isDefault: PropTypes.bool,
  maxElementCount: PropTypes.number,
  propertyId: PropTypes.number,
  propertyCount: PropTypes.number,
  title: PropTypes.string.isRequired,
  totalBannerCount: PropTypes.number.isRequired
};

export default withTranslation()(BannerSetPropertiesDefaultBanners);
