import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import './BaseVirtualList.scss';
import { withTranslation } from 'react-i18next';

/* istanbul ignore file */
class BaseVirtualList extends Component {

  bottomBufferZoneHeight;

  mainListRef = React.createRef();

  maxSameHeightCheckCount = 5;

  scrollTimeoutId;

  state = {
    scrollPosition: 0,
    loadInProgress: false,
    itemHeight: 0,
    segmentLength: 1
  };

  subscriptions = {};

  tempItemHeight;

  timeoutId;

  topBufferZoneHeight;

  updateItemHeightFinished = false;

  updateTriggered = false;

  visibleItemsCount;

  visibleItemsDeviation = 5;

  componentDidMount() {
    this.setState({ segmentLength: this.props.segmentLength })
  }

  componentDidUpdate() {
    if (this.state.segmentLength !== this.props.segmentLength) {
      this.setState({ segmentLength: this.props.segmentLength })
    }
  }

  componentWillUnmount() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
  }

  getBottomBufferZoneHeight = (endPosition) => {
    let height = 0;
    const { itemHeight, segmentLength } = this.state;
    if (itemHeight) {
      const { items } = this.props;
      height = (items.length - endPosition) / segmentLength * itemHeight;
    }
    return height;
  };

  getEndPosition = (start) => {
    const { items } = this.props;
    const { segmentLength } = this.state;
    let endPosition = undefined;
    if (this.visibleItemsCount) {
      if (segmentLength > 1) {
        const { scrollPosition } = this.state;
        endPosition = (scrollPosition + this.visibleItemsCount * 2 + this.visibleItemsDeviation) * segmentLength;
      } else {
        endPosition = start + this.visibleItemsCount + this.visibleItemsDeviation;
      }
    }
    if (endPosition > items.length) {
      endPosition = items.length;
    }

    return endPosition;
  };

  getItemId = (item, index) => {
    const { getItemId } = this.props;
    let id = index;
    if (getItemId) {
      id = getItemId(item, index);
    } else if (item.id !== undefined) {
      id = item.id;
    }

    return id;
  };

  getMainList = () => this.mainListRef.current;

  getMaxItemHeight = () => {
    const items = document.getElementsByClassName('Item');
    let item = items.length;
    let max;
    if (item) {
      max = items[0].scrollHeight;
      let currentItem;
      while (item) {
        item--;
        currentItem = items[item];
        if (currentItem.scrollHeight > max) {
          max = currentItem.scrollHeight;
        }
      }
    }

    return max;
  };

  getScrollPosition = () => {
    const mainList = this.getMainList();
    const notInfinity = Math.trunc(mainList.scrollTop / this.state.itemHeight);
    return isFinite(notInfinity) ? notInfinity : 0;
  };

  getStartPosition = () => {
    const { scrollPosition, segmentLength } = this.state;
    let startingPosition = 0;
    if (scrollPosition) {
      if (segmentLength > 1) {
        const denominator = this.visibleItemsCount * 2;
        let rowIndex = scrollPosition - denominator;
        if (rowIndex < 0) {
          rowIndex = 0;
        }
        startingPosition = rowIndex * segmentLength;
      } else {
        startingPosition = scrollPosition;
      }
    }

    return startingPosition;
  };

  getTopBufferZoneHeight = () => {
    let height = 0;
    const { itemHeight, scrollPosition, segmentLength } = this.state;
    if (scrollPosition && itemHeight) {
      let denominator;
      if (segmentLength > 1) {
        denominator = this.visibleItemsCount * 2;
      } else {
        denominator = 0;
      }
      let rowIndex = scrollPosition - denominator;
      if (rowIndex < 0) {
        rowIndex = 0;
      }
      height = itemHeight * rowIndex;
    }

    return height;
  };

  renderItem = (item, index) => {
    const { renderItem } = this.props;

    return (
      <div className="Item"
        key={this.getItemId(item, index)}  >
        {renderItem(item, index)}
      </div>
    );
  };

  renderItems = (items) => {
    if (!items.length) {
      const { t } = this.props;
      return <p className="NoBannersMsg">{t("BannerSets.noBanners")}</p>;
    }

    const { segmentLength } = this.state;
    let split = [];
    let counter = 0;
    let item = 0;
    const itemCount = items.length;
    let endPosition;
    while (item < itemCount) {
      endPosition = item + segmentLength;
      if (endPosition > itemCount) {
        endPosition = itemCount;
      }
      split[counter] = items.slice(item, endPosition);
      counter++;
      item += segmentLength;
    }
    split = cloneDeep(split);
    const lastRowItemsCount = split[split.length - 1].length;
    if (lastRowItemsCount < segmentLength) { // Not enough elements to render so fill the rest with dummy data
      let item = lastRowItemsCount;
      while (item < segmentLength) {
        split[split.length - 1].push({});
        item++;
      }
    }
    return split.map(this.renderItem);
  };

  updateBannerIndexes = (banners, start) => {
    let item = 0;
    let bannerCount = banners.length;
    while (item < bannerCount) {
      banners[item].index = start + item;
      item++;
    }

    return banners;
  };

  render() {
    const { items } = this.props;
    const startingPosition = this.getStartPosition();
    const endPosition = this.getEndPosition(startingPosition);
    return (
      <div className="BaseVirtualList"
        ref={this.mainListRef} >
        <div className="Inner">
          {this.renderItems(this.updateBannerIndexes(items.slice(startingPosition, endPosition), startingPosition))}
        </div>
      </div>
    );
  }
}

BaseVirtualList.propTypes = {
  getItemId: PropTypes.func,
  items: PropTypes.array.isRequired,
  loadInProgress: PropTypes.bool,
  onComponentReload: PropTypes.object,
  renderItem: PropTypes.func.isRequired,
  segmentLength: PropTypes.number
};

export default withTranslation()(BaseVirtualList);
