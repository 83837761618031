import MoviesViewListAbstract from '../../../MoviesView/MoviesViewListAbstract/MoviesViewListAbstract';
import {getStarMovieDetailsRoute} from "../../../../../../../../../../services/navigation/stars-navigation/stars-navigation-routes.service";
import {showStarMovieDetails} from "../../../../../../../../../../services/navigation/stars-navigation/stars-navigation.service";

class StarMoviesList extends MoviesViewListAbstract {
  constructor(props) {
    super(props);
    this.state.stickyPosition = 0;
  }

  /**
   * @override
   */
  getMovieDetailsRoute = (movieId) => {
    const {starId} = this.props;
    return getStarMovieDetailsRoute(starId, movieId);
  };

  /**
   * @override
   */
  showMovieDetails = (movieId) => {
    const {starId} = this.props;
    showStarMovieDetails(starId, movieId);
  };
}

export default StarMoviesList;
