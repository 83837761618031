import React, {Component} from 'react';
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../../components/BaseFooter/BaseFooter";

import './NatsUsersViewFooter.scss';

class NatsUsersViewFooter extends Component {

  getBaseFooterProps = () => {
    const {currentPage, onChange, pageCount, pageSize, recordCount} = this.props;
    return {
      currentPage,
      onChange,
      pageCount,
      pageSize,
      recordCount
    };
  };

  render() {
    const baseFooterProps = this.getBaseFooterProps();
    return (
      <BaseFooter 
        className="NatsUsersViewFooter"
        {...baseFooterProps}
      >
      </BaseFooter>
    );
  }
}

NatsUsersViewFooter.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  recordCount: PropTypes.number.isRequired
};

export default NatsUsersViewFooter;