import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, List, Table } from 'antd';
import PropTypes from 'prop-types';

import BaseCard from '../../../../../../../../../../components/BaseCard/BaseCard';
import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";

import { getLicensorDetailsRoute, getLicensorMoviesRoute } from '../../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation-routes.service';
import { getScrollHeight, renderListClickableItem } from "../../../../../../../../../../services/util-service/util.service";

import './LicensorsViewBody.scss';

class LicensorsViewBody extends Component {
  tableRowHeight = 65;

  getListCardFooter = (item) => {
    return (
      <div className="ListCardCFooter">
        {this.renderActions(item, item)}
      </div>
    );
  };

  onEditLicensor = (id, name) => {
    const { onEditLicensor } = this.props;
    onEditLicensor(id, name);
  };

  renderActions = (item, { id, name }) => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <LinkButton href={getLicensorDetailsRoute(id)}>
          {t('LicensorsViewBody.viewDetails')}
        </LinkButton>
        <Button className="EditLicensorBtn"
          onClick={this.onEditLicensor.bind(this, id, name)}>
          {t('LicensorsViewBody.edit')}
        </Button>
      </React.Fragment>
    );
  };

  renderManufacturers = item => {
    return item.length;
  };

  renderYesNo = item => {
    const { t } = this.props;
    return item.length ? t("LicensorsViewBody.yes") : t("LicensorsViewBody.no");
  };

  renderMoviesItem = (movies, data) => {
    return renderListClickableItem(data, undefined, `licensors-movies-${data.id}`, getLicensorMoviesRoute, this.getMoviesCountText);
  };

  getMoviesCountText = ({ movies }) => {
    return this.props.t('LicensorsViewBody.moviesCount', { moviesCount: movies });
  };

  columns = [
    {
      dataIndex: 'id',
      title: this.props.t('LicensorsViewBody.id'),
      width: 100
    },
    {
      dataIndex: 'name',
      title: this.props.t('LicensorsViewBody.name')
    },
    {
      dataIndex: 'manufacturers',
      title: this.props.t('LicensorsViewBody.manufacturers'),
      render: this.renderManufacturers,
      width: 200
    },
    {
      dataIndex: 'royalties',
      title: this.props.t('LicensorsViewBody.hasRoyaltyTerms'),
      render: this.renderYesNo,
      width: 200
    },
    {
      dataIndex: 'movies',
      title: this.props.t('LicensorsViewBody.movies'),
      render: this.renderMoviesItem,
      width: 200,
      key: 'movies'
    },
    {
      align: 'center',
      dataIndex: 'actions',
      title: this.props.t('LicensorsViewBody.actions'),
      render: this.renderActions,
      width: 250
    }
  ];

  calculateTableScroll = () => {
    const { contentHeight, licensors } = this.props;
    const reductionHeight = 150;
    return getScrollHeight(contentHeight, licensors, this.tableRowHeight, reductionHeight);
  };

  renderLicensorsList = () => {
    return (
      <List
        dataSource={this.props.licensors}
        renderItem={this.renderLicensorsListItem}
        split={false}
        itemLayout='vertical'
      />
    );
  };

  renderLicensorsListItem = item => {
    const { id, name, manufacturers, movies, royalties } = item;
    const { t } = this.props;
    return (
      <List.Item key={id}>
        <BaseCard
          title={`${t('LicensorsViewBody.licensor', { name })}`}
          footer={this.getListCardFooter(item)}
        >
          <div className='ListCardWrapper'>
            <p>
              <span className='ListItemLabel'>
                {t('LicensorsViewBody.id')}:
              </span>
              {id}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('LicensorsViewBody.name')}:
              </span>
              {name}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('LicensorsViewBody.manufacturers')}:
              </span>
              {this.renderYesNo(manufacturers)}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('LicensorsViewBody.movies')}:
              </span>
              {movies}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('LicensorsViewBody.hasRoyaltyTerms')}:
              </span>
              {this.renderYesNo(royalties)}
            </p>
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  renderLicensorsTable = () => {
    return (
      <Table
        dataSource={this.props.licensors}
        rowKey='id'
        bordered
        columns={this.columns}
        pagination={false}
        scroll={this.calculateTableScroll()}
      />
    );
  };

  render() {
    return (
      <div className='LicensorsViewBody'>
        {this.renderLicensorsTable()}
        {this.renderLicensorsList()}
      </div>
    );
  }
}

LicensorsViewBody.propTypes = {
  contentHeight: PropTypes.number,
  licensors: PropTypes.array.isRequired,
  onEditLicensor: PropTypes.func.isRequired
};

export default withTranslation()(LicensorsViewBody);
