import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Checkbox, InputNumber } from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {
  editProperty,
  editWhitelabelConfig,
  getPropertyById,
  getWhitelabelConfig
} from "../../../../../../../../../services/properties-service/properties.service";

import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";

import './EditPropertyModal.scss';
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

/* istanbul ignore file */

const OPTION_KEYS = {
  stars_page_enabled: 'stars_page_enabled',
  stars_page_exclusive_enabled: 'stars_page_exclusive_enabled',
  max_just_added_page_count: 'max_just_added_page_count',
  preview_max_count: 'preview_max_count',
  preview_time_period: 'preview_time_period',
  enable_benefits: 'enable_benefits',
  downloadable: 'downloadable'
};
class EditPropertyModal extends Component {

  modalRef = React.createRef();

  state = {
    dataLoaded: false,
    stars_page_enabled: 0,
    stars_page_exclusive_enabled: 0,
    max_just_added_page_count: 10,
    preview_max_count: 1,
    preview_time_period: 24, // user can preview after this time expires, default 24h
    enable_benefits: 0,
    downloadable: 0
  };

  componentDidMount() {
    this.fetchConfig();
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  fetchConfig = () => {
    const { t, applyToAll } = this.props;
    const message = t('EditPropertyModal.loadingPleaseWait');
    this.getModal().setLoading(message);
    const { propertyId } = this.props;
    const promises = [getWhitelabelConfig(propertyId)];

    if (!applyToAll) {
      promises.push(getPropertyById(propertyId));
    }

    Promise.all(promises)
      .then(this.loadData)
      .catch(this.onRequestFailure);
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='EditPropertyModal-actions'>
        <Button onClick={this.onCancel} key='cancelBtn'>
          {t('EditPropertyModal.cancelBtn')}
        </Button>
        <Button onClick={this.saveChanges}
          key='saveChangesBtn'>
          {t('EditPropertyModal.saveChanges')}
        </Button>
      </div>);
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    const { t, propertyName } = this.props;
    const name = propertyName ? ` - ${propertyName}` : '';
    return `${t('EditPropertyModal.title')}${name}`;
  };

  loadData = (response) => {
    const {
      stars_page_enabled,
      stars_page_exclusive_enabled,
      max_just_added_page_count,
      preview_max_count = 1,
      preview_time_period = 24,
      enable_benefits
    } = response[0].data.data || {};
    const { downloadable = 0 } = response[1]?.data?.data || {};

    this.setState({
      dataLoaded: true,
      stars_page_enabled,
      stars_page_exclusive_enabled,
      max_just_added_page_count,
      enable_benefits,
      preview_max_count,
      preview_time_period,
      downloadable
    });
    this.getModal().clearLoading();
  };

  onCancel = () => {
    this.closeModal();
  };

  previewTimPeriodMsg = () => {
    displayErrorNotification({
      duration: 6,
      message: <span><b>24</b> is max value for Max previews time period!</span>
    });
  };

  onInputChange = (key, value) => {
    if (isNaN(value)) return;

    if (key === OPTION_KEYS.preview_time_period && value > 24) {
      this.previewTimPeriodMsg();
    }

    this.setState(prevState => {
      const state = prevState[key] = parseInt(value, 10);
      return state;
    });
  };

  onRequestFailure = (error) => {
    LogController.logError(error);
    this.getModal().clearLoading();
  };

  onSaveChangesFailure = (error) => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditPropertyModal.propertyEditFailure')
    });
    this.onRequestFailure(error);
  };

  onSaveChangesSuccess = () => {
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditPropertyModal.propertyEditSuccess')
    });
    this.closeModal();
  };

  renderCheckbox = (label, key, applyToAll) => {
    const checked = !applyToAll ? this.state[key] : false;
    const child = (
      <Checkbox checked={checked} onChange={this.onCheckboxChange.bind(this, key)} />
    );
    return this.renderField(label, child);
  }

  renderFields = () => {
    const { t, applyToAll } = this.props;
    return (
      <React.Fragment>
        {this.renderCheckbox(t('EditPropertyModal.starsPageEnabled'), OPTION_KEYS.stars_page_enabled)}
        {this.renderCheckbox(t('EditPropertyModal.exclusiveStarsEnabled'), OPTION_KEYS.stars_page_exclusive_enabled)}
        {this.renderInputNumberField(t('EditPropertyModal.maxJustAddedPageCount'), OPTION_KEYS.max_just_added_page_count)}
        {this.renderCheckbox(t('EditPropertyModal.enableBenefits'), OPTION_KEYS.enable_benefits)}
        {!applyToAll && this.renderCheckbox(t('global.downloadable'), OPTION_KEYS.downloadable)}
        {!applyToAll && this.renderInputNumberField(t('EditPropertyModal.previewMaxCount'), OPTION_KEYS.preview_max_count)}
        {!applyToAll && this.renderInputNumberField(t('EditPropertyModal.previewMaxTime'), OPTION_KEYS.preview_time_period)}
      </React.Fragment>
    );
  };

  renderInputNumberField = (label, key) => {
    const value = this.state[key];
    const child = (
      <InputNumber value={value} name={key} onChange={this.onInputChange.bind(this, key)} />
    );
    return this.renderField(label, child);
  };

  renderField = (label, children) => {
    return (
      <div className='ContentRow'>
        <span>{label}:</span>
        {children}
      </div>
    );
  };

  saveChanges = () => {
    const {
      stars_page_enabled,
      stars_page_exclusive_enabled,
      max_just_added_page_count,
      enable_benefits,
      preview_max_count,
      preview_time_period,
      downloadable
    } = this.state;

    if (preview_time_period > 24) {
      this.previewTimPeriodMsg();
      return;
    }

    const message = this.props.t('EditPropertyModal.saveChangesMsg');
    this.getModal().setLoading(message);
    const { propertyId, applyToAll } = this.props;

    const config = {
      stars_page_enabled,
      stars_page_exclusive_enabled,
      max_just_added_page_count,
      preview_max_count,
      preview_time_period,
      enable_benefits
    };

    const applyToPropertyId = applyToAll || propertyId;
    const promises = [editWhitelabelConfig(applyToPropertyId, config)];

    if (!applyToAll) {
      promises.push(editProperty(propertyId, { downloadable }));
    }

    Promise.all(promises)
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  onCheckboxChange = (key, event) => {
    const { checked } = event.target;
    this.setState(prevState => {
      const state = prevState[key] = checked ? 1 : 0;
      return state;
    });
  };

  render() {
    const { dataLoaded } = this.state;
    return (
      <ModalDialog className="EditPropertyModal"
        forwardedRef={this.modalRef}
        title={this.getTitle()}
        actions={this.getActions()}>
        <div className="EditPropertyModal-inner">
          {dataLoaded ? this.renderFields() : null}
        </div>
      </ModalDialog>
    );
  }
}

EditPropertyModal.propTypes = {
  propertyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  propertyName: PropTypes.string,
  applyToAll: PropTypes.string
};

export default withTranslation()(EditPropertyModal);
