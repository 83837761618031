import {ROUTES} from "../../../routes";
import {MAIN_DASHBOARD_VIEW_ROUTES} from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";

/**
 * Returns licensors view route
 *
 * @returns {string}
 */
export const getLicensorsRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.LICENSORS}`;
};

export const getLicensorDetailsRootRoute = (licensorId) => {
  return `${getLicensorsRoute()}/${licensorId}`;
};

/**
 * Returns licensor details navigation route
 *
 * @param {number|string} licensorId
 *
 * @returns {string}
 */
export const getLicensorDetailsRoute = (licensorId) => {
  return `${getLicensorDetailsRootRoute(licensorId)}/details`;
};

/**
 * Returns licensor movies route
 *
 * @param {string|number} licensorId
 *
 * @returns {string}
 */
export const getLicensorMoviesRoute = licensorId => {
  return `${getLicensorDetailsRootRoute(licensorId)}/movies`;
};

/**
 * Returns licensor movie details route
 *
 * @param {number|string} licensorId
 * @param {number|string} movieId
 *
 * @returns {string}
 */
export const getLicensorMovieDetailsRoute = (licensorId, movieId) => {
  return `${getLicensorMoviesRoute(licensorId)}/${movieId}`;
};