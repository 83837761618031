import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import {InvalidReasons} from "../AdConfigurationBaseAbstract";

import './InvalidReasonDisplay.scss';

/* istanbul ignore file */
const InvalidReasonDisplay = props => {
  let view = null;
  const {invalidReason} = props;
  const {t} = useTranslation();
  let key;
  if (invalidReason) {
    switch (invalidReason) {
      case InvalidReasons.InvalidAdConfig:
        key =  'invalidAdConfigMessage';
        break;
      case InvalidReasons.InvalidDate:
        key = 'invalidAdDate';
        break;
      case InvalidReasons.InvalidEndDate:
        key = 'invalidAdEndDate';
        break;
      case InvalidReasons.InvalidStartDate:
        key = 'invalidAdStartDate';
        break;
      default: 
        break;
    }
    view = (
      <div className="InvalidReasonDisplay">
        <div className="Title">
          {t(`ConfigureAdsOnPropertyLevel.AdsConfigurationModal.${key}.title`)}
        </div>
        <div className="Explanation">
          {t(`ConfigureAdsOnPropertyLevel.AdsConfigurationModal.${key}.explanation`)}
        </div>
      </div>
    );
  }
  return view;
};

InvalidReasonDisplay.propTypes = {
  invalidReason: PropTypes.string
};

export default InvalidReasonDisplay;
