import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Icon, List, Menu, Select, Table } from "antd";
import PropTypes from "prop-types";

import BaseCard from "../../../../../../../../../components/BaseCard/BaseCard";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";

import { getScrollHeight } from "../../../../../../../../../services/util-service/util.service";

import './CategoriesMainView.scss';
import BaseFooter from "../../../../../../../../../components/BaseFooter/BaseFooter";
import ThemesViewHeader from './ThemesViewHeader/ThemesViewHeader';
import { ModalController } from '../../../../../../../../../controllers/modal-controller/modal.controller';
import EditThemeModal from './EditThemeModal/EditThemeModal';
import { setThemeEnabledStatus } from '../../../../../../../../../services/tag-service/tag-service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../services/notification-service/notification.service';

class CategoriesMainView extends Component {
  state = {
    appliedFilters: [],
    loadingMsg: ''
  }

  sort_maps = {
    name: 'a-z',
    elastic_score: 'elastic_score',
    id: 'id',
    type: 'id',
    enabled: 'enabled'
  };

  constructor(props) {
    super(props);
    this.state.loadingMsg = props.t('CategoriesMainView.loadingMsg');
  }

  setLoading = (loadingMsg) => {
    this.props.setLoading(!!loadingMsg.length);
    this.setState({
      loadingMsg
    });
  };

  setAppliedFilters = (filters) => {
    this.setState({
      appliedFilters: filters
    });
  }

  renderDisplayFilterItem = item => {
    const { t } = this.props;
    return (
      <Select.Option key={item} value={item}>
        <div>{t(`CategoriesMainView.${item}`)}</div>
      </Select.Option>
    );
  };

  toggleEnabledStatus = (id, value) => {
    setThemeEnabledStatus(id, value === 'Enabled')
      .then(this.toggleEnabledStatusSuccess)
      .catch(this.toggleEnabledStatusFailure)
  };

  toggleEnabledStatusSuccess = () => {
    displaySuccessNotification({
      duration: 3,
      message: this.props.t("EditThemeModal.editThemeSuccess"),
    });
    this.refreshCurrentPage();
  };

  toggleEnabledStatusFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t("EditThemeModal.editThemeFailure"),
    });
  };

  renderActions = (item, tag) => this.renderActionButtons(tag);

  getEnableDisableDropdownMenu = tagId => {
    const { t } = this.props;
    return (
      <Menu className="ThemeCard-enabledDisabled">
        {['Enabled', 'Disabled'].map(value => (
          <Menu.Item
            key={value}
          >
            <div
              onClick={(event) => this.toggleEnabledStatus(tagId, value)}
              className={`ThemeCard-${value}`}>
              {t(`CategoriesMainView.${value}`)}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  renderType = (type, tag) => {
    return this.props.t(`CategoriesMainView.${type}`);
  };

  renderEnabledComboBox = (status, tag) => {
    const isEnabled = status === 1;
    return (
      <div className="StatusItem">
        <Dropdown overlay={this.getEnableDisableDropdownMenu(tag.id)}>
          <Button className={isEnabled ? 'ThemeEnabled' : 'ThemeDisabled'}>
            {isEnabled ? 'Enabled' : 'Disabled'}
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
    );
  };

  renderOrientation(orientation, value, tag) {
    let customizedGayName = tag.customized_names.filter(custom => custom.orientation.name === orientation);
    if (customizedGayName.length === 0) {
      return 'N/A';
    }
    customizedGayName = customizedGayName[0]?.custom_name;
    return customizedGayName;
  };

  columns = [{
    sorter: true,
    align: 'center',
    dataIndex: 'id',
    title: this.props.t('CategoriesMainView.id'),
    width: 70
  }, {
    sorter: true,
    dataIndex: 'name',
    title: this.props.t('CategoriesMainView.name'),
    width: 250
  }, {
    sorter: true,
    align: 'center',
    dataIndex: 'enabled',
    title: this.props.t('CategoriesMainView.enabled'),
    render: this.renderEnabledComboBox,
    width: 150
  }, {
    sorter: true,
    dataIndex: 'type',
    align: 'center',
    title: this.props.t('CategoriesMainView.categorySexAct'),
    render: this.renderType,
    width: 200
  }, {
    align: 'center',
    dataIndex: 'name_gay',
    title: this.props.t('CategoriesMainView.gay'),
    render: this.renderOrientation.bind(this, 'Gay'),
    width: 250
  }, {
    align: 'center',
    dataIndex: 'name_straight',
    title: this.props.t('CategoriesMainView.straight'),
    render: this.renderOrientation.bind(this, 'Straight'),
    width: 250
  }, {
    align: 'center',
    dataIndex: 'actions',
    render: this.renderActions,
    title: this.props.t('CategoriesMainView.actions'),
    width: 200
  }];

  tableRowHeight = 65;

  getCategoryTag = (category) => {
    return category;
  };

  getScrollHeight = () => {
    const { contentHeight, listInfo } = this.props;
    const reductionHeight = 54 + 52;
    return getScrollHeight(contentHeight, listInfo.data, this.tableRowHeight, reductionHeight);
  };

  getTableData = () => {
    return this.props.listInfo.data.map(this.getCategoryTag);
  };

  getFilters = (criteria) => {
    const filters = [];
    let item;
    for (let key in criteria) {
      item = criteria[key];
      switch (key) {
        case 'search':
        case 'sort_order':
        case 'sort_by':
        case 'type':
        case 'enabled':
          if (item) {
            filters.push(`${key}=${item}`);
          }
          break;
        default:
          break;
      }
    }
    return filters;
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { filterBasedOnCriteria } = this.props;
    const { appliedFilters } = this.state;
    let { field, order } = sorter;
    if (!field) {
      field = this.sort_maps.elastic_score;
    }
    let sort_by = this.sort_maps[field];
    if (field) {
      const sortOrder = order ? {
        sort_order: order.replace('end', '')
      } : {};
      let appliedFiltersObj = {};
      if (Array.isArray(appliedFilters)) {
        appliedFiltersObj = appliedFilters.reduce((obj, val) => {
          const [key, value] = val.split('=');
          obj[key] = value;
          return obj;
        }, {});
      }
      let criteria = {
        ...appliedFiltersObj,
        sort_by,
        ...sortOrder
      };
      let filters = this.getFilters(criteria);
      filterBasedOnCriteria(filters);
      this.setAppliedFilters(filters);
    }
  };

  renderBody = () => {
    const data = this.getTableData();
    return (
      <React.Fragment>
        <List dataSource={data}
          renderItem={this.renderListItem} />
        <Table columns={this.columns}
          onChange={this.handleTableChange}
          rowKey="id"
          bordered
          pagination={false}
          dataSource={data}
          scroll={this.getScrollHeight()} />
      </React.Fragment>
    );
  };

  renderFooter = () => {
    const { currentPage, pageCount, perPage, total } = this.props.listInfo;
    return (
      <BaseFooter currentPage={currentPage}
        appliedFilters={this.state.appliedFilters}
        onChange={this.props.onPageChange}
        pageCount={pageCount}
        pageSize={perPage}
        recordCount={total} />
    );
  };

  renderListItem = (tag) => {
    return (
      <BaseCard title={this.renderListItemCardTitle(tag)} />
    );
  };

  renderListItemCardTitle = (tag) => {
    const { name } = tag;
    return (
      <div className="CategoriesMainView-categoryTitle">
        {name}
        {this.renderActionButtons(tag)}
      </div>
    );
  };

  refreshCurrentPage = (data) => {
    const { currentPage } = this.props.listInfo;
    this.props.onPageChange(currentPage, this.state.appliedFilters);
  };

  renderActionButtons = (tag) => {
    const { t } = this.props;
    return (
      <div className="Actions-Container">
        {/* <LinkButton href={getThemeDetailsRoute(tag.id)}>
          {t('CategoriesMainView.viewDetails')}
        </LinkButton> */}
        <Button onClick={() => {
          const modal = (
            <EditThemeModal
              tag={tag}
              onThemeEdit={this.refreshCurrentPage}
            />
          );
          ModalController.showModal(modal);
        }}>
          {t('CategoriesMainView.changeThemeName')}
        </Button>
      </div>
    );
  };

  renderHeader = () => {
    const { onPageChange, listInfo } = this.props;
    const { total } = listInfo;
    return (
      <ThemesViewHeader
        appliedFilters={this.state.appliedFilters}
        setLoading={this.setLoading}
        onPageChange={onPageChange}
        recordCount={total}
        setActiveFilters={this.setAppliedFilters}
      />
    );
  };

  render() {
    const { listInfo } = this.props;
    const { dataLoaded, loading } = listInfo;
    return (
      <LoadingWrapper className="CategoriesMainView"
        dataLoaded={dataLoaded}
        isLoading={loading}
        loadingMsg={this.state.loadingMsg}>
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
      </LoadingWrapper>
    );
  }
}

CategoriesMainView.propTypes = {
  setLoading: PropTypes.func.isRequired,
  listInfo: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  filterBasedOnCriteria: PropTypes.func.isRequired
};

export default withTranslation()(CategoriesMainView);

