import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Router } from "@reach/router";
import PropTypes from 'prop-types';

import BannerDetails from "../BannerDetails/BannerDetails";
import BannerSets from "../BannerSets/BannerSets";
import BannersViewBody from './BannersViewBody/BannersViewBody';
import BannersViewHeader from './BannersViewHeader/BannersViewHeader';
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';

import './BannersMainView.scss';

class BannersMainView extends Component {

  renderBody = () => {
    const { contentHeight, listInfo, onBannerEdit, onPageChange, onDeleteBanner, showCheckBox, bannersToDelete, showSets, onToggleBannerSets } = this.props;
    const newContentHeight = contentHeight ? contentHeight - 40 : contentHeight;
    return (
      <Router>
        <BannersViewBody bannersData={listInfo.bannersData}
          path={'/list/*'}
          showCheckBox={showCheckBox}
          bannersToDelete={bannersToDelete}
          listInfo={listInfo}
          onBannerEdit={onBannerEdit}
          onPageChange={onPageChange}
          onBannerSetChanged={this.props.onBannerSetChanged}
          onDeleteBanner={onDeleteBanner}
          setLoading={this.props.setLoading} />
        <BannerDetails path={`/list/details/:bannerId`} onBannerEdit={onBannerEdit} />
        <BannerSets
          showSets={showSets}
          onToggleBannerSets={onToggleBannerSets}
          path={`/sets/*`}
          contentHeight={newContentHeight} />
      </Router>
    );
  };

  renderHeader = () => {
    return (
      <BannersViewHeader
        deleteMultipleBanners={this.props.deleteMultipleBanners}
        bannersToDelete={this.props.bannersToDelete}
        bannersTotal={this.props.listInfo.recordCount}
        defaultDisplayFilter={this.props.defaultDisplayFilter}
        filterByStatus={this.props.filterByStatus}
        generalLinks={this.props.generalLinks}
        onBannerAdd={this.props.onBannerAdd}
        publishUnpublishedBanners={this.props.publishUnpublishedBanners}
        showUploadBannersModal={this.props.showUploadBannersModal}
        showNewBannerSetModal={this.props.showNewBannerSetModal}
        onToggleBannerSets={this.props.onToggleBannerSets}
      />
    );
  };

  render() {
    const { listInfo } = this.props;
    const { bannersLoaded, loadingMessage } = listInfo;
    return (
      <LoadingWrapper
        className='BannersMainViewWrapper'
        dataLoaded={bannersLoaded}
        isLoading={!!loadingMessage}
        loadingMsg={loadingMessage}>
        {this.renderHeader()}
        {this.renderBody()}
      </LoadingWrapper>
    );
  }
}

BannersMainView.propTypes = {
  defaultDisplayFilter: PropTypes.string.isRequired,
  filterByStatus: PropTypes.func.isRequired,
  generalLinks: PropTypes.array.isRequired,
  listInfo: PropTypes.object.isRequired,
  onBannerAdd: PropTypes.func.isRequired,
  onBannerEdit: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  publishUnpublishedBanners: PropTypes.func.isRequired,
  showNewBannerSetModal: PropTypes.func.isRequired,
  showCheckBox: PropTypes.func.isRequired,
  deleteMultipleBanners: PropTypes.func.isRequired,
  bannersToDelete: PropTypes.arrayOf(PropTypes.number)
};

export default withTranslation()(BannersMainView);
