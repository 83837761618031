import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Icon, Table, Tag, Checkbox } from "antd";
import PropTypes from 'prop-types';

import BaseCard from "../../../../../../../../../../../components/BaseCard/BaseCard";
import BaseDropdown from "../../../../../../../../../../../components/BaseDropdown/BaseDropdown";

import {
  getCoverImageUrl,
  uploadSceneImage
} from "../../../../../../../../../../../services/images-service/images.service";
import { deleteSceneImage } from "../../../../../../../../../../../services/images-service/images.service";
import {
  renderListItems,
  renderListClickableItems,
  getOnPropertiesData
} from "../../../../../../../../../../../services/util-service/util.service";
import { getSexActMoviesRoute } from "../../../../../../../../../../../services/navigation/sexact-navigation/sexact-navigation-routes.service";
import { showSexActMovies } from "../../../../../../../../../../../services/navigation/sexact-navigation/sexact-navigation.service";
import { getStarDetailsRoute } from "../../../../../../../../../../../services/navigation/stars-navigation/stars-navigation-routes.service";
import { showStarDetails } from "../../../../../../../../../../../services/navigation/stars-navigation/stars-navigation.service";

import { MovieDetailsCommController, MovieDetailsEventMap } from "../../movie-details-comm.controller";
import { ModalController } from "../../../../../../../../../../../controllers/modal-controller/modal.controller";

import DeleteImagesModal from "../../../../../../../../../../../components/modal/DeleteImagesModal/DeleteImagesModal";
import EditMovieSceneModal from "./EditMovieSceneModal/EditMovieSceneModal";
import SceneImage from "../../../../../../../../../../../components/SceneImage/SceneImage";
import UploadMovieSceneImagesModal from "./UploadMovieSceneImagesModal/UploadMovieSceneImagesModal";
import { showPropertyDetails } from '../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation.service';
import { getPropertyDetailsRoute } from '../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service';
import './MovieScene.scss';

/* istanbul ignore file */
class MovieScene extends Component {

  renderValueColumn = (item, data) => {
    let view;
    switch (data.key) {
      case 'sexActs':
        view = renderListClickableItems(this.mapValueForRender(item, 'sexActs'), showSexActMovies, data.key, getSexActMoviesRoute);
        break;
      case 'positions':
        view = renderListItems(this.mapValueForRender(item, 'positions'), data.key);
        break;
      case 'cast':
        view = renderListClickableItems(item, showStarDetails, data.key, getStarDetailsRoute);
        break;
      case 'length':
        view = this.renderLength(item);
        break;
      default:
        view = item;
        break;
    }
    return view;
  };

  columns = [{
    dataIndex: 'name',
    key: 'name'
  }, {
    dataIndex: 'value',
    key: 'value',
    render: this.renderValueColumn
  }];

  convertDurationToTime = (duration) => {
    const { hours, minutes, seconds } = this.getTime(duration);
    let render = this.formatTimeValue(seconds);
    render = `${this.formatTimeValue(minutes)}:${render}`;
    render = `${this.formatTimeValue(hours)}:${render}`;

    return render;
  };

  openEditSceneModal = () => {
    const { scenesAreEpisodes, data = {}, viewingMovieId, properties } = this.props;
    const {
      enabled,
      endTimeSeconds,
      id,
      index,
      movie,
      set_as_episode_of_movies,
      startTimeSeconds,
      videos,
      scenes_on_properties_data
    } = data;

    const {
      publishStart,
      publishEnd,
      downloadableOnProperties
    } = getOnPropertiesData(scenes_on_properties_data, properties);

    const modal = (
      <EditMovieSceneModal onSceneEditSuccess={this.onSceneEditSuccess}
        startTime={this.convertDurationToTime(startTimeSeconds)}
        enabled={enabled}
        downloadableOnProperties={downloadableOnProperties}
        endTime={this.convertDurationToTime(endTimeSeconds)}
        fullMovieData={set_as_episode_of_movies}
        publishStart={publishStart}
        publishEnd={publishEnd}
        sceneId={id}
        sceneIndex={index}
        videos={videos}
        movieId={+viewingMovieId || movie.id}
        scenesAreEpisodes={scenesAreEpisodes}
        viewingMovieId={viewingMovieId}
        properties={properties}
      />
    );
    ModalController.showModal(modal);
  };

  deleteSceneImages = () => {
    const { images } = this.props.data;
    const modal = (
      <DeleteImagesModal
        images={images}
        deleteImage={this.deleteImage}
        onDeleteSuccess={this.onDeleteFinished}
      />
    );
    ModalController.showModal(modal);
  };

  deleteImage = imageId => {
    const { id: sceneId } = this.props.data;
    return deleteSceneImage(sceneId, imageId);
  };

  formatTimeValue = (value) => {
    let format = '00';
    if (value) {
      format = value < 10 ? `0${value}` : value;
    }

    return format;
  };

  getDropdownItems = () => {
    const { t, scenesAreEpisodes } = this.props;
    const name = scenesAreEpisodes ? 'Episode' : 'Scene';
    return [
      <div className="MovieScene-sync"
        onClick={this.onSceneSync.bind(this)}>
        <Icon type="sync" />
        {t(`MovieScene.sync${name}`)}
      </div>,
      <div className="MovieScene-viewContentInfo"
        onClick={this.uploadImages.bind(this)}>
        <Icon type="camera" />
        {t(`MovieScene.upload${name}Image`)}
      </div>,
      <div className="MovieScene-viewContentInfo"
        onClick={this.onViewContentInfo.bind(this)}>
        <Icon type="book" />
        {t(`MovieScene.view${name}ContentInfo`)}
      </div>,

      <div className="MovieScene-sceneDetails"
        onClick={this.onDisplaySceneDetails.bind(this)}>
        <Icon type="info-circle" />
        {t(`MovieScene.display${name}Details`)}
      </div>,
      <div className="MovieScene-editScene"
        onClick={this.deleteSceneImages.bind(this)}>
        <Icon type="delete" />
        {t(`MovieScene.delete${name}Images`)}
      </div>,
      <div className="MovieScene-editScene"
        onClick={this.openEditSceneModal.bind(this)}>
        <Icon type="edit" />
        {t(`MovieScene.edit${name}`)}
      </div>
    ];
  };

  getLengthTimeData = () => {
    const startTime = +this.props.data.startTimeSeconds;
    const endTime = +this.props.data.endTimeSeconds;
    const duration = endTime - startTime;
    return this.getTime(duration);
  };

  getTableData = () => {
    const { t, scenesAreEpisodes, properties } = this.props;
    const name = scenesAreEpisodes ? 'episode' : 'scene';
    const { id: sceneId, sexAct, position, stars, scenes_on_properties_data } = this.props.data;
    const { downloadableOnProperties } = getOnPropertiesData(scenes_on_properties_data, properties);

    return [{
      name: t(`MovieScene.${name}Id`),
      value: sceneId,
      key: 'sceneId'
    }, {
      name: t('MovieScene.length'),
      value: undefined,
      key: 'length'
    }, {
      name: t('MovieScene.sexActs'),
      value: sexAct,
      key: 'sexActs'
    }, {
      name: t('MovieScene.positions'),
      value: position,
      key: 'positions'
    }, {
      name: t('MovieScene.cast'),
      value: stars,
      key: 'cast'
    }, {
      name: t('global.downloadable_on_properties'),
      value: renderListClickableItems(downloadableOnProperties, showPropertyDetails, 'downloadableOnProperties', getPropertyDetailsRoute),
      key: 'downloadableOnProperties'
    }];
  };

  renderCheckbox = (value) => <Checkbox checked={!!value} disabled={true} />;

  getTime = (duration) => {
    const hours = Math.floor(duration / (60 * 60));
    const minutes = Math.floor((duration - (hours * 60 * 60)) / 60);
    const seconds = duration - (hours * 60 * 60) - minutes * 60;
    return {
      hours,
      minutes,
      seconds
    };
  };

  renderSceneActions = () => {
    const { t, scenesAreEpisodes } = this.props;
    const name = scenesAreEpisodes ? 'Episode' : 'Scene';
    return (
      <div className="MovieScene-actions">
        <Button onClick={this.onSceneSync.bind(this)}>
          <Icon type="sync" />
          {t(`MovieScene.sync${name}`)}
        </Button>
        <Button onClick={this.uploadImages.bind(this)}>
          <Icon type="camera" />
          {t(`MovieScene.upload${name}Image`)}
        </Button>
        <Button onClick={this.onViewContentInfo.bind(this)}>
          <Icon type="book" />
          {t(`MovieScene.view${name}ContentInfo`)}
        </Button>
        <Button onClick={this.onDisplaySceneDetails.bind(this)}>
          <Icon type="info-circle" />
          {t(`MovieScene.display${name}Details`)}
        </Button>
        <Button onClick={this.deleteSceneImages.bind(this)}>
          <Icon type="delete" />
          {t(`MovieScene.delete${name}Images`)}
        </Button>
        <Button onClick={this.openEditSceneModal.bind(this)}>
          <Icon type="edit" />
          {t(`MovieScene.edit${name}`)}
        </Button>
      </div>
    );
  };

  mapValueForRender = (positions, key) => {
    return positions.map(this.valueRenderMap.bind(this, key));
  };

  onDisplaySceneDetails = () => {
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.detailsEvent, {
      sceneId: this.props.data.id
    });
  };

  onSceneEditSuccess = () => {
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.editEvent);
  };

  onSceneSync = () => {
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.syncEvent, {
      sceneId: this.props.data.id,
      sceneIndex: this.props.index
    });
  };

  onUploadFinished = () => {
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.sceneImageUploadFinishedEvent);
  };

  onDeleteFinished = () => {
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.sceneImagesDeleteFinishedEvent);
  };

  onUploadImages = (file, type) => {
    const { id } = this.props.data;
    return uploadSceneImage(id, type, file);
  };

  onViewContentInfo = () => {
    MovieDetailsCommController.fireEvent('scene', MovieDetailsEventMap.scene.viewContentInfoEvent, {
      sceneId: this.props.data.id
    });
  };

  renderLength = () => {
    const { t } = this.props;
    const { hours, minutes, seconds } = this.getLengthTimeData();
    let render = `${t('MovieScene.seconds', { seconds })}`;
    if (minutes) {
      render = `${t('MovieScene.minutes', { minutes })} ${render}`;
    }
    if (hours) {
      render = `${t('MovieScene.hours', { hours })} ${render}`;
    }
    const { endTimeSeconds, startTimeSeconds } = this.props.data;
    render = `${render} (${this.convertDurationToTime(startTimeSeconds)} - ${this.convertDurationToTime(endTimeSeconds)})`;
    return render;
  };

  renderActionsDropdown = () => {
    return (
      <BaseDropdown className="MovieScene-dropdown"
        menuClassName="MovieScene-dropdown-actions"
        items={this.getDropdownItems()}>
        <Icon type="more" />
      </BaseDropdown>
    );
  };

  renderCardTitle = () => {
    const { index, sceneCount, t, scenesAreEpisodes } = this.props;
    const name = scenesAreEpisodes ? 'episode' : 'scene';
    return (
      <div className="MovieSceneCardTitle">
        <Tag>{t(`MovieScene.${name}Title`, { sceneIndex: index, sceneCount: sceneCount })}</Tag>
        {this.renderSceneActions()}
        {this.renderActionsDropdown()}
      </div>
    );
  };

  renderSceneImage = (imageUrl) => {
    const { t } = this.props;
    let view;
    if (imageUrl) {
      view = (
        <SceneImage url={imageUrl} />
      )
    } else {
      view = (
        <div className="NoImage">
          <p>{t('MovieScene.ImageNotFound')}</p>
        </div>
      )
    }

    return view;
  };

  uploadImages = () => {
    const modal = (
      <UploadMovieSceneImagesModal uploadImages={this.onUploadImages}
        onUploadFinished={this.onUploadFinished} />
    );
    ModalController.showModal(modal);
  };

  valueRenderMap = (key, { tag }) => {
    const { t } = this.props;
    const data = {};
    if (tag) {
      const localeKey = `global.${key}.${tag.name}`;
      let name = t(localeKey);
      if (name === localeKey) {
        name = tag.name;
      }
      Object.assign(data, {
        id: tag.id,
        name: name
      });
    }
    return data;
  };

  render() {
    const { images } = this.props.data;
    return (
      <BaseCard className="MovieScene" title={this.renderCardTitle()}>
        {this.renderSceneImage(getCoverImageUrl(images))}
        <Table dataSource={this.getTableData()}
          columns={this.columns}
          showHeader={false}
          pagination={false}
          bordered
          size='small' />
      </BaseCard>
    );
  }
}

MovieScene.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  sceneCount: PropTypes.number.isRequired,
  viewingMovieId: PropTypes.string,
  properties: PropTypes.array
};

export default withTranslation()(MovieScene);
