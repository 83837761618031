import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Checkbox, Table} from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../../services/notification-service/notification.service";
import './EditSpecialCategoriesModal.scss';
import { getAssociatedControlSwitchers } from '../../../../../../../../../../services/control-switchers-service/control-switchers.service';
import SpecialCategoriesSelect from './SpecialCategoriesSelect/SpecialCategoriesSelect';
import { setPropertyControlSwitchers } from '../../../../../../../../../../services/properties-service/properties.service';

class EditSpecialCategoriesModal extends Component {

  modalRef = React.createRef();

  state = {
    specialCategories: [],
    selectedSpecialCategories: [],
    dataLoaded: false
  };

  componentDidMount() {
    this.fetchData();
  };

  fetchData = () => {
    const {t} = this.props;
    const message = t('EditSpecialCategoriesModal.loadingMsg');
    this.setLoading(message);
    const promises = [this.fetchSpecialCategories()];
    Promise.all(promises)
      .then(this.onLoadData)
      .catch(this.onLoadError);
  };

  fetchSpecialCategories = () => {
		const { propertyId } = this.props;
		return getAssociatedControlSwitchers({properties_id: propertyId});
  }

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="EditSpecialCategoriesModal-actions">
        <Button onClick={this.onCancel.bind(this)}
                key="cancelBtn">
          {t('EditSpecialCategoriesModal.cancel')}
        </Button>
        <Button
          key="saveChangesBtn"
          onClick={this.saveChanges.bind(this)}>
          {t('EditSpecialCategoriesModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getIndex = (specialCategoryId, item) => {
    return item.id === specialCategoryId;
  };

  getTitle = () => {
    const {t, propertyName} = this.props;
    return t('EditSpecialCategoriesModal.title', {propertyName});
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onLoadData = (response) => {
    let specialCategories = response[0].data.data;
    specialCategories = this.prependCategoryName(specialCategories, "Exclude");

    this.setState({
      dataLoaded: true,
			specialCategories: specialCategories,
      selectedSpecialCategories: specialCategories.filter(item => item.properties_id).map(item => item.id)
    });
    this.getModal().clearLoading();
  };

  onLoadError = () => {
    this.setLoading('');
    const {t} = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('EditSpecialCategoriesModal.fetchDataFailureMsg')
    });
  };

  onSaveChangesFailure = () => {
    const modal = this.getModal();
    modal.clearLoading();
    modal.closeModal();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditSpecialCategoriesModal.onSaveChangesFailure')
    });
  };

  onSaveChangesSuccess = () => {
    const modal = this.getModal();
    modal.clearLoading();
    modal.closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditSpecialCategoriesModal.onSaveChangesSuccess')
    });
  };

  onSelect = (activeItemsIds) => {
    this.setState(prevState => {
      if (prevState.selectedSpecialCategories !== activeItemsIds) {
        return {selectedSpecialCategories: activeItemsIds}
      }
    })
  };

  renderSpecialCategories = () => {
    const {specialCategories, selectedSpecialCategories} = this.state;
    return (
      <SpecialCategoriesSelect
        data={specialCategories}
        checked={selectedSpecialCategories}
        onItemSelect={this.onSelect}
        showSearch={true}
      />
    )
  }

  renderCheckbox = (text, record) => {
    let value = text;
    if (record.checkbox) {
      value = (
        <Checkbox disabled defaultChecked={true}/>
      );
    }

    return value;
  }

  renderDetailsTable = item => {
    const {t} = this.props;
    const columns = [{
      dataIndex: 'name'
    }, {
      dataIndex: 'value',
      render: this.renderCheckbox
    }];
    const dataSource = [{
      name: t('EditSpecialCategoriesModal.name'),
      value: item.name
    }, {
      name: t('EditSpecialCategoriesModal.enabled'),
      value: item.enabled,
      checkbox: true
    }];
    return (
      <Table columns={columns}
             dataSource={dataSource}
             bordered
             rowKey="name"
             pagination={false}
             showHeader={false}/>
    );
  };

  saveSpecialCategories = () => {
    let promise = Promise.resolve();
    const { propertyId } = this.props;
    promise = setPropertyControlSwitchers(propertyId, this.state.selectedSpecialCategories)
    return promise;
  };

  prependCategoryName = (categories, tag) => {
    return categories.map(item => {
      item.name = `${tag}: ${item.name}`;
      return item;
    })
  }

  saveChanges = () => {
    const loadingMsg = this.props.t('EditSpecialCategoriesModal.savingMsg');
    this.setLoading(loadingMsg);

    Promise.all([this.saveSpecialCategories()])
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  setLoading = loadingMsg => {
    this.getModal().setLoading(loadingMsg);
  };

  sortByName = ({name}, {name: name1}) => {
    let order = 0;
    if (name > name1) {
      order = 1;
    } else if (name < name1) {
      order = -1;
    }

    return order;
  };

  render() {
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className="EditSpecialCategoriesModal">
					{this.renderSpecialCategories()}
        </div>
      </ModalDialog>
    );
  }
}

EditSpecialCategoriesModal.propTypes = {
  propertyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default withTranslation()(EditSpecialCategoriesModal);
