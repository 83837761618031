export const MAIN_DASHBOARD_VIEW_ROUTES = {
  BANNERS: 'banners',
  THEMES: 'themes',
  LICENSORS: 'licensors',
  MAIL: 'mail',
  MFIDS: 'mfids',
  MOVIES: 'movies',
  SCENES: 'scenes',
  PAYOUTS: 'payouts',
  PROPERTIES: 'properties',
  STARS: 'stars',
  STUDIOS: 'studios',
  USERS: 'users',
  UNASSIGNED_VIEW_TIME: 'unassigned_view_time'
};
