import axios from 'axios';

/* istanbul ignore file */
export const urls = {
  aebnStreaming: '/movies/streaming/aebn/movie'
};

export const prepareAebnParamsForStream = ({movieId: id, startTime, orientation, maxBitrate, format}) => {
  const params = {id, format};
  if (startTime) {
    params.start_time = startTime;
  }
  if (orientation) {
    params.orientation = orientation;
  }
  if (maxBitrate) {
    params.max_bitrate = maxBitrate;
  }

  return params;
};

export const getAebnMovieStream = (data) => {
  const params = prepareAebnParamsForStream(data);
  return axios.get(`${urls.aebnStreaming}/${data.movieId}`, {params});
};