import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Input, Select} from "antd";
import PropTypes from 'prop-types';

import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../../../services/notification-service/notification.service';
import {
  getScenesPlaylistCategories,
  addNewScenePlaylist
} from '../../../../../../../../../../../../services/movies-service/movies.service';

import ModalDialog from "../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import './AddScenePlaylistModal.scss';

class AddScenePlaylistModal extends Component {

  modalRef = React.createRef();

  state = {
    playlistName: '',
    sceneCategories: [],
    category: {}
  };

  componentDidMount() {
    this.fetchScenePlaylistCategories();
  };

  fetchScenePlaylistCategories = () => {
    getScenesPlaylistCategories()
      .then(this.loadCategories)
      .catch(this.getCategoriesFailure);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className='AddScenePlaylistModal-actions'>
        <Button onClick={this.onCancel.bind(this)}
                key="cancelBtn">
          {t('AddScenePlaylistModal.close')}
        </Button>
        <Button onClick={this.onSavePlaylist.bind(this)}
                type="primary"
                disabled={!this.state.playlistName || !this.state.category.name}
                key="savePlaylistBtn">
          {t('AddScenePlaylistModal.savePlaylist')}
        </Button>
      </div>
    );
  };

  getCategoriesFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddScenePlaylistModal.getCategoriesFailed')
    });
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('AddScenePlaylistModal.addPlaylist');
  };

  loadCategories = response => {
    this.setState({
      sceneCategories: response.data.data.scene_playlist_categories
    });
  };

  onAddPlaylistFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddScenePlaylistModal.addPlaylistFailed')
    });
  };

  onAddPlaylistSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddScenePlaylistModal.addPlaylistSuccess')
    });
    this.props.onPlaylistAdded();
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onSceneCategoriesChange = (item) => {
    this.setState({
      category: {
        id: item.id,
        name: item.name
      }
    });
  };

  onPlaylistNameChange = (event) => {
    const playlistName = event.target.value;
    this.setState({
      playlistName: playlistName
    });
  };

  onSavePlaylist = () => {
    const message = this.props.t('AddScenePlaylistModal.addingPlaylistPleaseWait');
    this.getModal().setLoading(message);

    const categoryId = this.state.category.id;
    const name = this.state.playlistName;

    addNewScenePlaylist(categoryId, name)
      .then(this.onAddPlaylistSuccess)
      .catch(this.onAddPlaylistFailure);
  };

  renderSceneCategoriesOptions = (item) => {
    return (
      <Select.Option
        key={`category-${item.id}`}
        value={item.name}
        onClick={this.onSceneCategoriesChange.bind(this, item)}
      >
        <div className="CategoryDropdownItemWrapper">
          <span className="CategoryDropdownItemText">
            {item.name}
          </span>
        </div>
      </Select.Option>
    )
  };

  render() {
    const {t} = this.props;
    return (
      <React.Fragment>
        <ModalDialog title={this.getTitle()}
                     actions={this.getActions()}
                     forwardedRef={this.modalRef}>
          <div className="AddScenePlaylistModal">
            <div className='AddScenePlaylistModal-inner'>
              <span className='AddScenePlaylistModal-inner-label'>
                {t('AddScenePlaylistModal.name')}
              </span>
              <Input value={this.state.playlistName} onChange={this.onPlaylistNameChange}/>
            </div>

            <div className='AddScenePlaylistModal-inner'>
              <span className='AddScenePlaylistModal-inner-label'>
                {t('AddScenePlaylistModal.sceneCategories')}
              </span>
              <Select showArrow
                      className="CategoriesPlaylist-select"
                      value={this.state.category.name}
              >
                {this.state.sceneCategories.map(this.renderSceneCategoriesOptions)}
              </Select>
            </div>
          </div>
        </ModalDialog>
      </React.Fragment>
    );
  }
}

AddScenePlaylistModal.propTypes = {
  onPlaylistAdded: PropTypes.func.isRequired
};

export default withTranslation()(AddScenePlaylistModal);
