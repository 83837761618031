import {withTranslation} from "react-i18next";

import {getBanners} from '../../../../services/banners-service/banners.service';
import {uploadBannerImage, assignBannerImages} from '../../../../services/images-service/images.service';


import BaseGlobalImagesAssignSelectionModal from '../../../../components/BaseGlobalImagesAssignSelectionModal/BaseGlobalImagesAssignSelectionModal';

class BannerSelectionModal extends BaseGlobalImagesAssignSelectionModal {

  assignImagesData = (data) => {
    return assignBannerImages(data);
  };

  getAssignButtonTitle = () => {
    const {t} = this.props;
    return t('BannerSelectionModal.assignImagesToBanner');
  };

  getAssignData = (id, fileId, type) => {
    return {
      banners_id: id,
      images_id: fileId,
      type: type
    };
  };

  getImageCategories = () => {
    const {t} = this.props;
    return [t('BannerSelectionModal.banner')];
  };

  getInputPlaceholder = () => {
    const {t} = this.props;
    return t('BannerSelectionModal.enterBannerId');
  };

  getSelectConfig = () => {
    return {
      getData: getBanners, 
      name: 'banners',
      id: 'id'
    };
  };

  getSelectInputLabel = () => {
    const {t} = this.props;
    return t('BannerSelectionModal.label');
  };

  getTitle = () => {
    const {t} = this.props;
    return t('BannerSelectionModal.title');
  };

  uploadImage = (id, type, file) => {
    return uploadBannerImage(id, type, file);
  };
}

export default withTranslation()(BannerSelectionModal);