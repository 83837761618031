import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import './Company.scss';

const Company = props => {
  const {t} = useTranslation();
  return (
    <div className="Company"
         onClick={props.onClick}>
      <div className="logo"/>
      <div className="CompanyName">{t('appName')}</div>
    </div>
  );
};

Company.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default Company;
