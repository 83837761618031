import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import BannerSetCardAbstract from "../../../../BannersView/BannerSets/BannerSetCard/BannerSetCardAbstract";

import {getPropertiesDetailsBannerSetBannersRoute} from "../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";

/* istanbul ignore file */
class PropertyDetailsBannerSetCard extends BannerSetCardAbstract {

  getBannersRoute = () => {
    const {bannerSetId, propertyId} = this.props;
    return getPropertiesDetailsBannerSetBannersRoute(propertyId, bannerSetId);
  };

  getAdditionalClasses = () => {
    return ['PropertyDetailsBannerSetCard'];
  };
}

PropertyDetailsBannerSetCard.propTypes = {
  allActionsIncluded: PropTypes.bool,
  bannerOrder: PropTypes.array.isRequired,
  bannerSetId: PropTypes.number.isRequired,
  doNotShowStates: PropTypes.bool,
  isDefault: PropTypes.bool,
  maxElementCount: PropTypes.number,
  propertyId: PropTypes.number,
  propertyCount: PropTypes.number,
  title: PropTypes.string.isRequired,
  totalBannerCount: PropTypes.number.isRequired
};

export default withTranslation()(PropertyDetailsBannerSetCard);
