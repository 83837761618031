import {navigate} from "@reach/router";

import {
  getStudiosRoute,
  getStudioDetailsRoute,
  getStudioMoviesRoute,
  getStudioMovieDetailsRoute
} from "./studios-navigation-routes.service";

/**
 * Navigates to studios view
 *
 * @returns {Promise<any>}
 */
export const showStudios = () => {
  return navigate(getStudiosRoute());
};

/**
 * Navigates to studio details view
 *
 * @param {number|string} studioId
 *
 * @returns {Promise<any>}
 */
export const showStudioDetails = studioId => {
  return navigate(getStudioDetailsRoute(studioId));
};

/**
 * Navigates to studio movies view
 *
 * @param {number|string} studioId
 *
 * @returns {Promise<any>}
 */
export const showStudioMovies = studioId => {
  return navigate(getStudioMoviesRoute(studioId));
};

/**
 * Navigates to studio movie details view
 *
 * @param {number|string} studioId
 * @param {number|string} movieId
 *
 * @returns {Promise<any>}
 */
export const showStudioMovieDetails = (studioId, movieId) => {
  return navigate(getStudioMovieDetailsRoute(studioId, movieId));
};