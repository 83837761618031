import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon, Table } from "antd";
import PropTypes from "prop-types";

import { convertSecondsToTime } from "../../../../../../../../../../../../services/util-service/util.service";

import BaseCard from "../../../../../../../../../../../../components/BaseCard/BaseCard";

import "./AdDetails.scss";

/* istanbul ignore file */
class AdDetails extends Component {
	columns = [
		{
			dataIndex: "name",
			width: "50%",
		},
		{
			dataIndex: "value",
			width: "50%",
		},
	];

	getAdCardFooter = () => {
		const { editAd, t } = this.props;
		return (
			<div className="AdFooter">
				<Button onClick={editAd}>
					{t("ConfigureAdsOnMovieLevel.AdDetails.editAd")}
				</Button>
			</div>
		);
	};

	getAdCardTitle = () => {
		const { index, removeAd, t } = this.props;
		return (
			<div className="AdCardTitle">
				<div className="Text">
					{t("ConfigureAdsOnMovieLevel.AdDetails.cardTitle", {
						index,
					})}
				</div>
				<Icon type="close" onClick={removeAd} />
			</div>
		);
	};

	getTableData = () => {
		const { ad, t } = this.props;
		const {
			applyToLoggedUser,
			applyToNotLoggedUser,
			selectedTarget,
			goToText,
			goToUrl,
			start,
			type,
			url,
			duration,
		} = ad;
		let addValidFor = "";
		if (applyToLoggedUser) {
			addValidFor = t("ConfigureAdsOnMovieLevel.AdDetails.loggedUser");
		}
		if (applyToNotLoggedUser) {
			const text = t("ConfigureAdsOnMovieLevel.AdDetails.notLoggedUser");
			if (addValidFor.length) {
				addValidFor = `${addValidFor}, ${text}`;
			} else {
				addValidFor = text;
			}
		}
		const data = [
			{
				name: t("ConfigureAdsOnMovieLevel.AdDetails.adValidFor"),
				value: addValidFor,
				id: "addValidForValue",
			},
			{
				name: t("ConfigureAdsOnMovieLevel.AdDetails.adStartTime"),
				value: convertSecondsToTime(start),
				id: "adStartTime",
			},
			{
				name: t("ConfigureAdsOnMovieLevel.AdDetails.selectedTarget"),
				value: t(
					`ConfigureAdsOnMovieLevel.AdDetails.${selectedTarget}`
				),
				id: "selectedTarget",
			},
			{
				name: t("ConfigureAdsOnMovieLevel.AdDetails.goToText"),
				value: goToText,
				id: "goToText",
			},
			{
				name: t("ConfigureAdsOnMovieLevel.AdDetails.goToUrl"),
				value: goToUrl,
				id: "goToUrl",
			},
			{
				name: t("ConfigureAdsOnMovieLevel.AdDetails.adType"),
				value: type,
				id: "adType",
			},
			{
				name: t("ConfigureAdsOnMovieLevel.AdDetails.adUrl"),
				value: url,
				id: "adUrl",
			},
		];
		if (type === "image") {
			data.push({
				name: t("ConfigureAdsOnMovieLevel.AdDetails.duration"),
				value: duration,
				id: "duration",
			});
		}

		return data;
	};

	render() {
		return (
			<BaseCard
				className="AdDetails"
				footer={this.getAdCardFooter()}
				title={this.getAdCardTitle()}
			>
				<Table
					columns={this.columns}
					dataSource={this.getTableData()}
					showHeader={false}
					pagination={false}
					rowKey={"id"}
				/>
			</BaseCard>
		);
	}
}

AdDetails.propTypes = {
	ad: PropTypes.object.isRequired,
	editAd: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	removeAd: PropTypes.func,
};

export default withTranslation()(AdDetails);
