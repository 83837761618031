import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Select } from 'antd';

import {
  addNewPlaylistScene,
  getMovieById,
  getScenePlaylistById
} from '../../../../../../../../../../../../services/movies-service/movies.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../../../services/notification-service/notification.service';

import ModalDialog from '../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import PlaylistSceneSelect from './components/PlaylistSceneSelect/PlaylistSceneSelect';

import './AddScenesModal.scss';

class AddScenesModal extends Component {

  state = {
    selectedScenes: [],
    validInput: true,
    scenesIds: [],
    selectedIds: []
  };

  modalRef = React.createRef();

  addScene = (sceneId) => {
    const { playlistId } = this.props;
    return addNewPlaylistScene(playlistId, sceneId);
  };

  addScenesToPlaylist = async () => {
    const idsToSend = await this.prepareSceneIdsToSend();
    const message = this.props.t('AddScenesModal.addingScenesPleaseWait');
    this.getModal().setLoading(message);
    let promises = idsToSend.map(this.addScene);
    Promise.all(promises)
      .then(this.onAddToPlaylistSuccess)
      .catch(this.onAddToPlaylistFailure);
  };

  closeDialog = () => {
    this.getModal().closeModal();
  };

  getExistingIds = () => {
    const { playlistId } = this.props;
    return getScenePlaylistById(playlistId);
  };

  getSceneId = (item) => {
    return item.scene_id;
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='AddScenesModal-actions'>
        <Button
          onClick={this.onCancel}
          key='cancelBtn'>
          {t('AddScenesModal.cancel')}
        </Button>
        <Button
          onClick={this.addScenesToPlaylist}
          key='addSceneBtn'
          disabled={this.isDisabled()}
          type='primary'>
          {t('AddScenesModal.addSceneBtn')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getSceneIds = (scenes) => {
    return scenes.map(this.getSceneId);
  };

  getSceneIdsValues = () => {
    return this.state.selectedIds;
  };

  getTitle = () => {
    return this.props.t('AddScenesModal.addScenes');
  };

  isDisabled = () => {
    return this.state.selectedScenes.length === 0 && (!this.state.validInput || this.state.selectedIds.length);
  };

  matchById = (scenes_in_playlist, item) => {
    return !scenes_in_playlist.includes(+item);
  }

  matchExistingIds = (scenes_in_playlist, idsToSend) => {
    return idsToSend.filter(this.matchById.bind(this, scenes_in_playlist));
  };

  onAddToPlaylistFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddScenesModal.addSceneFailed')
    });
  };

  onAddToPlaylistSuccess = () => {
    this.closeDialog();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddScenesModal.addSceneSuccess')
    });
    this.props.onScenesListUpdate();
  };

  onCancel = () => {
    this.closeDialog();
  };

  onScenesSelect = (selectedScenes) => {
    this.setState({
      selectedScenes: selectedScenes
    });

    const message = this.props.t('AddScenesModal.loadingSceneIds');
    this.getModal().setLoading(message);
    getMovieById(selectedScenes[0]).then((res) => {
      const scenesIds = res.data.data.scenes.map((s) => s.id);
      this.setState({ scenesIds, selectedIds: [] });
      this.getModal().clearLoading();
    });
  };

  prepareSceneIdsToSend = async () => {
    const idsToSend = this.state.selectedIds;

    const playlistData = await this.getExistingIds();
    const { scenes_in_playlist } = playlistData.data.data;

    const sceneIdsArray = this.getSceneIds(scenes_in_playlist);
    const filteredIdsToSend = this.matchExistingIds(sceneIdsArray, idsToSend);

    return filteredIdsToSend;
  };

  sceneIdsMap = (id) => {
    return (
      <Select.Option
        value={id}
        key={id}>
        {id}
      </Select.Option>
    );
  };

  renderChildren = () => {
    return this.state.scenesIds.map(this.sceneIdsMap);
  };

  onChange = (selectedValues) => {
    this.setState({
      selectedIds: selectedValues
    });
  };


  renderPlaylistSceneIdsInput = () => {
    const { t } = this.props;
    return (
      <div className="idInputContainer">
        <div>{t('AddScenesModal.idTitle')}</div>
        <Select value={this.state.selectedIds}
          style={{ width: '100%' }}
          filterOption={false}
          mode='multiple'
          onChange={this.onChange}
        >
          {this.renderChildren()}
        </Select>
      </div>
    )
  };

  renderPlaylistSceneSelect = () => {
    return (
      <PlaylistSceneSelect
        onSceneSelect={this.onScenesSelect}
      />
    );
  };

  render() {
    const { t } = this.props;
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='AddScenesModal'>
          <div className="AddScenesModal-description">{t('AddScenesModal.description')}</div>
          {this.renderPlaylistSceneSelect()}
          {this.renderPlaylistSceneIdsInput()}
        </div>
      </ModalDialog>
    );
  };
};

export default withTranslation()(AddScenesModal);