import { Subject } from 'rxjs';

export const ManufacturerDetailsEventMap = Object.freeze({
  manufacturer: {
    editFinishedEvent: 'editFinishedEvent'
  }
});

class ManufacturerDetailsComm {
  static instance;

  events = {
    manufacturer: {
      [ManufacturerDetailsEventMap.manufacturer.editFinishedEvent]: new Subject()
    }
  };

  manufacturerData;

  static getInstance() {
    if (ManufacturerDetailsComm.instance == null) {
        ManufacturerDetailsComm.instance = new ManufacturerDetailsComm();
    }

    return ManufacturerDetailsComm.instance;
  }

  fireEvent = (type, key, data) => {
    const instance = ManufacturerDetailsComm.getInstance();
    instance.getEvent(type, key).next(data);
  };

  getEvent = (type, key) => {
    const instance = ManufacturerDetailsComm.getInstance();
    return instance.events[type][`${key}`];
  };

  subscribeToEvent = (type, key, callback) => {
    const instance = ManufacturerDetailsComm.getInstance();
    return instance.getEvent(type, key).subscribe(callback);
  };
};

export const ManufacturerDetailsCommController = ManufacturerDetailsComm.getInstance();
