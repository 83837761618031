import {navigate} from "@reach/router";

import {
  getSexActMoviesRoute,
  getSexActMovieDetailsRoute
} from "./sexact-navigation-routes.service";

/**
 * Navigates to sex act movies view
 *
 * @param {number|string} sexActId
 *
 * @return {Promise<any>}
 */
export const showSexActMovies = sexActId => {
  return navigate(getSexActMoviesRoute(sexActId));
};
/**
 * Navigates to sex act movie details
 *
 * @param {number|string} sexActId
 * @param {number|string} movieId
 *
 * @returns {Promise<any>}
 */
export const showSexActMovieDetails = (sexActId, movieId) => {
  return navigate(getSexActMovieDetailsRoute(sexActId, movieId));
};
