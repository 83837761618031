import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Icon} from "antd";
import ImageComponent from "../../../../components/ImageComponent/ImageComponent";
import {withTranslation} from "react-i18next";

import './MarkingImage.scss';

class MarkingImage extends Component {

  deleteImage = (id, type, event) => {
    event.stopPropagation();
    this.props.showDeleteSingleImageModal(id, type);
  };

  getCover = (url, id, type) => {
    return (
      <div className="ImageContainer">
        <Icon type="close" onClick={this.deleteImage.bind(this, id, type)}/>
        <ImageComponent url={url}/>
      </div>
    );
  };

  getFileMark = () => {
    const {checked} = this.props.file;
    let mark = null;
    if (checked) {
      mark = (
        <div className="MarkingImage-file-mark">
          <div className="MarkingImage-file-mark-background"/>
          <Icon type="check"/>
        </div>
      )
    }
    return mark;
  };

  getImageActions = () => {
    const {file, t} = this.props;
    const text = file.checked ? t('MarkingImage.deselectImage') : t('MarkingImage.selectImage');
    return [(
      <Button onClick={this.onCheckChange}>
        {text}
      </Button>
    )];
  };

  onCheckChange = () => {
    this.props.onCheckChange(this.props.index, !this.props.file.checked);
  };

  render() {
    const {file, index} = this.props;
    return (
      <Card className="MarkingImage"
            onClick={this.onCheckChange}
            key={file.url}
            cover={this.getCover(file.url, file.id, file.type)}
            actions={this.getImageActions()}>
        {this.getFileMark(file.checked, index)}
      </Card>
    );
  }
}

MarkingImage.propTypes = {
  file: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onCheckChange: PropTypes.func.isRequired
};

export default withTranslation()(MarkingImage);