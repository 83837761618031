import React, { Component } from "react";
import { Button, DatePicker, Input, Select } from 'antd';
import ModalDialog from '../../../../../../../../../components/modal/ModalDialog/ModalDialog';
import { withTranslation } from 'react-i18next';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../services/notification-service/notification.service';
import { createNewBannerSet, getBannerSets } from '../../../../../../../../../services/banners-service/banners.service';

import { BannerController } from '../../../../../../../../../controllers/banner-controller/banner.controller';
import moment from 'moment';
import { convertMomentToDate, momentFormats } from "../../../../../../../../../services/date-service/date.service";
import "./NewBannerSetModal.scss";

class NewBannerSetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      banner_override_url: '',
      natsCodes: [],
      parentSet: undefined,
      parentSets: [],
      loading: false
    }
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchBannerSets();
  }

  setLoading = loading => {
    this.setState({
      loading: loading
    });
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="NewBannerSetModal-actions">
        <Button
          onClick={this.onCancel.bind(this)}
          key="cancelBtn"
        >
          {t('NewBannerSetModal.cancel')}
        </Button>
        <Button
          key="saveChangesBtn"
          onClick={this.saveChanges.bind(this)}
        >
          {t('NewBannerSetModal.save')}
        </Button>
      </div>
    );
  };

  fetchBannerSets = () => {
    const loadingMsg = this.props.t('NewBannerSetModal.loadingBannerSetsMsg');
    this.getModal().setLoading(loadingMsg);

    getBannerSets(0, 'index', 'asc', 0)
      .then(response => {
        const bannerSets = response?.data?.data || [];
        this.setState({
          // Exclude all child sets and exclude Playlists set (id: 60)
          parentSets: bannerSets.filter(set => !set.parent_set && set.id !== 60),
          parentSet: bannerSets[0]
        })
        this.getModal().clearLoading();
      })
      .catch(error => {
        this.getModal().clearLoading();
        displayErrorNotification({
          duration: 3,
          message: this.props.t('NewBannerSetModal.loadBannerSetsFailMsg')
        });
      })
  }

  handleEditInput = (event = {}) => {
    const { name, value } = event?.target;
    this.setState({ [name]: value })
  }

  handleEditNATSCodes = (value) => {
    this.setState({ natsCodes: value })
  }

  handleParentSetChange = (parentSetId) => {
    const parentSetObject = this.state.parentSets.find(item => item.id === parentSetId);
    this.setState({ parentSet: parentSetObject })
  }

  onCancel = () => {
    this.getModal().closeModal();
  };

  saveChanges = () => {
    const { name, banner_override_url, natsCodes, parentSet, publishStart, publishEnd } = this.state;
    const loadingMsg = this.props.t('NewBannerSetModal.savingMsg');
    this.getModal().setLoading(loadingMsg);

    const data = Object.assign(
      {},
      { name },
      { banner_override_url: banner_override_url ? banner_override_url : 'null' },
      { nats_codes: natsCodes || "null" },
      { parent_set_id: parentSet.id },
      { publish_start: convertMomentToDate(publishStart, 1, 1) },
      { publish_end: convertMomentToDate(publishEnd, 1, 1) },
      { type_id: "movies" }
    );

    createNewBannerSet(data)
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  onSaveChangesSuccess = () => {
    this.getModal().clearLoading();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('NewBannerSetModal.saveSuccessMsg')
    });
    this.onCancel();
    BannerController.notifyBannerSetsUpdate()
  }

  onSaveChangesFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('NewBannerSetModal.saveFailureMsg')
    });
    this.onCancel();
  }

  onDateChange = (key, value) => {
    this.setState({ [key]: value });
  };

  renderField = (label, children) => {
    return (
      <div className="Option">
        {label}
        {children}
      </div>)
  };

  renderNATSOptions = () => {
    const { natsCodes, parentSet, parentSets, publishStart, publishEnd } = this.state;
    const { t } = this.props;

    return (
      <>
        {this.renderField(
          t("NewBannerSetModal.nats"),
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={natsCodes}
            onChange={this.handleEditNATSCodes}
          >
            {natsCodes.map(code =>
              <Select.Option value={code} key={code}>
                {code}
              </Select.Option>
            )}
          </Select>
        )}

        {this.renderField(
          t("NewBannerSetModal.parentSelect"),
          <Select value={parentSet?.id || undefined} onChange={this.handleParentSetChange}>
            {parentSets.length && parentSets.map(set =>
              <Select.Option value={set.id} key={set.id}>
                {set.name}
              </Select.Option>
            )}
          </Select>
        )}

        {this.renderField(
          t("NewBannerSetModal.publishStart"),
          <DatePicker
            showToday
            showTime
            value={moment(publishStart).isValid() ? moment(publishStart) : null}
            onChange={this.onDateChange.bind(this, 'publishStart')}
            format={momentFormats.dateHours}
          />)}

        {this.renderField(
          t("NewBannerSetModal.publishEnd"),
          <DatePicker
            showToday
            showTime
            value={moment(publishEnd).isValid() ? moment(publishEnd) : null}
            onChange={this.onDateChange.bind(this, 'publishEnd')}
            format={momentFormats.dateHours}
          />)}
      </>
    )
  }

  render() {
    const { t } = this.props;
    const { name, banner_override_url } = this.state;

    return (
      <ModalDialog
        title={t("NewBannerSetModal.title")}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        {this.renderField(
          t("NewBannerSetModal.name"),
          <Input onChange={this.handleEditInput} name='name' value={name} />)}
        {this.renderField(
          'Link to',
          <Input onChange={this.handleEditInput} name='banner_override_url' value={banner_override_url} />)}

        {this.renderNATSOptions()}
      </ModalDialog>
    );
  }
}

export default withTranslation()(NewBannerSetModal);
