import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Form, Input, Select } from "antd";
// import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import { addRoyaltiesUser } from "../../../../../../../../../../services/users-service/users.service";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../../services/notification-service/notification.service";

import './AddRoyaltiesUserModal.scss';

class addRoyaltiesUserModal extends Component {

  fields = ['username', 'licensorId', 'password', 'repeatPassword'];

  isUnMounted = false;

  modalRef = React.createRef();

  state = {
    confirmDirty: false,
    isValid: false,
    roles: []
  };

  componentDidMount() {
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  addUser = () => {
    const { username, licensorId, password } = this.props.form.getFieldsValue();
    const data = {
      username,
      licensors_id: licensorId,
      password
    };
    addRoyaltiesUser(data)
      .then(this.onAddUserSuccess)
      .catch(this.onAddUserFailure)
  };

  clearUntouchedErrors = () => {
    this.fields.forEach(this.clearUntouchedFieldErrors);
  };

  clearUntouchedFieldErrors = (name) => {
    const { form } = this.props;
    if (!form.isFieldTouched(name) && (name !== 'primaryRole' || !form.getFieldValue(name))) {
      form.setFields({
        [name]: {
          errors: undefined
        }
      });
    }
  };

  getActions = () => {
    return (
      <div className="addRoyaltiesUserModal-actions">
        <Button onClick={this.onCancel}
          key="cancelBtn">
          Cancel
        </Button>
        <Button onClick={this.addUser}
          key="addUserBtn"
          disabled={!this.state.isValid}
          type="primary">
          Add
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return 'Add Royalties User';
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState(prevState => {
      return {
        confirmDirty: prevState.confirmDirty || !!value
      };
    });
  };

  isValid = (errors) => {
    this.setState({
      isValid: errors === null
    });
    this.clearUntouchedErrors();
  };

  loadRolesData = (response) => {
    const { data } = response.data;
    if (!this.isUnMounted) {
      this.setState({
        roles: data
      });
    }
  };

  onAddUserFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: 'Add user failed'
    });
  };

  onAddUserSuccess = (response) => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: 'User Added'
    });
    this.props.refreshContent();
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onPrimaryRoleChange = (value) => {
    const { form } = this.props;
    form.setFieldsValue({
      primaryRole: value
    });
    this.onValuesChange();
  };

  onValuesChange = () => {
    this.props.form.validateFields(this.isValid.bind(this));
  };

  renderFilterItem = (item) => {
    const { t } = this.props;
    return (
      <Select.Option key={item.id} value={item.id}>
        <div className="addRoyaltiesUserModalFilterItemWrapper">
          {t(`addRoyaltiesUserModal.${item.name}`)}
        </div>
      </Select.Option>
    );
  };

  renderPasswordField = (key) => {
    let message = (key === 'password' ? 'Please enter password' : 'Please confirm password');

    const { getFieldDecorator } = this.props.form;
    return (
      <Form.Item>
        {getFieldDecorator(key, {
          rules: [{
            required: true,
            message: message,
            whitespace: true
          }, {
            validator: key === 'password' ? this.validatePassword : this.validateConfirmPassword
          }],
        })(
          <div className="addRoyaltiesUserModal-field-wrapper">
            <span className="Label">{key === 'password' ? 'Password' : 'Confirm Password'}:</span>
            <Input.Password name={key} onBlur={key === 'password' ? this.handleConfirmBlur : null} />
          </div>
        )}
      </Form.Item>
    );
  };

  // renderPrimaryRole = () => {
  //   const { t } = this.props;
  //   const { getFieldDecorator } = this.props.form;
  //   return (
  //     <Form.Item>
  //       {getFieldDecorator('primaryRole', {
  //         rules: [{
  //           required: true,
  //           message: t('addRoyaltiesUserModal.validation.pleaseSelectUserPrimaryRole')
  //         }],
  //       })(
  //         <div className="addRoyaltiesUserModal-field-wrapper">
  //           <span className="Label">{t('addRoyaltiesUserModal.primaryRole')}:</span>
  //           <Select showArrow
  //             className="addRoyaltiesUserModal-select"
  //             onChange={this.onPrimaryRoleChange}>
  //             {this.state.roles.map(this.renderFilterItem)}
  //           </Select>
  //         </div>
  //       )}
  //     </Form.Item>
  //   )
  // };

  renderUsername = () => {

    const { getFieldDecorator } = this.props.form;
    return (
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [{
            required: true,
            message: 'Please enter user name',
            whitespace: true
          }],
        })(
          <div className="addRoyaltiesUserModal-field-wrapper">
            <span className="Label">User Name:</span>
            <Input
              name="username" />
          </div>
        )}
      </Form.Item>
    );
  };

  renderLicensorId = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form.Item>
        {getFieldDecorator('licensorId', {
          rules: [{
            required: true,
            message: 'Please add licensor ID',
            whitespace: true
          }],
        })(
          <div className="addRoyaltiesUserModal-field-wrapper">
            <span className="Label">Licensor ID:</span>
            <Input
              name="licensorId" />
          </div>
        )}
      </Form.Item>
    );
  };

  validateConfirmPassword = (rule, repeatPassword, callback) => {
    const { form } = this.props;
    const password = form.getFieldValue('password');
    if (repeatPassword && (repeatPassword !== password)) {
      callback('Passwords must match');
    } else {
      callback();
    }
  };

  validatePassword = (rule, password, callback) => {
    const { form } = this.props;
    if (password && this.state.confirmDirty) {
      form.validateFields(['repeatPassword'], { force: true });
    }
    callback();
  };

  render() {

    return (
      <ModalDialog title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className="addRoyaltiesUserModal">
          <Form onChange={this.onValuesChange}>
            {this.renderUsername()}
            {this.renderLicensorId()}
            {this.renderPasswordField('password', 'pleaseEnterPassword')}
            {this.renderPasswordField('repeatPassword', 'pleaseConfirmPassword')}
            {/* {this.renderPrimaryRole()} */}
          </Form>
        </div>
      </ModalDialog>
    );
  }
}

// addRoyaltiesUserModal.propTypes = {

// };

const addRoyaltiesUserModalForm = Form.create({ name: 'addUser' })(addRoyaltiesUserModal);

export default withTranslation()(addRoyaltiesUserModalForm);
