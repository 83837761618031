import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

import SignIn from './SignIn/SignIn';

import {
  cleanupRefreshTimeout,
  cleanupToken,
  getUserToken,
  hasTokenExpired
} from "../../services/token-service/token.service";
import {showMainDashboard} from "../../services/navigation/navigation.service";
import {DocumentTitleController} from "../../controllers/title-controller/title.controller";
import {cleanupUserData} from "../../services/users-service/users.service";

import './Login.scss';

class Login extends Component {

  componentDidMount() {
    this.setDocumentTitle();
    if (getUserToken()) { // Token exists
      if (hasTokenExpired()) { // Token expired so cleanup token so that login can work properly
        cleanupToken();
        cleanupRefreshTimeout();
        cleanupUserData();
      } else { // Token is valid so redirect to main dashboard
        showMainDashboard();
      }
    }
  }

  setDocumentTitle = () => {
    DocumentTitleController.setDocumentTitle();
  };

  render() {
    return (
      <div className='LoginWrapper'>
        <SignIn/>
      </div>
    );
  }
}

export default withTranslation()(Login);
