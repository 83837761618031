import React from 'react';
import {withTranslation} from "react-i18next";
import BaseFooter from '../../../../../../../../../../../components/BaseFooter/BaseFooter';

import MoviesViewFooterAbstract from "../../../../MoviesView/MoviesMainView/MoviesViewFooter/MoviesViewFooterAbstract";

import './StudioMoviesFooter.scss';

class StudioMoviesFooter extends MoviesViewFooterAbstract {
  renderChildren = () => null;

  renderActionsDropdown = () => null;

  renderMinView = () => null;

  renderMaxView = () => {
    return (
      <BaseFooter
        className="StudioMoviesFooter"
        currentPage={this.props.currentPage}
        recordCount={this.props.recordCount}
        pageCount={this.props.pageCount}
        pageSize={this.props.pageSize}
        onChange={this.props.onChange}
      />
    )
  };
}

export default withTranslation()(StudioMoviesFooter);
