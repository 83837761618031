import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {BasePlayerAdOverlay} from "@falconstudios/ns-player";

import './TestAdConfigModal.scss';

/* istanbul ignore file */
class TestAdConfigModal extends Component {

  isUnMounted = false;

  modalRef = React.createRef();

  state = {
    showCloseIndicator: false,
    videoAddTime: 0
  };

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  closeModal = () => {
    this.getModal().closeModal();
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('TestAdConfigModal.adTest');
  };

  mapAd = () => {
    const { goToText: urlText, duration, type, url, videoType } = this.props.ad;
    const ad = {
      duration,
      urlText,
      type
    };
    if (type === 'image') {
      ad.url = url;
    } else {
      ad.url = url;
      ad.sources = [{
        "src": url,
        "type": videoType
      }];
    }
    return ad;
  };

  onAdClick = () => {
    const { goToUrl } = this.props.ad;
    window.open(goToUrl, '_blank');
  };

  onAdClose = () => {

  };

  updateVideoAddTimer = (duration, expiredTime) => {
    if (!this.isUnMounted) {
      const remaining = duration - expiredTime;
      this.setState({
        showCloseIndicator: !remaining,
        videoAddTime: remaining
      });
    }
  };

  renderAdContent = () => {
    const { showCloseIndicator, videoAddTime } = this.state;
    const ad = this.mapAd();
    return (
      <BasePlayerAdOverlay ad={ad}
                           onVideoAdTimeUpdate={this.updateVideoAddTimer}
                           videoAddTime={videoAddTime}
                           showCloseIndicator={showCloseIndicator}
                           onAddClick={this.onAdClick}
                           onClose={this.onAdClose}/>
    );
  };

  render() {
    return (
      <ModalDialog className="TestAdConfigModal"
                   title={this.getTitle()}
                   forwardedRef={this.modalRef}>
        <div className="TestAdConfigModal-inner">
          {this.renderAdContent()}
        </div>
      </ModalDialog>
    );
  }
}

TestAdConfigModal.propTypes = {
  ad: PropTypes.object.isRequired
};

export default withTranslation()(TestAdConfigModal);
