import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Checkbox, Input} from "antd";
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import {getMovieById} from "../../../../../../../../../../../services/movies-service/movies.service";
import {getStudioById} from "../../../../../../../../../../../services/studio-service/studio.service";

import {LogController} from "../../../../../../../../../../../controllers/log-controller/log.controller";

import './PropertiesSelectionModal.scss';

/* istanbul ignore file */
class PropertiesSelectionModal extends Component {

  debounceInterval = 300;

  filterTimeoutId;

  modalRef = React.createRef();

  movieData;

  nameFilterRef = React.createRef();

  properties = [];

  state = {
    properties: [],
    propertyNameFilter: '',
    selectedProperties: {}
  };

  constructor(props) {
    super(props);
    const {selectedProperties} = props;
    const {movieInfos} = props;
    if (movieInfos) {
      const {properties} = movieInfos;
      this.properties = properties ? properties : [];
      this.state.properties = cloneDeep(this.properties);
    }
    this.state.selectedProperties = selectedProperties ? selectedProperties : {};
  }

  componentDidMount() {
    const {movieInfos} = this.props;
    if (!movieInfos || !movieInfos.properties) {
      this.loadData();
    } else {
      this.focusSearch();
    }
  }

  applySelectedProperties = () => {
    const {movieId} = this.props;
    const {selectedProperties} = this.state;
    this.props.onPropertiesSelected({
      movieDetails: this.movieData,
      movieId,
      movieProperties: this.properties,
      selectedProperties
    });
    this.getModal().closeModal();
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  filterProperties = () => {
    const properties = this.properties.filter(this.matchPropertyByName);
    this.setState({
      properties
    });
  };

  focusSearch = () => {
    this.nameFilterRef.current.focus();
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="ConfigureAdsPropertySelectionModal-actions">
        <Button onClick={this.closeModal}>
          {t('ConfigureAdsOnMovieLevel.PropertiesSelectionModal.cancel')}
        </Button>
        <Button onClick={this.applySelectedProperties}>
          {t('ConfigureAdsOnMovieLevel.PropertiesSelectionModal.applySelectedProperties')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getStudioDetailsPromise = ({id}) => {
    return getStudioById(id);
  };

  getTitle = () => {
    const {movieTitle, t} = this.props;
    return t('ConfigureAdsOnMovieLevel.PropertiesSelectionModal.title', {title: movieTitle});
  };

  loadData = () => {
    const message = this.props.t('ConfigureAdsOnMovieLevel.PropertiesSelectionModal.loadingPropertyInfos');
    this.getModal().setLoading(message);
    const {movieId} = this.props;
    getMovieById(movieId)
      .then(this.onGetMovieDetailsSuccess)
      .catch(this.onRequestFailure);
  };

  matchById = (matchId, {id}) => matchId === id;

  matchNotAddedProperties = (properties, {id}) => {
    return !properties.find(this.matchById.bind(this, id))
  };

  matchPropertyByName = ({name}) => {
    const {propertyNameFilter} = this.state;
    return name.toLowerCase().indexOf(propertyNameFilter.toLowerCase()) !== -1;
  };

  onGetMovieDetailsSuccess = (response) => {
    const {data} = response.data;
    const {studios} = data;
    this.movieData = data;
    const promises = studios.map(this.getStudioDetailsPromise);
    Promise.all(promises)
      .then(this.onGetStudiosSuccess)
      .catch(this.onRequestFailure);
  };

  onGetStudiosSuccess = (response) => {
    this.getModal().clearLoading();
    const properties = response.reduce(this.propertiesReducer, []);
    this.properties = cloneDeep(properties);
    this.setState({properties}, this.focusSearch);
  };

  onPropertyNameFilterChange = (event) => {
    const {value} = event.target;
    this.setState({
      propertyNameFilter: value
    });
    if (this.filterTimeoutId) {
      clearTimeout(this.filterTimeoutId);
    }
    this.filterTimeoutId = setTimeout(this.filterProperties, this.debounceInterval);
  };

  onRequestFailure = (error) => {
    LogController.logError(error);
    this.getModal().clearLoading();
  };

  propertyItemMap = ({id, name}) => {
    const checked = !!this.state.selectedProperties[id];
    const classes = ['PropertyItem'];
    if (checked) {
      classes.push('Checked');
    }
    return (
      <div className={classes.join(' ')}
           onClick={this.setPropertySelected.bind(this, id, name)}
           key={id}>
        <div className="PropertyName">
          {name}
        </div>
        <Checkbox checked={checked}/>
      </div>
    );
  };

  propertiesReducer = (total, response) => {
    const {properties} = response.data.data;
    return total.concat(properties.filter(this.matchNotAddedProperties.bind(this, total)));
  };

  renderProperties = () => {
    let view = null;
    const {properties} = this.state;
    if (properties.length) {
      view = (
        <div className="PropertiesList">
          {properties.map(this.propertyItemMap)}
        </div>
      );
    }

    return view;
  };

  renderSearchByNameFilter = () => {
    const {t} = this.props;
    const {propertyNameFilter} = this.state;
    return (
      <div className="SearchByNameFilter">
        <div className="FilterLabel">
          {t('ConfigureAdsOnMovieLevel.PropertiesSelectionModal.searchByName')}
        </div>
        <Input value={propertyNameFilter}
               ref={this.nameFilterRef}
               onChange={this.onPropertyNameFilterChange}/>
      </div>
    );
  };

  setPropertySelected = (propertyId, name) => {
    this.setState(prevState => {
      let selectedProperties = cloneDeep(prevState.selectedProperties);
      const checked = !selectedProperties[propertyId];
      if (checked) {
        selectedProperties[propertyId] = {
          id: propertyId,
          name
        };
      } else {
        delete selectedProperties[propertyId];
      }
      return {selectedProperties};
    });
  };

  render() {
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}
                   className="PropertiesSelectionModal">
        <div className="PropertiesSelectionModal-inner">
          {this.renderSearchByNameFilter()}
          {this.renderProperties()}
        </div>
      </ModalDialog>
    );
  }
}

PropertiesSelectionModal.propTypes = {
  movieId: PropTypes.number.isRequired,
  movieTitle: PropTypes.string.isRequired,
  movieInfos: PropTypes.object,
  onPropertiesSelected: PropTypes.func.isRequired,
  selectedProperties: PropTypes.object
};

export default withTranslation()(PropertiesSelectionModal);
