import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import UsersView from '../../UsersView/UsersView';

class PropertyUsers extends Component {
  state = {
    contentHeight: 100
  };
  componentDidMount() {
    this.setDocumentTitle();
    const view = document.getElementsByClassName('MainDashboardMainView')[0];
    if (view) {
      this.setState({
        contentHeight: view.clientHeight - 50
      });
    }
  }

  setDocumentTitle = () => {
    const { propertyName, t } = this.props;
    const title = t('PropertyUsers.documentTitle', { name: propertyName });
    DocumentTitleController.setDocumentTitle(title);
  };

  render() {
    const { propertyId } = this.props;
    return (
      <div>
        <UsersView
          activeUsers={true}
          propertyId={propertyId}
          contentHeight={this.state.contentHeight}
        />
      </div>
    );
  }
}

PropertyUsers.propTypes = {
  propertyName: PropTypes.string
};

export default withTranslation()(PropertyUsers);
