import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Button, Icon} from 'antd';
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../../components/BaseFooter/BaseFooter";

import './LicensorsViewFooter.scss';

class LicensorsViewFooter extends Component {

  renderAddLicensorBtn = () => {
    const {t} = this.props;
    return (
      <Button onClick={this.props.onAddLicensor}>
        <Icon type='user-add'/>
        {t('LicensorsViewFooter.addLicensor')}
      </Button>
    );
  };

  render() {
    return (
      <BaseFooter className='LicensorsViewFooter'
                  {...this.props}>
        {this.renderAddLicensorBtn()}
      </BaseFooter>
    );
  }
}

LicensorsViewFooter.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onAddLicensor: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  recordCount: PropTypes.number.isRequired
};

export default withTranslation()(LicensorsViewFooter);
