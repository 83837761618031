import React from 'react';
import {withTranslation} from "react-i18next";
import {cloneDeep} from 'lodash';
import {Button, Collapse, Icon} from "antd";
import PropTypes from 'prop-types';

import AdConfigPanel from "../AdConfigPanel/AdConfigPanel";
import AdConfigurationBaseAbstract, {InvalidReasons} from "../../../../shared/AdConfigurationBaseAbstract";
import CollapsiblePanel from "../../../../shared/CollapsiblePanel/CollapsiblePanel";
import ModalDialog from "../../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import TestAdConfigModal from "../../../../TestAdConfigModal/TestAdConfigModal";

import {stopEvent} from "../../../../../../../../../../../../../services/util-service/util.service";

import {ModalController} from "../../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import './ConfigureAdsModal.scss';

/* istanbul ignore file */
class ConfigureAdsModal extends AdConfigurationBaseAbstract {

  modalRef = React.createRef();

  state = {
    activeKeys: [],
    ads: [],
    invalidKeys: {}
  };

  constructor(props) {
    super(props);
    const {ads} = props;
    if (ads) {
      this.state.ads = cloneDeep(ads);
      this.state.activeKeys = ads.map(this.getAdKey);
    }
  }

  componentDidMount() {
    if (!this.state.ads.length) {
      this.addNewAd();
    }
  }

  adMap = (ad, index) => {
    const {invalidKeys} = this.state;
    const {endInSeconds, startInSeconds} = this.props;
    const {key} = ad;
    const invalidReason = invalidKeys[index];
    const classes = ['AdConfigCollapse']
    if (Object.keys(invalidKeys).length) {
      if (invalidReason) {
        classes.push('Invalid');
      } else {
        classes.push('Valid');
      }
    }
    return (
      <Collapse.Panel key={key}
                      header={this.getSceneAdHeader(ad, index)}
                      className={classes.join(' ')}>
        {this.renderInvalidReasonExplanation(invalidReason)}
        <AdConfigPanel ad={ad}
                       index={index}
                       onAddConfigChange={this.onAddConfigChange}
                       removeAdd={this.removeAdd.bind(this, index, key)}
                       endInSeconds={endInSeconds}
                       startInSeconds={startInSeconds}
                       key={key}/>
      </Collapse.Panel>
    );
  };

  addNewAd = () => {
    this.setState(prevState => {
      const ads = cloneDeep(prevState.ads);
      const {activeKeys} = prevState;
      const start = this.props.startInSeconds;
      const key = new Date().getTime();
      ads.push({start, key});
      activeKeys.push(key);
      return {ads, activeKeys};
    });
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  getActions = () => {
    const {isMovie, t} = this.props;
    const key = isMovie ? 'saveMovieAds' : 'saveSceneAds';
    return (
      <div className="ConfigureAdsModal-actions">
        <Button onClick={this.addNewAd}>
          {t('ConfigureAdsOnMovieLevel.ConfigureAdsModal.moreAds')}
        </Button>
        <div className="Section2">
          <Button onClick={this.closeModal}>
            {t('ConfigureAdsOnMovieLevel.ConfigureAdsModal.cancel')}
          </Button>
          <Button onClick={this.onSaveSceneAdsClick}>
            {t(`ConfigureAdsOnMovieLevel.ConfigureAdsModal.${key}`)}
          </Button>
        </div>
      </div>
    );
  };

  getAdKey = ({key}) => key;

  getInvalidConfigAds = (ads) => {
    const invalidAds = {};
    let item = 0;
    let itemCount = ads.length;
    let currentAd;
    while (item < itemCount) {
      currentAd = ads[item];
      if (!this.isAdConfigValid(currentAd)) {
        invalidAds[item] = InvalidReasons.InvalidAdConfig;
      }
      item++;
    }

    return invalidAds;
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getSceneAdHeader = (ad, index) => {
    const {t} = this.props;
    const isAdConfigValid = this.isAdConfigValid(ad);
    return (
      <div className="SceneAdConfigHeader">
        {t('ConfigureAdsOnMovieLevel.ConfigureAdsModal.adTitle', {index})}
        <div>
          <Button onClick={this.testConfiguration.bind(this, index)} disabled={!isAdConfigValid}>
            {t('ConfigureAdsOnMovieLevel.ConfigureAdsModal.testConfiguration')}
          </Button>
          <Icon type="close" onClick={this.removeAdd.bind(this, index)}/>
        </div>
      </div>
    )
  };

  getTitle = () => {
    const {id, isMovie, t} = this.props;
    const key = isMovie ? 'movieTitle' : 'sceneTitle';
    return t(`ConfigureAdsOnMovieLevel.ConfigureAdsModal.${key}`, {id});
  };

  matchByKey = (key, panelKey) => {
    return key === panelKey;
  };

  onActivePanelChange = (activeKeys) => {
    this.setState({activeKeys});
  };

  onAddConfigChange = (index, data) => {
    this.setState(prevState => {
      const ads = cloneDeep(prevState.ads);
      ads[index] = Object.assign(ads[index], data);
      return {ads};
    });
  };

  onDataValidated = () => {
    const {invalidKeys} = this.state;
    if (!Object.keys(invalidKeys).length) {
      this.saveSceneAds();
    } else {
      this.displayErrorsFoundModal();
    }
  };

  onSaveSceneAdsClick = () => {
    this.validateData(this.onDataValidated);
  };

  removeAdd = (index, key) => {
    this.setState(prevState => {
      const ads = cloneDeep(prevState.ads);
      const activeKeys = this.removePanelKey(prevState.activeKeys, key);
      ads.splice(index, 1);
      return {ads, activeKeys};
    });
  };

  removePanelKey = (activeKeys, key) => {
    const index = activeKeys.findIndex(this.matchByKey.bind(this, key));
    activeKeys.splice(index, 1);
    return activeKeys;
  };

  saveSceneAds = () => {
    const {onAdsSave, id} = this.props;
    const {ads} = this.state;
    onAdsSave(id, ads);
    this.closeModal();
  };

  testConfiguration = (index, event) => {
    stopEvent(event);
    const ad = this.state.ads[index];
    const modal = (
      <TestAdConfigModal ad={ad}/>
    );
    ModalController.showModal(modal);
  };

  validateData = (callback) => {
    this.setState(prevState => {
      const {ads} = prevState;
      return {
        invalidKeys: this.getInvalidConfigAds(ads)
      };
    }, callback);
  };

  render() {
    const {activeKeys, ads} = this.state;
    return (
      <ModalDialog title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}
                   className="ConfigureAdsModal">
        <div className="ConfigureAdsModal-inner">
          <CollapsiblePanel defaultActiveKeys={activeKeys}
                            activeKeys={activeKeys} onChange={this.onActivePanelChange}>
            {ads.map(this.adMap)}
          </CollapsiblePanel>
        </div>
      </ModalDialog>
    );
  }
}

ConfigureAdsModal.propTypes = {
  ads: PropTypes.array,
  onAdsSave: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  isMovie: PropTypes.bool,
  endInSeconds: PropTypes.number.isRequired,
  startInSeconds: PropTypes.number.isRequired
};

export default withTranslation()(ConfigureAdsModal);
