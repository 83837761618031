import React, { Component } from 'react';
import { Button, Icon } from "antd";
import { withTranslation } from "react-i18next";

import {
  getBannerById,
  getBannerSets,
  deleteBanner
} from '../../../../../../../../../services/banners-service/banners.service';
import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import { deleteBannerImage, uploadBannerImage } from '../../../../../../../../../services/images-service/images.service';
import { getBannersBaseRoute } from '../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service';
import { showBanners } from '../../../../../../../../../services/navigation/banners-navigation/banners-navigation.service';
import { getProperties } from "../../../../../../../../../services/properties-service/properties.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

import BannerDetailsInfosTab from "./BannerDetailsInfosTab/BannerDetailsInfosTab";
import BannerDetailsImagesTab from "./BannerDetailsImagesTab/BannerDetailsImagesTab";
import UploadBannerImagesModal from "./UploadBannerImagesModal/UploadBannerImagesModal";

import ReplacePictureModal from "../ReplacePictureModal/ReplacePictureModal";

import DeleteImagesModal from "../../../../../../../../../components/modal/DeleteImagesModal/DeleteImagesModal";
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import TabsComponent from "../../../../../../../../../components/TabsComponent/TabsComponent";
import BaseNavigationItem from "../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import ConfirmationModal from "../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";

import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";
import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";
import { BannerController } from '../../../../../../../../../controllers/banner-controller/banner.controller';

import './BannerDetails.scss';

class BannerDetails extends Component {
  bannerId;
  imageDeleting;

  state = {
    activeTab: '1',
    data: {},
    dataLoaded: false,
    loadingMessage: '',
    properties: []
  };

  subscription;

  constructor(props) {
    super(props);
    this.bannerId = +props.bannerId;
    this.subscription = BannerController.bannerDetailsUpdated.subscribe(this.fetchBannerData);
  }

  componentDidMount() {
    this.fetchBannerData();
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  deleteBanner = () => {
    const message = this.props.t('BannerDetails.deletingBannerMsg');
    this.setLoading(message);

    deleteBanner(this.bannerId)
      .then(this.onDeleteBannerSuccess)
      .catch(this.onDeleteBannerFailure);
  };

  deleteImages = () => {
    const { image } = this.state.data;
    const modal = (
      <DeleteImagesModal
        images={image}
        deleteImage={this.mapDeleteImagePromise}
        onDeleteSuccess={this.onDeleteSuccess}
      />
    );
    ModalController.showModal(modal);
  };

  fetchBannerById = () => {
    return getBannerById(this.props.bannerId);
  };

  fetchBannerData = (resolve, reject) => {
    const message = this.props.t('BannerDetails.loadingPleaseWait');
    this.setLoading(message);

    const promises = [this.fetchBannerById(), this.fetchBannersSets(), getProperties()];

    Promise.all(promises)
      .then(this.loadBannerData.bind(this, resolve))
      .catch(this.onRequestFailure.bind(this, reject));
  };

  fetchBannersSets = () => {
    return getBannerSets();
  };

  getBannerActions = () => {
    const { t } = this.props;
    return [
      <Button onClick={this.uploadImages}
        key="uploadImageBtn">
        <Icon type="picture" />
        {t('BannerDetails.uploadImage')}
      </Button>,
      <Button onClick={this.deleteImages}
        key="deleteImages">
        <Icon type="delete" />
        {t('BannerDetails.deleteImages')}
      </Button>,
      <Button onClick={this.showReplaceBannerPicture}
        key="replacePicture">
        <Icon type="picture" />
        {t('BannerDetails.replacePicture')}
      </Button>,
      <Button onClick={this.showDeleteBannerConfirmationModal}
        key="deleteBanner">
        <Icon type="delete" />
        {t('BannerDetails.deleteBanner')}
      </Button>
    ];
  };

  getTabs = () => {
    const { t, bannerId } = this.props;
    const { data, bannersSets, properties } = this.state;
    let imageTab = null;
    if (data.image && data.image.length !== 0) {
      imageTab = {
        title: t('BannerDetails.images'),
        view: (
          <BannerDetailsImagesTab
            images={data.image}
            bannerId={bannerId}
            deleteImage={this.mapDeleteImagePromise}
            onDeleteSuccess={this.onDeleteSuccess}
            onEditImage={this.fetchBannerData}
          />
        )
      };
    }

    return [
      {
        title: t('BannerDetails.infos'),
        view: (
          <BannerDetailsInfosTab
            data={data}
            bannersSets={bannersSets}
            setLoading={this.setLoading}
            onBannerEdit={this.onBannerEdit}
            properties={properties}
          />
        )
      },
      imageTab
    ];
  };

  getUploadImagePromise = (file, type) => {
    const { bannerId } = this.props;
    return uploadBannerImage(bannerId, type, file);
  };

  loadBannerData = (resolve, response) => {
    const { data: bannerData } = response[0].data;
    const { data: bannersSets } = response[1].data;
    const { properties } = response[2].data.data;
    const state = {};

    this.setDocumentTitle(bannerData);

    if (this.imageDeleting && bannerData.image && bannerData.image.length === 0) {
      state.activeTab = '1';
    }

    Object.assign(state, {
      bannersSets,
      data: bannerData,
      dataLoaded: true,
      loadingMessage: '',
      properties
    });

    this.setState(state, this.onEditFinish.bind(this, resolve));

    this.imageDeleting = false;
  };

  mapDeleteImagePromise = (image) => {
    const { bannerId } = this.props;
    return deleteBannerImage(bannerId, image?.id);
  };

  onBannerEdit = () => {
    return new Promise(this.fetchBannerData);
  };

  onDeleteBannerFailure = () => {
    this.setLoading('');
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('BannerDetails.bannerDeletionFailureMsg')
    });
  };

  onDeleteBannerSuccess = () => {
    this.setLoading('');
    showBanners();
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t('BannerDetails.bannerDeletionSuccessMsg')
    });
  };

  onDeleteSuccess = () => {
    this.imageDeleting = true;
    this.fetchBannerData();
  };

  onEditFinish = (resolve) => {
    if (resolve) {
      const { data } = this.state;
      resolve(data);
    }
  };

  onRequestFailure = (reject, error) => {
    LogController.logError(error);
    if (reject) {
      reject();
    }
  };

  renderTitle = () => {
    const { t } = this.props;
    const { data = {} } = this.state;

    const routes = [{
      url: getBannersBaseRoute(),
      text: t('BannerDetails.banners')
    }];

    if (data.title) {
      routes.push({ text: data.title })
    }

    return (
      <BaseNavigationItem routes={routes}>
        {this.getBannerActions()}
      </BaseNavigationItem>
    );
  };

  replaceBannerPicture = (file, type) => {
    return uploadBannerImage(this.bannerId, type, file);
  };

  replaceBannerPictureFinished = () => {
    this.fetchBannerData();
    this.props.onBannerEdit();
  };

  showReplaceBannerPicture = () => {
    const modal = <ReplacePictureModal
      bannerId={this.bannerId}
      uploadImages={this.replaceBannerPicture}
      onUploadFinished={this.replaceBannerPictureFinished}
    />;

    ModalController.showModal(modal);
  };

  setDocumentTitle = (data) => {
    const title = this.props.t('BannerDetails.documentTitle', { name: data.title });
    DocumentTitleController.setDocumentTitle(title);
  };

  setLoading = (loadingMessage) => {
    this.setState({
      loadingMessage: loadingMessage
    });
  };

  showDeleteBannerConfirmationModal = () => {
    const { t } = this.props;
    const { data } = this.state;
    const { banners_sets = [] } = data;

    let deleteQuestion = t('BannerDetails.deleteQuestion');
    if (banners_sets.length !== 0) {
      deleteQuestion = t('BannerDetails.deleteFromSetsQuestion');
    }

    const modal = (
      <ConfirmationModal title={t('BannerDetails.confirmDelete')}
        message={deleteQuestion}
        yesBtnText={t('BannerDetails.deleteBanner')}
        noBtnText={t('BannerDetails.cancel')}
        confirm={this.deleteBanner.bind(this)} />
    );
    ModalController.showModal(modal);
  };

  uploadImages = () => {
    const modal = (
      <UploadBannerImagesModal uploadImages={this.getUploadImagePromise}
        onUploadFinished={this.fetchBannerData} />
    );
    ModalController.showModal(modal);
  };

  render() {
    const { loadingMessage } = this.state;
    return (
      <LoadingWrapper className="BannerDetails"
        dataLoaded={this.state.dataLoaded}
        isLoading={!!loadingMessage}
        loadingMsg={loadingMessage}>
        {this.renderTitle()}
        <TabsComponent
          activeKey={this.state.activeTab}
          onChange={this.changeTab}
        >
          {this.getTabs()}
        </TabsComponent>
      </LoadingWrapper>
    );
  };
}

export default withTranslation()(BannerDetails);
