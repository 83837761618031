import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Table, Dropdown, Icon, Menu } from "antd";
import PropTypes from 'prop-types';

import './RoyaltiesUserInfo.scss';
import { toggleRoyaltiesUserEnabled } from "../../../../../../../../../../../services/users-service/users.service";
import { getRoyaltiesUsersPage } from "../../../../../../../../../../../services/royalties-service/royalties.service";
import BaseCard from "../../../../../../../../../../../components/BaseCard/BaseCard";

import {
  displayErrorNotification
} from "../../../../../../../../../../../services/notification-service/notification.service";
class RoyaltiesUserInfo extends Component {

  generalColumns = [{
    dataIndex: 'name',
    width: 150
  }, {
    dataIndex: 'value'
  }];

  propertiesColumns = [{
    dataIndex: 'id',
    title: this.props.t('RoyaltiesUserInfo.id')
  }, {
    dataIndex: 'name',
    title: this.props.t('RoyaltiesUserInfo.property')
  }
  ];

  isUserEnabled = (item) => {
    return item.enable || item.status;
  };

  renderEnableDisableDropdown = (item) => {
    const userId = item.id;
    return (
      <div className="StatusItem">
        <Dropdown overlay={this.getEnableDisableDropdownMenu(userId)}>
          <Button className={this.isUserEnabled(item) ? 'UserEnabled' : 'UserDisabled'}>
            {this.isUserEnabled(item) ? 'Enabled' : 'Disabled'}
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
    );
  };

  setUserEnabled = (enable, userId) => {
    this.setState({
      loading: true
    });

    toggleRoyaltiesUserEnabled(userId, enable)
      .then(getRoyaltiesUsersPage(1))
      .then(this.onSetUserStatusSuccess)
      .catch(this.onSetUserStatusFailure.bind(this, enable));
  }

  onSetUserStatusFailure = (enabled, error) => {
    this.setState({
      loading: false
    });

    const msg = enabled === 1 ? 'Enable Failed' : 'Disable Failed';
    displayErrorNotification({
      duration: 3,
      message: msg
    });
  };

  getEnableDisableDropdownMenu = userId => {

    return (
      <Menu className="UsersViewBody-enabledDisabled">
        <Menu.Item>
          <div
            onClick={this.props.setUserEnabled.bind(this, 1, userId)}
            className="UsersViewBody-enabled"
          >
            Enabled
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={this.props.setUserEnabled.bind(this, 0, userId)}
            className="UsersViewBody-disabled"
          >
            Disabled
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  getPropertiesMinViewTableData = () => {
    const { data } = this.props;
    return [{
      name: 'User ID',
      value: data.id,
      key: 1
    }, {
      name: 'User Name',
      value: data.username,
      key: 2
    }, {
      name: 'Enabled',
      value: this.renderEnableDisableDropdown(data),
      key: 3
    }, {
      name: 'Licensor Name',
      value: data.licensor.name,
      key: 4
    }, {
      name: 'Licensor ID',
      value: data.licensor.id,
      key: 5
    }];
  };

  renderPropertiesMinTable = () => {
    const data = this.getPropertiesMinViewTableData();
    return this.renderTable(data, this.generalColumns, false);
  };

  renderTable = (data, columns, showHeader) => {
    return (
      <Table dataSource={data}
        bordered
        columns={columns}
        pagination={false}
        showHeader={showHeader} />
    );
  };

  render() {
    return (
      <div className="RoyaltiesUserInfo">
        {/* <BaseCard title={t('RoyaltiesUserInfo.accountInfo')}>
          {this.renderAccountUserInfoTable()}
        </BaseCard> */}
        <BaseCard title="User Info">
          {/* this.renderPropertiesMaxTable()*/}
          {this.renderPropertiesMinTable()}
        </BaseCard>
      </div>
    );
  }
}

RoyaltiesUserInfo.propTypes = {
  data: PropTypes.object.isRequired
};

export default withTranslation()(RoyaltiesUserInfo);
