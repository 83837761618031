import React, { Component } from 'react';
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import { LogController } from "../../../../../../../../controllers/log-controller/log.controller";

import { assignViewTimeToProperty, getUnassignedKeysCancelable } from '../../../../../../../../services/nats-service/nats.service';
import { Button, Checkbox, DatePicker, Icon, Input, List, Select, Table } from 'antd';
import BaseCard from '../../../../../../../../components/BaseCard/BaseCard';
import { convertMomentToDate, momentFormats } from '../../../../../../../../services/date-service/date.service';
import { getScrollHeight } from '../../../../../../../../services/util-service/util.service';
import { Link } from '@reach/router';
import { getMovieDetailsRoute } from "../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";
import BaseFooter from '../../../../../../../../components/BaseFooter/BaseFooter';
import moment from 'moment';
import LoadingWrapper from '../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import "./StreamingUsers.scss";
import { getProperties } from '../../../../../../../../services/properties-service/properties.service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../services/notification-service/notification.service';
import { ModalDialog } from '../../../../../../../../components/modal/ModalDialog/ModalDialog';
import { ModalController } from '../../../../../../../../controllers/modal-controller/modal.controller';

export const UserTypeFilters = Object.freeze({
    all: 0,
    adminAndTest: 1,
    registred: 2
});

class StreamingUsers extends Component {

    userTypeFilters = [{
        name: this.props.t('StreamingUsers.all'),
        value: UserTypeFilters.all
    }, {
        name: this.props.t('StreamingUsers.adminAndTest'),
        value: UserTypeFilters.adminAndTest
    }, {
        name: this.props.t('StreamingUsers.registred'),
        value: UserTypeFilters.registred
    }];

    state = {
        user_type: UserTypeFilters.all,
        streaming_key: '',
        view_time_min: '',
        filterToggled: false,
        start_date: null,
        end_date: null,

        currentPage: 1,
        dataLoaded: false,
        loading: false,
        recordCount: 0,
        pageCount: 0,
        pageSize: 0,
        unassigned: [],

        properties: []
    };

    tableRowHeight = 65;

    getCardTableData = (item) => {
        const { t } = this.props;
        return [{
            name: `${t('StreamingUsers.movies_id')}:`,
            value: item.movies_id
        }, {
            name: `${t('StreamingUsers.movie_title')}:`,
            value: this.renderMovieTitle(item)
        }, {
            name: `${t('StreamingUsers.seconds_viewed')}:`,
            value: item.seconds_viewed
        }, {
            name: `${t('StreamingUsers.streaming_key')}:`,
            value: item.streaming_key
        }, {
            name: `${t('StreamingUsers.is_test_user')}:`,
            value: this.renderCheckBox(item.is_test_user)
        }, {
            name: `${t('StreamingUsers.viewed_at')}:`,
            value: this.renderDate(item.viewed_at)
        }, {
            name: `${t('StreamingUsers.assign')}:`,
            value: this.renderAssignButton(item.id)
        }];
    };

    renderDate = (date) => {
        let render = '';
        if (date) {
            render = moment(date).format(momentFormats.dateYearFirstTime);
        }
        return render;
    };

    renderNatsStatus = (text, item) => {
        let value = 'N/A';
        switch (text) {
            case '0':
                value = 'NonMember';
                break;
            case '1':
                value = 'Active';
                break;
            case '2':
                value = 'Ended';
                break;
            case '3':
            default:
                break;
        }
        return value;
    };

    renderMovieLink = (data) => {
        const url = getMovieDetailsRoute(data);
        return <Link to={url} key={data} >
            {data}
        </Link>
    };

    renderCheckBox = (value) => {
        return <Checkbox checked={!!value}
            value={value}
            onChange={null}
        />;
    };

    closeModal = () => {
        ModalController.removeModal();
    };

    getActions = (view_time_id, property_id) => {
        const { t } = this.props;
        return ([
            <Button onClick={this.closeModal} key="cancelBtn">
                {t('ChangeAltTagModal.cancel')}
            </Button>,
            <Button onClick={this.onAssignConfirmed.bind(this, view_time_id, property_id)}
                key="saveBtn">
                {t('ChangeAltTagModal.saveChanges')}
            </Button>
        ]);
    };

    onAssignConfirmed = (view_time_id, property_id) => {
        this.setState({
            loading: true
        });
        const { t } = this.props;
        assignViewTimeToProperty(view_time_id, property_id).then(() => {
            displaySuccessNotification({
                duration: 3,
                message: t('StreamingUsers.assignSuccess')
            });
            this.setState({
                loading: false
            }, this.onFilterChange);
        }).catch(() => {
            displayErrorNotification({
                duration: 3,
                message: t('StreamingUsers.assignFail')
            });
            this.setState({
                loading: false
            }, this.onFilterChange);
        });
        this.closeModal();
    };

    onAssign = (view_time_id, property_id) => {
        const property = this.state.properties.find((prop) => prop.id === property_id);
        const { t } = this.props;
        const modal = (
            <ModalDialog
                title={t('StreamingUsers.confirmAssignTitle', { name: property?.name })}
                actions={this.getActions(view_time_id, property_id)}
                forwardedRef={this.modalRef}
            >
                <div style={{
                    display: 'flex',
                    padding: '10px'
                }}>{t('StreamingUsers.assignMessage', { name: property?.name })}</div>
            </ModalDialog>
        );
        ModalController.showModal(modal);
    };

    columns = [{
        align: 'center',
        dataIndex: 'movies_id',
        title: this.props.t('StreamingUsers.movies_id'),
        render: this.renderMovieLink,
        width: 150
    }, {
        dataIndex: 'movie_title',
        title: this.props.t('StreamingUsers.movie_title'),
        render: this.renderMovieTitle,
        width: 300
    }, {
        align: 'right',
        dataIndex: 'seconds_viewed',
        title: this.props.t('StreamingUsers.seconds_viewed'),
        width: 150
    }, {
        dataIndex: 'streaming_key',
        title: this.props.t('StreamingUsers.streaming_key'),
        width: 450
    }, {
        align: 'center',
        dataIndex: 'is_test_user',
        title: this.props.t('StreamingUsers.is_test_user'),
        render: this.renderCheckBox,
        width: 100
    }, {
        align: 'center',
        dataIndex: 'viewed_at',
        title: this.props.t('StreamingUsers.viewed_at'),
        render: this.renderDate,
        width: 200
    }, {
        align: 'center',
        dataIndex: 'id',
        title: 'Assigned',
        render: (id) => {
            return this.renderAssignButton(id);
        },
        width: 250
    }];

    renderAssignButton = (id) => {
        return (
            <Select
                width={250}
                showArrow
                className="UsersViewHeader-select"
                onChange={this.onAssign.bind(this, id)}
                value={'N/A'}>
                {this.state.properties.map((prop) => ({ name: prop.name, value: prop.id })).map(this.renderFilterItem)}
            </Select>
        )
    };

    renderMovieTitle = ({ movie }) => {
        return movie ? (movie.titleNs || movie.title) : 'Removed';
    };

    calculateTableScroll = () => {
        const { contentHeight } = this.props;
        const { unassigned = [] } = this.state;
        const reductionHeight = 52 + 54 + 52;
        return getScrollHeight(contentHeight, unassigned, this.tableRowHeight, reductionHeight);
    };

    renderFooter = () => {
        const { currentPage, pageCount, pageSize, recordCount } = this.state;
        return (
            <BaseFooter className="UsersViewFooter"
                currentPage={currentPage}
                onChange={this.onPageChange}
                pageCount={pageCount}
                pageSize={pageSize}
                recordCount={recordCount}
            />
        );
    };

    renderListItem = (item) => {
        const { t } = this.props;
        const columns = [{
            dataIndex: 'name',
            key: 'name'
        }, {
            align: 'center',
            dataIndex: 'value',
            key: 'value'
        }];

        return (
            <BaseCard
                key={item.id}
                title={`${t('StreamingUsers.streaming_key')}: ${item.streaming_key}`}
            >
                <Table
                    dataSource={this.getCardTableData(item)}
                    rowKey={'name'}
                    showHeader={false}
                    pagination={false}
                    columns={columns}
                    bordered
                />
            </BaseCard>
        );
    };

    renderUsersList = () => {
        return (
            <List
                className="ListView"
                dataSource={this.state.unassigned}
                renderItem={this.renderListItem}
                split={false}
                itemLayout="vertical"
            />
        );
    };

    renderUsersTable = () => {
        return (
            <Table
                className="UsersTable"
                dataSource={this.state.unassigned}
                rowKey={"id"}
                bordered
                columns={this.columns}
                pagination={false}
                scroll={this.calculateTableScroll()}
            />
        );
    };

    onDisplayChange = (user_type) => {
        this.setState({
            user_type
        }, this.onFilterChange);
    };


    renderDisplayFilter = () => {
        const { t } = this.props;
        return (
            <div className="UsersViewHeader-filterWrapper">
                <div className="UsersViewHeader-filterLabel">
                    {t('StreamingUsers.user_type')}:
                </div>
                <Select
                    showArrow
                    className="UsersViewHeader-select"
                    onChange={this.onDisplayChange}
                    value={this.state.user_type}>
                    {this.userTypeFilters.map(this.renderFilterItem)}
                </Select>
            </div>
        );
    };

    renderFilterItem = (item) => {
        return (
            <Select.Option key={item.name} value={item.value}>
                <div className="UsersViewHeaderFilterItemWrapper">
                    {item.name}
                </div>
            </Select.Option>
        );
    };

    clearFilter = () => {
        this.setState({
            user_type: UserTypeFilters.all,
            streaming_key: '',
            view_time_min: '',
            start_date: null,
            end_date: null
        }, this.onFilterChange);
    };

    renderClearFilterBtn = () => {
        const { t } = this.props;
        return (
            <Button onClick={this.clearFilter}>
                {t('UsersViewHeader.clearFilter')}
            </Button>
        );
    };

    onSearch = (event) => {
        const { value } = event.target;
        this.setState({
            streaming_key: value
        });
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(this.onFilterChange, 300);
    };

    renderSearchStreamingKey = () => {
        const { t } = this.props;
        return (
            <div className="UsersViewHeader-filterWrapper">
                <div className="UsersViewHeader-filterLabel">
                    {t('StreamingUsers.searchStreamingKey')}:
                </div>
                <Input
                    value={this.state.streaming_key}
                    onChange={this.onSearch}
                />
            </div>
        );
    };

    onMinViewTimeSearch = (event) => {
        const { value } = event.target;
        this.setState({
            view_time_min: Number(value) || 0
        });
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(this.onFilterChange, 300);
    };

    renderSearchMinViewSeconds = () => {
        const { t } = this.props;
        return (
            <div className="UsersViewHeader-filterWrapper">
                <div className="UsersViewHeader-filterLabel">
                    {t('StreamingUsers.searchMinViewSeconds')}:
                </div>
                <Input
                    type='number'
                    min={0}
                    step={1}
                    value={this.state.view_time_min}
                    onChange={this.onMinViewTimeSearch}
                />
            </div>
        );
    };

    toggleFilters = () => {
        this.setState(prevState => {
            return {
                filterToggled: !prevState.filterToggled
            };
        });
    };

    renderSearchToggle = () => {
        const { t } = this.props;
        return (
            <div className="UsersViewHeader-inner">
                {t('UsersViewHeader.filters')}:
                <Icon type={this.state.filterToggled ? 'up' : 'down'}
                    onClick={this.toggleFilters} />
            </div>
        );
    };


    handleChange = (picker, date) => {
        this.setState({
            [picker]: date
        }, this.onFilterChange);
    };

    renderTimePicker = (label, value, name, disabledDateFn, onChange) => {
        return (
            <div className="UsersViewHeader-filterWrapper">
                <div className="UsersViewHeader-filterLabel">{label}:</div>
                <DatePicker
                    // showTime={true}
                    format={'MMMM Do YYYY HH:mm'}
                    showTime={{
                        format: 'HH:mm'
                    }}
                    allowClear={false}
                    name={name}
                    disabledDate={disabledDateFn}
                    value={value}
                    onChange={onChange} />
            </div>
        );
    };

    renderStartDate = () => {
        const { t } = this.props;
        const { start_date } = this.state;
        return this.renderTimePicker(
            t(`StreamingUsers.start`), start_date, 'startPicker',
            this.disabledStartDate, this.handleChange.bind(this, 'start_date')
        );
    };

    renderEndDate = () => {
        const { t } = this.props;
        const { end_date } = this.state;
        return this.renderTimePicker(
            t(`StreamingUsers.end`), end_date, 'endPicker',
            this.disabledEndDate, this.handleChange.bind(this, 'end_date')
        );
    };
    renderRange = () => {
        return (
            <>
                {this.renderStartDate()}
                {this.renderEndDate()}
            </>
        )
    };

    renderHeader = () => {
        const classes = ['UsersViewHeader-mainWrapper'];
        if (this.state.filterToggled) {
            classes.push('FiltersToggled');
        }
        return (
            <div className="UsersViewHeader">
                {this.renderSearchToggle()}
                <div className={classes.join(' ')}>
                    {this.renderSearchStreamingKey()}
                    {this.renderSearchMinViewSeconds()}
                    {this.renderDisplayFilter()}
                    {this.renderRange()}
                    {this.renderClearFilterBtn()}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="StreamingUsers">
                {this.renderHeader()}
                <LoadingWrapper
                    className='StreamingUsers-inner'
                    dataLoaded={this.state.dataLoaded}
                    isLoading={this.state.loading}
                    loadingMsg={this.props.t('StreamingUsers.loading')}>
                    <div className="StreamingUsers">
                        {this.renderUsersTable()}
                        {this.renderUsersList()}
                    </div>
                </LoadingWrapper>

                {this.renderFooter()}
            </div>
        );
    }

    componentDidMount() {
        getProperties().then((response) => {
            const { properties } = response.data.data;
            this.setState({
                properties
            }, this.onFilterChange);
        });
    }

    getStreamingKeys = (page) => {
        this.setState({
            loading: true
        });

        const { user_type, start_date, end_date, view_time_min, streaming_key } = this.state;
        let forrmatedStartDate = start_date;
        if (forrmatedStartDate) {
            forrmatedStartDate = forrmatedStartDate.seconds(0);
            forrmatedStartDate = convertMomentToDate(forrmatedStartDate, true, true);
        }
        let forrmatedEndDate = end_date;
        if (forrmatedEndDate) {
            forrmatedEndDate = forrmatedEndDate.seconds(0);
            forrmatedEndDate = convertMomentToDate(forrmatedEndDate, true, true);
        }
        const { result, controller } = getUnassignedKeysCancelable(page, {
            user_type,
            start_date: forrmatedStartDate,
            end_date: forrmatedEndDate,
            view_time_min: Number(view_time_min),
            streaming_key: streaming_key?.length ? streaming_key : undefined
        });
        if (this.lastRequestController) {
            this.lastRequestController.abort();
        }
        this.lastRequestController = controller;
        result.then(this.loadData)
            .catch(this.loadData)
    };

    getDocumentTitle = () => {
        return this.props.t('StreamingUsers.documentTitle');
    };

    loadData = (response = {}) => {
        const { unassigned = [], pagination = {} } = response.data?.data || {};
        this.setState({
            currentPage: pagination.current_page,
            dataLoaded: true,
            loading: false,
            pageCount: pagination.last_page,
            pageSize: pagination.per_page,
            recordCount: pagination.total,
            unassigned: unassigned.map((item) => {
                const { movie } = item;
                if (movie) {
                    item.movie_title = movie.titleNs || movie.title
                }
                return item;
            })
        });
    };

    onFilterChange = () => {
        this.getStreamingKeys(1);
    };

    onGetUsersFailure = (error) => {
        LogController.logError(error);
    };

    onPageChange = (page) => {
        this.setState({
            currentPage: page
        });
        this.getStreamingKeys(page);
    };

    parseFilteredData = ({ unassigned, pagination }) => {
        return {
            unassigned,
            pagination: {
                currentPage: pagination.current_page,
                perPage: pagination.per_page,
                totalPages: pagination.to,
                total: pagination.total
            }
        };
    };
}

export default compose(withTranslation(), withDocumentTitleUpdate)(StreamingUsers);
