import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {Input, Select} from 'antd';

import './StarFilters.scss';

class StarFilters extends Component {
  state = {
    filters: {
      search: '',
      display: ''
    }
  };

  starNameFilterDebounce = 500;

  timeoutId;

  constructor(props) {
    super(props);

    Object.assign(this.state, {
      filters: {
        search: props.defaultNameFilter,
        display: props.defaultDisplayFilter
      }
    });
  }

  callPropsFilterChange = () => {
    this.props.onFilterChange(this.state.filters);
  };

  onDisplayChange = status => {
    this.setState(prevState => {
      return {
        filters: {
          ...prevState.filters,
          display: status
        }
      };
    }, this.callPropsFilterChange);
  };

  onStarNameChange = event => {
    const search = event.target.value;
    this.setState(prevState => {
      return {
        filters: {
          ...prevState.filters,
          search: search
        }
      };
    });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(
      this.callPropsFilterChange,
      this.starNameFilterDebounce
    );
  };

  renderDisplayFilterItem = item => {
    const {t} = this.props;
    return (
      <Select.Option key={item} value={item}>
        <div>{t(`StarFilters.${item}`)}</div>
      </Select.Option>
    );
  };

  render() {
    const {t} = this.props;
    return (
      <div className='StarFiltersWrapper'>
        <div className='InputWrapper'>
          <div>{t('StarFilters.searchByStarName')}:</div>
          <Input
            value={this.state.filters.search}
            onChange={this.onStarNameChange}
            allowClear
          />
        </div>
        <div className='InputWrapper'>
          <div>{t('StarFilters.display')}:</div>
          <Select
            showArrow
            onChange={this.onDisplayChange}
            value={this.state.filters.display}>
            {this.props.generalLinks.map(this.renderDisplayFilterItem)}
          </Select>
        </div>
      </div>
    );
  }
}

StarFilters.propTypes = {
  defaultDisplayFilter: PropTypes.string.isRequired,
  defaultNameFilter: PropTypes.string,
  generalLinks: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired
};

export default withTranslation()(StarFilters);
