import React, {Component} from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

export class LoadingWrapper extends Component {

  renderLoadingIndicator = (isLoading, loadingMsg) => {
    return isLoading ? this.renderSpinner(loadingMsg, 'Loading') : null;
  };

  renderMainView = () => {
    const {dataLoaded, isLoading, loadingMsg} = this.props;
    let view;
    if (dataLoaded) {
      view = (
        <React.Fragment>
          {this.props.children}
          {this.renderLoadingIndicator(isLoading, loadingMsg)}
        </React.Fragment>
      );
    } else {
      view = this.renderSpinner(loadingMsg);
    }

    return view;
  };

  renderSpinner = (message, className) => {
    return message ? (
      <LoadingIndicator message={message}
                        className={className}/>
    ) : null;
  };

  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        {this.renderMainView()}
      </div>
    );
  }
}

LoadingWrapper.propTypes = {
  className: PropTypes.string,
  dataLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingMsg: PropTypes.string,
  style: PropTypes.object
};

export default LoadingWrapper;
