import React, {Component} from 'react';
import {Router} from "@reach/router";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {withDocumentTitleUpdate} from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import {LogController} from '../../../../../../../../controllers/log-controller/log.controller';

import {getMails} from '../../../../../../../../services/mail-service/mail.service';

import MailMainView from './MailMainView/MailMainView';

import './MailView.scss';

class MailView extends Component {
  
  state = {
    data: [],
    dataLoaded: false,
    loading: false
  };

  componentDidMount() {
    this.fetchMails();
  };

  fetchMails = () => {
    this.setLoading(true);

    getMails()
      .then(this.loadData)
      .catch(this.onGetMailsFailure);
  };

  getDocumentTitle = () => {
    return this.props.t('MailView.documentTitle');
  };

  loadData = (response) => {
    const {data} = response.data;
    this.setState({
      data: data[0],
      dataLoaded: true
    });

    this.setLoading(false);
  };

  onGetMailsFailure = error => {
    this.setLoading(false);
    LogController.logError(error);
  };

  setLoading = (loading) => {
    this.setState({
      loading: loading
    });
  };

  render() {
    return (
      <div className="MailView">
        <Router>
          <MailMainView 
            path={"/"}
            listInfo={this.state}
            contentHeight={this.props.contentHeight}
          />
        </Router>
      </div>
    );
  }
}

export default compose(withTranslation(), withDocumentTitleUpdate)(MailView);
