import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {Button, Collapse, Icon} from "antd";

import ConfigurePropertyAdsModal from "../PropertiesAds/ConfigurePropertyAdsModal";
import ScenesCollapsePanel from "../ScenesCollapsePanel/ScenesCollapsePanel";

import {ConfigureMoviesAdsController} from "../ConfigureMoviesAdsController";
import {ModalController} from "../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import {stopEvent} from "../../../../../../../../../../../../services/util-service/util.service";

import CollapsiblePanel from "../../../shared/CollapsiblePanel/CollapsiblePanel";

import './PropertiesCollapsePanel.scss';
import ConfigurationWillBeRemoved from "../../../../ConfigurationWillBeRemoved/ConfigurationWillBeRemoved";

/* istanbul ignore file */
class PropertiesCollapsePanel extends Component {

  expandSubscription;

  state = {
    activeProperties: [],
    properties: {},
    validationTriggered: false
  };

  subscriptions = {};

  constructor(props) {
    super(props);
    this.subscriptions.expandSubscription = ConfigureMoviesAdsController.expandEvent.subscribe(this.setExpanded);
    this.subscriptions.validation = ConfigureMoviesAdsController.triggerValidationEvent.subscribe(this.triggerValidation);
  }

  static samePropertiesObjects(properties, oldProperties) {
    let sameObject = Object.keys(properties).length === Object.keys(oldProperties).length;
    if (sameObject) {
      for (const key in properties) {
        if (!oldProperties[key]) {
          sameObject = true;
          break;
        }
      }
    }

    return sameObject;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {properties: prevProperties} = prevState;
    const {properties} = nextProps;
    const state = {properties};
    if (!PropertiesCollapsePanel.samePropertiesObjects(properties, prevProperties)) {
      state.activeProperties = Object.keys(properties);
    }
    return state;
  }

  componentWillUnmount() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
  }

  displayAdsConfigModal = (propertyId, propertyName, ads, event) => {
    stopEvent(event);
    const {infos} = this.props.movie;
    const props = {
      ads,
      movieDetails: infos.details,
      onAdsConfigured: this.onAdsConfigured,
      propertyId,
      propertyName
    };
    const modal = (
      <ConfigurePropertyAdsModal {...props}/>
    );
    ModalController.showModal(modal);
  };

  getPropertyCardTitle = (propertyId, propertyName, scenes) => {
    const {t} = this.props;
    return (
      <div className="Header">
        <div className="PropertyName">
          {t('PropertiesCollapsePanel.propertyNameTxt', {name: propertyName})}
        </div>
        <div className="Section2">
          <Button
            onClick={this.displayAdsConfigModal.bind(this, propertyId, propertyName, scenes)}>
            {t('PropertiesCollapsePanel.configurePropertyAds')}
          </Button>
          <Icon type="close" onClick={this.removeProperty.bind(this, propertyId)}/>
        </div>
      </div>
    );
  };

  onActivePanelChange = (activeProperties) => {
    this.setState({activeProperties});
  };

  onAdsConfigured = ({ads, movieId, propertyId}) => {
    const {onConfigurePropertyAds} = this.props;
    onConfigurePropertyAds({
      ads,
      movieId,
      propertyId
    });
  };

  onRemoveScene = (propertyId, sceneId) => {
    const {onRemoveScene} = this.props;
    onRemoveScene(propertyId, sceneId);
  };

  onRemoveSceneAd = (propertyId, sceneId, adIndex) => {
    const {onRemoveSceneAd} = this.props;
    onRemoveSceneAd(propertyId, sceneId, adIndex);
  };

  onSceneAdsChange = (propertyId, sceneId, ads) => {
    const {onSceneAdsChange} = this.props;
    onSceneAdsChange(propertyId, sceneId, ads);
  };

  onUpdateSceneAdData = (propertyId, sceneId, index, data) => {
    const {onUpdateSceneAdData} = this.props;
    onUpdateSceneAdData(propertyId, sceneId, index, data);
  };

  propertyMap = ({deleteAll, name: propertyName, id: propertyId, scenes}) => {
    const {validationTriggered} = this.state;
    const {invalidKeys} = this.props;
    const classes = ['Property'];
    const adsConfigured = scenes && Object.keys(scenes).length;
    if (adsConfigured && validationTriggered) {
      const additionalClass = invalidKeys[propertyId] ? 'Invalid' : 'Valid';
      classes.push(additionalClass);
    }
    return (
      <Collapse.Panel header={this.getPropertyCardTitle(propertyId, propertyName, scenes)}
                      key={propertyId}
                      className={classes.join(' ')}>
        {this.renderPropertyContent(propertyId, scenes, adsConfigured, deleteAll)}
      </Collapse.Panel>
    );
  };

  renderNoPropertySelected = () => {
    const {t} = this.props;
    return (
      <div className="NoPropertySelected">
        {t('PropertiesCollapsePanel.noPropertiesSelected')}
      </div>
    );
  };

  renderNoSceneAdsConfig = () => {
    const {t} = this.props;
    return (
      <div className="NoSceneAdsConfig">
        {t('PropertiesCollapsePanel.noSceneAdsConfig')}
      </div>
    );
  };

  renderProperties = () => {
    const {properties} = this.state;
    const data = Object.values(properties);
    const initialActiveKeys = Object.keys(properties);
    const {activeProperties} = this.state;
    return (
      <CollapsiblePanel defaultActiveKeys={initialActiveKeys}
                        activeKeys={activeProperties}
                        onChange={this.onActivePanelChange}>
        {data.map(this.propertyMap)}
      </CollapsiblePanel>
    );
  };

  renderPropertyContent = (propertyId, scenes, adsConfigured, deleteAll) => {
    let view;
    if (deleteAll) {
      view = (
        <ConfigurationWillBeRemoved isFullRemoval={true}/>
      );
    } else if (adsConfigured) {
      view = this.renderPropertyScenes(propertyId, scenes);
    } else {
      view = this.renderNoSceneAdsConfig();
    }

    return view;
  };

  removeProperty = (propertyId, event) => {
    stopEvent(event);
    const {onRemoveProperty} = this.props;
    onRemoveProperty(propertyId);
  };

  renderPropertyScenes = (propertyId, scenesAds) => {
    const {deletedScenes, invalidKeys, movie} = this.props;
    const {details} = movie.infos;
    let view = null;
    if (details) {
      const {runTime, scenes: movieScenes} = details;
      view = (
        <ScenesCollapsePanel movieScenes={movieScenes}
                             deletedScenes={deletedScenes}
                             invalidKeys={invalidKeys[propertyId] ? invalidKeys[propertyId].scenes : {}}
                             movieRunTime={runTime}
                             onRemoveSceneAd={this.onRemoveSceneAd.bind(this, propertyId)}
                             onRemoveScene={this.onRemoveScene.bind(this, propertyId)}
                             onUpdateSceneAdData={this.onUpdateSceneAdData.bind(this, propertyId)}
                             onSceneAdsChange={this.onSceneAdsChange.bind(this, propertyId)}
                             scenesAds={scenesAds}/>
      )
    }

    return view;
  };

  setExpanded = (expand) => {
    const activeProperties = expand ? Object.keys(this.props.properties) : [];
    this.setState({activeProperties});
  };

  triggerValidation = () => {
    this.setState({
      validationTriggered: new Date().getTime()
    });
  };

  render() {
    const {properties} = this.state;
    return (
      <div className="PropertiesCollapsePanel">
        {Object.keys(properties).length ? this.renderProperties() : this.renderNoPropertySelected()}
      </div>
    );
  }
}

PropertiesCollapsePanel.propTypes = {
  deletedScenes: PropTypes.object.isRequired,
  invalidKeys: PropTypes.object,
  movie: PropTypes.object.isRequired,
  onConfigurePropertyAds: PropTypes.func.isRequired,
  onRemoveProperty: PropTypes.func.isRequired,
  onRemoveScene: PropTypes.func.isRequired,
  onRemoveSceneAd: PropTypes.func.isRequired,
  onSceneAdsChange: PropTypes.func.isRequired,
  onUpdateSceneAdData: PropTypes.func.isRequired,
  properties: PropTypes.object.isRequired
};

export default withTranslation()(PropertiesCollapsePanel);
