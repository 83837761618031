import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Icon, InputNumber} from "antd";
import PropTypes from 'prop-types';

import './PageInput.scss';

class PageInput extends Component {

  state = {
    currentPage: 0,
    inputInvalid: false
  };

  getCurrentPageIndex = () => {
    let currentPage = this.state.currentPage;
    if (!currentPage || this.props.currentPage !== this.currentPage) {
      this.currentPage = this.props.currentPage;
      currentPage = this.currentPage;
    }

    return currentPage;
  };

  getInputClasses = () => {
    return this.state.inputInvalid ? ['InvalidInput'] : [];
  };

  onChange = (page) => {
    let isValid = (page === null) || (1 <= page && page <= this.props.pageCount);
    this.setState({
      currentPage: isValid ? page : null,
      inputInvalid: !isValid
    });
  };

  onSearchClick = () => {
    if (!this.state.inputInvalid) {
      this.props.onChange(this.state.currentPage);
    }
  };

  render() {
    const {t} = this.props;
    return (
      <div className="PageInput">
        <div>{t('PageInput.goto')}</div>
        <InputNumber value={this.getCurrentPageIndex()}
                     onChange={this.onChange}
                     className={this.getInputClasses().join(' ')}/>
        <div className="PageCount"> /{this.props.pageCount}</div>
        <Button onClick={this.onSearchClick} shape="circle">
          <Icon type="search"/>
        </Button>
      </div>
    );
  }
}

PageInput.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired
};

export default withTranslation()(PageInput);
