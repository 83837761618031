import React, {Component} from 'react';
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../../components/BaseFooter/BaseFooter";

import './BannersViewFooter.scss';

class BannersViewFooter extends Component {

  /**
   * Returns an object containing base footer component props
   *
   * @returns {Object}
   */
  getBaseFooterProps = () => {
    const {currentPage, onChange, pageCount, pageSize, total} = this.props;
    return {
      currentPage,
      onChange,
      pageCount,
      pageSize,
      recordCount: total
    };
  };

  render() {
    const baseFooterProps = this.getBaseFooterProps();
    return (
      <BaseFooter className='BannersViewFooter' {...baseFooterProps}/>
    );
  }
}

BannersViewFooter.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default BannersViewFooter;
