import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import MoviesViewAbstract from '../../MoviesView/MoviesViewAbstract/MoviesViewAbstract';
import LicensorMoviesMainView from "./LicensorMoviesMainView/LicensorMoviesMainView";

import { getMoviesByLicensorId } from '../../../../../../../../../services/movies-service/movies.service';
import { displayErrorNotification } from '../../../../../../../../../services/notification-service/notification.service';
import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import { getLicensorMoviesRoute } from "../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation-routes.service";

class LicensorMovies extends MoviesViewAbstract {
  licensorId;

  constructor(props) {
    super(props);
    this.licensorId = props.licensorId;
  }

  componentDidMount() {
    super.componentDidMount();
    this.setDocumentTitle();
  }

  fetchMovies = page => {
    const message = this.props.t('MoviesViewAbstract.loadingPleaseWait');
    this.setLoading(message);
    const filters = this.getFilters();
    getMoviesByLicensorId(this.licensorId, page, filters)
      .then(this.loadMovies)
      .catch(this.onFetchMoviesFailure);
  };

  getMoviesRoute = () => {
    const { licensorId } = this.props;
    return getLicensorMoviesRoute(licensorId);
  };

  onFetchMoviesFailure = () => {
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('LicensorMovies.fetchMoviesFailure')
    });
  };

  renderMainViewRoute = () => {
    const props = this.getMainViewProps();
    props.licensorId = this.licensorId;
    return (
      <LicensorMoviesMainView {...props} />
    );
  };

  setDocumentTitle = () => {
    const { licensorName, t } = this.props;
    const title = t('LicensorMovies.documentTitle', { name: licensorName });
    DocumentTitleController.setDocumentTitle(title);
  };
}

LicensorMovies.propTypes = {
  licensorName: PropTypes.string
};

export default withTranslation()(LicensorMovies);
