import React, { Component } from "react";
import { Link, Location } from "@reach/router";
import PropTypes from "prop-types";

import { MainDashboardComController } from "../../pages/MainDashboard/main-dashboard-comm.controller";

import "./BaseHorizontalNavigation.scss";

class BaseHorizontalNavigation extends Component {
	previousSelectedItemIndex = 0;

	isItemSelected = (pathname, link) => {
		return pathname.startsWith(link);
	};

	mapItem = (pathname, item, index) => {
		let classes = ["BaseHorizontalNavigation-item"];
		if (this.isItemSelected(pathname, item.link)) {
			classes.push("selected");
			this.previousSelectedItemIndex = index;
		}
		return (
			<Link
				onClick={this.onNavigationItemClick.bind(this, item, index)}
				className={classes.join(" ")}
				to={item.link}
				key={item.name}
			>
				<div className="BaseHorizontalNavigation-placeholder">
					{item.name}
				</div>
				<div className="BaseHorizontalNavigation-bottomLine" />
			</Link>
		);
	};

	onNavigationItemClick = (item, index) => {
		if (this.props.onNavigationItemClick) {
			this.props.onNavigationLinkClick(item, index);
		}
		item.navigate();
		if (this.props.mainNavigation) {
			this.refreshMainContentPage();
		}
	};

	refreshMainContentPage = () => {
		MainDashboardComController.refreshMainContentPage();
	};

	renderComponent = ({ location }) => {
		const { children, items } = this.props;
		return (
			<div className="BaseHorizontalNavigation">
				<div className="BaseHorizontalNavigation-links">
					{items.length &&
						items.map(this.mapItem.bind(this, location.pathname))}
				</div>
				{children ? (
					<div className="BaseHorizontalNavigation-actions">
						{children}
					</div>
				) : null}
			</div>
		);
	};

	render() {
		return <Location>{this.renderComponent}</Location>;
	}
}

BaseHorizontalNavigation.propTypes = {
	items: PropTypes.array.isRequired,
	mainNavigation: PropTypes.bool,
	onNavigationLinkClick: PropTypes.func,
};

export default BaseHorizontalNavigation;
