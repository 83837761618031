import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';

import BaseFooter from "../../../../../../../../../components/BaseFooter/BaseFooter";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";

import ManufacturersViewBody from "./ManufacturersViewBody/ManufacturersViewBody";

import { getScrollHeight } from "../../../../../../../../../services/util-service/util.service";

import './ManufacturesMainView.scss';
import BaseSort from '../../../../../../../../../components/BaseSort/BaseSort';

class ManufacturesMainView extends Component {

  tableRowHeight = 54;

  /**
   * Returns table scroll height object
   *
   * @returns {Object}
   */
  getScrollHeight = () => {
    const { contentHeight, data } = this.props;
    const reductionHeight = 150;
    return getScrollHeight(contentHeight, data.manufacturers, this.tableRowHeight, reductionHeight);
  };

  /**
   * Returns body JSX element
   *
   * @returns {JSX.Element}
   */
  renderBody = () => {
    const { data, onMFIDEdit } = this.props;
    return (
      <ManufacturersViewBody manufacturers={data.manufacturers}
        scroll={this.getScrollHeight()}
        onMFIDEdit={onMFIDEdit} />
    );
  };

  /**
   * Returns footer JSX element
   *
   * @returns {JSX.Element}
   */
  renderFooter = () => {
    const { data, onPageChange, t } = this.props;
    return (
      <BaseFooter
        currentPage={data.currentPage}
        onChange={onPageChange}
        pageSize={data.pageSize}
        pageCount={data.pagesTotal}
        recordCount={data.totalManufacturers}>
        <div className="ManufacturesMainView-manufacturersCount">
          {t('ManufacturesMainView.manufacturersFound', {
            total: data.totalManufacturers
          })}
        </div>
      </BaseFooter>
    )
  };

  renderFilters = () => {
    const {
      t,
      searchValue,
      onInputSearch,
      onDropdownChange,
      onClearFilters,
      dropdownSort,
      selectOptions
    } = this.props;

    return (
      <div className='FiltersHeader'>
        <label>Search by Name or ID
          <Input
            value={searchValue}
            onChange={onInputSearch}
            className="user-inputs" />
        </label>

        <label>
          <BaseSort
            onValueChange={onDropdownChange}
            defaultSortFilter={selectOptions[0].name}
            sortCriteria={selectOptions}
            value={dropdownSort}
            className="user-inputs"
          />
        </label>

        <Button className="clear-filters" onClick={onClearFilters}>
          {t('UsersViewHeader.clearFilter')}
        </Button>
      </div>
    )
  }

  render() {
    const { t, data } = this.props;
    return (
      <LoadingWrapper className='ManufacturesMainView'
        dataLoaded={data.dataLoaded}
        isLoading={data.loading}
        loadingMsg={t('ManufacturesMainView.loadingPleaseWait')}>
        {this.renderFilters()}
        {this.renderBody()}
        {this.renderFooter()}
      </LoadingWrapper>
    );
  }
}

ManufacturesMainView.propTypes = {
  contentHeight: PropTypes.number,
  data: PropTypes.object.isRequired,
  onMFIDEdit: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default withTranslation()(ManufacturesMainView);
