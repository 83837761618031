import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, List, Table } from 'antd';
import PropTypes from 'prop-types';

import { getManufacturerDetailsRoute } from '../../../../../../../../../../services/navigation/manufacturers-navigation/manufacturers-navigation-routes.service';

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";

import './ManufacturersViewBody.scss';

class ManufacturersViewBody extends Component {

  /**
   * Renders item available actions
   *
   * @param {Object} item
   * @param {Object} data
   *
   * @returns {JSX.Element}
   */
  renderActions = (item, { id }) => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <LinkButton href={getManufacturerDetailsRoute(id)}>
          {t('ManufacturersViewBody.viewDetails')}
        </LinkButton>
        <Button className="EditButton"
          onClick={this.onEdit.bind(this, id)}>
          {t('ManufacturersViewBody.edit')}
        </Button>
      </React.Fragment>
    );
  };

  /**
   * Manufacturers table columns
   *
   * @type {Object[]}
   */
  columns = [{
    dataIndex: 'id',
    title: this.props.t('ManufacturersViewBody.id'),
    width: 200
  }, {
    dataIndex: 'name',
    title: this.props.t('ManufacturersViewBody.name')
  }, {
    dataIndex: 'licensor',
    title: this.props.t('ManufacturersViewBody.licensor'),
    width: '30%'
  }, {
    align: 'center',
    dataIndex: 'actions',
    title: this.props.t('ManufacturersViewBody.actions'),
    render: this.renderActions,
    width: 250
  }];

  /**
   * Returns list item card footer for render
   *
   * @param {Object} item
   *
   * @returns {JSX.Element}
   */
  getListItemCardFooter = (item) => {
    return (
      <div className="ManufacturersViewBody-listItem-footer">
        {this.renderActions(item, item)}
      </div>
    );
  };

  onEdit = (id) => {
    const { onMFIDEdit } = this.props;
    onMFIDEdit(id);
  };

  /**
   * Returns formatted object for manufacturers table
   *
   * @param {Object} manufacturer
   *
   * @returns {Object}
   */
  prepareManufacturersTableData = manufacturer => {
    const { t } = this.props;
    return {
      id: manufacturer.id,
      licensor: manufacturer.licensor ? manufacturer.licensor.name : t('ManufacturersViewBody.notExist'),
      name: manufacturer.name || t('ManufacturersViewBody.notExist')
    }
  };

  /**
   * Returns manufacturers list JSX element for min view display
   *
   * @returns {JSX.Element}
   */
  renderManufacturersList = () => {
    return (
      <List dataSource={this.props.manufacturers}
        renderItem={this.renderManufacturersListItem}
        split={false}
        itemLayout="vertical" />
    );
  };

  /**
   * Returns manufacturers list item JSX element for min view display
   *
   * @returns {JSX.Element}
   */
  renderManufacturersListItem = (item) => {
    const { id, name, licensor } = item;
    const { t } = this.props;
    return (
      <List.Item key={id} type="inner">
        <BaseCard title={`${t('ManufacturersViewBody.manufacturer')}: ${name}`}
          footer={this.getListItemCardFooter(item)}>
          <div className="ListCardWrapper">
            <p>
              <span className="ListItemLabel">{t('ManufacturersViewBody.id')}:</span> {id}
            </p>
            <p>
              <span
                className="ListItemLabel">{t('ManufacturersViewBody.licensor')}:</span> {licensor ? licensor.name : ""}
            </p>
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  /**
   * Returns manufacturers table JSX element for max view display
   *
   * @returns {JSX.Element}
   */
  renderManufacturersTable = () => {
    const { scroll, manufacturers } = this.props;
    const data = manufacturers.map(this.prepareManufacturersTableData);
    return (
      <Table dataSource={data}
        rowKey="id"
        bordered
        columns={this.columns}
        pagination={false}
        scroll={scroll} />
    );
  };

  render() {
    return (
      <div className="ManufacturersViewBody">
        {this.renderManufacturersTable()}
        {this.renderManufacturersList()}
      </div>
    )
  }
}

ManufacturersViewBody.propTypes = {
  manufacturers: PropTypes.array.isRequired,
  onMFIDEdit: PropTypes.func.isRequired,
  scroll: PropTypes.object
};

export default withTranslation()(ManufacturersViewBody);
