import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Input, DatePicker, Select } from 'antd';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import BaseCheckboxList from "../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList"
import ModalDialog from '../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";

import {
  editStudio,
  getStudioById,
  toggleEnableStatus,
  attachStudioCatalogs,
  setStudioControlSwitchers
} from '../../../../../../../../../services/studio-service/studio.service';
import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../services/notification-service/notification.service';
import {
  convertToMoment,
  momentFormats,
  renderMomentFormatted
} from '../../../../../../../../../services/date-service/date.service';
import { getMovieCatalogs } from '../../../../../../../../../services/movies-service/movies.service';

import { areArraysEqual, getOnPropertiesData } from "../../../../../../../../../services/util-service/util.service";
import { getAssociatedControlSwitchers } from '../../../../../../../../../services/control-switchers-service/control-switchers.service';
import SpecialCategoriesSelect from '../EditSpecialCategoriesModal/SpecialCategoriesSelect/SpecialCategoriesSelect';
import { getProperties } from "../../../../../../../../../services/properties-service/properties.service";
import './EditStudioModal.scss';

const { TextArea } = Input;

class EditStudioModal extends Component {
  modalRef = React.createRef();

  state = {};

  studioData = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { studioId, t } = this.props;
    const modal = this.getModal();
    if (modal) {
      const message = t('EditStudioModal.loadingDataMsg')
      modal.setLoading(message);
    }

    const promises = [
      getStudioById(studioId),
      getMovieCatalogs(),
      getAssociatedControlSwitchers({ studios_id: studioId }),
      getProperties()
    ];

    Promise.all(promises)
      .then(this.loadData)
      .catch(this.onLoadDataFailure)
  };

  filterCatalogs = (input, option) => {
    const { children } = option.props;
    return children.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  };

  getActions = () => {
    const { t } = this.props;
    return [
      <Button onClick={this.onCancel.bind(this)} key='cancelBtn'>
        {t('EditStudioModal.cancel')}
      </Button>,
      <Button
        onClick={this.onSaveChanges.bind(this)}
        disabled={!this.hasDataChanged()}
        type='primary'
        key='saveChangesBtn'
      >
        {t('EditStudioModal.saveChanges')}
      </Button>
    ];
  };

  getCatalogAttachPromise = (selectedCatalogs) => {
    let promise;
    if (this.isCatalogChanged()) {
      promise = attachStudioCatalogs(this.props.studioId, selectedCatalogs);
    } else {
      promise = Promise.resolve();
    }
    return promise;
  };

  getItemId = item => {
    return item.id;
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getModalTitle = () => {
    const { name = '' } = this.state;
    const { t } = this.props;
    const title = `${t('EditStudioModal.editStudio')}${name ? ' - ' + name : ''}`;
    return title;
  };

  getStudioStatusUpdatePromise = () => {
    let promise;
    const { status: initialStatus } = this.studioData;
    const { status } = this.state;

    if (initialStatus !== status) {
      const { studioId } = this.props;
      promise = toggleEnableStatus(studioId, status);
    } else {
      promise = Promise.resolve();
    }

    return promise;
  };

  hasDataChanged = () => {
    return JSON.stringify(this.state) !== JSON.stringify(this.studioData);
  };

  isCatalogChanged = () => {
    return !areArraysEqual(this.state.selectedCatalogs, this.studioData.selectedCatalogs);
  };

  loadData = response => {
    const {
      name,
      foundedDate,
      descriptionNs,
      status,
      movie_catalogs,
      studios_on_properties_data = []
    } = response[0].data.data;
    const selectedCatalogs = movie_catalogs.map(this.getItemId);
    const { data } = response[1].data;
    const { data: specialCategories } = response[2].data;
    const selectedSpecialCategories = specialCategories.filter(item => item.studios_id).map(item => item.id);
    const { properties } = response[3].data.data;
    const { downloadableOnProperties = [] } = getOnPropertiesData(studios_on_properties_data, properties);

    const state = {
      name,
      foundedDate: convertToMoment(foundedDate),
      descriptionNs,
      status,
      catalogs: data,
      selectedCatalogs,
      specialCategories,
      selectedSpecialCategories,
      downloadableOnProperties: downloadableOnProperties.map(item => item.id),
      properties
    };
    Object.assign(this.studioData, cloneDeep(state));
    this.setState(state);
    const modal = this.getModal();
    if (modal) {
      modal.clearLoading();
    }
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onCheckboxChange = (key, values) => {
    this.setState({
      [key]: values
    });
  };

  onChange = (key, event) => {
    const value = event?.target ? event?.target.value || (event?.target.checked ? 1 : 0) : event;
    this.setState({
      [key]: value
    });
  };

  onSelect = (activeItemsIds) => {
    this.setState(prevState => {
      if (prevState.selectedSpecialCategories !== activeItemsIds) {
        return { selectedSpecialCategories: activeItemsIds }
      }
    })
  };

  onLoadDataFailure = (error) => {
    LogController.logError(error);
  };

  saveSpecialCategories = () => {
    let promise = Promise.resolve();
    const { studioId } = this.props;
    promise = setStudioControlSwitchers(studioId, this.state.selectedSpecialCategories)
    return promise;
  };

  onSaveChanges = () => {
    const message = this.props.t('EditStudioModal.savingMsg');
    this.getModal().setLoading(message);
    const {
      name,
      foundedDate,
      descriptionNs,
      downloadableOnProperties = [],
      properties = [],
      selectedCatalogs
    } = this.state;

    const studiosOnPropertiesDataPost = properties.map(item => {
      const obj = {
        properties_id: item.id,
        downloadable: 0,
      };

      if (downloadableOnProperties.includes(item.id)) {
        obj.downloadable = 1;
      }
      return obj;
    });

    const postData = {
      name,
      descriptionNs,
      studiosOnPropertiesData: studiosOnPropertiesDataPost
    };

    if (foundedDate) {
      Object.assign(postData, {
        foundedDate: renderMomentFormatted(
          foundedDate,
          momentFormats.dateYearFirst
        )
      });
    }

    const promises = [
      editStudio(this.props.studioId, postData),
      this.getStudioStatusUpdatePromise(),
      this.getCatalogAttachPromise(selectedCatalogs),
      this.saveSpecialCategories()
    ];

    Promise.all(promises)
      .then(this.onStudioEditSuccess)
      .catch(this.onStudioEditFailure);
  };

  onStudioEditFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditStudioModal.editStudioFailure')
    });
  };

  onStudioEditSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditStudioModal.editStudioSuccess')
    });
    this.props.onStudioEdit();
  };

  renderCatalogsSelect = () => {
    const { catalogs, selectedCatalogs } = this.state;
    let view = null;

    if (catalogs) {
      view = (
        <BaseCheckboxList
          onChange={this.onCheckboxChange.bind(this, 'selectedCatalogs')}
          showSelectAll={true}
          checked={selectedCatalogs}
          items={catalogs} />
      );
    }

    return view;
  };

  renderSpecialCategoriesSelect = () => {
    const { selectedSpecialCategories } = this.state;
    let { specialCategories } = this.state;
    specialCategories = this.prependCategoryName(specialCategories, "Mark as");

    return (
      <SpecialCategoriesSelect
        data={specialCategories}
        checked={selectedSpecialCategories}
        onItemSelect={this.onSelect}
        showSearch={true}
      />
    )
  }

  renderEnabledDisabledSelect = () => {
    const { t } = this.props;
    const { status } = this.state;
    return (
      <Select
        onChange={this.onChange.bind(this, 'status')}
        value={status}
      >
        <Select.Option value={1} key="enabled">
          {t('EditStudioModal.enabled')}
        </Select.Option>
        <Select.Option value={0} key="disabled">
          {t('EditStudioModal.disabled')}
        </Select.Option>
      </Select>
    );
  };

  renderDatePicker = (label, value, onChange) => {
    const child = (
      <DatePicker value={value}
        format={momentFormats.date}
        onChange={onChange} />
    );

    return this.renderField(label, child);
  };

  renderField = (label, children) => {
    return (
      <div className='EditStudioModal-inner'>
        <span className='EditStudioModal-inner-label'>
          {label}:
        </span>
        {children}
      </div>
    );
  };

  renderTextArea = (label, value, onChange) => {
    const child = (
      <TextArea
        onChange={onChange}
        value={value} />
    );
    return this.renderField(label, child);
  };

  prependCategoryName = (categories, tag) => {
    return categories.map(item => {
      item.name = `${tag}: ${item.name}`;
      const removeDoubeTag = [...new Set(item.name.split(" "))].join(" ");
      item.name = removeDoubeTag;
      return item;
    })
  }

  renderBaseCheckboxList = () => {
    const { properties, downloadableOnProperties } = this.state;

    return (
      <BaseCheckboxList
        onChange={this.onCheckboxChange.bind(this, 'downloadableOnProperties')}
        showSelectAll={true}
        checked={downloadableOnProperties || []}
        items={properties}
      />
    );
  };

  render() {
    const { t } = this.props;
    const { catalogs, specialCategories, descriptionNs, foundedDate, name, properties = [] } = this.state;
    return (
      <ModalDialog title={this.getModalTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className='EditStudioModal'>
          {this.renderField(t('EditStudioModal.studioStatus'), this.renderEnabledDisabledSelect())}
          {this.renderTextArea(t('EditStudioModal.name'), name, this.onChange.bind(this, 'name'))}
          {this.renderDatePicker(t('EditStudioModal.foundedDate'), foundedDate, this.onChange.bind(this, 'foundedDate'))}
          {this.renderTextArea(t('EditStudioModal.customDescription'), descriptionNs, this.onChange.bind(this, 'descriptionNs'))}
          {catalogs && this.renderField(t('EditStudioModal.movieCatalogs'), this.renderCatalogsSelect())}
          {specialCategories && this.renderField(t('EditStudioModal.specialCategories'), this.renderSpecialCategoriesSelect())}
          {properties.length !== 0 && this.renderField(t('global.downloadable_on_properties'), this.renderBaseCheckboxList())}
        </div>
      </ModalDialog>
    );
  }
}

EditStudioModal.propTypes = {
  onStudioEdit: PropTypes.func.isRequired,
  studioId: PropTypes.number.isRequired
};

export default withTranslation()(EditStudioModal);
