import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Button, Input, InputNumber } from 'antd';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import {
  setLicensorRoyalties
} from '../../../../../../../../../../services/licensors-service/licensors.service';
import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../services/notification-service/notification.service';

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";

import './LicensorInfo.scss';

class LicensorInfo extends Component {

  editableProperties = [
    'licensorName'
  ];

  startLicensorName;

  state = {
    editMode: false,
    editRoyaltiesMode: {},
    licensorName: ''
  };

  constructor(props) {
    super(props);
    const { name } = props.data;
    this.state.licensorName = name;
    this.startLicensorName = name;
  }

  isEditable = (key) => {
    return this.editableProperties.indexOf(key) !== -1;
  };

  renderGeneralInfoValue = (value, { key }) => {
    const { editMode } = this.state;
    let render = value;
    if (editMode && this.isEditable(key)) {
      render = (
        <Input.TextArea
          autoFocus
          onChange={this.onChange.bind(this, key)}
          value={this.state[key]} />
      );
    }

    return render;
  };

  renderPercentage = item => parseFloat((item * 100).toFixed(2));

  hasInfoDataChanged = () => {
    return this.startLicensorName !== this.state.licensorName;
  };

  hasRoyaltyDataChanged = (id) => {
    const data = this.state.editRoyaltiesMode[id];
    return (
      + data.marketing_fee !== data.initialMarketing ||
      + data.processing_fee !== data.initialProcessing ||
      + data.split !== data.initialSplit
    );
  };

  isValid = (data) => {
    const { marketing_fee, processing_fee, split } = data;
    return !!(marketing_fee && processing_fee && split);
  };

  onChange = (key, event) => {
    const value = event.target.value;
    this.setState({
      [key]: value
    });
  };

  onChangeRoyaltyValue = (id, name, event) => {
    let value = event && event.target ? event.target.value : event;

    this.setState(prevState => {
      const newState = cloneDeep(prevState);
      newState.editRoyaltiesMode[id][name] = value;
      newState.editRoyaltiesMode[id].isValid = this.isValid(newState.editRoyaltiesMode[id]);

      return newState;
    });
  };

  onEditLicensorInfoSuccess = () => {
    this.startLicensorName = this.state.licensorName;
    this.toggleEditMode();
  };

  onLicensorInfoEdit = () => {
    const { licensorName } = this.state;
    this.props.onLicensorInfoEdit(licensorName, this.onEditLicensorInfoSuccess);
  };

  onSaveRoyaltyChanges = (royaltyId) => {
    const message = this.props.t('LicensorInfo.savingMsg');
    this.props.setLoading(message);

    const { licensorId } = this.props;
    const {
      marketing_fee: marketing,
      processing_fee: processing,
      split
    } = this.state.editRoyaltiesMode[royaltyId];

    const updateData = {
      marketing_fee: marketing / 100,
      processing_fee: processing / 100,
      split: split / 100
    };

    setLicensorRoyalties(licensorId, [royaltyId], {
      marketing,
      processing,
      split
    })
      .then(this.onRoyaltyEditSuccess.bind(this, updateData, royaltyId))
      .catch(this.onRoyaltyEditFailure);
  };

  onRoyaltyEditSuccess = (newData, royaltyId) => {
    this.props.setLoading('');
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('LicensorInfo.editRoyaltySuccess')
    });

    const togggleData = {
      id: royaltyId
    };

    this.props.onRoyaltyEdit(royaltyId, newData, this.toggleEditRoyaltiesMode.bind(this, togggleData));
  };

  onRoyaltyEditFailure = () => {
    this.props.setLoading('');
    displayErrorNotification({
      duration: 3,
      message: this.props.t('LicensorInfo.editRoyaltyFailure')
    });
  };

  renderInfoTableFooter = () => {
    const { t } = this.props;
    const { editMode } = this.state;
    const key = editMode ? 'cancel' : 'editBtn';
    return (
      <div className="InfoTableFooter">
        {editMode ?
          (<Button
            disabled={!this.hasInfoDataChanged()}
            onClick={this.onLicensorInfoEdit}>
            {t('LicensorInfo.saveChanges')}
          </Button>) : null}
        <Button className='EditBtn' onClick={this.toggleEditMode}>
          {t(`LicensorInfo.${key}`)}
        </Button>
      </div>
    );
  };

  renderLicensorInfoTable = () => {
    const { t, data } = this.props;
    const { id, name } = data;

    const getLicensorData = [{
      name: t('LicensorInfo.id'),
      value: id,
      key: 'id'
    }, {
      name: t('LicensorInfo.name'),
      value: name,
      key: 'licensorName'
    }];

    const columns = [{
      dataIndex: 'name',
      width: '20%'
    }, {
      dataIndex: 'value',
      render: this.renderGeneralInfoValue
    }];

    return (
      <BaseCard title={t('LicensorInfo.licensorInfo')}>
        {this.renderTable(getLicensorData, columns, false, this.renderInfoTableFooter)}
      </BaseCard>
    )
  };

  renderManufacturers = () => {
    const { data, t } = this.props;
    const { manufacturers = [] } = data;

    if (manufacturers && manufacturers.length === 0) return null;

    const getManufacturersData = manufacturers.map(item => ({
      id: item.id,
      name: item.name,
      key: item.id
    }));

    const columns = [{
      dataIndex: 'id',
      title: this.props.t('LicensorInfo.manufacturerId'),
      width: '20%'
    }, {
      dataIndex: 'name',
      title: this.props.t('LicensorInfo.manufacturerName')
    }];

    return (
      <BaseCard title={t('LicensorInfo.manufacturersInfo')}>
        {this.renderTable(getManufacturersData, columns, true)}
      </BaseCard>
    )
  };

  renderRoyalties = () => {
    const { data, t } = this.props;
    const { royalties = [] } = data;

    if (royalties && royalties.length === 0) return null;

    const getRoyaltiesData = royalties.map(item => {
      const { properties = {}, marketing_fee, processing_fee, split } = item;

      const marFee = marketing_fee ? marketing_fee : 0.1;
      const procFee = processing_fee ? processing_fee : 0.15;
      const spl = split ? split : 0.3;

      return {
        id: properties.id,
        name: properties.name,
        marketing_fee: marFee,
        processing_fee: procFee,
        split: spl,
        key: `royalty-${properties.id}`
      };
    });

    const renderRoyaltyColumnValue = (name, value, item) => {
      const { editRoyaltiesMode } = this.state;
      const { id } = item;
      let result = this.renderPercentage(value) + '%';

      if (editRoyaltiesMode[id]) {
        result = (
          <div className="Input-Container">
            <InputNumber
              autoFocus={name === 'marketing_fee'}
              min={0}
              max={100}
              onChange={this.onChangeRoyaltyValue.bind(this, id, name)}
              value={editRoyaltiesMode[id][name]}
            />
            <div>%</div>
          </div>
        );
      }

      return result;
    };

    const getEditRoyaltiesButton = (item, data) => {
      const { t } = this.props;
      const { editRoyaltiesMode } = this.state;
      const { id } = data;
      const key = editRoyaltiesMode[id] ? 'cancel' : 'editBtn';
      return (
        <div className="EditRoyaltiesButton">
          {editRoyaltiesMode[id] ?
            (<Button
              className='EditBtn'
              disabled={!editRoyaltiesMode[id].isValid || !this.hasRoyaltyDataChanged(id)}
              onClick={this.onSaveRoyaltyChanges.bind(this, id)}>
              {t('LicensorInfo.saveChanges')}
            </Button>) : null}
          <Button className='EditBtn' onClick={this.toggleEditRoyaltiesMode.bind(this, data)}>
            {t(`LicensorInfo.${key}`)}
          </Button>
        </div>
      );
    };

    const columns = [{
      dataIndex: 'name',
      title: this.props.t('LicensorInfo.propertyName'),
      width: '20%'
    }, {
      dataIndex: 'marketing_fee',
      title: this.props.t('LicensorInfo.marketing'),
      render: renderRoyaltyColumnValue.bind(this, 'marketing_fee'),
      width: '20%'
    }, {
      dataIndex: 'processing_fee',
      title: this.props.t('LicensorInfo.processing'),
      render: renderRoyaltyColumnValue.bind(this, 'processing_fee'),
      width: '20%'
    }, {
      dataIndex: 'split',
      title: this.props.t('LicensorInfo.split'),
      render: renderRoyaltyColumnValue.bind(this, 'split'),
      width: '20%'
    }, {
      title: this.props.t('LicensorInfo.actions'),
      render: getEditRoyaltiesButton,
      align: 'center',
      width: '20%'
    }];

    return (
      <BaseCard title={t('LicensorInfo.royaltiesInfo')} className="Royalties-Container">
        {this.renderTable(getRoyaltiesData, columns, true)}
      </BaseCard>
    )
  };

  renderTable = (data, columns, showHeader, footer) => {
    return (
      <Table
        dataSource={data}
        bordered
        columns={columns}
        pagination={false}
        showHeader={showHeader}
        size='middle'
        footer={footer} />
    );
  };

  toggleEditMode = () => {
    this.setState(prevState => {
      return {
        editMode: !prevState.editMode
      };
    });
  };

  toggleEditRoyaltiesMode = (data) => {
    let editRoyaltiesMode = this.state.editRoyaltiesMode;
    const { id, marketing_fee, processing_fee, split } = data;

    if (editRoyaltiesMode[id]) {
      delete editRoyaltiesMode[id];
    } else {
      editRoyaltiesMode = {
        ...this.state.editRoyaltiesMode,
        [id]: {
          isValid: false,
          initialMarketing: this.renderPercentage(marketing_fee),
          marketing_fee: this.renderPercentage(marketing_fee),
          initialProcessing: this.renderPercentage(processing_fee),
          processing_fee: this.renderPercentage(processing_fee),
          initialSplit: this.renderPercentage(split),
          split: this.renderPercentage(split)
        }
      };
    }

    this.setState({
      editRoyaltiesMode: editRoyaltiesMode
    });
  };

  render() {
    return (
      <div className='LicensorInfo'>
        {this.renderLicensorInfoTable()}
        {this.renderManufacturers()}
        {this.renderRoyalties()}
      </div>
    );
  }
}

LicensorInfo.propTypes = {
  data: PropTypes.object.isRequired,
  onLicensorInfoEdit: PropTypes.func.isRequired,
  onRoyaltyEdit: PropTypes.func.isRequired
};

export default withTranslation()(LicensorInfo);
