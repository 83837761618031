import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Input, Form } from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import { updateUser } from "../../../../../../../../../../services/users-service/users.service";
import { updateRoyaltyUser } from '../../../../../../../../../../services/royalties-service/royalties.service';
import './ChangePasswordModal.scss';

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../../services/notification-service/notification.service";

class ChangePasswordModal extends Component {

  fields = ['password', 'confirmPassword'];

  modalRef = React.createRef();

  state = {
    confirmDirty: false,
    isValid: false
  };

  changePassword = () => {
    const { isRoyalty, t, form, userId } = this.props;
    const message = t('ChangePasswordModal.savingDataPleaseWaitMsg');
    this.getModal().setLoading(message);
    const data = {
      password: form.getFieldValue('password')
    };
    const updateFn = isRoyalty ? updateRoyaltyUser : updateUser;
    updateFn(userId, data)
      .then(this.onChangePasswordSuccess)
      .catch(this.onChangePasswordFailure);
  };

  clearUntouchedErrors = () => {
    this.fields.forEach(this.clearUntouchedFieldErrors);
  };

  clearUntouchedFieldErrors = (name) => {
    const { form } = this.props;
    if (!form.isFieldTouched(name) && !form.getFieldValue(name)) {
      form.setFields({
        [name]: {
          value: '',
          errors: undefined
        }
      });
    }
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="ChangePasswordModal-actions">
        <Button onClick={this.onCancel.bind(this)}
          key="cancelBtn">
          {t('ChangePasswordModal.cancel')}
        </Button>
        <Button onClick={this.changePassword}
          disabled={!this.state.isValid}
          type="primary"
          key="changePasswordBtn">
          {t('ChangePasswordModal.changePassword')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('ChangePasswordModal.changePassword');
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState(prevState => {
      return {
        confirmDirty: prevState.confirmDirty || !!value
      };
    });
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChange = () => {
    this.props.form.validateFields(this.updateIsValid.bind(this));
  };

  onChangePasswordFailure = () => {
    this.getModal().clearLoading();
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('ChangePasswordModal.passwordChangeFailureMsg')
    });
  };

  onChangePasswordSuccess = () => {
    this.getModal().closeModal();
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t('ChangePasswordModal.passwordChangeSuccessMsg')
    });
  };

  renderConfirmPassword = () => {
    return this.renderPasswordField('confirmPassword', 'pleaseConfirmPassword');
  };

  renderPassword = () => {
    return this.renderPasswordField('password', 'pleaseEnterPassword');
  };

  renderPasswordField = (key, messageKey) => {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form.Item>
        {getFieldDecorator(key, {
          rules: [{
            required: true,
            message: t(`ChangePasswordModal.validation.${messageKey}`),
            whitespace: true
          }, {
            validator: key === 'password' ? this.validatePassword : this.validateConfirmPassword
          }],
        })(
          <div className="ChangePasswordModal-field-wrapper">
            <span className="Label">{t(`ChangePasswordModal.${key}`)}:</span>
            <Input.Password name={key} onBlur={key === 'password' ? this.handleConfirmBlur : null} />
          </div>
        )}
      </Form.Item>
    );
  };

  updateIsValid = (errors) => {
    this.setState({
      isValid: errors === null
    });
    this.clearUntouchedErrors();
  };

  validateConfirmPassword = (rule, repeatPassword, callback) => {
    const { form, t } = this.props;
    const password = form.getFieldValue('password');
    if (repeatPassword && (repeatPassword !== password)) {
      callback(t('ChangePasswordModal.validation.passwordMismatch'));
    } else {
      callback();
    }
  };

  validatePassword = (rule, password, callback) => {
    const { form } = this.props;
    if (password && this.state.confirmDirty) {
      form.validateFields(['repeatPassword'], { force: true });
    }
    callback();
  };

  render() {
    return (
      <ModalDialog title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className="ChangePasswordModal">
          <Form onChange={this.onChange}>
            {this.renderPassword()}
            {this.renderConfirmPassword()}
          </Form>
        </div>
      </ModalDialog>
    );
  }
}

ChangePasswordModal.propTypes = {
  userId: PropTypes.string.isRequired,
  isRoyalty: PropTypes.bool
};
const changePasswordModalForm = Form.create({ name: 'changePassword' })(ChangePasswordModal);

export default withTranslation()(changePasswordModalForm);
