import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Slider, Input, Select, Button, InputNumber} from "antd";
import PropTypes from 'prop-types';

import BaseDelayedInput from "../../../../../../../../../../../components/BaseDelayedInput/BaseDelayedInput";

import {convertSecondsToTime} from "../../../../../../../../../../../services/util-service/util.service";

import './AdConfigurationPanel.scss';

/* istanbul ignore file */
class AdConfigurationPanel extends Component {

  adTypes = [{
    name: this.props.t('AdConfigurationPanel.image'),
    value: 'image'
  }, {
    name: this.props.t('AdConfigurationPanel.video'),
    value: 'video'
  }];

  state = {
    adTime: this.props.startInSeconds,
    adInputTime: '',
    adUrl: '',
    adType: '',
    goToText: '',
    goToUrl: '',
    videoType: ''
  };

  videoTypes = {
    'mp4d': 'application/dash+xml',
    'm3u8': 'application/x-mpegURL',
    'mp4': 'video/mp4'
  };

  constructor(props) {
    super(props);
    const {start: adTime, url: adUrl, goToText, goToUrl, type: adType, videoType, duration} = props.ad;
    Object.assign(this.state, {
      adTime,
      adInputTime: adTime !== undefined ? convertSecondsToTime(adTime) : null,
      adUrl,
      adType: adType ? adType : this.adTypes[0].value,
      duration,
      goToText,
      goToUrl,
      videoType
    });
  }

  autodetectVideoType = (url) => {
    const fileExtension = url.split('.').pop();
    return this.videoTypes[fileExtension];
  };

  calculateAdTime = (timeArray) => {
    let counter = 0;
    let calculatedTime = 0;
    let item = timeArray.length;
    let currentTime;
    while (item) {
      item--;
      currentTime = timeArray[item];
      calculatedTime += currentTime * Math.pow(60, counter);
      counter++;
    }

    return calculatedTime;
  };

  isEnteredTimeValid = (timeArray) => {
    let isValid = true;
    let counter = 0;
    let item = timeArray.length;
    let currentItem;
    while (isValid && item) {
      item--;
      currentItem = timeArray[item];
      switch (counter) {
        case 0:
        case 1:
          isValid = 0 <= currentItem && currentItem <= 59;
          break;
        default:
          break;
      }
      counter++;
    }

    return isValid;
  };

  isVideoAd = () => {
    const {adType} = this.state;
    return adType === this.adTypes[1].value;
  };

  onAddConfigChange = () => {
    const {index, onAddConfigChange} = this.props;
    const {adTime: start, adUrl: url, adType: type, duration, goToText, goToUrl, videoType} = this.state;
    onAddConfigChange(index, {
      duration,
      goToText,
      goToUrl,
      start,
      type,
      url,
      videoType
    });
  };

  onAdTimeChange = (adTime) => {
    this.setState({
      adTime,
      adInputTime: convertSecondsToTime(adTime)
    }, this.onAddConfigChange);
  };

  onAdTimeInputChange = (event) => {
    const {value} = event.target;
    this.setState({adInputTime: value});
  };

  onAdUrlChange = (adUrl) => {
    let videoType = undefined;
    if (this.isVideoAd()) {
      videoType = this.autodetectVideoType(adUrl);
    }
    this.setState({adUrl, videoType}, this.onAddConfigChange);
  };

  onGoToTextChange = (goToText) => {
    this.setState({goToText}, this.onAddConfigChange);
  };

  onGoToUrlChange = (goToUrl) => {
    this.setState({goToUrl}, this.onAddConfigChange);
  };

  onImageAdDurationChange = (duration) => {
    this.setState({duration}, this.onAddConfigChange);
  };

  parseInt = (time) => {
    return parseInt(time);
  };

  renderAdConfig = () => {
    let duration;
    if (this.isVideoAd()) { // Video type
      duration = null;
    } else {
      duration = this.renderImageAdDurationConfig();
    }
    return (
      <div className="AdsConfig">
        {this.renderGoToTextInput()}
        {this.renderGoToTextUrlInput()}
        {this.renderAdUrlInput()}
        {duration}
      </div>
    );
  };

  renderImageAdDurationConfig = () => {
    const {t} = this.props;
    const {duration} = this.state;
    return (
      <div className="ImageDuration">
        <div className="Text">
          {t('AdConfigurationPanel.imageAdDuration')}
        </div>
        <InputNumber value={duration}
                     onChange={this.onImageAdDurationChange}/>
      </div>
    );
  };

  renderAdStartTimeSelection = () => {
    const {endInSeconds, startInSeconds, t} = this.props;
    const {adTime, adInputTime} = this.state;
    return (
      <div className="AdStartTime">
        <div className="Text">{t('AdConfigurationPanel.setAdStartTimeInScene')}</div>
        <div className="AdStartTime-config">
          <Slider min={startInSeconds}
                  max={endInSeconds}
                  defaultValue={adTime}
                  value={adTime}
                  onChange={this.onAdTimeChange}
                  tipFormatter={this.renderAdTime}/>
          <div className="Search">
            <Input value={adInputTime}
                   onChange={this.onAdTimeInputChange}
                   onPressEnter={this.setParsedTime}/>
            <Button onClick={this.setParsedTime}
                    type="primary">
              {t('AdConfigurationPanel.set')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderAdTypeSelect = () => {
    const {t} = this.props;
    const {adType} = this.state;
    return (
      <div className="AdType">
        <div className="Text">
          {t('AdConfigurationPanel.selectAdType')}
        </div>
        <Select value={adType} onChange={this.updateAdType}>
          {this.adTypes.map(this.renderAdTypeSelectOption)}
        </Select>
      </div>
    );
  };

  renderAdTypeSelectOption = ({name, value}) => {
    return (
      <Select.Option value={value} key={value}>
        {name}
      </Select.Option>
    );
  };

  renderAdUrlInput = () => {
    const {t} = this.props;
    const {adUrl} = this.state;
    const label = this.state.adType === this.adTypes[0].value ? t('AdConfigurationPanel.enterAdImageUrl') : t('AdConfigurationPanel.enterAdVideoUrl');
    return this.renderTextInput(label, adUrl, this.onAdUrlChange);
  };

  renderGoToTextInput = () => {
    const {goToText} = this.state;
    const label = this.props.t('AdConfigurationPanel.enterGoToLinkText');
    return this.renderTextInput(label, goToText, this.onGoToTextChange);
  };

  renderGoToTextUrlInput = () => {
    const {goToUrl} = this.state;
    const label = this.props.t('AdConfigurationPanel.enterGoToLinkUrl');
    return this.renderTextInput(label, goToUrl, this.onGoToUrlChange);
  };

  renderAdTime = () => {
    const {adTime} = this.state;
    return convertSecondsToTime(adTime);
  };

  renderTextInput = (label, value, onChange) => {
    return (
      <div className="TextInput">
        <div className="Text">{label}</div>
        <BaseDelayedInput onChange={onChange} initialValue={value}/>
      </div>
    );
  };

  setParsedTime = () => {
    this.setState(prevState => {
      const timeText = prevState.adInputTime;
      let adTime = prevState.adTime;
      const splitTime = timeText.split(':').map(this.parseInt);
      if (this.isEnteredTimeValid(splitTime)) {
        adTime = this.calculateAdTime(splitTime);
      }
      return {
        adTime
      };
    }, this.onAddConfigChange);
  };

  updateAdType = (value) => {
    this.setState({
      adType: value
    }, this.onAddConfigChange);
  }

  render() {
    return (
      <div className="AdConfigurationPanel">
        {this.renderAdStartTimeSelection()}
        {this.renderAdTypeSelect()}
        {this.renderAdConfig()}
      </div>
    );
  }
}

AdConfigurationPanel.propTypes = {
  ad: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onAddConfigChange: PropTypes.func.isRequired,
  endInSeconds: PropTypes.number.isRequired,
  startInSeconds: PropTypes.number.isRequired
};

export default withTranslation()(AdConfigurationPanel);
