import {navigate} from "@reach/router";

import {
  getStarsRoute,
  getStarContentInfoRoute,
  getStarDetailsRoute,
  getStarMoviesRoute,
  getStarMovieDetailsRoute
} from "./stars-navigation-routes.service";


/**
 * Navigates to stars view
 *
 * @returns {Promise<any>}
 */
export const showStars = () => {
  return navigate(getStarsRoute());
};

export const showStarContentInfo = (starId) => {
  return navigate(getStarContentInfoRoute(starId));
};

/**
 * Navigates to star details view
 *
 * @param {number|string} starId
 *
 * @returns {Promise<any>}
 */
export const showStarDetails = starId => {
  return navigate(getStarDetailsRoute(starId));
};

/**
 * Navigates to star movies view
 *
 * @param {number|string} starId
 *
 * @returns {Promise<any>}
 */
export const showStarMovies = starId => {
  return navigate(getStarMoviesRoute(starId));
};

/**
 * Navigates to star movie details view
 *
 * @param {number|string} starId
 * @param {number|string} movieId
 *
 * @returns {Promise<any>}
 */
export const showStarMovieDetails = (starId, movieId) => {
  return navigate(getStarMovieDetailsRoute(starId, movieId));
};