import React from 'react';
import PropTypes from 'prop-types';

import './StatusInfo.scss';

const StatusInfo = props => {
  return (
    <div className='StatusInfo'>
      <div>
        {props.message} {props.message && props.status ? ': ' : null}
      </div>
      <div className="StatusInfo-text">{props.status} ({props.detailsMessage})</div>
    </div>
  );
};

StatusInfo.propTypes = {
  detailsMessage: PropTypes.string,
  message: PropTypes.string,
  status: PropTypes.string
};

export default StatusInfo;


