import {ROUTES} from "../../../routes";
import {MAIN_DASHBOARD_VIEW_ROUTES} from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";

/**
 * Returns stars view route
 *
 * @returns {string}
 */
export const getStarsRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.STARS}`;
};

export const getStarDetailsBaseRoute = (starId) => {
  return `${getStarsRoute()}/${starId}`;
};

export const getStarContentInfoRoute = (starId) => {
  return `${getStarDetailsBaseRoute(starId)}/contentInfo`;
};

/**
 * Returns star details route
 *
 * @param {number|string} starId
 *
 * @returns {string}
 */
export const getStarDetailsRoute = (starId) => {
  return `${getStarDetailsBaseRoute(starId)}/details`;
};

/**
 * Returns star movies route
 *
 * @param {string|number} starId
 *
 * @returns {string}
 */
export const getStarMoviesRoute = starId => {
  return `${getStarDetailsBaseRoute(starId)}/movies`;
};

/**
 * Returns star movie details route
 *
 * @param {number|string} starId
 * @param {number|string} movieId
 *
 * @returns {string}
 */
export const getStarMovieDetailsRoute = (starId, movieId) => {
  return `${getStarMoviesRoute(starId)}/${movieId}`;
};