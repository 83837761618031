import React, { Component } from 'react';
import { Router } from "@reach/router";
import { compose } from "recompose";
import { withTranslation } from 'react-i18next';
import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import { getLicensors } from '../../../../../../../../services/licensors-service/licensors.service';

import AddLicensorModal from './AddLicensorModal/AddLicensorModal';

import { LogController } from '../../../../../../../../controllers/log-controller/log.controller';
import { ModalController } from '../../../../../../../../controllers/modal-controller/modal.controller';
// import { MainDashboardComController } from '../../../../../../../../pages/MainDashboard/main-dashboard-comm.controller';

import LicensorsMainView from './LicensorsMainView/LicensorsMainView';
import LicensorDetails from './LicensorDetails/LicensorDetails';

import './LicensorsView.scss';
import EditLicensorModal from "./EditLicensorModal/EditLicensorModal";

const selectOptions = [
  { text: "Name", name: "name" },
  { text: "ID", name: "id" },
]

const defaultFilterValues = {
  search: '',
  sort: {
    ascending: true,
    name: selectOptions[0].name,
    text: selectOptions[0].text
  }
}

class LicensorsView extends Component {
  // subscription;

  state = {
    currentPage: 1,
    dataLoaded: false,
    loading: false,
    licensors: [],
    pageCount: 0,
    pageSize: 0,
    recordCount: 0,
    filters: { ...defaultFilterValues }
  };

  searchTimeout;

  componentDidMount() {
    // this.subscription = MainDashboardComController.mainNavigationLinkClicked.subscribe(this.fetchLicensors.bind(this));
    this.fetchLicensors();
  }

  // componentWillUnmount() {
  //     if (this.subscription) {
  //       this.subscription.unsubscribe();
  //     }
  // }

  fetchLicensors = (page = 1) => {
    this.setState({
      loading: true
    });

    getLicensors(page, this.state.filters)
      .then(this.loadData)
      .catch(this.onGetLicensorsFailure);
  };

  getDocumentTitle = () => {
    return this.props.t('LicensorsView.documentTitle');
  };

  loadData = response => {
    let info = Array.isArray(response.data.data)
      ? this.parseUnFilteredData(response.data)
      : this.parseFilteredData(response.data.data);
    this.loadLicensorsData(info);
  };

  loadLicensorsData = ({ licensors, pagination }) => {
    this.setState({
      currentPage: pagination.currentPage,
      dataLoaded: true,
      loading: false,
      licensors: licensors,
      pageCount: pagination.totalPages,
      pageSize: pagination.perPage,
      recordCount: pagination.total
    });
  };

  matchLicensorById = (licensorId, licensor) => {
    return licensor.id === +licensorId;
  };

  onAddLicensor = () => {
    const modal = <AddLicensorModal onLicensorAdded={this.refreshContentIfNeeded} />;
    ModalController.showModal(modal);
  };

  onGetLicensorsFailure = error => {
    LogController.logError(error);
  };

  onPageChange = page => {
    this.setState({ currentPage: page });
    this.fetchLicensors(page);
  };

  parseFilteredData = ({ licensors, pagination }) => {
    return {
      licensors: licensors,
      pagination: {
        currentPage: pagination.current_page,
        perPage: pagination.per_page,
        totalPages: pagination.last_page,
        total: pagination.total
      }
    };
  };

  parseUnFilteredData = ({ data, meta }) => {
    return {
      licensors: data,
      pagination: meta.pagination
    };
  };

  refreshContent = () => {
    const { currentPage } = this.state;
    this.fetchLicensors(currentPage);
  };

  refreshContentIfNeeded = licensorId => {
    const { currentPage } = this.state;

    if (licensorId) {
      const licensor = this.state.licensors.find(this.matchLicensorById.bind(this, licensorId));
      if (licensor) {
        this.fetchLicensors(currentPage);
      }
    } else {
      this.fetchLicensors(currentPage);
    }
  };

  showEditLicensorDialog = (id, name) => {
    const modal = (
      <EditLicensorModal licensorId={id}
        licensorName={name}
        onLicensorEdit={this.refreshContent} />
    );
    ModalController.showModal(modal);
  };

  // filters
  onInputSearch = (event) => {
    const search = event.target.value;

    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        search
      }
    }));

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.fetchLicensors();
    }, 800);
  }

  onDropdownChange = (sortFilter, ascending, text) => {
    const { filters: { search } } = this.state;
    this.setState({
      filters: {
        search,
        sort: {
          ascending: ascending,
          name: sortFilter,
          text
        },
      }
    }, () => this.fetchLicensors());
  }

  onClearFilters = () => {
    this.setState({ filters: { ...defaultFilterValues } }, () => this.fetchLicensors());
  }

  render() {
    return (
      <div className="LicensorsView">
        <Router>
          <LicensorsMainView path="/"
            listInfo={this.state}
            contentHeight={this.props.contentHeight}
            onAddLicensor={this.onAddLicensor}
            onEditLicensor={this.showEditLicensorDialog}
            onPageChange={this.onPageChange}
            onInputSearch={this.onInputSearch}
            onDropdownChange={this.onDropdownChange}
            onClearFilters={this.onClearFilters}
            dropdownSort={this.state.filters.sort}
            searchValue={this.state.filters.search}
            selectOptions={selectOptions}
          />
          <LicensorDetails path={`/:licensorId/*`}
            refreshContent={this.refreshContentIfNeeded} />
        </Router>
      </div>
    );
  }
}

export default compose(withTranslation(), withDocumentTitleUpdate)(LicensorsView);
