import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Table, List} from "antd";

import BaseCard from '../../../../../../../../../../../../components/BaseCard/BaseCard';
import ConfirmationModal
  from "../../../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";
import LinkButton from '../../../../../../../../../../../../components/LinkButton/LinkButton';

import {ModalController} from "../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import {renderMoment} from '../../../../../../../../../../../../services/date-service/date.service';
import {getMoviesPlaylistDetailsRoute} from '../../../../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service';

import './MainList.scss';

class MainList extends Component {

  renderActions = (item, data) => {
    const {t} = this.props;
    return (
      <div className="MainListActions">
        <LinkButton href={getMoviesPlaylistDetailsRoute(data.id)}>
          {t('MainList.viewDetails')}
        </LinkButton>
        <Button onClick={this.showDeleteConfirmationModal.bind(this, data)}>
          {t('MainList.delete')}
        </Button>
      </div>
    );
  };

  columns = [{
    dataIndex: 'name',
    title: this.props.t('MainList.name'),
  }, {
    dataIndex: 'playlist_key',
    title: this.props.t('MainList.key'),
  }, {
    dataIndex: 'movie_in_playlist_count',
    title: this.props.t('MainList.movies'),
    width: 200
  }, {
    dataIndex: 'created_at',
    render: renderMoment,
    title: this.props.t('MainList.createdOn')
  }, {
    align: 'center',
    dataIndex: 'actions',
    title: this.props.t('MainList.actions'),
    render: this.renderActions
  }];

  renderPlaylistList = () => {
    return (
      <List
        dataSource={this.props.data}
        renderItem={this.renderPlaylistListItem}
        split={false}
        itemLayout='vertical'
      />
    );
  };

  renderPlaylistListItem = item => {
    const {id, name, movie_in_playlist_count, created_at, playlist_key} = item;
    const {t} = this.props;
    return (
      <List.Item key={id}>
        <BaseCard
          title={t('MainList.title', {name})}
          footer={this.renderActions(item, item)}
        >
          <div className='ListCardWrapper'>
            <p>
              <span className='ListItemLabel'>
                {t('MainList.name')}:
              </span>
              {name}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('MainList.key')}:
              </span>
              {playlist_key}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('MainList.movies')}:
              </span>
              {movie_in_playlist_count}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('MainList.createdOn')}:
              </span>
              {renderMoment(created_at)}
            </p>
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  renderPlaylistTable = () => {
    return (
      <Table
        className="MainListTable"
        dataSource={this.props.data}
        columns={this.columns}
        pagination={false}
        rowKey='id'
        bordered
      />
    );
  };

  showDeleteConfirmationModal = data => {
    const {t} = this.props;
    const modal = (
      <ConfirmationModal
        title={t('MainList.confirmDelete')}
        message={t('MainList.deletionQuestion')}
        yesBtnText={t('MainList.deletePlaylist')}
        noBtnText={t('MainList.cancel')}
        confirm={this.props.deletePlaylist.bind(this, data)}
      />
    );
    ModalController.showModal(modal);
  };

  render() {
    return (
      <div className="MainList">
        {this.renderPlaylistTable()}
        {this.renderPlaylistList()}
      </div>
    );
  };
}

MainList.propTypes = {
  data: PropTypes.array.isRequired,
  scroll: PropTypes.number.isRequired
};

export default withTranslation()(MainList);
