import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Table, Icon, Menu } from "antd";
import PropTypes from 'prop-types';

import { getRoyaltiesUserDetailsRoute } from "../../../../../../../../../../services/navigation/users-navigation/users-navigation-routes.service";
import { getScrollHeight } from "../../../../../../../../../../services/util-service/util.service";

import { toggleRoyaltiesUserEnabled } from "../../../../../../../../../../services/users-service/users.service";
import { getRoyaltiesUsersPage } from "../../../../../../../../../../services/royalties-service/royalties.service";

import { displayErrorNotification } from "../../../../../../../../../../services/notification-service/notification.service";

import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";

import './RoyaltiesUsersViewBody.scss';

class RoyaltiesUsersViewBody extends Component {

  renderEnabled = enable => {
    return (enable === 1 ? 'Enabled' : 'Disabled');
  }

  setUserEnabled = (enable, userId) => {
    this.setState({
      loading: true
    });
    // const data = {
    //   enable: enable
    // };

    toggleRoyaltiesUserEnabled(userId, enable)
      .then(getRoyaltiesUsersPage(1))
      .then(this.onSetUserStatusSuccess)
      .catch(this.onSetUserStatusFailure.bind(this, enable));
  }

  onSetUserStatusSuccess = (response) => {
    const user = response.data.data;
    this.updateUserData(user.id, user);
  };

  isUserEnabled = (item) => {
    return item.enable || item.status;
  };

  getEnableDisableDropdownMenu = userId => {
    return (
      <Menu className="UsersViewBody-enabledDisabled">
        <Menu.Item>
          <div
            onClick={this.setUserEnabled.bind(this, 1, userId)}
            className="UsersViewBody-enabled"
          >
            Enabled
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={this.setUserEnabled.bind(this, 0, userId)}
            className="UsersViewBody-disabled"
          >
            Disabled
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  onSetUserStatusFailure = (enabled, error) => {
    this.setState({
      loading: false
    });
    const msg = (enabled === 1 ? 'Enable Failed' : 'Disable Failed');
    displayErrorNotification({
      duration: 3,
      message: msg
    });
  };

  matchUserById = (id, user) => {
    return user.id === id;
  };

  updateUserData = (id, data) => {
    this.setState({
      loading: false
    });

    let users = this.props.users;
    const userIndex = users.findIndex(this.matchUserById.bind(this, id));
    users[userIndex] = data;

    this.setState({
      users
    });
  };

  renderEnableDisableDropdown = (text, item) => {
    const userId = item.id;
    return (
      <div className="StatusItem">
        <Dropdown overlay={this.getEnableDisableDropdownMenu(userId)}>
          <Button className={this.isUserEnabled(item) ? 'UserEnabled' : 'UserDisabled'}>
            {this.isUserEnabled(item) ? 'Enabled' : 'Disabled'}
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
    );
  };

  renderActions = (item, { id, name }) => {

    return (
      <LinkButton href={getRoyaltiesUserDetailsRoute(id)}>
        View Details
      </LinkButton>
    );
  };

  columns = [{
    dataIndex: 'id',
    title: 'User ID',
    width: 300
  }, {
    dataIndex: 'username',
    title: 'User Name',
    width: 300
  }, {
    dataIndex: 'licensor.name',
    title: 'Licensor Name',
    width: 300
  }, {
    dataIndex: 'licensor.id',
    title: 'Licensor ID',
    width: 200
  }, {
    align: 'center',
    dataIndex: 'enable',
    title: 'Enabled',
    render: this.renderEnableDisableDropdown,
    width: 180
  }, {
    align: 'center',
    dataIndex: 'actions',
    title: 'Actions',
    render: this.renderActions,
    width: 180
  }];

  tableRowHeight = 65;

  calculateTableScroll = () => {
    const { contentHeight, users } = this.props;
    // const reductionHeight = 52 + 54 + 40 + 52;// Remove 52 for header and also footer height
    const reductionHeight = 52 + 52;// Remove 52 for header and also footer height

    return getScrollHeight(contentHeight, users, this.tableRowHeight, reductionHeight);
  };

  renderUsersTable = () => {
    const { users } = this.props;
    // const data = users.map(this.prepareUsersTableData);
    return (
      <Table
        className="UsersTable"
        dataSource={users}
        rowKey="id"
        bordered
        columns={this.columns}
        pagination={false}
        scroll={this.calculateTableScroll()}
      />
    );
  };

  render() {
    return (
      <div className="UsersViewBody">
        {this.renderUsersTable()}
        {/* {this.renderUsersList()} */}
      </div>
    );
  }
}

RoyaltiesUsersViewBody.propTypes = {
  contentHeight: PropTypes.number,
  users: PropTypes.array.isRequired
};

export default withTranslation()(RoyaltiesUsersViewBody);
