import {notification} from "antd";

export const displayErrorNotification = (data) => {
  notification.error(data);
};

export const displayInfoNotification = (data) => {
  notification.info(data);
};

export const displaySuccessNotification = (data) => {
  notification.success(data);
};
