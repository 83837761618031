import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import { ModalController } from "../../../../../../../../../../../controllers/modal-controller/modal.controller";

import ConfirmationModal from "../../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";
import BaseImagesDisplay from "../../../../../../../../../../../components/BaseImagesDisplay/BaseImagesDisplay";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../../../services/notification-service/notification.service";
import { setSceneHeroImage } from "../../../../../../../../../../../services/movies-service/movies.service";
import { deleteSceneImage } from "../../../../../../../../../../../services/images-service/images.service";

import './SceneDetailsImagesTab.scss';

class SceneDetailsImagesTab extends Component {

  imagesDisplayRef;

  closeModal = () => {
    ModalController.removeModal();
  };

  deleteImage = image => {
    const { sceneId } = this.props;
    deleteSceneImage(sceneId, image.id)
      .then(this.deleteImageSuccess.bind(this, image.id))
      .catch(this.deleteImageFailure);
  };

  deleteImageFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('SceneDetailsImagesTab.deleteImageFailure')
    });
  };

  deleteImageSuccess = imageId => {
    this.props.onDeleteImage(imageId);
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('SceneDetailsImagesTab.deleteImageSuccess')
    });
  };

  displaySceneHeroStillUpdateInProgress = () => {
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('SceneDetailsImagesTab.sceneHeroUpdateInProgress')
    });
  };

  onDeleteImage = image => {
    const { t } = this.props;
    const modal = (
      <ConfirmationModal
        title={t('SceneDetailsImagesTab.confirmDelete')}
        message={t('SceneDetailsImagesTab.deleteQuestion')}
        yesBtnText={t('SceneDetailsImagesTab.deleteImage')}
        noBtnText={t('SceneDetailsImagesTab.cancel')}
        decline={this.closeModal}
        confirm={this.deleteImage.bind(this, image)}
      />
    );
    ModalController.showModal(modal);
  };

  onSetHeroImageFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('SceneDetailsImagesTab.setHeroFailedMsg')
    });
  };

  onSetHeroImageSuccess = imageId => {
    this.props.onEditCoverImage(imageId);
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('SceneDetailsImagesTab.sceneHeroStillChangeSuccess')
    });
  };

  setFullScreen = (index) => {
    this.imagesDisplayRef.toggleFullScreen(index);
  };

  setHeroImage = image => {
    this.displaySceneHeroStillUpdateInProgress();
    const { id: imageId } = image;
    const { sceneId } = this.props;
    setSceneHeroImage(sceneId, imageId)
      .then(this.onSetHeroImageSuccess.bind(this, imageId))
      .catch(this.onSetHeroImageFailure);
  };

  onEditImage = (imageId) => {
    this.props.onEditCoverImage(imageId);
  };

  setImagesDisplayRef = (imagesDisplay) => {
    this.imagesDisplayRef = imagesDisplay;
  };

  render() {
    let view = null;
    const { images } = this.props;
    if (images.length) {
      view = (
        <div className="SceneDetailsImagesTab">
          <BaseImagesDisplay
            images={images}
            onFullScreen={this.setFullScreen}
            onSetHeroStill={this.setHeroImage}
            onDeleteImage={this.onDeleteImage}
            onEditImage={this.onEditImage}
            onInitialize={this.setImagesDisplayRef}
          />
        </div>
      );
    }
    return view;
  }
}

SceneDetailsImagesTab.propTypes = {
  images: PropTypes.array.isRequired,
  onEditCoverImage: PropTypes.func.isRequired,
  sceneId: PropTypes.string
};

export default withTranslation()(SceneDetailsImagesTab);
