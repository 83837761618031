import React, { Component } from 'react';
import { Router } from "@reach/router";
import { withTranslation } from "react-i18next";

import UsersViewBody from "./UsersViewBody/UsersViewBody";
import UsersViewHeader from "./UsersViewHeader/UsersViewHeader";
import UserDetails from '../UserDetails/UserDetails';

import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";

import './UsersMainView.scss';

class UsersMainView extends Component {

  renderBody = () => {
    return (
      <Router>
        <UsersViewBody
          {...this.props}
          path={'/*'}
          users={this.props.listInfo.users}
          contentHeight={this.props.contentHeight}
          setUserEnabled={this.props.setUserEnabled}
        />
        <UserDetails
          path={`/details/:userId`}
          updateUserData={this.props.updateUserData}
        />
      </Router>
    );
  };

  renderHeader = () => {
    return this.props.activeUsers ? null : (
      <UsersViewHeader
        onChange={this.props.onFilterChange}
        defaultFilters={this.props.defaultFilters}
      />
    );
  };

  renderActiveUsers = () => {
    return <UsersViewBody
      {...this.props}
      onExport={this.props.onExport}
      path={'/*'}
      users={this.props.listInfo.users}
      contentHeight={this.props.contentHeight}
      setUserEnabled={this.props.setUserEnabled}
    />;
  };

  render() {
    const { t } = this.props;
    return (
      <div className="UsersMainView">
        {this.renderHeader()}
        <LoadingWrapper className='UsersMainView-inner'
          dataLoaded={this.props.listInfo.dataLoaded}
          isLoading={this.props.listInfo.loading}
          loadingMsg={t('UsersMainView.loadingPleaseWait')}>
          {this.props.activeUsers ? this.renderActiveUsers() : this.renderBody()}
        </LoadingWrapper>
      </div>
    );
  }
}

export default withTranslation()(UsersMainView);
