import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {cloneDeep} from 'lodash';
import {Button, Collapse} from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import ConfigureAdsModal from "../AdsConfiguration/ConfigureAdsModal/ConfigureAdsModal";
import EditAdModal from "../AdsConfiguration/EditAdModal/EditAdModal";
import AdDetails from "../AdDetails/AdDetails";

import {ModalController} from "../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import {stopEvent} from "../../../../../../../../../../../../services/util-service/util.service";
import {convertRunTimeToSeconds} from "../../../../../../../../../../../../services/date-service/date.service";

import CollapsiblePanel from "../../../shared/CollapsiblePanel/CollapsiblePanel";

import './ConfigurePropertyAdsModal.scss';

/* istanbul ignore file */
class ConfigurePropertyAdsModal extends Component {
	modalRef = React.createRef();

	state = {
		activeKeys: [],
		ads: {},
		scenes: [],
	};

	constructor(props) {
		super(props);
		const { ads, movieDetails } = props;
		this.state.ads = ads ? ads : {};
		this.state.scenes = movieDetails.scenes;
		const activeKeys = movieDetails.scenes.map(this.getSceneId);
		activeKeys.push(props.movieDetails.movieId);
		this.state.activeKeys = activeKeys;
	}

	adConfigPanelMap = (
		id,
		editFn,
		{
			applyToLoggedUser,
			applyToNotLoggedUser,
			selectedTarget,
			goToText,
			goToUrl,
			start,
			type,
			url,
			videoType,
			duration,
		},
		index
	) => {
		const props = {
			ad: {
				applyToLoggedUser,
				applyToNotLoggedUser,
				selectedTarget,
				duration,
				goToText,
				goToUrl,
				start,
				type,
				url,
				videoType,
			},
			index,
			editAd: editFn.bind(this, id, index),
			removeAd: this.removeAdd.bind(this, id, index),
		};
		return <AdDetails {...props} key={index} />;
	};

	closeModal = () => {
		this.getModal().closeModal();
	};

	displayEditMovieAdModal = (sceneId, index) => {
		const { movieId, runTime } = this.props.movieDetails;
		const ad = this.state.ads[movieId][index];
		const props = {
			ad,
			endInSeconds: convertRunTimeToSeconds(runTime),
			id: movieId,
			index,
			saveChanges: this.saveEditedAdChanges,
			startInSeconds: 0,
		};
		const modal = <EditAdModal {...props} />;
		ModalController.showModal(modal);
	};

	displayEditSceneAdModal = (sceneId, index) => {
		const {
			endTimeSeconds: sceneEndInSeconds,
			startTimeSeconds: sceneStartInSeconds,
		} = this.state.scenes.find(this.matchSceneById.bind(this, sceneId));
		const ad = this.state.ads[sceneId][index];
		const props = {
			ad,
			endInSeconds: sceneEndInSeconds,
			id: sceneId,
			index,
			saveChanges: this.saveEditedAdChanges,
			startInSeconds: sceneStartInSeconds,
		};
		const modal = <EditAdModal {...props} />;
		ModalController.showModal(modal);
	};

	displayMovieAdModal = (event) => {
		stopEvent(event);
		const { movieId, runTime } = this.props.movieDetails;
		const props = {
			ads: this.state.ads[movieId],
			endInSeconds: convertRunTimeToSeconds(runTime),
			id: movieId,
			isMovie: true,
			onAdsSave: this.onAdsSave,
			startInSeconds: 0,
		};
		const modal = <ConfigureAdsModal {...props} />;
		ModalController.showModal(modal);
	};

	displaySceneAdModal = (id, event) => {
		stopEvent(event);
		const {
			endTimeSeconds: sceneEndInSeconds,
			startTimeSeconds: sceneStartInSeconds,
		} = this.state.scenes.find(this.matchSceneById.bind(this, id));
		const props = {
			ads: this.state.ads[id],
			endInSeconds: sceneEndInSeconds - sceneStartInSeconds,
			id,
			onAdsSave: this.onAdsSave,
			startInSeconds: 0,
		};
		const modal = <ConfigureAdsModal {...props} />;
		ModalController.showModal(modal);
	};

	getActions = () => {
		const { t } = this.props;
		return (
			<div className="ConfigureMoviePropertyAdsModal-actions">
				<Button onClick={this.closeModal}>
					{t("ConfigurePropertyAdsModal.cancel")}
				</Button>
				<Button onClick={this.onSaveAdsConfig}>
					{t("ConfigurePropertyAdsModal.saveAdsConfig")}
				</Button>
			</div>
		);
	};

	getModal = () => {
		return this.modalRef.current;
	};

	getCollapseHeader = (id, key, addAdsClick) => {
		const { t } = this.props;
		return (
			<div className="SceneHeader">
				<div className="SceneText">
					{t(`ConfigurePropertyAdsModal.${key}`, { id })}
				</div>
				<Button onClick={addAdsClick}>
					{t("ConfigurePropertyAdsModal.addNewAd")}
				</Button>
			</div>
		);
	};

	getSceneId = ({ id }) => id;

	getTitle = () => {
		return this.props.t("ConfigurePropertyAdsModal.title");
	};

	matchSceneById = (id, { id: sceneId }) => {
		return id === sceneId;
	};

	onActiveCollapseChange = (activeKeys) => {
		this.setState({ activeKeys });
	};

	onAdsSave = (id, editedAds) => {
		this.setState((prevState) => {
			const ads = cloneDeep(prevState.ads);
			ads[id] = editedAds;
			return { ads };
		});
	};

	onSaveAdsConfig = () => {
		const { movieDetails, propertyId } = this.props;
		const { movieId } = movieDetails;
		const { ads } = this.state;
		this.props.onAdsConfigured({
			ads,
			movieId,
			propertyId,
		});
		this.closeModal();
	};

	removeAdd = (id, index) => {
		this.setState((prevState) => {
			const ads = cloneDeep(prevState.ads);
			ads[id].splice(index, 1);
			return { ads };
		});
	};

	renderMovie = () => {
		const { movieId: id } = this.props.movieDetails;
		const { ads } = this.state;
		const adsConfig = ads[id] ? ads[id] : [];
		return (
			<Collapse.Panel
				key={id}
				header={this.getCollapseHeader(
					id,
					"movieIdTxt",
					this.displayMovieAdModal
				)}
			>
				{adsConfig.length
					? adsConfig.map(
							this.adConfigPanelMap.bind(
								this,
								id,
								this.displayEditMovieAdModal
							)
					  )
					: this.renderNoAdsConfigured()}
			</Collapse.Panel>
		);
	};

	renderMovieScenes = () => {
		const { activeKeys, scenes } = this.state;
		return (
			<CollapsiblePanel
				defaultActiveKeys={[scenes[0]?.id]}
				activeKeys={activeKeys}
				onChange={this.onActiveCollapseChange}
			>
				{this.renderMovie()}
				{scenes.map(this.sceneMap)}
			</CollapsiblePanel>
		);
	};

	renderNoAdsConfigured = () => {
		const { t } = this.props;
		return (
			<div className="noAdsConfigured">
				{t(
					"ConfigureAdsOnMovieLevel.ConfigureAdsModal.noAdsConfigured"
				)}
			</div>
		);
	};

	saveEditedAdChanges = (sceneId, adIndex, data) => {
		this.setState((prevState) => {
			const ads = cloneDeep(prevState.ads);
			ads[sceneId][adIndex] = data;
			return { ads };
		});
	};

	sceneMap = (scene) => {
		const { id } = scene;
		const { ads } = this.state;
		const adsConfig = ads[id] ? ads[id] : [];
		return (
			<Collapse.Panel
				key={id}
				header={this.getCollapseHeader(
					id,
					"sceneIdTxt",
					this.displaySceneAdModal.bind(this, id)
				)}
			>
				{adsConfig.length
					? adsConfig.map(
							this.adConfigPanelMap.bind(
								this,
								id,
								this.displayEditSceneAdModal
							)
					  )
					: this.renderNoAdsConfigured()}
			</Collapse.Panel>
		);
	};

	render() {
		const { movieDetails, propertyName, t } = this.props;
		const { title } = movieDetails;
		return (
			<ModalDialog
				className="ConfigurePropertyAdsModal"
				title={this.getTitle()}
				actions={this.getActions()}
				forwardedRef={this.modalRef}
			>
				<div className="ConfigurePropertyAdsModal-inner">
					<div className="MovieName">
						{t("ConfigurePropertyAdsModal.movieTitle", { title })}
					</div>
					<div className="PropertyName">
						{t("ConfigurePropertyAdsModal.propertyNameTxt", {
							name: propertyName,
						})}
					</div>
					{this.renderMovieScenes()}
				</div>
			</ModalDialog>
		);
	}
}

ConfigurePropertyAdsModal.propTypes = {
  ads: PropTypes.object,
  movieDetails: PropTypes.object.isRequired,
  onAdsConfigured: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  propertyName: PropTypes.string.isRequired
};

export default withTranslation()(ConfigurePropertyAdsModal);
