import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Divider, Select, Spin } from "antd";
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import { LogController } from "../../controllers/log-controller/log.controller";

import './BaseLoadMoreSelect.scss';

class BaseLoadMoreSelect extends Component {

  initialLoad = false;

  isUnMounted = false;

  searchDebounce = 500;

  state = {
    filter: '',
    loading: false,
    data: [],
    page: 1,
    pageCount: 1,
    selectedValue: ''
  };

  searchTimeoutId;

  constructor(props) {
    super(props);
    this.state.selectedValue = props.selectedValue || '';
    this.state.data = props.preload || [];
  }

  componentDidMount() {
    this.getData(true);
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  canLoadMore = () => {
    const { page, pageCount } = this.state;
    return page < pageCount;
  };

  dataMap = (data) => {
    const { tag } = data;
    const tagId = this.getId(data) ?? this.props.name;
    const name = tag ? tag.name : data.title || data.name;

    return (
      <Select.Option
        value={tagId}
        key={tagId}>
        {tagId} {name && `- ${name}`}
      </Select.Option>
    );
  };

  filterExistingPreload = (preload, items) => {
    let item = items.length;
    while (item) {
      item--;
      if (preload.find(this.matchById.bind(this, items[item]))) {
        items.splice(item, 1);
      }
    }

    return items;
  };

  getData = (initialLoad) => {
    const { loading, filter } = this.state;
    if (initialLoad || (!loading && this.canLoadMore())) { //Only trigger if we can load a page
      this.initialLoad = initialLoad;
      this.setLoading(true);
      const { page } = this.state;
      const filters = {
        search: filter
      }
      this.props.getData(page, filters)
        .then(this.loadData)
        .catch(this.handleGetDataError);
    }
  };

  getId = (data) => {
    const { idIdentifier } = this.props;
    const { tag } = data;
    const dataId = data[idIdentifier];
    return tag ? tag[idIdentifier] : dataId;
  };

  handleGetDataError = error => {
    LogController.logError(error);
    this.setLoading(false);
  };

  imitateEntryObjectStructure = (id) => {
    const { data } = this.state;
    const { tag } = data[0];
    const { idIdentifier } = this.props;
    let obj = {
      [idIdentifier]: id
    };
    if (tag) {
      obj = {
        tag: {
          [idIdentifier]: id
        }
      };
    }

    return obj;
  };

  loadData = response => {
    let loadedItems = response.data.data[this.props.name];
    const { pagination } = response.data.data;
    if (!this.isUnMounted) {
      this.setState(prevState => {
        let data = loadedItems;
        let { preload } = this.props;
        if (this.initialLoad) {
          if (preload) {
            loadedItems = this.filterExistingPreload(cloneDeep(preload), loadedItems);
            data = prevState.data.concat(loadedItems);
          }
        } else {
          if (preload) {
            loadedItems = this.filterExistingPreload(cloneDeep(preload), loadedItems);
          }
          data = prevState.data.concat(loadedItems);
        }
        return {
          loading: false,
          page: prevState.page + 1,
          data: data,
          pageCount: pagination.last_page
        };
      });
    }
  };

  loadFilteredData = () => {
    this.setState({
      page: 1
    });
    this.getData(true);
  };

  matchById = (obj1, obj2) => {
    return this.getId(obj1) === this.getId(obj2);
  };

  onChange = (dataId) => {
    this.setState({
      selectedValue: dataId
    });
    const { fullValue, onSelect } = this.props;
    let value = dataId;
    if (fullValue) {
      const { data } = this.state;
      let obj = this.imitateEntryObjectStructure(value);
      // All items have the same structure so we are mimicking single object down to id identifier
      // so matchById functions has the same structure in both objects
      value = data.find(this.matchById.bind(this, obj));
    }
    onSelect(value);
  };

  onSearch = value => {
    this.setState({
      filter: value,
      data: []
    });
    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }
    this.searchTimeoutId = setTimeout(this.loadFilteredData, this.searchDebounce);
  };

  preventEventDefault = event => {
    event.preventDefault();
  };

  renderChildren = () => {
    return this.state.data.map(this.dataMap);
  };

  renderDivider = (canLoadMore) => {
    return canLoadMore ? (
      <Divider className="BaseLoadMoreSelect-dropdown-divider" />
    ) : null;
  };

  renderDropdown = menu => {
    const { loading } = this.state;
    const canLoadMore = this.canLoadMore();
    return (
      <div>
        {menu}
        {this.renderDivider(canLoadMore)}
        {loading ? this.renderLoadingIndicator() : this.renderLoadMore(canLoadMore)}
      </div>
    )
  };

  renderLoadingIndicator = () => {
    return (
      <Spin size="small"
        className="BaseLoadMoreSelect-spinner" />
    );
  };

  renderLoadMore = (canLoadMore) => {
    const { t } = this.props;
    return canLoadMore ? (
      <div onMouseDown={this.preventEventDefault}
        onClick={this.getData.bind(this, false)}
        className="BaseLoadMoreSelect-loadMore">
        {t('BaseLoadMoreSelect.loadMore')}
      </div>
    ) : null;
  };

  setLoading = loading => {
    this.setState({
      loading: loading
    });
  };

  render() {
    const { t } = this.props;
    const { selectedValue } = this.state;
    return (
      <div className="BaseLoadMoreSelect">
        <Select
          value={selectedValue}
          placeholder={t("BaseLoadMoreSelect.placeholder", { name: this.props.name })}
          showSearch
          onSearch={this.onSearch}
          filterOption={false}
          dropdownRender={this.renderDropdown}
          dropdownClassName="BaseLoadMoreSelect-dropdown"
          onChange={this.onChange}
          defaultActiveFirstOption={false}
        >
          {this.renderChildren()}
        </Select>
      </div>
    );
  }
}

BaseLoadMoreSelect.propTypes = {
  fullValue: PropTypes.bool,
  getData: PropTypes.func.isRequired,
  idIdentifier: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  preload: PropTypes.array,
  searchKey: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ])
};

export default withTranslation()(BaseLoadMoreSelect);