import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Input } from "antd";

import './ChangeAltTagModal.scss';
import { ModalDialog } from '../../modal/ModalDialog/ModalDialog';

class ChangeAltTagModal extends Component {
    modalRef = React.createRef();
    state = {
        altTag: null,
        originalAltTag: null
    };

    constructor(props) {
        super(props);
        const { alt = '' } = props.data;
        this.state = {
            altTag: alt,
            originalAltTag: alt
        }
    }

    closeModal = () => {
        this.getModal().closeModal();
    };

    getActions = () => {
        const { t } = this.props;
        return ([
            <Button onClick={this.closeModal} key="cancelBtn">
                {t('ChangeAltTagModal.cancel')}
            </Button>,
            <Button onClick={this.saveChanges}
                key="saveBtn"
                disabled={!this.hasDataChanged()}>
                {t('ChangeAltTagModal.saveChanges')}
            </Button>
        ]);
    };

    getModal = () => {
        return this.modalRef.current;
    };

    getTitle = () => {
        return this.props.t('ChangeAltTagModal.title');
    };

    hasDataChanged = () => {
        const { originalAltTag, altTag } = this.state;
        return originalAltTag !== altTag;
    };

    saveChanges = () => {
        const { saveChanges, data } = this.props;
        const { altTag } = this.state;
        saveChanges(data, altTag, this.closeModal);
    };

    onValueChange = (event) => {
        this.setState({
            altTag: event.target.value
        });
    };

    render() {
        const { t } = this.props;
        return (
            <ModalDialog title={this.getTitle()}
                actions={this.getActions()}
                ref={this.modalRef}
            >
                <div className="ChangeAltTagModal">
                    <div className="ChangeAltTagModal-inner">
                        <div className='InputWrapper'>
                            <div>{t('ChangeAltTagModal.altTagName')}:</div>
                            <Input
                                type='text'
                                allowClear={true}
                                autoFocus={true}
                                value={this.state.altTag}
                                onChange={this.onValueChange}
                            />
                        </div>
                    </div>
                </div>
            </ModalDialog>
        );
    }
}

export default withTranslation()(ChangeAltTagModal);
