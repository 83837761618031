import React, {Component} from 'react';
import {Button} from "antd";

import ModalDialog from "../modal/ModalDialog/ModalDialog";

import {readFileAsText} from "../../services/file-handler-service/file-handler.service";

import './ConfigModal.scss';

import data from '../../assets/player/data.json';
import {displaySuccessNotification} from "../../services/notification-service/notification.service";

/* istanbul ignore file */
class ConfigModal extends Component {

  fileUploader = React.createRef();

  modalRef = React.createRef();

  downloadConfigTemplateFile = () => {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.getConfigTemplate()));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", 'player-config.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  getConfigTemplate = () => {
    const config = localStorage.getItem('playerConfig');
    let configTemplate = data;
    if (config) {
      configTemplate = JSON.parse(config);
    }

    return configTemplate;
  };

  getModal = () => {
    return this.modalRef.current;
  };

  onBrowsedFileSelected = (e) => {
    const {files} = e.target;
    const file = files[0];
    readFileAsText(file)
      .then(this.saveNewPlayerConfig);
  };

  saveNewPlayerConfig = (data) => {
    localStorage.setItem('playerConfig', data);
    displaySuccessNotification({
      duration: 3,
      message: "Player config successfully updated!"
    });
    this.getModal().closeModal();
  };

  selectFile = () => {
    this.fileUploader.current.click();
  };

  render() {
    return (
      <ModalDialog className="ConfigModal"
                   title="Unified Player Configuration Modal"
                   forwardedRef={this.modalRef}>
        <Button onClick={this.downloadConfigTemplateFile}>
          Download Config Template File
        </Button>
        <input type="file" id="file" ref={this.fileUploader} onChange={this.onBrowsedFileSelected}/>
        <Button onClick={this.selectFile.bind(this)}>Upload Config File</Button>
      </ModalDialog>
    );
  }
}

ConfigModal.propTypes = {};

export default ConfigModal;
