import React, {Component} from 'react'
import {globalHistory} from "@reach/router";

import {DocumentTitleController} from "../../controllers/title-controller/title.controller";

export const withDocumentTitleUpdate = (WrappedComponent) => {

  return class extends Component {

    componentRef = React.createRef();

    historyUnsubscribe;

    componentDidMount() {
      this.updateTitleIfNeeded();
      this.historyUnsubscribe = globalHistory.listen(this.updateTitleIfNeeded.bind(this));
    }

    componentWillUnmount() {
      this.historyUnsubscribe();
    }

    updateTitleIfNeeded = () => {
      const componentPath = this.props.uri;
      const currentPath = window.location.pathname;
      if (componentPath === currentPath) {
        const {getDocumentTitle} = this.componentRef.current;
        if (getDocumentTitle) {
          const title = this.componentRef.current.getDocumentTitle();
          DocumentTitleController.setDocumentTitle(title);
        } else {
          console.log('Please implement getDocumentTitle in ', this);
        }
      }
    };

    render() {
      const me = this;
      return (
        <WrappedComponent {...me.props} ref={me.componentRef}/>
      );
    }
  }
};
