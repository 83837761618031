import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import MoviesViewAbstract from '../../MoviesView/MoviesViewAbstract/MoviesViewAbstract';
import CategoryMoviesMainView from "./CategoryMoviesMainView/CategoryMoviesMainView";

// import {getMovies} from '../../../../../../../../../services/movies-service/movies.service';
// import {displayErrorNotification} from '../../../../../../../../../services/notification-service/notification.service';

class CategoryMovies extends MoviesViewAbstract {
  categoryId;

  constructor(props) {
    super(props);
    this.categoryId = props.categoryId;
  }

  // fetchMovies = page => {
  //   const message = this.props.t('CategoryMovies.loadingPleaseWait');
  //   this.setLoading(message);

  //   let filters = this.getFilters();
  //   filters.push(`categories_id=${this.categoryId}`);

  //   getMovies(page, filters)
  //     .then(this.loadMovies)
  //     .catch(this.onFetchMoviesFailure);
  // };

  // onFetchMoviesFailure = () => {
  //   this.setLoading('');

  //   const {t} = this.props;
  //   displayErrorNotification({
  //     duration: 3,
  //     message: t('CategoryMovies.fetchMoviesFailure')
  //   });
  // };

  renderMainViewRoute = () => {
    const props = this.getMainViewProps();
    props.categoryId = this.categoryId;
    return (
      <CategoryMoviesMainView {...props} />
    );
  };
}

CategoryMovies.propTypes = {
  categoryId: PropTypes.string
};

export default withTranslation()(CategoryMovies);