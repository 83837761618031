import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Dropdown, Button, Icon, Menu, Table, DatePicker } from "antd";
import PropTypes from 'prop-types';

import { ModalController } from "../../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import {
  convertToMoment,
  momentFormats,
  renderMoment
} from '../../../../../../../../../../../../../services/date-service/date.service';
import { areArraysEqual, getOnPropertiesData, renderListClickableItems } from "../../../../../../../../../../../../../services/util-service/util.service";

import BaseCheckboxList from "../../../../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList";
import BaseDelayedInput from "../../../../../../../../../../../../../components/BaseDelayedInput/BaseDelayedInput";
import EditCatalogModal from "../../../../../../PropertiesView/PropertiesViewDetails/EditCatalogModal/EditCatalogModal";

import { showPropertyDetails } from '../../../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation.service';
import { getPropertyDetailsRoute } from '../../../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service';
import './StudioDetailsBodyTable.scss';

class StudioDetailsBodyTable extends Component {

  editableProperties = [
    'status', 'name', 'foundedDate', 'descriptionNs', 'movieCatalogs', 'downloadableOnProperties'
  ];

  startingData = {
    editMode: true
  };

  state = {
    allCatalogs: [],
    description: '',
    descriptionNs: '',
    editMode: false,
    foundedDate: undefined,
    importedOn: undefined,
    lastSyncedOn: undefined,
    movieCatalogs: undefined,
    moviesCount: undefined,
    name: '',
    status: undefined,
    downloadableOnProperties: []
  };

  constructor(props) {
    super(props);
    const { allCatalogs, description, descriptionNs, foundedDate, importedOn, lastSyncedOn, movie_catalogs, moviesCount, name, status, studios_on_properties_data, properties } = props;
    const { downloadableOnProperties = [] } = getOnPropertiesData(studios_on_properties_data, properties);

    const data = {
      allCatalogs,
      description,
      descriptionNs,
      foundedDate: convertToMoment(foundedDate),
      importedOn,
      lastSyncedOn,
      movieCatalogs: movie_catalogs.map(this.getId),
      moviesCount,
      name,
      status,
      downloadableOnProperties,
      downloadableOnPropertiesIDs: downloadableOnProperties.map(item => item.id)
    };
    Object.assign(this.state, data);
    Object.assign(this.startingData, data);
  }

  getEnableDisableDropdownMenu = () => {
    const { t } = this.props;
    return (
      <Menu className="StudioCard-enabledDisabled">
        <Menu.Item>
          <div onClick={this.setStudioEnabled.bind(this, 1)}
            className="StudioCard-enabled">
            {t('StudiosViewBody.enabled')}
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={this.setStudioEnabled.bind(this, 0)}
            className="StudioCard-disabled">
            {t('StudiosViewBody.disabled')}
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  getId = ({ id }) => id;

  getStatusText = status => {
    const { t } = this.props;
    return t(status ? 'StudioDetailsBodyTable.Enabled' : 'StudioDetailsBodyTable.Disabled');
  };

  getTableData = () => {
    const { t, description, descriptionNs, foundedDate, id, importedOn, lastSyncedOn, moviesCount, name, status, studios_on_properties_data, properties } = this.props;
    const { downloadableOnProperties } = getOnPropertiesData(studios_on_properties_data, properties);

    let columns = [{
      name: t('StudioDetailsBodyTable.studioName'),
      value: name,
      key: 'name'
    }, {
      name: t('StudioDetailsBodyTable.status'),
      value: status,
      key: 'status'
    }, {
      name: t('StudioDetailsBodyTable.studioId'),
      value: id,
      key: 'studioId'
    }, {
      name: t('StudioDetailsBodyTable.importedOn'),
      value: renderMoment(importedOn),
      key: 'importedOn'
    }, {
      name: t('StudioDetailsBodyTable.lastSync'),
      value: renderMoment(lastSyncedOn),
      key: 'lastSync'
    }, {
      name: t('StudioDetailsBodyTable.foundedOn'),
      value: this.renderDate(foundedDate),
      key: 'foundedDate'
    }, {
      name: t('StudioDetailsBodyTable.moviesCount'),
      value: moviesCount,
      key: 'moviesCount'
    }, {
      name: t('StudioDetailsBodyTable.description'),
      value: description,
      key: 'description'
    }, {
      name: t('StudioDetailsBodyTable.customDescription'),
      value: descriptionNs,
      key: 'descriptionNs'
    }, {
      name: t('StudioDetailsBodyTable.movieCatalogs'),
      value: this.renderMovieCatalogs(),
      key: 'movieCatalogs'
    }, {
      name: t('global.downloadable_on_properties'),
      value: renderListClickableItems(
        downloadableOnProperties,
        showPropertyDetails,
        'downloadableOnProperties',
        getPropertyDetailsRoute
      ),
      key: 'downloadableOnProperties'
    }];

    // from props
    // if (movie_catalogs && movie_catalogs.length) {
    // columns.push({
    //   name: t('StudioDetailsBodyTable.movieCatalogs'),
    //   value: this.renderMovieCatalogs(),
    //   key: 'movieCatalogs'
    // });
    // }

    return columns;
  };

  getStatusCssClass = (status) => {
    return status ? 'StudioEnabled' : 'StudioDisabled';
  };

  hasDataChanged = () => {
    return JSON.stringify(this.startingData) !== JSON.stringify(this.state);
  };

  isEditable = (key) => {
    return this.editableProperties.indexOf(key) !== -1;
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value
    })
  };

  onDownloadableChange = (values) => {
    this.setState({ downloadableOnPropertiesIDs: values });
  };

  onMovieCatalogsChange = (catalogs) => {
    this.setState({
      movieCatalogs: catalogs
    });
  };

  renderDate = (date) => {
    let render = '';
    if (date) {
      const split = date.split('/');
      render = renderMoment(`${split[2]}-${split[0]}-${split[1]}`);
    }

    return render;
  };

  renderStatusDropdown = () => {
    const { id: studioId } = this.props;
    const { status } = this.state;
    return (
      <div className="StatusItem">
        <Dropdown overlay={this.getEnableDisableDropdownMenu(studioId)}>
          <Button className={this.getStatusCssClass(status)}>
            {this.getStatusText(status)}
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
    );
  };

  renderCatalog = (item, index, catalogs) => {
    let separator = ',';
    if (index === catalogs.length - 1) {
      separator = '';
    }
    const { properties, name } = item;
    return (
      <div className="Catalog-Container" key={`movie-catalog-${index}`}>
        <span
          className="Movie-Catalog"
          onClick={this.showEditCatalogModal.bind(this, properties.id, properties.name)}
        >
          {name}
        </span>
        <span>{separator}</span>
      </div>
    );
  };

  renderMovieCatalogs = () => {
    const { movie_catalogs } = this.props;
    return movie_catalogs.map(this.renderCatalog);
  };

  renderDatePickerField = (key) => {
    return (
      <DatePicker value={this.state[key]}
        format={momentFormats.date}
        onChange={this.onChange.bind(this, key)} />
    );
  };

  renderInputField = (key, autoFocus) => {
    return (
      <BaseDelayedInput onChange={this.onChange.bind(this, key)}
        initialValue={this.state[key]}
        autoFocus={autoFocus} />
    );
  };

  renderMovieCatalogsEditField = () => {
    const { allCatalogs, movieCatalogs } = this.state;
    return (
      <BaseCheckboxList items={allCatalogs}
        checked={movieCatalogs}
        showSelectAll={true}
        onChange={this.onMovieCatalogsChange} />
    );
  };

  renderBaseCheckboxList = () => {
    const { properties } = this.props;
    const { downloadableOnPropertiesIDs } = this.state;

    return (
      <BaseCheckboxList
        onChange={this.onDownloadableChange}
        showSelectAll={true}
        checked={downloadableOnPropertiesIDs || []}
        items={properties}
      />
    );
  };

  renderEditableField = (key) => {
    let view = null;
    switch (key) {
      case 'name':
      case 'descriptionNs':
        view = this.renderInputField(key, key === 'name');
        break;
      case 'status':
        view = this.renderStatusDropdown();
        break;
      case 'foundedDate':
        view = this.renderDatePickerField(key);
        break;
      case 'movieCatalogs':
        view = this.renderMovieCatalogsEditField();
        break;
      case 'downloadableOnProperties':
        view = this.renderBaseCheckboxList();
        break;
      default:
        break;
    }

    return view;
  };

  renderStatus = (status) => {
    const classes = ['Status', this.getStatusCssClass(status)];
    return (
      <div className={classes.join(' ')}>
        {this.getStatusText(status)}
      </div>
    );
  };

  renderValueItem = (item, { key }) => {
    let render = key === 'status' ? this.renderStatus(item) : item;
    const { editMode } = this.state;
    if (editMode && this.isEditable(key)) {
      render = this.renderEditableField(key);
    }

    return render;
  };

  columns = [{
    dataIndex: 'name',
    key: 'name',
    width: 300
  }, {
    dataIndex: 'value',
    key: 'value',
    render: this.renderValueItem
  }];

  getStudioEditCatalogs = () => {
    const { movieCatalogs } = this.state;
    const { movie_catalogs } = this.props;
    const initialIds = movie_catalogs.map(this.getId);
    return areArraysEqual(initialIds, movieCatalogs) ? undefined : movieCatalogs;
  };

  getStudioEditStatus = () => {
    const { status } = this.state;
    const { status: initialStatus } = this.props;
    return initialStatus !== status ? status : undefined;
  };

  onSaveSuccess = () => {
    this.toggleEditMode();
  };

  renderFooter = () => {
    const { t } = this.props;
    const { editMode } = this.state;
    const key = editMode ? 'cancel' : 'edit'
    return (
      <div className="UserActions">
        {editMode ? (
          <Button onClick={this.saveChanges}
            disabled={!this.hasDataChanged()}>
            {t('StudioDetailsBodyTable.saveChanges')}
          </Button>
        ) : null}
        <Button className="EditStudioBtn"
          onClick={this.toggleEditMode}>
          {t(`StudioDetailsBodyTable.${key}`)}
        </Button>
      </div>
    );
  };

  saveChanges = () => {
    const { onStudioEdit, properties = [] } = this.props;
    const { name, descriptionNs, foundedDate, downloadableOnPropertiesIDs = [] } = this.state;
    const status = this.getStudioEditStatus();
    const movieCatalogs = this.getStudioEditCatalogs();
    const studiosOnPropertiesDataPost = properties.map(item => {
      const obj = {
        properties_id: item.id,
        downloadable: 0,
      };

      if (downloadableOnPropertiesIDs.includes(item.id)) {
        obj.downloadable = 1;
      }
      return obj;
    });

    onStudioEdit({
      descriptionNs,
      foundedDate,
      name,
      movieCatalogs,
      status,
      studiosOnPropertiesData: studiosOnPropertiesDataPost
    }, this.onSaveSuccess);
  };

  setStudioEnabled = (status) => {
    this.setState({ status });
  };

  showEditCatalogModal = (propertyId, propertyName) => {
    const modal = (
      <EditCatalogModal
        propertyName={propertyName}
        propertyId={propertyId}
      />
    );
    ModalController.showModal(modal);
  };

  toggleEditMode = () => {
    this.setState(prevState => {
      return {
        editMode: !prevState.editMode
      };
    });
  };

  render() {
    return (
      <Table className="StudioDetailsBodyTable"
        dataSource={this.getTableData()}
        columns={this.columns}
        showHeader={false}
        pagination={false}
        rowKey="key"
        footer={this.renderFooter}
        bordered />
    );
  }
}

StudioDetailsBodyTable.propTypes = {
  catalogs: PropTypes.array,
  studios_on_properties_data: PropTypes.array,
  description: PropTypes.string,
  descriptionNs: PropTypes.string,
  foundedOn: PropTypes.string,
  id: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  importedOn: PropTypes.string,
  lastSyncedOn: PropTypes.string,
  moviesCount: PropTypes.number.isRequired,
  onStudioEdit: PropTypes.func.isRequired,
  status: PropTypes.number
};

export default withTranslation()(StudioDetailsBodyTable);
