import { Button, Select } from "antd";
import { debounce } from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ConfirmationModal from "../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";
import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";
import { getCategoriesWithoutPagination } from "../../../../../../../../../services/categories-service/categories.service";
import {
	displayErrorNotification,
	displaySuccessNotification,
} from "../../../../../../../../../services/notification-service/notification.service";
import {
	getWhitelabelConfig,
	patchWhitelabelConfig,
} from "../../../../../../../../../services/properties-service/properties.service";
import { getStars } from "../../../../../../../../../services/stars-service/stars.service";
import { getStudios } from "../../../../../../../../../services/studio-service/studio.service";

import "./HomePageSpotlightModal.scss";

class HomePageSpotlightModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			starSearch: "",
			studioSearch: "",
			themeSearch: "",
			stars: [],
			studios: [],
			themes: [],
			selectedStar: undefined,
			selectedStudio: undefined,
			selectedTheme: undefined,
		};

		this.modalRef = React.createRef();

		this.handleStudiosSearch = debounce((value) => {
			if (value) {
				getStudios(1, [`search=${value}`, `elastic_score=desc`]).then(
					(res) =>
						this.setState({
							studios: res.data.data.studio.sort((a, b) =>
								a?.name > b?.name ? 1 : -1
							),
						})
				);
			} else {
				getStudios(1).then((res) =>
					this.setState({
						studios: res.data.data.studio.sort((a, b) =>
							a?.name > b?.name ? 1 : -1
						),
					})
				);
			}
		}, 1000);

		this.handleStarsSearch = debounce((value) => {
			if (value) {
				getStars(1, [`search=${value}`, "sort_by=elastic_score"]).then(
					(res) =>
						this.setState({
							stars: res.data.data.stars.sort((a, b) =>
								a?.name > b?.name ? 1 : -1
							),
						})
				);
			} else {
				getStars(1).then((res) =>
					this.setState({
						stars: res.data.data.stars.sort((a, b) =>
							a?.name > b?.name ? 1 : -1
						),
					})
				);
			}
		}, 1000);
	}

	componentDidMount() {
		const { propertyId } = this.props;

		const getSettings = [
			getWhitelabelConfig(propertyId),
			getStudios(1, ["name=asc"]),
			getStars(1, ["sort_by=a-z"]),
			getCategoriesWithoutPagination(),
		];
		this.getModal().setLoading("Loading");
		Promise.all(getSettings).then((res) => {
			const { star, studio, theme } = res[0].data.data.homepage_spotlight;

			this.setState(
				{
					studios: [
						...(!!studio?.id ? [studio] : []),
						...res[1].data.data.studio,
					],
					stars: [
						...(!!star?.id ? [star] : []),
						...res[2].data.data.stars,
					],
					themes: res[3].data.data.sort((a, b) =>
						a?.tag?.name > b?.tag?.name ? 1 : -1
					),
					selectedStar: star?.id || undefined,
					selectedStudio: studio?.id || undefined,
					selectedTheme: theme?.id || undefined,
				},
				this.getModal().clearLoading()
			);
		});
	}

	getActions = () => {
		const { t } = this.props;
		return (
			<div className="HomePageSpotlight-actions">
				<Button onClick={this.onCancel} key="cancelBtn">
					{t("HomePageSpotlight.cancel")}
				</Button>
				<Button onClick={this.saveChanges} key="saveChangesBtn">
					{t("HomePageSpotlight.saveChanges")}
				</Button>
			</div>
		);
	};

	getTitle = () => {
		const { t, propertyName } = this.props;
		const name = propertyName ? ` - ${propertyName}` : '';
		return `${t('HomePageSpotlight.title')}${name}`;
	};

	getModal = () => {
		return this.modalRef.current;
	};

	onCancel = () => {
		this.getModal().closeModal();
	};

	saveToAllWhitelabels = () => {
		const modal = (
			<ConfirmationModal
				title={"Save changes to all whitelabels"}
				message={"Are you sure?"}
				confirm={() => this.saveChanges(true)}
			/>
		);
		ModalController.showModal(modal);
	};

	saveChanges = () => {
		const { propertyId, applyToAll } = this.props;
		const saveToAllWhitelabels = !!applyToAll;
		const {
			selectedStar,
			selectedStudio,
			selectedTheme,
			stars,
			studios,
			themes,
		} = this.state;
		const star = stars.find((star) => star.id === selectedStar);
		const studio = studios.find((studio) => studio.id === selectedStudio);
		const theme = themes.find(
			(theme) => theme.tag.id === selectedTheme
		)?.tag;

		const homepage_spotlight = {
			...(star?.id && { star: { id: star.id, name: star.name } }),
			...(studio?.id && { studio: { id: studio.id, name: studio.name } }),
			...(theme?.id && { theme: { id: theme.id, name: theme.name } }),
		};
		patchWhitelabelConfig(saveToAllWhitelabels ? applyToAll : propertyId, {
			homepage_spotlight: homepage_spotlight,
		})
			.then(this.onSaveChangesSuccess)
			.catch(this.onSaveChangesFailure);
	};

	onSaveChangesSuccess = () => {
		this.getModal().clearLoading();
		displaySuccessNotification({
			duration: 3,
			message: this.props.t("HomePageSpotlight.saveChangesSuccess"),
		});
		this.onCancel();
	};

	onSaveChangesFailure = () => {
		this.getModal().clearLoading();
		displayErrorNotification({
			duration: 3,
			message: this.props.t("HomePageSpotlight.saveChangesFailure"),
		});
		this.onCancel();
	};

	renderStudioOptions = () => {
		const { t } = this.props;

		return (
			<div className="Option">
				Studios
				<Select
					showSearch
					allowClear
					value={this.state.selectedStudio}
					placeholder={t("HomePageSpotlight.searchStudio")}
					defaultActiveFirstOption={false}
					showArrow={false}
					filterOption={false}
					onSearch={this.handleStudiosSearch}
					onChange={(value) =>
						this.setState({ selectedStudio: value })
					}
					notFoundContent={null}
				>
					{this.state.studios.map((studio) => (
						<Select.Option key={studio.id} value={studio.id}>
							{studio.name}
						</Select.Option>
					))}
				</Select>
			</div>
		);
	};

	renderStarsOptions = () => {
		const { t } = this.props;

		return (
			<div className="Option">
				Stars
				<Select
					showSearch
					allowClear
					value={this.state.selectedStar}
					placeholder={t("HomePageSpotlight.searchStar")}
					defaultActiveFirstOption={false}
					showArrow={false}
					filterOption={false}
					onSearch={this.handleStarsSearch}
					onChange={(value) => this.setState({ selectedStar: value })}
					notFoundContent={null}
				>
					{this.state.stars.map((star) => (
						<Select.Option key={star.id} value={star.id}>
							{star.name}
						</Select.Option>
					))}
				</Select>
			</div>
		);
	};

	renderThemesOptions = () => {
		const { t } = this.props;

		return (
			<div className="Option">
				Themes
				<Select
					showSearch
					allowClear
					value={this.state.selectedTheme}
					placeholder={t("HomePageSpotlight.searchTheme")}
					defaultActiveFirstOption={false}
					showArrow={false}
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}
					onSearch={this.handleThemesFilter}
					onChange={(value) =>
						this.setState({ selectedTheme: value })
					}
					notFoundContent={null}
				>
					{this.state.themes.map((theme) => (
						<Select.Option key={theme.tag.id} value={theme.tag.id}>
							{theme.tag.name}
						</Select.Option>
					))}
				</Select>
			</div>
		);
	};

	render() {
		return (
			<ModalDialog
				title={this.getTitle()}
				actions={this.getActions()}
				forwardedRef={this.modalRef}
				className="HomePageSpotlightModal"
			>
				{this.renderStudioOptions()}
				{this.renderStarsOptions()}
				{this.renderThemesOptions()}
			</ModalDialog>
		);
	}
}

export default withTranslation()(HomePageSpotlightModal);
