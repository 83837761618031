import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, DatePicker, Input, Select, Table } from 'antd';
import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';
import {
    convertMomentToDate,
    daysToSeconds,
    momentFormats
} from '../../../../../../../../../../services/date-service/date.service';
import { displayErrorNotification } from '../../../../../../../../../../services/notification-service/notification.service';

import './EditMovieRecurrents.scss';

const { Option } = Select;

// Might have more options in future
// const recurrenceTypeOptions = [
//     { name: 'Free', value: 'free' },
//     { name: 'Busy', value: 'busy' }
// ];

const EditMovieRecurrents = (props) => {
    const { onMovieEdit, recurrence = [], property = {} } = props;
    const modalRef = useRef(null);
    const tempStateRef = useRef(null); // for Cancel button
    const hasDataChangedRef = useRef(JSON.stringify(recurrence));
    const shouldAddNewIRecurrence = useRef(null); // prevent Add new if edit in progress

    const [selectedRow, setSelectedRow] = useState(null); // index is used instead ID
    const [state, setState] = useState(recurrence);

    const getModal = () => modalRef.current;

    const onCloseModal = () => getModal().closeModal();

    const onModalSave = () => {
        const postData = state.map(item => {
            delete item.id; // server will ignore it anyway
            // convert to UTC  
            item.recurrence_first_start = convertMomentToDate(item.recurrence_first_start, true, true);
            item.recurrence_first_end = convertMomentToDate(item.recurrence_first_end, true, true);
            return item;
        });

        if (!shouldAddNewIRecurrence.current) {
            getModal().setLoading('Saving data. Please wait');
            onMovieEdit(postData, onCloseModal);
        }
    };

    const hasDataChanged = () => JSON.stringify(state) === hasDataChangedRef.current;

    const getModalActions = () => {
        return (
            <>
                <Button onClick={onCloseModal}>Cancel</Button>
                <Button onClick={onModalSave} disabled={hasDataChanged()}>Save Changes</Button>
            </>
        );
    };

    const onChange = (key, value, index) => {
        const dateTime = key === 'recurrence_time' ? daysToSeconds(value) : value;

        const newState = [...state];
        newState.map((item, i) => {
            if (i === index) {
                item[key] = dateTime;
            }
            return item;
        });

        setState(newState);
    };

    const renderPicker = (value, key, index) => {
        let view = moment(value).isValid() ? moment(value).format(momentFormats.shortMonthDate) : null;
        const classNames = key === 'recurrence_first_start' ? 'StartDate' : 'EndDate';

        if (index === selectedRow) {
            view = (
                <DatePicker
                    value={moment(value).isValid() ? moment(value) : null}
                    format={momentFormats.shortMonthDate}
                    onChange={val => onChange(key, val, index)}
                    size='small'
                    showTime={true}
                    className={classNames}
                />
            );
        }
        return view;
    };

    const renderDropdown = (value = '', index) => {
        let view = value;
        let title = value.charAt(0).toUpperCase() + value.slice(1);

        if (index === selectedRow) {
            view = (
                <Select
                    defaultValue={value}
                    size='small'
                    onChange={() => onChange(value)}
                >
                    <Option key={value} value={value}>{title}</Option>
                </Select>
            );
        }
        return view;
    };

    const renderInput = (value, key, index) => {
        const days = daysToSeconds(value, 1);
        let view = days || 1;

        if (index === selectedRow) {
            view = (
                <Input
                    type='number'
                    value={days ? days : ''}
                    onChange={(e) => onChange(key, e.target.value, index)}
                    className='InputNumber'
                />
            );
        }
        return view;
    };

    const dataIsValid = () => {
        const notification = (msg) => {
            displayErrorNotification({
                duration: 6,
                message: msg
            });
        };

        let isValid = true;
        state.forEach(item => {
            const startTime = moment(item.recurrence_first_start);
            const endTime = moment(item.recurrence_first_end);
            const recTime = item.recurrence_time;
            const startTimeValid = startTime.isSameOrAfter(endTime);

            if (startTimeValid) {
                notification('End date must be after Start date.');
                const startPicker = document.querySelector('.StartDate');
                const endPicker = document.querySelector('.EndDate');
                if (startPicker && endPicker) {
                    startPicker.style.border = '1px solid red';
                    endPicker.style.border = '1px solid red';
                }
                isValid = false;
            }
            else if (recTime <= 0) {
                notification('Value cannot be less than 0.');
                const inputNum = document.querySelector('.InputNumber');
                if (inputNum) {
                    inputNum.style.border = '1px solid red';
                }
                isValid = false;
            }
        });

        return isValid;
    };

    const deleteRecurrence = index => {
        const newState = state.filter((item, i) => i !== index);
        setState(newState);
        setSelectedRow(null);
        shouldAddNewIRecurrence.current = false;
    };

    const saveRecurrence = () => {
        if (!dataIsValid()) return;
        setSelectedRow(null);
        shouldAddNewIRecurrence.current = false;
    };

    const cancelRecurrence = (index) => {
        // keep the previous value if not saved
        const newState = state.map((item, i) => {
            if (index === i) {
                item = tempStateRef.current;
            }
            return item;
        });
        setState(newState);
        setSelectedRow(null);
        if (shouldAddNewIRecurrence.current) {
            deleteRecurrence(index);
        }
    };

    const editRecurrence = (data = {}, index) => {
        const { key, ...record } = data;
        // save current row data in tempStateRef to update state on Cancel
        tempStateRef.current = record;
        setSelectedRow(index);
        shouldAddNewIRecurrence.current = false;
    };

    const addRecurrence = () => {
        if (shouldAddNewIRecurrence.current) return;

        const newState = [...state];
        newState.push({
            recurrence_first_start: moment(),
            recurrence_first_end: moment().add(1, 'days'),
            recurrence_time: 86400, // one day in seconds
            recurrence_type: 'free',
        });
        setState(newState);
        setSelectedRow(newState.length - 1);
        shouldAddNewIRecurrence.current = true;
    };

    const renderAddRecurrenceBtn = () => {
        return (
            <div style={{ textAlign: 'right' }}>
                <Button className='Btn' onClick={() => addRecurrence()}>Add new</Button>
            </div>
        );
    };

    const renderTableRowActions = (data, index) => {
        return (
            <>
                {index === selectedRow ? (
                    <>
                        <Button className='Btn' onClick={() => saveRecurrence()}>Ok</Button>
                        <Button className='Btn' onClick={() => cancelRecurrence(index)}>Cancel</Button>
                    </>
                ) : (
                    <>
                        <Button className='Btn' onClick={() => editRecurrence(data, index)}>Edit</Button>
                        <Button className='Btn' onClick={() => deleteRecurrence(index)}>Delete</Button>
                    </>
                )}
            </>
        )
    };

    const renderTableData = () => {
        const dataSource = state.map((item, index) => {
            return {
                key: index,
                recurrence_first_start: item.recurrence_first_start,
                recurrence_first_end: item.recurrence_first_end,
                recurrence_time: item.recurrence_time,
                recurrence_type: item.recurrence_type,
            }
        });

        const columns = [{
            title: 'First start',
            dataIndex: 'recurrence_first_start',
            render: (val, data, index) => renderPicker(val, 'recurrence_first_start', index),
            width: '20%'
        }, {
            title: 'First end',
            dataIndex: 'recurrence_first_end',
            render: (val, data, index) => renderPicker(val, 'recurrence_first_end', index),
            width: '20%'
        }, {
            title: 'Repeat every _ days',
            dataIndex: 'recurrence_time',
            render: (val, data, index) => renderInput(val, 'recurrence_time', index),
            width: '20%'
        }, {
            title: 'Type',
            dataIndex: 'recurrence_type',
            render: (key, data, index) => renderDropdown(key, index),
            width: '15%'
        }, {
            title: 'Actions',
            dataIndex: 'actions',
            render: (key, data, index) => renderTableRowActions(data, index),
            align: 'center',
            width: '25%'
        }];

        return (
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                size='small'
                bordered
                title={() => renderAddRecurrenceBtn()}
                className='Table'
            />
        )
    };

    const getModalTitle = () => `Edit Movie Recurrents - ${property.name}`;

    return (
        <ModalDialog className='EditRecurrentsModal'
            title={getModalTitle()}
            actions={getModalActions()}
            forwardedRef={modalRef}>
            <div className='EditRecurrentsModal-inner'>
                {renderTableData()}
            </div>
        </ModalDialog>
    )
};

EditMovieRecurrents.propTypes = {
    property: PropTypes.object,
    recurrence: PropTypes.array,
    onMovieEdit: PropTypes.func
};

export default EditMovieRecurrents;