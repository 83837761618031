import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, List, Table } from 'antd';
import PropTypes from 'prop-types';

import { getStarDetailsRoute } from '../../../../../../../../../../services/navigation/stars-navigation/stars-navigation-routes.service';
import { getScrollHeight } from "../../../../../../../../../../services/util-service/util.service";
import { renderMoment } from "../../../../../../../../../../services/date-service/date.service";

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";

import './StarsViewList.scss';

class StarsViewList extends Component {

  sort_maps = {
    name: 'a-z',
    birthDate: 'birth_date',
    elastic_score: 'elastic_score',
    gender: 'gender',
    id: 'id',
    images: 'image_cnt',
    movies: 'movie_cnt'
  };

  onStarEdit = (id, name) => {
    const { onStarEdit } = this.props;
    onStarEdit(id, name);
  };

  renderActionItem = ({ id, name }) => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <LinkButton href={getStarDetailsRoute(id)}>
          {t('StarsViewList.viewDetails')}
        </LinkButton>
        <Button className="EditStar"
          onClick={this.onStarEdit.bind(this, id, name)}>
          {t('StarsViewList.edit')}
        </Button>
      </React.Fragment>
    );
  };

  renderBirthDate = (birthDate) => {
    let render = '';
    if (birthDate) {
      const split = birthDate.split('/');
      render = renderMoment(`${split[2]}-${split[0]}-${split[1]}`);
    }

    return render;
  };

  renderGender = gender => {
    return this.props.t(`StarsViewList.${gender}`);
  };

  columns = [
    {
      sorter: true,
      title: this.props.t('StarsViewList.id'),
      dataIndex: 'id',
      key: 'id',
      width: 100
    },
    {
      sorter: true,
      title: this.props.t('StarsViewList.name'),
      dataIndex: 'name',
      key: 'name',
      width: 200
    },
    {
      sorter: true,
      title: this.props.t('StarsViewList.movies'),
      dataIndex: 'movies',
      key: 'movies',
      width: 100
    },
    {
      sorter: true,
      title: this.props.t('StarsViewList.images'),
      key: 'images',
      dataIndex: 'images',
      width: 100
    },
    {
      sorter: true,
      title: this.props.t('StarsViewList.gender'),
      key: 'gender',
      dataIndex: 'gender',
      width: 100,
      render: this.renderGender
    },
    {
      sorter: true,
      title: this.props.t('StarsViewList.birthDate'),
      key: 'birthDate',
      render: this.renderBirthDate,
      dataIndex: 'birthDate',
      width: 200
    },
    {
      title: this.props.t('StarsViewList.studio'),
      key: 'studio',
      dataIndex: 'studio',
      width: 200
    },
    {
      title: this.props.t('StarsViewList.action'),
      align: 'center',
      key: 'action',
      render: this.renderActionItem,
      width: 250
    }
  ];

  rowHeight = 65;

  calcTableHeight = () => {
    const { contentHeight, dataSource } = this.props;
    const reductionHeight = 51 + 54 + 52;
    return getScrollHeight(contentHeight, dataSource, this.rowHeight, reductionHeight);
  };

  renderItemValue = (value, key) => {
    const { t } = this.props;
    const localeKey = `StarsViewList.${key}`;
    if (!value) {
      value = '';
    } else if (key === 'gender') {
      value = t(`StarsViewList.${value}`);
    }
    return (
      <div className='StarsViewList-list-item'>
        <span>{t(localeKey)}: </span>
        {key === 'birthDate' ? this.renderBirthDate(value) : value}
      </div>
    );
  };

  getCardFooter = (item) => {
    return (
      <div className="StarsViewList-card-footer">
        {this.renderActionItem(item, item)}
      </div>
    );
  };

  renderListItem = item => {
    return (
      <BaseCard title={item.name}
        footer={this.getCardFooter(item)}>
        {this.renderItemValue(item.id, 'id')}
        {this.renderItemValue(item.movies, 'movies')}
        {this.renderItemValue(item.images, 'images')}
        {this.renderItemValue(item.gender, 'gender')}
        {this.renderItemValue(item.birthDate, 'birthDate')}
        {this.renderItemValue(item.studio, 'studio')}
      </BaseCard>
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { filterBasedOnCriteria } = this.props;
    let { field, order } = sorter;
    if (!field) {
      field = this.sort_maps.elastic_score;
    }
    let sort_by = this.sort_maps[field];
    if (field) {
      const sortOrder = order ? {
        sort_order: order.replace('end', '')
      } : {};
      filterBasedOnCriteria({
        sort_by,
        ...sortOrder
      });
    }
  };

  render() {
    return (
      <div className='StarsViewContent'>
        <List
          className='StarsViewList-list'
          dataSource={this.props.dataSource}
          renderItem={this.renderListItem} />
        <Table
          className='StarsViewList-table'
          columns={this.columns}
          onChange={this.handleTableChange}
          dataSource={this.props.dataSource}
          pagination={false}
          rowKey='id'
          bordered
          scroll={this.calcTableHeight()} />
      </div>
    );
  }
}

StarsViewList.propTypes = {
  contentHeight: PropTypes.number,
  dataSource: PropTypes.array.isRequired,
  onStarEdit: PropTypes.func.isRequired,
  filterBasedOnCriteria: PropTypes.func
};

export default withTranslation()(StarsViewList);
