import axios from 'axios';

export const urls = {
  adsConfig: '/ads_config',
};

export const getExistingNatsAdsConfigs = () => {
  return axios.get(`${urls.adsConfig}/nats_codes`);
}

export const getAdsConfig = ({movieId, propertyId, natsCode}) => {
  return axios.get(`${urls.adsConfig}`, {
    params: {
      movies_id: movieId,
      properties_id: propertyId,
      nats_code: natsCode
    }
  });
};

export const setAdsConfig = ({movieId, propertyId, natsCodes, config}) => {
  return axios.patch(`${urls.adsConfig}`, undefined, {
    params: {
      movies_id: movieId,
      properties_id: propertyId,
      nats_codes: natsCodes,
      ads_config: JSON.stringify(config)
    }
  });
};

export const removeAdsConfig = (id) => {
  return axios.delete(`${urls.adsConfig}/${id}`);
};