import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button} from "antd";

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import './ErrorsFoundDialog.scss';

/* istanbul ignore file */
class ErrorsFoundDialog extends Component {

  modalRef = React.createRef();

  closeModal = () => {
    this.getModal().closeModal();
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.closeModal}>
          {t('ConfigureAdsOnPropertyLevel.ErrorsFoundDialog.close')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('ConfigureAdsOnPropertyLevel.ErrorsFoundDialog.title');
  };

  render() {
    const {t} = this.props;
    return (
      <ModalDialog className="ErrorsFoundDialog"
                   title={this.getTitle()}
                   actions={this.getActions()}
                   forwardedRef={this.modalRef}>
        <div className="ErrorsFoundDialog-inner">
          <div className="Title">
            {t('ConfigureAdsOnPropertyLevel.ErrorsFoundDialog.description.title')}
          </div>
          <div className="Subtitle">
            {t('ConfigureAdsOnPropertyLevel.ErrorsFoundDialog.description.subtitle')}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

export default withTranslation()(ErrorsFoundDialog);
