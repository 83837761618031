import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Icon, Radio } from "antd";
import PropTypes from "prop-types";

import AdConfigurationPanel from "../../../../shared/AdConfigurationPanel/AdConfigurationPanel";
import BaseCard from "../../../../../../../../../../../../../components/BaseCard/BaseCard";

import "./AdConfigPanel.scss";

/* istanbul ignore file */
class AdConfigPanel extends Component {
	adTypes = [
		{
			name: this.props.t("AdConfigPanel.image"),
			value: "image",
		},
		{
			name: this.props.t("AdConfigPanel.video"),
			value: "video",
		},
	];

	data;

	state = {
		applyToLoggedUser: true,
		applyToNotLoggedUser: true,
		selectedTarget: '_self',
		start: this.props.startInSeconds,
		url: "",
		type: "",
		goToText: "",
		goToUrl: "",
		videoType: "",
	};

	constructor(props) {
		super(props);
		const {
			applyToLoggedUser,
			applyToNotLoggedUser,
			selectedTarget,
			start,
			url,
			goToText,
			goToUrl,
			type,
			videoType,
			duration,
		} = props.ad;
		Object.assign(this.state, {
			applyToLoggedUser:
				applyToLoggedUser !== undefined ? applyToLoggedUser : true,
			applyToNotLoggedUser:
				applyToNotLoggedUser !== undefined
					? applyToNotLoggedUser
					: true,
			selectedTarget: selectedTarget || '_self',
			duration,
			start,
			url,
			type: type ? type : this.adTypes[0].value,
			goToText,
			goToUrl,
			videoType,
		});
	}

	getAdCardTitle = () => {
		const { index, t } = this.props;
		const removeEl = this.props.removeAdd ? (
			<Icon type="close" onClick={this.removeAdd} />
		) : null;
		return (
			<div className="AddHeader">
				<div className="Text">
					{t("AdConfigPanel.adCardTitle", { index })}
				</div>
				{removeEl}
			</div>
		);
	};

	onAddConfigChange = (adIndex, data) => {
		const { index, onAddConfigChange } = this.props;
		const { applyToLoggedUser, applyToNotLoggedUser, selectedTarget } =
			this.state;
		if (data) {
			// Save ad config panel changed data for when user state change happens
			this.data = data;
		}
		const adData = data || this.data || this.state;
		// If data then ads config panel change happened
		// If no data but this.data then user state change happened and ad config previous change data exists
		// If no data and no this.data then user state change happened but ads config change hasn't happened
		const { duration, start, url, type, goToText, goToUrl, videoType } =
			adData;
		onAddConfigChange(index, {
			applyToLoggedUser,
			applyToNotLoggedUser,
			selectedTarget,
			duration,
			goToText,
			goToUrl,
			start,
			type,
			url,
			videoType,
		});
	};

	removeAdd = () => {
		this.props.removeAdd();
	};

	renderUserStateConfig = () => {
		const { t } = this.props;
		const { applyToLoggedUser, applyToNotLoggedUser } = this.state;
		return (
			<div className="AdUserStateConfig">
				<div className="UserStateManagement">
					<div className="Text">
						{t(
							"ConfigureAdsOnMovieLevel.AdConfigPanel.applyToLoggedUser"
						)}
					</div>
					<Checkbox
						checked={applyToLoggedUser}
						onChange={this.setUserSetting.bind(
							this,
							"applyToLoggedUser"
						)}
					/>
				</div>
				<div className="UserStateManagement">
					<div className="Text">
						{t(
							"ConfigureAdsOnMovieLevel.AdConfigPanel.applyToNotLoggedUser"
						)}
					</div>
					<Checkbox
						checked={applyToNotLoggedUser}
						onChange={this.setUserSetting.bind(
							this,
							"applyToNotLoggedUser"
						)}
					/>
				</div>
			</div>
		);
	};

	renderTargetSelectionConfig = () => {
		const { selectedTarget } = this.state;
		return (
			<div className="AdTargetSelection">
				<Radio.Group
					onChange={this.onAdTargetChange.bind(
						this,
						"selectedTarget"
					)}
					value={selectedTarget}
				>
					<div className="SectionOptions">
						<div className="Text">Open in new tab</div>
						<Radio value="_blank"></Radio>
					</div>
					<div className="SectionOptions">
						<div className="Text">Open in same tab</div>
						<Radio value="_self"></Radio>
					</div>
				</Radio.Group>
			</div>
		);
	};

	setUserSetting = (key, event) => {
		this.setState(
			{
				[key]: event.target.checked,
			},
			this.onAddConfigChange
		);
	};

	onAdTargetChange = (key, event) => {
		this.setState(
			{
				[key]: event.target.value,
			},
			this.onAddConfigChange
		);
	};

	render() {
		const { index, endInSeconds, startInSeconds } = this.props;
		return (
			<BaseCard className="AdConfigPanel" title={this.getAdCardTitle()}>
				{this.renderUserStateConfig()}
				{this.renderTargetSelectionConfig()}
				<AdConfigurationPanel
					endInSeconds={endInSeconds}
					startInSeconds={startInSeconds}
					ad={this.state}
					index={index}
					onAddConfigChange={this.onAddConfigChange}
				/>
			</BaseCard>
		);
	}
}

AdConfigPanel.propTypes = {
	ad: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	onAddConfigChange: PropTypes.func.isRequired,
	endInSeconds: PropTypes.number.isRequired,
	startInSeconds: PropTypes.number.isRequired,
};

export default withTranslation()(AdConfigPanel);
