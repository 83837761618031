import {Subject} from "rxjs";

/* istanbul ignore file */
export class ConfigureAdsOnPropertyLevelCtrl {
  static instance;
  validationEvent = new Subject();

  static getInstance() {
    if (ConfigureAdsOnPropertyLevelCtrl.instance == null) {
      ConfigureAdsOnPropertyLevelCtrl.instance = new ConfigureAdsOnPropertyLevelCtrl();
    }

    return ConfigureAdsOnPropertyLevelCtrl.instance;
  }

  validateData = () => {
    const instance = ConfigureAdsOnPropertyLevelCtrl.getInstance();
    instance.validationEvent.next();
  };
}

export const ConfigureAdsOnPropertyLevelController = ConfigureAdsOnPropertyLevelCtrl.getInstance();
