import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, InputNumber, Table} from "antd";

import ConfirmationModal
  from "../../../../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";
import {ModalController} from "../../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../../../../services/notification-service/notification.service';
import {moveInArray, renderListClickableItem} from "../../../../../../../../../../../../../services/util-service/util.service";
import {getMovieDetailsRoute} from "../../../../../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";
import {deleteMovieInPlaylist, moviePlaylistReorder} from '../../../../../../../../../../../../../services/movies-service/movies.service';

import './MoviesPlaylistMoviesTab.scss';

class MoviesPlaylistMoviesTab extends Component {

  constructor (props) {
    super(props);

    this.state = {
      movies: []
    }
  }

  componentDidMount() {
    this.setState({movies: this.props.detailsData.movies_in_playlist});
  }

  renderActions = (item, data) => {
    const {t} = this.props;
    return (
      <>
      <Button onClick={this.showDeleteConfirmationModal.bind(this, data)}>
        {t('MoviesPlaylistMoviesTab.removeMovie')}
      </Button>

      <Button onClick={this.moveMovie.bind(this, data, -1, true)}>
        <i className="fas fa-angle-up"/>
      </Button>

      <Button onClick={this.moveMovie.bind(this, data, 1, true)}>
        <i className="fas fa-angle-down"/>
      </Button>

      <InputNumber onKeyDown={(event) => this.handleReorderMovieInput(event, data)}/>
      </>
    );
  };

  renderMovieName = (item, data) => {
    const {movie_id} = data;
    return renderListClickableItem(data, undefined, `movie-${movie_id}`, getMovieDetailsRoute.bind(this, movie_id), 'title');
  };

  columns = [
    {
      dataIndex: 'title',
      title: this.props.t('MoviesPlaylistMoviesTab.name'),
      render: this.renderMovieName,
      width: 200
    },
    {
      align: 'center',
      dataIndex: 'actions',
      title: this.props.t('MoviesPlaylistMoviesTab.actions'),
      render: this.renderActions,
      width: 200
    }
  ];

  deleteMovie = (data) => {
    const {id} = data;

    deleteMovieInPlaylist(id)
      .then(this.onDeleteMovieInPlaylistSuccess)
      .catch(this.onDeleteMovieInPlaylistFailure)
  };

  onDeleteMovieInPlaylistFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('MoviesPlaylistMoviesTab.deleteMovieFailed')
    });
  };

  onDeleteMovieInPlaylistSuccess = () => {
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('MoviesPlaylistMoviesTab.deleteMovieSuccess')
    });

    this.props.onMovieDelete();
  };

  handleReorderMovieInput = (event, data) => {
    if (event.keyCode === 13) {
      this.moveMovie(data, (event.target.value - 1))
    }
  }

  moveMovie = (data, position, direction = undefined) => {
    const movieList = this.state.movies;
    const {id: playlistId} = this.props.detailsData;
    const {movie_id: movieId} = data;
    const movieIndex = movieList.findIndex(movie => movie.movie_id === movieId);
    if (direction)
      position = movieIndex + position; // Turn position into a number

    if (position >= 0 && position < movieList.length) {
      moveInArray(movieList, movieIndex, position);
      const putBeforeIndex = movieList?.[position + 1]?.movie_id || null;
      this.setState({movies: movieList});
      moviePlaylistReorder(playlistId, movieId, putBeforeIndex);
    }
    else {
      displayErrorNotification({
        duration: 3,
        message: `Cannot move further ${position > 0 ? "down" : "up"}`
      });
    }
  }

  renderMoviesTable = () => {
    return (
      <Table
        dataSource={this.state.movies}
        rowKey='id'
        bordered
        columns={this.columns}
        pagination={false}
      />
    );
  };

  showDeleteConfirmationModal = (data) => {
    const {t} = this.props;
    const modal = (
      <ConfirmationModal
        title={t('MoviesPlaylistMoviesTab.confirmDelete')}
        message={t('MoviesPlaylistMoviesTab.deletionQuestion')}
        yesBtnText={t('MoviesPlaylistMoviesTab.deleteBtn')}
        noBtnText={t('MoviesPlaylistMoviesTab.cancel')}
        confirm={this.deleteMovie.bind(this, data)}
      />
    );
    ModalController.showModal(modal);
  };

  render() {
    return (
      <div className="MoviesPlaylistMoviesTab">
        {this.renderMoviesTable()}
      </div>
    )
  }
};

export default withTranslation()(MoviesPlaylistMoviesTab);