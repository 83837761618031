import React from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import StudioMoviesFooter from "../components/StudioMoviesFooter/StudioMoviesFooter";
import StudioMoviesList from "../components/StudioMoviesList/StudioMoviesList";
import MoviesMainViewAbstract from "../../../MoviesView/MoviesMainViewAbstract/MoviesMainViewAbstract";


class StudioMoviesMainView extends MoviesMainViewAbstract {

  renderFooter = () => {
    const { data } = this.props;
    return <StudioMoviesFooter onChange={this.props.onChange}
      currentPage={data.currentPage}
      pageCount={data.pageCount}
      pageSize={data.pageSize}
      recordCount={data.recordCount} />;
  };

  renderMoviesList = () => {
    const {
      data,
      studioId,
      setMovieEnabled,
      additionalActions,
      onMovieEdit,
      onSpecialCategoriesEdit,
      onMovieEdit2257,
      scrollTop } = this.props;
    return (
      <StudioMoviesList movies={data.movies}
        onMovieEdit={onMovieEdit}
        onMovieEdit2257={onMovieEdit2257}
        onSpecialCategoriesEdit={onSpecialCategoriesEdit}
        studioId={studioId}
        setMovieEnabled={setMovieEnabled}
        additionalActions={additionalActions}
        scrollTop={scrollTop} />
    )
  };
}

StudioMoviesMainView.propTypes = {
  studioId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setMovieEnabled: PropTypes.func.isRequired,
  additionalActions: PropTypes.object.isRequired
};

export default withTranslation()(StudioMoviesMainView);
