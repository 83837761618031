import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";
import { Button, Checkbox, Table } from "antd";
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import {
  editWhitelabelConfig,
  getWhitelabelConfig
} from "../../../../../../../../../services/properties-service/properties.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

import BaseDragDropList from "../../../../../../../../../components/BaseDragDropList/BaseDragDropList";
import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import InfoTooltip from '../../../../../../../../../components/InfoTooltip/InfoTooltip';
import BrowseConfigurationAddNewItemModal
  from "./BrowseConfigurationAddNewItemModal/BrowseConfigurationAddNewItemModal";

import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import './SetBrowseConfigurationModal.scss';

/* istanbul ignore file */
class SetBrowseConfigurationModal extends Component {

  renderValue = (value, record) => {
    let view = (
      <div className="Value">
        {value}
      </div>
    );
    if (['openInNewWindow', 'loggedIn', 'loggedOut'].indexOf(record.key) !== -1) {
      view = (
        <div className="Value">
          <Checkbox checked={value} disabled={true} />
        </div>
      );
    }

    return view;
  };

  columns = [{
    dataIndex: 'name'
  }, {
    dataIndex: 'value',
    width: 300,
    render: this.renderValue
  }];

  entryKey = 'browse_config';

  modalRef = React.createRef();

  state = {
    dataLoaded: false,
    whiteLabelConfig: {}
  };

  componentDidMount() {
    this.fetchData();
  }

  clearLoading = () => {
    this.getModal().clearLoading();
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  editItem = (item) => {
    const { id, link, open_in_new_window: openInNewWindow, text, logged_in = true, logged_out = true } = item;
    const modal = (
      <BrowseConfigurationAddNewItemModal
        id={id}
        text={text}
        link={link}
        loggedIn={logged_in}
        loggedOut={logged_out}
        openInNewWindow={openInNewWindow}
        onItemAdd={this.onItemEdit}
      />
    );
    ModalController.showModal(modal);
  };

  generateId = () => new Date().getTime();

  fetchData = () => {
    this.setLoading(this.props.t('SetBrowseConfigurationModal.loading'));
    const { propertyId } = this.props;
    getWhitelabelConfig(propertyId)
      .then(this.loadData)
      .catch(this.onRequestFailure);
  }

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.showAddNewBrowseItemModal}>
          {t('SetBrowseConfigurationModal.addNewItem')}
        </Button>
        <div className="Inner">
          <Button onClick={this.closeModal} key="cancelBtn">
            {t('SetBrowseConfigurationModal.cancel')}
          </Button>
          <Button onClick={this.saveBrowseConfiguration} key="saveBtn">
            {t('SetBrowseConfigurationModal.saveBrowseConfiguration')}
          </Button>
        </div>
      </div>
    );
  };

  getModal = () => this.modalRef.current;

  getTitle = () => {
    const { t, propertyName } = this.props;
    const name = propertyName ? ` - ${propertyName}` : '';

    return (
      <div>
        <InfoTooltip text='Submenu in the header -> Browse' />
        {t('SetBrowseConfigurationModal.title')} {name}
      </div>
    )
  };

  loadData = (response) => {
    const { data: whiteLabelConfig } = response.data;
    this.setState({
      dataLoaded: true,
      whiteLabelConfig
    });
    this.clearLoading();
  };

  matchItemById = (matchId, { id }) => matchId === id;

  onEditWhiteLabelConfigFailure = () => {
    this.clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('SetBrowseConfigurationModal.setBrowseConfigurationFailure')
    });
  };

  onEditWhiteLabelConfigSuccess = () => {
    this.clearLoading();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('SetBrowseConfigurationModal.setBrowseConfigurationSuccess')
    });
    this.closeModal();
  };

  onItemAdd = ({ link, openInNewWindow, text, loggedIn = true, loggedOut = true }) => {
    this.setState(prevState => {
      const whiteLabelConfig = cloneDeep(prevState.whiteLabelConfig);
      let data = whiteLabelConfig[this.entryKey];
      if (!data) {
        data = [];
      }
      data.push({
        id: this.generateId(),
        link,
        open_in_new_window: openInNewWindow,
        text,
        logged_in: loggedIn,
        logged_out: loggedOut
      });
      whiteLabelConfig[this.entryKey] = data;
      return { whiteLabelConfig };
    });
  };

  onItemEdit = ({ link, openInNewWindow, text, loggedIn, loggedOut }, id) => {
    this.setState(prevState => {
      const whiteLabelConfig = cloneDeep(prevState.whiteLabelConfig);
      const index = whiteLabelConfig[this.entryKey].findIndex(this.matchItemById.bind(this, id));
      if (index !== -1) {
        whiteLabelConfig[this.entryKey][index] = {
          id,
          link,
          open_in_new_window: openInNewWindow,
          logged_in: loggedIn,
          logged_out: loggedOut,
          text
        };
      }
      return { whiteLabelConfig };
    });
  };

  onOrderChange = (items) => {
    this.setState(prevState => {
      const { whiteLabelConfig } = prevState;
      const data = cloneDeep(whiteLabelConfig);
      data[this.entryKey] = items;
      return {
        whiteLabelConfig: data
      };
    });
  };

  onRequestFailure = (error) => {
    LogController.logError(error);
    this.clearLoading();
  };

  removeItem = (id) => {
    this.setState(prevState => {
      const whiteLabelConfig = cloneDeep(prevState.whiteLabelConfig);
      const index = whiteLabelConfig[this.entryKey].findIndex(this.matchItemById.bind(this, id));
      if (index !== -1) {
        whiteLabelConfig[this.entryKey].splice(index, 1);
      }
      return { whiteLabelConfig };
    });
  };

  renderBrowseItem = ({ link, open_in_new_window: openInNewWindow, text, logged_in = true, logged_out = true }, index) => {
    const { t } = this.props;
    const data = [{
      name: t('SetBrowseConfigurationModal.displayText'),
      key: 'text',
      value: text
    }, {
      name: t('SetBrowseConfigurationModal.navigationLink'),
      key: 'link',
      value: link
    }, {
      name: t('SetBrowseConfigurationModal.openInNewWindow'),
      key: 'openInNewWindow',
      value: openInNewWindow
    }, {
      name: t('SetBrowseConfigurationModal.loggedIn'),
      key: 'loggedIn',
      value: logged_in
    }, {
      name: t('SetBrowseConfigurationModal.loggedOut'),
      key: 'loggedOut',
      value: logged_out
    }];
    return (
      <div className="BrowseItem">
        <div className="BrowseItemIndex">{index + 1}</div>
        <div className="BrowseItem-inner">
          <Table columns={this.columns}
            rowKey="key"
            dataSource={data}
            pagination={false}
            bordered={true}
            showHeader={false} />
        </div>
      </div>
    );
  };

  renderItemActions = (item) => {
    const { t } = this.props;
    return (
      <div className="Actions">
        <Button key="editBtn" onClick={this.editItem.bind(this, item)}>
          {t('SetBrowseConfigurationModal.edit')}
        </Button>
        <Button key="removeBtn" onClick={this.removeItem.bind(this, item.id)}>
          {t('SetBrowseConfigurationModal.remove')}
        </Button>
      </div>
    );
  };

  renderMainView = () => {
    const { dataLoaded, whiteLabelConfig } = this.state;
    let view = null;
    if (dataLoaded) {
      const data = whiteLabelConfig[this.entryKey];
      if (data && data.length) {
        view = (
          <BaseDragDropList items={data}
            onOrderChange={this.onOrderChange}
            renderItem={this.renderBrowseItem}
            renderAdditional={this.renderItemActions} />
        );
      } else {
        const { t } = this.props;
        view = (
          <div className="NoItemsSet">
            {t('SetBrowseConfigurationModal.noBrowseConfigurationSet')}
          </div>
        );
      }
    }

    return view;
  };

  saveBrowseConfiguration = () => {
    const message = this.props.t('SetBrowseConfigurationModal.savingDataMsg');
    this.setLoading(message);
    const { whiteLabelConfig } = this.state;
    let { propertyId, applyToAll } = this.props;
    const config = { browse_config: whiteLabelConfig.browse_config };
    const applyToPropertyId = applyToAll || propertyId;

    editWhitelabelConfig(applyToPropertyId, config)
      .then(this.onEditWhiteLabelConfigSuccess)
      .catch(this.onEditWhiteLabelConfigFailure);
  };

  setLoading = (message) => {
    this.getModal().setLoading(message);
  };

  showAddNewBrowseItemModal = () => {
    const modal = (
      <BrowseConfigurationAddNewItemModal onItemAdd={this.onItemAdd} />
    );
    ModalController.showModal(modal);
  };

  render() {
    return (
      <ModalDialog className="SetBrowseConfigurationModal"
        forwardedRef={this.modalRef}
        title={this.getTitle()}
        actions={this.getActions()}>
        <div className="SetBrowseConfigurationModal-inner">
          {this.renderMainView()}
        </div>
      </ModalDialog>
    );
  }
}

SetBrowseConfigurationModal.propTypes = {
  propertyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  propertyName: PropTypes.string,
  applyToAll: PropTypes.string
};

export default withTranslation()(SetBrowseConfigurationModal);
