import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'antd';

import './MoviePlaylistDetailsFooter.scss';

class MoviePlaylistDetailsFooter extends Component {
  render() {
    const {t} = this.props;
    return (
      <div className='MoviePlaylistDetailsFooter'>
        <div className='MoviePlaylistDetailsFooter-inner'>
          <Button onClick={this.props.deletePlaylist}>
            <Icon type='delete' />
            {t('MoviePlaylistDetailsFooter.deletePlaylist')}
          </Button>
          <Button onClick={this.props.editPlaylist}>
            <Icon type='edit' />
            {t('MoviePlaylistDetailsFooter.editPlaylist')}
          </Button>
          <Button onClick={this.props.showAddMoviesModal}>
            <Icon type='file-add' />
            {t('MoviePlaylistDetailsFooter.addMovies')}
          </Button>
        </div>
      </div>
    );
  };
};

export default withTranslation()(MoviePlaylistDetailsFooter);