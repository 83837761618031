import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ImagesLightBox from "../ImagesLighbox/ImagesLighbox";

class BaseImagesFullScreen extends Component {

  images = [];

  state = {
    fullScreen: false,
    index: 0
  };

  getImageUrl = (image) => {
    const {getImageUrl} = this.props;
    return getImageUrl ? getImageUrl(image) : image.url;
  };

  renderImageFullScreen = () => {
    const {fullScreen, index} = this.state;
    return fullScreen ? (
      <ImagesLightBox currentIndex={index}
                      images={this.images}
                      onClose={this.toggleFullScreen}/>
    ) : null;
  };

  toggleFullScreen = (index) => {
    this.setState(prevState => {
      if (index === undefined) {
        index = prevState.index;
      }
      return {
        fullScreen: !prevState.fullScreen,
        index
      };
    });
  };

  render() {
    const classes = ['BaseImagesFullScreen'];
    const {className, images, children} = this.props;
    if (className) {
      classes.push(className);
    }
    this.images = images.map(this.getImageUrl);
    return (
      <div className={classes.join(' ')}>
        {children}
        {this.renderImageFullScreen()}
      </div>
    );
  }
}

BaseImagesFullScreen.propTypes = {
  className: PropTypes.string,
  getImageUrl: PropTypes.func,
  images: PropTypes.array.isRequired
};

export default BaseImagesFullScreen;
