import React, { Component } from 'react';
import { Router } from "@reach/router";
import { withTranslation } from "react-i18next";

import { getRoyaltiesUsers } from "../../../../../../../../../services/royalties-service/royalties.service";

import RoyaltiesUsersViewBody from "./RoyaltiesUsersViewBody/RoyaltiesUsersViewBody";
import RoyaltiesUsersViewFooter from './RoyaltiesUsersViewFooter/RoyaltiesUsersViewFooter';
import RoyaltiesUserDetails from './RoyaltiesUserDetails/RoyaltiesUserDetails';
import RoyaltiesUsersViewHeader from './RoyaltiesUsersViewHeader/RoyaltiesUsersViewHeader';

import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";

import { toggleRoyaltiesUserEnabled } from "../../../../../../../../../services/users-service/users.service";
import { getRoyaltiesUsersPage } from "../../../../../../../../../services/royalties-service/royalties.service";

import { cloneDeep } from 'lodash';

import './RoyaltiesUsersView.scss';

class RoyaltiesUsersView extends Component {

  defaultFilters = {};
  filters = [];

  state = {
    dataLoaded: false,
    loading: false,
    pagination: {
      current_page: 1,
      last_page: 0,
      per_page: 0,
      total: 0
    },
    users: []
  };

  componentDidMount() {
    this.fetchRoyaltiesUsers(1);
  }

  fetchRoyaltiesUsers = (page) => {
    this.setState({
      loading: true
    });
    // const filters = this.getFilters();
    getRoyaltiesUsers(page)
      .then(this.onGetRoyaltiesUsersSuccess)
      .catch(this.onGetRoyaltiesUsersFailure);
  };

  getFilters = () => {
    return this.filters;
  };

  onFilterChange = (filters, email, display) => {
    this.defaultFilters = { display: display, email: email };
    this.filters = filters;
    this.fetchRoyaltiesUsers(1);
  };

  onGetRoyaltiesUsersFailure = (error) => {
    LogController.logError(error);
  };

  onGetRoyaltiesUsersSuccess = response => {
    const { users, pagination } = response.data.data;

    this.setState({
      dataLoaded: true,
      users: users,
      loading: false,
      pagination: pagination
    });
  };

  onPageChange = (page) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        current_page: page
      }
    });
    this.fetchRoyaltiesUsers(page);
  };

  renderEnabled = enable => {
    return (enable === 1 ? 'Enabled' : 'Disabled');
  }

  refreshContent = () => {
    this.fetchRoyaltiesUsers(1);
  };

  renderFooter = () => {
    const { pathname } = this.props.location;
    const { current_page, total, per_page, last_page } = this.state.pagination;
    let footer = null;
    if (!pathname.includes('details')) {
      footer = (
        <RoyaltiesUsersViewFooter
          currentPage={current_page}
          pageSize={per_page}
          recordCount={total}
          onChange={this.onPageChange}
          pageCount={last_page}
          refreshContent={this.refreshContent}
        />
      );
    }
    return footer;
  };

  matchUserById = (id, user) => {
    return user.id === id;
  };

  setUserEnabled = (enable, userId) => {
    // const data = {
    //   enable: enable
    // };

    toggleRoyaltiesUserEnabled(userId, enable)
      .then(getRoyaltiesUsersPage(1))
      .then(this.onSetUserStatusSuccess)
      .catch(this.onSetUserStatusFailure.bind(this, enable));
  }

  onSetUserStatusSuccess = (response) => {
    const user = response.data.data;
    this.updateUserData(user.id, user);
  };

  updateUserData = (id, data) => {

    this.setState({
      loading: false
    });
    const userIndex = this.state.users.findIndex(this.matchUserById.bind(this, id));

    this.setState(prevState => {
      const users = cloneDeep(prevState.users);
      Object.assign(users[userIndex], data);

      return {
        users: users
      };
    });
  };

  renderBody = () => {
    const { users } = this.state;
    return (
      <Router>
        <RoyaltiesUsersViewBody
          path={'/*'}
          users={users}
          contentHeight={this.props.contentHeight}
        />
        {<RoyaltiesUserDetails
          path={'/details/:userId'}
          contentHeight={this.props.contentHeight}
          setUserEnabled={this.setUserEnabled}

        />}
      </Router>
    );
  };

  renderHeader = () => {
    return (
      <RoyaltiesUsersViewHeader
      // onChange={this.onFilterChange}
      // defaultFilters={this.defaultFilters}
      />
    );
  };

  render() {
    const { loading, dataLoaded } = this.state;
    return (
      <div className="RoyaltiesUsersView">
        {this.renderHeader()}
        <LoadingWrapper
          className='RoyaltiesUsersViewWrapper'
          dataLoaded={dataLoaded}
          isLoading={loading}
          loadingMsg='Loading Royalties Users. '
        >
          {this.renderBody()}
          {this.renderFooter()}
        </LoadingWrapper>
      </div>
    );
  }
}

export default withTranslation()(RoyaltiesUsersView);
