import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, List, Icon} from "antd";
import PropTypes from 'prop-types';

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";

import './PlayerConfigurationCard.scss';

/* istanbul ignore file */
class PlayerConfigurationCard extends Component {

  getActions = () => {
    const {onConfigurePlayer, t} = this.props;
    return (
      <div className="Actions">
        <Button onClick={onConfigurePlayer}>
          {t(`PlayerConfigurationCard.editConfig`)}
        </Button>
      </div>
    );
  };

  getTitle = () => {
    const {t} = this.props;
    return (
      <div className="Title">
        <div>{t(`PlayerConfigurationCard.enabledConfig`)}</div>
        <Icon type="close" onClick={this.removeConfiguration}/>
      </div>
    );
  };

  removeConfiguration = () => {
    const {id, onRemoveConfiguration} = this.props;
    onRemoveConfiguration(id);
  };

  renderListItem = item => {
    return <List.Item>{item.text}</List.Item>
  };

  render() {
    const {enabledConfigurations} = this.props;
    return (
      <BaseCard title={this.getTitle()}
                className="PlayerConfigurationCard"
                footer={this.getActions()}>
        <List
          size="small"
          bordered
          dataSource={enabledConfigurations}
          renderItem={this.renderListItem}
        />
      </BaseCard>
    );
  }
}

PlayerConfigurationCard.propTypes = {
  enabledConfigurations: PropTypes.array,
  configuration: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  onConfigurePlayer: PropTypes.func.isRequired,
  onRemoveConfiguration: PropTypes.func.isRequired
};

export default withTranslation()(PlayerConfigurationCard);

