import { navigate } from "@reach/router";

import { getUsersBaseRoute, getNatsUsersRoute, getRoyaltiesUsersRoute } from "./users-navigation-routes.service";

/**
 * Navigates to users view
 *
 * @returns {Promise<any>}
 */
export const showUsers = () => {
  return navigate(getUsersBaseRoute());
};

/**
 * Navigates to NATS users view
 *
 * @returns {Promise<any>}
 */
export const showNatsUsers = () => {
  return navigate(getNatsUsersRoute());
};

/**
 * Navigates to Royalties users view
 *
 * @returns {Promise<any>}
 */
export const showRoyaltiesUsers = () => {
  return navigate(getRoyaltiesUsersRoute());
};