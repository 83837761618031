import React, {Component} from "react";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {Router} from "@reach/router";
import {cloneDeep} from 'lodash';
import {withDocumentTitleUpdate} from "../../../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";
import PropTypes from 'prop-types';

import {getBannerSetById, getBannerSets} from "../../../../../../../../../../services/banners-service/banners.service";

import {LogController} from "../../../../../../../../../../controllers/log-controller/log.controller";

import LoadingWrapper from "../../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import PropertyDetailsBannerSetsList from "./PropertyDetailsBannerSetsList/PropertyDetailsBannerSetsList";
import PropertyDetailsBannerSetBanners from "./PropertyDetailsBannerSetBanners/PropertyDetailsBannerSetBanners";

import './PropertyDetailsBannerSets.scss';

/* istanbul ignore file */
class PropertyDetailsBannerSets extends Component {

  allBannerSets = [];

  maxDisplayedBanners = 6;

  state = {
    data: [],
    dataLoaded: false,
    loadingMsg: ''
  };

  componentDidMount() {
    this.fetchData();
  }

  clearLoading = () => {
    this.setState({loadingMsg: ''});
  };

  fetchBannerSetsProperties = (response) => {
    const {data: sets} = response.data;
    this.allBannerSets = sets;
    const promises = sets.map(this.getBannerSetPropertyOrder);
    return Promise.all(promises);
  };

  fetchData = () => {
    const message = 'Loading data. Please wait...';
    this.setLoading(message);
    getBannerSets(0)
      .then(this.fetchBannerSetsProperties)
      .then(this.loadData)
      .catch(this.onRequestFailure);
  };

  getBannerSetPropertyOrder = ({id: bannerSetId}) => {
    const {propertyId} = this.props;
    return getBannerSetById(bannerSetId, 1, 'index', 'asc', undefined, propertyId);
  };

  getDocumentTitle = () => {
    return this.props.t('PropertyBannerSets.documentTitle');
  };

  loadData = (values) => {
    const banners = values.reduce(this.parseBannerSetPropertyData, []).sort(this.sortBySetName);
    this.setState({
      data: banners,
      dataLoaded: true,
      loadingMsg: ''
    });
  };

  matchSetByIndex = (id, {bannerSetId}) => {
    return id === bannerSetId;
  };

  onRequestFailure = (error) => {
    this.clearLoading();
    LogController.logError(error);
  };

  parseBannerSetPropertyData = (result, response, index) => {
    const {banners, pagination} = response.data.data;
    const {id, name} = this.allBannerSets[index];
    result.push({
      banners,
      bannerSetId: id,
      bannerSetName: name,
      totalBannerCount: pagination.total
    });
    return result;
  };

  refreshPropertyBannerSetData = (bannerSetId) => {
    this.getBannerSetPropertyOrder({id: bannerSetId})
      .then(this.updateData.bind(this, bannerSetId))
      .catch(this.onRequestFailure);
  };

  setLoading = (loadingMsg) => {
    this.setState({loadingMsg});
  };

  sortBySetName = ({bannerSetName: name1}, {bannerSetName: name2}) => {
    let order = 0;
    if (name1 > name2) {
      order = 1;
    } else if (name1 < name2) {
      order = -1;
    }

    return order;
  };

  updateData = (bannerSetId, response) => {
    const {banners, pagination} = response.data.data;
    this.setState(prevState => {
      const index = prevState.data.findIndex(this.matchSetByIndex.bind(this, +bannerSetId));
      const data = cloneDeep(prevState.data);
      Object.assign(data[index], {
        banners: banners.slice(0, this.maxDisplayedBanners),
        totalBannerCount: pagination.total
      });
      return {data};
    });
  };

  render() {
    const {data, dataLoaded, loadingMsg} = this.state;
    return (
      <LoadingWrapper className="PropertyDetailsBannerSets"
                      dataLoaded={dataLoaded}
                      isLoading={!!loadingMsg}
                      loadingMsg={loadingMsg}>
        <Router>
          <PropertyDetailsBannerSetsList data={data}
                                         path={'/'}/>
          <PropertyDetailsBannerSetBanners path={'/:bannerSetId/banners'}
                                           onReorderChange={this.refreshPropertyBannerSetData}/>
        </Router>
      </LoadingWrapper>
    );
  }
}

PropertyDetailsBannerSets.propTypes = {
  propertyId: PropTypes.string
};

export default compose(withTranslation(), withDocumentTitleUpdate)(PropertyDetailsBannerSets);
