import {ROUTES} from "../../../routes";
import {MAIN_DASHBOARD_VIEW_ROUTES} from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";
import {MOVIES_ROUTES} from "../../../pages/MainDashboard/components/MainView/components/MainContent/components/MoviesView/movies.routes";

/**
 * Returns movies view route
 *
 * @returns {string}
 */
export const getMoviesRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.MOVIES}`;
};

/**
 * Returns movie details route
 *
 * @param {number|string} movieId
 *
 * @returns {string}
 */
export const getMovieDetailsRoute = (movieId) => {
  return `${getMoviesRoute()}/${movieId}`;
};

export const getSceneDetailsRoute = (movieId, sceneId) => {
  return `${getMovieDetailsRoute(movieId)}/scenes/details/${sceneId}`;
}

export const getMovieContentInfoRoute = (movieId) => {
  return `${getMovieDetailsRoute(movieId)}/contentInfo`;
};

export const getMovieSceneContentInfoRoute = (movieId, sceneId) => {
  return `${getMovieDetailsRoute(movieId)}/scenes/${sceneId}/contentInfo`;
};

export const getMovieSceneDetailsRoute = (movieId, sceneId) => {
  return `${getMovieDetailsRoute(movieId)}/scenes/details/${sceneId}`;
};

export const getMoviesScenesPlaylistRoute = () => {
  return `${getMoviesRoute()}/${MOVIES_ROUTES.scenePlaylists}`;
};

/**
 * Navigates to movie playlist details view
 *
 * @param {string|number} playlistId
 *
 * @returns {string}
 */
export const getScenePlaylistDetailsRoute = playlistId => {
  return `${getMoviesScenesPlaylistRoute()}/${playlistId}`;
};

export const getMoviesPlaylistsRoute = () => {
  return `${getMoviesRoute()}/${MOVIES_ROUTES.moviePlaylists}`;
};

/**
 * Navigates to movie playlist details view
 *
 * @param {string|number} playlistId
 *
 * @returns {string}
 */
export const getMoviesPlaylistDetailsRoute = playlistId => {
  return `${getMoviesPlaylistsRoute()}/${playlistId}`;
};