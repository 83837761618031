import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Button, InputNumber } from 'antd';
import {
  editPayoutProperty
} from '../../../../../../../../../../services/payouts-service/payouts.service';

import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../services/notification-service/notification.service';

import './EditPayoutsPropertyModal.scss';

class EditPayoutsPropertyModal extends Component {
  modalRef = React.createRef();

  startingData = {};

  state = {};

  componentDidMount() {
    const { propertyData } = this.props;

    this.startingData = {
      affiliate: +propertyData.affiliate,
      gross: +propertyData.gross
    };

    this.setState({
      affiliate: +propertyData.affiliate,
      gross: +propertyData.gross
    });
  }

  getActions = () => {
    const { t } = this.props;
    return [
      <Button key='cancelBtn' onClick={this.onCancel.bind(this)}>
        {t('EditPayoutsPropertyModal.cancel')}
      </Button>,
      <Button
        key='saveChangesBtn'
        disabled={!this.hasDataChanged()}
        onClick={this.onSaveChanges.bind(this)}
      >
        {t('EditPayoutsPropertyModal.saveChanges')}
      </Button>
    ];
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getModalTitle = () => {
    const { propertyData } = this.props;
    return this.props.t('EditPayoutsPropertyModal.editPayout', {
      name: propertyData.properties.name
    });
  };

  hasDataChanged = () => {
    return (
      this.state.affiliate !== this.startingData.affiliate ||
      this.state.gross !== this.startingData.gross
    );
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChange = (key, value) => {
    const newValue = value && value.target ? value.target.value : value;
    this.setState(prevState => {
      const newState = cloneDeep(prevState);
      newState[key] = newValue;
      return newState;
    });
  };

  onSaveChanges = () => {
    const message = this.props.t('EditPayoutsPropertyModal.savingMsg');
    this.getModal().setLoading(message);

    const { startDate, endDate, propertyData } = this.props;
    const { affiliate, gross } = this.state;

    const postData = {
      start_date: startDate,
      end_date: endDate,
      affiliate: affiliate.toString(),
      gross: gross.toString()
    };

    const updateData = {
      propertyId: propertyData.properties.id,
      affiliate: affiliate.toString(),
      gross: gross.toString()
    };

    editPayoutProperty(propertyData.properties.id, postData)
      .then(this.onPayoutEditSuccess.bind(this, updateData))
      .catch(this.onPayoutEditFailure);
  };

  onPayoutEditFailure = (error = {}) => {
    const status = error.response?.status;
    const msg = 'Please wait before next edit! ' + error.response?.data?.error;

    if (status === 503) {
      displayErrorNotification({
        duration: 5,
        message: msg
      });
    } else {
      displayErrorNotification({
        duration: 3,
        message: this.props.t('EditPayoutsPropertyModal.editPayoutFailure')
      });
    }
    this.getModal().clearLoading();
  };

  onPayoutEditSuccess = (data) => {
    const { updatePayoutData } = this.props;
    const { affiliate, gross, propertyId } = data;
    this.getModal().closeModal();

    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditPayoutsPropertyModal.editPayoutSuccess')
    });

    updatePayoutData({
      affiliate: affiliate.toString(),
      gross: gross.toString(),
      propertyId,
      status: 1
    });
    // this.props.onPayoutPropertyEdit(propertyId, updateData);
  };

  renderField = (label, children) => {
    return (
      <div className="EditPayoutsPropertyModal-field-wrapper">
        <div className="Label">{label}:</div>
        {children}
      </div>
    );
  };

  renderInputNumber = (label, value, onChange) => {
    const children = (
      <InputNumber value={value}
        onChange={onChange} />
    );
    return this.renderField(label, children);
  };

  render() {
    const { t } = this.props;
    const { affiliate, gross } = this.state;

    return (
      <ModalDialog
        title={this.getModalTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='EditPayoutsPropertyModal'>
          {this.renderInputNumber(t('EditPayoutsPropertyModal.affiliate'), affiliate, this.onChange.bind(this, 'affiliate'))}
          {this.renderInputNumber(t('EditPayoutsPropertyModal.gross'), gross, this.onChange.bind(this, 'gross'))}
        </div>
      </ModalDialog>
    );
  }
}

EditPayoutsPropertyModal.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  propertyData: PropTypes.object.isRequired,
  updatePayoutData: PropTypes.func.isRequired
};

export default withTranslation()(EditPayoutsPropertyModal);
