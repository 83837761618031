import React, {Component} from 'react';
import PropTypes from 'prop-types';

import StarDetailsBody from '../StarDetailsBody/StarDetailsBody'

import './StarDetailsBodyView.scss';

class StarDetailsBodyView extends Component {
  render() {
    const {activeTab, changeTab, headshot} = this.props;
    return (
      <div className='StarDetailsBodyView'>
        <StarDetailsBody path={"/details"}
                         {...this.props}
                         headshot={headshot}
                         activeTab={activeTab}
                         changeTab={changeTab}/>
      </div>
    );
  }
}

StarDetailsBodyView.propTypes = {
  activeTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  headshot: PropTypes.string,
  onStarEdit: PropTypes.func.isRequired,
  onStarUpdateFinished: PropTypes.func
};

export default StarDetailsBodyView;
