import { withTranslation } from "react-i18next";

import UploadImages from "../../../../../../../../../components/modal/UploadImages/UploadImages";
import { deleteBanner } from "../../../../../../../../../services/banners-service/banners.service";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

class UploadBannersModal extends UploadImages {

  /**
   * @override
   */
  getImageCategories = () => {
    const { t } = this.props;
    return [t('UploadBannersModal.banner')];
  };

  onUploadError = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('UploadBannersModal.uploadFailedMsg')
    });
  };

  onUploadSuccess = async (values = []) => {
    this.getModal().clearLoading();
    let failedToUpload = [];
    for (let i = 0; i < values.length; i++) {
      if (values[i]) {
        failedToUpload.push(values[i].file);
        await deleteBanner(values[i].bannerId);
      }
    }
    if (failedToUpload.length === 0) {
      this.closeDialog();
      displaySuccessNotification({
        duration: 3,
        message: this.props.t('UploadBannersModal.uploadSuccessMsg')
      });
    } else {
      displaySuccessNotification({
        duration: 3,
        message: this.props.t('UploadBannersModal.partialSuccessMsg')
      });
      this.setState({
        files: this.state.files.reduce((arr, item) => {
          if (failedToUpload.indexOf(item.file) !== -1) {
            arr.push(item);
          }
          return arr;
        }, [])
      })
    }
    this.props.onUploadFinished();
  };
}

export default withTranslation()(UploadBannersModal);