import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'antd';
import PropTypes from 'prop-types';
import { BaseVideoPlayerController, FullPlayer } from "@falconstudios/ns-player";

import "./SceneDetailsSampleVideoTab.scss";
import { ModalController } from '../../../../../../../../../../../controllers/modal-controller/modal.controller';
import SampleVideoConfiguration from './SampleVideoConfiguration';
import { isFunction } from 'lodash';

class SceneDetailsSampleVideoTab extends Component {
    state = {
        videoRefreshed: true
    };

    renderPlayer() {
        const { videoRefreshed } = this.state;
        const { activeScene } = this.props;
        const { sample_video, id } = activeScene;
        const videos = [{
            id,
            sources: [{
                src: sample_video,
                type: BaseVideoPlayerController.getPreviewSampleFormats().MP4
            }]
        }];
        const data = {
            ads: [],
            autoplay: true,
            className: "FullPlayer",
            onAddClick: this.onAddClick,
            videos,
            playerConfiguration: undefined
        };
        return videos.length && videoRefreshed ? (
            <FullPlayer
                data={data}
                onVideoFormatChange={this.updatePlayerData}
            />
        ) : null;
    }

    refreshVideo = () => {
        this.setState({
            videoRefreshed: true
        });
    };

    fetchData = (silently) => {
        const { fetchData } = this.props;
        if (isFunction(fetchData)) {
            fetchData(silently, this.refreshVideo);
        }
        this.setState({
            videoRefreshed: false
        });
    }

    recreateSample = (event) => {
        const { activeScene, t, setLoading } = this.props;
        const modal = (
            <SampleVideoConfiguration
                setLoading={setLoading}
                fetchData={this.fetchData}
                sceneId={activeScene.id}
                title={t('SceneDetailsSampleVideoTab.title')}
            />
        );
        ModalController.showModal(modal);
    };

    render() {
        const { t } = this.props;
        return (
            <div className="SceneDetailsSampleVideoTab">
                <Button
                    className='recreateBtn'
                    onClick={this.recreateSample}
                    key="recreateSampleBtn"
                >
                    <Icon type="reload" />
                    {t('SceneDetailsSampleVideoTab.recreateBtn')}
                </Button>
                {this.renderPlayer()}
            </div>
        );
    }
}

SceneDetailsSampleVideoTab.propTypes = {
    activeScene: PropTypes.array.isRequired
};

export default withTranslation()(SceneDetailsSampleVideoTab);
