import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Layout, Icon } from "antd";

import AvatarDropdown from "./AvatarDropdown/AvatarDropdown";
import BaseHorizontalNavigation from "../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";

import Company from "./Company/Company";

import { MainDashboardComController } from "../../main-dashboard-comm.controller";

import './MainDashboardHeader.scss';
import BaseDropdown from '../../../../components/BaseDropdown/BaseDropdown';
import { ModalController } from '../../../../controllers/modal-controller/modal.controller';
import EditTop10Modal from '../MainView/components/MainContent/components/PropertiesView/EditTop10Modal/EditTop10Modal';
import MovieSpotlightModal from '../MainView/components/MainContent/components/PropertiesView/PropertiesMainView/MovieSpotlightModal/MovieSpotlightModal';
import ConfigureCategoriesModal from '../MainView/components/MainContent/components/PropertiesView/ConfigureCategoriesModal/ConfigureCategoriesModal';
import HomePageSpotlightModal from '../MainView/components/MainContent/components/PropertiesView/HomePageSpotlightModal/HomePageSpotlightModal';
import SceneSpotlightModal from '../MainView/components/MainContent/components/PropertiesView/PropertiesMainView/SceneSpotlightModal/SceneSpotlightModal';
import SetBrowseConfigurationModal from '../MainView/components/MainContent/components/PropertiesView/SetBrowseConfigurationModal/SetBrowseConfigurationModal';
import EditPropertyModal from '../MainView/components/MainContent/components/PropertiesView/EditPropertyModal/EditPropertyModal';
import EditBannedWordsModal from '../MainView/components/MainContent/components/PropertiesView/PropertiesViewDetails/EditBannedWordsModal/EditBannedWordsModal';
import { env } from '../../../../env';
import EditSearchOptionsModal from '../MainView/components/MainContent/components/PropertiesView/EditSearchOptionsModal/EditSearchOptionsModal';

// in development for all global actions use GuerillaPorn whitelabel id as reference, but in production use NakedSword whitelabel id for global actions
const DEFAULT_WHITELABEL_ID = env.NODE_ENV === 'production' ? 1 : 22; // 1 naked sword, 22 - guerilla porn

/* istanbul ignore file */
class MainDashboardHeader extends Component {

  showEditTop10Modal = ({ id, applyToAll, name }) => {
    const modal = (
      <EditTop10Modal propertyId={id} applyToAll={applyToAll} propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showMovieSpotlightModal = ({ id, applyToAll, name }) => {
    const modal = (
      <MovieSpotlightModal propertyId={id} applyToAll={applyToAll} propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showConfigureCategoriesModal = ({ id, applyToAll, name }) => {
    const modal = (
      <ConfigureCategoriesModal propertyId={id} applyToAll={applyToAll} propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showHomePageSpotlightModal = ({ id, applyToAll, name }) => {
    const modal = (
      <HomePageSpotlightModal propertyId={id} applyToAll={applyToAll} propertyName={name} />
    );
    ModalController.showModal(modal);
  }

  showSceneSpotlightModal = ({ id, applyToAll, name }) => {
    const modal = (
      <SceneSpotlightModal propertyId={id} applyToAll={applyToAll} propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showSetBrowseConfigurationModal = ({ id, name, applyToAll }) => {
    const modal = (
      <SetBrowseConfigurationModal propertyId={id} applyToAll={applyToAll}
        propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showEditPropertyModal = ({ id, name, applyToAll }) => {
    const modal = (
      <EditPropertyModal propertyId={id} applyToAll={applyToAll} propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showEditSearchOptionsModal = (property) => {
    const { id, name, applyToAll } = property;
    const modal = (
      <EditSearchOptionsModal propertyId={id} propertyName={name} applyToAll={applyToAll} />
    );
    ModalController.showModal(modal);
  };

  showEditBanedWordsModal = ({ id, name, applyToAll }) => {
    const modal = <EditBannedWordsModal propertyId={id} propertyName={name} applyToAll={applyToAll} />;
    ModalController.showModal(modal);
  };

  getDropdownItems = (item = { id: DEFAULT_WHITELABEL_ID, name: 'All', applyToAll: 'all' }, index = 'GlobalActions') => {
    const {
      t
    } = this.props;

    return [
      <div onClick={this.showEditTop10Modal.bind(this, item)} key={`edit-top-10-${index}`}>
        {t('PropertiesBody.editTop10')}
      </div>,
      <div onClick={this.showConfigureCategoriesModal.bind(this, item)} key={`configure-category-${index}`}>
        {t('PropertiesBody.configureCategories')}
      </div>,
      <div onClick={this.showMovieSpotlightModal.bind(this, item)} key={`set-movie-spotlight-${index}`}>
        {t('PropertiesBody.setMovieSpotlight')}
      </div>,
      <div onClick={this.showHomePageSpotlightModal.bind(this, item)} key={`set-home-page-spotlight-${index}`}>
        {t('PropertiesBody.setHomePageSpotlight')}
      </div>,
      <div onClick={this.showSceneSpotlightModal.bind(this, item)} key={`scene-spotlight-${index}`}>
        {t('PropertiesBody.setSceneSpotlight')}
      </div>,
      <div onClick={this.showSetBrowseConfigurationModal.bind(this, item)} key={`browse-configuration-${index}`}>
        {t('PropertiesBody.setBrowseConfiguration')}
      </div>,
      <div onClick={this.showEditPropertyModal.bind(this, item)} key={`edit-property-${index}`}>
        {t('PropertiesBody.edit')}
      </div>,
      <div onClick={this.showEditSearchOptionsModal.bind(this, item)} key={`edit-search-options-${index}`}>
        {t('PropertiesBody.setSearchOptions')}
      </div>,
      <div onClick={this.showEditBanedWordsModal.bind(this, item)} key={`edit-banned-words-${index}`}>
        {t('PropertiesBody.editBannedWords')}
      </div>,
    ];
  };

  renderActionDropdown = () => {
    const { t } = this.props;
    return (
      <BaseDropdown key={'globalActions'} items={this.getDropdownItems()}>
        <Button className="Action-Button">
          {t('MainDashboardHeader.globalAction')}
          <Icon type="down" />
        </Button>
      </BaseDropdown>
    );
  };

  getAdditionalActions = () => {
    const { t } = this.props;
    return [
      this.renderActionDropdown(),
      <Button onClick={this.props.displayUploadFilesModal}
        key="assignImages">
        <Icon type="picture" />
        {t('MainDashboardHeader.assignImages')}
      </Button>,
      <Button onClick={this.props.displayAdsConfigDialog}
        key="configureMoviesAdsBtn">
        <Icon type="picture" />
        {t('MainDashboardHeader.configureMoviesAds')}
      </Button>,
      <Button onClick={this.props.displayConfigPlayerDialog}
        key="configurePlayerBtn">
        <Icon type="picture" />
        {t('MainDashboardHeader.configurePlayer')}
      </Button>
    ];
  };

  toggleSideMenu = () => {
    MainDashboardComController.toggleSideMenu();
  };

  render() {
    const { navigationItems, onLogoClick, onClick } = this.props;
    return (
      <Layout.Header className="MainDashboardHeader header">
        <div className="MainDashboardHeader-brand-section">
          <div className="MainDashboardHeader-firstChild">
            <Icon type="menu"
              onClick={this.toggleSideMenu} />
            <Company onClick={onLogoClick} />
          </div>
          <AvatarDropdown onClick={onClick} />
        </div>
        <BaseHorizontalNavigation items={navigationItems} mainNavigation={true}>
          {this.getAdditionalActions()}
        </BaseHorizontalNavigation>
      </Layout.Header>
    );
  }
}

MainDashboardHeader.propTypes = {
  displayAdsConfigDialog: PropTypes.func.isRequired,
  displayUploadFilesModal: PropTypes.func.isRequired,
  navigationItems: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func.isRequired
};

export default withTranslation()(MainDashboardHeader);
