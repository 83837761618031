import {navigate} from '@reach/router';

import {ROUTES} from '../../routes';

/**
 * Navigates to main dashboard
 *
 * @returns {Promise<any>}
 */
export const showMainDashboard = () => {
  return navigate(ROUTES.MAIN_DASHBOARD, {replace: true});
};

/**
 * Navigates to root view
 *
 * @returns {Promise<any>}
 */
export const showNavigationRoot = () => {
  window.history.replaceState(null, null, '/');
  return navigate(ROUTES.ROOT, {replace: true});
};
