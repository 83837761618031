import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PropertyDetailsBannerSetCard from "../PropertyDetailsBannerSetCard/PropertyDetailsBannerSetCard";

import './PropertyDetailsBannerSetsList.scss';

/* istanbul ignore file */
class PropertyDetailsBannerSetsList extends Component {

  maxDisplayedBanners = 6;

  mapBannerSetCard = ({ banners, bannerSetId, bannerSetName, totalBannerCount }, index) => {
    const { propertyId } = this.props;
    return (
      <PropertyDetailsBannerSetCard bannerSetId={bannerSetId}
        propertyId={+propertyId}
        title={bannerSetName}
        allActionsIncluded={false}
        bannerOrder={banners}
        isDefault={false}
        maxElementCount={this.maxDisplayedBanners}
        propertyCount={1}
        totalBannerCount={totalBannerCount}
        key={index}
        doNotShowStates={true}
        showSets={true} />
    );
  };

  renderBannerSetCards = () => {
    const { data } = this.state;
    return data.map(this.mapBannerSetCard);
  };

  render() {
    const { data } = this.props;
    return (
      <div className="PropertyDetailsBannerSetsList">
        {data.map(this.mapBannerSetCard)}
      </div>
    );
  }
}

PropertyDetailsBannerSetsList.propTypes = {
  data: PropTypes.array.isRequired
};

export default PropertyDetailsBannerSetsList;
