import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, InputNumber, Table } from "antd";

import ConfirmationModal
  from "../../../../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";

import { ModalController } from "../../../../../../../../../../../../../controllers/modal-controller/modal.controller";

import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../../../../services/notification-service/notification.service';
import { moveInArray, renderListClickableItem } from "../../../../../../../../../../../../../services/util-service/util.service";
import { getSceneDetailsRoute } from "../../../../../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";
import { deleteSceneInPlaylist, scenePlaylistReorder } from '../../../../../../../../../../../../../services/movies-service/movies.service';

import './ScenesPlaylistScenesTab.scss';

class ScenesPlaylistScenesTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      scenes: []
    }
  }

  componentDidMount() {
    this.setState({ scenes: this.props.detailsData.scenes_in_playlist });
  }

  renderActions = (item, data) => {
    const { t } = this.props;
    return (
      <>
        <Button onClick={this.showDeleteConfirmationModal.bind(this, data)}>
          {t('ScenesPlaylistScenesTab.removeScene')}
        </Button>

        <Button onClick={this.moveScene.bind(this, data, -1)}>
          <i className="fas fa-angle-up" />
        </Button>

        <Button onClick={this.moveScene.bind(this, data, 1)}>
          <i className="fas fa-angle-down" />
        </Button>

        <InputNumber onKeyDown={(event) => this.handleReorderSceneInput(event, data)} />
      </>
    );
  };

  renderIndex = (item, data, index) => {
    return index + 1;
  };

  renderTitle = (item) => {
    return `${item.title} (${item.scene_id})`; 
  };

  renderMovieName = (item, data) => {
    const { movie_id, scene_id } = data;
    return renderListClickableItem(data, undefined, `movie-${movie_id}-${scene_id}`, getSceneDetailsRoute.bind(this, movie_id, scene_id), this.renderTitle);
  };

  columns = [
    {
      align: 'center',
      dataIndex: 'index',
      title: this.props.t('ScenesPlaylistScenesTab.index'),
      render: this.renderIndex,
      width: 100
    },
    {
      dataIndex: 'title',
      title: this.props.t('ScenesPlaylistScenesTab.movie'),
      render: this.renderMovieName,
      width: 200
    },
    {
      align: 'center',
      dataIndex: 'actions',
      title: this.props.t('ScenesPlaylistScenesTab.actions'),
      render: this.renderActions,
      width: 200
    }
  ];

  deleteScene = (data) => {
    const { id } = data;

    deleteSceneInPlaylist(id)
      .then(this.onDeleteSceneInPlaylistSuccess)
      .catch(this.onDeleteSceneInPlaylistFailure)
  };

  onDeleteSceneInPlaylistFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('ScenesPlaylistScenesTab.deleteSceneFailed')
    });
  };

  onDeleteSceneInPlaylistSuccess = () => {
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('ScenesPlaylistScenesTab.deleteSceneSuccess')
    });

    this.props.onSceneDelete();
  };

  handleReorderSceneInput = (event, data) => {
    if (event.keyCode === 13) {
      this.moveScene(data, (event.target.value - 1))
    }
  }

  moveScene = (data, position, direction = undefined) => {
    const sceneList = this.state.scenes;
    const { id: playlistId } = this.props.detailsData;
    const { scene_id: sceneId } = data;
    const sceneIndex = sceneList.findIndex(movie => movie.scene_id === sceneId);
    if (direction)
      position = sceneIndex + position; // Turn position into a number

    if (position >= 0 && position < sceneList.length) {
      moveInArray(sceneList, sceneIndex, position);
      const putBeforeIndex = sceneList?.[position + 1]?.scene_id || null;
      this.setState({ movies: sceneList });
      scenePlaylistReorder(playlistId, sceneId, putBeforeIndex);
    }
    else {
      displayErrorNotification({
        duration: 3,
        message: `Cannot move further ${position > 0 ? "down" : "up"}`
      });
    }
  }

  renderSceneTable = () => {
    return (
      <Table
        dataSource={this.props.detailsData.scenes_in_playlist}
        rowKey='id'
        bordered
        columns={this.columns}
        pagination={false}
      />
    );
  };

  showDeleteConfirmationModal = (data) => {
    const { t } = this.props;
    const modal = (
      <ConfirmationModal
        title={t('ScenesPlaylistScenesTab.confirmDelete')}
        message={t('ScenesPlaylistScenesTab.deletionQuestion')}
        yesBtnText={t('ScenesPlaylistScenesTab.deleteBtn')}
        noBtnText={t('ScenesPlaylistScenesTab.cancel')}
        confirm={this.deleteScene.bind(this, data)}
      />
    );
    ModalController.showModal(modal);
  };

  render() {
    return (
      <div className="ScenesPlaylistScenesTab">
        {this.renderSceneTable()}
      </div>
    )
  }
};

export default withTranslation()(ScenesPlaylistScenesTab);