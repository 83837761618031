import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import BaseImagesFullScreen from "../../BaseImagesFullScreen/BaseImagesFullScreen";
import ImageComponent from "../../ImageComponent/ImageComponent";

import './BaseImagesMosaicDisplay.scss';
import ImageActions from '../../ImageActions/ImageActions';

class BaseImagesMosaicDisplay extends Component {

  fullScreenRef = React.createRef();

  componentDidMount() {
    const { onInitialize } = this.props;
    if (onInitialize) {
      onInitialize(this);
    }
  }

  matchImageType = ({ type }) => {
    return type.toLowerCase().indexOf(this.props.searchType.toLowerCase()) !== -1;
  };

  renderImage = (data, index) => {
    const { onDeleteImage, onEditImage, onSetHeroStill, bannerId, movieId } = this.props;
    const { url, is_cover: isCover, origin } = data;

    let imageEl = (
      <ImageComponent isCover={isCover}
        origin={origin}
        url={url}
        key={url} />
    );
    return (
      <ImageActions
        data={data}
        index={index}
        onFullScreen={this.toggleFullScreen.bind(this, index)}
        onDeleteImage={onDeleteImage}
        onSetHeroStill={onSetHeroStill}
        onEditImage={onEditImage}
        key={index}
        bannerId={bannerId}
        movieId={movieId}
      >
        {imageEl}
      </ImageActions>
    );
  };

  toggleFullScreen = (index) => {
    this.fullScreenRef.current.toggleFullScreen(index);
  };

  render() {
    let { images, searchType } = this.props;
    images = searchType ? images.filter(this.matchImageType) : images;
    return (
      <div className="BaseImagesMosaicDisplay">
        <BaseImagesFullScreen images={images}
          ref={this.fullScreenRef}
          className="BaseImagesMosaicDisplay-inner">
          <div className="BaseImagesMosaicDisplay-inner">
            {images.map(this.renderImage)}
          </div>
        </BaseImagesFullScreen>
      </div>
    );
  }
}

BaseImagesMosaicDisplay.propTypes = {
  images: PropTypes.array.isRequired,
  onInitialize: PropTypes.func,
  searchType: PropTypes.string,
  bannerId: PropTypes.string,
  movieId: PropTypes.string,
  onDeleteImage: PropTypes.func.isRequired,
  onEditImage: PropTypes.func.isRequired,
  onSetHeroStill: PropTypes.func
};

export default withTranslation()(BaseImagesMosaicDisplay);