import React, { Component } from 'react';
import { globalHistory, Router } from "@reach/router";
import { cloneDeep } from 'lodash';

import {
  getMoviesCancelable,
  setMovieEnabled
} from "../../../../../../../../../services/movies-service/movies.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";
import { getMoviesRoute } from "../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";

import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";
import { MainDashboardComController } from '../../../../../../../../../pages/MainDashboard/main-dashboard-comm.controller';

import EditCustodianOfRecordsModal from "../MovieDetails/EditCustodianOfRecordsModal/EditCustodianOfRecordsModal";
import EditMovieModal from "../MovieDetails/EditMovieModal/EditMovieModal";
import MoviesViewHeader from "../MoviesMainView/MoviesViewHeader/MoviesViewHeader";
import MoviesMainViewAbstract from "../MoviesMainViewAbstract/MoviesMainViewAbstract";
import MovieDetails from "../MovieDetails/MovieDetails";

import jsonData from './data';

import './MoviesViewAbstract.scss';
import EditSpecialCategoriesModal from '../MovieDetails/EditSpecialCategoriesModal/EditSpecialCategoriesModal';

class MoviesViewAbstract extends Component {
  defaultDisplayFilter = jsonData.generalLinks[jsonData.generalLinks.length - 1];

  defaultSortFilter = jsonData.sortCriteria[3];

  filters = {
    display: this.defaultDisplayFilter,
    sort: {
      name: this.defaultSortFilter,
      ascending: false
    }
  };

  mainRef = React.createRef();

  subscription;

  state = {
    currentPage: 0,
    loadingMsg: '',
    moviesLoaded: false,
    movies: [],
    pageCount: 0,
    pageSize: 0,
    recordCount: 0,
    status: ''
  };

  clearFiltersAndFetchMovies = () => {
    this.filters.search = '';
    this.fetchMovies(1);
  };

  componentDidMount() {
    this.subscription = MainDashboardComController.mainNavigationLinkClicked.subscribe(this.clearFiltersAndFetchMovies.bind(this, 1));
    this.fetchMovies(1);
    this.historyUnsubscribe = globalHistory.listen(this.handleLocationChange.bind(this));
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.historyUnsubscribe();
  }

  fetchMovies = (page) => {
    const message = this.props.t('MoviesViewAbstract.loadingPleaseWait');
    this.setLoading(message);
    const filters = this.getFilters();
    const { result, controller } = getMoviesCancelable(page, filters);
    if (this.lastRequestController) {
      this.lastRequestController.abort();
    }
    this.lastRequestController = controller;
    result.then(this.loadMovies).catch(() => { });
  };

  filterByStatus = (filters) => {
    Object.assign(this.filters, filters);
    this.fetchMovies(1);
  };

  fixScrollIfNeeded = () => {
    const list = this.getMoviesViewListElement();
    if (list) {
      list.scrollTop = 0;
    }
  };

  getFilters = () => {
    const filters = [];
    let item;
    const excluded = ['all'];
    for (let key in this.filters) {
      item = this.filters[key];
      if (key === 'search' && item) {
        filters.push(`search=${item}`);
      } else {
        if (item) {
          if (item.name) {
            if (excluded.indexOf(item.name) === -1) {
              filters.push(this.getNamedFilters(item));
            }
          } else {
            if (excluded.indexOf(item) === -1) {
              let filter = `${item}=true`;
              if (item === 'offline') {
                filter = `online=false`
              }
              filters.push(filter);
            }
          }
        }
      }
    }

    return filters;
  };

  /**
   * Returns main view data props object
   * By default returns entire state object
   *
   * @returns {Object}
   */
  getMainViewData = () => {
    return this.state;
  };

  /**
   * Returns main view props object
   *
   * @returns {Object}
   */
  getMainViewProps = () => {
    const data = this.getMainViewData();
    return {
      path: "/",
      data: data,
      filters: {
        display: this.defaultDisplayFilter,
        sort: {
          name: this.defaultSortFilter,
          ascending: false
        }
      },
      setMovieEnabled: this.setMovieEnabled,
      fetchMovies: this.fetchMovies,
      filterByStatus: this.filterByStatus,
      onClearFilter: this.onClearFilter,
      onPageChange: this.fetchMovies,
      onMovieEdit: this.showMovieEditDialog,
      onSpecialCategoriesEdit: this.showEditSpecialCategoriesDialog,
      onMovieEdit2257: this.showMovieEdit2257Dialog,
      scrollTop: this.scrollTop
    };
  };

  getMoviesRoute = () => {
    return getMoviesRoute();
  };

  getMoviesViewListElement = () => {
    return document.getElementsByClassName('MoviesViewList')[0];
  };

  getNamedFilters = (item) => {
    const name = item.name;
    const ascending = item.ascending ? 'asc' : 'desc';
    return `${name}=${ascending}`;
  };

  handleLocationChange = () => {
    const componentPath = this.props.uri;
    const currentPath = window.location.pathname;
    if (currentPath !== componentPath && currentPath.startsWith(componentPath)) {
      const list = this.getMoviesViewListElement();
      if (list) {
        this.scrollTop = list.scrollTop;
      }
    }
  };

  loadMovies = (response) => {
    const { t } = this.props;
    const { movies, pagination } = response.data.data;
    this.setState({
      currentPage: pagination.current_page,
      loadingMsg: '',
      moviesLoaded: true,
      movies: movies,
      pageCount: pagination.last_page,
      pageSize: pagination.per_page,
      recordCount: pagination.total,
      status: t(`MoviesViewAbstract.${this.filters.display}`)
    }, this.fixScrollIfNeeded);
  };

  matchMovieById = (id, movie) => {
    return movie.movieId === id;
  };

  onClearFilter = (filters) => {
    this.filterByStatus(filters);
  };

  onSetMovieEnabledFailure = (enabled, error) => {
    this.setLoading('');
    const { t } = this.props;
    const key = enabled === 1 ? 'enableFail' : 'disableFail';
    displayErrorNotification({
      duration: 3,
      message: t(`MoviesViewAbstract.${key}`)
    });
  };

  onSetMovieEnabledSuccess = (data) => {
    const { enable } = data;
    const key = enable === 1 ? 'enableSuccess' : 'disableSuccess';
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t(`MoviesViewAbstract.${key}`)
    });
    this.refreshContent();
  };

  refreshContent = () => {
    this.fetchMovies(this.state.currentPage);
  };

  /**
   * Returns additional paths for Moview view
   * Meant to be overridden as to add more paths
   *
   * @returns {null}
   */
  renderAdditionalPaths = () => null;

  renderHeader = () => {
    const { t } = this.props;
    return <MoviesViewHeader message={t('MoviesViewAbstract.defaultCardInfoText')}
      detailsMessage={t('MoviesViewAbstract.recordsFound', { recordCount: this.state.recordCount })}
      status={this.state.status}
      onClearFilter={this.onClearFilter}
      generalLinks={jsonData.generalLinks}
      sortCriteria={jsonData.sortCriteria}
      onStatusChange={this.filterByStatus}
      defaultDisplayFilter={this.defaultDisplayFilter}
      defaultSortFilter={this.defaultSortFilter} />;
  };

  /**
   * Returns main view route render element
   *
   * @returns {JSX.Element}
   */
  renderMainViewRoute = () => {
    const props = this.getMainViewProps();
    return (
      <MoviesMainViewAbstract {...props} />
    );
  };

  setLoading = (loadingMsg) => {
    this.setState({
      loadingMsg: loadingMsg
    });
  };

  setMovieEnabled = (id, enabled) => {
    const message = this.props.t('MoviesViewAbstract.loadingPleaseWait');
    this.setLoading(message);
    const data = {
      enable: enabled
    };
    setMovieEnabled(id, enabled)
      .then(this.onSetMovieEnabledSuccess.bind(this, id, data))
      .catch(this.onSetMovieEnabledFailure.bind(this, enabled));
  };

  showMovieEditDialog = (id, name) => {
    const modal = (
      <EditMovieModal onMovieEdit={this.refreshContent}
        movieId={id}
        movieName={name} />
    );
    ModalController.showModal(modal);
  };

  showEditSpecialCategoriesDialog = (id, name) => {
    const modal = (
      <EditSpecialCategoriesModal associatedId={id} associatedName={name} />
    );
    ModalController.showModal(modal);
  }

  showMovieEdit2257Dialog = (id, name) => {
    const modal = (
      <EditCustodianOfRecordsModal onSaveChanges={this.refreshContent}
        movieName={name}
        movieId={id} />
    );
    ModalController.showModal(modal);
  };

  updateMovieData = (id, data) => {
    this.setLoading('');
    const movieIndex = this.state.movies.findIndex(this.matchMovieById.bind(this, id));
    this.setState(prevState => {
      const movies = cloneDeep(prevState.movies);
      Object.assign(movies[movieIndex], data);
      return {
        movies: movies
      };
    });
  };

  render() {
    return (
      <div className="MoviesViewWrapper">
        <Router>
          {this.renderMainViewRoute()}
          {this.renderAdditionalPaths()}
          <MovieDetails
            refreshContent={this.refreshContent}
            getMoviesRoute={this.getMoviesRoute}
            path={"/:movieId/*"}
            updateMovieData={this.updateMovieData}
          />
        </Router>
      </div>
    );
  }
}

export default MoviesViewAbstract;
