import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";

import { editLicensor, getLicensorById } from '../../../../../../../../../services/licensors-service/licensors.service';
import {
  getLicensorsRoute,
  getLicensorDetailsRoute,
  getLicensorMoviesRoute
} from '../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation-routes.service';
import {
  showLicensorMovies,
  showLicensorDetails
} from '../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import BaseNavigationItem from "../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import LicensorDetailsBody from './LicensorDetailsBody/LicensorDetailsBody';
import BaseHorizontalNavigation
  from "../../../../../../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";

import './LicensorDetails.scss';

class LicensorDetails extends Component {
  licensorId;

  state = {
    data: {},
    dataLoaded: false,
    loadingMessage: '',
  };

  constructor(props) {
    super(props);
    this.licensorId = +props.licensorId;
  }

  componentDidMount() {
    this.fetchLicensorData();
  };

  fetchLicensorData = () => {
    const message = this.props.t('LicensorDetails.pleaseWait');
    this.setLoading(message);
    getLicensorById(this.licensorId)
      .then(this.loadLicensorData)
      .catch(err => {
        this.setState({
          dataLoaded: true,
          loadingMessage: ''
        });
        displayErrorNotification({
          duration: 3,
          message: this.props.t('global.somethingWentWrong')
        });
        console.log(err);
      });
  };

  getNavigationItems = () => {
    const { data } = this.state;
    const { id, name } = data;
    const { t } = this.props;
    return [{
      link: getLicensorDetailsRoute(id),
      name: t('LicensorDetails.licensorName', { name: name || '' }),
      navigate: showLicensorDetails.bind(null, id)
    }, {
      link: getLicensorMoviesRoute(id),
      name: t('LicensorDetails.movies'),
      navigate: this.navigateToLicensorMovies
    }];
  };

  loadLicensorData = response => {
    const { data } = response.data;
    this.setDocumentTitle(data);
    this.setState({
      data: data,
      dataLoaded: true,
      loading: false
    });
    this.setLoading('');
  };

  matchIndexById = (id, item) => {
    return item.properties.id === id;
  };

  navigateToLicensorMovies = () => {
    showLicensorMovies(this.licensorId);
  };

  onLicensorEdit = data => {
    this.setState(prevState => {
      const newData = cloneDeep(prevState.data);
      Object.assign(newData, data);
      return {
        data: newData
      };
    });
    this.props.refreshContent(this.props.licensorId);
  };

  onLicensorInfoEdit = (name, callback) => {
    const { licensorId, t } = this.props;
    const message = t('LicensorDetails.savingMsg');
    this.setLoading(message);
    const updateData = {
      name
    };
    const postData = {
      name
    };
    editLicensor(licensorId, postData)
      .then(this.onLicensorEditSuccess.bind(this, updateData, callback))
      .catch(this.onLicensorEditFailure);
  };

  onLicensorEditFailure = () => {
    this.setLoading('');
    displayErrorNotification({
      duration: 3,
      message: this.props.t('LicensorDetails.editLicensorFailure')
    });
  };

  onLicensorEditSuccess = (data, callback) => {
    this.setLoading('');
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('LicensorDetails.editLicensorSuccess')
    });
    callback();
    this.onLicensorEdit(data);
  };

  onRoyaltyEdit = (propertyId, data, callback) => {
    this.setState(prevState => {
      const newData = cloneDeep(prevState.data);
      const index = newData.royalties.findIndex(this.matchIndexById.bind(this, propertyId));
      Object.assign(newData.royalties[index], data);
      return {
        data: newData
      };
    });

    if (callback) {
      callback();
    }
    this.props.refreshContent(this.props.licensorId);
  };

  renderTitle = () => {
    const { t } = this.props;
    const { data } = this.state;
    const routes = [{
      url: getLicensorsRoute(),
      text: t('LicensorDetails.licensors')
    }, {
      text: data?.name || ''
    }];

    return <BaseNavigationItem routes={routes} />;
  };

  setDocumentTitle = (data) => {
    const title = this.props.t('LicensorDetails.documentTitle', { name: data.name });
    DocumentTitleController.setDocumentTitle(title);
  };

  setLoading = (message) => {
    this.setState({
      loadingMessage: message
    });
  };

  render() {
    const { data, loadingMessage } = this.state;
    return (
      <LoadingWrapper
        className='LicensorDetails'
        dataLoaded={this.state.dataLoaded}
        isLoading={!!loadingMessage}
        loadingMsg={loadingMessage}
      >
        {this.renderTitle()}
        <BaseHorizontalNavigation items={this.getNavigationItems()} />
        <LicensorDetailsBody
          {...data}
          onLicensorInfoEdit={this.onLicensorInfoEdit}
          onRoyaltyEdit={this.onRoyaltyEdit}
          setLoading={this.setLoading}
        />
      </LoadingWrapper>
    );
  }
}

LicensorDetails.propTypes = {
  licensorId: PropTypes.string
};

export default withTranslation()(LicensorDetails);
