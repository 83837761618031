import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Checkbox } from "antd";
import PropTypes from 'prop-types';
import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import {
  editWhitelabelConfig,
  getWhitelabelConfig
} from "../../../../../../../../../services/properties-service/properties.service";
import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";
import './EditSearchOptionsModal.scss';

class EditSearchOptionsModal extends Component {
  modalRef = React.createRef();
  ALL = 'all';

  state = {
    dataLoaded: false,
    search_options: [
      { name: "All", tag: "all", checked: false },
      { name: "Movies", tag: "movies", checked: false },
      { name: "Stars", tag: "stars", checked: false },
      { name: "Studios", tag: "studios", checked: false },
      { name: "Directors", tag: "directors", checked: false },
      { name: "Themes", tag: "categories", checked: false },
      { name: "Sex Acts", tag: "sex_acts", checked: false }
    ]
  };

  componentDidMount() {
    this.fetchConfig();
  }

  fetchConfig = () => {
    const message = 'Loading data. Please wait!';
    this.getModal().setLoading(message);
    const { propertyId } = this.props;
    getWhitelabelConfig(propertyId)
      .then(this.loadData)
      .catch(this.onFailure);
  };

  getActions = () => {
    return (
      <>
        <Button onClick={() => this.getModal().closeModal()} key='cancelBtn'>
          Cancel
        </Button>
        <Button onClick={this.saveChanges} key='saveChangesBtn'>
          Save changes
        </Button>
      </>);
  };

  getModal = () => this.modalRef.current;

  getTitle = () => {
    const { t, propertyName } = this.props;
    const name = propertyName ? ` - ${propertyName}` : '';
    return `${t('PropertiesBody.setSearchOptions')}${name}`;
  };

  loadData = (response) => {
    const { data } = response.data;
    const state = {
      dataLoaded: true
    };

    if (data?.search_options && data.search_options.length !== 0) {
      state.search_options = data.search_options;
    };

    this.setState(state, () => this.getModal().clearLoading());
  };

  onFailure = (error) => {
    displayErrorNotification({
      duration: 3,
      message: 'An error occurred!'
    });
    LogController.logError(error);
    this.getModal().clearLoading();
  };

  onSaveChangesSuccess = () => {
    displaySuccessNotification({
      duration: 3,
      message: "Updated property successfully!"
    });
    this.getModal().closeModal();
  };

  onCheckboxChange = (key, event) => {
    const { checked } = event.target;

    this.setState(prevState => {
      const { search_options: oldSearchOptions } = prevState;
      const allChecked = oldSearchOptions.find(item => item.tag === this.ALL);
      return {
        search_options: oldSearchOptions.map(option => {
          const { tag } = option;
          if (key === tag) option.checked = checked;
          if (key === this.ALL) option.checked = checked;
          if (key !== this.ALL && allChecked?.checked) allChecked.checked = false;

          return option;
        })
      }
    });
  };

  renderCheckbox = (options) => {
    const { name, tag, checked } = options;
    return (
      <div key={name}>
        <Checkbox
          checked={checked}
          onChange={this.onCheckboxChange.bind(this, tag)}>
          {name}
        </Checkbox>
      </div>
    );
  }

  renderView = () => {
    const { search_options } = this.state;
    return search_options.map(item => this.renderCheckbox(item, false));
  };

  saveChanges = () => {
    const message = "Saving changes. Please wait...";
    this.getModal().setLoading(message);
    const { propertyId, applyToAll } = this.props;
    const { search_options } = this.state;
    const applyToPropertyId = applyToAll || propertyId;

    editWhitelabelConfig(applyToPropertyId, { search_options })
      .then(this.onSaveChangesSuccess)
      .catch(this.onFailure);
  };

  render() {
    const { dataLoaded } = this.state;
    return (
      <ModalDialog
        forwardedRef={this.modalRef}
        title={this.getTitle()}
        actions={this.getActions()}>
        <div className="EditSearchOptionsModal-inner">
          {dataLoaded ? this.renderView() : null}
        </div>
      </ModalDialog>
    );
  }
}

EditSearchOptionsModal.propTypes = {
  propertyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  propertyName: PropTypes.string,
  applyToAll: PropTypes.string
};

export default withTranslation()(EditSearchOptionsModal);