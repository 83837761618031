import React, { Component } from 'react';
import { Router } from '@reach/router';
import { Button } from "antd";
import { withTranslation } from 'react-i18next';

import { LogController } from '../../../../../../../../../../controllers/log-controller/log.controller';
import { ModalController } from "../../../../../../../../../../controllers/modal-controller/modal.controller";

import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../../services/notification-service/notification.service';
import {
  getMoviesPlaylist,
  deleteMoviePlaylist
} from '../../../../../../../../../../services/movies-service/movies.service';
import {
  getMoviesPlaylistsRoute,
  getMoviesRoute
} from "../../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";

import AddPlaylistModal from "./Playlist/AddPlaylistModal/AddPlaylistModal";
import BaseNavigationItem from '../../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem';
import LoadingWrapper from '../../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import MoviePlaylistDetails from './MoviePlaylistDetails/MoviePlaylistDetails';
import Playlist from './Playlist/Playlist';

import { playlistType } from "../playlist-type";

import './MoviePlayLists.scss';

class MoviePlayLists extends Component {

  state = {
    dataLoaded: false,
    loadingMsg: '',
    playlistData: [],
    playlistName: ''
  };

  componentDidMount() {
    this.fetchMoviePlaylist();
  };

  deletePlaylist = (data) => {
    const { t } = this.props;
    const message = t('MoviePlayLists.deletingPlaylist');
    this.setLoading(message);
    const playlistId = data.id;

    deleteMoviePlaylist(playlistId)
      .then(this.onDeletePlaylistSuccess.bind(this, playlistId))
      .catch(this.onDeletePlaylistFailure)
  };

  fetchMoviePlaylist = () => {
    const message = this.props.t('MoviePlayLists.loadingPleaseWait');
    this.setLoading(message);

    getMoviesPlaylist()
      .then(this.loadMoviePlaylist)
      .catch(this.onGetMoviesPlaylistFailure)
  };

  filterPlaylistsById = (id, item) => {
    return id !== item.id;
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <Button onClick={this.toggleAddPlaylist}>
        {t('MoviePlayLists.addPlaylist')}
      </Button>
    );
  };

  getRoutes = () => {
    const { t, location = {} } = this.props;
    const { playlistName } = this.state;
    const pathname = location.pathname || '';

    const routes = [{
      url: getMoviesRoute(),
      text: t('MoviePlayLists.movies')
    }, {
      text: t('MoviePlayLists.moviesPlaylists')
    }];

    if (pathname.includes('/playlists/')) {
      routes
        .splice(1, 1, {
          url: getMoviesPlaylistsRoute(),
          text: t('MoviePlayLists.moviesPlaylists')
        }, {
          text: playlistName
        })
    }

    return routes;
  };

  loadMoviePlaylist = (response) => {
    const data = response.data.data;

    this.setState({
      loadingMsg: '',
      playlistData: data,
      dataLoaded: true
    });
  };

  matchPlaylistById = (id, playlist) => {
    return playlist.id === id;
  };

  onDeletePlaylistFailure = () => {
    this.setLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('MoviePlayLists.deletePlaylistFailure')
    });
  }

  onDeletePlaylistSuccess = (playlistId) => {
    this.setLoading();

    this.playlistDeleted(playlistId);

    displaySuccessNotification({
      duration: 3,
      message: this.props.t('MoviePlayLists.deletePlaylistSuccess')
    });
  };

  onGetMoviesPlaylistFailure = (error) => {
    LogController.logError(error);
  };

  onPlaylistDetailsObtained = (playlistId, playlistName) => {
    this.setState({ playlistId, playlistName });
  };

  playlistDeleted = (playlistId) => {
    const filteredPlaylists = this.state.playlistData.movie_playlists.filter(this.filterPlaylistsById.bind(this, playlistId));

    this.setState({
      ...this.state,
      playlistData: {
        movie_playlists: filteredPlaylists
      }
    });
  };

  playlistChanged = (playlistId) => {
    const playlist = this.state.playlistData.movie_playlists.find(this.matchPlaylistById.bind(this, playlistId));
    if (playlist) {
      this.fetchMoviePlaylist();
    }
  };

  setLoading = message => {
    this.setState({
      loadingMsg: message
    });
  };

  toggleAddPlaylist = () => {
    const modal = (
      <AddPlaylistModal onPlaylistAdded={this.fetchMoviePlaylist} />
    );
    ModalController.showModal(modal);
  };

  render() {
    const { dataLoaded, loadingMsg } = this.state;
    return (
      <LoadingWrapper
        className='MoviePlayListsView'
        dataLoaded={dataLoaded}
        isLoading={!!loadingMsg}
        loadingMsg={loadingMsg}>
        <BaseNavigationItem routes={this.getRoutes()}>
          {this.getActions()}
        </BaseNavigationItem>
        <Router>
          <Playlist
            path={"/"}
            contentHeight={this.props.contentHeight}
            category={playlistType.movie}
            playlistData={this.state.playlistData.movie_playlists}
            deletePlaylist={this.deletePlaylist}
          />
          <MoviePlaylistDetails
            path={`/:playlistId`}
            playlistDeleted={this.playlistDeleted}
            playlistChanged={this.playlistChanged}
            onPlaylistDetailsObtained={this.onPlaylistDetailsObtained}
            fetchMoviePlaylist={this.fetchMoviePlaylist}
          />
        </Router>
      </LoadingWrapper>
    );
  };
}

export default withTranslation()(MoviePlayLists);
