import React, {Component} from 'react';
import {Button} from "antd";

import {stopEvent} from "../../../../../../../../../services/util-service/util.service";

import ConfigurationWillBeRemoved from "../ConfigurationWillBeRemoved/ConfigurationWillBeRemoved";
import PlayerConfigurationCard from "./PlayerConfigurationCard/PlayerConfigurationCard";
import PlayerConfigurationModal from "./PlayerConfigurationModal/PlayerConfigurationModal";

import {ModalController} from "../../../../../../../../../controllers/modal-controller/modal.controller";

/* istanbul ignore file */
class ConfigurationCollapseAbstract extends Component {

  data = [{
    name: 'playerColor',
    text: this.props.t('ConfigurationCollapseAbstract.playerColor')
  }, {
    name: 'download',
    text: this.props.t('ConfigurationCollapseAbstract.showDownload')
  }, {
    name: 'title',
    text: this.props.t('ConfigurationCollapseAbstract.showTitle')
  }, {
    name: 'rating',
    text: this.props.t('ConfigurationCollapseAbstract.showRating')
  }, {
    name: 'loop',
    text: this.props.t('ConfigurationCollapseAbstract.showLoop')
  }, {
    name: 'videoURL',
    text: this.props.t('ConfigurationCollapseAbstract.showVideo')
  }, {
    name: 'social',
    text: this.props.t('ConfigurationCollapseAbstract.showSocial')
  }, {
    name: 'miniPlayer',
    text: this.props.t('ConfigurationCollapseAbstract.showMiniPlayer')
  }, {
    name: 'buyNow',
    text: this.props.t('ConfigurationCollapseAbstract.showBuyNow')
  }, {
    name: 'login',
    text: this.props.t('ConfigurationCollapseAbstract.showLogin')
  }];

  configurePlayer = (ids, name, event) => {
    stopEvent(event);
    const props = this.getPlayerConfigurationModalProps(ids, name);
    const modal = (
      <PlayerConfigurationModal {...props}/>
    );
    ModalController.showModal(modal);
  };

  filterConfiguration = (config, item) => {
    return config && config.includes(item.name);
  };

  getConfig = (ids) => undefined;

  getConfigValues = (ids) => undefined;

  getConfigurePlayerBtn = (ids, name) => {
    let configurePlayerBtn = null;
    const config = this.getConfig(ids);
    if (!config || !config.length) {
      const {t} = this.props;
      configurePlayerBtn = (
        <Button onClick={this.configurePlayer.bind(this, ids, name)}>
          {t(`ConfigurationCollapseAbstract.configPlayer`)}
        </Button>
      );
    }

    return configurePlayerBtn;
  };

  getItemId = (ids) => undefined;

  getPlayerConfigurationModalProps = (ids, name) => {
    const config = this.getConfig(ids);
    const itemId = this.getItemId(ids);
    const configValues = this.getConfigValues(ids);
    return {
      itemName: name,
      itemId,
      onPlayerChange: this.onPlayerConfigurationChange,
      config,
      configValues,
      configurations: this.data
    };
  };

  onPlayerConfigurationChange = (id, config, configValues) => {
    
  };

  removeConfiguration = (id) => {
  };

  renderConfigurationWillBeRemoved = (isFullRemoval) => {
    return (
      <ConfigurationWillBeRemoved isFullRemoval={isFullRemoval}/>
    );
  };

  renderEnabledConfiguration = (ids, name) => {
    let config = this.getConfig(ids);
    const configValues = this.getConfigValues(ids);
    if (configValues && configValues.playerColor && config.indexOf('playerColor') === -1) {
      config.push('playerColor');
    }
    let enabledConfigurations = this.data.filter(this.filterConfiguration.bind(this, config));
    enabledConfigurations = enabledConfigurations.filter(config => config.name === 'playerColor'); // TODO remove when other options are supported
    return (
      <PlayerConfigurationCard name={name}
                               id={this.getItemId(ids)}
                               configuration={config}
                               configValues={configValues}
                               enabledConfigurations={enabledConfigurations}
                               onConfigurePlayer={this.configurePlayer.bind(this, ids, name)}
                               onRemoveConfiguration={this.removeConfiguration}/>
    );
  };

  renderStateIndicator = (ids, {name, deleteAll, deleteConfig, config, title}) => {
    let view = null;
    if (deleteAll) {
      view = this.renderConfigurationWillBeRemoved(true);
    } else if (deleteConfig) {
      view = this.renderConfigurationWillBeRemoved(false);
    } else if (config && config.length) {
      view = this.renderEnabledConfiguration(ids, name || title);
    }

    return view;
  };
}

export default ConfigurationCollapseAbstract;
