import React, {Component} from 'react';
import ErrorsFoundDialog from "../ErrorsFoundDialog/ErrorsFoundDialog";
import {ModalController} from "../../../../../../../../../../controllers/modal-controller/modal.controller";

import InvalidReasonDisplay from "./InvalidReasonDisplay/InvalidReasonDisplay";

export const InvalidReasons = Object.freeze({
  InvalidDate: 'InvalidDate',
  InvalidStartDate: 'InvalidStartDate',
  InvalidEndDate: 'InvalidEndDate',
  InvalidAdConfig: 'InvalidAdConfig'
});

/* istanbul ignore file */
class AdConfigurationBaseAbstract extends Component {

  displayErrorsFoundModal = () => {
    const modal = (
      <ErrorsFoundDialog/>
    );
    ModalController.showModal(modal);
  };

  getAdsInvalidKeys = (ads) => {
    const invalidKeys = {};
    let item = ads.length;
    let invalidReason;
    while (item) {
      item--;
      invalidReason = this.getAdInvalidReason(ads[item]);
      if (invalidReason) {
        invalidKeys[item] = invalidReason;
      }
    }

    return invalidKeys;
  };

  getAdInvalidReason = (ad) => {
    let reasons = [];
    if (this.isAdUserSet(ad)) {
      if (!this.isAdStartDateSet(ad)) {
        reasons.push(InvalidReasons.InvalidStartDate);
      } else {
        if ((ad.appliedEndDate || ad.appliedEndTime) && !this.isAdEndDateSet(ad)) {
          reasons.push(InvalidReasons.InvalidEndDate);
        }
      }
      if (!this.isAdConfigValid(ad)) {
        reasons.push(InvalidReasons.InvalidAdConfig);
      }
    }
    return reasons[0];
  };

  isAdConfigValid = ({goToText, goToUrl, url}) => {
    let isValid = url;
    if (isValid) {
      isValid = goToUrl ? goToText : !goToText;
    }
    return isValid;
  };

  isAdDateSet = ({appliedEndDate, appliedStartDate}) => {
    return appliedStartDate || appliedEndDate;
  };

  isAdStartDateSet = ({ appliedStartDate, appliedStartTime }) => {
    return appliedStartDate && appliedStartTime;
  }

  isAdEndDateSet = ({ appliedEndDate, appliedEndTime }) => {
    return appliedEndDate && appliedEndTime;
  }

  isAdDateSetProperly = (ad) => {
    const {
      appliedEndDate, appliedEndTime,
      appliedStartDate, appliedStartTime
    } = ad;
    return appliedStartDate && appliedStartTime && appliedEndDate && appliedEndTime;
  };

  isAdUserSet = ({applyToLoggedUser, applyToNotLoggedUser}) => {
    return applyToLoggedUser || applyToNotLoggedUser;
  };

  renderInvalidReasonExplanation = (invalidReason) => {
    return (
      <InvalidReasonDisplay invalidReason={invalidReason}/>
    );
  };
}

export default AdConfigurationBaseAbstract;
