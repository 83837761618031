import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import BaseHorizontalNavigation
  from "../../../../../../../../../components/BaseHorizontalNavigation/BaseHorizontalNavigation";
import BaseNavigationItem from "../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem";
import AddHostModal from './AddHostModal/AddHostModal';
import AddCatalogModal from './AddCatalogModal/AddCatalogModal';
import EditCatalogModal from "./EditCatalogModal/EditCatalogModal";
import CopyCatalogModal from './CopyCatalogModal/CopyCatalogModal';
import DetailsTab from "./DetailsTab/DetailsTab";
import HostsTab from "./HostsTab/HostsTab";
import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";
import EditTop10Modal from '../EditTop10Modal/EditTop10Modal';
import ConfigureCategoriesModal from '../ConfigureCategoriesModal/ConfigureCategoriesModal';
import MovieSpotlightModal from '../PropertiesMainView/MovieSpotlightModal/MovieSpotlightModal';
import SceneSpotlightModal from '../PropertiesMainView/SceneSpotlightModal/SceneSpotlightModal';
import PropertiesViewDetailsBody from './PropertiesViewDetailsBody/PropertiesViewDetailsBody';

import { renderMoment } from "../../../../../../../../../services/date-service/date.service";
import {
  getPropertiesRoute,
  getPropertyDetailsRoute,
  getPropertyDetailsMoviesRoute,
  getPropertiesDetailsBannersSetsRoute,
  getPropertiesUserRoute
} from "../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";
import {
  showPropertyDetails,
  showPropertyDetailsBannersSets,
  showPropertyDetailsMovies,
  showPropertyUsers
} from "../../../../../../../../../services/navigation/properties-navigation/properties-navigation.service";
import {
  getPropertyById,
  getWhitelabelConfig
} from "../../../../../../../../../services/properties-service/properties.service";

import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";
import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import './PropertiesViewDetails.scss';
import SetBrowseConfigurationModal from "../SetBrowseConfigurationModal/SetBrowseConfigurationModal";

class PropertiesViewDetails extends Component {

  state = {
    activeTab: '1',
    data: {
      hosts: {
        hosts: []
      },
      movie_catalogs: [],
      name: ''
    },
    dataLoaded: false,
    loadingMessage: '',
    whiteLabel: null
  };

  componentDidMount() {
    this.fetchData();
  };

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  fetchData = () => {
    const message = this.props.t('PropertiesViewDetails.loadingDetailsPleaseWait');
    this.setLoading(message);

    const { propertyId } = this.props;
    const promises = [getPropertyById(propertyId), getWhitelabelConfig(propertyId)];
    Promise.all(promises)
      .then(this.loadData)
      .catch(this.onLoadPropertyDataFailure);
  };

  getNavigationItems = () => {
    const { id, name } = this.state.data;
    const { t } = this.props;
    return [
      {
        name: t('PropertiesViewDetails.property', { name: name }),
        navigate: showPropertyDetails,
        link: getPropertyDetailsRoute(id)
      },
      {
        name: t('PropertiesViewDetails.movies'),
        navigate: showPropertyDetailsMovies,
        link: getPropertyDetailsMoviesRoute(id)
      },
      {
        name: t('PropertiesViewDetails.users'),
        navigate: showPropertyUsers,
        link: getPropertiesUserRoute(id)
      },
      {
        name: t('PropertiesViewDetails.banners'),
        navigate: showPropertyDetailsBannersSets.bind(this, id),
        link: getPropertiesDetailsBannersSetsRoute(id)
      },
    ];
  };

  getTabs = () => {
    const { t } = this.props;
    const { id, name, hosts } = this.state.data;
    return [{
      title: t('PropertiesViewDetails.details'),
      view: (
        <DetailsTab propertyData={this.getTableData()}
          name={name}
          showEditCatalogModal={this.showEditCatalogModal.bind(this)}
          showPropertyMovies={showPropertyDetailsMovies.bind(this, id)}
          showPropertyUsers={showPropertyUsers.bind(this, id)} />
      )
    }, {
      title: t('PropertiesViewDetails.hosts'),
      view: (
        <HostsTab hosts={hosts.hosts}
          name={name}
          onHostListUpdate={this.fetchData}
        />
      )
    }];
  };

  getPropertyActions = () => {
    const { t } = this.props;
    const { movie_catalogs } = this.state.data;
    let catalogButton = (
      <Button onClick={this.showEditCatalogModal}
        key="editCatalogBtn">
        <Icon type="edit" />
        {t('PropertiesViewDetails.editCatalog')}
      </Button>
    );

    if (!movie_catalogs.length) {
      catalogButton = (
        <Button onClick={this.showAddCatalogModal}
          key="addCatalogBtn">
          <Icon type="file-add" />
          {t('PropertiesViewDetails.addCatalog')}
        </Button>
      );
    }

    return [
      <Button onClick={this.showAddHostModal}
        key="addHostBtn">
        <Icon type="file-add" />
        {t('PropertiesViewDetails.addHost')}
      </Button>,
      catalogButton,
      <Button onClick={this.showCopyCatalogModal}
        key="copyCatalogBtn">
        <Icon type="file-add" />
        {t('PropertiesBody.copyCatalog')}
      </Button>,
      <Button onClick={this.showEditTopTenModal}
        key="editTopTenBtn">
        <Icon type="edit" />
        {t('PropertiesViewDetails.editTopTen')}
      </Button>,
      <Button onClick={this.showConfigureCategoriesModal}
        key="showConfigureCategoriesBtn">
        <Icon type="edit" />
        {t('PropertiesViewDetails.categoriesConfig')}
      </Button>,
      <Button onClick={this.showMovieSpotlightModal}
        key="showMovieSpotlightModalBtn">
        <Icon type="edit" />
        {t('PropertiesViewDetails.setMovieSpotlight')}
      </Button>,
      <Button onClick={this.showSetSceneSpotlightModal}
        key="showSetSceneSpotlightBtn">
        <Icon type="edit" />
        {t('PropertiesViewDetails.setSceneSpotlight')}
      </Button>,
      <Button onClick={this.showSetBrowseConfigurationModal}
        key="setBrowseConfigurationBtn">
        <Icon type="edit" />
        {t('PropertiesViewDetails.setBrowseConfiguration')}
      </Button>
    ];
  };

  getTableData = () => {
    const {
      id,
      movie_count: moviesCount,
      nats_users_active_count: activeMembers,
      nats_users_count: totalUsers,
      created_at,
      max_just_added_page_count,
      downloadable,
      stars_page_enabled
    } = this.state.data;
    return [{
      name: 'propertyId',
      value: id
    }, {
      name: 'movies',
      value: moviesCount
    }, {
      name: 'activeMembers',
      value: activeMembers
    }, {
      name: 'users',
      value: totalUsers
    }, {
      name: 'dateCreated',
      value: renderMoment(created_at)
    }, {
      name: 'stars_page_enabled',
      value: stars_page_enabled
    }, {
      name: 'max_just_added_page_count',
      value: max_just_added_page_count
    }, {
      name: 'downloadable',
      value: downloadable
    }];
  };

  loadData = (response, callback) => {
    const { data } = response[0].data;
    const { data: whiteLabelData } = response[1].data;
    const { max_just_added_page_count, stars_page_enabled } = whiteLabelData;
    data.stars_page_enabled = stars_page_enabled;
    data.max_just_added_page_count = max_just_added_page_count || 10;

    this.setDocumentTitle(data);
    this.setState({
      data: data,
      dataLoaded: true,
      whiteLabel: whiteLabelData
    });

    if (callback) {
      callback();
    }

    this.setLoading('');
  };

  onLoadPropertyDataFailure = (error) => {
    LogController.logError(error);
  };

  setDocumentTitle = (data) => {
    const title = this.props.t('PropertiesViewDetails.documentTitle', { name: data.name });
    DocumentTitleController.setDocumentTitle(title);
  };

  setLoading = (message) => {
    this.setState({
      loadingMessage: message
    });
  }

  showAddHostModal = () => {
    const modal = (
      <AddHostModal
        propertyId={this.state.data.id}
        onHostListUpdate={this.fetchData}
      />
    );
    ModalController.showModal(modal);
  };

  showAddCatalogModal = () => {
    const modal = (
      <AddCatalogModal propertyId={this.state.data.id} />
    );
    ModalController.showModal(modal);
  };

  showConfigureCategoriesModal = () => {
    const { propertyId } = this.props;
    const modal = (
      <ConfigureCategoriesModal propertyId={propertyId} />
    );
    ModalController.showModal(modal);
  };

  showEditCatalogModal = () => {
    const modal = (
      <EditCatalogModal
        propertyName={this.state.data.name}
        propertyId={this.props.propertyId}
      />
    );
    ModalController.showModal(modal);
  };

  showCopyCatalogModal = () => {
    const modal = (
      <CopyCatalogModal propertyId={this.props.propertyId} propertyName={this.state.data.name} />
    );
    ModalController.showModal(modal);
  };

  showEditTopTenModal = () => {
    const { propertyId } = this.props;
    const modal = (
      <EditTop10Modal propertyId={propertyId} />
    );
    ModalController.showModal(modal);
  };

  showMovieSpotlightModal = () => {
    const { propertyId } = this.props;
    const modal = (
      <MovieSpotlightModal propertyId={propertyId} />
    );
    ModalController.showModal(modal);
  };

  showSetBrowseConfigurationModal = () => {
    const { id: propertyId, name } = this.state.data;
    const modal = (
      <SetBrowseConfigurationModal propertyId={propertyId}
        propertyName={name} />
    );
    ModalController.showModal(modal);
  };

  showSetSceneSpotlightModal = () => {
    const { propertyId } = this.props;
    const modal = (
      <SceneSpotlightModal propertyId={propertyId} />
    );
    ModalController.showModal(modal);
  };

  getRoutes = () => {
    const { data } = this.state;
    const { t } = this.props;

    const routes = [{
      url: getPropertiesRoute(),
      text: t('PropertiesViewDetails.headerTitle')
    }, {
      text: data?.name
    }];

    return routes;
  };

  render() {
    const { loadingMessage, data, whiteLabel } = this.state;
    const { name, hosts } = data;
    const { contentHeight, propertyId } = this.props;
    return (
      <LoadingWrapper className="PropertiesViewDetails"
        dataLoaded={this.state.dataLoaded}
        isLoading={!!loadingMessage}
        loadingMsg={loadingMessage}>
        <BaseNavigationItem routes={this.getRoutes()} />
        <BaseHorizontalNavigation items={this.getNavigationItems()}>
          {this.getPropertyActions()}
        </BaseHorizontalNavigation>
        <PropertiesViewDetailsBody
          contentHeight={contentHeight - 30}
          propertyData={this.getTableData()}
          hosts={hosts.hosts}
          name={name}
          onHostListUpdate={this.fetchData}
          onPropertyEdit={this.fetchData}
          propertyId={propertyId}
          setLoading={this.setLoading}
          whiteLabel={whiteLabel} />
      </LoadingWrapper>
    );
  }
}

PropertiesViewDetails.propTypes = {
  contentHeight: PropTypes.number,
  propertyId: PropTypes.string
};

export default withTranslation()(PropertiesViewDetails);

