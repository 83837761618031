import React from 'react';
import { withTranslation } from 'react-i18next';

import StarMoviesFooter from '../StarMoviesFooter/StarMoviesFooter';
import StarMoviesList from '../StarMoviesList/StarMoviesList';
import MoviesMainViewAbstract from "../../../MoviesView/MoviesMainViewAbstract/MoviesMainViewAbstract";

class StarMoviesMainView extends MoviesMainViewAbstract {

  renderFooter = () => {
    const { navigate, fetchMovies, data } = this.props;
    return (
      <StarMoviesFooter
        onChange={fetchMovies}
        currentPage={data.currentPage}
        navigate={navigate}
        pageCount={data.pageCount}
        pageSize={data.pageSize}
        recordCount={data.recordCount}
      />
    );
  };

  renderMoviesList = () => {
    const {
      data,
      setMovieEnabled,
      starId,
      onMovieEdit,
      onSpecialCategoriesEdit,
      onMovieEdit2257,
      scrollTop } = this.props;
    return (
      <StarMoviesList
        movies={data.movies}
        onMovieEdit={onMovieEdit}
        onMovieEdit2257={onMovieEdit2257}
        onSpecialCategoriesEdit={onSpecialCategoriesEdit}
        setMovieEnabled={setMovieEnabled}
        starId={starId}
        scrollTop={scrollTop} />
    );
  };
}

export default withTranslation()(StarMoviesMainView);
