import {Subject} from 'rxjs';

class MainDashboardComCtrl {
  static instance;
  menuCollapsed = true;
  menuToggled = new Subject();
  mainNavigationLinkClicked = new Subject();

  static getInstance() {
    if (MainDashboardComCtrl.instance == null) {
      MainDashboardComCtrl.instance = new MainDashboardComCtrl();
    }

    return MainDashboardComCtrl.instance;
  }

  hideSideMenuIfVisible = () => {
    const instance = MainDashboardComCtrl.getInstance();
    if (!instance.menuCollapsed) {
      instance.toggleSideMenu();
    }
  };

  refreshMainContentPage() {
    const instance = MainDashboardComCtrl.getInstance();
    instance.mainNavigationLinkClicked.next();
  };

  toggleSideMenu() {
    const instance = MainDashboardComCtrl.getInstance();
    this.menuCollapsed = !this.menuCollapsed;
    instance.menuToggled.next(this.menuCollapsed);
  }
}

export const MainDashboardComController = MainDashboardComCtrl.getInstance();
