import PropTypes from "prop-types";

import MoviesViewListAbstract from "../../../../MoviesView/MoviesViewListAbstract/MoviesViewListAbstract";

import {getStudioMovieDetailsRoute} from "../../../../../../../../../../../services/navigation/studios-navigation/studios-navigation-routes.service";
import {showStudioMovieDetails} from "../../../../../../../../../../../services/navigation/studios-navigation/studios-navigation.service";

class StudioMoviesList extends MoviesViewListAbstract {

  constructor(props) {
    super(props);
    this.state.stickyPosition = 0;
  }

  /**
   * @override
   */
  getMovieDetailsRoute = (movieId) => {
    const {studioId} = this.props;
    return getStudioMovieDetailsRoute(studioId, movieId);
  };

  /**
   * @override
   */
  showMovieDetails = (movieId) => {
    const {studioId} = this.props;
    showStudioMovieDetails(studioId, movieId);
  };
}

StudioMoviesList.propTypes = {
  studioId: PropTypes.string.isRequired
};

export default StudioMoviesList;
