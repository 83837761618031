import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Input } from "antd";
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import './SingleCustodianOfRecords.scss';

class SingleCustodianOfRecords extends Component {

  state = {
    address: '',
    city: '',
    custodianOfRecords: '',
    name: '',
    postalCode: '',
    state: ''
  };

  constructor(props) {
    super(props);
    this.state = cloneDeep(props.data);
  }

  notifyDataChange = () => {
    const { index, onDataChange } = this.props;
    onDataChange(index, cloneDeep(this.state));
  };

  onChange = (key, event) => {
    const value = event.target ? event.target.value : event;
    this.setState({
      [key]: value
    }, this.notifyDataChange);
  };

  renderField = (label, children) => {
    return (
      <div className="Field">
        <span className="Label">{label}:</span>
        {children}
      </div>
    );
  };

  renderTextArea = (label, value, onChange) => {
    const children = (
      <Input.TextArea
        onChange={onChange}
        value={value}
      />
    );
    return this.renderField(label, children);
  };

  render() {
    const { t } = this.props;
    const { address, city, custodianOfRecords, name, postalCode, state } = this.state;
    return (
      <div className="SingleCustodianOfRecords">
        {this.renderTextArea(t('SingleCustodianOfRecords.name'), name, this.onChange.bind(this, 'name'))}
        {this.renderTextArea(t('SingleCustodianOfRecords.custodianOfRecords'), custodianOfRecords, this.onChange.bind(this, 'custodianOfRecords'))}
        {this.renderTextArea(t('SingleCustodianOfRecords.address'), address, this.onChange.bind(this, 'address'))}
        {this.renderTextArea(t('SingleCustodianOfRecords.city'), city, this.onChange.bind(this, 'city'))}
        {this.renderTextArea(t('SingleCustodianOfRecords.state'), state, this.onChange.bind(this, 'state'))}
        {this.renderTextArea(t('SingleCustodianOfRecords.postalCode'), postalCode, this.onChange.bind(this, 'postalCode'))}
      </div>
    );
  }
}

SingleCustodianOfRecords.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onDataChange: PropTypes.func.isRequired
};

export default withTranslation()(SingleCustodianOfRecords);
