import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { Checkbox, Button, Input, InputNumber, DatePicker } from "antd";
import {
	editStar,
	getStarById,
} from "../../../../../../../../../services/stars-service/stars.service";
import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import {
	displaySuccessNotification,
	displayErrorNotification,
} from "../../../../../../../../../services/notification-service/notification.service";
import {
	uploadStarImage,
	deleteStarImage,
} from "../../../../../../../../../services/images-service/images.service";
import { convertMomentToDate } from "../../../../../../../../../services/date-service/date.service";

import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import UploadExclusiveImage from "../StarDetails/StarDetailsBody/StarDetailsBodyTable/UploadExclucisveImage/UploadExclusiveImage";

import "./EditStarModal.scss";

const { TextArea } = Input;

class EditStarModal extends Component {
	datePickerFormat = "MMMM Do YYYY";

	modalRef = React.createRef();

	startingData = {};

	state = {};

	componentDidMount() {
		this.fetchData();
	}

	convertToMoment = (time) => {
		return moment(time, "MM/DD/YYYY");
	};

	exclusiveImageUploaded = () => {
		return this.state.exclusive
			? this.getExclusiveImage().length || this.state.imageUploaded
			: true;
	};

	fetchData = () => {
		getStarById(this.props.starId).then(this.loadData);
	};

	filterExclusiveImages = (image) => image.type === "Exclusive";

	getActions = () => {
		const { t } = this.props;
		return [
			<Button key="cancelBtn" onClick={this.onCancel.bind(this)}>
				{t("EditStarModal.cancel")}
			</Button>,
			<Button
				key="saveChangesBtn"
				disabled={
					!this.exclusiveImageUploaded() || !this.hasDataChanged()
				}
				onClick={this.onSaveChanges.bind(this)}
			>
				{t("EditStarModal.saveChanges")}
			</Button>,
		];
	};

	getExclusiveImage = () => {
		return this.state.images
			? this.state.images.filter(this.filterExclusiveImages)
			: [];
	};

	getModal = () => {
		return this.modalRef.current;
	};

	getModalTitle = () => {
		return this.props.t("EditStarModal.editStar", {
			starName: this.props.starName,
		});
	};

	hasDataChanged = () => {
		return JSON.stringify(this.state) !== JSON.stringify(this.startingData);
	};

	loadData = (response) => {
		const {
			name,
			gender,
			eyeColor,
			hairColor,
			ethnicity,
			birthDate,
			heightInches,
			weightLbs,
			contractStudioId,
			biography,
			images,
			exclusive,
		} = response.data.data;
		const state = {
			name,
			gender,
			eyeColor,
			hairColor,
			ethnicity,
			birthDate: this.convertToMoment(birthDate),
			heightInches,
			weightLbs,
			contractStudioId,
			biography,
			exclusive: exclusive ? true : false,
			images,
			imageUploaded: false,
		};
		Object.assign(this.startingData, state);
		this.setState(state);
	};

	matchImageById = (imageId, image) => {
		return image.id === imageId;
	};

	onCancel = () => {
		this.getModal().closeModal();
	};

	onChange = (key, event) => {
		const value = event?.target
			? event?.target?.value || event?.target?.checked
			: event;
		this.setState({
			[key]: value,
		});
	};

	onSaveChanges = () => {
		const message = this.props.t("EditStarModal.savingMsg");
		this.getModal().setLoading(message);
		const {
			name,
			gender,
			eyeColor,
			hairColor,
			ethnicity,
			birthDate,
			heightInches,
			weightLbs,
			contractStudioId,
			biography,
			exclusive,
		} = this.state;
		const updateData = {
			name,
			gender,
			eyeColor,
			hairColor,
			ethnicity,
			birthDate:
				birthDate && birthDate.isValid()
					? birthDate.format("MM/DD/YYYY")
					: "null",
			heightInches,
			weightLbs,
			contractStudioId,
			biography,
			exclusive,
		};

		const postData = {
			name,
			gender,
			eyeColor,
			hairColor,
			ethnicity,
			birthDate:
				birthDate && birthDate.isValid()
					? convertMomentToDate(birthDate)
					: "null",
			heightInches,
			weightLbs,
			contractStudioId,
			biography,
			exclusive,
		};
		editStar(this.props.starId, postData)
			.then(this.onStarEditSuccess.bind(this, updateData))
			.catch(this.onStarEditFailure);
	};

	onStarEditFailure = () => {
		this.getModal().clearLoading();
		displayErrorNotification({
			duration: 3,
			message: this.props.t("EditStarModal.editStarFailure"),
		});
	};

	onStarEditSuccess = (data) => {
		this.getModal().closeModal();
		displaySuccessNotification({
			duration: 3,
			message: this.props.t("EditStarModal.editStarSuccess"),
		});
		this.props.onStarEdit(data);
	};

	onUploadFinished = (currentExclusiveImage) => {
		if (currentExclusiveImage.length) {
			const { starId } = this.props;
			deleteStarImage(starId, currentExclusiveImage[0].id);
		}

		this.setState({
			imageUploaded: true,
		});
	};

	showUploadImageModal = () => {
		const currentExclusiveImage = this.getExclusiveImage();
		const modal = (
			<UploadExclusiveImage
				uploadImages={this.uploadImages}
				onUploadFinished={this.onUploadFinished.bind(
					this,
					currentExclusiveImage
				)}
			/>
		);
		ModalController.showModal(modal);
	};

	uploadImages = (file, type) => {
		const { starId } = this.props;
		return uploadStarImage(starId, type, file);
	};

	render() {
		const { t } = this.props;
		const { exclusive } = this.state;
		return (
			<ModalDialog
				title={this.getModalTitle()}
				actions={this.getActions()}
				forwardedRef={this.modalRef}
			>
				<div className="EditStarModal">
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.name")}:
						</span>
						<TextArea
							onChange={this.onChange.bind(this, "name")}
							value={this.state.name}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.gender")}:
						</span>
						<TextArea
							onChange={this.onChange.bind(this, "gender")}
							value={this.state.gender}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.eyeColor")}:
						</span>
						<TextArea
							onChange={this.onChange.bind(this, "eyeColor")}
							value={this.state.eyeColor}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.hairColor")}:
						</span>
						<TextArea
							onChange={this.onChange.bind(this, "hairColor")}
							value={this.state.hairColor}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.ethnicity")}:
						</span>
						<TextArea
							onChange={this.onChange.bind(this, "ethnicity")}
							value={this.state.ethnicity}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.birthDate")}:
						</span>
						<DatePicker
							value={
								this.state.birthDate &&
									this.state.birthDate.isValid()
									? this.state.birthDate
									: null
							}
							format={this.datePickerFormat}
							onChange={this.onChange.bind(this, "birthDate")}
							allowClear={true}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.heightInches")}:
						</span>
						<InputNumber
							onChange={this.onChange.bind(this, "heightInches")}
							value={this.state.heightInches}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.weightLbs")}:
						</span>
						<InputNumber
							onChange={this.onChange.bind(this, "weightLbs")}
							value={this.state.weightLbs}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.contractStudioId")}:
						</span>
						<TextArea
							onChange={this.onChange.bind(
								this,
								"contractStudioId"
							)}
							value={this.state.contractStudioId}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.biography")}:
						</span>
						<TextArea
							onChange={this.onChange.bind(this, "biography")}
							value={this.state.biography}
						/>
					</div>
					<div className="EditStarModal-inner">
						<span className="EditStarModal-inner-label">
							{t("EditStarModal.exclusive")}:
						</span>
						<Checkbox
							checked={exclusive}
							onChange={this.onChange.bind(this, "exclusive")}
						/>
						{exclusive === true && (
							<Button onClick={this.showUploadImageModal}>
								{t("EditStarModal.uploadExclusiveImage")}
							</Button>
						)}
					</div>
				</div>
			</ModalDialog>
		);
	}
}

EditStarModal.propTypes = {
	starId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
	starName: PropTypes.string.isRequired,
	onStarEdit: PropTypes.func.isRequired,
};

export default withTranslation()(EditStarModal);
