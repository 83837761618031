import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Input, Checkbox, AutoComplete } from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import BaseCheckboxList from '../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList';

import { LogController } from "../../../../../../../../../../controllers/log-controller/log.controller";

import {
  displaySuccessNotification,
  displayErrorNotification
} from "../../../../../../../../../../services/notification-service/notification.service";
import {
  editMovie,
  getMovieById,
  setMovieEnabled
} from "../../../../../../../../../../services/movies-service/movies.service";
import { getProperties } from "../../../../../../../../../../services/properties-service/properties.service";
import { convertMomentToDate, convertToMoment, momentFormats } from "../../../../../../../../../../services/date-service/date.service";
import { searchCustomPreview } from "../../../../../../../../../../services/video-service/video.service";
import { getOnPropertiesData, sortByName } from '../../../../../../../../../../services/util-service/util.service';
import BaseDatePickerList from '../../../../../../../../../../components/BaseDatePickerList/BaseDatePickerList';

import './EditMovieModal.scss';

class EditMovieModal extends Component {
  filterDelay = 300;
  modalRef = React.createRef();
  startingData = {};

  state = {
    dataLoaded: false,
    descriptionNs: '',
    isNakedSwordOriginals: false,
    properties: [],
    publishedDate: '',
    releaseDate: '',
    titleNs: ''
  };

  timeoutId;

  componentDidMount() {
    const message = this.props.t('EditMovieModal.loadingDataMsg');
    this.setLoading(message);
    const promises = [
      getMovieById(this.props.movieId),
      getProperties()
    ];
    Promise.all(promises)
      .then(this.loadData)
      .catch(this.onLoadDataFailure);
  }

  checkStudios = studios => {
    return studios.findIndex(this.matchStudioName) > -1;
  };

  clearLoading = () => {
    const modal = this.getModal();
    if (modal) {
      modal.clearLoading();
    }
  };

  convertToMoment = (time) => {
    return time ? convertToMoment(time, true, true) : null;
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.onCancel.bind(this)}>
          {t('EditMovieModal.cancel')}
        </Button>
        <Button disabled={!this.hasDataChanged()}
          onClick={this.onSaveChanges.bind(this)}>
          {t('EditMovieModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getMovieEnableUpdate = () => {
    const { movieId } = this.props;
    const { enable: initiallyEnabled } = this.startingData;
    const { enable } = this.state;
    const promises = [];
    if (initiallyEnabled !== enable) {
      promises.push(setMovieEnabled(movieId, enable));
    }

    return promises;
  };

  getTitle = () => {
    return this.props.t('EditMovieModal.editMovie', { movieName: this.props.movieName });
  };

  getVideosValues = (videos) => {
    let url;
    if (videos && videos.length && typeof videos !== 'string') {
      url = videos[0].partial_url;
    } else {
      url = videos;
    }
    return url;
  };

  hasDataChanged = () => {
    return JSON.stringify(this.state) !== JSON.stringify(this.startingData);
  };

  loadData = (values) => {
    this.clearLoading();
    const {
      descriptionNs,
      enable,
      titleNs,
      ns_original_spotlight,
      videos,
      studios,
      movies_on_properties_data
    } = values[0].data.data;

    const { properties } = values[1].data.data;

    const {
      exclusive = [],
      excluded = [],
      publishStart = [],
      publishEnd = [],
      downloadableOnProperties = []
    } = getOnPropertiesData(movies_on_properties_data, properties);

    const state = {
      customPreview: this.getVideosValues(videos),
      ns_original_spotlight,
      dataLoaded: true,
      descriptionNs,
      enable,
      options: [],
      properties: properties.sort(sortByName),
      titleNs,
      isNakedSwordOriginals: this.checkStudios(studios),
      exclusive: exclusive.map(item => item.id),
      excluded: excluded.map(item => item.id),
      publishStart,
      publishEnd,
      downloadableOnProperties: downloadableOnProperties.map(item => item.id)
    };

    Object.assign(this.startingData, state);
    this.setState(state);
  };

  matchStudioName = item => {
    return item.name === 'NakedSword Originals';
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChange = (key, event) => {
    let value = event;
    if (event) {
      value = event.target ? event.target.value || event.target.checked : event;
    }
    this.setState({
      [key]: value
    });
  };

  onLoadDataFailure = (error) => {
    LogController.logError(error);
    this.clearLoading();
  };

  onSaveChanges = () => {
    const message = this.props.t('EditMovieModal.savingMsg');
    this.getModal().setLoading(message);

    const {
      titleNs,
      descriptionNs,
      customPreview,
      ns_original_spotlight,
      isNakedSwordOriginals,
      downloadableOnProperties = [],
      properties = [],
      exclusive = [],
      excluded = [],
      publishStart = [],
      publishEnd = []
    } = this.state;

    const { movieId } = this.props;

    const moviesOnPropertiesPost = properties.map(({ id }) => {
      const propertyPublishStart = publishStart.find(item => item.id === id).value;
      const propertyPublishEnd = publishEnd.find(item => item.id === id).value;

      const postObj = {
        properties_id: id,
        exclusive_excluded: null,
        publish_start: convertMomentToDate(propertyPublishStart, true, true),
        publish_end: convertMomentToDate(propertyPublishEnd, true, true),
        downloadable: 0
      };

      if (downloadableOnProperties.includes(id)) {
        postObj.downloadable = 1;
      }

      if (exclusive.includes(id)) {
        postObj.exclusive_excluded = 'exclusive';
      } else if (excluded.includes(id)) {
        postObj.exclusive_excluded = 'excluded';
      }

      return postObj;
    });

    const postData = {
      id: movieId,
      titleNs,
      descriptionNs,
      videos: customPreview,
      ns_original_spotlight: isNakedSwordOriginals ? ns_original_spotlight ? 1 : 0 : undefined,
      moviesOnPropertiesData: moviesOnPropertiesPost
    };

    const promises = [
      editMovie(movieId, postData),
      ...this.getMovieEnableUpdate()
    ];
    Promise.all(promises)
      .then(this.onMovieEditSuccess)
      .catch(this.onMovieEditFailure);
  };

  onMovieEditFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditMovieModal.editMovieFailure')
    });
  };

  onMovieEditSuccess = (data) => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditMovieModal.editMovieSuccess')
    });
    this.props.onMovieEdit(data);
  };

  onMultipleSelectChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  onTrailerChange = value => {
    this.setState({
      customPreview: value
    });
  };

  onTrailerSearch = (searchText) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.searchItems.bind(this, searchText), this.filterDelay);
  };

  onDateTimeChange = ({ key, id, dateTime, applyToAll }) => {
    const state = this.state[key] ?? [];

    const updatedState = state?.map(item => {
      if (item.id === id) {
        item.value = dateTime;
      } else if (applyToAll) {
        item.value = dateTime;
      }

      return item;
    });

    this.setState({ [key]: updatedState }, this.onDataChange);
  };

  renderEditDatePicker = (key, label) => {
    const props = {
      data: this.state[key],
      format: momentFormats.shortMonthDate,
      onChange: ({ id, dateTime, applyToAll }) => this.onDateTimeChange({
        key,
        id,
        dateTime: convertMomentToDate(dateTime, true, false),
        applyToAll
      }),
      showTime: true
    };

    const children = (
      <BaseDatePickerList {...props} />
    );

    return this.renderField(label, children);
  };

  renderField = (label, children) => {
    return (
      <div className="EditMovieModal-field">
        <span className="EditMovieModal-field-label">{label}:</span>
        {children}
      </div>
    );
  };

  renderMultipleSelect = (label, items, value, onChange) => {
    const children = (
      <BaseCheckboxList
        items={items}
        checked={value}
        onChange={onChange}
        showSelectAll={true} />
    );
    return this.renderField(label, children);
  };

  renderChecbox = (text, value, key) => {
    const label = this.props.t(text);
    const children = (
      <Checkbox
        checked={value}
        onChange={this.onChange.bind(this, key)}
      />
    );
    return this.renderField(label, children);
  };

  renderTrailerSearchField = () => {
    const { t } = this.props;
    const { customPreview, options } = this.state;
    return (
      <div className="EditMovieModal-trailer">
        <div className="EditMovieModal-field-label">
          {t('EditMovieModal.customPreview')}:
        </div>
        <AutoComplete
          autosize
          onSearch={this.onTrailerSearch}
          dataSource={options}
          onChange={this.onTrailerChange}
          value={customPreview}
        />
      </div>
    );
  };

  renderTextArea = (label, value, onChange) => {
    const children = (
      <Input.TextArea
        onChange={onChange}
        value={value}
      />
    );
    return this.renderField(label, children);
  };

  searchItems = (searchText) => {
    searchCustomPreview(searchText)
      .then(this.setCustomPreviewOptions);
  };

  setCustomPreviewOptions = (response) => {
    const { data } = response.data;
    this.setState({
      options: data
    });
  };

  setLoading = (message) => {
    const modal = this.getModal();
    if (modal) {
      modal.setLoading(message);
    }
  };

  render() {
    const { t } = this.props;
    const {
      dataLoaded,
      descriptionNs,
      properties,
      titleNs,
      isNakedSwordOriginals,
      downloadableOnProperties,
      exclusive,
      excluded,
      ns_original_spotlight,
      enable
    } = this.state;

    return (
      <ModalDialog className="EditMovieModal"
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className="EditMovieModal-inner">
          {this.renderChecbox('EditMovieModal.nsEnabled', enable, 'enable')}

          {isNakedSwordOriginals && this.renderChecbox('EditMovieModal.ns_original_spotlight', ns_original_spotlight, 'ns_original_spotlight')}

          {this.renderTextArea(t('EditMovieModal.titleNs'), titleNs, this.onChange.bind(this, 'titleNs'))}

          {this.renderTextArea(t('EditMovieModal.descriptionNs'), descriptionNs, this.onChange.bind(this, 'descriptionNs'))}

          {this.renderTrailerSearchField()}

          {dataLoaded && this.renderEditDatePicker('publishStart', t('EditMovieModal.publishStart'))}
          {dataLoaded && this.renderEditDatePicker('publishEnd', t('EditMovieModal.publishEnd'))}

          {dataLoaded && this.renderMultipleSelect(t('EditBannerModal.exclusiveProperties'), properties, exclusive, this.onMultipleSelectChange.bind(this, 'exclusive'))}

          {dataLoaded && this.renderMultipleSelect(t('EditBannerModal.excludedProperties'), properties, excluded, this.onMultipleSelectChange.bind(this, 'excluded'))}

          {dataLoaded && this.renderMultipleSelect(t('global.downloadable_on_properties'), properties, downloadableOnProperties, this.onMultipleSelectChange.bind(this, 'downloadableOnProperties'))}
        </div>
      </ModalDialog>
    );
  }
}

EditMovieModal.propTypes = {
  movieId: PropTypes.number.isRequired,
  movieName: PropTypes.string.isRequired,
  onMovieEdit: PropTypes.func.isRequired
};

export default withTranslation()(EditMovieModal);
