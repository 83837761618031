import {ROUTES} from "../../../routes";
import {MAIN_DASHBOARD_VIEW_ROUTES} from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";

/**
 * Returns properties view route
 *
 * @returns {string}
 */
export const getPropertiesRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.PROPERTIES}`;
};

export const getPropertyDetailsBaseRoute = (propertyId) => {
  return `${getPropertiesRoute()}/${propertyId}`;
};

/**
 * Returns property details route
 *
 * @param {string|number} propertyId
 *
 * @returns {string}
 */
export const getPropertyDetailsRoute = propertyId => {
  return `${getPropertyDetailsBaseRoute(propertyId)}/details`;
};

export const getPropertiesDetailsBannersSetsRoute = (propertyId) => {
  return `${getPropertyDetailsBaseRoute(propertyId)}/banner_sets`;
};

export const getPropertiesDetailsBannerSetBannersRoute = (propertyId, bannerSetId) => {
  return `${getPropertyDetailsBaseRoute(propertyId)}/banner_sets/${bannerSetId}/banners`;
};

export const getPropertiesUserRoute = propertyId => {
  return `${getPropertyDetailsBaseRoute(propertyId)}/users`;
};

export const getPropertyDetailsUsersRoute = (propertyId) => {
  return `${getPropertyDetailsRoute(propertyId)}/users`;
};

export const getPropertyDetailsMoviesRoute = propertyId => {
  return `${getPropertyDetailsBaseRoute(propertyId)}/movies`;
};

/**
 * Returns property movie details route
 *
 * @param {number|string} propertyId
 * @param {number|string} movieId
 *
 * @returns {string}
 */
export const getPropertyMovieDetailsRoute = (propertyId, movieId) => {
  return `${getPropertyDetailsMoviesRoute(propertyId)}/${movieId}`;
};