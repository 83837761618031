import {withTranslation} from "react-i18next";

import MoviesViewFooterAbstract from "../../../MoviesMainView/MoviesViewFooter/MoviesViewFooterAbstract";

import './SexActMoviesFooter.scss';

class SexActMoviesFooter extends MoviesViewFooterAbstract {
  renderChildren = () => null;
}

SexActMoviesFooter.propTypes = {};

export default withTranslation()(SexActMoviesFooter);
