import React, {Component} from 'react';
import {Input} from "antd";
import PropTypes from 'prop-types';

/* istanbul ignore file */
class BaseDelayedInput extends Component {

  debounceTime = 300;

  timeoutId;

  state = {
    value: ''
  };

  constructor(props) {
    super(props);
    const {debounceTime, initialValue} = this.props;
    if (initialValue) {
      this.state.value = initialValue;
    }
    if (debounceTime) {
      this.debounceTime = debounceTime;
    }
  }

  onChange = (event) => {
    const {value} = event.target;
    this.setState({value});
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.triggerChange, this.debounceTime);
  };

  triggerChange = () => {
    const {value} = this.state;
    this.props.onChange(value);
  };

  render() {
    const {autoFocus, allowClear, className, placeholder} = this.props;
    const {value} = this.state;
    return (
      <Input value={value}
             autoFocus={autoFocus}
             allowClear={allowClear}
             onChange={this.onChange}
             placeholder={placeholder}
             className={className}/>
    );
  }
}

BaseDelayedInput.propTypes = {
  autoFocus: PropTypes.bool,
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  debounceTime: PropTypes.number,
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default BaseDelayedInput;
