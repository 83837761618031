import { Button, Icon, Input } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../../services/notification-service/notification.service';
import { getWhitelabelBannedWords, setWhitelabelBannedWords } from '../../../../../../../../../../services/properties-service/properties.service';

import "./EditBannedWordsModal.scss";

class EditBannedWordsModal extends Component {
    modalRef = React.createRef();
    state = {
        words: []
    };

    componentDidMount() {
        const { t, propertyId } = this.props;
        this.getModal().setLoading(t("EditBannedWordsModal.loadingMsg"));
        getWhitelabelBannedWords(propertyId)
            .then(this.handleWhitelabelData)
            .catch(this.onFetchPropertyFail)
    }

    handleWhitelabelData = (response) => {
        this.getModal().clearLoading();
        const { data: words } = response.data;
        this.setState({ words });
    }

    getModal = () => {
        return this.modalRef.current;
    };

    getTitle = () => {
        const { t, propertyName } = this.props;
        return t("EditBannedWordsModal.title", { propertyName });
    };

    onChange = (event) => {
        const { words } = this.state;
        const { value, name } = event.target;
        const [, index] = name.split('-');
        words[Number(index)] = value;
        this.setState({
            words: [...words]
        });
    };
    onAddWord = () => {
        const { words } = this.state;
        this.setState({
            words: [...words, '']
        });
    };

    isDisabled = () => {
        const { words } = this.state;
        return !words.every(word => word.length);
    };

    remove = (index) => {
        let { words } = this.state;
        words.splice(index, 1);
        this.setState({
            words: [...words]
        });
    };

    renderWords = (word, index) => {
        const { t } = this.props;
        const isEmpty = word === '';
        return (
            <div className='Sample' key={index}>
                <div className={'Index'}>{index + 1}.</div>
                <label>{t('EditBannedWordsModal.label')}:</label>
                <div className='Column'>
                    <Input value={word}
                        autoFocus={isEmpty}
                        allowClear={true}
                        onChange={this.onChange}
                        placeholder={t('EditBannedWordsModal.placeholder')}
                        type={'text'}
                        name={`word-${index}`}
                        className={isEmpty ? 'error' : ''}
                    />
                </div>
                <div>
                    <Icon type="close" onClick={() => this.remove(index)} />
                </div>
            </div>
        );
    };

    getActions = () => {
        const { t } = this.props;
        return (
            <div className='SampleVideoConfiguration-actions'>
                <Button
                    onClick={this.onAddWord}
                >
                    {t('EditBannedWordsModal.add')}
                </Button>
                <div className='Section'>
                    <Button onClick={this.onCancel.bind(this)} key="cancelBtn">
                        {t("EditBannedWordsModal.cancel")}
                    </Button>
                    <Button
                        type="primary"
                        key="saveChangesBtn"
                        onClick={this.onSave.bind(this)}
                        disabled={this.isDisabled()}
                    >
                        {t("EditBannedWordsModal.saveChanges")}
                    </Button>
                </div>
            </div>
        );
    };

    onCancel = () => {
        this.getModal().closeModal();
    };

    onSave = () => {
        const { words } = this.state;
        const { propertyId, applyToAll } = this.props;
        setWhitelabelBannedWords(applyToAll || propertyId, words)
            .then(this.onSaveChangesSuccess)
            .catch(this.onSaveChangesFailure);
    };

    onFetchPropertyFail = () => {
        this.getModal().clearLoading();
        displayErrorNotification({
            duration: 3,
            message: this.props.t("EditBannerSetModal.onFetchPropertyFail"),
        });
        this.onCancel();
    }

    onSaveChangesFailure = () => {
        this.getModal().clearLoading();
        displayErrorNotification({
            duration: 3,
            message: this.props.t("EditBannerSetModal.saveFailureMsg"),
        });
        this.onCancel();
    };

    onSaveChangesSuccess = () => {
        this.getModal().clearLoading();
        displaySuccessNotification({
            duration: 3,
            message: this.props.t("EditBannerSetModal.saveSuccessMsg"),
        });
        this.onCancel();
    };

    render() {
        return (
            <ModalDialog
                title={this.getTitle()}
                actions={this.getActions()}
                forwardedRef={this.modalRef}
            >
                <div className="EditBannedWordsModal">
                    {this.state.words.map(this.renderWords)}
                </div>
            </ModalDialog>
        );
    }
}

export default withTranslation()(EditBannedWordsModal);

EditBannedWordsModal.propTypes = {
    propertyId: PropTypes.number,
    propertyName: PropTypes.string,
    applyToAll: PropTypes.string
}