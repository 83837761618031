import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Icon, DatePicker} from 'antd';
import moment from 'moment';

import './PayoutsByMonthHeader.scss';

class PayoutsByMonthHeader extends Component {

  state = {
    endDate: this.props.range[1],
    filtersDisplayed: false,
    startDate: this.props.range[0]
  }

  applyFilter = () => {
    const {startDate, endDate} = this.state;
    this.props.onDateChange(startDate, endDate);
  };

  disabledEndDate = endValue => {
    const {startDate} = this.state;
    return !!(startDate && endValue && (endValue.valueOf() <= startDate.valueOf())) ||
      (endValue && endValue > moment().endOf('month'));
  };

  disabledStartDate = startValue => {
    const {endDate} = this.state;
    return !!(startValue && endDate && (startValue.valueOf() > endDate.valueOf())) ||
      (startValue && startValue > moment().endOf('month'));
  };

  /**
   * Updates state to trigger filter re-render
   */
  displayFilters = () => {
    this.setState(prevState => {
      return {
        filtersDisplayed: !prevState.filtersDisplayed
      };
    });
  };

  /**
   * Returns filter icon type depending on state filtersDisplayed
   *
   * @returns {string}
   */
  getIconType = () => {
    return this.state.filtersDisplayed ? 'up' : 'down';
  };

  handleChange = (picker, date) => {
    this.setState({
      [picker]: date
    });
  };

  renderStartDate = () => {
    const {t} = this.props;
    const {startDate} = this.state;
    return this.renderMonthPickerField(
      t(`PayoutsByMonthHeader.start`), startDate, 'startPicker',
      this.disabledStartDate, this.handleChange.bind(this, 'startDate')
    );
  };

  renderEndDate = () => {
    const {t} = this.props;
    const {endDate} = this.state;
    return this.renderMonthPickerField(
      t(`PayoutsByMonthHeader.end`), endDate, 'endPicker',
      this.disabledEndDate, this.handleChange.bind(this, 'endDate')
    );
  };

  renderMonthPickerField = (label, value, name, disabledDateFn, onChange) => {
    return (
      <div className="MonthPickerField">
        <div className="Label">{label}:</div>
        <DatePicker.MonthPicker
          allowClear={false}
          name={name}
          disabledDate={disabledDateFn}
          value={value}
          onChange={onChange}/>
      </div>
    );
  };

  render() {
    const {onPayoutExport, t} = this.props;
    const {filtersDisplayed} = this.state;
    const classes = ['PayoutsByMonthHeader'];
    if (filtersDisplayed) {
      classes.push('FilterDisplayed');
    }
    return (
      <div className={classes.join(' ')}>
        <div className="PayoutsByMonthHeader-InnerWrapper"
             onClick={this.displayFilters}>
          <div className="Filter-Text">{t(`PayoutsByMonthHeader.filters`)}</div>
          <Icon type={this.getIconType()}/>
        </div>
        <div className='DateFiltersWrapper'>
          <div className="DatePickerContainer">
            {this.renderStartDate()}
            {this.renderEndDate()}
          </div>
          <Button onClick={this.applyFilter}>{t(`PayoutsByMonthHeader.applyFilter`)}</Button>
          <Button onClick={onPayoutExport}>{t(`PayoutsByMonthHeader.export`)}</Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PayoutsByMonthHeader);
