import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Select, Input } from 'antd';

import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../../../services/notification-service/notification.service';
import {
  getMoviesPlaylistCategories,
  editMoviePlaylist,
  getMoviesPlaylistById
} from '../../../../../../../../../../../../services/movies-service/movies.service';

import ModalDialog from '../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import './EditMoviePlaylistModal.scss';

class EditMoviePlaylistModal extends Component {

  modalRef = React.createRef();

  state = {
    currentPlaylistId: '',
    currentPlaylistName: '',
    playlistCategory: {
      name: '',
      id: ''
    },
    movieCategories: [],
    playlistName: ''
  }

  componentDidMount() {
    this.fetchData();
  };

  fetchData = () => {
    const promises = [
      this.fetchMoviePlaylistDetails(),
      this.fetchMoviePlaylistCategories()
    ];

    Promise.all(promises)
      .then(this.loadData)
      .catch(this.loadDataFailure);
  };

  fetchMoviePlaylistCategories = () => {
    return getMoviesPlaylistCategories();
  };

  fetchMoviePlaylistDetails = () => {
    const {playlistId} = this.props;
    return getMoviesPlaylistById(playlistId);
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='EditMoviePlaylistModal-actions'>
        <Button key='cancelBtn' onClick={this.onCancel.bind(this)}>
          {t('EditMoviePlaylistModal.cancel')}
        </Button>
        <Button
          key='saveChangesBtn'
          disabled={!this.hasDataChanged()}
          onClick={this.onSaveChanges.bind(this)}
        >
          {t('EditMoviePlaylistModal.saveChanges')}
        </Button>
      </div>
    );
  };

  loadDataFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditMoviePlaylistModal.getDataFailed')
    });
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getModalTitle = () => {
    return this.props.t('EditMoviePlaylistModal.editPlaylistTitle', {
      playlistName: this.state.currentPlaylistName
    });
  };

  hasDataChanged = () => {
    return this.state.playlistCategory.id !== this.state.currentPlaylistId || this.state.playlistName !== this.state.currentPlaylistName;
  };

  loadData = values => {
    const movieDetailsData = values[0].data.data;
    const categoryData = values[1].data.data;

    this.setState({
      currentPlaylistId: movieDetailsData.movie_playlist_category_id,
      currentPlaylistName: movieDetailsData.name,
      movieCategories: categoryData.movie_playlist_categories,
      playlistCategory: {
        id: movieDetailsData.movie_playlist_category_id,
        name: movieDetailsData.movie_playlist_category_name
      },
      playlistName: movieDetailsData.name
    });
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onCategoryChange = (item) => {
    this.setState({
      playlistCategory: {
        id: item.id,
        name: item.name
      }
    });
  };

  onChangeName = event => {
    this.setState({
      playlistName: event.target.value
    });
  };

  onEditPlaylistFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditMoviePlaylistModal.editPlaylistFailed')
    });
  }

  onEditPlaylistSuccess = data => {
    this.getModal().closeModal();

    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditMoviePlaylistModal.editPlaylistSuccess')
    });

    this.props.onEditPlaylist(data);
  };

  onSaveChanges = () => {
    const message = this.props.t('EditMoviePlaylistModal.savingMsg');
    this.getModal().setLoading(message);

    const {playlistCategory, playlistName} = this.state;
    const {playlistId} = this.props;
    const updateData = {
      movie_playlist_category_name: playlistCategory.name,
      movie_playlist_category_id: playlistCategory.id,
      name: playlistName
    };
    const postData = {
      movie_playlist_category_id: playlistCategory.id,
      name: playlistName
    };

    editMoviePlaylist(playlistId, postData)
      .then(this.onEditPlaylistSuccess.bind(this, updateData))
      .catch(this.onEditPlaylistFailure);
  };

  renderCategoryOptions = (item) => {
    return (
      <Select.Option 
        key={`category-${item.id}`} 
        value={item.name}
        onClick={this.onCategoryChange.bind(this, item)}
      >
        {item.name}
      </Select.Option>
    )
  };

  render() {
    const {t} = this.props;

    return (
      <ModalDialog
        title={this.getModalTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='EditMoviePlaylistModal'>
          <div className='EditMoviePlaylistModal-inner'>
            <span className='EditMoviePlaylistModal-inner-label'>
              {t('EditMoviePlaylistModal.nameTitle')}:
            </span>
            <Input
              onChange={this.onChangeName}
              value={this.state.playlistName}
            />
          </div>
           <div className='EditMoviePlaylistModal-inner'>
             <span className='EditMoviePlaylistModal-inner-label'>
               {t('EditMoviePlaylistModal.categoryNameTitle')}:
             </span>
             <Select showArrow
               className="EditMoviePlaylistModal-select"
               value={this.state.playlistCategory.name}
             >
               {this.state.movieCategories.map(this.renderCategoryOptions)}
             </Select>
           </div>
         </div>
      </ModalDialog>
    )
  }
};

export default withTranslation()(EditMoviePlaylistModal);