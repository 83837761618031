import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Checkbox, Input} from "antd";

import BaseFooter from '../../../../../../../../../../components/BaseFooter/BaseFooter';
import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import BaseCard from '../../../../../../../../../../components/BaseCard/BaseCard';
import ImageComponent from '../../../../../../../../../../components/ImageComponent/ImageComponent';

import {getPropertyMovies} from '../../../../../../../../../../services/properties-service/properties.service';
import {displayErrorNotification} from '../../../../../../../../../../services/notification-service/notification.service';

import './AddMoviesTop10Modal.scss';

class AddMoviesTop10Modal extends Component {
  filterDelay = 300;

  modalRef = React.createRef();

  state = {
    currentPage: 1,
    filteredMovies: [],
    filters: ["in_feed=1", "elastic_score=desc"],
    selectedMovies: []
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const message = this.props.t('AddMoviesTop10Modal.loadingMsg');
    this.getModal().setLoading(message);
    const {propertyId} = this.props;
    const {currentPage, filters} = this.state;

    getPropertyMovies(propertyId, currentPage, filters)
      .then(this.loadFilteredMovies)
      .catch(this.onLoadDataFailure);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="AddMoviesTop10Modal-actions">
        <Button onClick={this.onCancel.bind(this)}
                key="cancelBtn">
          {t('AddMoviesTop10Modal.cancel')}
        </Button>
        <Button
          key="saveChangesBtn"
          onClick={this.saveChanges.bind(this)}>
          {t('AddMoviesTop10Modal.saveChanges')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    const {t} = this.props;
    return t('AddMoviesTop10Modal.title');
  };

  loadFilteredMovies = response => {
    const {movies, pagination} = response.data.data;
    const filteredMovies = movies.filter(this.matchMovieIds);
    this.setState({
      currentPage: pagination.current_page,
      filteredMovies: filteredMovies,
      pageCount: pagination.last_page,
      pageSize: pagination.per_page,
      recordCount: pagination.total,
    });
    this.getModal().clearLoading();
  };

  matchCheckedMovies = (movieId) => {
    const {selectedMovies} = this.state;
    return selectedMovies.filter(this.matchMovieById.bind(this, movieId)).length > 0;
  };

  matchMovieById = (movieId, movie) => {
    return movie.movieId === movieId;
  };

  matchMovieIds = (movie) => {
    const {currentOrder} = this.props;
    return !currentOrder.includes(movie.movieId);
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChange = movie => {
    const {movieId: id} = movie;
    let selectedMovies = this.state.selectedMovies;
    const index = selectedMovies.findIndex(this.matchMovieById.bind(this, id));
    if (index > -1) {
      selectedMovies.splice(index, 1);
    } else {
      selectedMovies.push(movie);
    }

    this.setState({
      selectedMovies: selectedMovies
    });
  };

  onLoadDataFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddMoviesTop10Modal.loadDataFailed')
    });
  };

  onNameFilterChange = (event) => {
    const name = event.target.value;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.setFilters.bind(this, name), this.filterDelay);
  };

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, this.fetchData);
  };

  renderFooter = () => {
    const {currentPage, pageCount, pageSize, recordCount} = this.state;
    return (
      <BaseFooter currentPage={currentPage}
                  onChange={this.onPageChange}
                  pageCount={pageCount}
                  pageSize={pageSize}
                  recordCount={recordCount}/>
    );
  };

  renderMovieCard = (item, index) => {
    const {title, movieId, image} = item;
    return (
      <BaseCard key={`$movieCard-${movieId}-${index}`}>
        <Checkbox checked={this.matchCheckedMovies(movieId)}
                  onClick={this.onChange.bind(this, item)}/>
        <ImageComponent url={image} />
        <div className="Title">{title}</div>
      </BaseCard>
    )
  };

  renderMovieCards = () => {
    const {filteredMovies} = this.state;
    let movieCards = null;

    if(filteredMovies.length) {
      movieCards = filteredMovies.map(this.renderMovieCard);
    }

    return movieCards;
  };

  renderSearchInput = () => {
    const {t} = this.props;
    return (
      <Input 
        className="SearchInput"
        allowClear={true}
        placeholder={t('AddMoviesTop10Modal.searchMovies')}
        onChange={this.onNameFilterChange}
      /> 
    );
  };

  saveChanges = () => {
    const {selectedMovies: updateData} = this.state;
    this.props.updateTopTenMoviesList(updateData);
    this.getModal().closeModal();
  };

  setFilters = name => {
    let filters = [];
    if(name) {
      filters.push(`search=${name}`)
      filters.push(`in_feed=1`);
      filters.push(`elastic_score=desc`);
    }
    this.setState({
      filters: filters
    }, this.fetchData);
  };

  render() {
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className="AddMoviesTop10Modal">
          {this.renderSearchInput()}
          {this.renderMovieCards()}
        </div>
        {this.renderFooter()}
      </ModalDialog>
    );
  }
}

export default withTranslation()(AddMoviesTop10Modal);