import React, {Component} from 'react';
import {Select} from "antd";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import './BaseSort.scss';

class BaseSort extends Component {
  state = {
    sortFilter: '',
    filters: {
      sort: {
        ascending: false,
        name: ''
      }
    }
  };

  constructor(props) {
    super(props);
    const {defaultSortFilter, value} = props;
    const {ascending, name} = value;
    Object.assign(this.state, {
      sortFilter: defaultSortFilter,
      filters: {
        sort: {
          ascending,
          name
        }
      }
    });
  }

  callPropsValueChange = (name, ascending, text) => {
    if (ascending === undefined) {
      ascending = true;
    }
    this.setState({
      filters: {
        sort: {
          ascending,
          name
        }
      }
    });
    this.props.onValueChange(
      name,
      ascending,
      text
    );

  }

  getAscendingValue = (prevState, sortFilter, ascending) => {
    let ascendingValue = ascending;
    const sort = prevState.filters.sort.name;
    if (sort === sortFilter) {
      ascendingValue = !prevState.filters.sort.ascending;
    } else {
      ascendingValue = ascendingValue === undefined;
    }
    return ascendingValue;
  };

  getStateSortFilter = (sortFilter, ascendingValue) => {
    const {t} = this.props;
    const ascending = ascendingValue ? t('BaseSort.ascending') : t('BaseSort.descending');
    return `${sortFilter.name} (${ascending})`;
  };

  getSortFilterSelectionItemClasses = (item, ascending) => {
    return this.isSortFilterItemSelected(item, ascending) ? ['BaseSortDropdownItem SelectedSort'] : ['BaseSortDropdownItem'];
  };

  isSortFilterItemSelected = (item, ascending) => {
    const sortFilter = this.state.filters.sort;
    return (item === sortFilter.name) && (ascending === sortFilter.ascending);
  };

  renderSortFilterItem = (item) => {
    const {t} = this.props;
    return <Select.Option key={item.name} value={item.name}>
      <div className="BaseSortDropdownItemWrapper">
        <span className="BaseSortDropdownItemText"
              onClick={this.callPropsValueChange.bind(this, item.name, undefined, item.text)}>
          {item.text}
        </span>
        <div>
          {this.renderSortFilterSelectionItem(item.name, t('BaseSort.ascending'), true, item.text)}
          {this.renderSortFilterSelectionItem(item.name, t('BaseSort.descending'), false, item.text)}
        </div>
      </div>
    </Select.Option>
  };

  renderSortFilterSelectionItem = (item, text, ascending, localizedItemText) => {
    const classes = this.getSortFilterSelectionItemClasses(item, ascending);
    return (
      <span className={classes.join(' ')}
            onClick={this.callPropsValueChange.bind(this, item, ascending, localizedItemText)}>{text}</span>
    );
  };

  render() {
    const {t, value} = this.props;
    const {ascending, text} = value;
    const currentValue = this.getStateSortFilter({name: text}, ascending);
    return (
      <div className="BaseSort">
        <div className="label">{t('BaseSort.sortBy')}</div>
        <Select showArrow
                className="BaseSort-select"
                value={currentValue}>
          {this.props.sortCriteria.map(this.renderSortFilterItem)}
        </Select>
      </div>
    );
  }
}

BaseSort.propTypes = {
  defaultSortFilter: PropTypes.string,
  onValueChange: PropTypes.func,
  sortCriteria: PropTypes.array,
  value: PropTypes.any
};

export default withTranslation()(BaseSort);
