import { navigate } from "@reach/router";
import { MAIN_DASHBOARD_VIEW_ROUTES } from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";
import { ROUTES } from "../../../routes";

export const getUnassignedViewTime = () => {
    return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.UNASSIGNED_VIEW_TIME}`;
};

export const showUnassignedViewTime = () => {
    return navigate(getUnassignedViewTime());
};
