import React, {Component} from 'react';
import PropTypes from 'prop-types';

import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import LoadingWrapper from "../../../../../../../../../../components/LoadingWrapper/LoadingWrapper";

import {LogController} from "../../../../../../../../../../controllers/log-controller/log.controller";

import './ContentInfoView.scss';

class ContentInfoView extends Component {

  state = {
    data: {
      authorization: undefined,
      method: undefined,
      response: undefined,
      responseStatus: undefined,
      serviceAddress: undefined
    },
    dataLoaded: false,
    loadingMsg: ''
  };

  componentDidMount() {
    this.fetchData();
  }

  clearLoading = () => {
    this.setState({loadingMsg: ''});
  };

  fetchData = () => {
  };

  getContentInfoResponse = () => {
    const {response} = this.state.data;
    return {
      __html: `<pre>${JSON.stringify(response, undefined, 4)}</pre>`
    };
  };

  onRequestFailure = (error) => {
    LogController.logError(error);
    this.clearLoading();
  };

  setLoading = (loadingMsg) => {
    this.setState({loadingMsg});
  };

  renderField = (label, value) => {
    return (
      <div className="Field">
        <div className="Label">{label}</div>
        <div className="Value">{value}</div>
      </div>
    );
  };

  renderContentInfoResponse = () => {
    return (
      <BaseCard>
        <div className="ResponseText"
             dangerouslySetInnerHTML={this.getContentInfoResponse()}/>
      </BaseCard>
    );
  };

  renderTitle = () => {
    const {t} = this.props;
    return (
      <div className="Title">
        {t('ContentInfoView.aebnContentApi')}
      </div>
    );
  };

  render() {
    const {t} = this.props;
    const {data, dataLoaded, loadingMsg} = this.state;
    const {method, status: responseStatus, url: serviceAddress} = data;
    return (
      <LoadingWrapper dataLoaded={dataLoaded}
                      isLoading={!!loadingMsg}
                      loadingMsg={loadingMsg}
                      className="ContentInfoView">
        <div className="ContentInfoView-inner">
          {this.renderTitle()}
          {this.renderField(t('ContentInfoView.serviceAddress'), serviceAddress)}
          {this.renderField(t('ContentInfoView.method'), method)}
          {this.renderField(t('ContentInfoView.responseStatus'), responseStatus)}
          {this.renderContentInfoResponse()}
        </div>
      </LoadingWrapper>
    );
  }
}

ContentInfoView.propTypes = {
  movieId: PropTypes.string,
  sceneId: PropTypes.string
};

export default ContentInfoView;
