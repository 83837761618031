import axios from 'axios';

export const urls = {
  licensors: '/licensors',
  licensorEdit: '/licensors/edit',
  licensorRoyalties: '/licensors/royalties'
};

export const addLicensor = data => {
  return axios.post(urls.licensors, data);
};

export const editLicensor = (id, { name }) => {
  return axios.patch(`${urls.licensorEdit}/${id}`, null, {
    params: {
      name
    }
  });
};

export const getAllLicensorsWithoutPagination = () => {
  return axios.get(`${urls.licensors}/without_pagination`);
};

export const getLicensorById = licensorId => {
  return axios.get(`${urls.licensors}/${licensorId}`);
};

export const getLicensors = (page, filters) => {
  const params = {
    page
  };

  if (filters?.search) {
    params.name = filters.search;
  }

  if (filters?.sort) {
    const { name, ascending } = filters.sort;
    params.sort_by = name;
    params.sort_order = ascending ? 'asc' : 'desc';
  }

  return axios.get(`${urls.licensors}`, {
    params
  });
};

export const setLicensorRoyalties = (licensorId, properties_id, data) => {
  const { marketing: marketing_fee, processing: processing_fee, split } = data;
  return axios.patch(`${urls.licensorRoyalties}`, {
    licensors_id: licensorId,
    marketing_fee,
    properties_id,
    processing_fee,
    split
  })
};
