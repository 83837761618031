import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Input, InputNumber } from 'antd';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import BaseLoadMoreSelect from "../../../../../../../../../components/BaseLoadMoreSelect/BaseLoadMoreSelect";
import ModalDialog from '../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import {
  addLicensor,
  setLicensorRoyalties
} from '../../../../../../../../../services/licensors-service/licensors.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../services/notification-service/notification.service';
import { getManufacturers } from "../../../../../../../../../services/manufacturers-service/manufacturers.service";
import { getProperties } from "../../../../../../../../../services/properties-service/properties.service";

import { LogController } from "../../../../../../../../../controllers/log-controller/log.controller";

import './AddLicensorModal.scss';

class AddLicensorModal extends Component {

  fields = ['name'];

  isUnMounted = false;

  modalRef = React.createRef();

  propertiesIds = [];

  state = {
    isValid: false,
    licensorName: '',
    manufacturerId: undefined,
    marketing: 10,
    processing: 15,
    split: 30
  };

  componentDidMount() {
    this.fetchProperties();
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  addLicensor = () => {
    const message = this.props.t('AddLicensorModal.addingLicensorPleaseWait');
    this.getModal().setLoading(message);
    const { licensorName, manufacturerId } = this.state;
    const data = {
      name: licensorName,
      manufacturers_id: manufacturerId
    };
    addLicensor(data)
      .then(this.addRoyalties)
      .then(this.onAddLicensorSuccess)
      .catch(this.onAddLicensorFailure);
  };

  addRoyalties = (response) => {
    const { id: licensorId } = response.data.data;
    const { marketing, processing, split } = this.state;
    return setLicensorRoyalties(licensorId, this.propertiesIds, {
      marketing,
      processing,
      split
    });
  };

  fetchProperties = () => {
    getProperties()
      .then(this.loadProperties)
      .catch(this.onRequestFailure);
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='AddLicensorModal-actions'>
        <Button onClick={this.onCancel} key='cancelBtn'>
          {t('AddLicensorModal.cancel')}
        </Button>
        <Button
          onClick={this.addLicensor}
          key='addLicensorBtn'
          disabled={!this.state.isValid}
          type='primary'>
          {t('AddLicensorModal.addLicensor')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getPropertyId = ({ id }) => id;

  getTitle = () => {
    return this.props.t('AddLicensorModal.addLicensor');
  };

  isValid = (data) => {
    const { licensorName, manufacturerId, marketing, processing, split } = data;
    return !!(licensorName && manufacturerId && marketing && processing && split);
  };

  loadProperties = (response) => {
    const { properties } = response.data.data;
    this.propertiesIds = properties.map(this.getPropertyId);
  };

  onAddLicensorFailure = (error) => {
    LogController.logError(error);
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddLicensorModal.addLicensorFailed')
    });
  };

  onAddLicensorSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddLicensorModal.addLicensorSuccess')
    });
    this.props.onLicensorAdded();
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChange = (key, value) => {
    const newValue = value.target ? value.target.value : value;
    this.setState(prevState => {
      const newState = cloneDeep(prevState);
      newState[key] = newValue;
      newState.isValid = this.isValid(newState);

      return newState;
    });
  };

  onRequestFailure = (error) => {
    LogController.logError(error);
  };

  renderField = (label, children) => {
    return (
      <div className="AddLicensorModal-field-wrapper">
        <div className="Label">{label}:</div>
        {children}
      </div>
    );
  };

  renderInputNumber = (label, value, onChange) => {
    const children = (
      <InputNumber value={value}
        onChange={onChange} />
    );
    return this.renderField(label, children);
  };

  renderManufacturerSelect = () => {
    const label = this.props.t('AddLicensorModal.manufacturer');
    const children = (
      <BaseLoadMoreSelect
        onSelect={this.onChange.bind(this, 'manufacturerId')}
        getData={getManufacturers}
        idIdentifier="id"
        name="manufacturers"
        searchNotAvailable={true}
      />
    );
    return this.renderField(label, children);
  };

  renderName = () => {
    const { licensorName } = this.state;
    const label = this.props.t('AddLicensorModal.licensorName');
    const children = (
      <Input onChange={this.onChange.bind(this, 'licensorName')}
        value={licensorName} />
    );
    return this.renderField(label, children);
  };

  render() {
    const { t } = this.props;
    const { marketing, processing, split } = this.state;
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className='AddLicensorModal'>
          {this.renderName()}
          {this.renderManufacturerSelect()}
          {this.renderInputNumber(t('AddLicensorModal.marketing'), marketing, this.onChange.bind(this, 'marketing'))}
          {this.renderInputNumber(t('AddLicensorModal.processing'), processing, this.onChange.bind(this, 'processing'))}
          {this.renderInputNumber(t('AddLicensorModal.split'), split, this.onChange.bind(this, 'split'))}
        </div>
      </ModalDialog>
    );
  }
}

AddLicensorModal.propTypes = {
  onLicensorAdded: PropTypes.func.isRequired
};

export default withTranslation()(AddLicensorModal);
