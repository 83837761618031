import React, {Component} from 'react';
import {Router} from "@reach/router";
import PropTypes from 'prop-types';

import PayoutsByLicensorMainView from "./PayoutsByLicensorMainView/PayoutsByLicensorMainView";
import PayoutsByMovie from "../PayoutsByMovie/PayoutsByMovie";

import './PayoutsByLicensor.scss';

class PayoutsByLicensor extends Component {

  propertyId;

  constructor(props) {
    super(props);
    this.propertyId = props.propertyId;
  };

  componentDidMount() {
    this.props.onPayoutsByLicensorInitialize(this.propertyId);
  };

  render() {
    const {
      contentHeight, data,
      moviesData, onPayoutsByMoviesInitialize, onMoviesPageChange,
      onPageChange, propertyId, year, month
    } = this.props;
    return (
      <div className="PayoutsByLicensor">
        <Router>
          <PayoutsByLicensorMainView path={'/*'}
                                     contentHeight={contentHeight}
                                     data={data}
                                     onPageChange={onPageChange}
                                     propertyId={propertyId}
                                     month={month}
                                     year={year}/>
          <PayoutsByMovie path={`/licensors/:licensorId`}
                          contentHeight={contentHeight}
                          data={moviesData}
                          onPageChange={onMoviesPageChange}
                          onPayoutsByMoviesInitialize={onPayoutsByMoviesInitialize}/>
        </Router>
      </div>
    );
  };
}

PayoutsByLicensor.propTypes = {
  contentHeight: PropTypes.number,
  data: PropTypes.object.isRequired,
  moviesData: PropTypes.object,
  onMoviesPageChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPayoutsByMoviesInitialize: PropTypes.func.isRequired,
  onPayoutsByLicensorInitialize: PropTypes.func.isRequired
};

export default PayoutsByLicensor;