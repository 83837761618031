import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Collapse, Icon} from "antd";
import PropTypes from 'prop-types';

import AdDetails from "../AdDetails/AdDetails";
import CollapsiblePanel from "../../../shared/CollapsiblePanel/CollapsiblePanel";

import {ConfigureMoviesAdsController} from "../ConfigureMoviesAdsController";

import {mapInvalidColors} from "../../../shared/error-colors-schema";

import {convertSecondsToTime} from "../../../../../../../../../../../../services/util-service/util.service";

import './AdsCollapsePanel.scss';

/* istanbul ignore file */
class AdsCollapsePanel extends Component {

  colors = {};

  state = {
    ads: [],
    activeKeys: [],
    validationTriggered: false
  };

  subscriptions = {};

  constructor(props) {
    super(props);
    this.subscriptions.expandSubscription = ConfigureMoviesAdsController.expandEvent.subscribe(this.setExpanded);
    this.subscriptions.validation = ConfigureMoviesAdsController.triggerValidationEvent.subscribe(this.triggerValidation);
  }

  static getAdKey = ({key}) => key;

  static createObjectFromArray(array) {
    const object = {};
    if (array) {
      let item = 0;
      let itemCount = array.length;
      let currentItem;
      while (item < itemCount) {
        currentItem = array[item];
        object[currentItem.key] = true;
        item++;
      }
    }

    return object;
  }

  static sameAdsObjects(ads, oldAds) {
    let sameObject = Object.keys(ads).length === Object.keys(oldAds).length;
    if (sameObject) {
      for (const key in ads) {
        if (!oldAds[key]) {
          sameObject = true;
          break;
        }
      }
    }

    return sameObject;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {ads: prevAds} = prevState;
    const {ads} = nextProps;
    const state = {ads};
    const adsObject = AdsCollapsePanel.createObjectFromArray(ads);
    const oldAdsObject = AdsCollapsePanel.createObjectFromArray(prevAds);
    if (!AdsCollapsePanel.sameAdsObjects(adsObject, oldAdsObject)) {
      state.activeKeys = ads.map(AdsCollapsePanel.getAdKey);
    }
    return state;
  }

  componentWillUnmount() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
  }

  editAd = (index) => {
    const {editAd, id} = this.props;
    editAd(id, index);
  };

  getAdPanelTitle = (ad, index) => {
    const {t} = this.props;
    return (
      <div className="Title">
        <div>
          {t('ConfigureAdsOnMovieLevel.AdsCollapsePanel.adTitle', {index})}
          {this.getAdTimeText(ad)}
        </div>
        <Icon type="close" onClick={this.removeAd.bind(this, index)}/>
      </div>
    );
  };

  getAdTimeText = ({start}) => {
    return ` [${convertSecondsToTime(start)}]`;
  };

  getPanelColor = (index) => {
    let color;
    const {invalidKeys} = this.props;
    if (invalidKeys) {
      color = this.getValidationColor(index);
    }

    return color;
  };

  getValidationColor = (index) => {
    let color;
    for (const key in this.colors) {
      if (this.colors[key].values.indexOf(index) !== -1) {
        color = this.colors[key].shade;
      }
    }

    return color;
  };

  onActivePanelChange = (activeKeys) => {
    this.setState({activeKeys});
  };

  removeAd = (index) => {
    const {removeAd, id} = this.props;
    removeAd(id, index);
  };

  setExpanded = (expand) => {
    const activeKeys = expand ? Array.from(Array(this.props.ads.length).keys()) : [];
    this.setState({activeKeys});
  };

  sceneAdMap = (ad, index) => {
    const props = {
      ad,
      index,
      editAd: this.editAd.bind(this, index)
    };
    const {validationTriggered} = this.state;
    const classes = ['SceneAd'];
    const style = {};
    if (validationTriggered) {
      const color = this.getPanelColor(index);
      if (color) {
        style.background = color;
      } else {
        classes.push('Valid');
      }
    }
    return (
      <Collapse.Panel key={ad.key}
                      header={this.getAdPanelTitle(ad, index)}
                      className={classes.join(' ')}
                      style={style}>
        <AdDetails {...props} key={index}/>
      </Collapse.Panel>
    );
  };

  triggerValidation = () => {
    this.setState({
      validationTriggered: new Date().getTime()
    });
  };

  render() {
    const {invalidKeys} = this.props;
    const {ads, activeKeys, validationTriggered} = this.state;
    this.colors = mapInvalidColors(invalidKeys);
    const classes = ['AdsCollapsePanel'];
    if (validationTriggered) {
      classes.push('ValidationTriggered');
    }
    return (
      <CollapsiblePanel className={classes.join(' ')}
                        defaultActiveKeys={activeKeys}
                        onChange={this.onActivePanelChange}
                        activeKeys={activeKeys}>
        {ads.map(this.sceneAdMap)}
      </CollapsiblePanel>
    );
  }
}

AdsCollapsePanel.propTypes = {
  ads: PropTypes.array,
  editAd: PropTypes.func.isRequired,
  invalidKeys: PropTypes.object,
  removeAd: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default withTranslation()(AdsCollapsePanel);
