import {withTranslation} from "react-i18next";

import MoviesMainViewAbstract from "../MoviesMainViewAbstract/MoviesMainViewAbstract";
import MoviesViewList from "./MoviesViewList/MoviesViewList";
import React from "react";
import PropTypes from "prop-types";

class MoviesMainView extends MoviesMainViewAbstract {

  renderMoviesList = () => {
    const {data, setMovieEnabled, onMovieEdit, onSpecialCategoriesEdit, onMovieEdit2257, scrollTop} = this.props;
    return (
      <MoviesViewList movies={data.movies}
                      setMovieEnabled={setMovieEnabled}
                      onMovieEdit={onMovieEdit}
                      onSpecialCategoriesEdit={onSpecialCategoriesEdit}
                      onMovieEdit2257={onMovieEdit2257}
                      scrollTop={scrollTop}/>
    )
  };
}

MoviesMainView.propTypes = {
  data: PropTypes.object.isRequired,
  fetchMovies: PropTypes.func.isRequired,
  filterByStatus: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  setMovieEnabled: PropTypes.func.isRequired,
  onMovieEdit: PropTypes.func.isRequired,
  onSpecialCategoriesEdit: PropTypes.func.isRequired,
  onMovieEdit2257: PropTypes.func.isRequired,
  scrollTop: PropTypes.number
};

export default withTranslation()(MoviesMainView);
