import React from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import MoviesViewAbstract from "../../MoviesView/MoviesViewAbstract/MoviesViewAbstract";

import { getPropertyMovies } from "../../../../../../../../../services/properties-service/properties.service";
import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";

import PropertyMoviesMainView from "./PropertyMoviesMainView/PropertyMoviesMainView";
import { getPropertyDetailsMoviesRoute } from "../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";
import { displayErrorNotification } from '../../../../../../../../../services/notification-service/notification.service';

class PropertyMovies extends MoviesViewAbstract {

  componentDidMount() {
    super.componentDidMount();
    this.setDocumentTitle();
  }

  fetchMovies = (page) => {
    const message = this.props.t('PropertyMovies.loadingMsgPleaseWaitMsg');
    this.setLoading(message);
    const filters = this.getFilters();
    const { propertyId } = this.props;
    getPropertyMovies(propertyId, page, filters)
      .then(this.loadMovies)
      .catch(err => {
        displayErrorNotification({
          duration: 5,
          message: this.props.t('global.somethingWentWrong')
        });
        this.setLoading('');
        console.log(err);
      });
  };

  getMoviesRoute = () => {
    const { propertyId } = this.props;
    return getPropertyDetailsMoviesRoute(propertyId);
  };

  renderMainViewRoute = () => {
    const props = this.getMainViewProps();
    props.onChange = this.fetchMovies;
    return (
      <PropertyMoviesMainView {...props} />
    );
  };

  setDocumentTitle = () => {
    const { propertyName, t } = this.props;
    const title = t('PropertyMovies.documentTitle', { name: propertyName });
    DocumentTitleController.setDocumentTitle(title);
  };
}

PropertyMovies.propTypes = {
  propertyId: PropTypes.string,
  propertyName: PropTypes.string
};

export default withTranslation()(PropertyMovies);
