import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Checkbox, Input} from "antd";

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import BaseCard from '../../../../../../../../../../components/BaseCard/BaseCard';

import {getCategoryByPropertyId} from '../../../../../../../../../../services/categories-service/categories.service';
import {displayErrorNotification} from '../../../../../../../../../../services/notification-service/notification.service';

import './AddCategoriesModal.scss';
import _ from 'lodash';

class AddCategoriesModal extends Component {
  filterDelay = 300;

  modalRef = React.createRef();

  state = {
    categories: [],
    filteredCategories: []
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const message = this.props.t('AddCategoriesModal.loadingMsg');
    this.getModal().setLoading(message);
    const {propertyId} = this.props;

    getCategoryByPropertyId(propertyId)
      .then(this.loadCategories)
      .catch(this.onLoadDataFailure);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className="AddCategoriesModal-actions">
        <Button onClick={this.onCancel.bind(this)}
                key="cancelBtn">
          {t('AddCategoriesModal.cancel')}
        </Button>
        <Button
          key="saveChangesBtn"
          onClick={this.saveChanges.bind(this)}>
          {t('AddCategoriesModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getNameAndId = (category) => {
    return {
      name: category.name,
      id: category.id
    };
  };

  getTitle = () => {
    const {t} = this.props;
    return t('AddCategoriesModal.title');
  };

  loadCategories = response => {
    const {data} = response.data;
    const tags = data.map((item)=> item.tag)
    const filteredCategories = tags.filter(this.matchCategoriesIds);
    const selectedCategories = _.difference(tags, filteredCategories);

    this.setState({
      categories: tags,
      filteredCategories,
      selectedCategories
    });
    this.getModal().clearLoading();
  };

  matchCategoryByName = (name, item) => {
    return item.name.toLowerCase().includes(name.toLowerCase());
  };

  matchCheckedCategories = (categoryId) => {
    const {selectedCategories} = this.state;
    return selectedCategories.filter(this.matchCategoryById.bind(this, categoryId)).length > 0;
  };

  matchCategoryById = (categoryId, category) => {
    return category.id === categoryId;
  };

  matchCategoriesIds = (category) => {
    const {currentOrder} = this.props;
    return !currentOrder.includes(category.id);
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChange = category => {
    const {id: categoryId} = category;
    let selectedCategories = this.state.selectedCategories;
    const index = selectedCategories.findIndex(this.matchCategoryById.bind(this, categoryId));
    if (index > -1) {
      selectedCategories.splice(index, 1);
    } else {
      selectedCategories.push(category);
    }

    this.setState({
      selectedCategories: selectedCategories
    });
  };

  onLoadDataFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddCategoriesModal.loadDataFailed')
    });
  };

  onNameFilterChange = (event) => {
    const name = event.target.value;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.setFilters.bind(this, name), this.filterDelay);
  };

  renderCategoryCard = (item, index) => {
    const {name, id} = item;
    return (
      <BaseCard key={`$categoryCard-${id}-${index}`}>
        <div className="Title">{name}</div>
        <Checkbox checked={this.matchCheckedCategories(id)}
                  onClick={this.onChange.bind(this, item)}/>
      </BaseCard>
    )
  };

  renderCategoriesCards = () => {
    const {filteredCategories} = this.state;
    let categoriesCards = null;
    if(filteredCategories.length) {
      categoriesCards = filteredCategories.map(this.renderCategoryCard);
    }

    return categoriesCards;
  };

  renderSearchInput = () => {
    const {t} = this.props;
    return (
      <Input
        className="SearchInput"
        placeholder={t('AddCategoriesModal.searchCategories')}
        onChange={this.onNameFilterChange}
      />
    );
  };

  saveChanges = () => {
    const {selectedCategories} = this.state;
    const updateData = selectedCategories.map(this.getNameAndId);
    this.props.updateCategoriesList(updateData);
    this.getModal().closeModal();
  };

  setFilters = name => {
    const {categories} = this.state;

    const filteredCategories = categories.filter(this.matchCategoryByName.bind(this, name));
    this.setState({
      filteredCategories
    });
  };

  render() {
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className="AddCategoriesModal">
          {this.renderSearchInput()}
          <div className="Categories-Container">
            {this.renderCategoriesCards()}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

export default withTranslation()(AddCategoriesModal);