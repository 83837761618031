import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "antd";
import { cloneDeep } from "lodash";

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import PropertiesCollapse from "./PropertiesCollapse/PropertiesCollapse";
import SelectPropertiesModal from "./SelectPropertiesModal/SelectPropertiesModal";

import { ConfigureAdsOnPropertyLevelController } from "./ConfigureAdsOnPropertyLevelController";
import { LogController } from "../../../../../../../../../../controllers/log-controller/log.controller";
import { ModalController } from "../../../../../../../../../../controllers/modal-controller/modal.controller";

import {
	getAdsConfig,
	setAdsConfig,
} from "../../../../../../../../../../services/ads-configuration-service/ads-configuration.service";
import { isDateInBetween } from "../../../../../../../../../../services/date-service/date.service";
import {
	displayErrorNotification,
	displaySuccessNotification,
} from "../../../../../../../../../../services/notification-service/notification.service";

import "./MainDialog.scss";
import ErrorsFoundDialog from "../ErrorsFoundDialog/ErrorsFoundDialog";

/* istanbul ignore file */
class MainDialog extends Component {
	modalRef = React.createRef();

	state = {
		invalidKeys: {},
		isLoading: false,
		properties: {},
	};

	componentDidMount() {
		this.displayPropertiesSelectionModal();
	}

	areAdsInConflict = (ad1, ad2) => {
		const {
			appliedEndDate: endDate2,
			appliedEndTime: endTime2,
			appliedStartDate: startDate2,
			appliedStartTime: startTime2,
		} = ad2;
		let areAdsInConflict = false;
		if (
			endDate2?.isValid() && endTime2?.isValid() &&
			this.isAdDateInRange(
				ad1,
				startDate2,
				startTime2,
				endDate2,
				endTime2
			) &&
			this.areSameAdsUserSettings(ad1, ad2) &&
			this.isSameAdMovieSection(ad1, ad2)
		) {
			areAdsInConflict = true;
		}

		return areAdsInConflict;
	};

	areSameAdsUserSettings = (ad1, ad2) => {
		const {
			applyToLoggedUser: loggedUser1,
			applyToNotLoggedUser: notLoggedUser1,
		} = ad1;
		const {
			applyToLoggedUser: loggedUser2,
			applyToNotLoggedUser: notLoggedUser2,
		} = ad2;
		return (
			(loggedUser1 && loggedUser2) || (notLoggedUser1 && notLoggedUser2)
		);
	};

	clearLoading = () => {
		this.getModal().clearLoading();
	};

	closeModal = () => {
		this.getModal().closeModal();
	};

	displayErrorsFoundDialog = () => {
		const modal = <ErrorsFoundDialog />;
		ModalController.showModal(modal);
	};

	displayPropertiesSelectionModal = () => {
		const { properties } = this.state;
		const modal = (
			<SelectPropertiesModal
				properties={properties}
				onPropertiesSelected={this.setSelectedProperties}
			/>
		);
		ModalController.showModal(modal);
	};

	getActions = () => {
		const { t } = this.props;
		const { properties } = this.state;
		const addPropertiesBtn = Object.keys(properties).length ? (
			this.getAddPropertiesButton(false)
		) : (
			<div />
		);
		return (
			<div className="Actions">
				{addPropertiesBtn}
				<div>
					<Button onClick={this.closeModal}>
						{t("ConfigureAdsOnPropertyLevel.MainDialog.cancel")}
					</Button>
					<Button onClick={this.saveChanges.bind(this)}>
						{t(
							"ConfigureAdsOnPropertyLevel.MainDialog.saveChanges"
						)}
					</Button>
				</div>
			</div>
		);
	};

	getAdBaseConfig = (ad) => {
		const {
			appliedEndDate,
			appliedEndTime,
			appliedStartDate,
			appliedStartTime,
			applyToLoggedUser,
			applyToNotLoggedUser,
			goToText,
			goToUrl,
			selectedApplicationTime,
			selectedSection,
			selectedTarget,
			type,
			url,
		} = ad;
		return {
			applyToLoggedUser,
			applyToNotLoggedUser,
			endTime: this.getUtcTime(appliedEndDate, appliedEndTime),
			startTime: this.getUtcTime(appliedStartDate, appliedStartTime),
			goToText,
			goToUrl,
			selectedApplicationTime,
			selectedSection,
			selectedTarget: selectedTarget || "_self",
			type,
			url,
		};
	};

	getAddPropertiesButton = (isLink) => {
		const { t } = this.props;
		const props = {
			onClick: this.displayPropertiesSelectionModal,
		};
		if (isLink) {
			props.type = "link";
		}
		return (
			<Button {...props}>
				{t("ConfigureAdsOnPropertyLevel.MainDialog.addProperties")}
			</Button>
		);
	};

	getNewPropertyIds = (selectedProperties) => {
		const { properties } = this.state;
		const ids = [];
		for (const propertyId in selectedProperties) {
			if (!properties[propertyId]) {
				ids.push(propertyId);
			}
		}

		return ids;
	};

	getPropertiesAdsConfigs = (ids) => {
		const promises = ids.map((id) => getAdsConfig({ propertyId: id }));
		return Promise.all(promises);
	};

	getPropertyValidationData = (propertyId, ads) => {
		let validationData = {};
		if (ads && ads.length > 1) {
			const adsValidation = {};
			let currentAd1;
			let currentAd2;
			const adsCount = ads.length;
			let item1 = 0;
			let item2;
			while (item1 < adsCount) {
				currentAd1 = ads[item1];
				item2 = item1 + 1;
				while (item2 < adsCount) {
					currentAd2 = ads[item2];
					if (this.areAdsInConflict(currentAd1, currentAd2)) {
						if (!adsValidation[item1]) {
							adsValidation[item1] = [];
						}
						adsValidation[item1].push(item2);
					}
					item2++;
				}
				item1++;
			}
			if (Object.keys(adsValidation).length) {
				validationData = {
					[propertyId]: adsValidation,
				};
			}
		}

		return validationData;
	};

	getInvalidPropertiesKeys = (properties) => {
		let ads;
		let validationObject = {};
		for (const propertyId in properties) {
			ads = properties[propertyId].ads;
			Object.assign(
				validationObject,
				this.getPropertyValidationData(propertyId, ads)
			);
		}
		return validationObject;
	};

	getModal = () => {
		return this.modalRef.current;
	};

	getTitle = () => {
		return this.props.t("ConfigureAdsOnPropertyLevel.MainDialog.title");
	};

	getUtcTime = (date, time) => {
		if (!date || !time) {
			return null;
		} 
		const day = date.date();
		const month = date.month();
		const year = date.year();
		const correctDate = moment(`${year}-${month + 1}-${day}`);
		correctDate.hour(time.hour());
		correctDate.minute(time.minute());
		return correctDate.utc();
	};

	isAdDateInRange = (
		ad,
		rangeStartDate,
		rangeStartTime,
		rangeEndDate,
		rangeEndTime
	) => {
		const {
			appliedEndDate: endDate,
			appliedEndTime: endTime,
			appliedStartDate: startDate,
			appliedStartTime: startTime,
		} = ad;
		return (
			endDate?.isValid() && endTime?.isValid() &&
			(
				isDateInBetween(
					startDate,
					startTime,
					rangeStartDate,
					rangeStartTime,
					rangeEndDate,
					rangeEndTime
				) ||
				isDateInBetween(
					endDate,
					endTime,
					rangeStartDate,
					rangeStartTime,
					rangeEndDate,
					rangeEndTime
				)
			)
		);
	};

	isSameAdMovieSection = (ad1, ad2) => {
		const {
			selectedApplicationTime: applicationTime1,
			selectedSection: section1,
		} = ad1;
		const {
			selectedApplicationTime: applicationTime2,
			selectedSection: section2,
		} = ad2;
		return section1 === section2 && applicationTime1 === applicationTime2;
	};

	mapAdsConfigForSave = () => {
		const data = [];
		const { properties } = this.state;
		let currentProperty;
		for (const propertyId in properties) {
			currentProperty = properties[propertyId];
			if (currentProperty.ads) {
				data.push(
					this.mapPropertyAdsConfigSavePromise(properties[propertyId])
				);
			}
		}

		return data;
	};

	mapAdsForSave = (ad) => {
		const config = this.getAdBaseConfig(ad);
		if (config.type === "video") {
			config.videoType = ad.videoType;
		} else {
			config.duration = ad.duration;
		}
		return config;
	};

	mapAdInitialConfig = (ad) => {
		const { startTime, endTime } = ad;
		const start = moment(startTime);
		const end = moment(endTime);
		Object.assign(ad, {
			appliedEndDate: end,
			appliedEndTime: end,
			appliedStartDate: start,
			appliedStartTime: start,
		});
		return ad;
	};

	mapPropertiesAds = (selectedProperties, values) => {
		const data = {};
		let item = 0;
		const itemCount = values.length;
		let property;
		let propertyId;
		if (!!values[item].data.data) {
			while (item < itemCount) {
				property = values[item].data.data;
				propertyId = property?.properties?.id;
				data[propertyId] = this.mapPropertyInitialConfig(
					property,
					selectedProperties
				);
				item++;
			}
		}

		return data;
	};

	mapPropertyAdsConfigSavePromise = ({ ads, id }) => {
		const data = ads.map(this.mapAdsForSave);
		return setAdsConfig({ propertyId: id, config: data });
	};

	mapPropertyInitialConfig = (property, selectedProperties) => {
		const propertyId = property?.properties?.id;
		let initialConfig = selectedProperties[propertyId];
		if (property?.ads_config && property?.ads_config?.length) {
			initialConfig.ads = property.ads_config.map(
				this.mapAdInitialConfig
			);
		}

		return initialConfig;
	};

	onGetPropertiesAdsConfigFailure = (error) => {
		this.clearLoading();
		this.setState({ isLoading: false });
		LogController.logError(error);
	};

	onSaveAdsConfigFailure = (error) => {
		LogController.logError(error);
		displayErrorNotification({
			duration: 3,
			message: this.props.t(
				"ConfigureAdsOnPropertyLevel.MainDialog.saveAdsFailed"
			),
		});
		this.clearLoading();
	};

	onSaveAdsConfigSuccess = () => {
		displaySuccessNotification({
			duration: 3,
			message: this.props.t(
				"ConfigureAdsOnPropertyLevel.MainDialog.saveAdsSuccess"
			),
		});
		// this.closeModal();
		this.clearLoading();
	};

	removeAd = (propertyId, adIndex) => {
		this.setState((prevState) => {
			const properties = cloneDeep(prevState.properties);
			properties[propertyId].ads.splice(adIndex, 1);
			return { properties };
		});
	};

	removeProperty = (propertyId) => {
		this.setState((prevState) => {
			const properties = cloneDeep(prevState.properties);
			delete properties[propertyId];
			return { properties };
		});
	};

	renderNoPropertiesSelected = () => {
		const { t } = this.props;
		return (
			<div className="NoProperties">
				{t(
					"ConfigureAdsOnPropertyLevel.MainDialog.noPropertiesSelected"
				)}
				{this.getAddPropertiesButton(true)}
			</div>
		);
	};

	renderPropertyCollapse = () => {
		const { invalidKeys, properties } = this.state;
		return (
			<PropertiesCollapse
				invalidKeys={invalidKeys}
				properties={properties}
				onRemoveProperty={this.removeProperty}
				onRemovePropertyAd={this.removeAd}
				onPropertyAdsChange={this.savePropertyAds}
			/>
		);
	};

	saveAdsConfig = () => {
		const promises = this.mapAdsConfigForSave();
		return Promise.all(promises);
	};

	saveChanges = () => {
		const { invalidKeys } = this.state;
		if (Object.keys(invalidKeys).length) {
			this.displayErrorsFoundDialog();
		} else {
			const message = this.props.t(
				"ConfigureAdsOnPropertyLevel.MainDialog.savingAdsConfig"
			);
			this.setLoading(message);
			this.saveAdsConfig()
				.then(this.onSaveAdsConfigSuccess)
				.catch(this.onSaveAdsConfigFailure);
		}
	};

	savePropertyAds = (propertyId, ads) => {
		this.setState((prevState) => {
			const properties = cloneDeep(prevState.properties);
			properties[propertyId].ads = ads;
			const invalidKeys = this.getInvalidPropertiesKeys(properties);
			return {
				invalidKeys,
				properties,
			};
		}, this.validateData);
	};

	setLoading = (message) => {
		this.getModal().setLoading(message);
	};

	setSelectedProperties = (properties) => {
		const newPropertyIds = this.getNewPropertyIds(properties);
		if (newPropertyIds.length) {
			const message = this.props.t(
				"ConfigureAdsOnPropertyLevel.MainDialog.loadingPropertiesAdsMsg"
			);
			this.setLoading(message);
			this.setState({ isLoading: true });
			this.getPropertiesAdsConfigs(newPropertyIds)
				.then(this.updateDialogState.bind(this, properties))
				.catch(this.onGetPropertiesAdsConfigFailure);
		} else {
			this.setState({ properties });
		}
	};

	updateDialogState = (selectedProperties, values) => {
		this.clearLoading();
		let properties = this.mapPropertiesAds(selectedProperties, values);
		properties = Object.assign(selectedProperties, properties);
		this.setState({
			isLoading: false,
			properties: properties,
		});
	};

	validateData = () => {
		ConfigureAdsOnPropertyLevelController.validateData();
	};

	renderMainView = () => {
		let view = null;
		const { isLoading, properties } = this.state;
		const hasProperties = !!Object.keys(properties).length;
		if (!isLoading) {
			view = hasProperties
				? this.renderPropertyCollapse()
				: this.renderNoPropertiesSelected();
		} else {
			if (hasProperties) {
				view = this.renderPropertyCollapse();
			}
		}

		return view;
	};

	render() {
		const view = this.renderMainView();
		return (
			<ModalDialog
				title={this.getTitle()}
				actions={this.getActions()}
				forwardedRef={this.modalRef}
				className="MainDialog"
			>
				<div className="MainDialog-inner">{view}</div>
			</ModalDialog>
		);
	}
}

export default withTranslation()(MainDialog);
