import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'antd';

import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';
import BaseDropdown from '../../../../../../../../../../components/BaseDropdown/BaseDropdown';

import {
    editCopyMovieCatalog,
    getMovieCatalogs
} from '../../../../../../../../../../services/movies-service/movies.service';
import { getPropertyCatalogs } from '../../../../../../../../../../services/properties-service/properties.service';

import './CopyCatalogModal.scss';

const CopyCatalogModal = ({ propertyId, propertyName }) => {
    const [state, setState] = useState({
        propertyCatalog: {},
        allCatalogs: [],
        selectedCatalog: {},
        loading: true,
        error: false
    });
    const {
        propertyCatalog = {},
        allCatalogs = [],
        selectedCatalog = {},
        loading,
        error
    } = state;

    const { t } = useTranslation();
    const modalRef = useRef(null);

    useEffect(() => {
        setModalLoadingMsg(t('EditCatalogModal.loadingMsg'));

        const sortByName = ({ propertiesName }, { propertiesName: name1 }) => {
            let order = 0;
            if (propertiesName > name1) {
                order = 1;
            } else if (propertiesName < name1) {
                order = -1;
            }

            return order;
        };

        getPropertyCatalogs(propertyId)
            .then((resp = {}) => {
                const { id, name } = resp.data?.data?.[0] || {};
                setState(prevState => ({ ...prevState, propertyCatalog: { id, name } }));
            })
            .catch(err => {
                setState(prevState => ({ ...prevState, error: true }));
                console.log(err);
            });

        getMovieCatalogs()
            .then((resp = {}) => {
                const data = resp.data?.data || [];
                const allCatalogs =
                    data
                        .filter(item => +item.properties?.id !== +propertyId) // remove clicked property
                        .map(item => ({
                            id: item.id,
                            name: item.name,
                            propertiesName: item.properties?.name
                        }))
                        .sort(sortByName);

                const firstCatalog = {
                    id: allCatalogs[0]?.id,
                    name: allCatalogs[0]?.name,
                    propertiesName: allCatalogs[0]?.propertiesName
                };
                setState(prevState => ({ ...prevState, allCatalogs, selectedCatalog: firstCatalog }));
            })
            .catch(err => {
                setState(prevState => ({ ...prevState, error: true }));
                console.log(err);
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, loading: false }));
                setModalLoadingMsg('');
            });

        return () => {
            setState({});
        }
    }, [t, propertyId]);

    const onDropdownItemClick = (catalog = {}) => {
        const { id, name, propertiesName } = catalog;
        setState(prevState => ({ ...prevState, selectedCatalog: { id, name, propertiesName } }));
    };

    const setModalLoadingMsg = (msg) => modalRef.current.setLoading(msg);

    const saveChanges = () => {
        if (!propertyCatalog.id || !selectedCatalog.id) return;

        setModalLoadingMsg(t('EditCatalogModal.savingMsg'));

        editCopyMovieCatalog({ source_id: selectedCatalog.id, target_id: propertyCatalog.id })
            .then(() => modalRef.current.closeModal())
            .catch(err => {
                setState(prevState => ({ ...prevState, error: true }));
                setModalLoadingMsg('');
                console.log(err);
            })
    };

    const renderModalActions = () => {
        return (
            <>
                <Button onClick={() => modalRef.current.closeModal()}>{t('EditCatalogModal.cancel')}</Button>
                <Button onClick={saveChanges}>{t('EditCatalogModal.saveChanges')}</Button>
            </>
        );
    };

    const renderDropdownItems = () => {
        if (allCatalogs.length === 0) return [];

        return allCatalogs.map(item => {
            return (
                <div title={`${item.id} - ${item.name}`} onClick={() => onDropdownItemClick(item)}>
                    {item.propertiesName}
                </div>
            )
        });
    };

    return (
        <ModalDialog
            title={`${t('PropertiesBody.copyCatalog')} - ${propertyName}`}
            actions={renderModalActions()}
            forwardedRef={modalRef}
        >
            <div className='CopyCatalogModal'>
                {!loading && error && <b>Something went wrong!</b>}

                {!loading && !error && (
                    <>
                        <span className='Label'>Copy from</span>
                        <BaseDropdown items={renderDropdownItems()} addScrollBar>
                            <Button className='Action-Button'>
                                {selectedCatalog.propertiesName}
                                <Icon type='down' />
                            </Button>
                        </BaseDropdown>
                        <span className='Label'>to </span>
                        <span className='Label' title={propertyId}>{propertyName}</span>
                    </>
                )}
            </div>
        </ModalDialog>
    )
};

export default CopyCatalogModal;