import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Card } from "antd";
import PropTypes from 'prop-types';

import BaseImagesFullScreen from "../BaseImagesFullScreen/BaseImagesFullScreen";
import ImageComponent from "../ImageComponent/ImageComponent";

import './ImagesGridDisplay.scss';
import ImageActions from '../ImageActions/ImageActions';

class ImagesGridDisplay extends Component {

  fullScreenRef = React.createRef();

  componentDidMount() {
    const { onInitialize } = this.props;
    if (onInitialize) {
      onInitialize(this);
    }
  }

  /**
   * Returns image cover element for render
   *
   * @param {Object} image
   * @param {number} index
   *
   * @returns {JSX.Element}
   */
  getImageCover = (image, index) => {
    const { getImageUrl, onDeleteImage, onEditImage, onSetHeroStill, bannerId, movieId } = this.props;
    const url = getImageUrl ? getImageUrl(image) : image.url;
    const { is_cover: isCover, origin } = image;
    let imageEl = (
      <ImageComponent className='ImagesGridDisplay-card-image'
        isCover={isCover}
        origin={origin}
        url={url}
        key={url}
      />
    );
    return (
      <ImageActions data={image}
        index={index}
        onFullScreen={this.toggleFullScreen.bind(this, index)}
        onDeleteImage={onDeleteImage}
        onEditImage={onEditImage}
        onSetHeroStill={onSetHeroStill}
        key={index}
        bannerId={bannerId}
        movieId={movieId}
      >
        {imageEl}
      </ImageActions>
    );
  };

  /**
   * Returns true if type from the function arguments matches to the props searchType parameter, false otherwise
   *
   * @param {Object}
   *
   * @returns {boolean}
   */
  matchImageType = ({ type }) => {
    return type.toLowerCase().indexOf(this.props.searchType.toLowerCase()) !== -1;
  };

  /**
   * Returns mapped image card for render
   *
   * @param {Object} image
   * @param {number} index
   *
   * @returns {JSX.Element}
   */
  movieImageMap = (image, index) => {
    const { getCardTitle } = this.props;
    const title = getCardTitle ? getCardTitle(image) : image.type;
    return (
      <Card className="ImagesGridDisplay-card"
        key={index}
        cover={this.getImageCover(image, index)}>
        <Card.Meta title={title} />
      </Card>
    );
  };

  /**
   * Triggers image full screen display
   *
   * @param {number} index Index of clicked image
   */
  toggleFullScreen = (index) => {
    this.fullScreenRef.current.toggleFullScreen(index);
  };

  render() {
    let view = null;
    let { getImageUrl, images, searchType } = this.props;
    if (images.length) {
      images = searchType ? images.filter(this.matchImageType) : images;
      view = (
        <BaseImagesFullScreen className="ImagesGridDisplay-inner"
          ref={this.fullScreenRef}
          images={images}
          getImageUrl={getImageUrl}>
          {images.map(this.movieImageMap)}
        </BaseImagesFullScreen>
      );
    }
    return (
      <div className="ImagesGridDisplay">
        {view}
      </div>
    );
  }
}

ImagesGridDisplay.propTypes = {
  getCardTitle: PropTypes.func,
  getImageUrl: PropTypes.func,
  images: PropTypes.array,
  onInitialize: PropTypes.func,
  searchType: PropTypes.string,
  bannerId: PropTypes.string,
  movieId: PropTypes.string,
  onDeleteImage: PropTypes.func.isRequired,
  onEditImage: PropTypes.func.isRequired
};

export default withTranslation()(ImagesGridDisplay);
