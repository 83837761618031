import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Button, Input, Select} from "antd";
import PropTypes from 'prop-types';

import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../../../services/notification-service/notification.service';
import {getMoviesPlaylistCategories, addNewPlaylist} from '../../../../../../../../../../../../services/movies-service/movies.service';

import ModalDialog from "../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import './AddPlaylistModal.scss';

class AddPlaylistModal extends Component {

  modalRef = React.createRef();

  state = {
    playlistName: '',
    movieCategories: [],
    category: {}
  };

  componentDidMount() {
    this.fetchMoviePlaylistCategories();
  };

  fetchMoviePlaylistCategories = () => {
    getMoviesPlaylistCategories()
      .then(this.loadCategories)
      .catch(this.getCategoriesFailure);
  };

  getActions = () => {
    const {t} = this.props;
    return (
      <div className='AddPlaylistModal-actions'>
        <Button onClick={this.onCancel.bind(this)}
                key="cancelBtn">
          {t('AddPlaylistModal.close')}
        </Button>
        <Button onClick={this.onSavePlaylist.bind(this)}
                type="primary"
                disabled={!this.state.playlistName || !this.state.category.name}
                key="savePlaylistBtn">
          {t('AddPlaylistModal.savePlaylist')}
        </Button>
      </div>
    );
  };

  getCategoriesFailure = () => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddPlaylistModal.getCategoriesFailed')
    });
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('AddPlaylistModal.addPlaylist');
  };

  loadCategories = response => {
    this.setState({
      movieCategories: response.data.data.movie_playlist_categories
    });
  };

  onAddPlaylistFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddPlaylistModal.addPlaylistFailed')
    });
  };

  onAddPlaylistSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddPlaylistModal.addPlaylistSuccess')
    });
    this.props.onPlaylistAdded();
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onMovieCategoriesChange = (item) => {
    this.setState({
      category: {
        id: item.id,
        name: item.name
      }
    });
  };

  onPlaylistNameChange = (event) => {
    const playlistName = event.target.value;
    this.setState({
      playlistName: playlistName
    });
  };

  onSavePlaylist = () => {    
    const message = this.props.t('AddPlaylistModal.addingPlaylistPleaseWait');
    this.getModal().setLoading(message);

    const categoryId = this.state.category.id;
    const name = this.state.playlistName;

    addNewPlaylist(categoryId, name)
      .then(this.onAddPlaylistSuccess)
      .catch(this.onAddPlaylistFailure);
  };

  renderMovieCategoriesOptions = (item) => {
    return (
      <Select.Option 
        key={`category-${item.id}`} 
        value={item.name}
        onClick={this.onMovieCategoriesChange.bind(this, item)}
      >
        <div className="CategoryDropdownItemWrapper">
          <span className="CategoryDropdownItemText">
            {item.name}
          </span>
        </div>
      </Select.Option>
    )
  };

  render() {
    const {t} = this.props;
    return (
      <React.Fragment>
        <ModalDialog title={this.getTitle()}
                     actions={this.getActions()}
                     forwardedRef={this.modalRef}>
          <div className="AddPlaylistModal">
            <div className='AddPlaylistModal-inner'>
              <span className='AddPlaylistModal-inner-label'>
                {t('AddPlaylistModal.name')}
              </span>
              <Input value={this.state.playlistName} onChange={this.onPlaylistNameChange}/>
            </div>

            <div className='AddPlaylistModal-inner'>
              <span className='AddPlaylistModal-inner-label'>
                {t('AddPlaylistModal.movieCategories')}
              </span>
              <Select showArrow
                className="CategoriesPlaylist-select"
                value={this.state.category.name}
              >
                {this.state.movieCategories.map(this.renderMovieCategoriesOptions)}
              </Select>
            </div>
          </div>
        </ModalDialog>
      </React.Fragment>
    );
  }
}

AddPlaylistModal.propTypes = {
  onPlaylistAdded: PropTypes.func.isRequired
};

export default withTranslation()(AddPlaylistModal);
