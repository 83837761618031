import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Icon } from "antd";
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../../components/BaseFooter/BaseFooter";

import AddRoyaltiesUserModal from '../AddRoyaltiesUserModal/AddRoyaltiesUserModal';
import { ModalController } from "../../../../../../../../../../controllers/modal-controller/modal.controller";


import './RoyaltiesUsersViewFooter.scss';

class RoyaltiesUsersViewFooter extends Component {

  getBaseFooterProps = () => {
    const { currentPage, onChange, pageCount, pageSize, recordCount } = this.props;
    return {
      currentPage,
      onChange,
      pageCount,
      pageSize,
      recordCount
    };
  };

  addUser = () => {

    const modal = (
      <AddRoyaltiesUserModal refreshContent={this.props.refreshContent} />
    );
    ModalController.showModal(modal);
  };

  renderAddUserBtn = () => {
    // const { t } = this.props;
    return (
      <Button onClick={this.addUser}>
        <Icon type="user-add" />
        Add User
      </Button>
    );
  };

  render() {
    const baseFooterProps = this.getBaseFooterProps();
    return (
      <BaseFooter className="UsersViewFooter"
        {...baseFooterProps}>
        {this.renderAddUserBtn()}
      </BaseFooter>
    );
  }
}

RoyaltiesUsersViewFooter.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  recordCount: PropTypes.number.isRequired
};

export default withTranslation()(RoyaltiesUsersViewFooter);

