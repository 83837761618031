import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { Button, Checkbox, Icon, Table, Input } from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import TabsComponent from "../../../../../../../../../../components/TabsComponent/TabsComponent";

import { getStudiosWithoutPagination } from "../../../../../../../../../../services/studio-service/studio.service";
import { getCategoriesWithoutPagination } from "../../../../../../../../../../services/categories-service/categories.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../../services/notification-service/notification.service";
import {
  getPropertyCatalogs,
  getAllOrientations,
  editCatalog,
  getProperties,
  addCatalogToProperty
} from '../../../../../../../../../../services/properties-service/properties.service';

import CatalogStudioSelect from './CatalogStudioSelect/CatalogStudioSelect';
import CatalogCategoriesSelect from './CatalogCategoriesSelect/CatalogCategoriesSelect';
import CatalogOrientationSelect from './CatalogOrientationSelect/CatalogOrientationSelect';

import './EditCatalogModal.scss';
import BaseDropdown from '../../../../../../../../../../components/BaseDropdown/BaseDropdown';

class EditCatalogModal extends Component {

  modalRef = React.createRef();
  initialPropertyId;
  initialCatalogName;
  noCatalogForProperty;
  catalogEnabledState = -1;
  state = {
    activeTab: '1',
    catalogsData: [],
    dataLoaded: false,
    copyFromCatalog: '',
    allProperties: [],
    name: '',
    movieCatalogs: []
  };

  componentDidMount() {
    this.fetchData();
  };

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  fetchData = (propertyId) => {
    const { t } = this.props;
    const message = t('EditCatalogModal.loadingMsg');
    this.setLoading(message);
    const promises = [
      this.fetchStudios(),
      this.fetchCategories(),
      this.fetchOrientations(),
      this.fetchCatalogs(propertyId),
      this.fetchAllProperties()
    ];
    Promise.all(promises)
      .then(this.onLoadData)
      .catch(this.onLoadError);
  };

  fetchCatalogs = (propertyId = this.props.propertyId) => {
    if (!this.initialPropertyId) {
      this.initialPropertyId = +propertyId;
    }
    return getPropertyCatalogs(propertyId);
  };

  fetchAllProperties = () => {
    return getProperties(-1);
  };

  fetchCategories = () => {
    return getCategoriesWithoutPagination();
  };

  fetchOrientations = () => {
    return getAllOrientations();
  };

  fetchStudios = () => {
    return getStudiosWithoutPagination();
  };

  getTag = ({ tag }) => tag;

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="EditCatalogModal-actions">
        <Button onClick={this.onCancel.bind(this)}
          key="cancelBtn">
          {t('EditCatalogModal.cancel')}
        </Button>
        <Button
          key="saveChangesBtn"
          onClick={this.saveChanges.bind(this)}>
          {t('EditCatalogModal.saveChanges')}
        </Button>
      </div>
    );
  };

  getId = ({ id }) => id;

  getModal = () => {
    return this.modalRef.current;
  };

  getIds = (el) => {
    return el.id;
  };

  getIndex = (catalogId, item) => {
    return item.id === catalogId;
  };

  getTabs = () => {
    const { catalogsData } = this.state;
    let tabs = [];
    if (catalogsData.length) {
      tabs = catalogsData.map(this.renderTab);
    }
    return tabs;
  };

  getTitle = () => {
    const { t, propertyName } = this.props;
    return t('EditCatalogModal.title', { propertyName });
  };

  initialCatalogDataMap = (catalog) => {
    const { name, studios, categories, orientations } = catalog;
    Object.assign(catalog, {
      categories: categories.map(this.getTag).map(this.getId),
      orientations: orientations.map(this.getId),
      studios: studios.map(this.getId)
    });

    if (!this.initialCatalogName) {
      this.initialCatalogName = name;
    }

    return catalog;
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onLoadData = (response) => {
    const { studio } = response[0].data.data;
    const { data: categories } = response[1].data;
    const { data: orientations } = response[2].data;
    const { data: catalogs } = response[3].data;
    const { data: allProperties } = response[4].data;
    const catalogsData = catalogs.map(this.initialCatalogDataMap);
    const copyFromCatalog = allProperties?.properties.find(item => item?.id?.toString() === this.noCatalogForProperty);
    const catalogPropertiesId = +catalogs[0]?.properties?.id;

    this.setState({
      dataLoaded: true,
      studios: studio.sort(this.sortByName),
      categories: categories.map(this.getTag).sort(this.sortByName),
      orientations: orientations.sort(this.sortByName),
      allProperties: allProperties?.properties,
      catalogsData: this.initialPropertyId === catalogPropertiesId ? catalogsData : [{
        ...this.state.catalogsData[0],
        studios: catalogsData[0]?.studios,
        categories: catalogsData[0]?.categories,
        orientations: catalogsData[0]?.orientations
      }],
      movieCatalogs: catalogs,
      name: this.initialCatalogName,
      copyFromCatalog: copyFromCatalog?.name ?? 'Select property'
    });
    this.getModal().clearLoading();
  };

  onLoadError = () => {
    this.setLoading('');
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('EditCatalogModal.fetchDataFailureMsg')
    });
  };

  onSaveChangesFailure = () => {
    const modal = this.getModal();
    modal.clearLoading();
    modal.closeModal();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditCatalogModal.onSaveChangesFailure')
    });
  };

  onSaveChangesSuccess = () => {
    const modal = this.getModal();
    modal.clearLoading();
    modal.closeModal();
    if (this.catalogEnabledState !== -1) {
      const { onChange } = this.props;
      if (onChange) {
        onChange();
      }
    }
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditCatalogModal.onSaveChangesSuccess')
    });
  };

  onSelect = (catalogId, name, items) => {
    this.setState(prevState => {
      const index = prevState.catalogsData.findIndex(this.getIndex.bind(this, catalogId));
      const catalogsData = cloneDeep(prevState.catalogsData);
      catalogsData[index][name] = items;
      return { catalogsData }
    });
  };

  renderCategories = (catalogId, attachedCategories) => {
    const { categories } = this.state;
    let view = null;
    if (categories.length) {
      view = (
        <CatalogCategoriesSelect
          data={categories}
          checked={attachedCategories}
          onItemSelect={this.onSelect.bind(this, catalogId, 'categories')}
          showSearch={true}
        />
      );
    }

    return view;
  };

  renderOrientations = (catalogId, attachedOrientations) => {
    const { orientations } = this.state;
    let view = null;
    if (orientations.length) {
      view = (
        <CatalogOrientationSelect data={orientations}
          checked={attachedOrientations}
          onItemSelect={this.onSelect.bind(this, catalogId, 'orientations')} />
      );
    }

    return view;
  };

  renderStudios = (catalogId, attachedStudios) => {
    const { studios } = this.state;
    let view = null;
    if (studios.length) {
      view = (
        <CatalogStudioSelect showSearch={true}
          data={studios}
          checked={attachedStudios}
          onItemSelect={this.onSelect.bind(this, catalogId, 'studios')} />
      );
    }

    return view;
  };

  enableCatalog = (e) => {
    const { checked } = e.target;
    this.catalogEnabledState = checked ? 1 : 0;
  }

  renderCheckbox = (text, record) => {
    let value = text;
    if (record.checkbox) {
      value = (
        <Checkbox onChange={this.enableCatalog} defaultChecked={record.value} />
      );
    }

    return value;
  }

  renderColumn = (text, record) => {
    let value = text;
    if (record.checkbox) {
      return this.renderCheckbox(text, record);
    }
    if (record.copy) {
      return this.renderResetData(text, record);
    }
    if (record.editName) {
      return this.renderInput();
    }
    return value;
  }

  renderInput = () => {
    const { name } = this.state;
    const setNewName = (e) => {
      const value = e.target.value;
      this.initialCatalogName = value;
      this.setState({ name: value });
    };
    return (
      <Input
        value={name}
        onChange={e => setNewName(e)}
        placeholder='Add catalog name'
      />
    )
  }

  renderDetailsTable = item => {
    const { t } = this.props;
    const columns = [{
      dataIndex: 'name'
    }, {
      dataIndex: 'value',
      render: this.renderColumn
    }];
    const dataSource = [{
      name: t('EditCatalogModal.name'),
      value: item.name,
      editName: true
    }, {
      name: t('EditCatalogModal.enabled'),
      value: !!item.enabled,
      checkbox: true
    },
      // {
      //   name: t('EditCatalogModal.copyConfigurationFrom'),
      //   value: item,
      //   copy: true
      // }
    ];
    return (
      <Table columns={columns}
        dataSource={dataSource}
        bordered
        size='small'
        rowKey="name"
        pagination={false}
        showHeader={false} />
    );
  };

  getConfigForId = (event) => {
    const { propertyid } = event.target.dataset;
    this.noCatalogForProperty = propertyid;
    this.setState({
      studios: [],
      categories: [],
      orientations: [],
      dataLoaded: false
    });
    this.fetchData(propertyid);
  }
  mapProperties = (item) => {
    return (
      <div
        data-propertyid={item.id}
        onClick={this.getConfigForId}>
        {item.name}
      </div>
    );
  }
  getDropDownItems = () => {
    const { allProperties } = this.state;
    return allProperties.map(this.mapProperties);
  }

  renderResetData = (text, record) => {
    return (
      <div className='ResetDataWrapper'>
        <div>{record ? null : this.props.t('EditCatalogModal.copyConfigurationFrom')}</div>
        <BaseDropdown items={this.getDropDownItems()} addScrollBar>
          <Button className="Action-Button">
            {this.state.copyFromCatalog}
            <Icon type="down" />
          </Button>
        </BaseDropdown>
      </div>
    );
  };

  renderTab = item => {
    const { t } = this.props;
    const { id: catalogId, studios, categories, orientations, properties } = item;
    const { dataLoaded } = this.state;
    return ({
      title: t('EditCatalogModal.catalogTitle', { id: properties?.id }),
      view: (
        <div className='EditCatalogModal-tab'>
          {this.renderDetailsTable(item)}
          {dataLoaded ? (
            <React.Fragment>
              {this.renderStudios(catalogId, studios)}
              {this.renderCategories(catalogId, categories)}
              {this.renderOrientations(catalogId, orientations)}
            </React.Fragment>
          ) : null}
        </div>
      )
    });
  };

  saveChanges = () => {
    const { catalogsData, name, movieCatalogs } = this.state;
    const { propertyId, t } = this.props;
    this.setLoading(t('EditCatalogModal.savingMsg'));

    if (!name && movieCatalogs.length === 0) {
      displayErrorNotification({
        duration: 3,
        message: 'You must add catalog name!'
      });
      this.getModal().clearLoading();
      return;
    }

    const promises = catalogsData.map(item => {
      const { id, name: oldName, categories, orientations, studios } = item;
      let promise = Promise.resolve();

      const postData = {
        name: name ? name : oldName,
        studios_id: studios,
        categories_id: categories,
        orientations_id: orientations
      };

      if (this.catalogEnabledState !== -1) {
        postData.enabled = this.catalogEnabledState;
      }

      if (name && id) {
        promise = editCatalog(id, postData);
      } else {
        promise = addCatalogToProperty(propertyId, postData);
      }

      return promise;
    });

    Promise.all(promises)
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  setLoading = loadingMsg => {
    this.getModal().setLoading(loadingMsg);
  };

  sortByName = ({ name }, { name: name1 }) => {
    let order = 0;
    if (name > name1) {
      order = 1;
    } else if (name < name1) {
      order = -1;
    }

    return order;
  };

  render() {
    const { catalogsData } = this.state;
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className="EditCatalogModal">
          {catalogsData.length ? (<TabsComponent
            activeKey={this.state.activeTab}
            onChange={this.changeTab}
          >
            {this.getTabs()}
          </TabsComponent>) : null}
        </div>
      </ModalDialog>
    );
  }
}

EditCatalogModal.propTypes = {
  propertyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  propertyName: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default withTranslation()(EditCatalogModal);
