import {Subject} from 'rxjs';

class ModalCtrl {
  static instance;
  data = [];
  modalAdded = new Subject();

  static getInstance() {
    if (ModalCtrl.instance == null) {
      ModalCtrl.instance = new ModalCtrl();
    }

    return ModalCtrl.instance;
  }

  cleanupKeysListener = () => {
    const instance = ModalCtrl.getInstance();
    document.removeEventListener("keydown", instance.handleKeyEvent, false);
  };

  handleKeyEvent = (event) => {
    if (event.keyCode === 27 && this.data.length) { // Close modal on ESC
      const instance = ModalCtrl.getInstance();
      instance.removeModal();
    }
  };

  removeModal() {
    const lightBox = document.querySelector('[aria-label="Lightbox"]');
    if (!lightBox) {
      const instance = ModalCtrl.getInstance();
      this.data.pop();
      instance.modalAdded.next(instance.data);
    }
  }

  setupKeysListener = () => {
    const instance = ModalCtrl.getInstance();
    document.addEventListener("keydown", instance.handleKeyEvent, false);
  };

  showModal(data) {
    const instance = ModalCtrl.getInstance();
    instance.data.push(data);
    instance.modalAdded.next(instance.data);
  }
}

export const ModalController = ModalCtrl.getInstance();
