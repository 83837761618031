import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PayoutsByMovieMainView from "./PayoutsByMovieMainView/PayoutsByMovieMainView";

import './PayoutsByMovie.scss';

class PayoutsByMovie extends Component {

  licensorId;

  propertyId;

  constructor(props) {
    super(props);
    this.licensorId = props.licensorId;
    this.propertyId = props.propertyId;
  };

  componentDidMount() {
    this.props.onPayoutsByMoviesInitialize(this.propertyId, this.licensorId);
  };

  render() {
    const {contentHeight, data, onPageChange} = this.props;
    return (
      <div className="PayoutsByMovie">
        <PayoutsByMovieMainView contentHeight={contentHeight}
                                data={data}
                                onPageChange={onPageChange}/>
      </div>
    );
  };
}

PayoutsByMovie.propTypes = {
  data: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPayoutsByMoviesInitialize: PropTypes.func.isRequired
};

export default PayoutsByMovie;