import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Select } from "antd";

import BaseImagesDisplaySearch from "./BaseImagesDisplaySearch/BaseImagesDisplaySearch";
import BaseImagesGridDisplay from "./BaseImagesGridDisplay/BaseImagesGridDisplay";
import BaseImagesMosaicDisplay from "./BaseImagesMosaicDisplay/BaseImagesMosaicDisplay";
import ImagesGridDisplay from "../ImagesGridDisplay/ImagesGridDisplay";

import './BaseImagesDisplay.scss';
import { displayErrorNotification, displaySuccessNotification } from '../../services/notification-service/notification.service';
import { isFunction } from 'lodash';

class BaseImagesDisplay extends Component {

  activeElement;

  displayTypes = Object.freeze({
    TABLE: 'Table',
    MOSAIC: 'Mosaic',
    ROW: 'Row'
  });

  state = {
    displayType: this.props.defaultType ? this.props.defaultType : this.displayTypes.MOSAIC,
    searchType: ''
  };

  componentDidMount() {
    const { onInitialize } = this.props;
    if (onInitialize) {
      onInitialize(this);
    }
  }

  onActiveElementInitialize = (element) => {
    this.activeElement = element;
  };

  onDisplayTypeChange = (displayType) => {
    this.setState({ displayType });
  };

  onSearchTypeChange = (searchType) => {
    this.setState({ searchType });
  };

  renderDisplayTable = () => {
    const { images, bannerId, movieId, onSetHeroStill } = this.props;
    const { searchType } = this.state;
    return (
      <BaseImagesGridDisplay images={images}
        bannerId={bannerId}
        movieId={movieId}
        onDeleteImage={this.onDeleteImage}
        onSetHeroStill={onSetHeroStill}
        onEditImage={this.onEditImage}
        onInitialize={this.onActiveElementInitialize}
        searchType={searchType} />
    );
  };

  onDeleteSuccess = () => {
    const { t, onDeleteSuccess } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t('DeleteImagesModal.imageDeletionSuccessMsg')
    });
    onDeleteSuccess();
  };

  onDeleteFailure = () => {
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('DeleteImagesModal.imageDeletionFailureMsg')
    });
  };

  onDeleteImage = (image) => {
    const { deleteImage, onDeleteImage } = this.props;
    if (isFunction(onDeleteImage)) {
      onDeleteImage(image);
    } else if (isFunction(deleteImage)) {
      deleteImage(image)
        .then(this.onDeleteSuccess)
        .catch(this.onDeleteFailure)
    }
  };

  onEditImage = () => {
    const { onEditImage } = this.props;
    if (onEditImage) {
      onEditImage();
    }
  };

  renderDisplayMosaic = () => {
    const { searchType } = this.state;
    const { images, bannerId, movieId, onSetHeroStill } = this.props;
    return (
      <BaseImagesMosaicDisplay images={images}
        bannerId={bannerId}
        movieId={movieId}
        onDeleteImage={this.onDeleteImage}
        onEditImage={this.onEditImage}
        onSetHeroStill={onSetHeroStill}
        searchType={searchType}
        onInitialize={this.onActiveElementInitialize}
      />
    );
  };

  renderDisplayRows = () => {
    const { searchType } = this.state;
    const { images, bannerId, movieId, onSetHeroStill } = this.props;
    return (
      <ImagesGridDisplay images={images}
        bannerId={bannerId}
        movieId={movieId}
        onDeleteImage={this.onDeleteImage}
        onSetHeroStill={onSetHeroStill}
        onEditImage={this.onEditImage}
        searchType={searchType}
        onInitialize={this.onActiveElementInitialize} />
    );
  };

  renderDisplayTypeSelect = () => {
    const { t } = this.props;
    const { TABLE, MOSAIC, ROW } = this.displayTypes;
    const { displayType } = this.state;
    return (
      <div className="OptionsContainer">
        <BaseImagesDisplaySearch initialValue="" onChange={this.onSearchTypeChange} />
        <div className="DisplayType">
          <div className="Label">{t('BaseImagesDisplay.displayType')}</div>
          <Select onChange={this.onDisplayTypeChange}
            value={displayType}
            defaultValue={displayType}
            dropdownMatchSelectWidth={false}>
            <Select.Option value={TABLE}>{t('BaseImagesDisplay.table')}</Select.Option>
            <Select.Option value={MOSAIC}>{t('BaseImagesDisplay.mosaic')}</Select.Option>
            <Select.Option value={ROW}>{t('BaseImagesDisplay.row')}</Select.Option>
          </Select>
        </div>
      </div>
    );
  };

  renderMainView = () => {
    let view;
    const { displayType } = this.state;
    switch (displayType) {
      case this.displayTypes.MOSAIC:
        view = this.renderDisplayMosaic();
        break;
      case this.displayTypes.TABLE:
        view = this.renderDisplayTable();
        break;
      default:
        view = this.renderDisplayRows();
        break;
    }

    return view;
  };

  toggleFullScreen = (index) => {
    this.activeElement.toggleFullScreen(index);
  };

  render() {
    return (
      <div className="BaseImagesDisplay">
        {this.renderDisplayTypeSelect()}
        {this.renderMainView()}
      </div>
    );
  }
}

BaseImagesDisplay.propTypes = {
  images: PropTypes.array.isRequired,
  onInitialize: PropTypes.func,
  bannerId: PropTypes.string,
  movieId: PropTypes.string,
  deleteImage: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  onDeleteImage: PropTypes.func,
  onEditImage: PropTypes.func.isRequired,
  onSetHeroStill: PropTypes.func
};

export default withTranslation()(BaseImagesDisplay);