import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import {
  editLicensor,
  getLicensorById
} from '../../../../../../../../../services/licensors-service/licensors.service';
import ModalDialog from '../../../../../../../../../components/modal/ModalDialog/ModalDialog';
import {
  displaySuccessNotification,
  displayErrorNotification
} from '../../../../../../../../../services/notification-service/notification.service';

import './EditLicensorModal.scss';

const { TextArea } = Input;

class EditLicensorModal extends Component {
  modalRef = React.createRef();

  startingData = {};

  state = {};

  componentDidMount() {
    getLicensorById(this.props.licensorId).then(this.loadData);
  }

  getActions = () => {
    const { t } = this.props;
    return [
      <Button key='cancelBtn' onClick={this.onCancel.bind(this)}>
        {t('EditLicensorModal.cancel')}
      </Button>,
      <Button
        key='saveChangesBtn'
        disabled={!this.hasDataChanged()}
        onClick={this.onSaveChanges.bind(this)}
      >
        {t('EditLicensorModal.saveChanges')}
      </Button>
    ];
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getModalTitle = () => {
    return this.props.t('EditLicensorModal.editLicensor', {
      licensorName: this.props.licensorName
    });
  };

  hasDataChanged = () => {
    return JSON.stringify(this.state) !== JSON.stringify(this.startingData);
  };

  loadData = response => {
    const { name } = response.data.data;
    const state = {
      name
    };
    Object.assign(this.startingData, state);
    this.setState(state);
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChange = (key, event) => {
    const value = event.target.value;
    this.setState({
      [key]: value
    });
  };

  onSaveChanges = () => {
    const message = this.props.t('EditLicensorModal.savingMsg');
    this.getModal().setLoading(message);
    const { name } = this.state;
    const updateData = {
      name
    };
    const postData = {
      name
    };
    editLicensor(this.props.licensorId, postData)
      .then(this.onLicensorEditSuccess.bind(this, updateData))
      .catch(this.onLicensorEditFailure);
  };

  onLicensorEditFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditLicensorModal.editLicensorFailure')
    });
  };

  onLicensorEditSuccess = data => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditLicensorModal.editLicensorSuccess')
    });
    this.props.onLicensorEdit(data);
  };

  render() {
    const { t } = this.props;
    return (
      <ModalDialog
        title={this.getModalTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='EditLicensorModal'>
          <div className='EditLicensorModal-inner'>
            <span className='EditLicensorModal-inner-label'>
              {t('EditLicensorModal.name')}:
            </span>
            <TextArea
              onChange={this.onChange.bind(this, 'name')}
              value={this.state.name}
            />
          </div>
        </div>
      </ModalDialog>
    );
  }
}

EditLicensorModal.propTypes = {
  licensorId: PropTypes.number.isRequired,
  licensorName: PropTypes.string.isRequired,
  onLicensorEdit: PropTypes.func.isRequired
};

export default withTranslation()(EditLicensorModal);
