import React from 'react';
import PropTypes from 'prop-types';

import StudioDetailsBodyDescription from "./components/StudioDetailsBodyDescription/StudioDetailsBodyDescription";
// import StudioDetailsBodyImages from "./components/StudioDetailsBodyImages/StudioDetailsBodyImages";
import StudioDetailsBodyLogo from "./components/StudioDetailsBodyLogo/StudioDetailsBodyLogo";
import StudioDetailsBodyTable from "./components/StudioDetailsBodyTable/StudioDetailsBodyTable";
import TabsComponent from '../../../../../../../../../../../components/TabsComponent/TabsComponent';

import './StudioDetailsBody.scss';

const StudioDetailsBody = (props) => {
  const {t} = props;
  // let imagesTab = {};

  // if(props.images && props.images.length !== 0) {
  //   imagesTab = {
  //     title: t('StudioDetailsBody.studioImages'),
  //     view: <StudioDetailsBodyImages images={props.images}/>
  //   }
  // }
  
  return (
    <div className="StudioDetailsBody">
      <TabsComponent 
        activeKey={props.activeKey}
        onChange={props.changeTab}
      >
      {[
          {
            title: t('StudioDetailsBody.studioInfo'),
            view: (
              <div>
                <StudioDetailsBodyDescription description={props.descriptionNs || props.description}/>
                <div className="StudioDetailsBody-inner">
                  <StudioDetailsBodyLogo url={props.images[0] && props.images[0].url}/>
                  <StudioDetailsBodyTable 
                    {...props}
                    setStudioStatus={props.setStudioStatus}
                  />
                </div>
              </div>
            )
          },
          // imagesTab
        ]}
      </TabsComponent>
    </div>
  );
};

StudioDetailsBody.propTypes = {
  catalogs: PropTypes.array,
  description: PropTypes.string,
  foundedOn: PropTypes.string,
  id: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  importedOn: PropTypes.string,
  lastSyncedOn: PropTypes.string,
  moviesCount: PropTypes.number.isRequired,
  onStudioEdit: PropTypes.func.isRequired,
  status: PropTypes.number
};

export default StudioDetailsBody;


