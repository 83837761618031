import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import ContentInfoView from "../ContentInfoView/ContentInfoView";

import {getMovieContentInfo} from "../../../../../../../../../../services/movies-service/movies.service";

class MovieContentInfo extends ContentInfoView {

  fetchData = () => {
    const message = this.props.t('MovieContentInfo.fetchingDataMsg');
    this.setLoading(message);
    this.getMovieContentInfo()
      .then(this.loadData)
      .catch(this.onRequestFailure);
  };

  getMovieContentInfo = () => {
    const {movieId} = this.props;
    return getMovieContentInfo(movieId);
  };

  loadData = (response) => {
    const {data} = response.data;
    this.setState({
      data,
      dataLoaded: true,
      loadingMsg: ''
    });
  };
}

MovieContentInfo.propTypes = {
  movieId: PropTypes.string.isRequired
};

export default withTranslation()(MovieContentInfo);
