import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Checkbox } from 'antd';
import PropTypes from 'prop-types';

import {
  renderListItems,
  renderListClickableItems
} from "../../../../../../../../../../../services/util-service/util.service";

import ImageComponent from "../../../../../../../../../../../components/ImageComponent/ImageComponent";

import { getStarDetailsRoute } from "../../../../../../../../../../../services/navigation/stars-navigation/stars-navigation-routes.service";
import { getSexActMoviesRoute } from "../../../../../../../../../../../services/navigation/sexact-navigation/sexact-navigation-routes.service"
import { showSexActMovies } from "../../../../../../../../../../../services/navigation/sexact-navigation/sexact-navigation.service";
import { showStarDetails } from "../../../../../../../../../../../services/navigation/stars-navigation/stars-navigation.service";
import { getCoverImageUrl } from "../../../../../../../../../../../services/images-service/images.service";
import { showPropertyDetails } from "../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation.service";
import { getPropertyDetailsRoute } from "../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";

import "./SceneDetailsInfoTab.scss";

class SceneDetailsInfoTab extends Component {
  renderValueColumn = (item, data) => {
    let view;
    switch (data.key) {
      case 'sexActs':
        view = renderListClickableItems(this.mapValueForRender(item, 'secActs'), showSexActMovies, data.key, getSexActMoviesRoute);
        break;
      case 'positions':
        view = renderListItems(this.mapValueForRender(item, 'positions'), data.key);
        break;
      case 'cast':
        view = renderListClickableItems(item, showStarDetails, data.key, getStarDetailsRoute);
        break;
      case 'length':
        view = this.renderLength(item);
        break;
      case 'downloadableOnProperties':
        view = renderListClickableItems(item, showPropertyDetails, data.key, getPropertyDetailsRoute, 'name');
        break;
      default:
        view = item;
        break;
    }
    return view;
  };

  columns = [{
    dataIndex: 'name',
    key: 'name',
    width: 250
  }, {
    dataIndex: 'value',
    key: 'value',
    render: this.renderValueColumn,
    width: '100%'
  }];

  convertDurationToTime = (duration) => {
    const { hours, minutes, seconds } = this.getTime(duration);
    let render = this.formatTimeValue(seconds);
    render = `${this.formatTimeValue(minutes)}:${render}`;
    render = `${this.formatTimeValue(hours)}:${render}`;

    return render;
  };

  formatTimeValue = (value) => {
    let format = '00';
    if (value) {
      format = value < 10 ? `0${value}` : value;
    }

    return format;
  };

  getLengthTimeData = () => {
    const startTime = +this.props.data[0].startTimeSeconds;
    const endTime = +this.props.data[0].endTimeSeconds;
    const duration = endTime - startTime;
    return this.getTime(duration);
  };

  getTableData = () => {
    const { t, scenesAreEpisodes, downloadableOnProperties } = this.props;
    const { id: sceneId, sexAct, position, stars } = this.props.data[0];
    const name = scenesAreEpisodes ? 'episode' : 'scene';

    return [{
      name: t(`SceneDetailsInfoTab.${name}Id`),
      value: sceneId,
      key: 'sceneId'
    }, {
      name: t('SceneDetailsInfoTab.length'),
      value: undefined,
      key: 'length'
    }, {
      name: t('SceneDetailsInfoTab.sexActs'),
      value: sexAct,
      key: 'sexActs'
    }, {
      name: t('SceneDetailsInfoTab.positions'),
      value: position,
      key: 'positions'
    }, {
      name: t('SceneDetailsInfoTab.cast'),
      value: stars,
      key: 'cast'
    }, {
      name: t('global.downloadable_on_properties'),
      value: downloadableOnProperties,
      key: 'downloadableOnProperties'
    }];
  };

  renderCheckbox = (value) => <Checkbox checked={!!value} disabled={true} />;

  getTime = (duration) => {
    const hours = Math.floor(duration / (60 * 60));
    const minutes = Math.floor((duration - (hours * 60 * 60)) / 60);
    const seconds = duration - (hours * 60 * 60) - minutes * 60;
    return {
      hours,
      minutes,
      seconds
    };
  };

  mapValueForRender = (positions, key) => {
    return positions.map(this.valueRenderMap.bind(this, key));
  };

  renderLength = () => {
    const { t } = this.props;
    const { hours, minutes, seconds } = this.getLengthTimeData();
    let render = `${t('SceneDetailsInfoTab.seconds', { seconds })}`;
    if (minutes) {
      render = `${t('SceneDetailsInfoTab.minutes', { minutes })} ${render}`;
    }
    if (hours) {
      render = `${t('SceneDetailsInfoTab.hours', { hours })} ${render}`;
    }
    const { endTimeSeconds, startTimeSeconds } = this.props.data[0];
    render = `${render} (${this.convertDurationToTime(startTimeSeconds)} - ${this.convertDurationToTime(endTimeSeconds)})`;
    return render;
  };

  renderSceneImage = () => {
    const { images } = this.props.data[0];
    const url = getCoverImageUrl(images);
    return (
      <ImageComponent
        className='SceneDetailsInfoTab-Images'
        url={url}
      />
    )
  };

  valueRenderMap = (key, { tag }) => {
    const { t } = this.props;
    const localeKey = `global.${key}.${tag.name}`;
    let name = t(localeKey);
    if (name === localeKey) {
      name = tag.name;
    }
    return {
      id: tag.id,
      name: name
    };
  };

  render() {
    return (
      <div className="SceneDetailsInfoTab">
        {this.renderSceneImage()}
        <Table
          dataSource={this.getTableData()}
          columns={this.columns}
          showHeader={false}
          pagination={false}
          bordered
          size='middle'
        />
      </div>
    );
  }
}

SceneDetailsInfoTab.propTypes = {
  data: PropTypes.array.isRequired
};

export default withTranslation()(SceneDetailsInfoTab);
