import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseImagesFullScreen from "../../BaseImagesFullScreen/BaseImagesFullScreen";
import BaseImagesDisplayTable from "../BaseImagesDisplayTable/BaseImagesDisplayTable";
import BaseImagesDisplayList from "../BaseImagesDisplayList/BaseImagesDisplayList";

import './BaseImagesGridDisplay.scss';

class BaseImagesGridDisplay extends Component {

  fullScreenRef = React.createRef();

  componentDidMount() {
    const { onInitialize } = this.props;
    if (onInitialize) {
      onInitialize(this);
    }
  }

  toggleFullScreen = (index) => {
    this.fullScreenRef.current.toggleFullScreen(index);
  };

  render() {
    const { images, searchType, bannerId, movieId, onDeleteImage, onEditImage, onSetHeroStill } = this.props;
    return (
      <div className="BaseImagesGridDisplay">
        <BaseImagesFullScreen images={images} ref={this.fullScreenRef}>
          <BaseImagesDisplayTable images={images}
            bannerId={bannerId}
            movieId={movieId}
            onDeleteImage={onDeleteImage}
            onSetHeroStill={onSetHeroStill}
            onEditImage={onEditImage}
            searchType={searchType}
            toggleFullScreen={this.toggleFullScreen} />
          <BaseImagesDisplayList images={images}
            bannerId={bannerId}
            movieId={movieId}
            onDeleteImage={onDeleteImage}
            onSetHeroStill={onSetHeroStill}
            onEditImage={onEditImage}
            searchType={searchType}
            toggleFullScreen={this.toggleFullScreen} />
        </BaseImagesFullScreen>
      </div>
    );
  }
}

BaseImagesGridDisplay.propTypes = {
  images: PropTypes.array.isRequired,
  onInitialize: PropTypes.func,
  searchType: PropTypes.string,
  bannerId: PropTypes.string,
  movieId: PropTypes.string,
  onDeleteImage: PropTypes.func.isRequired,
  onEditImage: PropTypes.func.isRequired,
  onSetHeroStill: PropTypes.func
};

export default BaseImagesGridDisplay;
