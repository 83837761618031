import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Input, Select } from "antd";
import PropTypes from 'prop-types';

import BaseSort from "../../../../../../../../../../../components/BaseSort/BaseSort";

import './MovieFilters.scss';
import { MainDashboardComController } from '../../../../../../../../../main-dashboard-comm.controller';

/* istanbul ignore file */
class MovieFilters extends Component {
  state = {
    sortFilter: '',
    filters: {
      search: '',
      sort: {
        ascending: false,
        name: ''
      },
      display: ''
    }
  };

  movieNameFilterDebounce = 800;

  timeoutId;

  subscription;

  constructor(props) {
    super(props);
    Object.assign(this.state, {
      sortFilter: props.defaultSortFilter,
      filters: {
        search: props.defaultNameFilter,
        sort: {
          ascending: false,
          name: props.defaultSortFilter,
          text: props.localizedDefaultSortFilter
        },
        display: props.defaultDisplayFilter
      }
    });
  }

  componentDidMount() {
    this.subscription = MainDashboardComController.mainNavigationLinkClicked.subscribe(this.onClearFilter.bind(this));
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  callPropsClearFilter = () => {
    this.props.onClearFilter(this.state.filters);
  };

  callPropsFilterChange = () => {
    this.props.onFilterChange(this.state.filters);
  };

  getAdditionalActions = () => {
    let actions = null;
    const { additionalActions } = this.props;

    if (additionalActions) {
      actions = additionalActions;
    }

    return actions;
  };

  onClearFilter = () => {
    const { defaultSortFilter, defaultDisplayFilter, localizedDefaultSortFilter } = this.props;
    this.setState({
      sortFilter: defaultSortFilter,
      filters: {
        search: '',
        sort: {
          ascending: false,
          name: defaultSortFilter,
          text: localizedDefaultSortFilter
        },
        display: defaultDisplayFilter
      }
    }, this.callPropsClearFilter);
  };

  onDisplayChange = (status) => {
    this.setState(prevState => {
      return {
        filters: {
          ...prevState.filters,
          display: status
        }
      }
    }, this.callPropsFilterChange);
  };

  onMovieNameChange = (event) => {
    const search = event.target.value;
    this.setState(prevState => {
      return {
        filters: {
          ...prevState.filters,
          search: search
        }
      };
    });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.callPropsFilterChange, this.movieNameFilterDebounce);
  };

  onSortChange = (sortFilter, ascending, text) => {
    this.setState(prevState => {
      return {
        filters: {
          ...prevState.filters,
          sort: {
            ascending: ascending,
            name: sortFilter,
            text
          },
        }
      }
    }, this.callPropsFilterChange);
  };

  renderDisplayFilterItem = (item) => {
    const { t } = this.props;
    return <Select.Option key={item} value={item}>
      <div className="MovieFilterDropdownItemWrapper">{t(`MovieFilters.${item}`)}</div>
    </Select.Option>
  };

  render() {
    const { t } = this.props;
    return (
      <div className="MovieFilters">
        <div className="FilterWrapper">
          <div className="label">{t('MovieFilters.searchByMovieName')}</div>
          <Input value={this.state.filters.search} onChange={this.onMovieNameChange} />
        </div>
        <div className="FilterWrapper">
          <div className="label">{t('MovieFilters.display')}</div>
          <Select showArrow
            className="MoviesViewHeader-select"
            onChange={this.onDisplayChange}
            value={this.state.filters.display}>
            {this.props.generalLinks.map(this.renderDisplayFilterItem)}
          </Select>
        </div>
        <BaseSort
          onValueChange={this.onSortChange}
          defaultSortFilter={this.props.defaultSortFilter}
          sortCriteria={this.props.sortCriteria}
          value={this.state.filters.sort}
        />
        <Button className="ClearFilter" onClick={this.onClearFilter}>
          {t('MovieFilters.clearFilter')}
        </Button>
        {this.getAdditionalActions()}
      </div>
    );
  }
}

MovieFilters.propTypes = {
  defaultDisplayFilter: PropTypes.string.isRequired,
  defaultNameFilter: PropTypes.string,
  defaultSortFilter: PropTypes.string.isRequired,
  generalLinks: PropTypes.array.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  sortCriteria: PropTypes.array.isRequired,
  localizedDefaultSortFilter: PropTypes.string
};

export default withTranslation()(MovieFilters);

