import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {DatePicker} from "antd";

import {momentFormats} from "../../services/date-service/date.service";

import './BaseVerticalRangePicker.scss';

class BaseVerticalRangePicker extends Component {

  state = {
    endDate: null,
    startDate: null
  };

  constructor(props) {
    super(props);
    const {endInitialValue, startInitialValue} = this.props;
    if (endInitialValue) {
      this.state.endDate = endInitialValue;
    }
    if (startInitialValue) {
      this.state.startDate = startInitialValue;
    }
  }

  disabledStartDate = startValue => {
    const {endDate} = this.state;
    return  !!(startValue && endDate && (startValue.valueOf() > endDate.valueOf())) || 
    (this.props.disabledAfterToday && startValue > moment().endOf('day'));
  };

  disabledEndDate = endValue => {
    const {startDate} = this.state;
    return !!(startDate && endValue && (endValue.valueOf() <= startDate.valueOf())) || 
    (this.props.disabledAfterToday && endValue > moment().endOf('day'));;
  };

  onDateChange = (key, value) => {
    this.setState({
      [key]: value
    }, this.onStateUpdated);
  };

  onStateUpdated = () => {
    const {endDate, startDate} = this.state;
    this.props.onChange({endDate, startDate});
  };

  renderDateField = (value, label, name, disableFn) => {
    return (
      <div className="BaseVerticalRangePicker-date">
        <div>{label}</div>
        <DatePicker
          showToday={false}
          disabledDate={disableFn}
          value={value}
          format={momentFormats.date}
          onChange={this.onDateChange.bind(this, name)}/>
      </div>
    );
  };

  render() {
    const {endLabel, startLabel} = this.props;
    return (
      <div className="BaseVerticalRangePicker">
        {this.renderDateField(this.state.startDate, startLabel, 'startDate', this.disabledStartDate)}
        {this.renderDateField(this.state.endDate, endLabel, 'endDate', this.disabledEndDate)}
      </div>
    );
  }
}

BaseVerticalRangePicker.propTypes = {
  endInitialValue: PropTypes.any,
  endLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  startInitialValue: PropTypes.any,
  startLabel: PropTypes.string.isRequired
};

export default BaseVerticalRangePicker;