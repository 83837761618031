import React, { Component } from "react";
import { Router } from "@reach/router";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import StarsMainView from "./StarsMainView/StarsMainView";
import StarDetails from "./StarDetails/StarDetails";

import { getStarsCancelable } from "../../../../../../../../services/stars-service/stars.service";
import jsonData from "./data";

import "./StarsView.scss";
import EditStarModal from "./EditStarModal/EditStarModal";
import { ModalController } from "../../../../../../../../controllers/modal-controller/modal.controller";
import { MainDashboardComController } from "../../../../../../../../pages/MainDashboard/main-dashboard-comm.controller";

class StarsView extends Component {
	subscription;

	state = {
		currentPage: 0,
		errorMsg: "",
		loading: false,
		pagesTotal: 0,
		recordCount: 0,
		pageSize: 0,
		starsData: [],
		starsTotal: 0,
		starsLoaded: false,
		status: "",
	};

	defaultDisplayFilter =
		jsonData.generalLinks[jsonData.generalLinks.length - 1];

	filters = {
		display: this.defaultDisplayFilter,
	};

	starFilters = {
		imported_today: "imported_today=true",
		past_7_days: "imported_days=7",
		past_14_days: "imported_days=14",
		past_30_days: "imported_days=30",
		has_headshot: "head=true",
	};

	componentDidMount() {
		this.subscription =
			MainDashboardComController.mainNavigationLinkClicked.subscribe(
				this.fetchStars.bind(this, 1)
			);
		this.fetchStars(1);
	}

	componentWillUnmount() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	filterBasedOnCriteria = (criteria) => {
		this.filterStars(criteria);
	};

	fetchStars = (page) => {
		this.setState({
			loading: true,
		});
		const filters = this.getFilters();
		const hasSort = filters.some(filter => !!filter.match(/sort_by/g));
		if (!hasSort) {
			filters.push(`sort_by=elastic_score`);
		}
		const { result, controller } = getStarsCancelable(page, filters);
		if (this.lastRequestController) {
			this.lastRequestController.abort();
		}
		this.lastRequestController = controller;
		result.then(this.loadStars).catch(() => { });
	};

	filterStars = (filters) => {
		Object.assign(this.filters, filters);
		this.fetchStars(1);
	};

	getDocumentTitle = () => {
		return this.props.t("StarsView.documentTitle");
	};

	getFilters = () => {
		const filters = [];
		let item;
		const excluded = ["all"];
		for (let key in this.filters) {
			item = this.filters[key];
			switch (key) {
				case 'search':
				case 'sort_order':
				case 'sort_by':
					if (item) {
						filters.push(`${key}=${item}`);
					}
					break;
				default:
					if (item && excluded.indexOf(item) === -1) {
						this.starFilters[item]
							? filters.push(this.starFilters[item])
							: filters.push(`${item}=true`);
					}
					break;
			}
		}
		return filters;
	};

	loadStars = (response) => {
		const { t } = this.props;
		const stars = response.data.data.stars;
		const pagination = response.data.data.pagination;
		this.setState({
			currentPage: pagination.current_page,
			loading: false,
			pagesTotal: pagination.last_page,
			pageSize: pagination.per_page,
			recordCount: pagination.total,
			starsLoaded: true,
			starsData: stars,
			status: t(`StarsView.${this.filters.display}`),
		});
	};

	onPageChange = (page) => {
		this.fetchStars(page);
	};

	refreshContent = () => {
		this.fetchStars(this.state.currentPage);
	};

	showEditStarDialog = (id, name) => {
		const modal = (
			<EditStarModal
				onStarEdit={this.refreshContent}
				starId={id}
				starName={name}
			/>
		);
		ModalController.showModal(modal);
	};

	render() {
		return (
			<div className="StarsView">
				<Router>
					<StarsMainView
						path={"/"}
						listInfo={this.state}
						onPageChange={this.onPageChange}
						contentHeight={this.props.contentHeight}
						defaultDisplayFilter={this.filters.display}
						defaultNameFilter={this.filters.search}
						filterStars={this.filterStars}
						filterBasedOnCriteria={this.filterBasedOnCriteria}
						jsonData={jsonData}
						onStarEdit={this.showEditStarDialog}
					/>
					<StarDetails path={`/:starId/*`} />
				</Router>
			</div>
		);
	}
}

export default compose(withTranslation(), withDocumentTitleUpdate)(StarsView);
