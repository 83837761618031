import {withTranslation} from "react-i18next";

import UploadImages from "../../../../../../../../../../components/modal/UploadImages/UploadImages";

class UploadStudioImagesModal extends UploadImages {

  /**@override
   */
  getImageCategories = () => {
    const {t} = this.props;
    return [t('UploadStudioImagesModal.logo')];
  };
}

export default withTranslation()(UploadStudioImagesModal);