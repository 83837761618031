import {navigate} from "@reach/router";

import {
  getBannersBaseRoute,
  getBannerSetsRoute,
  getBannerSetBannersRoute,
  getBannerSetDetailsRoute,
  getBannerSetPropertiesRoute, getBannerDetailsRoute
} from "./banners-navigation-routes.service";

export const showBannerDetails = (bannerId) => {
  return navigate(getBannerDetailsRoute(bannerId));
};

/**
 * Navigates to banners view
 *
 * @return {Promise<any>}
 */
export const showBanners = () => {
  return navigate(getBannersBaseRoute());
};

/**
 * Navigates to banners sets view
 *
 * @return {Promise<any>}
 */
export const showBannerSets = () => {
  return navigate(getBannerSetsRoute());
};

/**
 * Navigates to banner set details view
 *
 * @param {number|string} bannerSetId
 * @returns {Promise<any>}
 */
export const showBannerSetDetails = bannerSetId => {
  return navigate(getBannerSetDetailsRoute(bannerSetId));
};

export const showBannerSetBanners = (bannerSetId) => {
  return navigate(getBannerSetBannersRoute(bannerSetId));
};

export const showBannerSetProperties = (bannerSetId) => {
  return navigate(getBannerSetPropertiesRoute(bannerSetId));
};