import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Router } from "@reach/router";
import PropTypes from 'prop-types';

import PropertyDetails from '../PropertyDetails/PropertyDetails';
import PropertyMovies from '../../PropertyMovies/PropertyMovies';
import PropertyUsers from '../../PropertyUsers/PropertyUsers';
import PropertyDetailsBannerSets from "../PropertyDetailsBannerSets/PropertyDetailsBannerSets";

import './PropertiesViewDetailsBody.scss';

class PropertiesViewDetailsBody extends Component {
  render() {
    const { name } = this.props;
    return (
      <div className='PropertiesViewDetailsBody'>
        <Router>
          <PropertyDetails
            path={`/details`}
            setLoading={this.props.setLoading}
            {...this.props}
          />
          <PropertyMovies path={`/movies/*`}
            propertyName={name} />
          <PropertyUsers path={`/users`} propertyName={name} propertyId={this.props.propertyId} />
          <PropertyDetailsBannerSets path={'/banner_sets/*'}
            contentHeight={this.props.contentHeight}
            propertyId={this.props.propertyId} />
        </Router>
      </div>
    );
  }
}

PropertiesViewDetailsBody.propTypes = {
  contentHeight: PropTypes.number,
  hosts: PropTypes.array,
  name: PropTypes.string,
  onHostListUpdate: PropTypes.func,
  propertyData: PropTypes.array,
  propertyId: PropTypes.string
};

export default withTranslation()(PropertiesViewDetailsBody);
