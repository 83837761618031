import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Checkbox, Button, Input } from "antd";

import { ModalDialog } from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import { displayErrorNotification, displaySuccessNotification } from "../../../../../../../../../../services/notification-service/notification.service";
import { setThemeCustomNameByOrientation, setThemeEnabledStatus } from "../../../../../../../../../../services/tag-service/tag-service";

import "./EditThemeModal.scss";

const { TextArea } = Input;

class EditThemeModal extends Component {

    modalRef = React.createRef();
    startingData = {};
    state = {};

    componentDidMount() {
        this.loadData(this.props.tag);
    }

    getActions = () => {
        const { t } = this.props;
        return [
            <Button key="cancelBtn" onClick={this.onCancel}>
                {t("EditThemeModal.cancel")}
            </Button>,
            <Button
                key="saveChangesBtn"
                disabled={!this.hasDataChanged()}
                onClick={this.onSaveChanges}
            >
                {t("EditThemeModal.saveChanges")}
            </Button>,
        ];
    };

    getModal = () => {
        return this.modalRef.current;
    };

    getModalTitle = () => {
        return this.props.t("EditThemeModal.title", {
            name: this.props.tag.name
        });
    };

    hasDataChanged = () => {
        return JSON.stringify(this.state) !== JSON.stringify(this.startingData);
    };

    loadData = (tag) => {
        const {
            name,
            customized_names = [],
            enabled
        } = tag;
        const customised = customized_names.reduce((obj, custom) => {
            if (custom.orientation?.name) {
                obj[custom.orientation?.name] = custom.custom_name;
            }
            return obj;
        }, {});
        const state = {
            name,
            ...customised,
            enabled: enabled === 1
        };
        Object.assign(this.startingData, state);
        this.setState(state);
    };

    onCancel = () => {
        this.getModal().closeModal();
    };

    onChange = (key, event) => {
        const value = event?.target
            ? event?.target?.value || event?.target?.checked
            : event;
        this.setState({
            [key]: value
        });
    };

    getOrientationId = (tag, orientation) => {
        const { orientations = [] } = tag;
        return orientations.filter(o => o.name === orientation)[0]?.id;
    }

    onSaveChanges = () => {
        const { tag, t } = this.props;
        const { orientations } = tag;
        const { enabled } = this.state;
        const message = t("EditThemeModal.savingMsg");
        this.getModal().setLoading(message);
        const promises = orientations.reduce((arr, orientation) => {
            const { name } = orientation;
            if (this.state[name] !== this.startingData[name]) {
                let capitaliseEveryWord = this.state[name]?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                arr.push(setThemeCustomNameByOrientation(tag.id, this.getOrientationId(tag, name), capitaliseEveryWord));
            }
            return arr;
        }, []);
        if (enabled !== this.startingData.enabled) {
            promises.push(setThemeEnabledStatus(tag.id, enabled))
        }
        Promise.all(promises)
            .then(this.onThemeEditSuccess)
            .catch(this.onThemeEditFailure);
    };

    onThemeEditFailure = () => {
        this.getModal().clearLoading();
        displayErrorNotification({
            duration: 3,
            message: this.props.t("EditThemeModal.editThemeFailure"),
        });
    };

    onThemeEditSuccess = (data) => {
        this.getModal().closeModal();
        displaySuccessNotification({
            duration: 3,
            message: this.props.t("EditThemeModal.editThemeSuccess"),
        });
        this.props.onThemeEdit(data);
    };

    renderInput = (value, changed, label) => {
        const { t } = this.props;
        return (
            <div key={label + changed} className="EditThemeModal-inner">
                <span className="EditThemeModal-inner-label">
                    {t(`EditThemeModal.${label}`, {
                        orientation: changed
                    })}:
                </span>
                <TextArea
                    onChange={this.onChange.bind(this, changed)}
                    value={value}
                />
            </div>
        );
    };

    renderOrientation = (orientation) => {
        const { name } = orientation;
        return this.renderInput(this.state[name], name, "edit");
    }

    render() {
        const { t, tag } = this.props;
        const { orientations } = tag;
        const {
            enabled,
        } = this.state;
        return (
            <ModalDialog
                title={this.getModalTitle()}
                actions={this.getActions()}
                ref={this.modalRef}
            >
                <div className="EditThemeModal">
                    {orientations.map(this.renderOrientation)}
                    <div className="EditThemeModal-inner">
                        <span className="EditThemeModal-inner-label">
                            {t(`EditThemeModal.enabled`)}:
                        </span>
                        <Checkbox
                            onChange={this.onChange.bind(this, 'enabled')}
                            checked={enabled}
                        />
                    </div>
                </div>
            </ModalDialog>
        );
    }
}

EditThemeModal.propTypes = {
    tag: PropTypes.object.isRequired,
    onThemeEdit: PropTypes.func.isRequired,
};

export default withTranslation()(EditThemeModal);
