import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, AutoComplete, Input, Checkbox, Col, Row } from "antd";
import moment from "moment";
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import { MovieDetailsCommController } from "../../../movie-details-comm.controller";
import { LogController } from "../../../../../../../../../../../../controllers/log-controller/log.controller";

import {
  detachMovieEpisode,
  editScene
} from "../../../../../../../../../../../../services/movies-service/movies.service";
import { searchCustomPreview } from "../../../../../../../../../../../../services/video-service/video.service";
import { convertMomentToDate, momentFormats } from "../../../../../../../../../../../../services/date-service/date.service";
import {
  displaySuccessNotification,
  displayErrorNotification
} from "../../../../../../../../../../../../services/notification-service/notification.service";

import ModalDialog from "../../../../../../../../../../../../components/modal/ModalDialog/ModalDialog"
import TimeSelector from "./TimeSelector/TimeSelector";
import BaseCheckboxList from '../../../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList';
import './EditMovieSceneModal.scss';
import BaseDatePickerList from '../../../../../../../../../../../../components/BaseDatePickerList/BaseDatePickerList';

/* istanbul ignore file */
class EditMovieSceneModal extends Component {

  filterDelay = 300;

  modalRef = React.createRef();

  endTime;
  initialCustomPreview;
  runTime;
  startTime;
  initialIsEpisode;

  state = {
    customPreview: '',
    enabled: 0,
    endTime: '',
    episode_index: '',
    options: [],
    overridden_movies_id: '',
    publishEnd: [],
    publishStart: [],
    startTime: '',
    isEpisode: 0,
    isValid: false,
    runTime: '',
    downloadableOnProperties: []
  };

  constructor(props) {
    super(props);
    const { runTime } = MovieDetailsCommController.getMovieData();
    const {
      enabled,
      endTime,
      fullMovieData,
      publishEnd,
      publishStart,
      scenesAreEpisodes,
      sceneIndex,
      startTime,
      videos,
      downloadableOnProperties = []
    } = props;
    this.initialCustomPreview = videos.length ? videos[0].partial_url : '';
    this.startTime = this.convertToMoment(startTime);
    this.endTime = this.convertToMoment(endTime);
    this.runTime = this.convertToMoment(runTime);
    this.publishEnd = publishEnd;
    this.publishStart = publishStart;
    this.enabled = enabled ? 1 : 0;
    this.downloadableOnProperties = downloadableOnProperties.map(item => item.id);
    this.initialIsEpisode = scenesAreEpisodes ? 1 : 0;
    this.episode_index = this.initialIsEpisode ? sceneIndex : undefined;
    this.overridden_movies_id = this.initialIsEpisode ? fullMovieData.id : undefined;
    const newState = {
      customPreview: this.initialCustomPreview,
      enabled: this.enabled,
      downloadableOnProperties: this.downloadableOnProperties,
      endTime: this.endTime,
      episode_index: this.episode_index,
      isEpisode: this.initialIsEpisode,
      overridden_movies_id: this.overridden_movies_id,
      publishEnd: this.publishEnd,
      publishStart: this.publishStart,
      startTime: this.startTime,
      runTime: runTime
    };
    newState.isValid = this.isValid(newState);

    Object.assign(this.state, newState);
  }

  convertToMoment = (time) => {
    return moment(time, 'HH:mm:ss');
  };

  detachMovieEpisodeIfNeeded = () => {
    const { movieId, sceneId, scenesAreEpisodes } = this.props;
    const { isEpisode } = this.state;
    let promise;
    if (scenesAreEpisodes && !isEpisode) {
      promise = detachMovieEpisode(movieId, sceneId);
    } else {
      promise = Promise.resolve();
    }

    return promise;
  };

  getActions = () => {
    const { t } = this.props;
    return [(
      <Button onClick={this.onCancel.bind(this)}
        key="cancelBtn">
        {t('EditMovieSceneModal.cancel')}
      </Button>
    ), (
      <Button onClick={this.saveChanges.bind(this)}
        key="saveChangesBtn"
        type="primary"
        disabled={!this.hasDataChanged()}>
        {t('EditMovieSceneModal.saveChanges')}
      </Button>
    )];
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getModalTitle = () => {
    const { t, sceneIndex, scenesAreEpisodes } = this.props;
    const name = scenesAreEpisodes ? 'Episode' : 'Scene';
    return t(`EditMovieSceneModal.edit${name}`, { sceneIndex: sceneIndex });
  };

  getTimeDurationInSeconds = (time) => {
    return time.diff(moment().startOf('day'), 'seconds');
  };

  hasDataChanged = () => {
    const {
      customPreview, enabled, episode_index, isEpisode, publishEnd, publishStart, startTime,
      endTime, overridden_movies_id, downloadableOnProperties
    } = this.state;
    return (
      customPreview !== this.initialCustomPreview ||
      startTime !== this.startTime ||
      endTime !== this.endTime ||
      publishEnd !== this.publishEnd ||
      publishStart !== this.publishStart ||
      enabled !== this.enabled ||
      downloadableOnProperties.toString() !== this.downloadableOnProperties.toString() ||
      isEpisode !== this.initialIsEpisode ||
      (isEpisode && (
        (episode_index && +episode_index !== this.episode_index) ||
        (overridden_movies_id && +overridden_movies_id !== this.overridden_movies_id))
      )
    );
  };

  isValid = ({ episode_index, isEpisode, startTime, endTime, overridden_movies_id }) => {
    let isValid = endTime.diff(startTime) > 0 && this.runTime.diff(endTime) >= 0;

    if (isValid) {
      isValid = !isEpisode || (episode_index !== undefined && episode_index !== '' && overridden_movies_id !== undefined && overridden_movies_id !== '');
    }

    return isValid;
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onDateChange = (key, value) => {
    this.setState({ [key]: value });
  };

  onCheckBoxChange = (key, value) => {
    this.setState(prevState => {
      const newValue = key === 'downloadableOnProperties' ? value : prevState[key] ? 0 : 1;
      const newState = {
        [key]: newValue
      };
      // const stateChange = Object.assign(cloneDeep(prevState), newState);
      // newState.isValid = this.isValid(stateChange);
      return newState;
    });
  };

  onCustomPreviewChange = value => {
    this.setState({
      customPreview: value
    });
  };

  onCustomPreviewSearch = (searchText) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.searchItems.bind(this, searchText), this.filterDelay);
  };

  onEpisodeDataChanged = (key, event) => {
    let value = event.target ? event.target.value : event;
    this.setState(prevState => {
      const newState = {
        [key]: value
      };
      const stateChange = Object.assign(cloneDeep(prevState), newState);
      newState.isValid = this.isValid(stateChange);
      return newState;
    });
  };

  onSceneEditFailure = (error) => {
    LogController.logError(error);
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditMovieSceneModal.sceneEditFailure', { sceneIndex: this.props.sceneIndex })
    });
  };

  onSceneEditSuccess = () => {
    this.getModal().clearLoading();
    const { onSceneEditSuccess, sceneIndex, t } = this.props;
    onSceneEditSuccess();
    displaySuccessNotification({
      duration: 3,
      message: t('EditMovieSceneModal.sceneEditSuccess', { sceneIndex })
    });
    this.onCancel();
  };

  onTimeChange = (isStart, value) => {
    this.setState(() => {
      const property = isStart ? 'startTime' : 'endTime';
      const newState = {
        [property]: value
      };
      // const stateChange = Object.assign(cloneDeep(prevState), newState);
      // newState.isValid = this.isValid(stateChange);
      return newState;
    });
  };

  onDateTimeChange = ({ key, id, dateTime, applyToAll }) => {
    const state = this.state[key] ?? [];

    const updatedState = state?.map(item => {
      if (item.id === id) {
        item.value = dateTime;
      } else if (applyToAll) {
        item.value = dateTime;
      }

      return item;
    });

    this.setState({ [key]: updatedState }, this.onDataChange);
  };

  renderEditDatePicker = (key, label) => {
    const props = {
      data: this.state[key],
      format: momentFormats.shortMonthDate,
      onChange: ({ id, dateTime, applyToAll }) => this.onDateTimeChange({
        key,
        id,
        dateTime: convertMomentToDate(dateTime, true, false),
        applyToAll
      }),
      showTime: true
    };

    if (this.state[key]?.length === 0) return null;

    return (
      <div className="EditMovieSceneModal-date-field" key={key}>
        <div className="text-bold">
          {label}:
        </div>
        <BaseDatePickerList {...props} />
      </div>
    )
  };

  renderEditableEpisodeData = () => {
    const { isEpisode } = this.state;
    const { t } = this.props;
    return (
      <div className="px-10">
        <div className='CheckboxContainer mb-20'>
          <div className="text-bold">{t('EditMovieSceneModal.isEpisode')}</div>
          <Checkbox
            checked={isEpisode}
            onChange={this.onCheckBoxChange.bind(this, 'isEpisode')} />
        </div>

        {this.state.isEpisode ? (
          <Row gutter={16} className='mb-20'>
            <Col span={12}>
              {this.renderInput('overridden_movies_id', t('EditMovieSceneModal.episodeFullMovieId'))}
            </Col>
            <Col span={12}>
              {this.renderInput('episode_index', t('EditMovieSceneModal.episodeNumber'))}
            </Col>
          </Row>
        ) : null
        }
      </div>
    );
  };

  renderEpisodeAttachedData = () => {
    const { fullMovieData, t } = this.props;
    const { id, title } = fullMovieData;
    return (
      <div className="text-bold">
        {t('EditMovieSceneModal.sceneIsSetAsEpisode', { id, title })}
      </div>
    );
  };

  renderEpisodeData = () => {
    const { fullMovieData, viewingMovieId } = this.props;
    let view;
    if (!fullMovieData || (+fullMovieData.id === +viewingMovieId)) { // We are looking at full movie view so display editable options
      view = this.renderEditableEpisodeData();
    } else {
      view = this.renderEpisodeAttachedData();
    }

    return view;
  };

  renderInput = (key, label) => {
    return (
      <>
        <div className="text-bold">{label}</div>
        <Input value={this.state[key]}
          onChange={this.onEpisodeDataChanged.bind(this, key)} />
      </>
    );
  };

  saveChanges = () => {
    const { sceneId, properties = [], t } = this.props;
    const loadingMsg = t('EditMovieSceneModal.editInProgress');
    this.getModal().setLoading(loadingMsg);

    const startTime = this.getTimeDurationInSeconds(this.state.startTime);
    const endTime = this.getTimeDurationInSeconds(this.state.endTime);
    const {
      customPreview,
      enabled,
      overridden_movies_id,
      episode_index,
      publishEnd = [],
      publishStart = [],
      downloadableOnProperties = []
    } = this.state;

    const scenesOnPropertiesData = properties.map(({ id }) => {
      const propertyPublishStart = publishStart.find(item => item.id === id).value;
      const propertyPublishEnd = publishEnd.find(item => item.id === id).value;

      const postObj = {
        properties_id: id,
        publish_start: convertMomentToDate(propertyPublishStart, true, true),
        publish_end: convertMomentToDate(propertyPublishEnd, true, true),
        downloadable: 0
      };

      if (downloadableOnProperties.includes(id)) {
        postObj.downloadable = 1;
      }

      return postObj;
    });

    const postData = {
      startTime,
      endTime,
      videos: customPreview,
      overriddenData: {
        overridden_movies_id,
        episode_index
      },
      enabled,
      scenesOnPropertiesData
    };

    editScene(sceneId, postData)
      .then(this.detachMovieEpisodeIfNeeded)
      .then(this.onSceneEditSuccess)
      .catch(this.onSceneEditFailure);
  };

  searchItems = (searchText) => {
    searchCustomPreview(searchText)
      .then(this.setCustomPreviewOptions);
  };

  setCustomPreviewOptions = (response) => {
    const { data } = response.data;
    this.setState({
      options: data
    });
  };

  renderBaseCheckboxList = () => {
    const { downloadableOnProperties } = this.state;
    const { t, properties } = this.props;

    return (
      <>
        <div className='text-bold'>{t('global.downloadable_on_properties')}</div>
        <BaseCheckboxList
          onChange={this.onCheckBoxChange.bind(this, 'downloadableOnProperties')}
          showSelectAll={true}
          checked={downloadableOnProperties}
          items={properties}
        />
      </>
    );
  };

  render() {
    const { customPreview, enabled, options, runTime } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <ModalDialog title={this.getModalTitle()}
          actions={this.getActions()}
          forwardedRef={this.modalRef}>
          <div className="EditMovieSceneModal">
            <div className="Duration text-bold">
              {t('EditMovieSceneModal.movieDuration', { duration: runTime })}
            </div>
            <Row gutter={16} className='mb-20 px-10'>
              <Col span={5}  >
                <TimeSelector label={t('EditMovieSceneModal.startTime')}
                  value={this.state.startTime}
                  onChange={this.onTimeChange.bind(this, true)} />
              </Col>
              <Col span={5}  >
                <TimeSelector label={t('EditMovieSceneModal.endTime')}
                  value={this.state.endTime}
                  onChange={this.onTimeChange.bind(this, false)} />
              </Col>

              <Col span={9}  >
                <div className='text-bold'>{t('EditMovieSceneModal.customPreview')}:</div>
                <AutoComplete
                  autosize
                  onSearch={this.onCustomPreviewSearch}
                  dataSource={options}
                  onChange={this.onCustomPreviewChange}
                  value={customPreview}
                  className='w-100'
                />
              </Col>

              <Col span={5}  >
                <br />
                <div className='CheckboxContainer'>
                  <div className="text-bold">{t('EditMovieSceneModal.enabled')}</div>
                  <Checkbox
                    checked={enabled}
                    onChange={this.onCheckBoxChange.bind(this, 'enabled')} />
                </div>
              </Col>
            </Row>

            {this.renderEpisodeData()}

            <div className='px-10 mb-20'>
              {this.renderEditDatePicker('publishStart', t('EditMovieSceneModal.publishStartDate'))}
            </div>
            <div className='px-10 mb-20'>
              {this.renderEditDatePicker('publishEnd', t('EditMovieSceneModal.publishEndDate'))}
            </div>
            <div className='px-10'>
              {this.renderBaseCheckboxList()}
            </div>
          </div>
        </ModalDialog>
      </React.Fragment>
    );
  }
}

EditMovieSceneModal.propTypes = {
  enabled: PropTypes.number,
  downloadableOnProperties: PropTypes.array,
  endTime: PropTypes.string,
  fullMovieData: PropTypes.object,
  movieId: PropTypes.number,
  onSceneEditSuccess: PropTypes.func,
  publishEnd: PropTypes.array,
  publishStart: PropTypes.array,
  sceneId: PropTypes.number.isRequired,
  scenesAreEpisodes: PropTypes.number,
  sceneIndex: PropTypes.number.isRequired,
  startTime: PropTypes.string,
  viewingMovieId: PropTypes.string
};

export default withTranslation()(EditMovieSceneModal);
