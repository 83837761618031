import React, {Component} from 'react';
import {Card} from "antd";
import PropTypes from 'prop-types';

import './BaseCard.scss';

class BaseCard extends Component {

  /**
   * Returns card body element for render
   *
   * @returns {JSX.Element}
   */
  renderBody = () => {
    const {children} = this.props;
    return children ? (
      <div className="BaseCard-body-children">
        {children}
      </div>
    ) : null;
  };

  /**
   * Returns footer element for render
   *
   * @returns {JSX.Element}
   */
  renderFooter = () => {
    const {footer} = this.props;
    return footer ? (
      <div className="BaseCard-body-footer">
        {footer}
      </div>
    ) : null;
  };

  render() {
    const {className, children, title, extra} = this.props;
    const classes = children ? ['BaseCard', className] : ['BaseCard', className, 'Empty'];
    return (
      <Card className={classes.join(' ')}
            type="inner"
            title={title}
            extra={extra}>
        <div className="BaseCard-body">
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </Card>
    );
  }
}

BaseCard.propTypes = {
  className: PropTypes.string,
  extra: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  footer: PropTypes.element,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
};

export default BaseCard;
