import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import BannerSetBannersAbstract from "./BannerSetBannersAbstract";

import { getProperties } from "../../../../../../../../../../services/properties-service/properties.service";
import { renderListClickableItems } from "../../../../../../../../../../services/util-service/util.service";
import { getPropertyDetailsRoute } from "../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";
import {
  getBannerSetsRoute
} from "../../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";

import { getDefaultBannerSetSaveKey } from "../../../../../../../../../../services/banners-service/banners.service";

import { DocumentTitleController } from "../../../../../../../../../../controllers/title-controller/title.controller";

/* istanbul ignore file */
class BannerSetBanners extends BannerSetBannersAbstract {

  constructor(props) {
    super(props);
    this.dialogName = 'BannerSetBanners';
  }

  getAdditionalDataPromises = () => {
    return [
      getProperties()
    ];
  };

  // getAdditionalStatePropertiesFromValue = (values) => {
  //   const {properties} = values[4].data.data;
  //   return {properties};
  // };

  getAdditionalTableData = () => {
    const { properties } = this.state;
    return [{
      name: this.props.t('BannerSetBanners.properties'),
      value: properties,
      key: 'properties'
    }];
  };

  getRoutes = () => {
    const { showBreadcrumbs, t } = this.props;
    const { name } = this.state;

    if (showBreadcrumbs) return [];

    const routes = [{
      url: getBannerSetsRoute(),
      text: t('BannerSetProperties.defaultBannerSets')
    }, {
      text: `${name} banners`
    }];

    return routes;
  };

  getSaveKey = () => {
    const { bannerSetId } = this.props;
    return getDefaultBannerSetSaveKey(bannerSetId);
  };

  loadData = (callback, values) => {
    this.clearLoading();
    const state = this.getStateFromValues(values)
    DocumentTitleController.setDocumentTitle(this.props.t('BannerSetBanners.documentTitle', { name: state.name }));
    this.setState(state, this.triggerVirtualListReload);
    if (callback) {
      callback();
    }
  };

  parseTableSetName = (name) => {
    const { t } = this.props;
    return t('BannerSetBanners.defaultSet', { name: name || '' });
  };

  renderNonProtoField = (value, data) => {
    const { key } = data;
    let render = null;
    if (key === 'properties') {
      render = renderListClickableItems(value, undefined, 'property', getPropertyDetailsRoute);
    }

    return render;
  };
}

BannerSetBanners.propTypes = {
  bannerSetId: PropTypes.string,
  onReorderChange: PropTypes.func.isRequired
};

export default withTranslation()(BannerSetBanners);
