import React from 'react';
import PropTypes from 'prop-types';

import './StudioDetailsBodyDescription.scss';

const StudioDetailsBodyDescription = props => {
  let view = null;
  if (props.description) {
    view = (
      <p className="StudioDetailsBodyDescription">{props.description}</p>
    );
  }
  return view;
};

StudioDetailsBodyDescription.propTypes = {
  description: PropTypes.string
};

export default StudioDetailsBodyDescription;
