import {withTranslation} from "react-i18next";

import PropertyBannerSetBannersAbstract
  from "../../../../BannersView/BannerSets/PropertyBannerSetBanners/PropertyBannerSetBannersAbstract";

import {
  getPropertiesDetailsBannerSetBannersRoute,
  getPropertiesDetailsBannersSetsRoute
} from "../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";
import {showPropertyDetailsBannersSets} from "../../../../../../../../../../../services/navigation/properties-navigation/properties-navigation.service";
import {getBannerSetPropertySaveKey} from "../../../../../../../../../../../services/banners-service/banners.service";

/* istanbul ignore file */
class PropertyDetailsBannerSetBanners extends PropertyBannerSetBannersAbstract {

  constructor(props) {
    super(props);
    this.dialogName = 'PropertyDetailsBannerSetBanners';
  }

  getRoutes = () => {
    const {bannerSetId, propertyId, t} = this.props;
    const {propertyName, name} = this.state;
    return [{
      displayedLink: getPropertiesDetailsBannerSetBannersRoute(propertyId, bannerSetId),
      link: getPropertiesDetailsBannersSetsRoute(propertyId),
      onNavigationItemClick: showPropertyDetailsBannersSets,
      subtitle: t('PropertyDetailsBannerSetBanners.propertyBannerSetBanners', {
        propertyName: propertyName || '',
        setName: name || ''
      }),
      text: t('PropertyDetailsBannerSetBanners.propertyBannerSets', {propertyName: propertyName || ''})
    }];
  };

  getPushAllConfirmationModalMessage = () => {
    const {t} = this.props;
    const {propertyName, name: setName} = this.state;
    return t(`${this.dialogName}.pushAllConfirmMsg`, {propertyName, setName});
  };

  getSaveKey = () => {
    const {bannerSetId, propertyId} = this.props;
    return getBannerSetPropertySaveKey(bannerSetId, propertyId);
  };
}

export default withTranslation()(PropertyDetailsBannerSetBanners);
