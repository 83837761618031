import React, {Component} from 'react';
import {List} from "antd";
import PropTypes from 'prop-types';

import MovieCard from "../MoviesMainView/MoviesViewList/MovieCard/MovieCard";

import {MoviesViewCommController} from '../MoviesViewCommController/movies-view-comm.controller';

import {getMovieDetailsRoute,} from "../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";
import {showMovieDetails} from "../../../../../../../../../services/navigation/movies-navigation/movies-navigation.service";

import './MoviesViewListAbstract.scss';

class MoviesViewListAbstract extends Component {
  elements;

  savedScrollPosition;

  scrollPosition;

  state = {
    movieName: '',
    stickyPosition: 61,
    showSticky: false
  };

  stickyUpdated = false;

  updateSubscription;

  componentDidMount() {
    this.updateSubscription = MoviesViewCommController.viewUpdated.subscribe(this.triggerUpdate);
    const moviesList = document.getElementsByClassName('MoviesViewList')[0];
    if (moviesList) {
      moviesList.addEventListener('scroll', this.onScroll);
      const {scrollTop} = this.props;
      if (scrollTop !== undefined) {
        moviesList.scrollTop = scrollTop;
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.savedScrollPosition) {
      this.savedScrollPosition = undefined;
      const moviesList = document.getElementsByClassName('MoviesViewList')[0];
      if (moviesList) {
        moviesList.scrollTop = 0;
      }
    }
  }

  componentWillUnmount() {
    const moviesList = document.getElementsByClassName('MoviesViewList')[0];
    moviesList.removeEventListener('scroll', this.onScroll);
    this.updateSubscription.unsubscribe();
  }

  getElementIndexByScrollPosition = () => {
    let item = 0;
    let elementIndex = -1;
    let itemCount = this.elements.length;
    let currentHeight = 15;
    let currentItem;
    let rect;
    while ((elementIndex === -1) && (item < itemCount)) {
      currentItem = this.elements[item];
      rect = currentItem.getBoundingClientRect();
      currentHeight += rect.height;
      if (this.scrollPosition <= currentHeight) {
        elementIndex = item;
      }
      currentHeight += 15;
      item++;
    }

    return elementIndex;
  };

  getMovieDetailsRoute = (movieId) => {
    return getMovieDetailsRoute(movieId);
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.stickyUpdated) {
      this.stickyUpdated = false;
      this.savedScrollPosition = undefined;
    } else {
      this.savedScrollPosition = this.scrollPosition;
    }
    return null;
  }

  getStickyHeaderView = () => {
    let sticky = null;
    if (this.state.showSticky) {
      const style = {
        top: `${this.state.stickyPosition}px`
      };
      sticky = (
        <div className="StickyHeader" style={style}>
          <div className="StickyHeaderInner">{this.state.movieName}</div>
        </div>
      );
    }

    return sticky;
  };

  getStickyMovieName = () => {
    let movieName;
    if (!this.state.movieName) {
      movieName = this.props.movies[0].title;
    } else {
      const index = this.getElementIndexByScrollPosition();
      movieName = this.props.movies[index].title;
    }

    return movieName;
  };

  onScroll = (event) => {
    const isLargeView = window.matchMedia("(min-width: 500px)");
    if (!isLargeView.matches) {
      this.scrollPosition = event.currentTarget.scrollTop;
      if (!this.elements) {
        this.elements = document.getElementsByClassName('MovieCard');
      }
      this.updateMovieStateIfNeeded();
    }
  };

  renderMainListItem = (movie) => {
    const {movieId} = movie;
    const {onMovieEdit, onSpecialCategoriesEdit, onMovieEdit2257} = this.props;
    return (
      <MovieCard {...movie}
                 onMovieEdit={onMovieEdit}
                 onSpecialCategoriesEdit={onSpecialCategoriesEdit}
                 onMovieEdit2257={onMovieEdit2257}
                 showDetails={this.showMovieDetails.bind(this, movieId)}
                 movieDetailsRoute={this.getMovieDetailsRoute(movieId)}
                 setMovieEnabled={this.props.setMovieEnabled}
                 additionalActions={this.props.additionalActions}/>
    );
  };

  showMovieDetails = (movieId) => {
    showMovieDetails(movieId);
  };

  triggerUpdate = () => {
    const moviesList = document.getElementsByClassName('MoviesViewList')[0];
    const top = moviesList.getBoundingClientRect().top - 67;
    this.stickyUpdated = true;
    this.setState({
      stickyPosition: top
    });
  };

  updateMovieStateIfNeeded = () => {
    let movieName;
    if (this.scrollPosition >= 100) {
      if (!this.state.showSticky) {
        this.stickyUpdated = true;
        movieName = this.getStickyMovieName();
        this.setState({
          movieName: movieName,
          showSticky: true
        });
      } else {
        movieName = this.getStickyMovieName();
        if (this.state.movieName !== movieName) {
          this.stickyUpdated = true;
          this.setState({
            movieName: movieName
          });
        }
      }
    } else {
      if (this.state.showSticky) {
        this.stickyUpdated = true;
        this.setState({
          movieName: '',
          showSticky: false
        });
      }
    }
  };

  render() {
    this.elements = undefined;
    return (
      <div className="MoviesViewList">
        {this.getStickyHeaderView()}
        <List renderItem={this.renderMainListItem}
              dataSource={this.props.movies}
              split={false}/>
      </div>
    );
  }
}

MoviesViewListAbstract.propTypes = {
  className: PropTypes.string,
  movies: PropTypes.array.isRequired,
  setMovieEnabled: PropTypes.func.isRequired,
  onMovieEdit: PropTypes.func.isRequired,
  onSpecialCategoriesEdit: PropTypes.func.isRequired,
  onMovieEdit2257: PropTypes.func.isRequired
};

export default MoviesViewListAbstract;



