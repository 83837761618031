
import axios from 'axios';

export const urls = {
  exportMonthly: '/nats/report/profitloss/monthly/export',
  monthly: '/nats/report/profitloss/monthly',
  byLicensor: '/nats/report/profitloss/movies',
  byPeriod: '/nats/report/profitloss/properties',
  byProperty: '/nats/report/profitloss/licensors',
  byLicensorTop: '/nats/report/profitloss/licensors/monthly',
  byLicensorTopExport: '/nats/report/profitloss/licensors/monthly/export',
  exportByLicensor: '/nats/report/profitloss/licensors/export',
  exportByMovie: '/nats/report/profitloss/movies/export',
  exportByProperty: '/nats/report/profitloss/properties/export',
  exportFull: '/nats/report/profitloss/export/full',
  editPayout: '/nats/report/profitloss/update'
};

export const exportFullPayouts = (startDate, endDate) => {
  return axios.get(`${urls.exportFull}?start_date=${startDate}&end_date=${endDate}`);
};

export const exportPayoutsByLicensor = (propertyId, startDate, endDate) => {
  return axios.get(`${urls.exportByLicensor}?properties_id=${propertyId}&start_date=${startDate}&end_date=${endDate}`);
};

export const exportPayoutsByMovie = (propertyId, licensorId, startDate, endDate) => {
  return axios.get(`${urls.exportByMovie}?properties_id=${propertyId}&licensors_id=${licensorId}&start_date=${startDate}&end_date=${endDate}`);
};

export const exportPayoutsByProperty = (startDate, endDate) => {
  return axios.get(`${urls.exportByProperty}?start_date=${startDate}&end_date=${endDate}`);
};

export const exportPayoutsMonthly = period => {
  const { start_date, end_date } = period;
  return axios.get(`${urls.exportMonthly}?start_date=${start_date}&end_date=${end_date}`);
};

export const getPayouts = (startDate, endDate, page) => {
  return axios.get(`${urls.monthly}?page=${page}&start_date=${startDate}&end_date=${endDate}`);
};

export const getPayoutsByLicensorTop = (startDate, endDate, page) => {
  return axios.get(`${urls.byLicensorTop}?page=${page}&start_date=${startDate}&end_date=${endDate}`);
};

export const getPayoutsByLicensorTopExport = (startDate, endDate) => {
  return axios.get(`${urls.byLicensorTopExport}?&start_date=${startDate}&end_date=${endDate}`);
};

export const getPayoutByLicensor = params => {
  const { properties_id, licensors_id, start_date, end_date, page } = params;
  return axios.get(`${urls.byLicensor}?page=${page}&properties_id=${properties_id}&licensors_id=${licensors_id}&start_date=${start_date}&end_date=${end_date}`);
};

export const getPayoutByPeriod = (startDate, endDate) => {
  return axios.get(`${urls.byPeriod}?start_date=${startDate}&end_date=${endDate}`);
};

export const getPayoutByProperty = params => {
  const { startDate, endDate, propertyId, page } = params
  return axios.get(`${urls.byProperty}?page=${page}&properties_id=${propertyId}&start_date=${startDate}&end_date=${endDate}`);
};

export const editPayoutProperty = (propertyId, data) => {
  return axios.patch(`${urls.editPayout}?properties_id=${propertyId}`, data);
};

export const editFinalizePayoutStatus = (propertyId, startDate, endDate, status) => {
  return axios.patch(`/nats/report/profitloss/lock?properties_id=${propertyId}&start_date=${startDate}&end_date=${endDate}&status=${status}`);
};
