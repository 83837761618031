import React from 'react';
import { withTranslation } from 'react-i18next';

import LicensorMoviesFooter from '../LicensorMoviesFooter/LicensorMoviesFooter';
import LicensorMoviesList from '../LicensorMoviesList/LicensorMoviesList';
import MoviesMainViewAbstract from "../../../MoviesView/MoviesMainViewAbstract/MoviesMainViewAbstract";

class LicensorMoviesMainView extends MoviesMainViewAbstract {

  renderFooter = () => {
    const { fetchMovies, data } = this.props;
    return (
      <LicensorMoviesFooter
        onChange={fetchMovies}
        currentPage={data.currentPage}
        pageCount={data.pageCount}
        pageSize={data.pageSize}
        recordCount={data.recordCount}
      />
    );
  };


  renderMoviesList = () => {
    const {
      data,
      setMovieEnabled,
      licensorId,
      onMovieEdit,
      onMovieEdit2257,
      onSpecialCategoriesEdit } = this.props;
    return (
      <LicensorMoviesList
        movies={data.movies}
        licensorId={licensorId}
        setMovieEnabled={setMovieEnabled}
        onMovieEdit={onMovieEdit}
        onMovieEdit2257={onMovieEdit2257}
        onSpecialCategoriesEdit={onSpecialCategoriesEdit}
      />
    );
  };
}

export default withTranslation()(LicensorMoviesMainView);
