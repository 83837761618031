import React from 'react';
import {withTranslation} from "react-i18next";
import {Button, Collapse, Icon} from "antd";
import PropTypes from 'prop-types';

import ConfigurationCollapseAbstract from "../ConfigurationCollapseAbstract";
import SelectStudioModal from '../SelectStudioModal/SelectStudioModal';
import StudioPlayerConfigCollapse from '../StudioPlayerConfigCollapse/StudioPlayerConfigCollapse';

import {stopEvent} from "../../../../../../../../../../services/util-service/util.service";

import {ModalController} from "../../../../../../../../../../controllers/modal-controller/modal.controller";

import './PropertyPlayerConfigCollapse.scss';

/* istanbul ignore file */
class PropertyPlayerConfigCollapse extends ConfigurationCollapseAbstract {

  displaySelectStudiosModal = (id, event) => {
    const {studios} = this.props.properties[id];
    stopEvent(event);
    const modal = (
      <SelectStudioModal
        studios={studios || {}}
        onStudiosSelected={this.props.setSelectedStudios}
        propertyId={id}
      />
    );
    ModalController.showModal(modal);
  };

  getConfig = ({propertyId}) => {
    return this.props.properties[propertyId].config;
  };

  getConfigValues = ({propertyId}) => {
    return this.props.properties[propertyId].configValues;
  };

  getItemId = ({propertyId}) => propertyId;

  getPropertyCollapseHeader = (propertyId, propertyName, deleteAll) => {
    const {t} = this.props;
    return (
      <div className="Header">
        <div className="Text">
          {t('PropertyPlayerConfigCollapse.propertyTitle', {propertyName})}
        </div>
        <div className="Section2">
          {this.getConfigurePlayerBtn({propertyId}, propertyName)}
          <Button onClick={this.displaySelectStudiosModal.bind(this, propertyId)}>
            {t(`PropertyPlayerConfigCollapse.selectStudios`)}
          </Button>
          {!deleteAll ? <Icon type="close" onClick={this.removeProperty.bind(this, propertyId)}/> : null}
        </div>
      </div>
    );
  };

  onPlayerConfigurationChange = (propertyId, config, configValues) => {
    this.props.onPropertyPlayerChange(propertyId, config, configValues);
  };

  propertyMap = (property) => {
    const {id: propertyId, name, studios, deleteAll} = property;
    return (
      <Collapse.Panel key={propertyId}
                      className="Property"
                      header={this.getPropertyCollapseHeader(propertyId, name, deleteAll)}>
        {this.renderStateIndicator({propertyId}, property)}
        {this.renderStudiosCollapse(propertyId, studios, deleteAll)}
      </Collapse.Panel>
    );
  };

  removeConfiguration = propertyId => {
    this.props.removePropertyConfiguration(propertyId);
  };

  removeProperty = (propertyId, event) => {
    stopEvent(event);
    this.props.onRemoveProperty(propertyId);
  };

  renderStudiosCollapse = (propertyId, studios, deleteAll) => {
    const {
      onMoviePlayerChange, onRemoveMovie, onRemoveStudio,
      onStudioPlayerChange, removeStudioConfiguration, setSelectedMovies
    } = this.props;

    return (
      <div className="StudioCollapse-Container">
        <StudioPlayerConfigCollapse
          studios={studios || {}}
          propertyId={propertyId}
          onRemoveStudio={onRemoveStudio}
          onRemoveMovie={onRemoveMovie}
          onStudioPlayerChange={onStudioPlayerChange}
          setSelectedMovies={setSelectedMovies}
          onMoviePlayerChange={onMoviePlayerChange}
          removeStudioConfiguration={removeStudioConfiguration}
        />
      </div>
    );
  };

  render() {
    const {properties} = this.props;
    const data = Object.values(properties);
    const keys = Object.keys(properties);
    return (
      <Collapse className="PropertyPlayerConfigCollapse"
                defaultActiveKey={keys}>
        {data.map(this.propertyMap)}
      </Collapse>
    );
  }
}

PropertyPlayerConfigCollapse.propTypes = {
  onMoviePlayerChange: PropTypes.func.isRequired,
  onPropertyPlayerChange: PropTypes.func.isRequired,
  onRemoveProperty: PropTypes.func.isRequired,
  onRemoveStudio: PropTypes.func.isRequired,
  onRemoveMovie: PropTypes.func.isRequired,
  onStudioPlayerChange: PropTypes.func.isRequired,
  properties: PropTypes.object.isRequired,
  removePropertyConfiguration: PropTypes.func.isRequired,
  removeStudioConfiguration: PropTypes.func.isRequired,
  setSelectedMovies: PropTypes.func.isRequired,
  setSelectedStudios: PropTypes.func.isRequired
};

export default withTranslation()(PropertyPlayerConfigCollapse);
