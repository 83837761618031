import {withTranslation} from "react-i18next";

import UploadImages from "../../../../../../../../../../components/modal/UploadImages/UploadImages";

/* istanbul ignore file */
class NumberOneImageSelectModal extends UploadImages {

  constructor(props) {
    super(props);
    this.singleImageUpload = true;
  }

  getImageCategories = () => [this.props.imageType];

  getUploadButtonText = () => this.props.t('NumberOneImageSelectModal.save');
}

export default withTranslation()(NumberOneImageSelectModal);
