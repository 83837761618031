import React, {Component} from 'react';
import ModalDialog from '../modal/ModalDialog/ModalDialog';
import {Button, Input, Select} from "antd";
import {cloneDeep} from 'lodash';

import BaseLoadMoreSelect from '../BaseLoadMoreSelect/BaseLoadMoreSelect';
import SingleFile from "../modal/UploadImages/SingleFile/SingleFile";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../services/notification-service/notification.service";

import './BaseGlobalImagesAssignSelectionModal.scss';

class BaseGlobalImagesAssignSelectionModal extends Component {
  modalRef = React.createRef();

  state = {
    files: [],
    selectedType: '',
    manualInputId: '',
    selectedId: null
  }

  componentDidMount() {
    const initialImagesType = this.getImageCategories()[0];
    this.setState({
      files: this.props.files.map(this.mapCategory.bind(this, initialImagesType)),
      selectedType: initialImagesType
    });
  };

  assignImagesData = () => {
    console.log('Abstract function definition. Please implement assignImagesData');
  };

  assignImage = (imageFile) => {
    const {selectedId, manualInputId} = this.state;
    const {file, type, id} = imageFile;
    let selectedID = selectedId;
    if (this.validateInput()) {
      selectedID = manualInputId;
    }
    let promise;

    if(file) {
      promise = this.uploadImage(selectedID, type, file);
    } else {
      const data = this.getAssignData(selectedID, id, type);
      promise = this.assignImagesData(data);
    }

    return promise;
  };

  assignImages = () => {
    const message = this.props.t('BaseGlobalImagesAssignSelectionModal.assigningPleaseWaitMsg');
    this.getModal().setLoading(message);

    const promises = this.state.files.map(this.assignImage);
    Promise.all(promises)
      .then(this.onAssignImagesSuccess)
      .catch(this.onAssignImagesFailure);
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  fetchBaseLoadMoreData = () => {
    console.log('Abstract function definition. Please implement fetchBaseLoadMoreData');
  };

  getActions = () => {
    const {t} = this.props;
    return [
      <Button 
        onClick={this.closeModal}
        key="cancelBtn"
      >
        {t('BaseGlobalImagesAssignSelectionModal.cancelBtn')}
      </Button>,
      <Button 
        onClick={this.assignImages}
        key="assignBtn"
        disabled={this.isDisabled()}
      >
        {this.getAssignButtonTitle()}
      </Button>
    ]
  };

  getAssignButtonTitle = () => {
    console.log('Abstract function definition. Please implement getAssignButtonTitle');
  };

  getAssignData = (id, fileId, type) => {
    console.log('Abstract function definition. Please implement getAssignData');
  };

  getCommonType = (files) => {
    const fileCount = files.length;
    let commonType = fileCount ? files[0].type : undefined;
    if (files.length > 1) {
      let item = fileCount;
      let file;
      while (commonType && item) {
        item--;
        file = files[item];
        if (file.type !== commonType) {
          commonType = undefined;
        }
      }
    }
    return commonType;
  };

  
  getImageCategories = () => {
    console.log('Abstract function definition. Please implement getImageCategories');
    return [];
  };

  getInputPlaceholder = () => {
    console.log('Abstract function definition. Please implement getInputPlaceholder');
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getSelectConfig = () => {
    return {
      getData: this.fetchBaseLoadMoreData,
      inputLabel: this.getSelectInputLabel(),
      name: '',
      id: ''
    }
  };

  getSelectInputLabel = () => {
    console.log('Abstract function definition. Please implement getSelectInputLabel');
  };

  getTitle = () => {
    console.log('Abstract function definition. Please implement getTitle');
  };

  handleInputChange = (event) => {
    this.setState({
      manualInputId: event.target.value
    });
  };

  isDisabled = () => {
    return this.state.files.length === 0 || (!this.state.selectedId && !this.validateInput());
  };

  mapCategory = (startingType, file) => {
    file.type = startingType;
    return file;
  };

  onAssignImagesFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('BaseGlobalImagesAssignSelectionModal.assignFailedMsg')
    });
  };

  onAssignImagesSuccess = () => {
    this.getModal().clearLoading();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('BaseGlobalImagesAssignSelectionModal.assignSuccessMsg')
    });
    const {files} = this.props;
    this.props.clearAssignedImages(files);
    this.closeModal();
  };

  onCommonTypeChange = (value) => {
    this.setState(prevState => {
      let files = cloneDeep(prevState.files);
      files = files.map(this.setFileType.bind(this, value));
      return {
        files,
        selectedType: value
      };
    });
  };

  onItemSelect = (selectedID) => {
    this.setState({
      selectedId: selectedID
    });
  };

  onTypeChange = (index, value) => {
    const {t} = this.props;
    this.setState(prevState => {
      let files = cloneDeep(prevState.files);
      files[index].type = value;
      const commonType = this.getCommonType(files);
      return {
        files,
        selectedType: commonType ? commonType : t('BaseGlobalImagesAssignSelectionModal.mixed')
      };
    });
  };

  removeFile = (index) => {
    this.setState(prevState => {
      let files = cloneDeep(prevState.files);
      files.splice(index, 1);
      return {
        files,
        selectedType: this.getCommonType(files)
      };
    });
  };

  renderImageCategory = (category) => {
    return (
      <Select.Option value={category} key={category}>
        {category}
      </Select.Option>
    );
  };

  renderSingleFile = (file, index) => {
    return (
      <SingleFile index={index}
                  key={index}
                  url={file.url}
                  imageCategories={this.getImageCategories()}
                  id={index}
                  name={file.name}
                  hideSelectionCheckbox={true}
                  onTypeChange={this.onTypeChange}
                  removeFile={this.removeFile}
                  selected={true}
                  type={file.type}
      />
    );
  };

  setFileType = (type, file) => {
    file.type = type;
    return file;
  };

  uploadImage = (id, type, file) => {
    console.log('Abstract function definition. Please implement uploadImage');
  };

  validateInput = () => {
    const value = this.state.manualInputId;
    const regexForNumbers = new RegExp(/^\d+$/);

    return regexForNumbers.test(value); 
  };

  render() {
    const {t} = this.props;
    const {getData, id, name, inputLabel} = this.getSelectConfig();
    return (
      <ModalDialog 
        title={this.getTitle()} 
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className="BaseGlobalImagesAssignSelectionModal">
          <div className="BaseGlobalImagesAssignSelectionModal-star-selection">
            {inputLabel}
            <BaseLoadMoreSelect 
              onSelect={this.onItemSelect}
              getData={getData}
              name={name}
              idIdentifier={id}
            />
            <Input 
              onChange={this.handleInputChange} 
              placeholder={this.getInputPlaceholder()}
              value={this.state.manualInputId}
            />
          </div>
          <div className="BaseGlobalImagesAssignSelectionModal-images-wrapper">
            <div className="BaseGlobalImagesAssignSelectionModal-images-actions">
              <div className="BaseGlobalImagesAssignSelectionModal-images-actions-label">
                {t('BaseGlobalImagesAssignSelectionModal.setCategory')}
              </div>
              <Select value={this.state.selectedType} onChange={this.onCommonTypeChange} dropdownMatchSelectWidth={false}>
                {this.getImageCategories().map(this.renderImageCategory)}
              </Select>
            </div>
            <div className="BaseGlobalImagesAssignSelectionModal-images">
              {this.state.files.map(this.renderSingleFile)}
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}

export default BaseGlobalImagesAssignSelectionModal;