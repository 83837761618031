import {ROUTES} from "../../../routes";
import {MAIN_DASHBOARD_VIEW_ROUTES} from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";

/**
 * Returns manufacturers view route
 *
 * @returns {string}
 */
export const getManufacturersRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.MFIDS}`;
};

/**
 * Returns manufacturer details route
 *
 * @param {number|string} manufacturerId
 *
 * @returns {string}
 */
export const getManufacturerDetailsRoute = (manufacturerId) => {
  return `${getManufacturersRoute()}/${manufacturerId}`;
};