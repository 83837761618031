import React from 'react';
import { Link } from "@reach/router";
import PropTypes from "prop-types";

import BannerSetBannersAbstract from "../BannerSetBanners/BannerSetBannersAbstract";

import { DocumentTitleController } from "../../../../../../../../../../controllers/title-controller/title.controller";

import {
  getBannerSetBannersRoute,
} from "../../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";
import {
  editBannerExclusiveExcluded,
  getBannerById,
  getBannerSetById, getBannerSetPropertySaveKey, getDefaultBannerSetSaveKey,
} from "../../../../../../../../../../services/banners-service/banners.service";
import { getPropertyById } from "../../../../../../../../../../services/properties-service/properties.service";

/* istanbul ignore file */
class PropertyBannerSetBannersAbstract extends BannerSetBannersAbstract {

  constructor(props) {
    super(props);
    this.dialogName = 'PropertyBannerSetBannersAbstract';
  }

  excludeBannerFromProperty = (response) => {
    const { exclusiveExcluded, id } = response.data.data;
    const { exclusive_excluded } = exclusiveExcluded;
    const { propertyId } = this.props;
    const index = exclusive_excluded.findIndex(this.matchPropertyById);
    let alreadyExcluded = false;
    if (index !== -1) {
      alreadyExcluded = exclusive_excluded[index].type === 'excluded';
      if (!alreadyExcluded) {// Banner is exclusive to this property
        // Remove this entry because we are excluding this banner
        // This can happen when working on multiple instances of the app
        exclusive_excluded.splice(index, 1);
      }
    }
    let promise = Promise.resolve();
    if (!alreadyExcluded) { // If not already excluded we can post else just resolve
      const data = exclusive_excluded.map(this.mapExclusiveExcludedForPost);
      data.push({
        excluded: [{
          properties_id: propertyId,
          exclusive_excluded: 'excluded',
        }]
      });
      promise = editBannerExclusiveExcluded(id, data);
    }

    return promise;
  };

  excludeBannersFromProperty = (values) => {
    return values.map(this.excludeBannerFromProperty)
  };

  getAdditionalDataPromises = () => {
    const { propertyId } = this.props;
    return [
      getPropertyById(propertyId)
    ];
  };

  getAdditionalStatePropertiesFromValue = (values) => {
    const { name } = values[4].data.data;
    return {
      propertyName: name
    };
  };

  getAdditionalTableData = () => {
    const { bannerSetId, propertyId, t } = this.props;
    const { name } = this.state;
    return [{
      name: this.props.t('PropertyBannerSetBanners.inheritsFrom'),
      value: (
        <Link to={getBannerSetBannersRoute(bannerSetId)}>
          {t('PropertyBannerSetBanners.inheritsFromValue', { setName: name || '' })}
        </Link>
      ),
      key: 'inheritsFrom'
    }, {
      name: t('PropertyBannerSetBanners.propertyId'),
      value: propertyId,
      key: 'propertyId'
    }];
  };

  getBannerDetails = (bannerId) => {
    return getBannerById(bannerId);
  };

  getBannerSetBannersFullListArgs = () => {
    const { bannerSetId, propertyId } = this.props;
    return [
      bannerSetId, 'index', 'asc', propertyId
    ];
  };

  getBannerSetById = (page) => {
    const { bannerSetId, propertyId } = this.props;
    return getBannerSetById(bannerSetId, page, 'index', 'asc', undefined, propertyId);
  };

  getPostData = () => {
    const reorder = this.mapStepsForPost();
    const { propertyId } = this.props;
    return reorder.length ? { reorder, properties_id: propertyId } : undefined;
  };

  getSavedData = () => {
    let data = {};
    const { bannerSetId, propertyId } = this.props;
    const defaultKey = getDefaultBannerSetSaveKey(bannerSetId);
    let defaultData = localStorage.getItem(defaultKey);
    if (defaultData) {
      defaultData = JSON.parse(defaultData);
      data = defaultData;
    }

    const bannerKey = getBannerSetPropertySaveKey(bannerSetId, propertyId);
    let bannerSetData = localStorage.getItem(bannerKey);
    if (bannerSetData) {
      const { order, removedBanners } = JSON.parse(bannerSetData);
      if (order) {
        data.order = (data.order || []).concat(order);
      }
      if (removedBanners) {
        data.removedBanners = (data.removedBanners || []).concat(removedBanners);
      }
    }

    return data;
  };

  loadData = (callback, values) => {
    this.clearLoading();
    const state = this.getStateFromValues(values);
    DocumentTitleController.setDocumentTitle(this.props.t('PropertyBannerSetBanners.documentTitle', {
      propertyName: state.propertyName || '',
      setName: state.name || ''
    }));
    this.setState(state, this.triggerVirtualListReload);
    if (callback) {
      callback();
    }
  };

  mapExclusiveExcludedForPost = ({ property_id, type }) => {
    return {
      properties_id: property_id,
      type
    };
  };

  mapRemovePromise = () => {
    const promises = this.removedBanners.map(this.getBannerDetails);
    return Promise.all(promises)
      .then(this.excludeBannersFromProperty);
  };

  matchPropertyById = ({ property_id }) => {
    const { propertyId } = this.props;
    return propertyId === property_id;
  };

  parseTableSetName = (name) => {
    const { propertyName } = this.state;
    return `${propertyName} ${name}`;
  };

}

PropertyBannerSetBannersAbstract.propTypes = {
  bannerSetId: PropTypes.string,
  propertyId: PropTypes.string,
  t: PropTypes.func
};

export default PropertyBannerSetBannersAbstract;
