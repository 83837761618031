import axios from 'axios';

export const urls = {
  imagesScene: '/images',
  licensorMoviesFilter: '/movies/getlicensorwithfilter',
  movie2257: '/movie2257/edit',
  movieCatalogs: '/movie_catalogs',
  movieEdit: '/movies/edit',
  movieExport: '/movies/export',
  movieInPlaylist: '/movie_in_playlist',
  moviePlaylist: '/movie_playlist',
  moviePlaylistCategories: '/movie_playlist_categories',
  movies: '/movies',
  moviesSwitch: '/movies/switcher',
  movieSync: '/movies/sync',
  sexActMovies: '/movies/sexAct',

  sceneEdit: '/scenes/edit',
  sceneInPlaylist: '/scene_in_playlist',
  scenePlaylist: '/scene_playlist',
  scenePlaylistCategories: '/scene_playlist_categories',
  sceneSync: '/movies/scene/sync',

  starMovies: '/movies/star',
  starMoviesExport: '/movies/export_star',
  studiosMovies: '/movies/studio',
  top10Movies: '/top_movies_config',

  controlSwitchers: '/control_switchers'
};

export const addNewScenePlaylist = (categoryId, name) => {
  return axios.post(`${urls.scenePlaylist}/`, null, {
    params: {
      scene_playlist_category_id: categoryId,
      name: name
    }
  });
};

export const addNewPlaylist = (categoryId, name) => {
  return axios.post(`${urls.moviePlaylist}/`, null, {
    params: {
      movie_playlist_category_id: categoryId,
      name: name
    }
  });
};

export const addNewPlaylistMovie = (playlistId, movieId) => {
  return axios.post(`${urls.movieInPlaylist}/`, null, {
    params: {
      movie_playlist_id: playlistId,
      movie_id: movieId
    }
  });
};

export const addNewPlaylistScene = (playlistId, sceneId) => {
  return axios.post(`${urls.sceneInPlaylist}/`, null, {
    params: {
      scene_playlist_id: playlistId,
      scene_id: sceneId
    }
  })
}

export const deleteMovieInPlaylist = id => {
  return axios.delete(`${urls.movieInPlaylist}/${id}`);
};

export const deleteMoviePlaylist = id => {
  return axios.delete(`${urls.moviePlaylist}/${id}`);
};

export const deleteSceneInPlaylist = id => {
  return axios.delete(`${urls.sceneInPlaylist}/${id}`);
};

export const deleteScenePlaylist = id => {
  return axios.delete(`${urls.scenePlaylist}/${id}`);
};

export const detachMovieEpisode = (movieId, sceneId) => {
  const postData = {
    id: movieId,
    scenes_id: sceneId
  };
  return axios.patch(`${urls.movies}/${movieId}/episodes/detach`, postData);
};

export const editMovie = (id, data) => {
  const {
    titleNs,
    descriptionNs,
    releasedDate,
    videos,
    ns_original_spotlight,
    moviesOnPropertiesData
  } = data;

  const params = {
    title_ns: titleNs,
    description_ns: descriptionNs,
    released_date: releasedDate,
    ns_original_spotlight
  };

  if (videos && videos?.length !== 0) {
    params.videos = [videos];
  }

  return axios.patch(`${urls.movieEdit}/${id}`,
    { movies_on_properties_data: moviesOnPropertiesData },
    { params }
  );
};

export const editMovie2257 = ({ id, address, city, custodianOfRecords, name, postalCode, state }) => {
  return axios.patch(`${urls.movie2257}/${id}`, {
    address, city, custodianOfRecords, name, postalCode, state
  });
};

export const editMoviePlaylist = (id, postData) => {
  return axios.patch(`${urls.moviePlaylist}/${id}`, postData);
};

export const editScene = (id, data) => {
  const {
    start_time_seconds,
    end_time_seconds,
    videos,
    overriddenData,
    enabled,
    scenesOnPropertiesData
  } = data;

  const params = {
    id,
    start_time_seconds,
    end_time_seconds,
    enabled
  };

  if (overriddenData.overridden_movies_id) {
    params.overridden_movies_id = overriddenData.overridden_movies_id;
  }

  if (overriddenData.episode_index) {
    params.episode_index = overriddenData.episode_index;
  }

  if (videos && videos?.length !== 0) {
    params.videos = [videos];
  }

  return axios.patch(`${urls.sceneEdit}/${id}`,
    { scenes_on_properties_data: scenesOnPropertiesData },
    { params }
  );
};

export const editScenePlaylist = (id, postData) => {
  return axios.patch(`${urls.scenePlaylist}/${id}`, postData);
};

export const getMovieCatalogs = () => {
  return axios.get(`${urls.movieCatalogs}`);
};

export const editCopyMovieCatalog = ({ source_id, target_id }) => {
  return axios.put(`${urls.movieCatalogs}/${source_id}/copy/${target_id}`);
};

export const getMovieById = (
  id,
  sceneImagesSortOrder,
  customImagesOnly,
  movieImagesCustomOnly,
  movieImagesMasterOnly,
  sceneImagesMasterOnly
) => {

  return axios.get(`${urls.movies}/${id}`, {
    params: {
      movie_images_custom_only: movieImagesCustomOnly,
      movie_images_master_only: movieImagesMasterOnly,
      scene_images_custom_only: customImagesOnly,
      scene_images_master_only: sceneImagesMasterOnly,
      scene_images_sort_order: sceneImagesSortOrder,
    }
  })
};

export const getMoviePlayerStills = (movieId) => {
  return axios.get(`${urls.movies}/${movieId}/stills/aebn`);
};

export const recreateVideoSample = (sceneId, samples = []) => {
  return axios.post(`/scenes/${sceneId}/sample/create`, {
    samples
  });
};

export const getMoviesByLicensorIdPage = (licensorId, page) => {
  return axios.get(`${urls.licensorMoviesFilter}/${licensorId}?page=${page}`);
}

export const getFilteredLicensorMovies = (licensorId, page, filters) => {
  return axios.get(`${urls.licensorMoviesFilter}?id=${licensorId}&${filters.join('&')}&page=${page}`);
};

export const getMoviesByLicensorId = (licensorId, page, filters) => {
  return filters.length
    ? getFilteredLicensorMovies(licensorId, page, filters)
    : getMoviesByLicensorIdPage(licensorId, page)
};

export const getMoviesBySexActId = (sexActId, page, filters) => {
  return filters.length
    ? getMoviesBySexActIdFiltered(sexActId, page, filters)
    : getMoviesBySexActIdPage(sexActId, page);
};

export const getMoviesBySexActIdFiltered = (sexActId, page, filters) => {
  return axios.get(`${urls.sexActMovies}?id=${sexActId}&${filters.join('&')}&page=${page}`);
};

export const getMoviesBySexActIdPage = (sexActId, page) => {
  return axios.get(`${urls.sexActMovies}/${sexActId}?page=${page}`);
};

export const getMoviesByStarId = (starId, page, filters) => {
  return filters.length
    ? getMoviesByStarIdFiltered(starId, page, filters)
    : getMoviesByStarIdPage(starId, page);
};

export const getMoviesByStarIdFiltered = (starId, page, filters) => {
  return axios.get(`${urls.starMovies}?id=${starId}&${filters.join('&')}&page=${page}`);
};

export const getMoviesByStarIdPage = (starId, page) => {
  return axios.get(`${urls.starMovies}/${starId}?page=${page}`);
};

export const getMovieContentInfo = (movieId) => {
  return axios.get(`/movies/${movieId}/info/aebn`);
};

export const getFilteredStudioMovies = (studioId, page, filters) => {
  return axios.get(`${urls.studiosMovies}?id=${studioId}&${filters.join('&')}&page=${page}`);
};

export const getMoviesByStudioIdPage = (studioId, page) => {
  return axios.get(`${urls.studiosMovies}/${studioId}?page=${page}`);
};

export const getMoviesByStudioId = (studioId, page, filters) => {
  return filters.length
    ? getFilteredStudioMovies(studioId, page, filters)
    : getMoviesByStudioIdPage(studioId, page)
};

export const getMovies = (page, filters, signal) => {
  const params = {
    page
  };

  if (Array.isArray(filters)) {
    filters.forEach(f => {
      const sortBy = f.split('=');
      if (sortBy.length === 2) {
        params[sortBy[0]] = sortBy[1];
      }
    })
  }

  if (filters?.search) {
    params.search = filters.search;
  }

  return axios.get(`${urls.movies}`, {
    params,
    signal
  });
};

export const getMoviesCancelable = (page, filters) => {
  const controller = new AbortController();
  return {
    result: getMovies(page, filters, controller.signal),
    controller
  };
};

export const getMoviesExport = (searchParams) => {
  let url = urls.movieExport;
  if (searchParams && searchParams.length) {
    url = `${url}?${searchParams.join('&')}`;
  }
  return axios.get(url);
};

export const getMoviesPlaylist = () => {
  return axios.get(`${urls.moviePlaylist}`);
};

export const getMoviesPlaylistById = (id) => {
  return axios.get(`${urls.moviePlaylist}/${id}`);
};

export const getMoviesPlaylistCategories = () => {
  return axios.get(`${urls.moviePlaylistCategories}`);
};

export const moviePlaylistReorder = (playlistId, movieId, putBeforeId = null) => {
  return axios.patch(`${urls.moviePlaylist}/${playlistId}/movie_in_playlist/reorder`, {
    reorder: [{
      movies_id: movieId,
      put_before_movies_id: putBeforeId
    }]
  })
}

export const getSceneContentInfo = (sceneId) => {
  return axios.get(`/scenes/${sceneId}/info/aebn`);
};

export const getScenesPlaylist = (page) => {
  return axios.get(`${urls.scenePlaylist}?page=${page}`);
};

export const getScenePlaylistById = (id) => {
  return axios.get(`${urls.scenePlaylist}/${id}`);
};

export const getScenesPlaylistCategories = () => {
  return axios.get(`${urls.scenePlaylistCategories}`);
};

export const scenePlaylistReorder = (playlistId, sceneId, putBeforeId = null) => {
  return axios.patch(`${urls.scenePlaylist}/${playlistId}/scene_in_playlist/reorder`, {
    reorder: [{
      scenes_id: sceneId,
      put_before_scenes_id: putBeforeId
    }]
  })
}

export const getStarMoviesExport = (starId) => {
  return axios.get(`${urls.starMoviesExport}?id=${starId}`);
};

export const getTop10Movies = propertyId => {
  return axios.get(`${urls.top10Movies}/${propertyId}/get`);
};

export const setMovieEnabled = (id, enable) => {
  return axios.get(
    `${urls.moviesSwitch}/${id}/${enable ? 'enable' : 'disable'}`
  );
};

export const setSceneHeroImage = (sceneId, imageId) => {
  return axios.patch(`${urls.imagesScene}/${imageId}/scene/${sceneId}`);
};

export const setImageAltTag = (imageId, alt) => {
  return axios.patch(`${urls.imagesScene}/${imageId}/alt`, {
    alt: alt === '' ? null : alt
  });
};

export const setTopTenPropertyMovies = (propertyId, moviesOrder, file) => {
  if (file) {
    let formData = new FormData();
    formData.append("image", file)
    return axios.post(`${urls.top10Movies}/${propertyId}/set`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        movies_id: moviesOrder
      }
    });
  } else {
    return axios.post(`${urls.top10Movies}/${propertyId}/set`, {
      movies_id: moviesOrder
    });
  }
};

export const setMovieControlSwitchers = (movieId, controlSwitchers = []) => {
  return axios.patch(`${urls.movies}/${movieId}${urls.controlSwitchers}/attach`,
    { id: movieId, control_switchers_id: controlSwitchers }
  );
}

export const syncMovie = id => {
  return axios.get(`${urls.movieSync}/${id}`);
};

export const syncScene = (movieId, id) => {
  return axios.get(`${urls.sceneSync}/${movieId}/${id}`);
};
