import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {stopEvent, areArraysEqual} from "../../services/util-service/util.service";
import {cloneDeep} from 'lodash';

import './BaseDragDropList.scss';

class BaseDragDropList extends Component {

  previousState = undefined;

  state = {
    draggedItemId: undefined,
    dragChangeInitiated: false,
    hoveredItemId: undefined,
    items: []
  };

  constructor(props) {
    super(props);
    this.state.items = props.items;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = null;
    if (prevState.dragChangeInitiated) {
      state = {dragChangeInitiated: false};
    } else if (!areArraysEqual(nextProps.items, prevState.items)) {
      state = {
        items: nextProps.items,
      };
    }

    return state;
  }

  allowDrop = (event) => {
    stopEvent(event);
  };

  convertToString = (item) => {
    return `${item}`;
  };

  getDraggableItemClasses = (id) => {
    let classes = ['DraggableItem'];
    const {draggedItemId, hoveredItemId} = this.state;
    if (id === draggedItemId) {
      classes.push('Dragged');
    } else if (id === hoveredItemId) {
      classes.push('Hovered');
    }

    return classes;
  };

  getIndexes = (draggedIndex, hoverIndex) => {
    let newIndex = 0;
    let removalIndex = draggedIndex;
    if (hoverIndex) {
      if (draggedIndex < hoverIndex) {
        newIndex = hoverIndex + 1;
      } else {
        newIndex = hoverIndex;
        removalIndex++;
      }
    } else {
      removalIndex++;
    }

    return {newIndex, removalIndex};
  };

  getItemId = (item) => {
    const {getItemId} = this.props;
    let id = item.id;
    if (getItemId) {
      id = getItemId(item);
    }

    return this.convertToString(id);
  };

  mapDraggableItem = (item, index) => {
    const {renderItem, renderAdditional} = this.props;
    const id = this.getItemId(item);
    return (
      <div className="BaseDragDropList-item"
           key={id}>
        <div draggable={true}
             onDragStart={this.onDragStart.bind(this)}
             onDragEnd={this.onDragEnd.bind(this)}
             onDragOver={this.onDragOver.bind(this, id)}
             onDrop={this.onDrop}
             className={this.getDraggableItemClasses().join(' ')}
             id={id}>
          {renderItem(item, index)}
          {this.renderCloseIndicator(item, index)}
        </div>
        {renderAdditional ? renderAdditional(item, index) : null}
      </div>
    );
  };

  matchItemById = (id, item) => {
    return id === this.getItemId(item);
  };

  onDragEnd = event => {
    const {dropEffect} = event.dataTransfer;
    if (dropEffect !== 'move') { // User moved the item outside of allowed drop zone, so reset to previous state
      this.setState({
        hoveredItemId: undefined,
        items: cloneDeep(this.previousState)
      });
    }
    this.previousState = undefined;
  };

  onDragOver = (id) => {
    if (id !== this.state.draggedItemId) {// Make sure that we don't check dragged item drag over event
      this.setState(prevState => {
        const items = cloneDeep(prevState.items);
        let draggedIndex = items.findIndex(this.matchItemById.bind(this, prevState.draggedItemId));
        const hoverIndex = items.findIndex(this.matchItemById.bind(this, id));
        const hoveredItemId = items[hoverIndex].id;
        const {newIndex, removalIndex} = this.getIndexes(draggedIndex, hoverIndex);
        items.splice(newIndex, 0, items[draggedIndex]);// Insert dragged item to the new place
        items.splice(removalIndex, 1);// Remove dragged item from the old place so we don't duplicate
        return {items, hoveredItemId: hoveredItemId, dragChangeInitiated: true};
      });
    }
  };

  onDragStart = (event) => {
    const {id} = event.target;
    event.dataTransfer.setData("text", id);
    if (!this.previousState) {
      this.previousState = cloneDeep(this.state.items);
    }
    this.setState({draggedItemId: id, dragChangeInitiated: true});
  };

  onDrop = (event) => {
    stopEvent(event);
    const {items} = this.state;
    this.props.onOrderChange(items);
    this.setState({
      dragChangeInitiated: true,
      hoveredItemId: undefined
    });
  };

  onItemRemove = (movieId, index) => {
    this.props.onItemRemove(movieId, index);
  };

  renderCloseIndicator = (item, index) => {
    const {onItemRemove} = this.props;
    let view = null;
    if (onItemRemove) {
      view = (
        <div className="CloseContainer">
          <i className="fas fa-times" onClick={this.onItemRemove.bind(this, item.movieId, index)}/>
        </div>
      );
    }

    return view;
  };

  render() {
    const {items} = this.state;
    return (
      <div className="BaseDragDropList" onDragOver={this.allowDrop}>
        {items.map(this.mapDraggableItem)}
      </div>
    );
  }
}

BaseDragDropList.propTypes = {
  getItemId: PropTypes.func,
  items: PropTypes.array.isRequired,
  onItemRemove: PropTypes.func,
  onOrderChange: PropTypes.func.isRequired,
  renderAdditional: PropTypes.func,
  renderItem: PropTypes.func.isRequired
};

export default BaseDragDropList;
