import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Input } from 'antd';

import { addCatalogToProperty } from '../../../../../../../../../../services/properties-service/properties.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../../../../services/notification-service/notification.service';

import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';

import './AddCatalogModal.scss';

class AddCatalogModal extends Component {

  state = {
    catalogName: ''
  };

  modalRef = React.createRef();

  addCatalog = () => {
    const message = this.props.t('AddCatalogModal.addingCatalogPleaseWait');
    const { propertyId } = this.props;
    const { catalogName } = this.state;

    this.getModal().setLoading(message);

    addCatalogToProperty(propertyId, { name: catalogName })
      .then(this.onAddCatalogToPropertySuccess)
      .catch(this.onAddCatalogToPropertyFailure);
  };

  closeDialog = () => {
    this.getModal().closeModal();
  };

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='AddCatalogModal-actions'>
        <Button
          onClick={this.onCancel}
          key='cancelBtn'>
          {t('AddCatalogModal.cancel')}
        </Button>
        <Button
          onClick={this.addCatalog}
          key='addCatalogBtn'
          disabled={!this.state.catalogName}
          type='primary'>
          {t('AddCatalogModal.addCatalogBtn')}
        </Button>
      </div>
    );
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getTitle = () => {
    return this.props.t('AddCatalogModal.title');
  };

  onAddCatalogToPropertyFailure = () => {
    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('AddCatalogModal.addCatalogFailed')
    });
  };

  onAddCatalogToPropertySuccess = () => {
    this.closeDialog();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('AddCatalogModal.addCatalogSuccess')
    });
  };

  onCancel = () => {
    this.closeDialog();
  };

  onInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { t } = this.props;
    return (
      <ModalDialog
        title={this.getTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
      >
        <div className='AddCatalogModal'>
          <div className="AddCatalogModal-field-wrapper">
            <div className="Label">{t('AddCatalogModal.name')}:</div>
            <Input
              value={this.state.catalogName}
              name="catalogName"
              onChange={this.onInputChange}
            />
          </div>
        </div>
      </ModalDialog>
    );
  };
};

export default withTranslation()(AddCatalogModal);