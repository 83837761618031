import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

import StudiosViewBody from "./StudiosViewBody/StudiosViewBody";
import StudiosViewFooter from './StudiosViewFooter/StudiosViewFooter';
import StudiosViewHeader from './StudiosViewHeader/StudiosViewHeader';

import LoadingWrapper from "../../../../../../../../../components/LoadingWrapper/LoadingWrapper";

import {getScrollHeight} from "../../../../../../../../../services/util-service/util.service";

import './StudiosMainView.scss';


class StudiosMainView extends Component {

  state = {
    currentPage: 0,
    loading: false,
    pageCount: 0,
    pageSize: 0,
    recordCount: 0,
    studios: [],
    studiosLoaded: false
  };
  filters = {
    name: ''
  }
  tableRowHeight = 65;

  filterByName = (name) => {
    this.filters.name = name;
    this.props.onNameFilterChange(1, this.filters);
  };

  getFooterHeight = () => {
    return this.props.listInfo.recordCount === this.props.listInfo.pageSize ? 0 : 32;
  };

  getScrollHeight = () => {
    const {contentHeight, listInfo} = this.props;
    const reductionHeight = 52 + this.getFooterHeight();
    return getScrollHeight(contentHeight, listInfo.studios, this.tableRowHeight, reductionHeight);
  };

  renderBody = () => {
    const scroll = this.getScrollHeight();
    const {listInfo, onStudioEdit, setStudioEnabled} = this.props;
    return (
      <StudiosViewBody scroll={scroll}
                       studios={listInfo.studios}
                       setStudioEnabled={setStudioEnabled}
                       onStudioEdit={onStudioEdit}/>
    );
  };

  renderFooter = () => {
    return (
      <StudiosViewFooter
        pageCount={this.props.listInfo.pageCount}
        pageSize={this.props.listInfo.pageSize}
        total={this.props.listInfo.recordCount}
        currentPage={this.props.listInfo.currentPage}
        onChange={this.props.onPageChange}
      />
    );
  };

  renderHeader = () => {
    const {
      defaultDisplayFilter,
      generalLinks, sortCriteria,
      onClearFilter, onStatusChange,
      defaultSortFilter, defaultFilters,
      exportStudiosData, localizedDefaultSortFilter
    } = this.props;
    return (
      <StudiosViewHeader
        defaultDisplayFilter={defaultDisplayFilter}
        generalLinks={generalLinks}
        sortCriteria={sortCriteria}
        onClearFilter={onClearFilter}
        onFilterChange={onStatusChange}
        defaultSortFilter={defaultSortFilter}
        localizedDefaultSortFilter={localizedDefaultSortFilter}
        defaultFilters={defaultFilters}
        onNameFilterChange={this.filterByName}
        onStudiosExport={exportStudiosData.bind(this)}
      />
    );
  };

  render() {
    const {listInfo} = this.props;
    const {dataLoaded, loadingMsg} = listInfo;
    return (
      <LoadingWrapper className='StudiosMainView'
                      dataLoaded={dataLoaded}
                      isLoading={!!loadingMsg}
                      loadingMsg={loadingMsg}>
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
      </LoadingWrapper>
    );
  };
}

StudiosMainView.propTypes = {
  defaultFilters: PropTypes.object,
  listInfo: PropTypes.object.isRequired,
  onNameFilterChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  localizedDefaultSortFilter: PropTypes.string
};

export default withTranslation()(StudiosMainView);

